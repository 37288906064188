import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Skeleton,
  Modal,
  Select,
  InputNumber,
  Tabs,
  Space,
  Radio,
  Tooltip
} from 'antd';
import PicturesWallLocal from '~/components/Utils/PicturesWallLocal';
import Variants from './Variants';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreateMedicine,
  useMedicine,
  useInitMedicine,
  useUpdateMedicine,
  useMedicineGroupOptions,
  useMedicineLocations,
  useMedicineMethods,
  useManufactureOptions,
  useCountries,
  useMedicineUnits,
  filterActive,
  useSuppliersAuthenticated
} from '~/hooks';
import './index.scss';
import MedicineName from './MedicineName';
import TextArea from 'antd/lib/input/TextArea';
import MedicineGroupForm from '~/components/WorldClinic/MedicineGroup/Form';
import MedicineLocationForm from '~/components/WorldClinic/MedicineLocation/Form';
import MedicineMethodForm from '~/components/WorldClinic/MedicineMethod/Form';
import ManufactureForm from '~/components/WorldClinic/Manufacture/Form';
import { DeleteTwoTone, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PRODUCT_TYPES } from '~/constants/defaultValue';
import { validatePrice } from '~/utils/validators';
import moment from 'moment';
import UploadMultiImage from './UploadMultiImage';
import { get } from 'lodash';
import { DebounceSelect } from '~/components/Common';
import api from '~/api';
import SupplierForm from '../../Supplier/Form';

const { Option } = Select;
const FormItem = Form.Item;
const { TabPane } = Tabs;

const emptyQuery = {};

const MedicineForm = ({ isOpen, onClose, id, callBack }) => {
  const [form] = Form.useForm();

  const onCreateSuccess = medicine => {
    callBack?.(medicine);
    onClose();
  };

  const [isSubmitLoading, handleCreate] = useCreateMedicine(onCreateSuccess);
  const [, handleUpdate] = useUpdateMedicine(onClose);
  const [medicine, isLoading] = useMedicine(id);
  const [isUpdate, setIsUpdate] = useState(Boolean(id));

  const initMedicine = useInitMedicine(medicine, id);
  const [groupOptions, isGroupOptionLoading] = useMedicineGroupOptions();
  const [methodOptions, isMethodOptionsLoading] = useMedicineMethods(emptyQuery);
  const [locationOptions, isLocationOptionLoading] = useMedicineLocations(emptyQuery);
  const [unitOptions, isUnitOptionLoading] = useMedicineUnits(emptyQuery);
  const [manufactureOptions, isManufactureOptionsLoading] = useManufactureOptions(emptyQuery);
  const [countries, isGettingCountries] = useCountries();
  const [images, setImages] = useState([]);
  const [loadingImg, setLoadingImg] = useState(false);
  const [isGroupFormOpen, setGroupFormOpen] = useState(false);
  const [isLocationFormOpen, setLocationFormOpen] = useState(false);
  const [isMethodFormOpen, setMethodFormOpen] = useState(false);
  const [isManufactureFormOpen, setManufactureFormOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  
  const [suppliers, loading] = useSuppliersAuthenticated();
  const [isOpenFormSupplier, setIsOpenFormSupplier] = useState(false);
  
  // useEffect(() => {
  //   setIsUpdate(!!id)
  // }, [id]);

  useEffect(() => {
    form.resetFields();
    setActiveTab('1');

    const { image } = initMedicine;
    if (image && image.length) {
      setImages(image);
    }
  }, [initMedicine, form]);

  const onFinish = values => {
    const medicine = {
      category: 'MEDICINE',
      ...values,
      name: values?.name?.trim(),
      codeBySupplier: values?.codeBySupplier?.trim(),
      image: images.filter(img => !!img),
      defaultVariant: undefined,
      productVariants: [
        {
          ...values.defaultVariant,
          cost: get(values, ['defaultVariant', 'cost'],0),
          isDefault: true
        },
        ...(values.productVariants || []).map(varian => ({
          ...varian,
          isDefault: false
        }))
      ],
      productType: PRODUCT_TYPES.MEDICINE,
      produceDate: moment(values.produceDate).toISOString()
    };
    if (id) {
      medicine.productVariants[0]._id = initMedicine.defaultVariant?._id;

      const deletedVariants = initMedicine.productVariants
        .filter(
          ({ _id }) =>
            !medicine.productVariants.find(variant => variant._id === _id)
        )
        .map(deletedVariant => ({ ...deletedVariant, status: 'DELETED' }));

      handleUpdate({
        ...medicine,
        _id: id,
        productVariants: [...medicine.productVariants, ...deletedVariants]
      });
    } else {
      handleCreate(medicine);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={1080}
      footer={[]}
      onCancel={onClose}
      className="form-modal wc-form form-modal--clinic"
    >
      <div className="medicine-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={values => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initMedicine}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
        >
          <Row>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} thuốc
            </h4>
          </Row>

          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Thông tin" key="1">
              <Row gutter={48} align="middle" justify="space-between">
                <Col span={12}>
                  <FormItem
                    label="Mã hàng"
                    name={['defaultVariant', 'variantCode']}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Input placeholder="Mã hàng tự động" disabled />
                    )}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Mã vạch"
                    name={['defaultVariant', 'barcode']}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <MedicineName form={form} unitOptions={unitOptions} />
                </Col>

                <Col span={12}>
                  <FormItem label="Tên viết tắt" name="aliasName">
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col
                  span={24}
                  style={{ position: 'relative' }}
                  id="medicine-autocomple-container"
                ></Col>
              </Row>

              <Row gutter={48} align="middle" justify="space-between">
                <Col span={12}>
                  <Row justify="space-between">
                    <FormItem
                      label="Nhóm"
                      name="groupId"
                      rules={[{ required: true, message: 'Nhập nhóm thuốc' }]}
                      className={'error-message-ml-22'}
                    >
                      <Select
                        loading={isGroupOptionLoading}
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 318, marginLeft: 22, padding: 0 }}
                      >
                        {filterActive(groupOptions)?.map(({ name, _id,disabled }) => (
                          <Option disabled={disabled} key={_id} value={_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                      {/* <DebounceSelect
                        allowClear
                        fetchOptions={(query) => api.medicineGroup.getAll({...query,status : "ACTIVE"})}
                        valueKey="name"
                        style={{ width: 318, marginLeft: 22, padding: 0 }}
                      /> */}
                    </FormItem>

                    <Button onClick={() => setGroupFormOpen(true)}>
                      <PlusOutlined />
                    </Button>
                  </Row>
                </Col>

                <Col span={12}>
                  <FormItem label="Giá vốn" name={['defaultVariant', 'cost']}>
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <InputNumber
                        min={0}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <Row justify="space-between">
                    <FormItem
                      label="Đường dùng"
                      name="typeId"
                      // rules={[{ required: true, message: 'Nhập đường dùng' }]}
                      className={'error-message-ml-14'}
                    >
                      <Select
                        loading={isMethodOptionsLoading}
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 317, marginLeft: 14, padding: 0 }}
                      >
                        {methodOptions.map(({ name, _id }) => (
                          <Option key={_id} value={_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Button onClick={() => setMethodFormOpen(true)}>
                      <PlusOutlined />
                    </Button>
                  </Row>
                </Col>

                <Col span={12}>
                  <FormItem label="Giá bán" name={['defaultVariant', 'price']}>
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <InputNumber
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <Row justify="space-between">
                    <FormItem label="Vị trí" name="positionId">
                      <Select
                        showSearch
                        style={{ width: 317, marginLeft: 25, padding: 0 }}
                        optionFilterProp="children"
                        loading={isLocationOptionLoading}
                      >
                        {locationOptions.map(({ name, _id }) => (
                          <Option key={_id} value={_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Button onClick={() => setLocationFormOpen(true)}>
                      <PlusOutlined />
                    </Button>
                  </Row>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Trọng lượng"
                    name={['productDetail', 'weight']}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>
              <Row  justify="space-between" gutter={48}>
                <Col span={12}>
                  <FormItem
                    label={'Mã sản phẩm'}
                    name={'codeBySupplier'}
                    rules={[{ required: true, message: 'Vui lòng nhập mã sản phẩm' }]}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                        <Input/>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}></Col>
              </Row>

              <h5 className="form-section__title">Hình ảnh</h5>
              <Space align="start">
                {/* <PicturesWallLocal
                  onImagesChange={setImages}
                  initFilelist={initMedicine.images}
                /> */}
                {images?.map((e, index) => (
                  <Row>
                    <UploadMultiImage
                      images={images}
                      loadingImg={loadingImg}
                      setLoadingImg={setLoadingImg}
                      onChange={image => {
                        const newImages = [...images];
                        newImages.splice(index, 1, image);
                        setImages(newImages);
                      }}
                      imgUrl={e?.response?.url}
                    />
                    <Button
                      onClick={() =>
                        setImages(images.filter((v, i) => i !== index))
                      }
                    >
                      <DeleteTwoTone />
                    </Button>
                  </Row>
                ))}
                {images.length < 10 ? (
                  <UploadMultiImage
                    title="Ảnh thuốc"
                    loadingImg={loadingImg}
                    setLoadingImg={setLoadingImg}
                    onChange={image => setImages([...images, image])}
                    imgUrl={''}
                  />
                ) : null}
              </Space>

              <h5 className="form-section__title" style={{ marginTop: 25 }}>
                Thông tin chung
              </h5>
              <Row gutter={48} align="middle" justify="space-between">
                <Col span={12}>
                  <FormItem
                    label="Số đăng ký"
                    name={['productDetail', 'registrationNo']}
                    rules={[
                      { required: true, message: 'Xin mời nhập số đăng ký' },
                      // { max: 20, message: 'Tối đa 20 ký tự' }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Hoạt chất"
                    name={['productDetail', 'ingredient']}
                    rules={[
                      { required: true, message: 'Xin mời nhập hoạt chất' },
                      // { max: 200, message: 'Tối đa 200 ký tự' }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Hàm lượng"
                    name={['productDetail', 'dosage']}
                    rules={[
                      { required: true, message: 'Xin mời nhập hàm lượng' },
                      // { max: 200, message: 'Tối đa 200 ký tự' }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Đóng gói"
                    name={['productDetail', 'packagingSize']}
                    // rules={[{ max: 50, message: 'Tối đa 50 ký tự' }]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Liều dùng"
                    name={['productDetail', 'doseInUse']}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Nhà nhập khẩu"
                    name="abc"
                    // rules={[{ max: 200, message: 'Tối đa 200 ký tự' }]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Dạng bào chế"
                    name={['productDetail', 'dosageForm']}
                    // rules={[{ max: 200, message: 'Tối đa 200 ký tự' }]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={12} style={{ display: 'none' }}>
                  <FormItem name={['productDetail', 'medicineCode']}>
                    <Input />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <Row justify="space-between">
                    <FormItem
                      label="Hãng sản xuất"
                      name="manufacturerId"
                      className="medicine-form__manufacturer-id"
                      rules={[
                        {
                          required: true,
                          message: 'Xin mời nhập hãng sản xuất'
                        },
                        // { max: 100, message: 'Tối đa 100 ký tự' }
                      ]}
                    >
                      <Select
                        loading={isManufactureOptionsLoading}
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 316, marginLeft: 12, padding: 0 }}
                      >
                        {manufactureOptions.map(({ name, _id }) => (
                          <Option key={_id} value={_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Button onClick={() => setManufactureFormOpen(true)}>
                      <PlusOutlined />
                    </Button>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="space-between">
                    <FormItem
                      label="Nhà cung cấp"
                      name="supplierId"
                      className="medicine-form__manufacturer-id"
                      rules={[
                        {
                          required: true,
                          message: 'Xin mời nhập nhà cung cấp'
                        },
                      ]}
                    >
                      <Select
                        loading={loading}
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 316, marginLeft: 12, padding: 0 }}
                      >
                        {suppliers?.map(({ name, _id }) => (
                          <Option key={_id} value={_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Button onClick={() => setIsOpenFormSupplier(true)}>
                      <PlusOutlined />
                    </Button>
                  </Row>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Nước sản xuất"
                    name="countryId"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Xin mời nhập nước sản xuất'
                    //   }
                    // ]}
                  >
                    <Select
                      loading={isGettingCountries}
                      showSearch
                      optionFilterProp="children"
                    >
                      {countries.map(({ name, _id }) => (
                        <Option key={_id} value={_id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Trạng thái liên thông"
                    name="isRequest"
                    wrapperCol={12}
                    labelCol={8}
                  >
                  <Radio.Group 
                    size='small'
                    style={{float: 'right'}}
                    options={[{ label:'Liên thông',value:true },{ label:'Không liên thông',value:false }]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                  </FormItem>
                </Col>
              </Row>

              <Variants
                isLoading={isLoading}
                form={form}
                unitOptions={unitOptions}
                isUnitOptionLoading={isUnitOptionLoading}
              />
            </TabPane>
            <TabPane tab="Mô tả chi tiết" key="2">
              <Row gutter={48} align="middle" justify="space-between">
                <Col span={12}>
                  <FormItem
                    label="Tồn kho ít nhất"
                    name={['detailDescription', 'quantityMin']}
                    labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <InputNumber
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Tồn kho nhiều nhất"
                    name={['detailDescription', 'quantityMax']}
                    labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <InputNumber
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={48}>
                <Col span={24}>
                  <FormItem
                    name={['detailDescription', 'description']}
                    label=" Mô tả"
                    labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <TextArea
                        style={{
                          width: 850,
                          minWidth: 850,
                          marginLeft: -8
                        }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={48}>
                <Col span={24}>
                  <FormItem
                    name={['detailDescription', 'noteTemplate']}
                    label="Mẫu ghi chú"
                    labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <TextArea
                        style={{
                          width: 850,
                          minWidth: 850,
                          marginLeft: -8
                        }}
                        placeholder="Mẫu ghi chú(hoá đơn, đặt hàng)"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </TabPane>
          </Tabs>

          <Row className="submit-box">

          {isUpdate&&<Button
              type="primary"
              htmlType="button"
              loading={isSubmitLoading || loadingImg}
              onClick={(e)=>{onFinish({...form.getFieldsValue(),category:'PRODUCT'})}}
            >
              Cập nhật thành thuốc ngoài danh mục
            </Button>}
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitLoading || loadingImg}
            >
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>

      <MedicineGroupForm
        isOpen={isGroupFormOpen}
        onClose={() => setGroupFormOpen(false)}
        callback={({ _id }) => form.setFieldsValue({ groupId: _id })}
      />

      <MedicineLocationForm
        isOpen={isLocationFormOpen}
        onClose={() => setLocationFormOpen(false)}
        callback={({ _id }) => form.setFieldsValue({ positionId: _id })}
      />

      <MedicineMethodForm
        isOpen={isMethodFormOpen}
        onClose={() => setMethodFormOpen(false)}
        callback={({ _id }) => form.setFieldsValue({ typeId: _id })}
      />

      <ManufactureForm
        isOpen={isManufactureFormOpen}
        onClose={() => setManufactureFormOpen(false)}
        callback={({ _id }) => form.setFieldsValue({ manufactureId: _id })}
      />
        <SupplierForm
        isOpen={isOpenFormSupplier}
        onClose={() => setIsOpenFormSupplier(false)}
        callback={({ _id }) => form.setFieldsValue({ supplierId: _id })}
      />
    </Modal>
  );
};

export default MedicineForm;
