import React, { useMemo } from 'react';
import { Col, Row, Select, Table, Button, Tag } from 'antd';
import { formatNumber, formatDate } from '~/utils/helper';
import { connect } from 'react-redux';
import { getUserEmployees } from '~/redux/action';
import { INVOICE_STATUS, SALE_CHANNEL } from '~/constants/defaultValue';
import { get, transform } from 'lodash';
import moment from "moment"
import { getDiscount } from '~/utils/saleHelper';
import { getDiscountValue } from '~/utils/caculator';
import ItemInformation from './ItemInformation';

const {Option} = Select

const columns = [
  {
    title: 'Mã hàng',
    key: 'variantCode',
    dataIndex: ['variant', 'variantCode'],
    className: 'wpx85'
  },
  {
    title: 'Tên hàng',
    key: 'name',
    dataIndex: ['product', 'name'],
    className: 'wpx120'
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    key: 'quantity',
    render: quantity => formatNumber(quantity),
    align: 'end',
    className: 'wpx85'
  },
  {
    title: 'Giá bán',
    dataIndex: 'price',
    key: 'price',
    align: 'end',
    className: 'wpx120',
    render: price => formatNumber(price),
  },
  // {
  //   title: 'Giá nhập lại',
  //   dataIndex: 'priceRefund',
  //   key: 'priceRefund',
  //   align: 'end',
  //   className: 'wpx120'
  // },
  {
    title: 'Thành tiền',
    dataIndex: 'price',
    key: 'total',
    align: 'end',
    className: 'wpx120',
    render: (price, _ ) => {
      return formatNumber(_?.quantity * price)
    },
  }
];

const HistoryPayment = ({record,listUsers,dispatchList,chooseUser,saleChannel,setSaleChannel,setChooseUser}) => {
  const options = useMemo(()=>{
    if(record?.receivedBy&&!listUsers?.length){
     return [{
          value: record?.receivedBy?._id,
          label: record?.receivedBy?.fullName,
      }]
    }
    if(!!listUsers){
        return listUsers.map((_)=>{
          return {
            value: _?._id,
            label: _?.fullName,
          }
        })
    }
  
  },[record.receivedBy,listUsers])
  return (
    <div className={'warehouse-history-payment product-return-detail-tab-item'}>
      <Row gutter={48}>
        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation title={'Mã hóa đơn'} value={record?.exchangeInvoice?.code}/>
              </Row>
            </Col>

            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                  <ItemInformation title={'Thời gian'} value={moment(record?.exchangeInvoice?.createdAt).format('DD/MM/YYYY')} />
              </Row>
            </Col>


            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                 <ItemInformation title={'Khách hàng'} value= {record?.customer?.name??'Khách lẻ'}/>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation title={'Trạng thái'} value={
                  INVOICE_STATUS[record?.status]
                } />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation title={'Chi nhánh'} value={record?.branch?.name.vi} />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation title={'Người bán'} value={
                  <Select
                  placeholder="Người bán"
                  onFocus={()=>dispatchList() }
                  onSelect={(e)=>{setChooseUser(e)}}
                  value={ chooseUser || record?.receivedBy?._id}
                  style={{width: '100%'}}
                  options={options}
                >
                </Select>
                } />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
              <ItemInformation title={'Kênh bán'} value={
                <Select
                    placeholder="Chọn kênh bán"
                    onSelect={(e)=>{setSaleChannel(e)}}
                    showSearch
                    value={saleChannel||get(SALE_CHANNEL,record?.saleChannel,"OTHER")}
                    style={{width: '100%'}}
                    options={transform(SALE_CHANNEL,(obj,value,key)=>{obj.push({value:key,label:value})},[])}                  
                  />
                } />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
              <ItemInformation title={'Mã phiếu thu'} value={record?.paymentNote?.code} style={{color: '#1890ff', fontWeight: '600'}} />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={'table-wrapper'}>
        <Table
          className="nested-table"
          columns={columns}
          dataSource={record?.exchangeInvoice?.invoiceDetail}
          pagination={false}
          size="small"
          rowKey={({ variantId }) => variantId}
          expandable={{
            expandedRowRender: item => (
              <div style={{ paddingLeft: 150 }}>
                <Row>
                  <Tag color="#2db7f5">
                    {`${item?.batch?.lotNumber} - ${(item?.batch?.batchSearch[2])}`}
                  </Tag>
                </Row>
              </div>
            ),
            defaultExpandAllRows: true,
            expandedRowKeys: record?.exchangeInvoice?.invoiceDetail?.map(
              ({ variantId }) => variantId
            ),
            expandIcon: () => <></>
          }}
        />
        <div className={'info-bill'} style={{marginTop: '20px'}}>
          <Row gutter={0}>
            <span>Tổng số lượng:</span>
            <span className='wpx90'>{
            get(record?.exchangeInvoice,'invoiceDetail',[]).reduce(
              (total,curr)=>total+(curr.quantity),0
            ) 
            }</span>
          </Row>
          <Row gutter={0}>
            <span>Tổng tiền hàng:</span>
            <span className='wpx90'>
              {formatNumber(get(record?.exchangeInvoice,'totalPayment',0))}
            </span>
          </Row>
          <Row gutter={0}>
            <span>Giảm giá phiếu nhập:</span>
            <span className='wpx90'>{getDiscountValue(getDiscount({
                discountPercent: record?.exchangeInvoice?.discountPercent,
                discountValue: record?.exchangeInvoice?.discountValue,
                discountType: record?.exchangeInvoice?.discountType,
              }))
              }</span>
          </Row>
          <Row gutter={0}>
            <span>Tổng tiền hóa đơn mua:</span>
            <span className='wpx90'>{formatNumber(get(record,['exchangeInvoice','totalPayment'],0))}</span>
          </Row>
          <Row gutter={0}>
            <span>Tổng tiền hóa đơn trả ({get(record,['exchangeInvoice','code'],'')}):</span>
            <span className='wpx90'>{formatNumber(get(record,['exchangeInvoice','totalPayment'],0))}</span>
          </Row>
          <Row gutter={0}>
            <span>Khách cần trả:</span>
            <span className='wpx90'>{formatNumber(get(record,['needToPay'],0))}</span>
          </Row>
          <Row gutter={0}>
            <span>Khách đã trả:</span>
            <span className='wpx90'>{formatNumber(get(record,['paid'],0))}</span>
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state)=>({
  listUsers: state.userEmployee.list
})
const mapDispatchToProps = (dispatch)=>({
    dispatchList : ()=>dispatch(getUserEmployees({limit:200}))
})

export default connect(mapStateToProps,mapDispatchToProps)(HistoryPayment) ;
