import {
  DISCOUNT_TYPES,
  SALE_TYPES,
  SALE_CASES
} from '~/constants/defaultValue';

export const getSaleParam = state => {
  const { saleOrderId, invoiceId } = state || {};
  return saleOrderId ? { saleOrderId } : invoiceId ? { invoiceId } : undefined;
};

export const getDiscount = ({
  discountType,
  discountValue,
  discountPercent
}) => {
  return {
    type: discountType,
    value:
      discountType === DISCOUNT_TYPES.VALUE ? discountValue : discountPercent
  };
};

const { UPDATE_INVOICE, UPDATE_ORDER, NEW_INVOICE, NEW_ORDER } = SALE_CASES;
const { DIRECT } = SALE_TYPES;

export const getSaleCase = sale => {
  if (sale?.case) return sale?.case;

  const saleType = sale?.type || sale?.saleChannel;

  if (sale?._id) {
    return saleType === DIRECT ? UPDATE_INVOICE : UPDATE_ORDER;
  } else {
    return saleType === DIRECT ? NEW_INVOICE : NEW_ORDER;
  }
};

export const getOrderItems = sale => {
  const saleType = sale.type || sale.saleChannel;

  switch (saleType) {
    case SALE_TYPES.DIRECT:
      return sale.invoiceDetail;

    case SALE_TYPES.ORDER:
      return sale.saleOrderDetail;

    default:
      return sale.invoiceDetail;
  }
};

export const getSaleIdProperty = sale => {
  const saleType = sale.type || sale.saleChannel;

  switch (saleType) {
    case SALE_TYPES.DIRECT:
      return 'invoiceId';

    case SALE_TYPES.ORDER:
      return 'saleOrderId';

    default:
      return 'invoiceId';
  }
};
