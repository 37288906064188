import requester from '../requester';

const purchaseOrderQuotation = {
  getAll: query => requester.get('/purchase-order-quotation/list', query),
  getById: id => requester.get(`/purchase-order-quotation/${id}`),
  create: purchaseOrderQuotation => requester.post('/purchase-order-quotation/create', purchaseOrderQuotation),
  update: purchaseOrderQuotation =>
    requester.put(`/purchase-order-quotation/update/${purchaseOrderQuotation._id}`, purchaseOrderQuotation),
  delete: id => requester.delete(`/purchase-order-quotation/delete/${id}`),
  suggestProduct:keyword=> requester.post('/purchase-order-quotation/suggest-product',{keyword}),
  suggestUnit:keyword=> requester.post('/purchase-order-quotation/suggest-unit',{keyword}),
  suggestSupplier:keyword=> requester.post('/purchase-order-quotation/suggest-supplier',{keyword}),
};

export default purchaseOrderQuotation;
