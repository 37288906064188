import { useMemo, useState } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getNewses,
  createNews,
  deleteNews,
  getNews,
  resetNewsState,
  updateNews
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.news[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useNewsQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current }) => setPage(current);

  const deleteNewsSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteNewsSuccess]);
};

export const useNewses = query => {
  return useFetchByParam({
    action: getNewses,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useNewsPaging = () => useSelector(pagingSelector);

export const useCreateNews = () => {
  const history = useHistory();
  const callback = () => history.push('/news');

  useSuccess(createSuccessSelector, 'Tạo mới tin tức thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createNews
  });
};

export const useUpdateNews = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật tin tức thành công');
  useFailed(updateFailedSelector, 'Cập nhật tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateNews
  });
};

export const useDeleteNews = () => {
  useSuccess(deleteSuccessSelector, 'Xoá tin tức thành công');
  useFailed(deleteFailedSelector, 'Xoá tin tức thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteNews
  });
};

export const useNews = id => {
  return useFetchByParam({
    action: getNews,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

const initContentVi = '<p>Xin mời nhập nội dung tin tức</p>';
const initContentEn = '<p>Please input content</p>';

export const useInitNews = news =>
  useMemo(() => {
    if (!news) {
      return {
        content: {
          vi: initContentVi,
          en: initContentEn
        }
      };
    }

    return {
      ...news,
      status: news.status === 'PUBLISHED' ? true : false,
      content: {
        vi: news?.content?.vi || initContentVi,
        en: news?.content?.en || initContentEn
      }
    };
  }, [news]);

export const useResetNews = () => {
  useResetState(resetNewsState);
};
