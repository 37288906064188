import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getShippingVendors({ payload: query }) {
  try {
    const data = yield call(Api.shippingVendor.getAll, query);
    yield put({ type: Types.GET_SHIPPING_VENDORS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SHIPPING_VENDORS_FAILED,
      payload: error.message
    });
  }
}

function* getShippingVendor({ payload }) {
  try {
    const shippingVendor = yield call(Api.shippingVendor.getById, payload);
    yield put({
      type: Types.GET_SHIPPING_VENDOR_DETAIL_SUCCESS,
      payload: shippingVendor
    });
  } catch (error) {
    yield put({
      type: Types.GET_SHIPPING_VENDOR_DETAIL_FAILED,
      payload: error.message
    });
  }
}

function* createShippingVendor(action) {
  try {
    const data = yield call(Api.shippingVendor.create, action.payload);
    yield put({ type: Types.CREATE_SHIPPING_VENDOR_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SHIPPING_VENDOR_FAILED,
      payload: error
    });
  }
}

function* updateShippingVendor(action) {
  try {
    const data = yield call(Api.shippingVendor.update, action.payload);
    yield put({ type: Types.UPDATE_SHIPPING_VENDOR_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SHIPPING_VENDOR_FAILED,
      payload: error
    });
  }
}

function* deleteShippingVendor({ payload }) {
  try {
    yield call(Api.shippingVendor.delete, payload);
    yield put({ type: Types.DELETE_SHIPPING_VENDOR_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_SHIPPING_VENDOR_FAILED,
      payload: error
    });
  }
}

export default function* shippingVendor() {
  yield takeLatest(Types.GET_SHIPPING_VENDORS_REQUEST, getShippingVendors);
  yield takeLatest(Types.GET_SHIPPING_VENDOR_DETAIL_REQUEST, getShippingVendor);
  yield takeLatest(Types.DELETE_SHIPPING_VENDOR_REQUEST, deleteShippingVendor);
  yield takeLatest(Types.CREATE_SHIPPING_VENDOR_REQUEST, createShippingVendor);
  yield takeLatest(Types.UPDATE_SHIPPING_VENDOR_REQUEST, updateShippingVendor);
}
