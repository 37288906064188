import React from 'react';
import ContextProvider, { useGetValueContextPurchaseOrder } from './contextProvider';
import TablePurchaseOrder from './TablePurchaseOrder';
import { Col, Row ,Input} from 'antd';
import './table.scss'
import SelectSearchStatus from './SelectSearchStatus';
import { convertQueryString } from '~/utils/helper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { usePurchaseOrderQuotationQueryParams, useUpdatePurchaseOrderQuotationParams } from './PurchaseOrderQuotation.hook';
import { PATH_CLINIC } from '~/routes/paths';
const { Search } = Input;
function PurchaseOrderQuotation() {
    const history = useHistory();
    const [query] = usePurchaseOrderQuotationQueryParams();
    const [keyword, { setKeyword, onParamChange }] = useUpdatePurchaseOrderQuotationParams(query);
    const handleChangeStatus = (status) => {
        const newKeyword = status.toString();
        setKeyword(newKeyword);
        console.log(newKeyword,'newKeyword')
        let searchParams = convertQueryString(
            {
                ...query,
                status: newKeyword
            }
        );
        console.log(searchParams,'searchParams')
        history.push({
          pathname: PATH_CLINIC.purchaseOrderQuotation.root,
          search: searchParams
        });
      };
    return (
        <ContextProvider>
            <div
                className="page-wraper page-content"
                style={{ height: 'calc(100vh - 68px)', overflowY: 'auto' }}
            >
                <div className="container-fluid" style={{ minHeight: '100%' }}>
                    <div className="wc-page-header" style={{ paddingRight: 0 }}>
                        <h1 className="wc-page-header__title">
                            Đơn yêu cầu nhập hàng
                        </h1>
                        
                        {/* <div className="wc-page-header__search wclinic-search">
                            <ProviderPurchaseOrderQuotation.Consumer>
                                {({ openModalCreate }) => (
                                    <Button
                                        onClick={() => openModalCreate()}
                                        icon={<AppstoreAddOutlined />}
                                    >
                                        Tạo yêu cầu{' '}
                                    </Button>
                                )}
                            </ProviderPurchaseOrderQuotation.Consumer>
                            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_PRODUCT_RETURN}/>
                        </div> */}
                    </div>
                    <Row gutter={12}>
                        <Col span={6}>
                            <Search
                                showSearch
                                placeholder='Tìm bất kỳ'
                                onSearch={(e) => {
                                    console.log(e)
                                    onParamChange({ keyword: e })
                                }}
                                allowClear
                                style={{marginBottom:10}}
                            />
                        </Col>
                        <Col span={14}>
                            <SelectSearchStatus
                                handleChangeStatus={handleChangeStatus}
                                style={{width:'max-content'}}
                            />
                        </Col>
                    </Row>
                    <TablePurchaseOrder />
                </div>
            </div>
        </ContextProvider>
    );
}

export default PurchaseOrderQuotation;
