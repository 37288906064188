import * as Types from '~/constants/actionTypes';

export const getProcessesImport = query => ({
  type: Types.GET_PROCESSES_IMPORT_REQUEST,
  payload: query
});

export const getProcessImport = id => ({
  type: Types.GET_PROCESS_IMPORT_REQUEST,
  payload: id
});

export const createProcessImport = processImport => ({
  type: Types.CREATE_PROCESS_IMPORT_REQUEST,
  payload: processImport
});

export const updateProcessImport = processImport => ({
  type: Types.UPDATE_PROCESS_IMPORT_REQUEST,
  payload: processImport
});

export const deleteProcessImport = id => ({
  type: Types.DELETE_PROCESS_IMPORT_REQUEST,
  payload: id
});

// export const fetchEventProcessImport = (payload) => ({
//   type: Types.FETCH_EVENT_PROCESS_IMPORT,
//   payload: payload
// })

export const createEventProcessImport = (payload) => ({
  type: Types.CREATE_EVENT_PROCESS_IMPORT,
  payload: payload
})

export const updateEventProcessImport = (payload) => ({
  type: Types.UPDATE_EVENT_PROCESS_IMPORT,
  payload: payload
})

export const resetProcessImportState = () => ({
  type: Types.RESET_PROCESS_IMPORT_STATE
});
