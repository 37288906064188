import * as Types from '~/constants/actionTypes';

export const getProductInvoices = query => ({
  type: Types.GET_PRODUCT_INVOICES_REQUEST,
  payload: query
});

export const getProductInvoice = id => ({
  type: Types.GET_PRODUCT_INVOICE_REQUEST,
  payload: id
});

export const createProductInvoice = invoice => ({
  type: Types.CREATE_PRODUCT_INVOICE_REQUEST,
  payload: invoice
});

export const updateProductInvoice = invoice => ({
  type: Types.UPDATE_PRODUCT_INVOICE_REQUEST,
  payload: invoice
});

export const deleteProductInvoice = id => ({
  type: Types.DELETE_PRODUCT_INVOICE_REQUEST,
  payload: id
});

export const resetProductInvoiceState = () => ({
  type: Types.RESET_PRODUCT_INVOICE_STATE
});
