import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useFetch
} from '~/hooks/utils';

import {
  getMedicineGroups,
  createMedicineGroup,
  deleteMedicineGroup,
  getMedicineGroup,
  resetMedicineGroupState,
  updateMedicineGroup,
  getMedicineGroupOptions
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS } from '~/constants/defaultValue';
import { get } from 'lodash';

const MEDICINE_GROUP_MODULE = 'medicineGroup';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(MEDICINE_GROUP_MODULE);

const getSelector = key => state => state[MEDICINE_GROUP_MODULE][key];

const optionsSelector = getSelector('options');
const getOptionsLoadingSelector = getSelector('isGetOptionsLoading');
const getOptionsFailedSelector = getSelector('getOptionsFailed');

export const useMedicineGroups = query => {
  return useFetchByParam({
    action: getMedicineGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useMedicineGroupOptions = () => {
  return useFetch({
    action: getMedicineGroupOptions,
    loadingSelector: getOptionsLoadingSelector,
    dataSelector: optionsSelector,
    failedSelector: getOptionsFailedSelector
  });
};

export const useCreateMedicineGroup = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới nhóm thuốc thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới nhóm thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicineGroup
  });
};

export const useUpdateMedicineGroup = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật nhóm thuốc thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật nhóm thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicineGroup
  });
};

export const useDeleteMedicineGroup = onDeleteSuccess => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá nhóm thuốc thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector, 'Xoá nhóm thuốc thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteMedicineGroup
  });
};

export const useMedicineGroup = params => {
  return useFetchByParam({
    action: getMedicineGroup,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitMedicineGroup = (medicineGroup, id) => {
  return useMemo(() => {
    if (!medicineGroup || !id) {
      return {
        status: true
      };
    }

    return {
      ...medicineGroup,
      status: medicineGroup.status === STATUS.ACTIVE ? true : false
    };
  }, [medicineGroup, id]);
};

export const useUpdateMedicineGroupParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    history.push({
      pathname: '/medicine-group',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useMedicineGroupQueryParams = (defaultLimit) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || defaultLimit || 10;
  const name = query.get('name');
  const page = query.get('page');
  const status = query.get('status');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== name) {
    prevKeyword.current = name;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      status,
      name
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, name, createSuccess, updateSuccess, deleteSuccess, status]);
};

export const useMedicineGroupPaging = () => useSelector(pagingSelector);

export const useResetMedicineGroup = () => {
  useResetState(resetMedicineGroupState);
};
/**
 * 
 * @param {Array} data 
 * @returns 
 */

export const filterActive = (data) => data?.map(item => {
  if(get(item,'status') === STATUS.INACTIVE){
    return {...item,disabled : true}
  }
  return item
})
export const SEARCH_MEDICINE_GROUP = [
  {
      name: "status",
      type: "radio",
      label: "Trạng thái",
  },
]