import * as Types from '~/constants/actionTypes';

export const getPartners = (query) => ({
  type: Types.GET_PARTNERS_REQUEST,
  payload: query
});

export const getPartner = (id) => ({
  type: Types.GET_PARTNER_REQUEST,
  payload: id
});

export const createPartner = (employee) => ({
  type: Types.CREATE_PARTNER_REQUEST,
  payload: employee
});

export const updatePartner = (employee) => ({
  type: Types.UPDATE_PARTNER_REQUEST,
  payload: employee
});

export const deletePartner = (id) => ({
  type: Types.DELETE_PARTNER_REQUEST,
  payload: id
});

export const getPartnerBranches = ({ id }) => ({
  type: Types.GET_PARTNER_BRANCHES_REQUEST,
  payload: id
});

export const resetPartnerState = () => ({
  type: Types.RESET_PARTNER_STATE
});

export const updateApiKeyPartner = (query) => ({
  type: Types.UPDATE_API_KEY_PARTNER_SAVE_REQUEST,
  payload: query
});