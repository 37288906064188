import produce from 'immer';
import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILED,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILED,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILED,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILED,
  RESET_EMPLOYEE_STATE,
  RESET_STORE
} from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_EMPLOYEES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case GET_EMPLOYEES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case GET_EMPLOYEES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case GET_EMPLOYEE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case GET_EMPLOYEE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case GET_EMPLOYEE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case DELETE_EMPLOYEE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_EMPLOYEE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_EMPLOYEE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_EMPLOYEE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_EMPLOYEE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_EMPLOYEE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_EMPLOYEE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_EMPLOYEE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      return;

    case UPDATE_EMPLOYEE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case RESET_EMPLOYEE_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
