import React from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 500,
  labelKey = 'name',
  valueKey = 'id',
  searchKey = 'keyword',
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions({ [searchKey]: value || '' }).then(response => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        const newOptions = response.map(option => ({
          label: option[labelKey],
          value: option[valueKey]
        }));
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
    //SHOULD RECHECK
    //eslint-disable-next-line
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      onFocus={() => debounceFetcher()}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default DebounceSelect;
