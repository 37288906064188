import { omit } from 'lodash';
import request from './requester';

const batch = {
  getAll: productId => request.get('/batch', { productId }), //server need propertId
  create: batch => request.post('/batch', batch),
  update: (data) => request.put(`/batch/${data.id}`, omit(data, ['_id'])),
};

export default batch;
