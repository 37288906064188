import requester from '../requester';

const staff = {
  getStaffs: query => requester.get('/staff', query),
  getById: id => requester.get(`/staff/${id}?raw=true`),
  delete: id => requester.delete(`/staff/${id}`),
  create: staff => requester.post('/staff', staff),
  update: staff => requester.put(`/staff/${staff.id}`, staff)
};

export default staff;
