import React, { createContext, useContext } from 'react';
import ButtonRequest from './Button';
import TableDrugRequest from './Table';


const DrugProvider = createContext({});
export const useDrugValue = ()=>{return useContext(DrugProvider)};
function DrugRequestLogs({product}) {
    return (
        <div className='medicine-detail-tab-item medicine-remain-tab'>
            <DrugProvider.Provider value={{product}}>
                <TableDrugRequest/>
                <ButtonRequest/>
            </DrugProvider.Provider>
        </div>
    )
}

export default DrugRequestLogs
