import React, { useEffect, useState } from 'react';
import {
  usePartners,
  useUpdatePartner,
  useDeletePartner,
  usePartnerQueryParams,
  usePartnerPaging,
  useUpdatePartnerParams,
  useResetPartner,
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Switch} from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import FilterModules from '~/components/Common/FilterModules';
import PartnerForm from './Form';
import ListBranches from './Detail/Table/ListBranches';
import { onSelectRow } from '~/utils/validators';
// import Form from '../Appointment/Form';

const { Search } = Input;

const ColumnActions = ({
  _id,
  deletePartner,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_PARTNER}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}

      <WithPermission permission={POLICY.DELETE_PARTNER}>
        <Popconfirm
          title="Bạn muốn xoá đối tác này?"
          onConfirm={() => deletePartner(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};


const ColumnStatus = ({
  _id,
  status,
  isSubmitLoading,
  updatePartner,
  ...restProps
}) => {
  const [isChecked, setIsChecked] = useState(status === 'ACTIVE');
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_PARTNER}>
        <Switch
          checked={isChecked}
          onChange={value => {
            setIsChecked(!isChecked);
            updatePartner({ status: value ? 'ACTIVE' : 'INACTIVE', _id });
          }}
        />
      </WithPermission>
    </div>
  );
};

const Partners = () => {
  const [query] = usePartnerQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdatePartnerParams(
    query
  );
  const [partners, isLoading] = usePartners(query);
  const [partnerId, setPartnerId] = useState(null);

  const [selectTable, setSelectTable] = useState(null)

  const [isOpenForm, setIsOpenForm] = useState(false);
  const paging = usePartnerPaging();

  const hasUpdate = useMatchPolicy(POLICY.UPDATE_PARTNER);
  const hasDelete = useMatchPolicy(POLICY.DELETE_PARTNER);
  const shouldShowDevider = hasUpdate && hasDelete;

  const numberOfPartners = partners.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfPartners === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deletePartner] = useDeletePartner(onDeleteSuccess);
  const [, updatePartner] = useUpdatePartner();

  useEffect(() => {
    if (query.searchBranch) {
      setSelectTable(partners.map((item)=> String(item._id)))
  } 
  },[query, partners])

  useResetPartner();

  const onOpenForm = (id) => {
    setPartnerId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setPartnerId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên Đối tác',
      key: 'partnerName',
      render: (rc) => <Link to={`/partner/detail/${rc._id}`}>{rc.name}</Link>
    },
    // {
    //   title: 'Loại',
    //   key: 'modules',
    //   render: (rc) => {
    //     return Object.values(rc.modules).join(', ');
    //   }
    // },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>
          {address &&
            `${address?.street} - ${address?.ward} - ${address?.district} - ${address?.city}`}
        </p>
      )
    },

    {
      title: 'Điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Trạng thái',
      key: 'status',
      align: 'center',
      render: record => {
        return <ColumnStatus {...record} updatePartner={updatePartner} />;
      }
    }
  ];

  if (hasUpdate || hasDelete) {
    columns.push({
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions
            {...record}
            deletePartner={deletePartner}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
          />
        );
      }
    });
  }
  const expandedRowRender = customer => {
    return (
      <div className="card-container expanded-table">
        {/* <Tabs type="card" tabPosition='left'>
          <TabPane tab="Danh sách chi nhánh" key="1"> */}
            <ListBranches
              customer = {customer}
            />
            {/* </TabPane>
        </Tabs> */}
      </div>
    );
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách đối tác" />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <FilterCity
              value={query.cityId}
              onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />

            {/* <FilterModules
              value={query?.modules?.split(',')}
              onChange={(value) => onParamChange({ modules: value })}
            /> */}
            <Search
                // style={{ width: 250 }}
                placeholder="Tìm chi nhánh"
                allowClear
                enterButton
                onSearch={(e) => onParamChange({searchBranch : e})}
                // onChange={(e) => setKeyword(e.target.value)}
                // value={keyword}
              />
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <Search
                allowClear
                style={{ width: 300 }}
                placeholder="Tìm đối tác"
                enterButton
                onSearch={() => onParamChange({ keyword })}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />

              <WithPermission permission={POLICY.WRITE_PARTNER}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm()}
                >
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable
                columns={columns}
                rowCount={partners.length}
                pagination={paging}
              />
            ) : (
              <Table
                className='permission-table'
                rowKey={(rc) => String(rc._id)}
                columns={columns}
                dataSource={partners}
                onChange={({ current }) => onParamChange({ page: current })}
                onRow={onSelectRow(setSelectTable)}
                onExpandedRowsChange={(e) => setSelectTable(e?.at(-1))}
                expandable={
                    {
                  expandedRowRender,
                  expandedRowKeys: [selectTable].flat()
                }}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <PartnerForm isOpen={isOpenForm} onClose={onCloseForm} id={partnerId} handleUpdate={updatePartner}/>
    </div>
  );
};

export default Partners;
