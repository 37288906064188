import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Row, Col, Select, Button, Skeleton, Modal } from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import {
  useCreateUserEmployee,
  useUserEmployee,
  useInitUserEmployee,
  useResetUserEmployee,
  useEmployeeGroups,
  useEmployees,
  useCreateUsername
} from '~/hooks';
import './form.scss';
import { filterAcrossAccents } from '~/hooks/utils';
import { useParams } from 'react-router-dom';
import AddressFormSection from '~/components/Common/AddressFormSection';
import AccountFormSection from './Account';
import { Children } from 'react';
import { cloneElement } from 'react';
import toastr from 'toastr';
import { removeAccents } from '~/utils/helper';

const { Option } = Select;

const FormItem = Form.Item;


const UserEmployeeForm = ({ isOpen, onClose, id, handleUpdate, callback }) => {
  const { id: _id, branchId } = useParams();
  const [form] = Form.useForm();
  const [logo, setLogo] = useState();

  const [isSubmitLoading, handleCreate] = useCreateUserEmployee();

  const [userDetail, isGetUserEmployeeLoading] = useUserEmployee(id);
  const initUserEmployee = useInitUserEmployee(userDetail, id);

  const branchIdParam = useMemo(
    () => ({ branchId: branchId ?? _id, limit: 10000 }),
    [branchId, _id]
  );
  const [employeeGroups, isEmployeeGroupsLoading] =
    useEmployeeGroups(branchIdParam);

  const [employess, isEmployeesLoading] = useEmployees(branchIdParam);

  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  const [, autoUsername] = useCreateUsername();
  const [loadingValidateUsername, setLoadingValidateUsername] = useState(false);

  useEffect(() => {
    form.resetFields();
    const { avatar, address } = initUserEmployee;

    if (avatar) {
      setLogo(avatar);
    }

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initUserEmployee, form]);

  useResetUserEmployee();

  const onFinish = values => {
    const { password } = values;
    const user = {
      ...values,
      username: values?.username?.trim(),
      ...(password && { password: values?.password?.trim() }),
      avatar: logo,
      branchId: branchIdParam.branchId
    };

    if (id) {
      handleUpdate({
        ...user,
        _id: id,
        userNumber: initUserEmployee?.userNumber
      });
    } else {
      handleCreate(user);
    }
    onClose();
  };

  const onFocusOutFullName = async () => {
    const fullName = form.getFieldValue('fullName');
    if (!id && fullName) {
      // Only Create
      try {
        setLoadingValidateUsername(true);
        const callB = username => {
          form.setFieldsValue({ username });
          setLoadingValidateUsername(false);
        };
        await autoUsername({ data: fullName, callback: callB });
      } catch (error) {
        setLoadingValidateUsername(false);
        toastr.error('Lỗi khi lấy dữ liệu từ máy chủ');
      }
    }
  };

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }

    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }
  };

  const isLoading = isGetUserEmployeeLoading;

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="user-form">
        <h4 style={{ marginRight: 'auto', paddingLeft: 27 }}>
          {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} nhân viên
        </h4>

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          initialValues={initUserEmployee}
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="user-form__logo-row"
          >
            <Col span={12}>
              <Row gutter={36}>
                <Col span={24}>
                  <FormItem
                    label="Tên nhân viên"
                    name="fullName"
                    rules={[
                      { required: true, message: 'Xin mời nhập tên nhân viên!' }
                    ]}
                  >
                    {isLoading || loadingValidateUsername ? (
                      <Skeleton.Input active />
                    ) : (
                      <Input onBlur={onFocusOutFullName} />
                    )}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Giới tính" name="gender">
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select>
                        <Option value="M" key="male">
                          Nam
                        </Option>
                        <Option value="F" key="female">
                          Nữ
                        </Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Mô tả ngắn" name="description">
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                  <FormItem name="status" hidden>
                    {isLoading ? <Skeleton.Input active defaultValue={'ACTIVE'} /> : <Input />}
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="user-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Nhân viên" name="employeeNumber">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isEmployeesLoading}
                    showSearch
                    allowClear
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    // rules={[
                    //   { required: true, message: 'Xin mời chọn nhân viên!' }
                    // ]}
                  >
                    {employess.map(({ employeeNumber, fullName }) => (
                      <Select.Option
                        value={employeeNumber}
                        key={employeeNumber}
                      >
                        {fullName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              {' '}
              <FormItem
                label="Nhóm người dùng"
                name="groups"
                rules={[
                  {
                    required: true,
                    message: 'Xin mời chọn nhóm người dùng!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    loading={isEmployeeGroupsLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {employeeGroups.map(({ _id, name }) => (
                      <Select.Option value={_id} key={_id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <AccountFormSection
            isLoading={isLoading}
            required={id ? false : true}
          />

          <Row className="user-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default UserEmployeeForm;
