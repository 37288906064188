import React from 'react';
import { Button, Form, Modal, Row, Select } from 'antd';

import {
  useSearchReceiversBox,
} from '~/hooks';

const { Option } = Select

const ModalUpdate = ({onClose, isOpen, selectedIds,updateProductInfoReturn,isUpdatingProductInfoReturn}) => {
  // const [
  //   isUpdatingProductInfoReturn,
  //   updateProductInfoReturn
  // ] = useUpdateProductInfoReturn();

  const [form] = Form.useForm();

  const [
    { receivers, inputEl },
    { onSearch }
  ] = useSearchReceiversBox();


  const onFinish = (value) => {
    updateProductInfoReturn({
      ids: selectedIds,
      saleChannel: value.saleChannel,
      note: value.note,
      receivedById: value.receivedById
    })
  }

  const handleOnSearch = (value) => {
    onSearch({
      keyword: value
    })
  }

  const handleSelectReceiver = (value) => {
    form.setFieldsValue({
      receivedById: value,
    });
  }

  const handleSelectChannel = (value) => {
    form.setFieldsValue({
      saleChannel: value,
    });
  }

  return (
    <Modal
      title="Cập nhật danh sách phiếu trả hàng"
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal form-modal__return"
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item name="receivedById">
          <Row>
            <label className={'label-update'}>Người nhận trả</label>
            <div className='form-control_cs'>
              <Select
                placeholder="Chọn người nhận trả"
                ref={inputEl}
                onSearch={handleOnSearch}
                onChange={handleSelectReceiver}
                allowClear
                showSearch
              >
                {receivers?.length > 0 && receivers.map(({ _id, fullName }) => (
                  <Option key={_id} value={_id}>
                    {fullName}
                  </Option>
                ))}
              </Select>
            </div>
          </Row>
        </Form.Item>
        <Form.Item name="saleChannel">
          <Row>
            <label className={'label-update'}>Kênh bán</label>
            <div className='form-control_cs'>
              <Select
                placeholder="Chọn kênh bán"
                onChange={handleSelectChannel}
                allowClear
              >
                <Option value="DIRECT">
                  Bán trực tiếp
                </Option>
                <Option value="ORDER">
                  Đặt hàng
                </Option>
              </Select>
            </div>
          </Row>
        </Form.Item>
        <Form.Item name="note">
          <Row>
            <label className={'label-update'}>Ghi chú</label>
            <div className='form-control_cs'>
              <textarea rows={3} maxLength={4000}/>
            </div>
          </Row>
        </Form.Item>
        <Row
          className="wc-setting-form__submit-box"
          style={{ marginTop: 10 }}
        >
          <Button type="primary" htmlType="submit" loading={isUpdatingProductInfoReturn}>Lưu</Button>
          <Button onClick={onClose}>Bỏ qua</Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalUpdate;
