import medicineGroup from './medicineGroup';
import medicineMethod from './medicineMethod';
import medicineLocation from './medicineLocation';
import medicineUnit from './medicineUnit';
import supplier from './supplier';
import supplierGroup from './suplierGroup';
import medicine from './medicine';
import warehouse from './warehouse';
import productOrder from './productOrder';
import productInvoice from './productInvoice';
import cashFlow from './cashFlow';
import customer from './customer';
import manufacture from './manufacture';
import batch from './batch';
import sale from './sale';
import purchaseReturn from './purchaseReturn';
import purchaseOrderQuotation from './purchaseOrderQuotation';
import damage from './damage';
import productReturn from './productReturn';
import productReport from './productReport';
import stockTake from './stocktake';
import samplePrescription from './samplePrescription';
import medicineDirectory from './medicineDirectory';
import notificationService from './notificationService';
import formPrint from './formPrint';
import informationPayment from './informationPayment';
import importFileMedicine from './importFileMedicine';
import receiptPaymentType from './receiptPaymentType';
import processImport from './processImport';
import myNotification from './myNotification';

const worldClinicApi = {
  medicineGroup,
  medicineMethod,
  medicineLocation,
  medicineUnit,
  supplier,
  supplierGroup,
  medicine,
  warehouse,
  productOrder,
  productInvoice,
  cashFlow,
  customer,
  manufacture,
  batch,
  sale,
  purchaseReturn,
  purchaseOrderQuotation,
  productReturn,
  productReport,
  damage,
  stockTake,
  samplePrescription,
  medicineDirectory,
  notificationService,
  formPrint,
  informationPayment,
  importFileMedicine,
  receiptPaymentType,
  processImport,
  myNotification,
};

export default worldClinicApi;
