export const GET_USER_EMPLOYEES_REQUEST = 'GET_USER_EMPLOYEES_REQUEST';
export const GET_USER_EMPLOYEES_SUCCESS = 'GET_USER_EMPLOYEES_SUCCESS';
export const GET_USER_EMPLOYEES_FAILED = 'GET_USER_EMPLOYEES_FAILED';

export const GET_USER_EMPLOYEE_REQUEST = 'GET_USER_EMPLOYEE_REQUEST';
export const GET_USER_EMPLOYEE_SUCCESS = 'GET_USER_EMPLOYEE_SUCCESS';
export const GET_USER_EMPLOYEE_FAILED = 'GET_USER_EMPLOYEE_FAILED';

export const CREATE_USER_EMPLOYEE_REQUEST = 'CREATE_USER_EMPLOYEE_REQUEST';
export const CREATE_USER_EMPLOYEE_SUCCESS = 'CREATE_USER_EMPLOYEE_SUCCESS';
export const CREATE_USER_EMPLOYEE_FAILED = 'CREATE_USER_EMPLOYEE_FAILED';

export const UPDATE_USER_EMPLOYEE_REQUEST = 'UPDATE_USER_EMPLOYEE_REQUEST';
export const UPDATE_USER_EMPLOYEE_SUCCESS = 'UPDATE_USER_EMPLOYEE_SUCCESS';
export const UPDATE_USER_EMPLOYEE_FAILED = 'UPDATE_USER_EMPLOYEE_FAILED';

export const DELETE_USER_EMPLOYEE_REQUEST = 'DELETE_USER_EMPLOYEE_REQUEST';
export const DELETE_USER_EMPLOYEE_SUCCESS = 'DELETE_USER_EMPLOYEE_SUCCESS';
export const DELETE_USER_EMPLOYEE_FAILED = 'DELETE_USER_EMPLOYEE_FAILED';

export const CREATE_USERNAME_REQUEST = 'CREATE_USERNAME_REQUEST';
export const CREATE_USERNAME_SUCCESS = 'CREATE_USERNAME_SUCCESS';
export const CREATE_USERNAME_FAILED = 'CREATE_USERNAME_FAILED';

export const RESET_USER_EMPLOYEE_STATE = 'RESET_USER_EMPLOYEE_STATE';
