import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME_EN,
  ACTIVITY_NAME
} from '~/constants/defaultValue';
function* getPurchaseReturns({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const purchaseReturn = yield call(Api.purchaseReturn.getById, query.id);
      data = { docs: [purchaseReturn], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.purchaseReturn.getAll, query);
    }

    yield put({ type: Types.GET_PURCHASE_RETURNS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PURCHASE_RETURNS_FAILED,
      payload: error
    });
  }
}

function* getPurchaseReturn({ payload }) {
  try {
    const purchaseReturn = yield call(Api.purchaseReturn.getById, payload);
    yield put({
      type: Types.GET_PURCHASE_RETURN_SUCCESS,
      payload: purchaseReturn
    });
  } catch (error) {
    yield put({
      type: Types.GET_PURCHASE_RETURN_FAILED,
      payload: error
    });
  }
}

function* createPurchaseReturn(action) {
  try {
    const data = yield call(Api.purchaseReturn.create, action.payload);
    yield put({ type: Types.CREATE_PURCHASE_RETURN_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PURCHASE_RETURN_FAILED,
      payload: error
    });
  }
}
function* createPurchaseReturnSuccessAndCreateActivity(action) {
  try {
    const { createdBy, branchId, totalSupplierPayment } = action.payload;
    const message = `${createdBy.fullName} ${MESSAGE_ACTIVITY.PURCHASE_RETURN} ${totalSupplierPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy, message , action: ACTIVITY_NAME.PURCHASE_RETURN},
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
  } catch (error) {
    console.log(error);
  }
}
function* updatePurchaseReturn(action) {
  try {
    const data = yield call(Api.purchaseReturn.update, action.payload);
    yield put({ type: Types.UPDATE_PURCHASE_RETURN_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PURCHASE_RETURN_FAILED,
      payload: error
    });
  }
}

function* deletePurchaseReturn({ payload }) {
  try {
    yield call(Api.purchaseReturn.delete, payload);
    yield put({ type: Types.DELETE_PURCHASE_RETURN_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PURCHASE_RETURN_FAILED,
      payload: error
    });
  }
}

export default function* purchaseReturn() {
  yield takeLatest(Types.GET_PURCHASE_RETURNS_REQUEST, getPurchaseReturns);
  yield takeLatest(Types.GET_PURCHASE_RETURN_REQUEST, getPurchaseReturn);
  yield takeLatest(Types.DELETE_PURCHASE_RETURN_REQUEST, deletePurchaseReturn);
  yield takeLatest(Types.CREATE_PURCHASE_RETURN_REQUEST, createPurchaseReturn);
  yield takeLatest(Types.CREATE_PURCHASE_RETURN_SUCCESS, createPurchaseReturnSuccessAndCreateActivity);
  yield takeLatest(Types.UPDATE_PURCHASE_RETURN_REQUEST, updatePurchaseReturn);
}
