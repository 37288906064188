import React from 'react';
import { Button, AutoComplete, Spin } from 'antd';
import { useSearchProductBox } from '~/hooks';
import { MoreOutlined } from '@ant-design/icons';
import { formatNumber } from '~/utils/helper';
import { get } from 'lodash';

const SearchBox = ({ onSelect, children }) => {
  const [
    { products, isLoading, inputEl, searchText },
    { onSearch }
  ] = useSearchProductBox();

  const handleSelect = value => {
    inputEl.current.blur();
    onSelect?.(value);
  };
  const formatNumberFix = (params) =>{
    let numberRic = Number(params)
    let value =  Number.isInteger(numberRic) ? numberRic.toString() : numberRic.toFixed(1)
    return Number(value).toLocaleString('vi-VN')
  }

  return (
    <AutoComplete
      style={{ width: '100%' }}
      onSearch={onSearch}
      dropdownMatchSelectWidth={false}
      options={products}
      ref={inputEl}
      notFoundContent={<div className="sale-medicine-option"></div>}
      dropdownRender={() => {
        if (!products?.length) {
          return (
            <Spin spinning={isLoading}>
              <div className="sale-medicine-option">
                <p className="sale-medicine-option__detail">
                  {isLoading
                    ? 'Đang tìm thuốc'
                    : searchText
                    ? 'Không tìm thấy dữ liệu'
                    : 'Tìm kiếm thuốc'}
                </p>
              </div>
            </Spin>
          );
        }

        return (
          <Spin spinning={isLoading}>
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
              {products.map((product, index) => {
                const selectedVariants = product.relationVariants.find((variant) => variant?.productUnit?._id === product?.unit?._id)
                return (
                  <div
                    className="sale-medicine-option"
                    key={index}
                    onClick={() => handleSelect(product)}
                  >
                    <Button
                      className="sale-medicine-option__action"
                      shape="circle"
                      icon={<MoreOutlined />}
                    ></Button>

                    <div className="sale-medicine-option__info">
                      <p className="sale-medicine-option__name">
                        {product?.product?.name}{' '}
                        <span className="sale-medicine-option__unit">
                          {product?.unit?.name}
                        </span>
                      </p>
                      <p className="sale-medicine-option__code">
                        {product.code}
                      </p>
                      <p className="sale-medicine-option__detail">
                        <span>Tồn: {formatNumberFix(get(product,'availableQuantity',0) / get(selectedVariants,'exchangeValue',1)) }</span> | {' '}
                        <span>KH đặt: {formatNumberFix(get(product,'totalOrderQuantity',0) / get(selectedVariants,'exchangeValue',1)) }</span>
                      </p>
                    </div>

                    <div className="sale-medicine-option__price">
                      {formatNumber(product.price)}
                    </div>
                  </div>
                );
              })}
            </div>
          </Spin>
        );
      }}
    >
      {children}
    </AutoComplete>
  );
};

export default SearchBox;
