import requester from './requester';

const medicineLocation = {
  getAll: (query) => requester.get('/product-position', query),
  getById: id => requester.get(`/product-position/${id}`),
  create: position => requester.post(`/product-position`, position),
  update: position =>
    requester.put(`/product-position/${position._id}`, position),
  delete: id => requester.delete(`/product-position/${id}`)
};

export default medicineLocation;
