import React from 'react';
import { Form, Row, Col, Input, TimePicker, Switch } from 'antd';

const workingHourLabels = {
  0: 'Thứ 2',
  1: 'Thứ 3',
  2: 'Thứ 4',
  3: 'Thứ 5',
  4: 'Thứ 6',
  5: 'Thứ 7',
  6: 'Chủ nhật'
};

export const workingHours = ({ form }) => {
  return (
    <>
      {' '}
      <h4 className="hospital-form__working-hours-form-title">Giờ làm việc:</h4>
      <Form.List name="workingHours" shouldUpdate>
        {(workingHours) => (
          <>
            {workingHours.map(
              ({ key, name, fieldKey, ...restField }, index) => (
                <Row
                  key={key}
                  style={{ display: 'flex' }}
                  align="baseline"
                  gutter={48}
                >
                  <Col sm={4} lg={3}>
                    <Form.Item
                      label={workingHourLabels[index]}
                      className="hospital-form__working-hours-form-item"
                      labelCol={24}
                    >
                      <Input hidden />
                    </Form.Item>
                  </Col>

                  <Col sm={4} lg={3}>
                    <Form.Item
                      {...restField}
                      name={[name, 'isOpen']}
                      fieldKey={[fieldKey, 'isOpen']}
                      valuePropName="checked"
                      className="hospital-form__working-hours-form-item"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col sm={8} lg={6}>
                    <Form.Item shouldUpdate noStyle>
                      {() => (
                        <Form.Item
                          {...restField}
                          name={[name, 'startTime']}
                          fieldKey={[fieldKey, 'startTime']}
                          className="hospital-form__working-hours-form-item"
                          shouldUpdate
                        >
                          <TimePicker
                            format="HH:mm"
                            placeholder="Giờ mở cửa"
                            disabled={
                              !form.getFieldValue('workingHours')?.[index]
                                ?.isOpen
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>

                  <Col sm={8} lg={6}>
                    <Form.Item shouldUpdate noStyle>
                      {() => (
                        <Form.Item
                          {...restField}
                          name={[name, 'endTime']}
                          fieldKey={[fieldKey, 'endTime']}
                          className="hospital-form__working-hours-form-item"
                        >
                          <TimePicker
                            disabled={
                              !form.getFieldValue('workingHours')?.[index]
                                ?.isOpen
                            }
                            format="HH:mm"
                            placeholder="Giở đóng cửa"
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )
            )}
          </>
        )}
      </Form.List>
    </>
  );
};

export default workingHours;
