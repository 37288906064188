import { Button, message, Modal, Space, Upload } from 'antd';
import { get } from 'lodash';
import { useState, useCallback } from 'react';
import importFileMedicine from '~/api/worldClinic/importFileMedicine';
import { BASE_URL, API_LIST } from '~/constants/defaultValue';
import { useExport } from '~/components/Common/ModuleExport';
import { has } from 'lodash';
import { PATH_CLINIC } from '~/routes/paths';
import { Link } from 'react-router-dom';

const FormImportFile = ({ isModalOpen, onClose, onModule, query }) => {
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState();
  const [disabled, setDisabled] = useState(true);
  const [complete, setComplete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { handleAddExport } = useExport();
  const handle = useCallback(() => {
    const tranFormQuery = () => {
      const newQuery = { ...query };
      if (has(newQuery, 'limit')) {
        delete newQuery['limit'];
      }
      if (has(newQuery, 'page')) {
        delete newQuery['page'];
      }
      return newQuery;
    };

    handleAddExport({
      query: { ...tranFormQuery(), exportOption: 5 },
      router: API_LIST.product,
      fileName: 'Danhsachmau'
    });
  }, [query]);
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', fileList[0]?.originFileObj);

    if (!fileList[0]) {
      message.destroy();
      message.error('Chưa có file để thực hiện thao tác');
      return;
    }
    setUploading(true);
    const response = await importFileMedicine.postFile(formData);
    switch (response?.message) {
      case true:
        message.success('Thực hiện thành công');
        break;
      default:
        setErrors({ message: response.message, key: response.key });
        message.warning('File đã được tiếp nhận');
        break;
    }
    setTimeout(() => {
      setUploading(false);
      setComplete(true);
    }, 2000);
  };
  const onFileChange = async ({ file, fileList }) => {
    setUploading(true);
    if (file.status === 'removed') {
      setErrors(null);
      setDisabled(true);
      setFileList([]);
      setUploading(false);
      return;
    }
    if (file.status !== 'uploading') {
      if (Array.isArray(fileList)) {
        const newFileList = fileList.map(item => {
          if (!get(item, 'response')) return item;
          return {
            ...item,
            url: get(item, 'response.url')
          };
        });
        setFileList([...newFileList]);
      }
    } else {
      setFileList([...fileList]);
      setDisabled(false);
    }
    setUploading(false);
  };
  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      if (!newFileList.length) {
        setDisabled(true);
      }
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      setDisabled(false);
      return false;
    },
    fileList
  };

  return (
    <Modal
      width={700}
      height={600}
      visible={isModalOpen}
      onCancel={onClose}
      onOk={() => onModule(value => !value)}
      footer={[
        <Link to={PATH_CLINIC.processImport.root}>
          <Button
            key={'uploading'}
            hidden={!complete}
            type="primary"
            
          >
            {' '}
            Đến trang
          </Button>
        </Link>,
        <Button
          key={'import'}
          type="primary"
          disabled={disabled}
          loading={uploading}
          onClick={handleUpload}
          htmlType="button"
          style={{ marginLeft: '1rem' }}
        >
          Import
        </Button>,
        <Button
          key={'done'}
          hidden={!complete}
          type="primary"
          onClick={() => {
            setErrors(null);
            setDisabled(true);
            setFileList([]);
            onModule(value => !value);
          }}
          htmlType="button"
        >
          Huỷ
        </Button>
      ]}
    >
      <div className="container-fluid">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2rem'
          }}
        >
          <p style={{ fontSize: '22px', fontWeight: '500' }}>
            Nhập thuốc, hàng hoá từ file dữ liệu
          </p>
          <p>
            &nbsp; (Tải về file mẫu:
            <a
              target="_blank"
              style={{ color: '#1990ff' }}
              onClick={() => {
                handle();
              }}
            >
              {' '}
              Excel file
            </a>
            )
          </p>
        </div>

        <Upload
          {...props}
          fileList={fileList}
          style={{ width: '60%', color: '#1990ff' }}
          onChange={onFileChange}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          maxCount={1}
        >
          <Button
            key={'select'}
            type="primary"
            htmlType="submit"
            // style={{ marginRight: '5px' }}
          >
            Chọn file dữ liệu
          </Button>
          <div style={{ marginTop: '5px', marginBottom: '1rem' }}>
            <i>
              *Hệ thống cho phép nhập tối đa <b>2000 loại thuốc, hàng hoá</b>{' '}
              mỗi lần từ file
            </i>
          </div>
        </Upload>
        <div style={{ marginTop: '10px', display: 'block' }}>
          {errors?.key && (
            <>
              <Space style={{ color: 'red' }}>{errors?.message} </Space>
              <Link to={PATH_CLINIC.processImport.root} />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default FormImportFile;
