import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Image, Modal, Upload,message} from 'antd';
import React, { useEffect, useState } from 'react';
import { BASE_URL_UPLOAD_IMAGE } from '~/constants/defaultValue';
import {  useUser } from '~/hooks';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      message.error('Bạn chỉ có thể tải lên ảnh JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      message.error('Hình ảnh phải nhỏ hơn 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  
const UpLoadListImage = (props) => {
  const { fileList, setFileList,title,onChange,action = BASE_URL_UPLOAD_IMAGE , disabled = false} = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [, setPreviewTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [, token] = useUser();
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.response?.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handelRemove = (file) => {
    const newFileList = fileList.filter((item) => item.url !== file.url);
    setFileList(newFileList);
  };
  const handleChange = ({ fileList, file }) => {
     let newFileList = fileList?.filter((item) => (item.size / 1024 / 1024) < 2);
      newFileList = newFileList?.filter((item) => item.type === 'image/jpeg' || item.type === 'image/png');
    setFileList(newFileList);  
  };
  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{title || 'Logo'}</div>
    </div>
  );
  return (
    <>
      <Upload
        action={action}
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        fileList={fileList}
        headers={{Authorization: 'Bearer '+ token}}
        onPreview={handlePreview }
        onChange={handleChange}
        beforeUpload={beforeUpload}
        handleCancel={handleCancel}
        onRemove={handelRemove}
        
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
       {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
            overflow: 'hidden',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
          
        />
      )}
    </>
  );
};
export default UpLoadListImage;