import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete, Input, Spin, Row, Button } from 'antd';
import { useUserEmployees } from '~/hooks';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import CustomerForm from '~/components/WorldClinic/Customer/Form';

let timeOut = null;

const SelectReceiver = ({ customer, setCustomer, canUpdate,form }) => {
  const [search, setSearch] = useState('');
  const [userEmployees, isLoading] = useUserEmployees({
    fullname: search
  });
  const inputEl = useRef(null);
  const [isCustomerFormOpen, setCustomerFormOpen] = useState(false);

  const createCustomerCallback = customer => {
    setCustomer(customer);
  };

  useEffect(() => {
    return () => {
      timeOut = null;
    };
  }, []);

  useEffect(() => {
    setSearch('');
  }, [customer]);

  const onSearch = value => {
    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      if (timeOut) {
        setSearch(value);
      }
    }, );
  };

  const handleSelect = value => {
    inputEl.current.blur();
    setCustomer(value);
  };

  return (
    <div style={{ width: '100%' }}>
      <AutoComplete
        disabled={customer}
        onSearch={onSearch}
        // onChange={val => onSelect(val)}
        dropdownMatchSelectWidth={false}
        options={userEmployees}
        ref={inputEl}
        value={customer?.name || search} 
        className="sale-customer-input"
        notFoundContent={<div className="sale-customer-option"></div>}
        dropdownRender={() => {
          if (!userEmployees?.length) {
            return (
              <Spin spinning={isLoading}>
                <div className="sale-customer-option">
                  <p className="sale-customer-option__detail">
                    {isLoading
                      ? 'Đang tìm người nhận'
                      : search
                        ? 'Không tìm thấy dữ liệu'
                        : 'Tìm kiếm người nhận'}
                  </p>
                </div>
              </Spin>
            );
          }
          return userEmployees.map((option, index) => {
            return (
              <div
                className="sale-customer-option"
                key={index}
                onClick={() => {
                  handleSelect(option)
                  form.setFieldsValue({"customer":option})
                }}
              >
                <div className="sale-customer-option__info">
                  <p className="sale-customer-option__name">
                    {option.fullName}
                  </p>
                </div>

                <div className="sale-customer-option__phone-number">
                  {option.phoneNumber}
                </div>
              </div>
            );
          });
        }}
      >
        <Input
          bordered={false}
          className="warehouse-form__custom-input "
          placeholder="Tìm người nhận"
          prefix={<SearchOutlined style={{ color: '#ced4d9' }} />}
          suffix={
            <Row
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {customer && canUpdate && (
                <Button
                  size="small"
                  danger
                  style={{ height: 30 }}
                  onClick={() =>{ 
                    setCustomer()
                    form.setFieldsValue({"customer":null})
                  }}
                >
                  <CloseOutlined size="small" />
                </Button>
              )}

              {/*{canUpdate && (*/}
              {/*  <Button*/}
              {/*    size="small"*/}
              {/*    style={{ height: 30, marginLeft: 5 }}*/}
              {/*    onClick={() => setCustomerFormOpen(true)}*/}
              {/*  >*/}
              {/*    <PlusOutlined size="small" />*/}
              {/*  </Button>*/}
              {/*)}*/}
            </Row>
          }
        />
      </AutoComplete>
      <CustomerForm
        isOpen={isCustomerFormOpen}
        onClose={() => setCustomerFormOpen(false)}
        callback={createCustomerCallback}
      />
    </div>
  );
};

export default SelectReceiver;
