import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getDamages,
  createDamage,
  deleteDamage,
  getDamage,
  resetDamageState,
  updateDamage
} from '~/redux/action';
import { Menu, Checkbox, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { formatNumber } from '~/utils/helper';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import { get, keyBy } from 'lodash';
import { PATH_CLINIC } from '~/routes/paths';

const DAMAGE_MODULE = 'damage';

const CREATE_SUCCESS_MESS = 'Tạo mới đơn xuất huỷ thành công';
const CREATE_FAILED_MESS = 'Tạo mới đơn xuất huỷ thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật đơn xuất huỷ thành công';
const UPDATE_FAILED_MESS = 'Cập nhật đơn xuất huỷ thất bại';
const DELETE_SUCCESS_MESS = 'Huỷ đơn xuất huỷ thành công';
const DELETE_FAILED_MESS = 'Huỷ đơn xuất huỷ thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(DAMAGE_MODULE);

export const useDamages = query => {
  return useFetchByParam({
    action: getDamages,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateDamage = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createDamage
  });
};

export const useUpdateDamage = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateDamage
  });
};

export const useDeleteDamage = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteDamage
  });
};

export const useDamage = params => {
  return useFetchByParam({
    action: getDamage,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitDamage = ({ damage, updatingId, setFormData }) => {
  return useEffect(() => {
    if (!damage || !updatingId) {
      return {};
    }

    const onInitMultipleVariant = detailItems => {
      const addingVariants = detailItems.map(variant => {
        const { fullBatches } = variant;

        const batchDict = keyBy(fullBatches, '_id');
        const variantBatches = [variant.batch];

        const selectedBatches = variantBatches.map(batch => ({
          ...batch,
          batchId: batch._id,
          lotNumber: batchDict[batch._id]?.lotNumber,
          expirationDate: batchDict[batch._id]?.expirationDate,
          manufacturingDate: batchDict[batch._id]?.manufacturingDate,
          quantity: variant.quantity
        }));

        return {
          ...variant,
          _id: variant.variantId || variant._id,
          selectedBatches: selectedBatches,
          variantCode: variant.productVariant?.variantCode,
          batches: fullBatches,
          buyPrice: variant.cost
        };
      });

      setFormData(formData => [...addingVariants, ...formData]);
    };

    onInitMultipleVariant(damage.detailItems);
  }, [damage, updatingId, setFormData]);
};

export const useUpdateDamageParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: PATH_CLINIC.damage.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          id: undefined
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useDamageQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const lotNumber = query.get('lotNumber');
  const expirationDate = query.get('expirationDate');
  const variantCodeProductName = query.get('variantCodeProductName');
  const code = query.get('code');
  const processedAt = query.get('processedAt');
  const status = query.get('status');
  const page = query.get('page');
  const id = query.get('id');

  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      lotNumber,
      expirationDate,
      code,
      variantCodeProductName,
      processedAt,
      status,
      id,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page,
     limit,
     keyword, 
     deleteSuccess, 
     lotNumber,
     expirationDate,
     code,
     variantCodeProductName,
     processedAt,
     status,
     id]);
};

export const useDamagePaging = () => useSelector(pagingSelector);

export const useResetDamage = () => {
  useResetState(resetDamageState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã xuất huỷ',
    key: 'code'
  },
  {
    title: 'Giá trị huỷ',
    key: 'totalCost'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Chi nhánh',
    key: 'branch'
  },
  {
    title: 'Ghi chú',
    key: 'note'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  }
];

export const useDamageColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    totalCost: true,
    time: true,
    branch: true,
    note: true,
    status: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

export const useDamageColumns = (setFormData,handleSelect) => {
  const columns = useMemo(() => {
    const onDeleteVariant = variant => {
      setFormData(variants =>
        variants.filter(({ variantId }) => variantId !== variant.variantId)
      );
    };

    const onFieldChange = (fieldName, value, updatedIndex) => {
      setFormData(formData =>
        formData.map((item, index) => {
          return index === updatedIndex
            ? {
                ...item,
                [fieldName]: value,
                selectedBatches:
                  fieldName === 'quantity'
                    ? [{ ...item.selectedBatches[0], quantity: value }]
                    : item.selectedBatches
              }
            : item;
        })
      );
    };

    return [
      {
        title: '',
        key: 'delete',
        align: 'center',
        render: rc => (
          <Button
            onClick={() => onDeleteVariant(rc)}
            size="small"
            shape="circle"
            className="warehouse-form__delete-btn"
            icon={<DeleteOutlined />}
          />
        )
      },
      {
        title: 'STT',
        key: 'STT',
        align: 'center',
        render: (_, x, index) => index + 1
      },
      {
        title: 'Mã hàng',
        key: 'variantCode',
        dataIndex: 'variantCode',
        render: (_, x, index) => {
          return x?.selectedVariant?.variantCode || _
        }
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['product', 'name']
      },
      {
        title: 'ĐVT',
        key: 'unit',
        dataIndex: ['unit', 'name'],
        render: (_,product) => {
          if(!product?.relationVariants) {
            return (
              <div className="sale-table__unit">
                {get(product,'unit.name','')}
              </div>
            )
          }

          return (
            <div className="sale-table__unit">
              {product.relationVariants.length <= 1 ? (
                <span>
                  {`${product.unit?.name}`}
                </span>
              ) : (
                <Dropdown
                  overlay={
                    <Menu>
                      {get(product,'relationVariants',[])
                        .filter(
                          (variant) => variant?._id !== product?.selectedVariant?._id
                        )
                        .map((variant) => {
                          return (
                            <Menu.Item
                              onClick={() =>
                                handleSelect({
                                  productId: get(product,'productId'),
                                  variantId: variant?._id
                                })
                              }
                              style={{ minWidth: 120 }}
                              key={variant._id}
                            >
                              {get(variant,'productUnit.name','')}
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <span
                    className="product-table__selected-variant"
                    onClick={e => e.preventDefault()}
                  >
                    {product.selectedVariant?.productUnit?.name || product.relationVariants[0]?.productUnit?.name}
                    <DownOutlined />
                  </span>
                </Dropdown>
              )}
            </div>
          )
        }
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        render: ({ quantity, selectedBatches }, _, index) => {
          const quantityConvertUnit = selectedBatches[0]?.quantityConvertUnit
          return (
            <InputNumber
              size="small"
              className="warehouse-form__quantity"
              value={quantity > quantityConvertUnit ? quantityConvertUnit : quantity}
              onChange={value => onFieldChange('quantity', value, index)}
              bordered={false}
              disabled={selectedBatches.length !== 1 }
              min = {quantityConvertUnit >= 1 ? 1 : 0}
              max = {parseInt(quantityConvertUnit)}
            />
          )
        }
      },
      {
        title: 'Giá vốn',
        key: 'buyPrice',
        dataIndex:'buyPrice',
        render: (buyPrice,product) => {
          return formatNumber(buyPrice)
        }
      },
      {
        title: 'Giá trị huỷ',
        key: 'finalPrice',
        align: 'end',
        render: (_,product) => {
          return formatNumber(product.quantity * ( product.selectedVariant?.cost ?? product.price))
        }
      }
    ];
  }, [setFormData,handleSelect]);

  return columns;
};
export const SERACH_DAMAGE =[
  {
    name: "code",
    // label: "Mã kiểm kho",
    placeholder:"Theo mã xuất hủy"
},
{
    name: "variantCodeProductName",
    // label: "Ngày cân bằng",
    placeholder:"Theo mã, tên hàng"
},
{
    name: "lotNumber",
    placeholder:"Theo lô"
},
{
    name: "expirationDate",
    type: "date",
    label:"Hạn sử dụng",
},
{
    name: "processedAt",
    type: "rangerTime",
    label:"Thời gian",
},
{
    name: "status",
    type: "radio",
    label: "Trạng thái",
}  
]
