import React, { useEffect, useCallback } from 'react';
import { Form, Input, Row, Button, Modal, Col, Skeleton, Switch } from 'antd';
import {
  useCreateManufacture,
  useResetManufacture,
  useManufacture,
  useInitManufacturer
} from '~/hooks';
import { STATUS } from '~/constants/defaultValue';

const FormItem = Form.Item;

const ManufactureForm = ({ isOpen, onClose, _id, updateManufacture }) => {
  const [form] = Form.useForm();
  const [manufacture, isLoading] = useManufacture(_id);

  const [isSubmitLoading, handleCreate] = useCreateManufacture();
  const initManufacture = useInitManufacturer(manufacture, _id);

  useResetManufacture();
  useEffect(() => {
    form.resetFields();
  }, [initManufacture, form]);

  const onFinish = values => {
    const getStatus = ({ status }) =>
      status ? STATUS.ACTIVE : STATUS.INACTIVE;

    if (_id) {
      updateManufacture({
        ...values,
        _id: _id,
        status: getStatus(values)
      });
    } else {
      handleCreate({
        ...values,
        status: getStatus(values)
      });
    }
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initManufacture}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!_id ? 'Tạo mới ' : 'Cập nhật'}`} hãng sản xuất
            </h4>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Tên hãng sản xuất"
                name="name"
                rules={[
                  { required: true, message: 'Xin mời nhập tên hãng sản xuất' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem label="Mô tả" name="description">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input.TextArea rows={3} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Trạng thái"
                name="status"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Hoạt động"
                  unCheckedChildren="Ngưng hoạt động"
                  disabled={isLoading}
                />
              </FormItem>
            </Col>
          </Row>

          <Row className="wc-setting-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {_id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ManufactureForm;
