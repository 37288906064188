export const GET_IMFORMATION_PAYMENT_REQUEST = 'GET_IMFORMATION_PAYMENT_REQUEST';
export const GET_IMFORMATION_PAYMENT_SUCCESS = 'GET_IMFORMATION_PAYMENT_SUCCESS';
export const GET_IMFORMATION_PAYMENT_FAILED = 'GET_IMFORMATION_PAYMENT_FAILED';

export const CREATE_IMFORMATION_PAYMENT_REQUEST = 'CREATE_IMFORMATION_PAYMENT_REQUEST';
export const CREATE_IMFORMATION_PAYMENT_SUCCESS = 'CREATE_IMFORMATION_PAYMENT_SUCCESS';
export const CREATE_IMFORMATION_PAYMENT_FAILED = 'CREATE_IMFORMATION_PAYMENT_FAILED';

export const UPDATE_IMFORMATION_PAYMENT_REQUEST = 'UPDATE_IMFORMATION_PAYMENT_REQUEST';
export const UPDATE_IMFORMATION_PAYMENT_SUCCESS = 'UPDATE_IMFORMATION_PAYMENT_SUCCESS';
export const UPDATE_IMFORMATION_PAYMENT_FAILED = 'UPDATE_IMFORMATION_PAYMENT_FAILED';

export const DELETE_IMFORMATION_PAYMENT_REQUEST = 'DELETE_IMFORMATION_PAYMENT_REQUEST';
export const DELETE_IMFORMATION_PAYMENT_SUCCESS = 'DELETE_IMFORMATION_PAYMENT_SUCCESS';
export const DELETE_IMFORMATION_PAYMENT_FAILED = 'DELETE_IMFORMATION_PAYMENT_FAILED';

export const RESET_IMFORMATION_PAYMENT_STATE = 'RESET_IMFORMATION_PAYMENT_STATE';