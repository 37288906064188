export const GET_PRODUCT_ORDERS_REQUEST = 'GET_PRODUCT_ORDERS_REQUEST';
export const GET_PRODUCT_ORDERS_SUCCESS = 'GET_PRODUCT_ORDERS_SUCCESS';
export const GET_PRODUCT_ORDERS_FAILED = 'GET_PRODUCT_ORDERS_FAILED';

export const GET_PRODUCT_ORDER_REQUEST = 'GET_PRODUCT_ORDER_REQUEST';
export const GET_PRODUCT_ORDER_SUCCESS = 'GET_PRODUCT_ORDER_SUCCESS';
export const GET_PRODUCT_ORDER_FAILED = 'GET_PRODUCT_ORDER_FAILED';

export const CREATE_PRODUCT_ORDER_REQUEST = 'CREATE_PRODUCT_ORDER_REQUEST';
export const CREATE_PRODUCT_ORDER_SUCCESS = 'CREATE_PRODUCT_ORDER_SUCCESS';
export const CREATE_PRODUCT_ORDER_FAILED = 'CREATE_PRODUCT_ORDER_FAILED';

export const UPDATE_PRODUCT_ORDER_REQUEST = 'UPDATE_PRODUCT_ORDER_REQUEST';
export const UPDATE_PRODUCT_ORDER_SUCCESS = 'UPDATE_PRODUCT_ORDER_SUCCESS';
export const UPDATE_PRODUCT_ORDER_FAILED = 'UPDATE_PRODUCT_ORDER_FAILED';

export const UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_REQUEST = 'UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_REQUEST';
export const UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_SUCCESS = 'UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_SUCCESS';
export const UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_FAILED = 'UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_FAILED';

export const DELETE_PRODUCT_ORDER_REQUEST = 'DELETE_PRODUCT_ORDER_REQUEST';
export const DELETE_PRODUCT_ORDER_SUCCESS = 'DELETE_PRODUCT_ORDER_SUCCESS';
export const DELETE_PRODUCT_ORDER_FAILED = 'DELETE_PRODUCT_ORDER_FAILED';

export const RESET_PRODUCT_ORDER_STATE = 'RESET_PRODUCT_ORDER_STATE';
