import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import InformationItem from '~/components/Common/InformationItem';
import DetailTable from './DetailTable';
import { formatDate } from '~/utils/helper';
import { PATH_CLINIC } from '~/routes/paths';
import { API_LIST, INVOICE_STATUS, PAYMENT_STATE } from '~/constants/defaultValue';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';

const LEFT_INFO = [
  {
    label: 'Mã đặt hàng:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Thời gian:',
    dataIndex: 'createdAt',
    render: createdAt => formatDate(createdAt)
  },
  {
    label: 'Khách hàng:',
    dataIndex: 'customer',
    render: (customer, record) =>  <Link to="#">{customer?.name}</Link>
  },
  {
    label: 'Bảng giá:',
    dataIndex: 'priceList'
  }
];

const RIGHT_INFO = [
  {
    label: 'Trạng thái:',
    dataIndex: 'status',
    render: status => INVOICE_STATUS[status]
  },
  {
    label: 'Chi nhánh:',
    dataIndex: 'branch',
    render: branch => branch?.name?.vi
  },
  {
    label: 'Người nhận đặt:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  },
  {
    label: 'Người tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.username
  }
];

const Information = ({ record, deleteProductOrder, updateStatus, isLoadingSubmit }) => {
  // const convertRecord = useMemo(() => record?.typeSale === 'PM'
  //   ? {
  //     ...record,
  //     customer: {
  //       name: record?.customerInfo?.name
  //     },
  //   }
  //   :record, [record]);
  return (
    <div className="warehouse-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={8}>
          <div className="warehouse-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={record[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={record[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <InformationItem key={'Ghi chú'} label="Ghi chú" value="" />
          </div>
        </Col>
      </Row>

      <DetailTable sale={record} />

      <Row className="medicine-info__actions" justify="end">
        {
          (record?.typeSale === 'PM' && record?.status === PAYMENT_STATE.DRAFT) && (
            <WithPermission permission={POLICIES.UPDATE_SALEORDER}>
              <Button loading={isLoadingSubmit} style={{ marginRight: 10 }} type='primary' onClick={() => updateStatus({
                id: record._id,
                status: PAYMENT_STATE.PACKAGE_EXPORT
              })}>
              <CheckOutlined /> Xác nhận đặt hàng
              </Button>
            </WithPermission>
          )
        }
        {
        // record?.typeSale === 'PM' ? (record?.status !== PAYMENT_STATE.DRAFT &&
        //    <WithPermission permission={POLICIES.UPDATE_SALEORDER}>
        //   {/* {record.status !== PAYMENT_STATE.COMPLETED && ( */}
        //     <Link
        //       to={{
        //         pathname: PATH_CLINIC.sale.root,
        //         state: { saleOrderId: record._id }
        //       }}
        //     >
        //       <Button loading = {isLoadingSubmit} type="primary" style={{ marginRight: 10 }}>
        //         {' '}
        //         <EditOutlined style={{ marginLeft: 0 }} /> Cập nhật
        //       </Button>
        //     </Link>
        //   {/* )} */}
        //     </WithPermission>) :
            record.status !== PAYMENT_STATE.DRAFT && 
            <WithPermission permission={POLICIES.UPDATE_SALEORDER}>
            <Link
              to={{
                pathname: PATH_CLINIC.sale.root,
                state: { saleOrderId: record._id }
              }}
            >
              <Button loading = {isLoadingSubmit} type="primary" style={{ marginRight: 10 }}>
                {' '}
                <EditOutlined style={{ marginLeft: 0 }} /> Cập nhật
              </Button>
            </Link>
          {/* )} */}
          </WithPermission>
        }

        <WithPermission permission={POLICIES.WRITE_SALEORDER}>
          <ButtonExport router={API_LIST.productOrder} label="Đặt hàng" id={record._id} fileName={`Chitietxuatdathang_${record.code}`}/>
        </WithPermission>

        {record?.status === PAYMENT_STATE.DRAFT && <WithPermission permission={POLICIES.DELETE_SALEORDER}>
          <Popconfirm
            title="Bạn muốn xoá đơn đặt hàng này?"
            onConfirm={() => deleteProductOrder(record._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger" style={{ marginLeft: 10 }}>
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>{' '}
        </WithPermission>}
      </Row>
    </div>
  );
};

export default Information;
