import requester, { mockApi } from './requester';
import { BASE_URL } from '~/constants/defaultValue';

const CASH_FLOW = [
  {
    _id: 1,
    code: 'TTDH00001',
    time: '15/11/2021 16:46',
    customer: 'Trần Văn A',
    amount: 1600000,
    branch: 'Chi nhánh trung tâm',
    type: 'Thu Tiền khách trả',
    phone: '0978990909',
    address: 'Phố A, phường B',
    status: 'Đã thanh toán',
    user: 'Trần Văn B',
    employee: 'Trần Văn B',
    target: 'Khách hàng'
  },
  {
    _id: 2,
    code: 'TTDH00002',
    time: '15/11/2021 16:46',
    customer: 'Trần Văn B',
    amount: 2100000,
    branch: 'Chi nhánh trung tâm',
    type: 'Chi Tiền trả NCC',
    phone: '0978990909',
    address: 'Phố A, phường B',
    status: 'Đã thanh toán',
    user: 'Trần Văn B',
    employee: 'Trần Văn B',
    target: 'Khách hàng'
  },
  {
    _id: 3,
    code: 'TTDH00003',
    time: '15/11/2021 16:46',
    customer: 'Trần Văn C',
    amount: 3100000,
    branch: 'Chi nhánh trung tâm',
    type: 'Thu Tiền khách trả',
    phone: '0978990909',
    address: 'Phố A, phường B',
    status: 'Đã thanh toán',
    user: 'Trần Văn B',
    employee: 'Trần Văn B',
    target: 'Khách hàng'
  },
  {
    _id: 4,
    code: 'TTDH00004',
    time: '15/11/2021 16:46',
    customer: 'Trần Văn D',
    amount: 4100000,
    branch: 'Chi Tiền trả NCC',
    phone: '0978990909',
    type: 'Thu Tiền khách trả',
    address: 'Phố A, phường B',
    status: 'Đã thanh toán',
    user: 'Trần Văn B',
    employee: 'Trần Văn B',
    target: 'Khách hàng'
  }
];

const cashFlow = {
  getAll: query => {
    return requester.get('/general-ledger', query)
  },
  createPaymentNoteType: paymentNoteType =>
    requester.post(`${BASE_URL}/api/v1/payment-note-type`, paymentNoteType),
  createRecipientSubmitter: recipientSubmitter =>
    requester.post(`${BASE_URL}/api/v2/customer`, recipientSubmitter),
  createPayRectNote: data =>
    requester.post(`${BASE_URL}/api/v1/payment-note`, data),
  searchPayerReceivers: (params) => {
    return requester.get(`/payment-note/payer-receiver/search`, { keyword: params.keyword, target: params.target })
  },
  searchPayReceiversType: (type) => {
    return requester.get(`/payment-note-type`, { type })
  },

  getOptions: () => mockApi.request(CASH_FLOW)
};

export default cashFlow;
