
import { MenuOutlined, CaretDownOutlined} from '@ant-design/icons';
import './Navigation.css';
import React from 'react';
import { Button} from 'antd';

function App(){
    return (
    <div id='nav'>
        <div id = 'left-nav'>
            <Button id="Dashboard"  icon={<MenuOutlined /> } >
            Dashboard
            </Button>
        
        </div> 
        <div id='right-nav'>
            Username&ensp;|&ensp;
            <div id='right-nav2'> 
            <Button id="select" >
                DRKUMO.INC <CaretDownOutlined />
            </Button> 
            </div>
        </div>
        
    </div>

    );
}
export default App;