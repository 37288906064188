import React, { useMemo } from 'react';
import { Table } from 'antd';
import { formatCurrency, formatDateTime } from '~/utils/helper';
import { PAYMENT_METHOD_DICT } from '~/constants/defaultValue';

const PaymentHistory = ({ record, bordered }) => {
  const paymentNotes = record.paymentNotes || [];

  const columns = useMemo(
    () => [
      {
        title: 'Mã phiếu',
        key: 'code',
        dataIndex: 'code'
      },
      {
        title: 'Thời gian',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => formatDateTime(createdAt)
      },
      {
        title: 'Người tạo',
        key: 'createdBy',
        dataIndex: ['createdBy', 'fullName']
      },

      {
        title: 'Phương thức',
        key: 'paymentMethod',
        dataIndex: 'paymentMethod',
        render: method => PAYMENT_METHOD_DICT[method]
      },

      {
        title: 'Giá trị phiếu',
        key: 'paymentAmount',
        dataIndex: 'paymentAmount',
        align: 'end',
        render: paymentAmount => formatCurrency(paymentAmount)
      },
      {
        title: 'Tiền thu/chi',
        key: 'paymentAmount',
        dataIndex: 'paymentAmount',
        align: 'end',
        render: paymentAmount => formatCurrency(paymentAmount)
      }
    ],
    []
  );

  return (
    <div className="payment-history warehouse-info medicine-detail-tab-item">
      <Table
        dataSource={paymentNotes}
        columns={columns}
        pagination={false}
        className="nested-table"
        rowKey={rc => rc._id}
        size="small"
        bordered={bordered}
      />
    </div>
  );
};

export default PaymentHistory;
