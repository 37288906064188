import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getCustomers,
  createCustomer,
  deleteCustomer,
  getCustomer,
  resetCustomerState,
  updateCustomer,
  getCustomerOptions,
  getCustomerDebt,
  getCustomerPurchases,
  getCustomerOrderHistory, getCustomerSearch
} from '~/redux/action';
import { Menu, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const CUSTOMER_MODULE = 'customer';

const CREATE_SUCCESS_MESS = 'Tạo mới khách hàng thành công';
const CREATE_FAILED_MESS = 'Tạo mới khách hàng thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật khách hàng thành công';
const UPDATE_FAILED_MESS = 'Cập nhật khách hàng thất bại';
const DELETE_SUCCESS_MESS = 'Xoá khách hàng thành công';
const DELETE_FAILED_MESS = 'Xoá khách hàng thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(CUSTOMER_MODULE);

const getSelector = key => state => state[CUSTOMER_MODULE][key];

export const useCustomers = query => {
  return useFetchByParam({
    action: getCustomers,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateCustomer = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createCustomer
  });
};

export const useUpdateCustomer = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateCustomer
  });
};

export const useDeleteCustomer = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteCustomer
  });
};

export const useCustomer = params => {
  return useFetchByParam({
    action: getCustomer,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitCustomer = (customer, id) => {
  return useMemo(() => {
    if (!customer || !id) {
      return {};
    }

    return { ...customer };
  }, [customer, id]);
};

export const useUpdateCustomerParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/customer',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useCustomerQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page') || 1;
  const code = query.get('code') 
  const name = query.get('name') 
  const phoneNumber = query.get('phoneNumber') 

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      code,
      name,
      phoneNumber,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, updateSuccess, deleteSuccess,code ,phoneNumber,name]);
};

export const useCustomerPaging = () => useSelector(pagingSelector);

export const useResetCustomer = () => {
  useResetState(resetCustomerState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã khách hàng',
    key: 'code'
  },
  {
    title: 'Tên khách hàng',
    key: 'name'
  },
  {
    title: 'Điện thoại',
    key: 'phoneNumber'
  },
  {
    title: 'Nợ hiện tại',
    key: 'totalDebt'
  },
  {
    title: 'Tổng bán',
    key: 'totalSold'
  },
  { title: 'Tổng bán trừ trả hàng', key: 'totalSoldWithoutReturn' }
];

export const useCustomerColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    name: true,
    phoneNumber: true,
    totalDebt: true,
    totalSold: true,
    totalSoldWithoutReturn: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

const optionsSelector = getSelector('options');
const getOptionsLoadingSelector = getSelector('isGetOptionsLoading');
const getOptionsFailedSelector = getSelector('getOptionsFailed');

export const useCustomerOptions = searchText => {
  return useFetchByParam({
    action: getCustomerOptions,
    loadingSelector: getOptionsLoadingSelector,
    dataSelector: optionsSelector,
    failedSelector: getOptionsFailedSelector,
    param: searchText
  });
};

const searchSelector = getSelector('search');
const getSearchLoadingSelector = getSelector('isGetSearchLoading');
const getSearchFailedSelector = getSelector('getSearchFailed');

export const useCustomerSearch = searchText => {
  return useFetchByParam({
    action: getCustomerSearch,
    loadingSelector: getSearchLoadingSelector,
    dataSelector: searchSelector,
    failedSelector: getSearchFailedSelector,
    param: searchText
  });
};


const customerPurchasesSelector = getSelector('customerPurchases');
const getCustomerPurchasesLoadingSelector = getSelector(
  'isGetCustomerPurchasesLoading'
);
const getCustomerPurchasesFailedSelector = getSelector(
  'getCustomerPurchasesFailed'
);

export const useCustomerPurchases = (query, customerId) => {
  const jsonParam = JSON.stringify({
    limit: query.limit,
    page: query.page,
    customerId: customerId
  })

  const paramRef = useRef(null);
  const [queryParam, setQueryParam] = useState(null)

  if(jsonParam !== JSON.stringify(paramRef.current)) {
    paramRef.current = {
      limit: query.limit,
      page: query.page,
      customerId: customerId
    }

    setQueryParam(paramRef.current)
  }

  return useFetchByParam({
    action: getCustomerPurchases,
    loadingSelector: getCustomerPurchasesLoadingSelector,
    dataSelector: customerPurchasesSelector,
    failedSelector: getCustomerPurchasesFailedSelector,
    param: queryParam,
  });
};

const customerOrderHistorySelector = getSelector('customerOrderHistory');
const getCustomerOrderHistoryLoadingSelector = getSelector(
  'isGetCustomerOrderHistoryLoading'
);
const getCustomerOrderHistoryFailedSelector = getSelector(
  'getCustomerOrderHistoryFailed'
);

export const useCustomerOrderHistory = (query, customerId) => {
  const jsonParamHis = JSON.stringify({
    limit: query.limit,
    page: query.page,
    customerId: customerId
  })

  const paramRef = useRef(null);
  const [queryParam, setQueryParam] = useState(null)

  if(jsonParamHis !== JSON.stringify(paramRef.current)) {
    paramRef.current = {
      limit: query.limit,
      page: query.page,
      customerId: customerId
    }

    setQueryParam(paramRef.current)
  }

  return useFetchByParam({
    action: getCustomerOrderHistory,
    loadingSelector: getCustomerOrderHistoryLoadingSelector,
    dataSelector: customerOrderHistorySelector,
    failedSelector: getCustomerOrderHistoryFailedSelector,
    param: queryParam,
  });
};

const customerDebtSelector = getSelector('customerDebt');
const getCustomerDebtLoadingSelector = getSelector('isGetCustomerDebtLoading');
const getCustomerDebtFailedSelector = getSelector('getCustomerDebtFailed');
const pagingDebtSelector = getSelector('pagingDebt') 

export const useDebtPaging = () => useSelector(pagingDebtSelector);

export const useCustomerDebt = (query, customerId) => {
  const jsonParamDebt = JSON.stringify({
    limit: query.limit,
    page: query.page,
    customerId: customerId
  })

  const paramRef = useRef(null);
  const [queryParam, setQueryParam] = useState(null)

  if(jsonParamDebt !== JSON.stringify(paramRef.current)) {
    paramRef.current = {
      limit: query.limit,
      page: query.page,
      customerId: customerId
    }

    setQueryParam(paramRef.current)
  }

  return useFetchByParam({
    action: getCustomerDebt,
    loadingSelector: getCustomerDebtLoadingSelector,
    dataSelector: customerDebtSelector,
    failedSelector: getCustomerDebtFailedSelector,
    param: queryParam,
  });
};
export const SEARCH_CUSTOMER =[
  {
    name:"code",
    placeholder : "Theo mã khách hàng",
  },
  {
    name:"name",
    placeholder : "Theo tên khách hàng",
  },
  {
    name:"phoneNumber",
    placeholder : "Theo số điện thoại",
  },

]
