import React, {  useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Row,
  Button,
  Select,
  Col,
  DatePicker,
  TimePicker,
  Radio
} from 'antd';
import moment from 'moment';
import {  getUtcDateTime } from '~/utils/helper';
import {
  CARD_STYLE,
  STATUS_SAMPLE
} from '~/constants/defaultValue';

import {
  useResetSupplier,
  useProfile, useCreateSample, useUpdateSample
} from '~/hooks';
import SupplierForm from '~/components/WorldClinic/Supplier/Form';
const {ACTIVE, INACTIVE} = STATUS_SAMPLE
const FormItem = Form.Item;

const SummaryForm = ({ formData, initSample, isGetSampleLoading }) => {
  const isEditingCompletedSample =
    initSample?._id

  const [profile] = useProfile();
  const [form] = Form.useForm();
  const history = useHistory();
  const onSubmitSuccess = sample => {
    // history.push({
    //   pathname: '/sample-prescription',
    //   //search: new URLSearchParams({ id: sample._id }).toString()
    // });
    history.go(-1)
  };
  const [status, setStatus] = useState("");
  const onStatusChange = (value) => {
    setStatus(value);
  };
  const [isCreatingSample, createSample] = useCreateSample(
    onSubmitSuccess
  );
  const [isUpdatingSample, updateSample] = useUpdateSample(
    onSubmitSuccess
  );

  const [isSupplierFormOpen, setSupplierFormOpen] = useState(false);
useEffect(()=>{
  if(!!formData[0]){
    setStatus(formData[0]?.status)
  }
  else{
    setStatus("ACTIVE")
  }
},[formData]);

  useEffect(() => {
    if (initSample) {
      form.setFieldsValue({
        code: initSample.code,
        note: initSample.note,
        date: moment(initSample.purchasedAt),
        time: moment(initSample.purchasedAt),
        name: initSample.name,
      });
    }
  }, [initSample, form]);

  useResetSupplier();

  const onFinish = values => {
    const model = {
      ...values,
      status: status,
      samplePrescriptionDetail: formData.map(variant => {
        return {
          productId: variant.productId,
          variantId: initSample ? variant.variantId : variant._id,
          quantity: variant.quantity
        };
      }),
    };

    model.createSampleAt = getUtcDateTime(
      values.date,
      values.time
    );

    model.date = undefined;
    model.time = undefined;
    const { _id } = initSample || {};
    if (_id) {
      updateSample({ ...model,id:_id});
    } else {
      createSample(model);
    }
  };

  const isActionLoading = isCreatingSample || isUpdatingSample;

  return (
    <div className="warehouse-form__right" style={CARD_STYLE}>
      <Form form={form} onFinish={onFinish}>
        <Row justify="space-between">
          <Col span={9} style={{ borderBottom: '1px solid #dedede' }}>
            <Select
              className="warehouse-form__user"
              bordered={false}
              value={profile?.fullName}
            ></Select>
          </Col>
          <Col span={14}>
            <Row justify="end">
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="date" initialValue={moment()}>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    style={{
                      paddingRight: 0
                    }}
                    format={'DD-MM-YYYY'}
                  />
                </FormItem>
              </Col>
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="time" initialValue={moment()}>
                  <TimePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    placeholder=""
                    format={'HH:mm'}
                    style={{ width: 45, paddingRight: 0, paddingLeft: 0 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="warehouse-form__row">
          <label>Tên đơn thuốc</label>
          <FormItem name="name" noStyle>
            <Input
              bordered={false}
              placeholder=""
              style={{ width: 200 }}
              className="warehouse-form__custom-input"
              rows={1}
            ></Input>
          </FormItem>
        </Row>
        <Row className="warehouse-form__row" style={{ marginTop: 0 }}>
          <label>Mã đơn thuốc </label>

          <FormItem noStyle name="code">
            <Input
              bordered={false}
              className="warehouse-form__custom-input warehouse-form__code"
              style={{ width: 200 }}
              placeholder="Mã tự động"
              disabled
            />
          </FormItem>
        </Row>
        <Row className="warehouse-form__row">
          <label>Trạng thái</label>
          <Radio.Group
            onChange={e => onStatusChange( e.target.value)}
            value={status}
            // disabled={isEditingCompletedSample}
          >
            <Radio value={'ACTIVE'}>{ACTIVE}</Radio>
            <Radio value={'INACTIVE'}>{INACTIVE}</Radio>
          </Radio.Group>
        </Row>

        <Row className="warehouse-form__row">
          <FormItem style={{ width: '100%' }} name="note">
            <Input.TextArea
              bordered={false}
              placeholder="Ghi chú"
              style={{ width: '100%', paddingLeft: 0 }}
              className="warehouse-form__custom-input"
              rows={1}
            ></Input.TextArea>
          </FormItem>
        </Row>

        <Row className="warehouse-form__submit">
          <Button
            htmlType="submit"
            type="primary"
            style={{ marginLeft: 10 }}
            loading={isActionLoading}
          >
            {!isEditingCompletedSample ? 'Hoàn thành' : 'Cập nhật'}
          </Button>
        </Row>
      </Form>

      <SupplierForm
        callback={supplier => form.setFieldsValue({ supplierId: supplier._id })}
        isOpen={isSupplierFormOpen}
        onClose={() => setSupplierFormOpen(false)}
      />
    </div>
  );
};

export default SummaryForm;
