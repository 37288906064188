import requester from './requester';

const medicineGroup = {
  getAll: (query) => requester.get('/product-group',query),
  // getAllFilterStatus: (query) => requester.get('/product-group'),
  getById: id => requester.get(`/product-group/${id}`),
  create: group => requester.post(`/product-group`, group),
  update: group => requester.put(`/product-group/${group._id}`, group),
  delete: id => requester.delete(`/product-group/${id}`)
};

export default medicineGroup;
