import * as Types from '~/constants/actionTypes';

export const getInformationPayment = query => {
  return {
    type: Types.GET_IMFORMATION_PAYMENT_REQUEST,
    payload: query
  };
};

export const createInformationPayment = id => ({
  type: Types.CREATE_IMFORMATION_PAYMENT_REQUEST,
  payload: id
});

export const updateInformationPayment = id => ({
  type: Types.UPDATE_IMFORMATION_PAYMENT_REQUEST,
  payload: id
});

export const deleteInformationPayment = id => ({
  type: Types.DELETE_IMFORMATION_PAYMENT_REQUEST,
  payload: id
});

