import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getNewsCategories() {
  try {
    const data = yield call(Api.newsCategory.getAll);
    yield put({ type: Types.GET_NEWS_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_NEWS_CATEGORIES_FAILED,
      payload: error.message
    });
  }
}

function* getNewsCategory({ payload: id }) {
  try {
    const newsCategory = yield call(Api.newsCategory.getById, id);
    yield put({ type: Types.GET_NEWS_CATEGORY_SUCCESS, payload: newsCategory });
  } catch (error) {
    yield put({ type: Types.GET_NEWS_CATEGORY_FAILED, payload: error.message });
  }
}

function* createNewsCategory(action) {
  try {
    const data = yield call(Api.newsCategory.create, action.payload);
    yield put({ type: Types.CREATE_NEWS_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_NEWS_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* updateNewsCategory(action) {
  try {
    const data = yield call(Api.newsCategory.update, action.payload);
    yield put({ type: Types.UPDATE_NEWS_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_NEWS_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* deleteNewsCategory({ payload }) {
  try {
    yield call(Api.newsCategory.delete, payload);
    yield put({ type: Types.DELETE_NEWS_CATEGORY_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_NEWS_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

export default function* newsCategory() {
  yield takeLatest(Types.GET_NEWS_CATEGORIES_REQUEST, getNewsCategories);
  yield takeLatest(Types.GET_NEWS_CATEGORY_REQUEST, getNewsCategory);
  yield takeLatest(Types.DELETE_NEWS_CATEGORY_REQUEST, deleteNewsCategory);
  yield takeLatest(Types.CREATE_NEWS_CATEGORY_REQUEST, createNewsCategory);
  yield takeLatest(Types.UPDATE_NEWS_CATEGORY_REQUEST, updateNewsCategory);
}
