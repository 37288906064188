import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],
  summary:null,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  saveSuccess: null,
  saveFailed: null,

  updateSuccess: null,
  updateFailed: null,

  stockTakes: [],
  isSearchStockTakesLoading: false,
  searchStockTakesFailed: false,

  paging: { current: 1, pageSize: 11 }
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_STOCK_TAKES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_STOCK_TAKES_SUCCESS:
      state.isLoading = false;
      state.summary={ ...payload.summary }
      state.list = [
        // { ...payload.summary, isSummaryRow: true, _id: -1 },
        ...payload.docs
      ];
      state.paging = getPaging(payload);
      return;

    case Types.GET_STOCK_TAKES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_STOCK_TAKE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_STOCK_TAKE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_STOCK_TAKE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_STOCK_TAKE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_STOCK_TAKE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_STOCK_TAKE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.SAVE_STOCK_TAKE_LUMPED_REQUEST:
      state.isSubmitLoading = true;
      state.saveSuccess = null;
      state.saveFailed = null;
      return;

    case Types.SAVE_STOCK_TAKE_LUMPED_SUCCESS:
      state.isSubmitLoading = false;
      state.saveSuccess = payload;
      return;

    case Types.SAVE_STOCK_TAKE_LUMPED_FAILED:
      state.isSubmitLoading = false;
      state.saveFailed = payload;
      return;

    case Types.UPDATE_STOCK_TAKE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_STOCK_TAKE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_STOCK_TAKE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_STOCK_TAKE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_STOCK_TAKE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_STOCK_TAKE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_STOCK_TAKE_STATE:
    case Types.RESET_STORE:
      return initState;

    case Types.SEARCH_STOCK_TAKES_REQUEST:
      state.isSearchStockTakesLoading = true;
      state.searchStockTakesFailed = null;
      return;

    case Types.SEARCH_STOCK_TAKES_SUCCESS:
      state.isSearchStockTakesLoading = false;
      state.stockTakes = payload;

      return;

    case Types.SEARCH_STOCK_TAKES_FAILED:
      state.isSearchStockTakesLoading = false;
      state.searchStockTakesFailed = null;
      state.stockTakes = [];
      return;

    default:
      return;
  }
}, initState);
