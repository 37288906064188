import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  options: [],
  isGetOptionsLoading: false,
  getOptionsFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_MANUFACTURES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_MANUFACTURES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_MANUFACTURES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_MANUFACTURE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_MANUFACTURE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_MANUFACTURE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_MANUFACTURE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_MANUFACTURE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.options = [payload, ...state.options];
      return;

    case Types.CREATE_MANUFACTURE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_MANUFACTURE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_MANUFACTURE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_MANUFACTURE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_MANUFACTURE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_MANUFACTURE_SUCCESS:
      state.isLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_MANUFACTURE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_MANUFACTURE_OPTIONS_REQUEST:
      state.isGetOptionsLoading = true;
      state.getOptionsFailed = null;
      return;

    case Types.GET_MANUFACTURE_OPTIONS_SUCCESS:
      state.isGetOptionsLoading = false;
      state.options = payload;
      return;

    case Types.GET_MANUFACTURE_OPTIONS_FAILED:
      state.isGetOptionsLoading = false;
      state.getOptionsFailed = payload;
      return;

    case Types.RESET_MANUFACTURE_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
