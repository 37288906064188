import { omit } from 'lodash';
import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getBatches({ payload: query }) {
  try {
    const data = yield call(Api.batch.getAll, query);
    yield put({ type: Types.GET_BATCHES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_BATCHES_FAILED,
      payload: error
    });
  }
}

function* createBatch(action) {
  try {
    const data = yield call(Api.batch.create, action.payload);
    yield put({
      type: Types.CREATE_BATCH_SUCCESS,
      payload: { ...data, quantity: action.payload.quantity }
    });
  } catch (error) {
    yield put({
      type: Types.CREATE_BATCH_FAILED,
      payload: error
    });
  }
}

function* updateBatchByOrder(action) {
  try {
    const data = yield call(Api.batch.update, omit(action.payload, ['callback']));
    yield put({
      type: Types.UPDATE_BATCH_BY_ORDER_SUCCESS,
      payload: { ...data}
    });
    if(action.payload.callback && typeof action.payload.callback === 'function') action.payload.callback();
  } catch (error) {
    yield put({
      type: Types.UPDATE_BATCH_BY_ORDER_FAILED,
      payload: error
    });
  }
}

export default function* batch() {
  yield takeLatest(Types.GET_BATCHES_REQUEST, getBatches);
  yield takeLatest(Types.CREATE_BATCH_REQUEST, createBatch);
  yield takeLatest(Types.UPDATE_BATCH_BY_ORDER_REQUEST, updateBatchByOrder);
}
