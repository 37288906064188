import React, { useState,useMemo } from 'react';
import './index.scss';
import { CARD_STYLE, DISCOUNT_TYPES } from '~/constants/defaultValue';
import SearchProductsBox from '~/components/Utils/SearchProductsBox';
import { Button, Input, Row, Table } from 'antd';
import { MedicineBoxOutlined } from '@ant-design/icons';
import {
  useInitSamplePrescription,
  useResetSamplePrescription,
  useSamplePrescriptionColumns,
} from '~/hooks';
import SummaryForm from './SummaryForm';
const findById = (data, id) => data.find(({ _id }) => _id === id);
const SamplePrescriptionForm = props => {
  useResetSamplePrescription()
  const [formData, setFormData] = useState([]);
  const handleSelect = ({productId, variantId}) => {
    const dataForm = formData.map(data => {
      if (data.productId !== productId) {
        return data
      }

      const newSelectedVariant = findById(data.relationVariants, variantId)
      return {
        ...data,
        selectedVariant: newSelectedVariant,
      };
    });

    setFormData([...dataForm])
  }
  const columns = useSamplePrescriptionColumns(setFormData,handleSelect)

  const { id: updatingId } = props.match.params;

  //const [samplePres, isGetSamplePres] = useSamplePrescription(updatingId)
  //const isLoading = isGetSamplePres;

  const sampleInit = useMemo(() => {
    const items = JSON.parse(localStorage.getItem('item'));
    if(!items || !updatingId){
      localStorage.removeItem('item')
      return;
    }
    return items;
  },[updatingId])
  useInitSamplePrescription({sampleInit,updatingId,setFormData})
  const onSelectVariant = variant => {
    const currentVariants = formData.filter(
      ({ variantCode }) => variantCode !== variant.variantCode
    );

    const initBatch = variant.batches?.[0] || currentVariants.batches?.[0];
    //const selectedBatches = initBatch ? [{ ...initBatch, quantity: 1 }] : [];
    if(currentVariants.length === formData.length - 1){
      setFormData([...formData])
    } else{
      const addedVariant = {
        ...variant,
        discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
        quantity: initBatch ? 1 : 0,
        //selectedBatches,
        variantId: variant._id,
        buyPrice: variant.cost
      };
      setFormData([addedVariant, ...currentVariants]);
    }

  };
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="warehouse-form">
          <div className="warehouse-form__left" style={CARD_STYLE}>
            <div className="wc-page-header">
              <h1
                className="wc-page-header__title"
              >
                Thêm đơn thuốc mẫu
              </h1>

              <div className="wc-page-header__search" id="search-product-box">
                <SearchProductsBox onSelect={onSelectVariant}>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Tìm theo mã hàng hoá hoặc tên"
                    suffix={
                      <Row
                        className="warehouse-form__search"
                        onMouseDown={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Button
                          //onClick={e => setMedicineFormOpen(true)}
                          size="small"
                          shape="circle"
                          icon={<MedicineBoxOutlined />}
                        ></Button>
                      </Row>
                    }
                  />
                </SearchProductsBox>
              </div>
            </div>

            <div className="warehouse-form-table">
              <Table
                size="small"
                columns={columns}
                dataSource={formData}
                rowKey={rc => rc.variantCode}
                pagination={false}
              ></Table>
            </div>
          </div>
          <SummaryForm
            formData={formData}
            initSample={sampleInit}
            //isGetSampleLoading={isLoading}
          />

        </div>
      </div>

    </div>
  );
};

export default SamplePrescriptionForm;
