/* eslint-disable import/no-anonymous-default-export */
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

function ListItem({children}) {
    return (
        <span style={{display:'flex',alignItems:'center',gap:10,justifyContent:'center'}} > {children}</span> 
    )
}

const Success =({style,children})=>{
    return (
    <ListItem>
        <CheckCircleOutlined style={{color:'white', borderRadius:'50%' , fontSize:22,...style }} /> {children?<Tag color={ style?.backgroundColor??'black'}>{children}</Tag>:''}
    </ListItem>)
}
const Error =({style,children})=>{
    return (
    <ListItem>
        <CloseCircleOutlined style={{color:'white', borderRadius:'50%' , fontSize:22,...style }} /> {children?<Tag color={ style?.backgroundColor??'black'}>{children}</Tag>:''}
    </ListItem>)
}
const Wanning =({style,children})=>{
    return (
    <ListItem>
        <InfoCircleOutlined style={{color:'white', borderRadius:'50%' , fontSize:22,...style }} /> {children?<Tag color={ style?.backgroundColor??'black'}>{children}</Tag>:''}
    </ListItem>)
}
const Pending =({style,children})=>{
    return (
    <ListItem>
        <PauseCircleOutlined style={{color:'white', borderRadius:'50%' , fontSize:22,...style }} /> {children?<Tag color={ style?.backgroundColor??'black'}>{children}</Tag>:''}
    </ListItem>)
}

export default {
    Success,
    Error,
    Wanning,
    Pending,
}
