import {
  GET_BRANCHES_REQUEST,
  GET_BRANCH_REQUEST,
  CREATE_BRANCH_REQUEST,
  UPDATE_BRANCH_REQUEST,
  DELETE_BRANCH_REQUEST,
  RESET_BRANCH_STATE,
  GET_API_KEY_REQUEST,
  UPDATE_API_KEY_SAVE_REQUEST,
  DELETE_API_KEY_REQUEST
} from '~/constants/actionTypes';

export const getBranches = (query) => ({
  type: GET_BRANCHES_REQUEST,
  payload: query
});

export const getBranch = (id) => ({
  type: GET_BRANCH_REQUEST,
  payload: id
});

export const createBranch = (company) => ({
  type: CREATE_BRANCH_REQUEST,
  payload: company
});

export const updateBranch = (company) => ({
  type: UPDATE_BRANCH_REQUEST,
  payload: company
});

export const deleteBranch = (id) => ({
  type: DELETE_BRANCH_REQUEST,
  payload: id
});

export const resetBranchState = () => ({
  type: RESET_BRANCH_STATE
});

export const getApiKey = (branchId) => ({
  type: GET_API_KEY_REQUEST,
  payload: branchId
});

export const updateApiKey = (query) => ({
  type: UPDATE_API_KEY_SAVE_REQUEST,
  payload: query
});

export const deleteApiKey = (query) => ({
  type: DELETE_API_KEY_REQUEST,
  payload: query
});

