import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getNotificationService({ payload: query }) {
  try {
    const data = yield call(Api.notificationService.notificationServiceApi, query);
    yield put({
      type: Types.GET_NOTIFICATION_SERVICE_SUCCESS,
      payload: { query, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_NOTIFICATION_SERVICE_FAILED,
      payload: error
    });
  }
}

export default function* notificationService() {
  yield takeLatest(Types.GET_NOTIFICATION_SERVICE_REQUEST, getNotificationService);
}
