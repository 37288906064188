import {
  useFetchByParam,
  getSelectors,
  useQueryParams, useSuccess, useFailed, useSubmit, useResetState
} from '~/hooks/utils';

import {
  deleteSamplePrescription,
  getSamplePrescriptions, resetSamplePrescriptionState,
  updateSamplePrescriptionNote, getSamplePrescription, createSamplePrescription, updateSamplePrescription
} from '~/redux/worldClinic/samplePrescription/actions';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getExistProp } from '~/utils/helper';
import { Button, Checkbox, InputNumber, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';
import { DeleteOutlined } from '@ant-design/icons';
import { forIn, isNil, isString } from 'lodash';

const SAMPLE_PRESCRIPTION_MODULE = 'samplePrescription';
const CREATE_SUCCESS_MESS = 'Tạo mới đơn thuốc mẫu thành công';
const CREATE_FAILED_MESS = 'Tạo mới đơn thuốc mẫu thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật đơn thuốc mẫu thành công';
const UPDATE_FAILED_MESS = 'Cập nhật đơn thuốc mẫu thất bại';
const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  deleteSuccessSelector,
  pagingSelector,
  updateSuccessSelector,
  updateFailedSelector,
  isSubmitLoadingSelector,
  deleteFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  createSuccessSelector,
  createFailedSelector,
} = getSelectors(SAMPLE_PRESCRIPTION_MODULE);

export const useSamplePrescriptions = query => {
  return useFetchByParam({
    action: getSamplePrescriptions,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useSamplePrescriptionQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const code = query.get('code');
  const page = query.get('page') || 1;
  const id = query.get('id');
  const status = query.get('status')
  const name = query.get('name');
  const productName = query.get('productName');
  const note = query.get('note');
  const variantCode = query.get('variantCode');
  const keyword = query.get('keyword');

  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const dataExit = {
      page,
      limit,
      variantCode
    }
    if (code) {
      dataExit.code = code
    }

    if (keyword) {
      dataExit.keyword = keyword
    }

    if (name) {
      dataExit.name = name
    }

    if (id) {
      dataExit.id = id
    }

    if (status) {
      dataExit.status = status
    }
    if (productName) {
      dataExit.productName = productName
    }
    if (note) {
      dataExit.note = note
    }
    const queryParams = getExistProp({
      ...dataExit
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, code, deleteSuccess, id,name,productName,note,status,variantCode,keyword]);
};
const COLUMNS_OPTIONS = [
  {
    title:'Mã thuốc',
    key:'code',
  },
  {
    title:'Tên thuốc',
    key:'name',
  },
  {
    title:'Trạng thái',
    key:'status',
  },
  {
    title:'Ghi chú',
    key:'note',
  },
  {
    title:'Người tạo',
    key:'createdBy',
  }
]
export const useSampleColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    name: true,
    status: true,
    note: false,
    createdBy: false,
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
}

export const useUpdateSampleParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);
  const onParamChange = param => {
    for (const key in param) {
      if(param[key] && isString(param[key])){
        param[key] = param[key].trim()
      }
    }
    if(!isNil(param.keyword)){
      for (const key in param) {
        if(key!=='keyword'){
          param[key] = undefined
        } 
      }
      forIn(query,(value,key)=>{
        if(!['limit','page'].includes(key)){
          query[key] = undefined
        }
      })
    }
    history.push({
      pathname: PATH_CLINIC.samplePrescription.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          id: undefined
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

//paging
export const useSamplePaging = () => useSelector(pagingSelector);

export const useUpdateSamplePrescriptionNode = () => {
  useSuccess(updateSuccessSelector,'Cập nhật ghi chú thành công');
  useFailed(updateFailedSelector,'Cập nhật ghi chú thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSamplePrescriptionNote
  })
}

export const useDeleteSamplePrescription = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, 'Xoá đơn thuốc thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá đơn thuốc thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteSamplePrescription
  });
};

export const useSamplePrescription = params => {
  return useFetchByParam({
    action: getSamplePrescription,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  })
}

export const useResetSamplePrescription = () => {
  useResetState(resetSamplePrescriptionState);
};

export const useSamplePrescriptionColumns = (setFormData,handleSelect) => {
  const columns = useMemo(() => {
    const onDeleteVariant = variant => {
      setFormData(variants =>
        variants.filter(({ variantId }) => variantId !== variant.variantId)
      );
    };
    const onFieldChange = (fieldName, value, updatedIndex) => {
      setFormData(formData =>
        formData.map((item, index) => {
          return index === updatedIndex
            ? {
              ...item,
              [fieldName]: value,
            }
            : item;
        })
      );
    };
    return [
      {
        title: '',
        key: 'delete',
        align: 'center',
        width: 60,
        render: rc => (
          <Button
            onClick={() => onDeleteVariant(rc)}
            size="small"
            shape="circle"
            className="warehouse-form__delete-btn"
            icon={<DeleteOutlined />}
          />
        )
      },
      {
        title: 'STT',
        key: 'STT',
        align: 'center',
        width: 60,
        render: (_, x, index) => index + 1
      },
      {
        title: 'Mã hàng',
        key: 'variantCode',
        dataIndex: 'variantCode',
        width: 120,
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['product', 'name']
      },
      {
        title: 'Đơn vị tính',
        key: 'unit',
        dataIndex: ['unit', 'name']
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        render: ({ quantity, selectedBatches }, _, index) => {
          return (
            <InputNumber
              size="small"
              className="warehouse-form__quantity"
              value={quantity}
              onChange={value => onFieldChange('quantity', value, index)}
              bordered={false}
              min = {1}
            />
          )
        }
      },
      {
        title: 'Liều dùng',
        key: 'lieudung',
        dataIndex: 'lieudung',
        align: 'right',
      },
    ]
  },[setFormData])
  return columns
}

// export const useInitSamplePrescription = ({samplePres,updatingId, setFormData}) => {
//   return useEffect(() => {
//     if (!samplePres || !updatingId) {
//       return {};
//     }
//   },[samplePres,updatingId,setFormData])
// }

export const useCreateSample = callback =>{
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSamplePrescription
  });
}
export const useUpdateSample = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSamplePrescription
  });
};

export const useInitSamplePrescription = ({sampleInit,updatingId, setFormData}) => {
  return useEffect(() => {
    if (!sampleInit || !updatingId) {
      return {};
    }
    const onInitMultipleVariant = (sampleDetailItems,status)  => {
      const addingVariants = sampleDetailItems.map(variant => {
        return {
          ...variant,
          variantCode: variant.variantId.variantCode,
          product:variant.productId,
          unit: variant.variantId.unitId,
          status
        }
      })
      setFormData(formData => [...addingVariants,...formData])
    }
    onInitMultipleVariant(sampleInit.samplePrescriptionDetail,sampleInit.status)

  },[sampleInit,updatingId,setFormData])
}