import { useMemo } from 'react';
import {
  useFailed,
  useFetch,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';

import {
  getDegrees,
  createDegree,
  deleteDegree,
  getDegree,
  resetDegreeState,
  updateDegree
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.degree[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useDegrees = () => {
  return useFetch({
    action: getDegrees,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};

export const useCreateDegree = () => {
  const history = useHistory();
  const callback = () => history.push('/degree');

  useSuccess(createSuccessSelector, 'Tạo mới chứng chỉ thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới chứng chỉ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createDegree
  });
};

export const useUpdateDegree = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật chứng chỉ thành công');
  useFailed(updateFailedSelector, 'Cập nhật chứng chỉ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateDegree
  });
};

export const useDeleteDegree = () => {
  useSuccess(deleteSuccessSelector, 'Xoá chứng chỉ thành công');
  useFailed(deleteFailedSelector, 'Xoá chứng chỉ thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteDegree
  });
};

export const useDegree = id => {
  return useFetchByParam({
    action: getDegree,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitDegree = degree =>
  useMemo(() => {
    if (!degree) {
      return {};
    }

    return degree;
  }, [degree]);

export const useResetDegree = () => {
  useResetState(resetDegreeState);
};
