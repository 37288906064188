import React, { useCallback, useMemo, useState, useRef } from 'react';
import WithPermission from '../../Common/WithPermission';
import POLICY from '~/constants/policy';
import { Link } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';
import { Button, Dropdown, Table, Space, Menu } from 'antd';
import { DownOutlined, PlusOutlined, UnorderedListOutlined,CloseOutlined } from '@ant-design/icons';
import { API_LIST, CARD_STYLE, INVOICE_STATUS } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import InvoiceTemplate from './InvoiceTemplate'
import { formatCurrency, formatDateTime } from '~/utils/helper';
import { useReactToPrint } from 'react-to-print';

import {
  SEARCH_PRODUCT_RETURN,
  useProductReturnColumnSetting,
  useProductReturnPaging,
  useProductReturnQueryParams,
  useProductReturns,
  useUpdateProductInfoReturn,
  useUpdateProductReturnParams,
} from '~/hooks';
import ModalUpdate from '~/components/WorldClinic/ProductReturn/ModalUpdate/ModalUpdate';
import { connect } from 'react-redux';
import { has } from 'lodash';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ExpandedRowRender from './Table';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import { onSelectRow } from '~/utils/validators';

const ProductReturn = ({summary}) => {
  const [query] = useProductReturnQueryParams();
  const [keyword, {setKeyword, onParamChange}] = useUpdateProductReturnParams(query)
  const [productReturns, isLoading] = useProductReturns(query);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const componentPrintRef = useRef();

  const handlePrintSelectInvoice = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const menu = (
    <Menu>
      <Menu.Item key={'infoGeneral'} onClick = {() =>  setIsModalVisible(true)}>
        Cập nhật thông tin chung
      </Menu.Item>
      <Menu.Item key={'print'} onClick={handlePrintSelectInvoice}>
        In
      </Menu.Item>
    </Menu>
  );

  const handleDeleteSelected = useCallback(() => {
    setSelectedRowKeys([])
  },[])
  const paging = useProductReturnPaging();
  const [columnMenu, selectedColumnKeys] = useProductReturnColumnSetting();
  const [isUpdatingProductInfoReturn,updateProductInfoReturn] = useUpdateProductInfoReturn();
  const [selectTable, setSelectTable]=useState([])

  const columns = useMemo(() => {
    const allColumns = [
      {
        title: 'Mã trả hàng',
        key: 'code',
        dataIndex: 'code',
      },
      {
        title: 'Người bán',
        key: 'receivedBy',
        dataIndex: ['receivedBy', 'fullName'],
        render:(_)=>(!!_?_:'')
      },
      {
        title: 'Thời gian',
        key: 'time',
        dataIndex: 'updatedAt',
        render: (updatedAt, record) =>
          !record.isSummaryRow && formatDateTime(updatedAt)
      },
      {
        title: 'Khách hàng',
        key: 'customer',
        dataIndex: 'customer',
        render: (customer)=> {
          const _has = (text)=> has(customer,[text])
          return  !!customer?`${ _has("name")?customer?.name : "" } ${_has("phoneNumber") ?(" - "+customer?.phoneNumber):""}`:"Khách lẻ"  }
      },
      {
        title: 'Cần trả khách',
        key: 'totalReturn',
        dataIndex: 'totalReturn',
        align: 'end',
        render: formatCurrency
      },
      {
        title: 'Đã trả khách',
        key: 'totalReturn',
        dataIndex: 'totalReturn',
        align: 'end',
        render: formatCurrency
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        render :(status)=> INVOICE_STATUS[status] 
      }
    ];

    return allColumns.filter(({key}) => !!selectedColumnKeys[key]);
  },[selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const handleUpdate=useCallback(({record,chooseUser,saleChannel,note})=>{
    updateProductInfoReturn({
      ids: [record._id] ,
      saleChannel: saleChannel ?? record.saleChannel,
      note:  note ?? record.note,
      receivedById: chooseUser?? record.receivedById
    })
  },[updateProductInfoReturn])

  return (
    <>
      <div className="page-wraper page-content">
        <div className="container-fluid">
          <div className="wc-page-header" style={{ paddingRight: 0 }}>
            <h1 className="wc-page-header__title">Phiếu trả hàng</h1>

            <div className="wc-page-header__search wclinic-search">
              <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_PRODUCT_RETURN}/>
            </div>

            <div className="wc-page-header__actions">
              <WithPermission permission={POLICY.WRITE_RETURN}>
                {
                  selectedRowKeys.length !== 0 && (
                    <Space align={'center'} size={'small'} className={'mr8px'}>
                      <Space align={'center'} size={'small'} className={'mr8px'}>
                        <p>Có {selectedRowKeys.length} phiếu trả hàng</p>
                        <div style={{display:'flex', cursor: 'pointer'}} onClick={handleDeleteSelected}>
                          <CloseOutlined />
                        </div>
                      </Space>
                      <div>
                        <Dropdown
                          overlay={menu}
                          arrow={true}
                        >
                          <Button type={'primary'}>Thao tác</Button>
                        </Dropdown>
                      </div>
                    </Space>
                  )
                }
                <Link to={PATH_CLINIC.sale.root}>
                  <Button
                    onClick={() => localStorage.setItem('isReturnProduct', 'true')}
                    type="primary"
                    style={{ float: 'right', marginRight: 10 }}
                  >
                    <PlusOutlined />
                    Trả hàng
                  </Button>
                </Link>
                <ButtonExportOption 
                router={API_LIST.productReturn} 
                label="Trả hàng" 
                query={query} 
                fileName={["Danhsachtrahang_",'Danhsachchitiettrahang_']} />

                <Dropdown
                  onVisibleChange={flag => setShowSettingColumn(flag)}
                  visible={isShowSettingColumn}
                  overlay={columnMenu}
                  trigger={['click']}
                  style={{ float: 'right' }}
                >
                  <Button type="primary" onClick={e => e.stopPropagation()}>
                    <UnorderedListOutlined /> <DownOutlined />
                  </Button>
                </Dropdown>
              </WithPermission>
            </div>
          </div>

          <div className="page-content__main">
            <div className="page-content__left hidden" style={CARD_STYLE}></div>

            <div className="page-content__right" style={CARD_STYLE}>
              {isLoading ? (
                <SkeletonTable
                  rowCount={productReturns.length}
                  columns={columns}
                  pagination={paging}
                />
              ) : (
                <Table
                  className="table--selectable permission-table"
                  size="middle"
                  rowClassName={record =>
                    record.isSummaryRow ? 'summary-row' : ''
                  }
                  rowKey={rc => rc._id}
                  columns={columns}
                  dataSource={productReturns}
                  onChange={({ current,pageSize }) => onParamChange({ page: current ,limit:pageSize})}
                  // summary={()=>(<Table.Summary fixed={"top"} >
                  //          <Table.Summary.Row key={-1} >
                  //             <Table.Summary.Cell align='right'colSpan={6} ><strong>{summary?.total?.toLocaleString()??0}</strong></Table.Summary.Cell>
                  //             <Table.Summary.Cell align='right'><strong>{summary?.totalPayment?.toLocaleString()??0}</strong></Table.Summary.Cell>
                  //         </Table.Summary.Row>
                  //     </Table.Summary>)}
                  pagination={
                    !query.id && {
                      ...paging,
                      // pageSize: 11,
                      showSizeChanger: true,
                      // total: Math.ceil(paging.total + paging.total / 10),
                      showTotal: () => `Tổng cộng: ${paging.total} `
                    }
                  }
                  onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}

                  onRow={onSelectRow(setSelectTable)}

                  expandable={{
                    expandedRowRender:(record)=><ExpandedRowRender record={record} handleUpdate={handleUpdate}/>,
                    rowExpandable: record => !record.isSummaryRow,
                    defaultExpandAllRows: !!query.id,
                    expandedRowKeys:[selectTable]
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <ModalUpdate
          selectedIds={selectedRowKeys}
          onClose = {() => setIsModalVisible(false)}
          isOpen={isModalVisible} 
          updateProductInfoReturn={updateProductInfoReturn}
          isUpdatingProductInfoReturn={isUpdatingProductInfoReturn}
          />
      </div>
      <div ref={componentPrintRef}>
        <InvoiceTemplate />
      </div>
    </>

  );
};
const mapState =(state)=> ({
  summary: state?.productReturn?.summary
})
export default connect(mapState)(ProductReturn) ;
