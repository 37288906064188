export const GET_SUPPLIERS_REQUEST = 'GET_SUPPLIERS_REQUEST';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_FAILED = 'GET_SUPPLIERS_FAILED';

export const GET_SUPPLIERS_AUTHENTICATED_REQUEST = 'GET_SUPPLIERS_AUTHENTICATED_REQUEST';
export const GET_SUPPLIERS_AUTHENTICATED_SUCCESS = 'GET_SUPPLIERS_AUTHENTICATED_SUCCESS';
export const GET_SUPPLIERS_AUTHENTICATED_FAILED = 'GET_SUPPLIERS_AUTHENTICATED_FAILED';

export const GET_SUPPLIER_REQUEST = 'GET_SUPPLIER_REQUEST';
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_FAILED = 'GET_SUPPLIER_FAILED';

export const CREATE_SUPPLIER_REQUEST = 'CREATE_SUPPLIER_REQUEST';
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS';
export const CREATE_SUPPLIER_FAILED = 'CREATE_SUPPLIER_FAILED';

export const UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST';
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER_FAILED = 'UPDATE_SUPPLIER_FAILED';

export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST';
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
export const DELETE_SUPPLIER_FAILED = 'DELETE_SUPPLIER_FAILED';

export const RESET_SUPPLIER_STATE = 'RESET_SUPPLIER_STATE';
