import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getPolicies({ payload: query }) {
  try {
    const data = yield call(Api.policy.getAll, query);
    yield put({ type: Types.GET_POLICIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_POLICIES_FAILED,
      payload: error.message
    });
  }
}

function* getPolicy({ payload }) {
  try {
    const policy = yield call(Api.policy.getById, payload);
    yield put({
      type: Types.GET_POLICY_SUCCESS,
      payload: policy
    });
  } catch (error) {
    yield put({
      type: Types.GET_POLICY_FAILED,
      payload: error.message
    });
  }
}

function* createPolicy(action) {
  try {
    const data = yield call(Api.policy.create, action.payload);
    yield put({ type: Types.CREATE_POLICY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_POLICY_FAILED,
      payload: error
    });
  }
}

function* updatePolicy(action) {
  try {
    const data = yield call(Api.policy.update, action.payload);
    yield put({ type: Types.UPDATE_POLICY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_POLICY_FAILED,
      payload: error
    });
  }
}

function* deletePolicy({ payload }) {
  try {
    yield call(Api.policy.delete, payload);
    yield put({ type: Types.DELETE_POLICY_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_POLICY_FAILED,
      payload: error
    });
  }
}

export default function* policy() {
  yield takeLatest(Types.GET_POLICIES_REQUEST, getPolicies);
  yield takeLatest(Types.GET_POLICY_REQUEST, getPolicy);
  yield takeLatest(Types.DELETE_POLICY_REQUEST, deletePolicy);
  yield takeLatest(Types.CREATE_POLICY_REQUEST, createPolicy);
  yield takeLatest(Types.UPDATE_POLICY_REQUEST, updatePolicy);
}
