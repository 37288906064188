import React from 'react'

function WithRules({rules=[],children}) {
    const  isRules = [...rules].some((e)=>!!e)
    if(isRules||rules.length===0){
        return <>{children}</>
    }
    return (
        <></>
    )
}

export default WithRules
