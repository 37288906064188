import React, {
    forwardRef, useEffect
  } from 'react';
  
  import { Select, Spin } from 'antd';
  import { debounce, get, isObject } from 'lodash';
  const DebounceSelectInitValue = (
    {
      fetchOptions,
      debounceTimeout = 500,
      labelKey = 'name',
      valueKey = '_id',
      searchKey = 'name',
      initOptions,
      valueInit,
      onSelect,
      disabled,
      ...props
    },
    ref
  ) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    useEffect(() => {
      if (initOptions) {
        const newOptions = initOptions.map((option) => ({
          label: get(option, labelKey),
          value: get(option, valueKey),
        }));
        setOptions(newOptions);
      }
    }, [initOptions]);
    // Fetch API select
    const debounceFetcher = React.useMemo(() => {
      const loadOptions = async (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions([]);
        setFetching(true);
        const response = await fetchOptions({ [searchKey]: value || '' })
        const newOptions = response.map((option) => {
          const showOption = {
            label: `${get(option, labelKey)}`,
            value: get(option, valueKey)
          }
          return {
            ...option,
            ...showOption
          };
        });
        setOptions(newOptions);
        setFetching(false);
      };
  
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    const onDropVisibleChange = async(open) => {
      if(open){
        setFetching(true);
        const response = await fetchOptions()
        const newOptions = response.map((option) => {
          const showOption = {
            label: `${get(option, labelKey)}`,
            value: get(option, valueKey)
          }
          return {
            ...option,
            ...showOption
          };
        });
        setOptions(newOptions);
        setFetching(false);
      }
    }
    const transformValue = (value) => {
      if (!isObject(value) && initOptions) {
        const currentOption = initOptions.find(
          (item) => get(item, valueKey) === value
        );
        if (currentOption)
          return {
            value,
            label: get(currentOption, labelKey)
          };
        return value;
      }
      return value;
    };
    return (
      <Select
        ref={ref}
        disabled={disabled}
        showSearch
        labelInValue
        onSelect={(e, index) => {
          if(onSelect && typeof onSelect === 'function'){
          onSelect(e)
          }
        }}
        filterOption={false}
        onSearch={debounceFetcher}
        onDropdownVisibleChange={onDropVisibleChange}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        value={transformValue(valueInit)}
        options={options}
      />
    );
  };
  
  export default forwardRef(DebounceSelectInitValue);
  