import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],
  summary: null,
  
  listAuthenticated: [],
  getListAuthenticatedFailed: null,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_SUPPLIERS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_SUPPLIERS_SUCCESS:
      state.isLoading = false;
      state.summary= payload?.summary
      state.list = payload?.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_SUPPLIERS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_SUPPLIER_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_SUPPLIER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_SUPPLIER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_SUPPLIER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_SUPPLIER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.list = [...state.list, payload];
      return;

    case Types.CREATE_SUPPLIER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_SUPPLIER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_SUPPLIER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_SUPPLIER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_SUPPLIER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_SUPPLIER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_SUPPLIER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

      case Types.GET_SUPPLIERS_AUTHENTICATED_REQUEST:
        state.isLoading = true;
        return;
  
    case Types.GET_SUPPLIERS_AUTHENTICATED_SUCCESS:
        state.isLoading = false;
        state.listAuthenticated = payload;
        return;
  
      case Types.GET_SUPPLIERS_AUTHENTICATED_FAILED:
        state.isLoading = false;
        state.getListAuthenticatedFailed = payload;
        state.list = [];
      return;
    
    case Types.RESET_SUPPLIER_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
