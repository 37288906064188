import * as Types from '~/constants/actionTypes';

export const getPurchaseOrderQuotations = query => ({
  type: Types.GET_PURCHASE_ORDER_QUOTATIONS_REQUEST,
  payload: query
});

export const getPurchaseOrderQuotation = id => ({
  type: Types.GET_PURCHASE_ORDER_QUOTATION_REQUEST,
  payload: id
});

export const createPurchaseOrderQuotation = purchase => ({
  type: Types.CREATE_PURCHASE_ORDER_QUOTATION_REQUEST,
  payload: purchase
});

export const updatePurchaseOrderQuotation = purchase => ({
  type: Types.UPDATE_PURCHASE_ORDER_QUOTATION_REQUEST,
  payload: purchase
});

export const deletePurchaseOrderQuotation = id => ({
  type: Types.DELETE_PURCHASE_ORDER_QUOTATION_REQUEST,
  payload: id
});

export const resetPurchaseOrderQuotationState = () => ({
  type: Types.RESET_PURCHASE_ORDER_QUOTATION_STATE
});
