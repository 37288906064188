import React, { useEffect, useState, useMemo } from 'react';
import { Table, Input, Tag, Spin, Row, Col, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useProductLot } from '~/hooks';
import { formatDate, formatNumber, isMatchFilter } from '~/utils/helper';
import { API_LIST, STOCK_STATUS } from '~/constants/defaultValue';
import { get, isEmpty, negate, pickBy } from 'lodash';
import  moment  from "moment";
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
  str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
  str = str.replace(/đ/g,"d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g," ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
  return str;
}
const convertDate = (dateCovert) => {
  const regex = /[0-9]{2,4}[-/][0-9]{2}[-/][0-9]{2}/g;
  const regexConvert = /[0-9]{2}[-/][0-9]{2}[-/][0-9]{4}/g;
  let temp
  if (regex.test(dateCovert) || regexConvert.test(dateCovert)) {
    temp = dateCovert.replace(/[-/]/ig, '-');
    if (regexConvert.test(dateCovert)) {
      temp = temp.split(/-/).reverse().join('-');
    }
  }
  return temp ?? dateCovert
}
const Lot = ({ product ,keySearch }) => {
  const [ search , setSearch]= useState( pickBy({id:product?._id,page: 1,limit:10 , keyword: get(keySearch,'lotNumber',get(keySearch,'expirationDate','')) },negate(isEmpty) ));
  const [lot] = useProductLot(search);
  const selectedVariant = get(product,['selectedVariant','quantity'],1)
  const data = lot[product._id];
  const docs = get( data,'docs',[])
  const paging = get(data,'paging',{})
  const totalQuantity = get(paging,'totalQuantity',0)
  // const [search, setSearch] = useState('');
  // const dataSource = useMemo(() => {
  //   if (!data) return [];

  //   const filterdData = data.filter(
  //     item =>
  //     isMatchFilter(removeVietnameseTones(item?.lotNumber), removeVietnameseTones(search)) ||
  //     isMatchFilter(removeVietnameseTones(formatDate(item?.expirationDate)), removeVietnameseTones(search))
  //   );

  //   return [{ lotNumber: -1, isSummaryRow: true }, ...filterdData];
  // }, [data, search]);

  // const total = product?.selectedVariant?.totalQuantity

  // useEffect(() => {
  //   if (variantId) {
  //     setSearch('');
  //   }
  // }, [variantId]);

  const columns = [
    {
      title: 'Số lô',
      key: 'lotNumber',
      render: record => {
        return record.isSummaryRow ? (
          <Input
            value={search}
            onChange={e => setSearch((value)=>({...value, keyword: e.target.value}))}
            placeholder="Tìm theo lô, hạn sử dụng"
            style={{ width: 250 }}
            suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
          />
        ) : (
          <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
            {record?.lotNumber}
          </Tag>
        );
      }
    },
    {
      title: 'Hạn sử dụng',
      key: 'expirationDate',
      align: 'end',
      render: record =>
        !record.isSummaryRow &&( !isNaN(new Date(record?.expirationDate).valueOf()) ?  moment(new Date(String(record?.expirationDate)?.split('T')[0])).format('DD/MM/YYYY'):'') //formatDate(record?.expirationDate)
    },
    {
      title: 'Số lượng',
      align: 'end',
      render: record => Number(get(record,'quantity',0) / selectedVariant).toLocaleString('vi-VN')
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: status => STOCK_STATUS[status]
    }
  ];

  const checkDate = (ar)=>{
    if(!isNaN(new Date((ar)).valueOf())) 
    return moment(ar).format('DD/MM/YYYY')
    return ''
  }

  return (
    <div className="medicine-detail-tab-item medicine-remain-tab">
      <Row gutter={48}> 
        <Col lg={8}>
          <Input.Search 
            placeholder='Tìm kiếm trong các Lô' 
            allowClear 
            enterButton 
            defaultValue={get(keySearch,'lotNumber', checkDate(convertDate(get(keySearch,'expirationDate',''))) )} 
            onSearch={(e)=>{
                setSearch((value)=>( pickBy({...value,page:1, keyword: (e??'').trim()},negate(isEmpty)) ))
              }} />
        </Col>
        <Col lg={12} align={'left'}>
          <Space> <strong> Tổng số lượng</strong> <span style={{fontSize: 16}}>{Number(totalQuantity).toLocaleString('vi-VN')}</span></Space>
        </Col>
      </Row>
      <Spin spinning={!docs}>
        <Table
          className="nested-table"
          style={{ marginTop: 20, marginBottom: 30, marginLeft: -20 }}
          size="small"
          columns={columns}
          dataSource={docs}
          rowKey={({ lotNumber }) => lotNumber}
          onChange={(value)=>{setSearch((searchValue)=> ({...searchValue,page:value.current,limit:value.pageSize})) }}
          pagination={ get(paging,'totalDocs',0)<=10?false: {
            current:get(paging,'page',''),
            pageSize:get(paging,'limit',''),
            total: get(paging,'totalDocs',''),
            showSizeChanger: false,
            showTotal: total => `Tổng cộng: ${total} `
          }}
          bordered
          rowClassName={record => (record.isSummaryRow ? 'summary-row' : '')}
        />
      </Spin>
      <WithPermission permission={POLICIES.WRITE_PRODUCT}>
          <Typography.Paragraph style={{ textAlign: 'right' }}>
            <ButtonExport 
              fileName={'Danhsachlohansudung'}
              router={API_LIST.product} 
              label="Hàng hóa" 
              id={search.id}
              exportOption={3}
            />
          </Typography.Paragraph>
        </WithPermission>
    </div>
  );
};

export default Lot;
