import * as Types from '~/constants/actionTypes';

export const getProductReturns = query => ({
  type: Types.GET_PRODUCT_RETURNS_REQUEST,
  payload: query
})

export const returnProduct = product => ({
  type: Types.RETURN_PRODUCT_REQUEST,
  payload: product
});

export const exchangeProduct = product => ({
  type: Types.EXCHANGE_PRODUCT_REQUEST,
  payload: product
});

export const updateProductInfoReturn = product => ({
  type: Types.UPDATE_PRODUCT_INFO_RETURN_REQUEST,
  payload: product
});

export const getReceivers = query => ({
  type: Types.GET_RECEIVERS_REQUEST,
  payload: query
});
