export const GET_MY_NOTIFICATIONS_REQUEST = 'GET_MY_NOTIFICATIONS_REQUEST';
export const GET_MY_NOTIFICATIONS_SUCCESS = 'GET_MY_NOTIFICATIONS_SUCCESS';
export const GET_MY_NOTIFICATIONS_FAILED = 'GET_MY_NOTIFICATIONS_FAILED';

export const CHANGE_STATUS_NOTIFICATION_REQUEST = 'CHANGE_STATUS_NOTIFICATION_REQUEST';
export const CHANGE_STATUS_NOTIFICATION_SUCCESS = 'CHANGE_STATUS_NOTIFICATION_SUCCESS';
export const CHANGE_STATUS_NOTIFICATION_FAILED = 'CHANGE_STATUS_NOTIFICATION_FAILED';

export const CHANGE_MANY_STATUS_NOTIFICATION_REQUEST = 'CHANGE_MANY_STATUS_NOTIFICATION_REQUEST';
export const CHANGE_MANY_STATUS_NOTIFICATION_SUCCESS = 'CHANGE_MANY_STATUS_NOTIFICATION_SUCCESS';
export const CHANGE_MANY_STATUS_NOTIFICATION_FAILED = 'CHANGE_MANY_STATUS_NOTIFICATION_FAILED';


export const RESET_MY_NOTIFICATION_STATE = 'RESET_MY_NOTIFICATION_STATE';
