import { AutoComplete, Button, Form, Input, Select, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, {
    useContext,
    useRef,
    useState,
    createContext,
    useEffect,
    useCallback,
    // useMemo
} from 'react';
import { useGetValueContextPurchaseOrder } from './contextProvider';
import api from '~/api';
import { filterAcrossAccents } from '~/hooks/utils';
const EditableContext = createContext(null);
const TableContext = createContext({listUnit:[]});
const EditableRow = ({index,...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={{...form, index:Number(props['data-row-key'])}}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
  
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const refSearch = useRef('');
  const {index,...form} = useContext(EditableContext);
  const {listUnit} = useContext(TableContext);

    const listSuggest = useState([])
    useEffect(() => {
        if (editing) inputRef.current?.focus();
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
    const  handleSearch = useCallback(async(value) => {
      if(!!value){
        const dataSearch = await api.purchaseOrderQuotation.suggestProduct(value.trim());
        listSuggest[1](dataSearch.map(({name,_id})=>({value:name,label:name,key:_id})));
      }else{
        listSuggest[1]([]);
      }
    },[listSuggest]);
    // const handleSearchUnit = useCallback(async(value) => {
    //     const dataSearch = await api.purchaseOrderQuotation.suggestUnit(value.trim());
    //     listSuggest[1](dataSearch.map(({name,_id})=>({value:name,label:name,key:_id})));
    // },[listSuggest]);

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `Bắt buộc có ${title}`
                    }
                ]}
                
            >
                {(() => {
                    switch (dataIndex) {
                        case 'name':
                            return (
                                <AutoComplete
                                    tabIndex={index}
                                    allowClear
                                    ref={inputRef}
                                    options={listSuggest[0]}
                                    onFocus={()=>{
                                      if(!refSearch.current){
                                            handleSearch('')
                                        }
                                    }}
                                  
                                    onSearch={value => {
                                      if(refSearch.current){
                                            clearTimeout(refSearch.current)
                                        }
                                      refSearch.current = setTimeout(()=>{
                                            handleSearch(value)
                                        refSearch.current=null
                                      },500)
                                      
                                    }}
                                >
                                    <Input />
                                </AutoComplete>
                            );
                        case 'unit':
                            return ( 
                                <Select
                                    allowClear
                                    tabIndex={index}
                                    ref={inputRef}
                                    defaultOpen
                                    filterOption={filterAcrossAccents}
                                    options={listUnit}
                                />
                            );

                        default:
                            return (
                                <Input
                                    ref={inputRef}
                                    tabIndex={index}
                                    type={
                                        dataIndex === 'quantity'
                                            ? 'number'
                                            : 'text'
                                    }
                                    step={10}
                                    onPressEnter={save}
                                    onBlur={save}
                                />
                            );
                    }
                })()}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children ?? ' '}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};


function ModalCreate() {
    const [form] = Form.useForm();
    const [count, setCount] = useState(1);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const listSuggest = useState([]);
    const refSearchSupplier = useRef()
    const [dataSource, setDataSource] = useState([
        {
            key: '0',
            name: 'Tên hàng',
            quantity: 1,
            unit: 'Thùng',
            // productId: '',
            // variantId: '',
        }
    ]);
    // const [listUnit, setListUnit] = useState([]);
    // useEffect(() => {
    //     (()=>{
    //         api.purchaseOrderQuotation.suggestUnit().then((dataSearch)=>{
    //             setListUnit(dataSearch.map(({name,_id})=>({value:name,label:name,key:_id})))

    //         });
    //     })();
    
    // }, []);
    const { onCreate, closeModal,listUnit } = useGetValueContextPurchaseOrder();
    const column = [
        {
            title: 'Tên mặt hàng',
            key: 'name',
            dataIndex: 'name',
            width: '50%',
            editable: true
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 140,
            align: 'center',
            editable: true
        },
        {
            title: 'Đơn vị',
            dataIndex: 'unit',
            key: 'unit',
            editable: true
        },
        {
            title: 'Hành động',
            dataIndex: '_id',
            width: 110,
            align: 'center',
            render: (id, rc, idx) => {
                return (
                    <Button
                        onClick={() => handleDelete(rc.key)}
                        danger
                        size="small"
                    >
                        Xoá
                    </Button>
                );
            }
        }
    ];
    function handleSave(data) {
        const row = { ...data, quantity: Number(data?.quantity) };
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        setDataSource(newData);
    }
    const columns = column.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave
            })
        };
    });

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    function handleDelete(key) {
        const newData = dataSource.filter(item => item.key !== key);
        setDataSource(newData);
    }  
    const handlesSuggestSupplier = useCallback(async(value) => {
      const dataSearch = await api.purchaseOrderQuotation.suggestSupplier(value.trim());
      listSuggest[1](dataSearch.map(({name,_id})=>({value:name,label:name,key:_id})));
  },[listSuggest]);
    const handleAdd = () => {
        const newData = {
            key: count,
            name: `Tên hàng ${count}`,
            quantity: 1,
            unit: `Thùng`
        };
        setDataSource([newData,...dataSource ]);
        setCount(count + 1);
    };
    return (
        <div>
            <Form
                form={form}
                wrapperCol={{ span: 18 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
            >
                <Form.Item
                    name="supplier"
                    label="Nhà cung cấp"
                    rules={[
                        { required: true, message: 'Nhà cung cấp là bắt buộc' }
                    ]}
                >
                  <AutoComplete
                    allowClear
                    options={listSuggest[0]}
                    autoFocus
                    onFocus={()=>{
                      if(!refSearchSupplier.current){
                        handlesSuggestSupplier('');

                      }
                    }}
                    onSearch={(value)=>{
                      if(refSearchSupplier.current){
                        clearTimeout(refSearchSupplier.current);
                       
                      }
                      refSearchSupplier.current = setTimeout(()=>{
                        handlesSuggestSupplier(value);
                        refSearchSupplier.current = null;
                        console.log('refSearchSupplier.current',refSearchSupplier.current);
                      },500)
                    }}>
                    <Input autoFocus />
                  </AutoComplete>
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                    <TextArea rows={1} />
                </Form.Item>
            </Form>
            <Button
                onClick={handleAdd}
                type="primary"
                style={{ marginBottom: 16 }}
            >
                Thêm hàng
            </Button>
            <TableContext.Provider value={{listUnit}}>
                <Table
                    bordered
                    components={components}
                    rowClassName={() => 'editable-row'}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ y: '50vh' }}
                    columns={columns}
                />
            </TableContext.Provider>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: 32
                }}
            >
                <Button onClick={closeModal}>Huỷ</Button>
                <Button
                    loading={loadingSubmit}
                    type="primary"
                    onClick={async () => {
                        try {
                            await form.validateFields();
                            setLoadingSubmit(true);
                            onCreate(
                                {
                                    ...form.getFieldsValue(),
                                    orderItems: dataSource
                                },
                                () => setLoadingSubmit(false)
                            );
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                >
                    Tạo đơn
                </Button>
            </div>
        </div>
    );
}

export default ModalCreate;
