import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getDirectory ({payload: query}){
  try{
    const data = yield call(Api.medicineDirectory.getAll, query)

    yield put({type: Types.GET_DIRECTORY_SUCCESS, payload: data})
  } catch(error){
    yield put({
      type: Types.GET_DIRECTORY_FAILED,
      payload: error
    });
  }
}

export default function* directory(){
  yield takeLatest(Types.GET_DIRECTORY_REQUEST, getDirectory);
}
