import React from 'react';
import { Spin, Table } from 'antd';
import { useProductStock } from '~/hooks';
import { STOCK_STATUS } from '~/constants/defaultValue';
import { formatDate, formatNumber } from '~/utils/helper';

const columns = [
  {
    title: 'Chi nhánh',
    dataIndex:['branchId','name'],
    key: 'branch'
  },
  {
    title: 'Tồn kho',
    dataIndex: 'totalStock',
    key: 'totalStock',
    align: 'end',
    render: quantity => formatNumber(quantity)
  },
  {
    title: 'KH đặt',
    dataIndex: 'totalOrderQuantity',
    key: 'totalOrderQuantity',
    align: 'end',
    render: quantity => formatNumber(quantity)
  },
  {
    title: 'Dự kiến hết hàng',
    dataIndex: 'outOfStockDate',
    key: 'outOfStockDate',
    render: date => formatDate(date),
    align: 'end'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: status => STOCK_STATUS[status]
  }
];

const Stock = ({ product }) => {
  const [stock] = useProductStock(product?._id);

  const dataSource = stock[product._id];
  return (
    <div className="medicine-detail-tab-item medicine-remain-tab">
      <Spin spinning={!dataSource}>
        <Table
          className="nested-table"
          style={{ marginTop: 20, marginBottom: 30, marginLeft: -20 }}
          size="small"
          columns={columns}
          dataSource={dataSource}
          rowKey={rc => rc.totalStock}
          pagination={false}
          bordered
          rowClassName={record => (record.isSummaryRow ? 'summary-row' : '')}
        />
      </Spin>
    </div>
  );
};

export default Stock;
