import produce from 'immer';
import { find, get } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PROCESSES_IMPORT_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PROCESSES_IMPORT_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PROCESSES_IMPORT_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_PROCESS_IMPORT_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_PROCESS_IMPORT_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_PROCESS_IMPORT_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_PROCESS_IMPORT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PROCESS_IMPORT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.options = [payload, ...state.options];
      return;

    case Types.CREATE_PROCESS_IMPORT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_PROCESS_IMPORT_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PROCESS_IMPORT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_PROCESS_IMPORT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_PROCESS_IMPORT_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_PROCESS_IMPORT_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_PROCESS_IMPORT_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.FETCH_EVENT_PROCESS_IMPORT:
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.CREATE_EVENT_PROCESS_IMPORT:
      state.list = [payload].concat(state.list);
      return;

    case Types.UPDATE_EVENT_PROCESS_IMPORT:
      state.list = state.list.map((el, i) => {
        if (el._id === payload._id) {
          if (payload?.status) {
            el.status = payload.status;
            el.fileError = payload.fileError;
          } else el.process = payload.process;
        }
        return el;
      });
      return;

    case Types.RESET_PROCESS_IMPORT_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
