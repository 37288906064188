import produce from 'immer';
import getPaging from '~/utils/getPaging';
import * as Types from '~/constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],
  summary:null,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  isSearchReceivers: false,
  receivers: null,
  searchReceiversFailedSelector: null,

  updateSuccess: null,
  updateFailed: null,

  paging: { current: 1, pageSize: 11 }
};

export default produce((state, {type, payload}) => {
  switch (type){
    case Types.GET_PRODUCT_RETURNS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PRODUCT_RETURNS_SUCCESS:
      state.isLoading = false;
      state.summary= { ...payload.summary, isSummaryRow: true, _id: -1 }
      state.list = [
        // { ...payload.summary, isSummaryRow: true, _id: -1 },
        ...payload.docs
      ];
      state.paging = getPaging(payload);
      return;

    case Types.GET_PRODUCT_RETURNS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_RECEIVERS_REQUEST:
      state.isSearchReceivers = true;
      state.receivers = null;
      return;

    case Types.GET_RECEIVERS_SUCCESS:
      state.isSearchReceivers = false;
      state.receivers = [
        ...payload.docs
      ];

      return;

    case Types.GET_RECEIVERS_FAILED:
      state.isSearchReceivers = false;
      state.receivers = null;
      return;

    case Types.RETURN_PRODUCT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.RETURN_PRODUCT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.RETURN_PRODUCT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.EXCHANGE_PRODUCT_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.EXCHANGE_PRODUCT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      return;

    case Types.EXCHANGE_PRODUCT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.UPDATE_PRODUCT_INFO_RETURN_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PRODUCT_INFO_RETURN_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_PRODUCT_INFO_RETURN_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    default:
      return;
  }
}, initState)
