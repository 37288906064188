export const GET_STAFF_GROUPS_REQUEST = 'GET_STAFF_GROUPS_REQUEST';
export const GET_STAFF_GROUPS_SUCCESS = 'GET_STAFF_GROUPS_SUCCESS';
export const GET_STAFF_GROUPS_FAILED = 'GET_STAFF_GROUPS_CATEGORIES_FAILED';

export const GET_STAFF_GROUP_REQUEST = 'GET_STAFF_GROUP_REQUEST';
export const GET_STAFF_GROUP_SUCCESS = 'GET_STAFF_GROUP_SUCCESS';
export const GET_STAFF_GROUP_FAILED = 'GET_STAFF_GROUP_FAILED';

export const CREATE_STAFF_GROUP_REQUEST = 'CREATE_STAFF_GROUP_REQUEST';
export const CREATE_STAFF_GROUP_SUCCESS = 'CREATE_STAFF_GROUP_SUCCESS';
export const CREATE_STAFF_GROUP_FAILED = 'CREATE_STAFF_GROUP_FAILED';

export const UPDATE_STAFF_GROUP_REQUEST = 'UPDATE_STAFF_GROUP_REQUEST';
export const UPDATE_STAFF_GROUP_SUCCESS = 'UPDATE_STAFF_GROUP_SUCCESS';
export const UPDATE_STAFF_GROUP_FAILED = 'UPDATE_STAFF_GROUP_FAILED';

export const DELETE_STAFF_GROUP_REQUEST = 'DELETE_STAFF_GROUP_REQUEST';
export const DELETE_STAFF_GROUP_SUCCESS = 'DELETE_STAFF_GROUP_SUCCESS';
export const DELETE_STAFF_GROUP_FAILED = 'DELETE_STAFF_GROUP_FAILED';

export const RESET_STAFF_GROUP_STATE = 'RESET_STAFF_GROUP_STATE';
