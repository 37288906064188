import React from 'react';
import PropTypes from 'prop-types';
import { filterAcrossAccents } from '~/hooks/utils';
import { connect } from 'react-redux';
import { useSelectBranch, useProfile, useUserPolicy } from '~/hooks';
import { Link } from 'react-router-dom';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from '../../redux/action';
// reactstrap
import { Row } from 'reactstrap';

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import logo from '../../assets/images/logo-white.svg';
import logoLight from '../../assets/images/logo-white.svg';
import logoDark from '../../assets/images/logo-white.svg';

//i18n
import { withTranslation } from 'react-i18next';
import { Select } from 'antd';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';

const Header = props => {
  const [brandId] = useSelectBranch();
  const [isGetPolicyLoading, getPolicy] = useUserPolicy();
  const [profile] = useProfile();
  return (
    <React.Fragment>
      <div className='navbar-header'>
        <div className='d-flex'>
          <div className='navbar-brand-box'>
            <Link to='/' className='logo logo-dark'>
              <span className='logo-sm'>
                <img src={logo} alt='' height='22' />
              </span>
              <span className='logo-lg'>
                <img src={logoDark} alt='' height='17' />
              </span>
            </Link>

            <Link to='/' className='logo logo-light'>
              <span className='logo-sm'>
                <img src={logo} alt='' height='22' />
              </span>
              <span className='logo-lg'>
                <img src={logoLight} alt='' height='19' />
              </span>
            </Link>
          </div>

          <button
            type='button'
            className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light'
            data-toggle='collapse'
            onClick={() => {
              props.toggleLeftmenu(!props.leftMenu);
            }}
            data-target='#topnav-menu-content'>
            <i className='fa fa-fw fa-bars' />
          </button>

          {/* <form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <span className="uil-search"></span>
            </div>
          </form> */}
        </div>

        <div className='d-flex'>
          <div className='dropdown d-inline-block d-lg-none ms-2'>
            {/* <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <i className="uil-search"></i>
            </button>
            <div
              className={
                isSearch
                  ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
                  : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t('Search') + '...'}
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div> */}
          </div>

          <Row style={{ alignItems: 'center' }}>
            <Select
              style={{ width: 250 }}
              className='select-branch-dropdown'
              autoComplete='off'
              filterOption={filterAcrossAccents}
              showSearch
              value={+brandId}
              onChange={value => value && getPolicy(value)}
              disabled={isGetPolicyLoading}
              loading={isGetPolicyLoading}
              suffixIcon={
                <div className='navbar-header__location-icon'>
                  <img src='./pin.png' alt="" />
                </div>
              }>
              {profile?.branches?.map(({ name, _id }) => (
                <Select.Option value={_id} key={_id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Row>

          {/* <LanguageDropdown />

          <Dropdown
            className="d-none d-lg-inline-block ms-1"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              caret
              tag="button"
            >
              <i className="bx bx-customize" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" right>
              <div className="px-lg-2">
                <Row className="g-0">
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={github} alt="Github" />
                      <span>GitHub</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={bitbucket} alt="bitbucket" />
                      <span>Bitbucket</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={dribbble} alt="dribbble" />
                      <span>Dribbble</span>
                    </Link>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={dropbox} alt="dropbox" />
                      <span>Dropbox</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={mail_chimp} alt="mail_chimp" />
                      <span>Mail Chimp</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={slack} alt="slack" />
                      <span>Slack</span>
                    </Link>
                  </Col>
                </Row>
              </div>
            </DropdownMenu>
          </Dropdown> */}

          {/* <div className="dropdown d-none d-lg-inline-block ms-1">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              onClick={() => {
                toggleFullscreen();
              }}
              data-toggle="fullscreen"
            >
              <i className="uil-minus-path"></i>
            </button>
          </div> */}

          {/* <NotificationDropdown/> */}

          <ProfileMenu />

          <div className='dropdown d-inline-block'>
            <button
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              type='button'
              className='btn header-item noti-icon right-bar-toggle waves-effect'>
              <i className='uil-cog'></i>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
