import React from 'react';
import EmployeeGroup from './EmployeeGroup';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import './employeeGroup.scss';

const EmployeeGroupRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={EmployeeGroup} />
      <Route path={`${path}/:groupId`} component={EmployeeGroup} />
      <Redirect to={path} component={EmployeeGroup}></Redirect>
    </Switch>
  );
};

export default EmployeeGroupRoutes;
