import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import {
  useCustomerOrderHistory,
  useCustomerPaging,
  useCustomerQueryParams,
} from '~/hooks';
import moment from 'moment';
import { INVOICE_STATUS } from '~/constants/defaultValue';

const columns = [
  {
    title: 'Mã hóa đơn',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Thời gian',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (_) => moment(_).format('DD-MM-YYYY')
  },
  {
    title: 'Người đặt hàng',
    dataIndex: 'involvedBy',
    key: 'involvedBy'
  },
  {
    title: 'Tổng cộng',
    dataIndex: 'saleOrderDetail',
    key: 'saleOrderDetail',
    align: 'end',
    render: (_) => {
      return _.reduce((acc, crr) => acc + crr.cost, 0)
    }
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render : (status) => INVOICE_STATUS[status]
  }
];

const OrderHistory = ({ customerId }) => {
  const [dataSource, setDataSource] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [queryHistory] = useCustomerQueryParams();
  const [history] = useCustomerOrderHistory({
    ...queryHistory,
    page: currentPage
  }, customerId);

  useEffect(() => {
    setDataSource(history)
  }, [history])

  const paging = useCustomerPaging();

  return (
    <div className="medicine-detail-tab-item medicine-remain-tab">
      <Spin spinning={!dataSource}>
        <Table
          className="nested-table"
          style={{ marginTop: 20, marginBottom: 30, marginLeft: -20 }}
          size="small"
          columns={columns}
          dataSource={dataSource}
          rowKey={rc => rc.code}
          bordered
          rowClassName={record => (record.isSummaryRow ? 'summary-row' : '')}
          onChange={({ current }) => {
            setCurrentPage(current)}
          }
          pagination={{
            ...paging,
            pageSize: 11,
            total: Math.ceil(paging.total + paging.total / 10),
            showTotal: () => `Tổng cộng: ${paging.total} `
          }}
        />
      </Spin>
    </div>
  );
};

export default OrderHistory;
