import React, { useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Button,
  Skeleton,
  Modal,
  Col,
  DatePicker,
  InputNumber
} from 'antd';
import { useCreateBatch } from '~/hooks';
import moment from 'moment';

const FormItem = Form.Item;

const BatchForm = ({ isOpen, onClose, callBack, variant }) => {
  const [form] = Form.useForm();
  const onSuccess = batch => {
    callBack?.(batch);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, form]);

  const [isLoading, createBatch] = useCreateBatch(onSuccess);

  const onFinish = values => {
    const getUtcDate = date => moment(date).utc('+07:00').startOf('day').toISOString();

    createBatch({
      ...values,
      variantId: variant._id,
      expirationDate: getUtcDate(values.expirationDate),
      manufacturingDate: getUtcDate(values.manufacturingDate),
      productId: variant.productId
    });
  };

  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Row style={{ marginBottom: 20 }}>
          <h4 style={{ marginRight: 'auto' }}>
            Thêm lô cho {variant?.variantCode}
          </h4>
        </Row>

        <Form
          onFinish={onFinish}
          form={form}
          style={{ paddingLeft: 0 }}
          autoComplete="off"
          scrollToFirstError
          requiredMark={false}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
        >
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Lô"
                name="lotNumber"
                rules={[{ required: true, message: 'Xin mời nhập tên lô' }]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              {' '}
              <FormItem
                label="Ngày sản xuất"
                name="manufacturingDate"
                rules={[
                  { required: true, message: 'Xin mời nhập ngày sản xuất' }
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Hạn sử dụng"
                name="expirationDate"
                rules={[
                  { required: true, message: 'Xin mời nhập hạn sử dụng' }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <DatePicker style={{ width: '100%' }} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Số lượng"
                name="quantity"
                rules={[{ required: true, message: 'Xin mời nhập số lượng' }]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber style={{ width: '100%' }} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row
            className="wc-setting-form__submit-box"
            style={{ marginTop: 10 }}
          >
            {false ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit">
              Thêm lô
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default BatchForm;
