import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import {
  GET_USER_EMPLOYEES_REQUEST,
  GET_USER_EMPLOYEES_SUCCESS,
  GET_USER_EMPLOYEES_FAILED,
  GET_USER_EMPLOYEE_REQUEST,
  GET_USER_EMPLOYEE_SUCCESS,
  GET_USER_EMPLOYEE_FAILED,
  CREATE_USER_EMPLOYEE_REQUEST,
  CREATE_USER_EMPLOYEE_SUCCESS,
  CREATE_USER_EMPLOYEE_FAILED,
  UPDATE_USER_EMPLOYEE_REQUEST,
  UPDATE_USER_EMPLOYEE_SUCCESS,
  UPDATE_USER_EMPLOYEE_FAILED,
  DELETE_USER_EMPLOYEE_REQUEST,
  DELETE_USER_EMPLOYEE_SUCCESS,
  DELETE_USER_EMPLOYEE_FAILED,
  CREATE_USERNAME_SUCCESS,
  CREATE_USERNAME_FAILED,
  CREATE_USERNAME_REQUEST
} from '~/constants/actionTypes';

function* getUserEmployees({ payload: query }) {
  try {
    const response = yield call(Api.userEmployee.getAll, query);
    yield put({ type: GET_USER_EMPLOYEES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_USER_EMPLOYEES_FAILED, payload: error.message });
  }
}

function* getUserEmployee({ payload: id }) {
  try {
    const { data } = yield call(Api.userEmployee.getById, id);
    yield put({ type: GET_USER_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_USER_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* createUserEmployee(action) {
  try {
    const data = yield call(Api.userEmployee.create, action.payload);
    yield put({ type: CREATE_USER_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_USER_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* updateUserEmployee(action) {
  try {
    const data = yield call(Api.userEmployee.update, action.payload);
    yield put({ type: UPDATE_USER_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_USER_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* deleteUserEmployee({ payload }) {
  try {
    yield call(Api.userEmployee.delete, payload);
    yield put({ type: DELETE_USER_EMPLOYEE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_USER_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* createUsername(action) {
  try {
    const { username } = yield call(Api.userEmployee.autoUsername,action.payload.data);
    action.payload.callback(username);
    yield put({ type: CREATE_USERNAME_SUCCESS, payload: username });
  } catch (error) {
    yield put({ type: CREATE_USERNAME_FAILED, payload: error.message });
  }
}

export default function* userEmployee() {
  yield takeLatest(GET_USER_EMPLOYEES_REQUEST, getUserEmployees);
  yield takeLatest(GET_USER_EMPLOYEE_REQUEST, getUserEmployee);
  yield takeLatest(DELETE_USER_EMPLOYEE_REQUEST, deleteUserEmployee);
  yield takeLatest(CREATE_USER_EMPLOYEE_REQUEST, createUserEmployee);
  yield takeLatest(UPDATE_USER_EMPLOYEE_REQUEST, updateUserEmployee);
  yield takeLatest(CREATE_USERNAME_REQUEST, createUsername);
}
