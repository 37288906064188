import * as Types from '~/constants/actionTypes';

export const getPolicySystemPartner = (id) => ({
  type: Types.GET_POLICY_SYSTEM_PARTNER_REQUEST,
  payload: id
});

export const getPolicySystemBranches = (id) => ({
  type: Types.GET_POLICY_SYSTEM_BRANCHES_REQUEST,
  payload: id
});

export const getResourcesBranch = (branchId) => ({
  type: Types.GET_RESOURCES_BRANCH_REQUEST,
  payload: branchId
});

export const updatePermissionSystemPartnerPermission = (payload) => ({
  type: Types.UPDATE_POLICY_SYSTEM_PARTNER_REQUEST,
  payload
});
export const updatePermissionSystemBranchesPermission = (payload) => ({
  type: Types.UPDATE_POLICY_SYSTEM_BRANCHES_REQUEST,
  payload
});
export const resetPartnerState = () => ({
  type: Types.RESET_BRANCH_GROUP_STATE
});
export const resetBranchState = () => ({
  type: Types.RESET_PARTNER_GROUP_STATE
});
