export const GET_SALE_REQUEST = 'GET_SALE_REQUEST';
export const GET_SALE_SUCCESS = 'GET_SALE_SUCCESS';
export const GET_SALE_FAILED = 'GET_SALE_FAILED';

export const CREATE_SALE_REQUEST = 'CREATE_SALE_REQUEST';
export const CREATE_SALE_SUCCESS = 'CREATE_SALE_SUCCESS';
export const CREATE_SALE_FAILED = 'CREATE_SALE_FAILED';

export const UPDATE_SALE_REQUEST = 'UPDATE_SALE_REQUEST';
export const UPDATE_SALE_SUCCESS = 'UPDATE_SALE_SUCCESS';
export const UPDATE_SALE_FAILED = 'UPDATE_SALE_FAILED';

export const UPDATE_SALES = 'UPDATE_SALES';
export const CREATE_SALE = 'CREATE_SALE';
export const DELETE_SALE = 'DELETE_SALE';
export const SET_SELECTED_SALE = 'SET_SELECTED_SALE';
export const SET_SELECTED_EXCHANGE_RETURN = 'SET_SELECTED_EXCHANGE_RETURN';

export const REMOVE_SUBMITED_SALE = 'REMOVE_SUBMITED_SALE';

export const RESET_SALE_STATE = 'RESET_SALE_STATE';
export const SET_SELECTED_SALE_PRODUCT_VARIANT = 'SET_SELECTED_SALE_PRODUCT_VARIANT'

export const UPDATE_LOGISTIC_CODE_SALE_REQUEST = 'UPDATE_LOGISTIC_CODE_SALE_REQUEST';
export const UPDATE_LOGISTIC_CODE_SALE_SUCCESS = 'UPDATE_LOGISTIC_CODE_SALE_SUCCESS';
export const UPDATE_LOGISTIC_CODE_SALE_FAILED = 'UPDATE_LOGISTIC_CODE_SALE_FAILED';

export const SALE_CHECK_IN_OUT_REQUEST = 'SALE_CHECK_IN_OUT_REQUEST';
export const SALE_CHECK_IN_OUT_SUCCESS = 'SALE_CHECK_IN_OUT_SUCCESS';
export const SALE_CHECK_IN_OUT_FAILED = 'SALE_CHECK_IN_OUT_FAILED';

export const SET_NEW_BILL = 'SET_NEW_BILL';
