import React, { useState, useMemo } from 'react';
import {
  useCustomers,
  useDeleteCustomer,
  useCustomerQueryParams,
  useUpdateCustomerParams,
  useResetCustomer,
  useCustomerPaging,
  useCustomerColumnSetting,
  SEARCH_CUSTOMER
} from '~/hooks';
import { Table, Input, Button, Dropdown, Tabs } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE } from '~/constants/defaultValue';
import CustomerForm from './Form/index';
import CustomerInfo from './Detail/Information';
import Debt from './Detail/Debt';
import Purchases from './Detail/Purchases';
import OrderHistory from './Detail/OrderHistory';

import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined
} from '@ant-design/icons';
import { formatNumber } from '~/utils/helper';
import './index.scss';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { onSelectRow } from '~/utils/validators';

const { Search } = Input;
const { TabPane } = Tabs;

const Customer = () => {
  const [query] = useCustomerQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCustomerParams(
    query
  );

  const [customers, isLoading] = useCustomers(query);  
  const [selectTable, setSelectTable]=useState([])

  const paging = useCustomerPaging();
  const [customerId, setCustomerId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = customers.length === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteCustomer] = useDeleteCustomer(onDeleteSuccess);

  useResetCustomer();

  const onOpenForm = id => {
    setCustomerId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setCustomerId(null);
    setIsOpenForm(false);
  };

  const [columnMenu, selectedColumnKeys] = useCustomerColumnSetting();

  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Mã khách hàng',
        key: 'code',
        dataIndex: 'code'
      },

      {
        title: 'Tên khách hàng',
        key: 'name',
        dataIndex: 'name'
      },

      {
        title: 'Điện thoại',
        key: 'phoneNumber',
        dataIndex: 'phoneNumber'
      },

      {
        title: 'Nợ hiện tại',
        key: 'totalDebt',
        dataIndex: 'totalDebt',
        align: 'end',
        render: formatNumber
      },
      {
        title: 'Tổng bán',
        key: 'totalSold',
        dataIndex: 'totalSold',
        align: 'end',
        render: formatNumber
      },
      {
        title: 'Tổng bán trừ trả hàng',
        key: 'totalSoldWithoutReturn',
        dataIndex: 'totalSoldWithoutReturn',
        align: 'end',
        render: formatNumber
      }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);


  const expandedRowRender = customer => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <CustomerInfo
              customer={customer}
              deleteCustomer={deleteCustomer}
              onOpenForm={onOpenForm}
            />
          </TabPane>
          <TabPane tab="Nợ cần thu từ khách" key="2">
            <Debt customerId={customer._id}/>
          </TabPane>
          <TabPane tab="Lịch sử đặt hàng" key="3">
            <OrderHistory customerId={customer._id}/>
          </TabPane>
          <TabPane tab="Lịch sử bán/trả hàng" key="4">
            <Purchases customerId={customer._id}/>
          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header">
          <h1 className="wc-page-header__title ">Khách hàng</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_CUSTOMER} />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_CUSTOMER}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => onOpenForm()}
              >
                <PlusOutlined />
                Thêm mới
              </Button>

              <ButtonExport
                router={API_LIST.customer} 
                label="Khách hàng" 
                query={query} 
                exportOption={1}
                fileName={"Danhsachkhachhang_"} />

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={customers.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                size="middle"
                rowClassName={record =>
                  record.isSummaryRow ? 'summary-row' : ''
                }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={customers}
                onChange={({ current ,  pageSize}) => onParamChange({ page: current,limit: pageSize })}
                pagination={{
                  ...paging,
                  // pageSize: 11,
                  showSizeChanger:true,
                  // total: Math.ceil(paging.total + paging.total / 10),
                  showTotal: () => `Tổng cộng: ${paging.total} `
                }}
                // rowSelection={{
                //   renderCell: (checked, record, index, originNode) =>
                //     record.isSummaryRow ? <></> : originNode,
                //   getCheckboxProps: record => ({
                //     disabled: record.isSummaryRow,
                //     name: record.name
                //   })
                // }}                  
                onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}

                onRow={onSelectRow(setSelectTable)}
                
                expandable={{
                  expandedRowRender,
                  rowExpandable: record => !record.isSummaryRow,
                  expandedRowKeys:[selectTable],
                }}
              />
            )}
          </div>
        </div>
      </div>

      <CustomerForm isOpen={isOpenForm} onClose={onCloseForm} id={customerId} />
    </div>
  );
};

export default Customer;
