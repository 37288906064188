import React, { useState, useEffect, useMemo } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Row, Select, Upload } from 'antd';
import { get, head } from 'lodash';
import UploadMultiImage from './UploadMultiImage';
import Breadcrumb from '~/components/Common/Breadcrumb';
import {
  useFormPrints,
  useCreateFormPrint,
  useFormPrintQueryParams,
  useUpdateFormPrint
} from '~/hooks/worldClinic/formPrint';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import { useMatchOrPolicy, useMatchPolicy } from '~/hooks';
import WithOrPermission from '~/components/Common/WithOrPermission';

const FormItem = Form.Item;

const FormPrint = () => {
  const isMatchPolicyUpdate = useMatchPolicy(POLICIES.UPDATE_ORDERFORM)
  const isMatchPolicyUAndW = useMatchOrPolicy([POLICIES.WRITE_ORDERFORM,POLICIES.UPDATE_ORDERFORM])
  const [form] = Form.useForm();
  const [formStep] = Form.useForm();
  const [indexDisabled, setIndexDisabled] = useState(0)
  const [query, onPagingChange] = useFormPrintQueryParams();
  const [formPrints, isLoading] = useFormPrints(query);
  const [, handleUpdate] = useUpdateFormPrint();
  const [, handleCreate] = useCreateFormPrint();
  const [image, setImage] = useState();
  const [valueStepDefault, setValueStepDefault] = useState([]);
  useEffect(() => {
    form.resetFields();
  }, [formPrints, form, formStep]);

  useEffect(() => {
    if (!isMatchPolicyUpdate) {
      setIndexDisabled(get(formPrints, '[0].description.length'))
    }
  }, [isMatchPolicyUpdate, formPrints])
  const mergedFormPrint = useMemo(() => {
    // if (!formPrints || !formPrints.length) {
    //   return null;
    // }
    // if (Array.isArray(formPrints)) {
    //   return formPrints[0];
    // }
    return head(formPrints)
  }, [formPrints]);
  console.log(mergedFormPrint, 'mergedFormPrint');
  useEffect(() => {
    if (mergedFormPrint) {
      const { image : imageForm, description } = mergedFormPrint;
      console.log(imageForm,'imageForm');
      form.setFieldsValue({ image : imageForm });
      setImage(imageForm);
      formStep.setFieldsValue({ description });
    }
  }, [form, formStep, mergedFormPrint, formPrints]);

  const handleChangeStep = (valuesChange, allChanges) => {
    valueStepDefault.map(el => {
      if ([].keys(valuesChange)[0] == el.key) {
        return {
          key: el.key,
          value: valuesChange[[].keys(valuesChange)[0]]
        };
      } else {
        return el;
      }
    });
  };

  const onFinish = values => {
    handleUpdate({
      ...mergedFormPrint,
      ...form.getFieldsValue(),
      ...formStep.getFieldsValue()
    });
    // if (get(mergedFormPrint, '_id')) {

    // } else {
    //   handleCreate({
    //     ...form.getFieldsValue(),
    //     ...formStep.getFieldsValue()
    //   });
    // }
  };
  return (
    <div className="page-wraper page-content__right">
      <div className="container-fluid" style={{ marginTop: '126px' }}>
        <Breadcrumb title="Quản lý phiếu in" />
        <Form
          form={form}
          autoComplete="off"
          scrollToFirstError
          requiredMark={false}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <Row align='middle'>

            
              {
                image &&
                <Col span={8}>
                <Row align='middle'>
                <Col>
                <Image  style={{objectFit : 'contain'}} width={200} height={200} src={image} />
                </Col>
                  <WithOrPermission permission={[POLICIES.UPDATE_ORDERFORM,POLICIES.DELETE_ORDERFORM]}>
                  <Col>
                  <Button type='dashed' htmlType='button' onClick={() => {
                    setImage('')
                    form.setFieldsValue({image : null})
                  }} style={{ margin: '10px 0px 20px 0' }} >Xóa</Button>
                  </Col>
                  </WithOrPermission>
                </Row>
                </Col>
              }
            <Col span={8}>
              <FormItem hidden={!!image} label="Thêm ảnh" name="image">
                <UploadMultiImage disabled={!isMatchPolicyUAndW} onChange={setImage} imgUrl={image} />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Form form={formStep} onValuesChange={handleChangeStep}>
          <Form.List name="description">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Row key={index} style={{ width: '100%' }}>
                      <Col span={20}>
                        <Row style={{ width: '100%' }}>
                          <FormItem
                            style={{ width: '90%' }}
                            name={[index]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Vui lòng nhập hoặc xoá text.'
                              }
                            ]}
                          >
                            <Input.TextArea disabled={index < indexDisabled}></Input.TextArea>
                          </FormItem>
                        </Row>
                      </Col>
                      <WithOrPermission permission={[POLICIES.DELETE_ORDERFORM, POLICIES.UPDATE_ORDERFORM]}>
                        <Col span={4}>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(index)}
                            />
                          ) : null}
                        </Col>
                      </WithOrPermission>
                    </Row>
                  ))}
                  <WithOrPermission permission={[POLICIES.WRITE_ORDERFORM, POLICIES.UPDATE_ORDERFORM]}>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          return add('');
                        }}
                        style={{
                          width: '100%'
                        }}
                        icon={<PlusOutlined />}
                      >
                        Thêm text
                      </Button>
                    </Form.Item>
                  </WithOrPermission>
                </>
              );
            }}
          </Form.List>
          <WithOrPermission permission={[POLICIES.WRITE_ORDERFORM, POLICIES.UPDATE_ORDERFORM , POLICIES.DELETE_ORDERFORM]}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: '50%' }}
                onClick={onFinish}
              >
                Cập nhật
              </Button>
            </Form.Item>
          </WithOrPermission>
        </Form>
      </div>
    </div>
  );
};

export default FormPrint;

