import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import InformationItem from '~/components/Common/InformationItem';
import { PATH_CLINIC } from '~/routes/paths';
import moment from 'moment';
import { formatCurrency } from '~/utils/helper';
import { PURCHASE_ORDER_STATUS, CASH_FLOW_TYPES_DICT, ACTOR_GROUP } from '~/constants/defaultValue';
import { ArrowDownOutlined, ArrowUpOutlined, EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import api from '~/api';
import POLICIES from '~/constants/policy';
import { WithPermission } from '~/components/Common';
import { get, isNil } from 'lodash';

const getColumnLeftInfo = ({ type }) => [
  {
    label: 'Mã phiếu:',
    dataIndex: 'code',
    render: code => <Link to="#">{code}</Link>
  },
  {
    label: 'Thời gian:',
    dataIndex: 'createdAt',
    render: createdAt => moment(createdAt).format('DD/MM/YYYY HH:mm')
  },
  {
    label: 'Giá trị:',
    dataIndex: 'paymentAmount',
    render: paymentAmount => formatCurrency(paymentAmount)
  },
  {
    label: (type === 'RECEIPT' ? 'Người nộp:': 'Người nhận:'),
    dataIndex: type === 'RECEIPT' ? 'customer' : 'supplier',
    render: (user, record) => {
      return <Link to="#">{user?.name ?? get(record,['payerReceiver','name'],'')}</Link>
    }
  },
  {
    label: 'Số điện thoại:',
    dataIndex: type === 'RECEIPT' ? 'customer' : 'supplier',
    render: (supplier,record) => {
      return supplier?.phoneNumber ?? (get(record,[ 'supplier'])??get(record,[ 'customer']))?.phoneNumber ;
    }
  },
  {
    label: 'Địa chỉ:',
    dataIndex: type === 'RECEIPT' ? 'customer' : 'supplier',
    render: supplier => {
      return `${supplier?.address?.ward  ? (supplier?.address.street ?? " ")+" "+  supplier?.address.ward +", "+ supplier?.address.district +", "+supplier?.address.city : ''}`;
    }
  }
];

const getColumnRightInfo = ({type}) => [
  {
    label: 'Chi nhánh:',
    dataIndex: 'branch',
    render: branch => branch?.name
  },
  {
    label: 'Loại thu chi:',
    dataIndex: 'type',
    render: type => <>{CASH_FLOW_TYPES_DICT[type]}</> 
  },
  {
    label: 'Trạng thái:',
    dataIndex: 'referenceDoc',
    render: (referenceDoc,record) => PURCHASE_ORDER_STATUS[referenceDoc?.status] ?? ({ACTIVE:'Hoàn thành'})[get(record, 'status') ]
  },
  {
    label: 'Người dùng tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  },
  {
    label: 'Nhân viên tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  },
  {
    label: (type === 'RECEIPT' ? 'Đối tượng nộp:': 'Đối tượng nhận:'),
    dataIndex: 'payerReceiver',
    render: (payerReceiver,record) => {
      if(!isNil(record.supplier)){
        return ACTOR_GROUP['SUPPLIER'];
      }
      if(!isNil(record.customer)){
        return ACTOR_GROUP['CUSTOMER'];
      }
      return ACTOR_GROUP[(get(payerReceiver,'target', 'EMPLOYEE') )];
    }
  }
];

const getReference = ({ referenceDocName }) => {
  if (referenceDocName === 'product_return') {
    return ['thu', 'Phiếu hủy hàng', PATH_CLINIC.productReturn.root];
  }

  if (referenceDocName === 'purchase_return') {
    return ['thu', 'Phiếu trả hàng nhập', PATH_CLINIC.purchaseReturn.root];
  }

  if (referenceDocName === 'invoice') {
    return ['thu', 'Hoá đơn', PATH_CLINIC.productInvoice.root];
  }

  if (referenceDocName === 'sale_order') {
    return ['thu', 'Đơn đặt hàng', PATH_CLINIC.productOrder.root];
  }

  if (referenceDocName === 'purchase_order') {
    return ['chi', 'Đơn nhập hàng', PATH_CLINIC.warehouse.root];
  }
};

const Information = ({ record, handleOpenReceiptPayment }) => {
  const [loading,setLoading] = useState(false)
  const componentRef = useRef();
  const [type, title, referenceLink] = record && record?.referenceDocName ? getReference(record) : ['', '', ''];
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [newReCord,setNewReCord] = useState({...record})
  useEffect(() => {
    // const fetchCustomer =
    ( async () => {
      setLoading(true)
      try {
        if(newReCord?.payerReceiver){
          const { payerReceiver }=newReCord
          let res =null
           switch (payerReceiver?.target) {
            case "CUSTOMER":
               res = await api.customer.getOptions(newReCord?.payerReceiver?._id);
              setNewReCord({...newReCord,customer:res||null})  
              break;
            // case "EMPLOYEE":
            //    res = await api.employee.getById(newReCord?.payerReceiver?._id); // error BE
            //   setNewReCord({...newReCord,customer:res||null})
            //   break; 
            case "SUPPLIER":
               res = await api.supplier.getById(newReCord?.payerReceiver?._id);
              setNewReCord({...newReCord,supplier:res||null})  
              break;
          
            default:
              break;
          }
        }
        if(!newReCord?.payerReceiver&&!newReCord?.supplier&& !newReCord?.customer){
          setNewReCord({...newReCord,customer: {name:"Khách lẻ",phoneNumber:""} })
        }
        // if(newReCord?.supplier&& newReCord?.supplierId){
        //   const response = await api.supplier.getById(newReCord?.supplierId)
        //   setNewReCord({...newReCord, supplier:response})
        // }
        
      } catch (message) {
        console.dir(message.response.data.message)
      }
      setLoading(false)
    })()
    // record?.type === "RECEIPT" && fetchCustomer()
  },[record])

  return (
    <div className="warehouse-info medicine-detail-tab-item" ref={componentRef}>
      <Row gutter={48}>
        <Col span={8}>
         {loading ? <div>...loading</div>: <div className="warehouse-info__general">
            {getColumnLeftInfo(newReCord).map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={newReCord[dataIndex]} 
                record={newReCord}
                render={render}
              />
            ))}
          </div>}
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {getColumnRightInfo(newReCord).map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={newReCord[dataIndex]}
                record={newReCord}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <InformationItem
              key={'Ghi chú'}
              label="Ghi chú"
              value={record?.note}
            />
          </div>
        </Col>
      </Row>

      {
        <p className="cash-flow__bottom-description">
          { (type.length > 0 && title.length > 0 && referenceLink.length > 0) ? `Phiếu ${type} tự động được tạo gắn với ${title}` : `Phiếu ${type} chưa được tạo gắn với ${title} nào`}

          {type.length > 0 && title.length > 0 && referenceLink.length > 0 && (
            <Link
              style={{ marginLeft: 5 }}
              target="_blank"
              to={`${referenceLink}?id=${record?.referenceDoc?._id}`}
            >
              {record?.referenceDoc?.code}
            </Link>
          )}
        </p>
      }
      <WithPermission permission={POLICIES.UPDATE_CASHBOOK}>
        <Row className="medicine-info__actions" justify="end" style={{gap: '10px'}}>
          <Button type="primary" style={{ cursor: 'pointer' }} onClick={() => handleOpenReceiptPayment(record)}>
            <EditOutlined style={{ marginLeft: 0 }} /> Mở phiếu
          </Button>
          <Button style={{ backgroundColor: '#898c8d', color: '#fff', cursor: 'pointer' }} onClick={handlePrint}>
            {' '}
            <PrinterOutlined style={{ marginLeft: 0 }} /> In
          </Button>
        </Row>
      </WithPermission>
    </div>
  );
};

export default Information;
