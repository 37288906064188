import * as Types from '~/constants/actionTypes';

export const getSpecialities = () => ({
  type: Types.GET_SPECIALITIES_REQUEST
});

export const getSpeciality = id => ({
  type: Types.GET_SPECIALITY_REQUEST,
  payload: id
});

export const createSpeciality = speciality => ({
  type: Types.CREATE_SPECIALITY_REQUEST,
  payload: speciality
});

export const updateSpeciality = speciality => ({
  type: Types.UPDATE_SPECIALITY_REQUEST,
  payload: speciality
});

export const deleteSpeciality = id => ({
  type: Types.DELETE_SPECIALITY_REQUEST,
  payload: id
});

export const resetSpecialityState = () => ({
  type: Types.RESET_SPECIALITY_STATE
});
