import {
  CopyOutlined,
  LoadingOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { Button, Col, Input, Modal, Popconfirm, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteApiKey, useUpdateApiKeyPartner } from '~/hooks';
import toastr from 'toastr';
import { v4 } from 'uuid';
import moment from 'moment';
import api from '~/api';
import BaseBorderBox from '~/components/Common/BaseBorderBox';
import POLICY from '~/constants/policy';
import { WithPermission } from '~/components/Common';
const styleIconCopy = {
  marginRight: '10px',
  padding: '5px',
  position: 'relative',
  bottom: '-65px'
};

const ModalForm = ({
  id,
  setApiKey,
  handleCancel,
  countdown,
  setCountdown,
  valueForm,
  actionSetForm,
  currentApiKey
}) => {
  const { keyConfirm } = valueForm;
  const [isLoading, setIsLoading] = useState(true)
  const [apiKeyFetch, setApiKeyFetch] = useState(null)
  const [valueInput, setValueInput] = useState(null)

  useEffect(() => {
    actionSetForm({ keyConfirm: v4().slice(0, 5) });
    setApiKey(apiKeyFetch);
  }, [apiKeyFetch]);
  const [, setIsLoadingCopy] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 0) {
          clearInterval(interval);
          handleCancel();
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);


  const getApiKey = async () => {
    const res = await api.partner.getApiKey(id)
    setIsLoading(!res)
    setCountdown(120);
    setApiKeyFetch(res)
  }

  const handleCopy = (current = false) => {
    const apiKey = current ? currentApiKey : apiKeyFetch
    setIsLoadingCopy(true);
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        setIsLoadingCopy(false);
        toastr.success('Sao chép mã liên kết thành công');
      })
      .catch(error => {
        setIsLoadingCopy(false);
        toastr.error('Sao chép mã liên kết thất bại', error);
      });
  };

  return (
    <div className="modal-apikey">
      {/* <BaseBorderBox title={
        <h5>Mã hiện tại: {currentApiKey &&  <CopyOutlined
              style={{ fontSize: '16px', cursor: 'copy' }}
              onClick={() => {
                handleCopy(true);
              }}
            /> }</h5>
      }>
        <div className="modal-apikey__content" style={{ padding: '10px' }}>
          
          <code style={{color:'#FF9A00'}}>{currentApiKey}</code>
        </div>
      </BaseBorderBox> */}
      <BaseBorderBox title={<h5>Mã mới</h5>}>
        <h6 style={{ marginTop: '10px' }}> Vui lòng nhập mã xác thực để lấy mã liên kết mới</h6>

        <Row style={{ marginTop: '15px' }}>
          <Input
            style={{width: "50%"}}
            onChange={val => {
            setValueInput(val.target.value)
            actionSetForm({ valueConfirm: val.target.value });
            }}
            
            addonAfter={
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <code
                    style={{
                    userSelect: 'none',
                      width: '100%',
                      height: '100%',
                      fontSize: '18px',
                    }}
                    disabled={true}
                  >
                    {keyConfirm}
                  </code>
                  <ReloadOutlined
                    style={{ fontSize: '20px', marginLeft: '20px' }}
                    onClick={() =>
                      actionSetForm({ keyConfirm: v4().slice(0, 5) })
                    }
                  />
              </div>
            }
          />
          <Button
            disabled={keyConfirm !== valueInput ? true : false}
            onClick={getApiKey}
          >Lấy mã</Button>
        </Row>
        {apiKeyFetch && <div className="modal-apikey__content" style={{ padding: '10px' }}>
          <code>
            {isLoading ? (
              <LoadingOutlined
                style={{
                  fontSize: 24,
                  color: '#1890ff'
                }}
                spin
              />
            ) : (
          apiKeyFetch
            )}
          </code>
        </div>}

      {apiKeyFetch &&  <Row style={{ ...styleIconCopy }}>
          <Col span={4}>
            {apiKeyFetch ? `${moment(0).set('s', countdown).format('mm:ss')}` : ''}
          </Col>
          <Col span={4}>
            <CopyOutlined
              style={{ fontSize: '20px', cursor: 'copy' }}
              onClick={() => {
                handleCopy();
              }}
            />
          </Col>
        </Row>}
      </BaseBorderBox>
    </div>
  );
};
const GetApiKeyForm = ({branchIdQuery, currentApiKey}) => {
  const { id } = useParams();
  
  const [isOpen, setIsOpen] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [disabled, setDisable] = useState(true);

  const query = useMemo(
    () => ({
      apiKey: apiKey,
      id
    }),
    [apiKey]
  );

  const [isUpdateLoading, updateApiKey] = useUpdateApiKeyPartner();
  const [,deleteApiKey] = useDeleteApiKey();

  const [formApiKey, setFormApiKey] = useState({
    valueConfirm: '',
    keyConfirm: ''
  });

  const setValueForm = useCallback(
    value => {
      setFormApiKey(item => ({ ...item, ...value }));
    },
    [setFormApiKey]
  );

  const handleOpen = () => {
    setCountdown(120);
    setIsOpen(true);
  };
  const handleCancel = () => {
    setCountdown(120);
    setIsOpen(false);
  };
  const handleOk = () => {
    setIsOpen(false);
    updateApiKey(query);
    setCountdown(120);
  };

  const Verification = ({ valueConfirm, keyConfirm }) => {
    if (valueConfirm === keyConfirm) {
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Button onClick={handleOpen}>Lấy Mã Liên Kết</Button>
        </Col>
        <WithPermission permission={POLICY.DELETE_APIKEY}>
        <Col>
          <Popconfirm
            title={'Hành động này sẽ xoá toàn bộ liên kết của các đơn vị khác, bạn có chắc chắn?'}
              onConfirm={() => {
                deleteApiKey({
                  id:id,
                  type:'partner'
                })
            }} 
            okText={'Xác nhận'}
            cancelText={'Huỷ'}
          >
          <Button style={{ marginLeft: '10px' }} danger type='primary'>Xoá mã liên kết</Button>
          </Popconfirm>
        </Col>
        </WithPermission>
      </Row>
      <Modal
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        width={'600px'}
        destroyOnClose
        title="Lấy mã liên kết"
        visible = {isOpen}
        onCancel={handleCancel}
        className='modal-apikey__partner'
        footer={[
          <Button type="button" onClick={handleOk} disabled={!apiKey}>
            Lưu
          </Button>,
          <Button type="primary" onClick={handleCancel}>
            Huỷ
          </Button>
        ]}
      >
        <ModalForm
          id={branchIdQuery}
          handleOk={handleOk}
          handleCancel={handleCancel}
          setApiKey={setApiKey}
          countdown={countdown}
          setCountdown={setCountdown}
          Verification={Verification}
          setDisable={setDisable}
          actionSetForm={setValueForm}
          valueForm={formApiKey}
          currentApiKey = {currentApiKey}
        />
      </Modal>
    </React.Fragment>
  );
};

export default GetApiKeyForm;
