import { Select } from 'antd';
import React from 'react';
import { ORDER_STATUS_VI } from './constants';
import { get, transform } from 'lodash';

export default function SelectSearchStatus({ handleChangeStatus, style }) {
    return (
        <Select
            mode="multiple"
            allowClear
            style={{
                ...style,
                minWidth: 250
            }}
            placeholder="Vui lòng chọn trạng thái"
            onChange={handleChangeStatus}
        >
            {transform(
                ORDER_STATUS_VI,
                (result, value, key) => {
                    result.push({
                        label: get(value, 'name', ''),
                        value: key,
                        color: get(value, 'color', '')
                    });
                },
                []
            )?.map(e => (
                <Select.Option value={get(e, 'value')}>
                    <p style={{ color: `${e.color}` }}>{get(e, 'label')}</p>
                </Select.Option>
            ))}
        </Select>
    );
}
