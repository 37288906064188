import * as Types from '~/constants/actionTypes';

export const getEmployeeGroups = (branchId) => ({
  type: Types.GET_EMPLOYEE_GROUPS_REQUEST,
  payload: branchId
});

export const getEmployeeGroup = (id) => ({
  type: Types.GET_EMPLOYEE_GROUP_REQUEST,
  payload: id
});


export const createEmployeeGroup = (employeeGroup) => ({
  type: Types.CREATE_EMPLOYEE_GROUP_REQUEST,
  payload: employeeGroup
});

export const updateEmployeeGroup = (employeeGroup) => ({
  type: Types.UPDATE_EMPLOYEE_GROUP_REQUEST,
  payload: employeeGroup
});

export const deleteEmployeeGroup = (id) => ({
  type: Types.DELETE_EMPLOYEE_GROUP_REQUEST,
  payload: id
});

export const resetEmployeeGroupState = () => ({
  type: Types.RESET_EMPLOYEE_GROUP_STATE
});

export const getResources = (branchId) => ({
  type: Types.GET_RESOURCES_REQUEST,
  payload: branchId
});


export const updateGroupPermission = (payload) => ({
  type: Types.UPDATE_GROUP_PERMISSION_REQUEST,
  payload
});

