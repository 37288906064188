export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILED = 'GET_CITIES_FAILED';

export const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_DISTRICTS_FAILED = 'GET_DISTRICTS_FAILED';

export const GET_WARDS_REQUEST = 'GET_WARDS_REQUEST';
export const GET_WARDS_SUCCESS = 'GET_WARDS_SUCCESS';
export const GET_WARDS_FAILED = 'GET_WARDS_FAILED';
