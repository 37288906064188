import React from 'react';
import { Form, Select, Row, Button, Skeleton, Modal, Col } from 'antd';

const { Option } = Select;

const FormItem = Form.Item;

const SelectGroup = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();

  const isLoading = false;
  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Row style={{ marginBottom: 20 }}>
          <h4 style={{ marginRight: 'auto' }}>Thêm hàng hoá từ nhóm hàng</h4>
        </Row>

        <Form
          form={form}
          style={{ paddingLeft: 0 }}
          autoComplete="off"
          scrollToFirstError
          requiredMark={false}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 5, xl: 5 }}
          wrapperCol={{ sm: 24, md: 24, lg: 19, xl: 98 }}
        >
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Nhóm hàng"
                name="name"
                rules={[{ required: true, message: 'Xin mời nhóm hàng' }]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select showSearch placeholder="Chọn nhóm hàng">
                    <Option value="1">Nhóm 1</Option>
                    <Option value="2">Nhóm 2</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row
            className="wc-setting-form__submit-box"
            style={{ marginTop: 10 }}
          >
            {false ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit">
              Chọn
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default SelectGroup;
