import { useSelector, useDispatch } from 'react-redux';
import { useFailed, useSubmit, useFetchByParam } from '~/hooks/utils';
import {
  loginRequest,
  logoutRequest,
  getProfile,
  resetStore,
  getPolicy
} from '~/redux/action';
import { useMemo, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.user[key];

const loadingSelector = getSelector('isLoading');
const tokenSelector = getSelector('token');
const loginFailedSelector = getSelector('loginFailed');
const branchIdSelector = getSelector('branchId');

const profileSelector = getSelector('profile');
const isGetProfileLoadingSelector = getSelector('isGetProfileLoading');
const getProfileFailedSelector = getSelector('getProfileFailed');

const policySelector = getSelector('policy');
const isGetPolicyLoadingSelector = getSelector('isGetPolicyLoading');
const getPolicyFailedSelector = getSelector('getPolicyFailed');
const getPolicySuccessSelector = getSelector('getPolicySuccess');

const isMatchPolicy = (policies, requiredPermission) => {
  const isAdmin = policies?.[requiredPermission[0]]?.admin;

  return (
    isAdmin ||
    !!requiredPermission.reduce((policy, permission) => {
      return policy?.[permission];
    }, policies)
  );
};

export const useMatchPolicy = requiredPermission => {
  const policies = useSelector(policySelector);

  const isMatch = useMemo(() => {
    if (!requiredPermission) return true;

    if (Array.isArray(requiredPermission[0])) {
      return requiredPermission.reduce((isMatch, permissionItem) => {
        return isMatch && isMatchPolicy(policies, permissionItem);
      }, true);
    }

    return isMatchPolicy(policies, requiredPermission);
  }, [requiredPermission, policies]);

  return isMatch;
};
export const useMatchOrPolicy = (requiredPermission) => {
  const policies = useSelector(policySelector);
  const profile = useSelector(profileSelector);
  const isMatch = useMemo(() => {
    if (profile?.isSuperAdmin) {
      return true;
    }
    if (!requiredPermission) return true;

    for (const permissionItem of requiredPermission) {
        if (isMatchPolicy(policies, permissionItem)) {
          return true;
        }
    }

    return isMatchPolicy(policies, requiredPermission);
  }, [requiredPermission, policies]);

  return isMatch;
};

export const useResetStore = () => {
  const dispatch = useDispatch();
  const handleResetStore = useCallback(() => dispatch(resetStore()), [
    dispatch
  ]);

  return handleResetStore;
};

export const useUser = () => {
  const token = useSelector(tokenSelector);
  const branchId = useSelector(branchIdSelector);

  return [branchId, token];
};

export const useLogin = () => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector,
    action: loginRequest
  });

  // useSuccess(tokenSelector, 'Login success');
  useFailed(loginFailedSelector, 'Login Failed');

  return [isLoading, handleSubmit];
};

export const useLogout = () => {
  const [, handleLogout] = useSubmit({
    loadingSelector,
    action: logoutRequest
  });
  const handleResetStore = useResetStore();

  return () => {
    handleResetStore();
    handleLogout();
  };
};

export const useSelectBranch = () => {
  const branchId = useSelector(branchIdSelector);
  const profile = useSelector(profileSelector);
  
  //eslint-disable-next-line
  const selectedBranch = profile.branches?.find(({ _id }) => _id == branchId);
  return [branchId, selectedBranch];
};

export const useProfile = token => {
  return useFetchByParam({
    action: getProfile,
    loadingSelector: isGetProfileLoadingSelector,
    dataSelector: profileSelector,
    failedSelector: getProfileFailedSelector,
    param: token
  });
};

export const useUserPolicy = () => {
  const [isLoading, handleGetPolicy] = useSubmit({
    loadingSelector: isGetPolicyLoadingSelector,
    action: getPolicy
  });

  const policies = useSelector(policySelector);
  const branchId = useSelector(branchIdSelector);

  useEffect(() => {
    if (!Object.keys(policies || {}).length && branchId && !isLoading) {
      handleGetPolicy(branchId);
    }
    //eslint-disable-next-line
  }, []);

  useFailed(getPolicyFailedSelector);

  return [isLoading, handleGetPolicy, policies];
};

export const useReload = () => {
  const getPolicySuccess = useSelector(getPolicySuccessSelector);
  const handleResetStore = useResetStore();
  const moutedRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (moutedRef && getPolicySuccess) {
      handleResetStore();
      history.push('/dashboard');
    }

    if (!moutedRef.current) {
      moutedRef.current = true;
    }
  }, [getPolicySuccess, history, handleResetStore]);
};
