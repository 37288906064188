import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
// import toastr from 'toastr';
import toastrNotify from '~/hooks/utils';

function* getPolicySystemPartner({ payload: partnerId }) {
  try {
    const data = yield call(Api.permission.getAllPolicySystemPartner, partnerId);
    yield put({ type: Types.GET_POLICY_SYSTEM_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_POLICY_SYSTEM_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* getPolicySystemBranches({ payload: partnerId }) {
  try {
    const data = yield call(Api.permission.getResourcesBranch, partnerId);
    yield put({ type: Types.GET_POLICY_SYSTEM_BRANCHES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_POLICY_SYSTEM_BRANCHES_FAILED,
      payload: error.message
    });
  }
}

function* getResourcesBranches({ payload }) {
  try {
    const resources = yield call(Api.permission.getAllPolicySystemBranches, payload);
    yield put({ type: Types.GET_RESOURCES_BRANCH_SUCCESS, payload: resources });
  } catch (error) {
    yield put({
      type: Types.GET_RESOURCES_BRANCH_FAILED,
      payload: error.message
    });
  }
}

//Handle Update Permissions System Parter
function* updatePermissionSystemPartnerPermission({ payload }) {
  try {
    const { isAssgined, companyId, partnerId, ...rest } = payload;
    const request = isAssgined
    ? Api.permission.updatePermissionSystemPartner
    : Api.permission.removePermissionSystemPartner;

    yield call(request, { ...rest, partnerId });

    yield put({
      type: Types.GET_POLICY_SYSTEM_PARTNER_REQUEST,
      payload: { companyId, partnerId }
    });

    yield put({
      type: Types.UPDATE_POLICY_SYSTEM_PARTNER_SUCCESS
    });
  } catch (error) {
    toastrNotify.error((error.response.data.message))
    yield put({
      type: Types.UPDATE_POLICY_SYSTEM_PARTNER_FAILED,
      payload: error.message
    });
  }
}

//Handle Update Permissions System Branches
function* updatePermissionSystemBranchesPermission({ payload }) {
  try {
    const { isAssgined, companyId, branchId, ...rest } = payload;
    const request = isAssgined
    ? Api.permission.updatePermissionSystemBranches
    : Api.permission.removePermissionSystemBranches;

    yield call(request, { ...rest, branchId });

    yield put({
      type: Types.GET_POLICY_SYSTEM_BRANCHES_REQUEST,
      payload: { companyId, branchId }
    });

    yield put({
      type: Types.UPDATE_POLICY_SYSTEM_BRANCHES_SUCCESS
    });
  } catch (error) {
    toastrNotify.error(error.response.data.message)
    yield put({
      type: Types.UPDATE_POLICY_SYSTEM_BRANCHES_FAILED,
      payload: error.message
    });
  }
}

export default function* employeeGroup() {
  yield takeLatest(Types.GET_POLICY_SYSTEM_PARTNER_REQUEST, getPolicySystemPartner);
  yield takeLatest(Types.GET_POLICY_SYSTEM_BRANCHES_REQUEST, getPolicySystemBranches);
  yield takeLatest(Types.UPDATE_POLICY_SYSTEM_PARTNER_REQUEST, updatePermissionSystemPartnerPermission);
  yield takeLatest(Types.UPDATE_POLICY_SYSTEM_BRANCHES_REQUEST, updatePermissionSystemBranchesPermission);
  yield takeLatest(Types.GET_RESOURCES_BRANCH_REQUEST, getResourcesBranches);
}
