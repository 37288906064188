import { Button, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import BaseBorderBox from '~/components/Common/BaseBorderBox';
import UploadImage from '~/components/Utils/UploadImage';
import moment from 'moment';
import UpLoadListImage from './UpLoadListImage';
import { useAddLocalSale, useResetSale } from '~/hooks';
import { v4 } from 'uuid';

export default function ImagesModal(props) {
    useResetSale();
    const [, addSale] = useAddLocalSale();
    const { isOpen, onClose, selectedSale, isLoadingSubmit, onSubmit, _id } = props;
    const [checkIn, setCheckIn] = useState([]);
    const [checkOut, setCheckOut] = useState([]);

    // const filterData = (data) => data?.map((item) => ({
    //     fileName: item?.name,
    //     url: item?.response?.url ?? item?.url,
    //     date: item?.date || moment().format('DD/MM/YYYY HH:mm:ss'),
    //     type: item?.type,
    //     size: item?.size
    // }));
    const handleCallbackSubmitImage = (data) => {
        addSale({
          ...selectedSale,
          ...data,
        });
    };
    const handleSubmit = (type) => {
        const newData = {
            _id,
            ...(type === 'CHECKIN' ? { checkIn } : {checkOut}),
            type,
            callbackSubmit: handleCallbackSubmitImage
        };
        onSubmit(newData);
    };
    useEffect(() => {
        if (selectedSale) {
            const checkIn = selectedSale?.checkIn?.map((item) => ({
                ...item,
                uid: v4()
            }));
            const checkOut = selectedSale?.checkOut?.map((item) => ({
                ...item,
                uid: v4()
            }));
            setCheckIn(checkIn || []);
            setCheckOut(checkOut || []);
        };
    }, [selectedSale]);
    return (
        <>
            <Modal
                visible={isOpen}
                width={1080}
                footer={null}
                onCancel={onClose}
                className="form-modal wc-form form-modal--clinic"
                destroyOnClose
            >
                <BaseBorderBox title={'Hình ảnh xuất hàng'}>
                    <UpLoadListImage
                        fileList={checkIn}
                        title="Ảnh xuất hàng"
                        setFileList={setCheckIn}
                        onChange={file => {
                            const data = {
                                url: file,
                                date: moment().format('DD/MM/YYYY HH:mm:ss')
                            };
                            setCheckIn(cur => [...cur, data]);
                        }}
                    />
                    <Row justify="end">
                        <Button
                            type="primary"
                            onClick={() => handleSubmit('CHECKIN')}
                            loading = {isLoadingSubmit}
                        >
                            Cập nhật
                        </Button>
                    </Row>
                </BaseBorderBox>
                <BaseBorderBox title={'Hình ảnh khách nhận hàng'}>
                    <UpLoadListImage
                        fileList={checkOut}
                        title="Ảnh khách nhận hàng"
                        setFileList={setCheckOut}
                        onChange={file => {
                            const data = {
                                url: file,
                                date: moment().format('DD/MM/YYYY HH:mm:ss')
                            };
                            setCheckOut(cur => [...cur, data]);
                        }}
                    />
                    <Row justify="end">
                        <Button
                            type="primary"
                            onClick={() => handleSubmit('CHECKOUT')}
                            loading = {isLoadingSubmit}
                        >
                            Cập nhật
                        </Button>
                    </Row>
                </BaseBorderBox>
            </Modal>
        </>
    );
}
