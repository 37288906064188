import * as Types from '~/constants/actionTypes';

export const getPartnerships = (query) => ({
  type: Types.GET_PARTNERSHIPS_REQUEST,
  payload: query
});

export const getPartnership = (id) => ({
  type: Types.GET_PARTNERSHIP_REQUEST,
  payload: id
});

export const createPartnership = (partnership) => ({
  type: Types.CREATE_PARTNERSHIP_REQUEST,
  payload: partnership
});

export const updatePartnership = (partnership) => ({
  type: Types.UPDATE_PARTNERSHIP_REQUEST,
  payload: partnership
});

export const deletePartnership = (id) => ({
  type: Types.DELETE_PARTNERSHIP_REQUEST,
  payload: id
});

export const resetPartnershipState = () => ({
  type: Types.RESET_PARTNERSHIP_STATE
});
