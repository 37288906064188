import React, { useMemo, useCallback } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors
} from '~/hooks/utils';

import {
  createSale,
  deleteSale,
  getSale,
  resetSaleState,
  updateSale,
  updateSales,
  setSelectedSale,
  createSaleRequest,
  removeSubmitedSale,
  updateLogisticCodeSale,
  updateSaleCheckInOut,
  setNewBill,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import {
  DISCOUNT_TYPES,
  PAYMENT_METHODS,
  SALE_CASES,
  SALE_TYPES
} from '~/constants/defaultValue';
import { formatNumber } from '~/utils/helper';
import { Button, Dropdown, InputNumber, Menu } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import currencyFormatter from '~/utils/currencyFormatter';
import {
  getPriceAfterDiscount,
  getPriceAfterDiscountProd,
  caculateDiscountValueOnTypeChange,
  getDiscountValue
} from '~/utils/caculator';
import DiscountInput from '~/components/Common/DiscountInput';
import { get } from 'lodash';
import {v4 as uuidv4} from 'uuid'
import { getSaleCase } from '~/utils/saleHelper';
const SALE_MODULE = 'sale';

const CREATE_SUCCESS_MESS = 'Tạo mới thành công';
const CREATE_FAILED_MESS = 'Tạo mới thất bại';

const UPDATE_SUCCESS_MESS = 'Cập nhật thành công';
const UPDATE_FAILED_MESS = 'Cập nhật thất bại';

const getSelector = key => state => state[SALE_MODULE][key];

const {
  loadingSelector,
  listSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  isSubmitLoadingSelector,
  updateSuccessSelector,
  updateFailedSelector,
  createSuccessSelector,
  createFailedSelector
} = getSelectors(SALE_MODULE);

export const getSaleName = (sales, type, index = 1) => {
  let prefix;

  switch(type) {
    case SALE_TYPES.DIRECT:
      prefix = 'Hoá đơn';
      break;
    case SALE_TYPES.ORDER:
      prefix = 'Đặt hàng';
      break;
    default:
      prefix = 'Trả hàng';
  }

  const saleName = `${prefix} ${index}`;

  const isNameExist = sales.find(({ name }) => name === saleName);
  return isNameExist ? getSaleName(sales, type, index + 1) : saleName;
};

export const useSales = () => {
  const sales = useSelector(listSelector);
  const [, setSales] = useSubmit({ loadingSelector, action: updateSales });

  const onSaleChange = (uuid, updatedProp) => {
    const nextSales = sales.map(sale => {
      const isTypeChanged = updatedProp['type'];

      return sale.uuid !== uuid
        ? sale
        : {
            ...sale,
            ...updatedProp,
            name: isTypeChanged
              ? getSaleName(sales, updatedProp.type)
              : sale.name
          };
    });

    setSales(nextSales);
  };

  return [sales, onSaleChange];
};

const selectedSaleSelector = getSelector('selectedSale');

export const useSelectedSale = () => {
  const selectedSale = useSelector(selectedSaleSelector);
  const [, selectSale] = useSubmit({
    loadingSelector,
    action: setSelectedSale
  });

  return [selectedSale, selectSale];
};

export const useAddLocalSale = () => {
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSale
  });
};

export const useRemoveSubmitedSale = () => {
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: removeSubmitedSale
  });
};

export const useCreateSale = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSaleRequest
  });
};

export const useUpdateSale = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSale
  });
};

export const useDeleteSale = () => {
  return useSubmit({
    loadingSelector,
    action: deleteSale
  });
};

export const useSale = params => {
  return useFetchByParam({
    action: getSale,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitSale = (cashFlow, id) => {
  return useMemo(() => {
    if (!cashFlow || !id) {
      return {};
    }

    return { ...cashFlow };
  }, [cashFlow, id]);
};

export const useResetSale = () => {
  useResetState(resetSaleState);
};

export const useSaleColumns = () => {
  const [selectedSale] = useSelectedSale();
  const [, onSaleChange] = useSales();
  const onFieldChange = useCallback(
    (fieldName, value, updatedIndex) => {
      const nextMedicines = selectedSale?.medicines?.map((medicine, index) => {
        if(fieldName==='quantity' && index === updatedIndex){
          let _id = medicine.selectedBatches[0]._id,
              batch = medicine.batches.find((batch)=>batch._id===_id),
              exchangeValue = medicine.selectedVariant.exchangeValue
          if(batch.quantity / exchangeValue  < value){
            return medicine
          } 
        }
        return index === updatedIndex
          ? {
              ...medicine,
              [fieldName]: value,
              selectedVariant :  fieldName === 'selectedVariant' ? {...medicine.selectedVariant,...value} : medicine.selectedVariant,
              selectedBatches:
                fieldName === 'quantity'
                  ? [{ ...medicine.selectedBatches[0], quantity: value }]
                  : medicine.selectedBatches
            }
          : medicine;
      });

      onSaleChange(selectedSale.uuid, {
        medicines: nextMedicines
      });
    },
    [onSaleChange, selectedSale]
  );

  const onDiscountTypeChange = useCallback(
    (updatedIndex, type) => {
      const nextMedicines = selectedSale?.medicines.map((medicine, index) => {
        if (!index === updatedIndex) {
          return medicine;
        }
        const { discount, price } = selectedSale?.medicines[index];

        const discountValue = caculateDiscountValueOnTypeChange({
          value: discount.value,
          price,
          type
        });

        return {
          ...medicine,
          discount: {
            ...discount,
            type
          }
        };
      });

      onSaleChange(selectedSale.uuid, {
        medicines: nextMedicines
      });
    },
    [onSaleChange, selectedSale]
  );

  const onDiscountValueChange = useCallback(
    (updatedIndex, value, type) => {
      const nextMedicines = selectedSale?.medicines?.map((medicine, index) => {
        return index === updatedIndex
          ? {
              ...medicine,
              discount: {
                ...medicine.discount,
                value : type === DISCOUNT_TYPES.VALUE ? value : value * medicine.price / 100 ,
                percent : type === DISCOUNT_TYPES.VALUE  ? value / medicine.price * 100 : value 
              }
            }
          : medicine;
      });

      onSaleChange(selectedSale.uuid, {
        medicines: nextMedicines
      });
    },
    [onSaleChange, selectedSale]
  );

  const onDeleteMedicine = useCallback(
    delectedCode => {
      const nextMedicines = selectedSale.medicines.filter(
        ({ variantCode }) => variantCode !== delectedCode
      );

      onSaleChange(selectedSale.uuid, {
        medicines: nextMedicines
      });
    },

    [selectedSale, onSaleChange]
  );
  const selectVariantbyID= useCallback((product,key='cost')=>{
    switch (key) {
      case "price": return get(product,['selectedVariant','price'],0);        
      default: return get(product,['selectedVariant','cost'],0); ;
    }
  },[]) 

  const isDisableDelete =
    selectedSale?.medicines?.length === 1 &&
    selectedSale?.case === SALE_CASES.UPDATE_INVOICE;

  const handleSelect = useCallback((productId, variantId) => {
    const nextMedicines = selectedSale?.medicines.map((medicine, index) => {
        if (medicine?.productId !== productId) {
          return medicine
        }
        let selectedVariant = medicine?.relationVariants.find(item => item._id === variantId);
        let exchangeValue = selectedVariant.exchangeValue,
            axQuantity = medicine.quantity,
            initBatch = medicine.batches.filter((batch)=>{
               return parseFloat((batch.quantity/exchangeValue).toFixed(3)) > axQuantity
            }).reduce((final,finalCurrent,i)=>{
              let dateB = new Date(final?.expirationDate),
                  dateA = new Date(finalCurrent.expirationDate)
                  if((finalCurrent.quantity/selectedVariant.exchangeValue>=axQuantity && dateB > dateA)|| i===0  ){
                    return finalCurrent
                  }
                  return final
            },{})
            let selectedBatches = Object.values(initBatch).length? [{...initBatch , quantity:axQuantity}]:[]

      return {
        ...medicine,
        selectedBatches,
        selectedVariant ,
      };
    });

    onSaleChange(selectedSale?.uuid, {
      medicines: nextMedicines
    });
  }, [onSaleChange, selectedSale?.medicines, selectedSale?.uuid ])

  const columns = useMemo(() => {
    return [
      {
        title: '',
        key: 'delete',
        colSpan: 0,
        align: 'center',
        render: (product) => (
          <Button
            disabled={isDisableDelete}
            onClick={() => onDeleteMedicine(product?.variantCode)}
            size="small"
            shape="circle"
            className="warehouse-form__delete-btn"
            icon={<DeleteOutlined />}
          ></Button>
        )
      },
      {
        title: 'STT',
        colSpan: 2,
        key: 'STT',
        align: 'center',
        render: (_, x, index) => index + 1
      },
      {
        title: 'Mã hàng',
        key: 'code',
        dataIndex: ['selectedVariant', 'variantCode'],
        render: (code, product) => {
          return code || product?.variantCode
        }
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['product', 'name'],
        render: (name, product) => {
          return name || product?.code
        }
      },
      {
        title: 'ĐVT',
        key: 'unit',
        dataIndex: ['unit', 'name'],
        render: (unit, medicine) => {
          if (!medicine?.relationVariants) {
            return <div className="sale-table__unit">{unit}</div>
          }
          return (
            selectedSale?.typeSale !== 'PM' ?
              <div className="sale-table__unit">
                {medicine?.relationVariants.length <= 1 ? (
                  <span>
                    {unit}
                  </span>
                ) : (
                  <Dropdown
                    overlay={
                      <Menu>
                        {medicine?.relationVariants
                          .filter(
                            ({ _id }) => _id !== medicine?.relationVariants?._id
                          )
                          .map(({ _id, productUnit }) => {
                            return (
                              <Menu.Item
                                onClick={() =>
                                  handleSelect(medicine?.productId, _id)
                                }
                                style={{ minWidth: 120 }}
                                key={_id}
                              >
                                {productUnit?.name}
                              </Menu.Item>
                            );
                          })}
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <span
                      className="product-table__selected-variant"
                      onClick={e => e.preventDefault()}
                    >
                      {medicine?.selectedVariant?.productUnit?.name || medicine?.unit?.name}
                      <DownOutlined />
                    </span>
                  </Dropdown>
                )}
              </div>
              : <span> {medicine?.selectedVariant?.productUnit?.name || medicine.unit.name}</span>
          )
        }
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        render: (rc, _, index) => {
          return (
            <InputNumber
              className="warehouse-form__quantity"
              size="small"
              value={rc?.quantity}
              onChange={value => onFieldChange('quantity', value, index)}
              bordered={false}
              disabled={_?.disableFields || rc?.selectedBatches?.length !== 1 || selectedSale?.typeSale === 'PM'}
              min={0}
            />
          )
        }
      },
      ...(selectedSale?.typeSale !== 'PM' ? [{
        title: 'Đơn giá',
        key: 'price',
        render: (product, _, index) => {
          return (<InputNumber
            size="small"
            step={1000}
            // value={product?.selectedVariant?.cost || product?.price || 0}
            value={selectVariantbyID(product, 'price')}
            bordered={false}
            disabled={_?.disableFields}
            onChange={value => onFieldChange('selectedVariant', { price: value }, index)}
            {...currencyFormatter}
            min={0}
          />)
        }
      }] : []),
      ...(selectedSale?.typeSale !== 'PM' ? [{
        title: 'Giảm giá',
        key: 'discount',
        render: (product, _, index) => {
          return (
            <DiscountInput
              discount={product?.discount}
              disabled={_?.disableFields}
              onTypeChange={value => onDiscountTypeChange(index, value)}
              onValueChange={value => onDiscountValueChange(index, value, product?.discount.type)}
              max={product?.discount?.type === DISCOUNT_TYPES.PERCENT ? 100 : selectVariantbyID(product, 'price')}
            >
              <div className="warehouse-form-table__discount warehouse-form-table__discount--width-auto">
                {product?.discount?.type === DISCOUNT_TYPES.VALUE
                  ? formatNumber(product?.discount?.value)
                  : formatNumber(((product?.discount?.percent || 0) / 100) * product?.quantity * product?.price)
                }
              </div>
            </DiscountInput>
          );
        }
      }] : []),
      ...(selectedSale?.typeSale !== 'PM' ? [{
        title: 'Giá bán',
        key: 'realPrice',
        align: 'end',
        render: (product) =>
          formatNumber(getPriceAfterDiscount(selectVariantbyID(product, 'price')))
      }] : []),
      ...(selectedSale?.typeSale !== 'PM' ? [{
        title: 'Thành tiền',
        key: 'amount',
        align: 'end',
        render: (product) => {
          return product?.discount?.type === DISCOUNT_TYPES.PERCENT
            ? formatNumber(product?.quantity * getPriceAfterDiscount(selectVariantbyID(product, 'price'), product?.discount))
            : formatNumber(product?.quantity * getPriceAfterDiscountProd(selectVariantbyID(product, 'price'), product?.discount, product?.quantity))
        }
      }] : []),
    ]}, [
    onDiscountTypeChange,
    onFieldChange,
    onDiscountValueChange,
    onDeleteMedicine,
    isDisableDelete,
    handleSelect,
    selectVariantbyID,
  ]);

  return columns;
};

export const useSaleChange = (selectedSale, onSaleChange, typeReturn = false) => {
  const totalAmount = useMemo(() => {
    return selectedSale?.medicines?.filter(medicine => 
      medicine?.typeExchangeReturn === typeReturn 
      && (medicine.quantity!==0)).reduce(
      (total, medicine) => {
        let amount = 0;
        medicine?.discount?.type === DISCOUNT_TYPES.PERCENT ?  amount = (medicine?.quantity || 1) * getPriceAfterDiscount(medicine?.selectedVariant?.price || medicine?.selectedVariant?.cost, medicine?.discount || 0 ) :
        amount = (medicine?.quantity || 1) * getPriceAfterDiscountProd(medicine?.selectedVariant?.price || medicine?.selectedVariant?.cost, medicine?.discount || 0, medicine?.quantity || 1);
        return total + amount;
      },
      0
    );
  }, [selectedSale, typeReturn]);

  const totalItems = selectedSale?.medicines?.filter(medicine => (medicine?.typeExchangeReturn === typeReturn)&&(medicine.quantity!==0)).length;

  const discountValue = useMemo(() => {
    return getDiscountValue(totalAmount, selectedSale?.summary?.discount);
  }, [totalAmount, selectedSale]);

  const onDiscountTypeChange = useCallback(
    value => {
      const { summary, uuid } = selectedSale;
      const isFromValueToPercen = value === DISCOUNT_TYPES.PERCENT;
      const discountValue = isFromValueToPercen
        ? (summary.discount.value / totalAmount) * 100
        : (summary.discount.value * totalAmount) / 100;

      onSaleChange(uuid, {
        summary: {
          ...summary,
          discount: {
            type: value,
            value: discountValue
          }
        }
      });
    },
    [onSaleChange, selectedSale, totalAmount]
  );

  const onDiscountValueChange = useCallback(
    value => {
      const { uuid, summary } = selectedSale;
      onSaleChange(uuid, {
        summary: {
          ...summary,
          discount: {
            ...summary.discount,
            value
          }
        }
      });
    },
    [onSaleChange, selectedSale]
  );

  const onPaymentChange = (key, value) => {
    const { uuid, summary } = selectedSale;

    onSaleChange(uuid, {
      summary: {
        ...summary,
        payment: [{ ...summary.payment[0], [key]: value }]
      }
    });
  };

  return [
     totalAmount, discountValue, totalItems, onDiscountTypeChange, onDiscountValueChange, onPaymentChange
  ];
};

export const useOnAddSale = (type = SALE_TYPES.ORDER) => {
  const [sales] = useSales();
  const [, addSale] = useAddLocalSale();

  const onAddSale = () => {
    const newSale = {
      name: getSaleName(sales, type),
      medicines: [],
      summary: {
        discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
        payment: [{ method: PAYMENT_METHODS.CASH, value: 0 }]
      },
      uuid: uuidv4(),
      type
    };

    newSale.case = getSaleCase(newSale);

    addSale(newSale);
  };
  return {onAddSale}
}

//Update LOGISTIC CODE
export const useUpdateLogisticCodeSale = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateLogisticCodeSale
  });
};

const updateCheckInOutSelectorSuccess = getSelector('updateCheckInOutSuccess');
const updateCheckInOutSelectorFailed = getSelector('updateCheckInOutFailed');

export const useUpdateSaleCheckInOut = callback => {
  useSuccess(updateCheckInOutSelectorSuccess, 'Cập nhật hình ảnh cho đơn hàng thành công', callback);
  useFailed(updateCheckInOutSelectorFailed, 'Cập nhật hình ảnh cho đơn hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSaleCheckInOut
  });
};

export const useSetNewBill = () => {
  return useSubmit({
    loadingSelector: ()=> false,
    action : setNewBill
  })
}