import { omit } from 'lodash';
import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME_EN,
  ACTIVITY_NAME
} from '~/constants/defaultValue';
function* getSale({ payload }) {
  try {
    const { saleOrderId, invoiceId } = payload;

    const request = saleOrderId ? Api.sale.getOrder : Api.sale.getInvoice;
    const sale = yield call(request, saleOrderId || invoiceId);

    yield put({
      type: Types.GET_SALE_SUCCESS,
      payload: sale
    });
  } catch (error) {
    yield put({
      type: Types.GET_SALE_FAILED,
      payload: error
    });
  }
}

function* createSale(action) {
  try {
    const data = yield call(Api.sale.create, action.payload);
    yield put({ type: Types.CREATE_SALE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SALE_FAILED,
      payload: error
    });
  }
}
function* createSaleSuccessAndCreateActivity(action) { // customerNeedToPay
  try {
    if(action.payload.saleChannel === "ORDER"){
 const { createdBy, branchId, customerNeedToPay } = action.payload;
    const message = `${createdBy.fullName} ${MESSAGE_ACTIVITY.PRODUCT_ORDER} ${customerNeedToPay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy, message , action: ACTIVITY_NAME.PRODUCT_ORDER},
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
    }
    if(action.payload.saleChannel === "DIRECT"){
 const { createdBy, branchId, total } = action.payload;
    const message = `${createdBy.fullName} ${MESSAGE_ACTIVITY.SOLD_PRODUCT} ${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy, message , action: ACTIVITY_NAME.SOLD_PRODUCT},
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateSale(action) {
  try {
    const data = yield call(Api.sale.update, action.payload);
    yield put({ type: Types.UPDATE_SALE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SALE_FAILED,
      payload: error
    });
  }
}

function* updateLogisticCodeSale(action) {
  try {
    const data = yield call(Api.sale.updateLogisticCode, action.payload);
    yield put({ type: Types.UPDATE_LOGISTIC_CODE_SALE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_LOGISTIC_CODE_SALE_FAILED,
      payload: error
    });
  }
};

function* updateCheckInOut(action) {
  try {
    const type = action.payload?.type;
    let data;
    if (type === 'CHECKIN') {
       data = yield call(Api.sale.checkIn, omit(action.payload,['callbackSubmit']));
       if(action.payload?.callbackSubmit && typeof action.payload?.callbackSubmit === 'function') action.payload?.callbackSubmit({checkIn: data?.data?.checkIn});
       return yield put({ type: Types.SALE_CHECK_IN_OUT_SUCCESS, payload: data });
      } else if (type === 'CHECKOUT') {
      data = yield call(Api.sale.checkOut, omit(action.payload, ['callbackSubmit']));
      if(action.payload?.callbackSubmit && typeof action.payload?.callbackSubmit === 'function') action.payload?.callbackSubmit({checkOut:data?.data?.checkOut});
        return yield put({ type: Types.SALE_CHECK_IN_OUT_SUCCESS, payload: data });
    };
  } catch (error) {
    yield put({
      type: Types.SALE_CHECK_IN_OUT_FAILED,
      payload: error
    });
  }
}

export default function* sale() {
  yield takeLatest(Types.GET_SALE_REQUEST, getSale);
  yield takeLatest(Types.UPDATE_SALE_REQUEST, updateSale);
  yield takeLatest(Types.CREATE_SALE_REQUEST, createSale);
  yield takeLatest(Types.CREATE_SALE_SUCCESS, createSaleSuccessAndCreateActivity);
  yield takeLatest(Types.UPDATE_LOGISTIC_CODE_SALE_REQUEST, updateLogisticCodeSale);
  yield takeLatest(Types.SALE_CHECK_IN_OUT_REQUEST, updateCheckInOut);
}
