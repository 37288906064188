import React, { useMemo, useState } from 'react';
import { Table, Input, Typography } from 'antd';
import { formatNumber } from '~/utils/helper';
import { SearchOutlined } from '@ant-design/icons';
import { compact } from 'lodash';

const { Text } = Typography;

const DetailTable = ({ datasource }) => {
  const [codeKeyword, setCodeKeyword] = useState(null);
  const [nameKeyword, setNameKeyword] = useState(null);

  const data = useMemo(() => {
    let displayedOrders = datasource.stocktakeDetail
      .filter(order =>
        !codeKeyword
          ? true
          : order.variantId.variantCode
            .toLowerCase()
            .includes(codeKeyword.toLowerCase())
      )
      .filter(order =>
        !nameKeyword
          ? true
          : order.productId?.name
              ?.toLowerCase()
              ?.includes?.(nameKeyword.toLowerCase())
      );

    const dataNew = displayedOrders.map(order => {
      return {
        totalInventoryQuantity: order.inventoryQuantity,
        totalActualCount: order.actualCount,
        totalAdjustmentValue: (order.actualCount-order.inventoryQuantity) ,
        ...order,
      };
    });

    return [{ isSummaryRow: true, variantId: -1 }, ...dataNew];
  }, [codeKeyword, nameKeyword, datasource]);
  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'code',
        render: record => {
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm mã hàng"
              value={codeKeyword}
              onChange={e => setCodeKeyword(e.target.value)}
            />
          ) : (
            record?.variantId?.variantCode ??''
          );
        },
        width: 150
      },
      {
        title: 'Tên hàng',
        key: 'name',
        render: record => {
          const lotArea =[compact(record?.batchId?.batchSearch).join(" - ")]
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm tên hàng"
              value={nameKeyword}
              onChange={e => setNameKeyword(e.target.value)}
            />
          ) : (
          <>
           { record?.productId?.name??""}
          {lotArea && <div style={{marginTop:4,display:"flex",flexWrap:"wrap"}}>{lotArea?.map((e)=><span style={{backgroundColor:"#3d7ff3",padding:3,borderRadius:2 ,marginRight :10 ,color:'white'}}>{e}</span>) }</div>}
          </>
          );
        },
        width: 400
      },
      {
        title: 'Tồn kho',
        key: 'totalInventoryQuantity',
        dataIndex: 'totalInventoryQuantity',
        align: 'right'
      },
      {
        title: 'Thực tế',
        key: 'totalActualCount',
        dataIndex: 'totalActualCount',
        align: 'right'
      },
      {
        title: 'SL lệch',
        key: 'totalAdjustmentValue',
        dataIndex: 'totalAdjustmentValue',
        align: 'right'
      }
    ];
  }, [codeKeyword, nameKeyword]);

  const summary = useMemo(() => {
    const summaryData = [
      {
        label: 'Số lượng thực tế',
        value: formatNumber(datasource.totalActualCount)
      },
      {
        label: 'Số lượng lệch tăng',
        value: formatNumber(datasource.totalIncreaseValue)
      },
      {
        label: 'Số lượng lệch giảm',
        value: formatNumber(datasource.totalReducedValue)
      },
      {
        label: 'Số lượng chênh lệch',
        value: formatNumber(datasource.totalAdjustmentValue)
      },
    ];

    return summaryData.map(({ label, value }, index) => {
      return (
        <Table.Summary.Row key={index}>
          <Table.Summary.Cell colSpan={4} align="right">
            {label}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{value}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });
  }, [datasource]);

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        className="nested-table"
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        rowKey={rc => rc.variantId}
        summary={() => (
          <Table.Summary className="table-summary">{summary}</Table.Summary>
        )}
      />
    </div>
  );
};

export default DetailTable;
