import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getManufactures({ payload: query }) {
  try {
    const data = yield call(Api.manufacture.getAll, query);
    yield put({ type: Types.GET_MANUFACTURES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MANUFACTURES_FAILED,
      payload: error
    });
  }
}

function* getManufacture({ payload: id }) {
  try {
    const data = yield call(Api.manufacture.getById, id);
    yield put({ type: Types.GET_MANUFACTURE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_MANUFACTURE_FAILED, payload: error });
  }
}

function* createManufacture(action) {
  try {
    const data = yield call(Api.manufacture.create, action.payload);
    yield put({ type: Types.CREATE_MANUFACTURE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MANUFACTURE_FAILED,
      payload: error
    });
  }
}

function* updateManufacture(action) {
  try {
    const data = yield call(Api.manufacture.update, action.payload);
    yield put({ type: Types.UPDATE_MANUFACTURE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.UPDATE_MANUFACTURE_FAILED, payload: error });
  }
}

function* deleteManufacture({ payload }) {
  try {
    yield call(Api.manufacture.delete, payload);
    yield put({ type: Types.DELETE_MANUFACTURE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.DELETE_MANUFACTURE_FAILED, payload: error });
  }
}
function* getManufactureOptions({ payload: query }) {
  try {
    const data = yield call(Api.manufacture.getOptions, query);
    yield put({ type: Types.GET_MANUFACTURE_OPTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MANUFACTURE_OPTIONS_FAILED,
      payload: error
    });
  }
}

export default function* manufacture() {
  yield takeLatest(Types.GET_MANUFACTURES_REQUEST, getManufactures);
  yield takeLatest(Types.GET_MANUFACTURE_REQUEST, getManufacture);
  yield takeLatest(Types.CREATE_MANUFACTURE_REQUEST, createManufacture);
  yield takeLatest(Types.UPDATE_MANUFACTURE_REQUEST, updateManufacture);
  yield takeLatest(Types.DELETE_MANUFACTURE_REQUEST, deleteManufacture);
  yield takeLatest(Types.GET_MANUFACTURE_OPTIONS_REQUEST, getManufactureOptions);
}
