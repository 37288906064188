export const GET_STOCK_TAKES_REQUEST = 'GET_STOCK_TAKES_REQUEST';
export const GET_STOCK_TAKES_SUCCESS = 'GET_STOCK_TAKES_SUCCESS';
export const GET_STOCK_TAKES_FAILED = 'GET_STOCK_TAKES_FAILED';

export const GET_STOCK_TAKE_REQUEST = 'GET_STOCK_TAKE_REQUEST';
export const GET_STOCK_TAKE_SUCCESS = 'GET_STOCK_TAKE_SUCCESS';
export const GET_STOCK_TAKE_FAILED = 'GET_STOCK_TAKE_FAILED';

export const CREATE_STOCK_TAKE_REQUEST = 'CREATE_STOCK_TAKE_REQUEST';
export const CREATE_STOCK_TAKE_SUCCESS = 'CREATE_STOCK_TAKE_SUCCESS';
export const CREATE_STOCK_TAKE_FAILED = 'CREATE_STOCK_TAKE_FAILED';

export const SAVE_STOCK_TAKE_LUMPED_REQUEST = 'SAVE_STOCK_TAKE_LUMPED_REQUEST';
export const SAVE_STOCK_TAKE_LUMPED_SUCCESS = 'SAVE_STOCK_TAKE_LUMPED_SUCCESS';
export const SAVE_STOCK_TAKE_LUMPED_FAILED = 'SAVE_STOCK_TAKE_LUMPED_FAILED';

export const UPDATE_STOCK_TAKE_REQUEST = 'UPDATE_STOCK_TAKE_REQUEST';
export const UPDATE_STOCK_TAKE_SUCCESS = 'UPDATE_STOCK_TAKE_SUCCESS';
export const UPDATE_STOCK_TAKE_FAILED = 'UPDATE_STOCK_TAKE_FAILED';

export const DELETE_STOCK_TAKE_REQUEST = 'DELETE_STOCK_TAKE_REQUEST';
export const DELETE_STOCK_TAKE_SUCCESS = 'DELETE_STOCK_TAKE_SUCCESS';
export const DELETE_STOCK_TAKE_FAILED = 'DELETE_STOCK_TAKE_FAILED';

export const SEARCH_STOCK_TAKES_REQUEST = 'SEARCH_STOCK_TAKES_REQUEST';
export const SEARCH_STOCK_TAKES_SUCCESS = 'SEARCH_STOCK_TAKES_SUCCESS';
export const SEARCH_STOCK_TAKES_FAILED = 'SEARCH_STOCK_TAKES_FAILED';

export const RESET_STOCK_TAKE_STATE = 'RESET_STOCK_TAKE_STATE';
