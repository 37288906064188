import { Skeleton } from 'antd';
import { Card, CardBody } from 'reactstrap';

const PendingOrdersSkeleton = () => {
  return Array(5)
    .fill(0)
    .map((_, key) => (
      <Card key={key}>
        <CardBody>
          <Skeleton active title paragraph={{ rows: 2 }} />
        </CardBody>
      </Card>
    ));
};

export default PendingOrdersSkeleton;
