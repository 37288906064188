import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],
  summary :null,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  isCreatePayRecNoLoading: false,
  createPayRecNoSuccess: null,
  createPayRecNoFailed: null,

  createRecipientSubmitterSuccess: null,
  isCreateRecipientSubmitterLoading: false,
  createRecipientSubmitterFailed: null,

  updateSuccess: null,
  updateFailed: null,

  payerReceivers: [],
  isSearchPayerReceiversLoading: false,
  searchPayerReceiversFailedSelector: null,

  payReceivesType: [],
  isSearchLoadingPayReceive: false,
  searchPayReceiveTypeFailedSelector: null,

  paging: { current: 1, pageSize: 11 }
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CASH_FLOWS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_CASH_FLOWS_SUCCESS:
      state.isLoading = false;
      state.summary = payload?.summary
      state.list = payload?.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CASH_FLOWS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.CREATE_RECIPIENT_SUBMITTER_REQUEST:
      state.isCreateRecipientSubmitterLoading = true;
      state.createRecipientSubmitterSuccess = null;
      state.createRecipientSubmitterFailed = null;
      return;

    case Types.CREATE_RECIPIENT_SUBMITTER_SUCCESS:
      state.isCreateRecipientSubmitterLoading = false;
      state.createRecipientSubmitterSuccess = payload;
      return;

    case Types.CREATE_RECIPIENT_SUBMITTER_FAILED:
      state.isCreateRecipientSubmitterLoading = false;
      state.createRecipientSubmitterFailed = payload;
      return;

    case Types.CREATE_PAYMENT_NOTE_TYPE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PAYMENT_NOTE_TYPE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PAYMENT_NOTE_TYPE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.CREATE_PAY_REC_NOTE_REQUEST:
      state.isCreatePayRecNoLoading = true;
      state.createPayRecNoSuccess = null;
      state.createPayRecNoFailed = null;
      return;

    case Types.CREATE_PAY_REC_NOTE_SUCCESS:
      state.isCreatePayRecNoLoading = false;
      state.createPayRecNoSuccess = payload;
      state.list = [...state.list, payload];
      return;

    case Types.CREATE_PAY_REC_NOTE_FAILED:
      state.isCreatePayRecNoLoading = null;
      state.createPayRecNoFailed = payload;
      return;

    case Types.SEARCH_PAYER_RECEIVERS_REQUEST:
      state.isSearchPayerReceiversLoading = true;
      state.searchPayerReceiversFailedSelector = null;
      return;

    case Types.SEARCH_PAYER_RECEIVERS_SUCCESS:
      state.isSearchPayerReceiversLoading = false;
      state.payerReceivers = [...payload];

      return;

    case Types.SEARCH_PAYER_RECEIVERS_FAILED:
      state.isSearchPayerReceiversLoading = false;
      state.searchPayerReceiversFailedSelector = null;
      state.payReceivesType = [];
      return;

    case Types.SEARCH_PAY_RECEIVE_TYPE_REQUEST:
      state.isSearchLoadingPayReceive = true;
      state.searchPayReceiveTypeFailedSelector = null;
      return;

    case Types.SEARCH_PAY_RECEIVE_TYPE_SUCCESS:
      state.isSearchLoadingPayReceive = false;
      state.payReceivesType = [...payload];

      return;

    case Types.SEARCH_PAY_RECEIVE_TYPE_FAILED:
      state.isSearchLoadingPayReceive = false;
      state.searchPayReceiveTypeFailedSelector = null;
      state.payReceivesType = [];
      return;

    case Types.GET_CASH_FLOW_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_CASH_FLOW_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_CASH_FLOW_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_CASH_FLOW_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_CASH_FLOW_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_CASH_FLOW_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_CASH_FLOW_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_CASH_FLOW_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_CASH_FLOW_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_CASH_FLOW_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_CASH_FLOW_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_CASH_FLOW_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_CASH_FLOW_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
