import user from './user';
import hospital from './hospital';
import appointment from './appointment';
import degree from './degree';
import speciality from './speciality';
import geo from './geo';
import staff from './staff';
import service from './service';
import newsCategory from './newsCategory';
import news from './news';
import staffGroup from './staffGroup';
import branch from './branch';
import employee from './employee';
import order from './order';
import employeeGroup from './employeeGroup';
import partner from './partner';
import userEmployee from './userEmployee';
import partnership from './partnership';
import coupon from './coupon';
import policy from './policy';
import dashboard from './dashboard';
import shippingVendor from './shippingVendor';
import permission from './permission';

const worldCareApi = {
  user,
  hospital,
  appointment,
  degree,
  speciality,
  geo,
  staff,
  service,
  newsCategory,
  news,
  staffGroup,
  branch,
  employee,
  employeeGroup,
  partner,
  order,
  userEmployee,
  partnership,
  coupon,
  policy,
  dashboard,
  shippingVendor,
  permission
};

export default worldCareApi;
