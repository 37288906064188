export const GET_CASH_FLOWS_REQUEST = 'GET_CASH_FLOWS_REQUEST';
export const GET_CASH_FLOWS_SUCCESS = 'GET_CASH_FLOWS_SUCCESS';
export const GET_CASH_FLOWS_FAILED = 'GET_CASH_FLOWS_FAILED';

export const GET_CASH_FLOW_REQUEST = 'GET_CASH_FLOW_REQUEST';
export const GET_CASH_FLOW_SUCCESS = 'GET_CASH_FLOW_SUCCESS';
export const GET_CASH_FLOW_FAILED = 'GET_CASH_FLOW_FAILED';

export const CREATE_CASH_FLOW_REQUEST = 'CREATE_CASH_FLOW_REQUEST';
export const CREATE_CASH_FLOW_SUCCESS = 'CREATE_CASH_FLOW_SUCCESS';
export const CREATE_CASH_FLOW_FAILED = 'CREATE_CASH_FLOW_FAILED';

export const CREATE_PAYMENT_NOTE_TYPE_REQUEST = 'CREATE_PAYMENT_NOTE_TYPE_REQUEST';
export const CREATE_PAYMENT_NOTE_TYPE_SUCCESS = 'CREATE_PAYMENT_NOTE_TYPE_SUCCESS';
export const CREATE_PAYMENT_NOTE_TYPE_FAILED = 'CREATE_PAYMENT_NOTE_TYPE_FAILED';

export const CREATE_RECIPIENT_SUBMITTER_REQUEST = 'CREATE_RECIPIENT_SUBMITTER_REQUEST';
export const CREATE_RECIPIENT_SUBMITTER_SUCCESS = 'CREATE_RECIPIENT_SUBMITTER_SUCCESS';
export const CREATE_RECIPIENT_SUBMITTER_FAILED = 'CREATE_RECIPIENT_SUBMITTER_FAILED';

export const CREATE_PAY_REC_NOTE_REQUEST = 'CREATE_PAY_REC_NOTE_REQUEST'
export const CREATE_PAY_REC_NOTE_SUCCESS = 'CREATE_PAY_REC_NOTE_SUCCESS'
export const CREATE_PAY_REC_NOTE_FAILED = 'CREATE_PAY_REC_NOTE_FAILED'

export const SEARCH_PAYER_RECEIVERS_REQUEST = 'SEARCH_PAYER_RECEIVERS_REQUEST';
export const SEARCH_PAYER_RECEIVERS_SUCCESS = 'SEARCH_PAYER_RECEIVERS_SUCCESS';
export const SEARCH_PAYER_RECEIVERS_FAILED= 'SEARCH_PAYER_RECEIVERS_FAILED';

export const SEARCH_PAY_RECEIVE_TYPE_REQUEST = 'SEARCH_PAY_RECEIVE_TYPE_REQUEST';
export const SEARCH_PAY_RECEIVE_TYPE_SUCCESS = 'SEARCH_SEARCH_PAY_RECEIVE_TYPE_SUCCESS';
export const SEARCH_PAY_RECEIVE_TYPE_FAILED= 'SEARCH_PAY_RECEIVE_TYPE_FAILED';

export const UPDATE_CASH_FLOW_REQUEST = 'UPDATE_CASH_FLOW_REQUEST';
export const UPDATE_CASH_FLOW_SUCCESS = 'UPDATE_CASH_FLOW_SUCCESS';
export const UPDATE_CASH_FLOW_FAILED = 'UPDATE_CASH_FLOW_FAILED';

export const DELETE_CASH_FLOW_REQUEST = 'DELETE_CASH_FLOW_REQUEST';
export const DELETE_CASH_FLOW_SUCCESS = 'DELETE_CASH_FLOW_SUCCESS';
export const DELETE_CASH_FLOW_FAILED = 'DELETE_CASH_FLOW_FAILED';

export const RESET_CASH_FLOW_STATE = 'RESET_CASH_FLOW_STATE';
