import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateBatchByOrderSuccess: null,
  updateBatchByOrderFailed: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_BATCHES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      state.list = [];
      return;

    case Types.GET_BATCHES_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      return;

    case Types.GET_BATCHES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.CREATE_BATCH_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_BATCH_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.list = [...state.list, payload];
      return;

    case Types.CREATE_BATCH_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      
    case Types.UPDATE_BATCH_BY_ORDER_REQUEST:
      state.isSubmitLoading = true;
      state.updateBatchByOrderSuccess = null;
      state.updateBatchByOrderFailed = null;
      return;

    case Types.UPDATE_BATCH_BY_ORDER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateBatchByOrderSuccess = payload;
      return;

    case Types.UPDATE_BATCH_BY_ORDER_FAILED:
      state.isSubmitLoading = false;
      state.updateBatchByOrderFailed = payload;
      return;

    case Types.RESET_BATCH_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
