import * as Types from '~/constants/actionTypes';

export const getPolicies = (query) => ({
  type: Types.GET_POLICIES_REQUEST,
  payload: query
});

export const getPolicy = (id) => ({
  type: Types.GET_POLICY_REQUEST,
  payload: id
});

export const createPolicy = (policy) => ({
  type: Types.CREATE_POLICY_REQUEST,
  payload: policy
});

export const updatePolicy = (policy) => ({
  type: Types.UPDATE_POLICY_REQUEST,
  payload: policy
});

export const deletePolicy = (id) => ({
  type: Types.DELETE_POLICY_REQUEST,
  payload: id
});

export const resetPolicyState = () => ({
  type: Types.RESET_POLICY_STATE
});
