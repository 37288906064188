import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useMatchOrPolicy, useMatchPolicy } from '~/hooks';

const RedirectTo = ({ location, path }) => {
  return <Redirect to={{ pathname: path, state: { from: location } }} />;
};

const PermissionGate = ({ component: Component, permission, ...rest }) => {
  const isMatchPolicy = useMatchPolicy(permission);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isMatchPolicy) {
          return <RedirectTo path="/" {...props} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PermissionGate;

export const PermissionGateOr = ({ component: Component, permission, ...rest }) => {
  const isMatchOrPolicy = useMatchOrPolicy(permission);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isMatchOrPolicy) {
          return <RedirectTo path="/" {...props} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

