export const GET_MANUFACTURES_REQUEST = 'GET_MANUFACTURES_REQUEST';
export const GET_MANUFACTURES_SUCCESS = 'GET_MANUFACTURES_SUCCESS';
export const GET_MANUFACTURES_FAILED = 'GET_MANUFACTURES_FAILED';

export const GET_MANUFACTURE_REQUEST = 'GET_MANUFACTURE_REQUEST';
export const GET_MANUFACTURE_SUCCESS = 'GET_MANUFACTURE_SUCCESS';
export const GET_MANUFACTURE_FAILED = 'GET_MANUFACTURE_FAILED';

export const CREATE_MANUFACTURE_REQUEST = 'CREATE_MANUFACTURE_REQUEST';
export const CREATE_MANUFACTURE_SUCCESS = 'CREATE_MANUFACTURE_SUCCESS';
export const CREATE_MANUFACTURE_FAILED = 'CREATE_MANUFACTURE_FAILED';

export const UPDATE_MANUFACTURE_REQUEST = 'UPDATE_MANUFACTURE_REQUEST';
export const UPDATE_MANUFACTURE_SUCCESS = 'UPDATE_MANUFACTURE_SUCCESS';
export const UPDATE_MANUFACTURE_FAILED = 'UPDATE_MANUFACTURE_FAILED';

export const DELETE_MANUFACTURE_REQUEST = 'DELETE_MANUFACTURE_REQUEST';
export const DELETE_MANUFACTURE_SUCCESS = 'DELETE_MANUFACTURE_SUCCESS';
export const DELETE_MANUFACTURE_FAILED = 'DELETE_MANUFACTURE_FAILED';

export const GET_MANUFACTURE_OPTIONS_REQUEST = 'GET_MANUFACTURE_OPTIONS_REQUEST';
export const GET_MANUFACTURE_OPTIONS_SUCCESS = 'GET_MANUFACTURE_OPTIONS_SUCCESS';
export const GET_MANUFACTURE_OPTIONS_FAILED = 'GET_MANUFACTURE_OPTIONS_FAILED';

export const RESET_MANUFACTURE_STATE = 'RESET_MANUFACTURE_STATE';
