import React from 'react';
import { Input, InputNumber, Form, Select } from 'antd';

const EditableCell = ({
  editing,
  onSave,
  form,
  dataIndex,
  title,
  inputType,
  record,
  selectOptions = [],
  index,
  required,
  children,
  ...restProps
}) => {
  const save = async () => {
    try {
      const values = await form.validateFields();
      if (form.isFieldTouched(dataIndex)) {
        onSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  let inputNode = <Input onPressEnter={save} onBlur={save} />;
  switch (inputType) {
    case 'number':
      inputNode = (
        <InputNumber
          onPressEnter={save}
          onBlur={save}
          min="1"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      );
      break;
    case 'select':
      inputNode = (
        <Select onChange={save}>
          {selectOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    default:
      inputNode = <Input onPressEnter={save} onBlur={save} />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[{ required: required }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
