import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors
} from '~/hooks/utils';
import { getTotalQuantity } from '~/utils/caculator';
import { getBatches, createBatch, resetBatchState, updateBatchByOrder } from '~/redux/action';

const BATCH_MODULE = 'batch';

const CREATE_SUCCESS_MESS = 'Tạo mới lô hàng thành công';
const CREATE_FAILED_MESS = 'Tạo mới lô hàng thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector
} = getSelectors(BATCH_MODULE);

const getSelectorOther = key => state => state[BATCH_MODULE][key];
const updateBatchByOrderSuccessSelector = getSelectorOther('updateBatchByOrderSuccess');
const updateBatchByOrderFailedSelector = getSelectorOther('updateBatchByOrderFailed');

export const useBatches = variantId => {
  return useFetchByParam({
    action: getBatches,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: variantId
  });
};

export const useCreateBatch = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createBatch
  });
};

export const useResetBatch = () => {
  useResetState(resetBatchState);
};

export const useBatchActions = ({
  updateVariant,
  setFormData,
  formData,
  selectedVariant
}) => {
  const onCreateBatchSuccess = batch => {
    const addedQuantity = batch.quantity;

    setFormData(formData =>
      formData.map(item => {
        return item._id === selectedVariant._id
          ? {
              ...item,
              batches: [batch, ...item.batches],
              selectedBatches: [
                ...item.selectedBatches,
                { ...batch, quantity: addedQuantity }
              ],
              quantity: item.quantity + addedQuantity
            }
          : item;
      })
    );
  };

  const onSelectBatches = batches => {
    const selectedBatches = batches
      .filter(({ isSelected }) => isSelected)
      .map(batch => ({ ...batch, isSelected: undefined }));

    const updatedVariant = {
      ...selectedVariant,
      selectedBatches,
      quantity: getTotalQuantity(selectedBatches)
    };

    updateVariant(updatedVariant);
  };

  const onUpdateBatch = updatedBatch => {
    const updatedVariant = formData.find(
      variant => variant._id === updatedBatch.variantId
    );

    updatedVariant.selectedBatches = updatedVariant.selectedBatches.map(
      batch => {
        const notMatchId = batch._id && batch._id !== updatedBatch._id;
        const notMatchBatchId =
          batch.batchId && batch.batchId !== updatedBatch.batchId;

        return notMatchId || notMatchBatchId ? batch : { ...updatedBatch };
      }
    );

    updateVariant({
      ...updatedVariant,
      quantity: getTotalQuantity(updatedVariant.selectedBatches)
    });
    //cập nhật batch ở đây
  };

  const onRemoveBatch = removedBatch => {
    const updatedVariant = formData.find(
      variant => variant._id === removedBatch.variantId
    );

    updatedVariant.selectedBatches = updatedVariant.selectedBatches.filter(
      batch => {
        if (batch._id) return batch._id !== removedBatch._id;

        return batch.batchId !== removedBatch.batchId;
      }
    );

    updateVariant({
      ...updatedVariant,
      quantity: getTotalQuantity(updatedVariant.selectedBatches)
    });
  };

  return {
    onCreateBatchSuccess,
    onSelectBatches,
    onUpdateBatch,
    onRemoveBatch
  };
};

export const useUpdateBatchByIdOrder = callback => {
  useSuccess(updateBatchByOrderSuccessSelector, 'Cập nhật lô thành công', callback);
  useFailed(updateBatchByOrderFailedSelector, 'Cập nhật lô thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateBatchByOrder
  });
};