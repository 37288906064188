import requester from './requester';

const supplier = {
  getAll: query => requester.get(`/supplier`, query),
  getById: id => requester.get(`/supplier/${id}`),
  create: supplier => requester.post('/supplier', supplier),
  update: supplier => requester.put(`/supplier/${supplier._id}`, supplier),
  delete: id => requester.delete(`/supplier/${id}`),

  getAllAuthenticated: query => requester.get(`/search-supplier`, query)
};

export default supplier;
