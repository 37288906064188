import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getInformationPayment({ payload: query }) {
  try {
    const data = yield call(Api.informationPayment.getAll, query);
    yield put({ type: Types.GET_IMFORMATION_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_IMFORMATION_PAYMENT_FAILED,
      payload: error
    });
  }
}


function* createInformationPayment(action) {
  try {
    const data = yield call(Api.informationPayment.create, action.payload);
    yield put({ type: Types.CREATE_IMFORMATION_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_IMFORMATION_PAYMENT_FAILED,
      payload: error
    });
  }
}

function* updateInformationPayment(action) {
  try {
    const data = yield call(Api.informationPayment.update, action.payload);
    yield put({ type: Types.UPDATE_IMFORMATION_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_IMFORMATION_PAYMENT_FAILED,
      payload: error
    });
  }
}



export default function* informationPayment() {
  yield takeLatest(Types.GET_IMFORMATION_PAYMENT_REQUEST, getInformationPayment);
  yield takeLatest(Types.CREATE_IMFORMATION_PAYMENT_REQUEST, createInformationPayment);
  yield takeLatest(Types.UPDATE_IMFORMATION_PAYMENT_REQUEST, updateInformationPayment);
}
