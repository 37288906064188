export const GET_WAREHOUSES_REQUEST = 'GET_WAREHOUSES_REQUEST';
export const GET_WAREHOUSES_SUCCESS = 'GET_WAREHOUSES_SUCCESS';
export const GET_WAREHOUSES_FAILED = 'GET_WAREHOUSES_FAILED';

export const GET_WAREHOUSE_REQUEST = 'GET_WAREHOUSE_REQUEST';
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';
export const GET_WAREHOUSE_FAILED = 'GET_WAREHOUSE_FAILED';

export const CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILED = 'CREATE_WAREHOUSE_FAILED';

export const UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILED = 'UPDATE_WAREHOUSE_FAILED';

export const DELETE_WAREHOUSE_REQUEST = 'DELETE_WAREHOUSE_REQUEST';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAILED = 'DELETE_WAREHOUSE_FAILED';

export const RESET_WAREHOUSE_STATE = 'RESET_WAREHOUSE_STATE';
