import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME
} from '~/constants/defaultValue';
function* getDamages({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const damage = yield call(Api.damage.getById, query.id);
      data = { docs: [damage], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.damage.getAll, query);
    }

    yield put({ type: Types.GET_DAMAGES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_DAMAGES_FAILED,
      payload: error
    });
  }
}

function* getDamage({ payload }) {
  try {
    const damage = yield call(Api.damage.getById, payload);
    yield put({
      type: Types.GET_DAMAGE_SUCCESS,
      payload: damage
    });
  } catch (error) {
    yield put({
      type: Types.GET_DAMAGE_FAILED,
      payload: error
    });
  }
}

function* createDamage(action) {
  try {
    const data = yield call(Api.damage.create, action.payload);
    yield put({ type: Types.CREATE_DAMAGE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_DAMAGE_FAILED,
      payload: error
    });
  }
}
function* createDamageSuccessAndCreateActivity(action) {
  try {
    const { createdBy, branchId, totalCost } = action.payload;
    const message = `${createdBy.fullName} ${MESSAGE_ACTIVITY.DAMAGE} ${totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy, message , action: ACTIVITY_NAME.DAMAGE},
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
  } catch (error) {
    console.log(error);
  }
}
function* updateDamage(action) {
  try {
    const data = yield call(Api.damage.update, action.payload);
    yield put({ type: Types.UPDATE_DAMAGE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_DAMAGE_FAILED,
      payload: error
    });
  }
}

function* deleteDamage({ payload }) {
  try {
    yield call(Api.damage.delete, payload);
    yield put({ type: Types.DELETE_DAMAGE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_DAMAGE_FAILED,
      payload: error
    });
  }
}

export default function* damage() {
  yield takeLatest(Types.GET_DAMAGES_REQUEST, getDamages);
  yield takeLatest(Types.GET_DAMAGE_REQUEST, getDamage);
  yield takeLatest(Types.DELETE_DAMAGE_REQUEST, deleteDamage);
  yield takeLatest(Types.CREATE_DAMAGE_REQUEST, createDamage);
  yield takeLatest(Types.CREATE_DAMAGE_SUCCESS, createDamageSuccessAndCreateActivity);
  yield takeLatest(Types.UPDATE_DAMAGE_REQUEST, updateDamage);
}
