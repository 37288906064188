import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Skeleton, Modal } from 'antd';
import UploadImage from '~/components/Utils/UploadImage';

import { onFormAddressChange } from '~/utils/helper';
import {
  useCreatePartnership,
  usePartnership,
  useInitPartnership,
  useUpdatePartnership
} from '~/hooks';
import './index.scss';
import { BASE_URL } from '~/constants/defaultValue';

const FormItem = Form.Item;

const PartnershipForm = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState();

  const [isSubmitLoading, handleCreate] = useCreatePartnership(onClose);
  const [, handleUpdate] = useUpdatePartnership(onClose);
  const [partnership, isLoading] = usePartnership(id);
  const initPartnership = useInitPartnership(partnership, id);

  useEffect(() => {
    form.resetFields();
    const { logo } = initPartnership;

    if (logo) {
      setLogo(logo);
    }
  }, [initPartnership, form]);

  const onFinish = (values) => {
    const partnership = {
      ...values,
      logo
    };

    if (id) {
      handleUpdate({ ...partnership, _id: id });
    } else {
      handleCreate(partnership);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="partnership-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values) => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initPartnership}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} đối tác
            </h4>
          </Row>
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="partnership-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên đối tác"
                name="name"
                rules={[
                  { required: true, message: 'Xin mời nhập tên đối tác' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Email bạn nhập không đúng định dạng!'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem
                label="Số điện thoại"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,11}$/),
                    message: 'Xin vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12} className="partnership-form__upload-logo">
              <UploadImage
                onChange={setLogo}
                imgUrl={logo}
                action={`${BASE_URL}/api/v1/file/partnership`}
              />
            </Col>
          </Row>

          <FormItem
            labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
            wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
            label="Địa chỉ"
            name="address"
            className="partnership-form__description form-item-horizontal--max-width"
          >
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>

          <FormItem
            labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
            wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
            label="Mô tả ngắn"
            name="description"
            className="partnership-form__description form-item-horizontal--max-width"
          >
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>

          <Row className="partnership-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default PartnershipForm;
