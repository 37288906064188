import React, { useState, useMemo } from 'react';
import {
  useSupplierGroups,
  useDeleteSupplierGroup,
  useSupplierGroupQueryParams,
  useUpdateSupplierGroupParams,
  useResetSupplierGroup,
  useSupplierGroupPaging,
  useUpdateSupplierGroup
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Checkbox, Radio, Space, Typography, Row, } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE, STATUS, STATUS_NAMES } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import SupplierGroupForm from './Form';
import { get, omit } from 'lodash';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

const { Search } = Input;

const ColumnActions = ({ _id, deleteGroup, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_SUPPLIERGROUP}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_SUPPLIERGROUP}>
        <WithPermission permission={POLICY.DELETE_SUPPLIERGROUP}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_SUPPLIERGROUP}>
        <Popconfirm
          title="Bạn muốn xoá nhóm nhà cung cấp này?"
          onConfirm={() => deleteGroup(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const SupplierGroups = () => {
  const [query] = useSupplierGroupQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateSupplierGroupParams(query);

  const [groups, isLoading] = useSupplierGroups(query);
  const paging = useSupplierGroupPaging();
  const [groupId, setGroupId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const numberOfGroups = groups.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfGroups === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteGroup] = useDeleteSupplierGroup(onDeleteSuccess);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_SUPPLIERGROUP);
  const canDelete = useMatchPolicy(POLICY.DELETE_SUPPLIERGROUP);

  useResetSupplierGroup();

  const onOpenForm = id => {
    setGroupId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setGroupId(null);
    setIsOpenForm(false);
  };

  const [isSubmitLoading, handleUpdate] = useUpdateSupplierGroup(onCloseForm);

  const onChangeStatus = (record, _id, status, isSubmitLoading) => {
    handleUpdate({
      _id,
      status,
      isSubmitLoading,
      ...omit(record, ['_id', 'status'])
    });
  };
  const onChange = ({ target }) => {
    switch (target.value) {
     case 2:
       onParamChange({...query, status: STATUS['ACTIVE']})
       break;
     case 3:
       onParamChange({...query, status: STATUS['INACTIVE']})
       break;
     default:
       onParamChange({...query ,status:'' })
       break;
    }
 }

  const columns = useMemo(
    () => [
      {
        title: 'Tên nhóm nhà cung cấp',
        key: 'name',
        dataIndex: 'name'
      },

      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        width: 150,
        render: (status, record) => {
          return (
            <Checkbox
              checked={status === STATUS.ACTIVE}
              onChange={e => {
                return onChangeStatus(
                  record,
                  get(record, '_id'),
                  e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE,
                  isSubmitLoading
                );
              }}
            />
          );
        }
      },
      ...(canDelete || canUpdate
        ? [
            {
              title: 'Thao tác',
              key: 'action',
              width: '110px',
              render: record => {
                return (
                  <ColumnActions
                    {...record}
                    deleteGroup={deleteGroup}
                    onOpenForm={onOpenForm}
                  />
                );
              }
            }
          ]
        : [])
    ],
    [deleteGroup]
  );

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Nhóm nhà cung cấp</h1>

          <div className="wc-page-header__search wclinic-search">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm nhóm nhà cung cấp"
              enterButton
              allowClear
              onSearch={() => onParamChange({ keyword })}
              onChange={e => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_SUPPLIERGROUP}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => onOpenForm()}
              >
                Thêm mới
              </Button>
            </WithPermission>
          </div>
        </div>

        <Space style={{ marginBottom: 20 }}>
          <Typography style={{ fontSize: 18, marginRight: 20 }}>
            Phân loại trạng thái theo :
          </Typography>
          <Row gutter={20}>
            <Radio.Group
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={(() => {
                switch (query?.status) {
                  case 'ACTIVE':
                    return 2;
                  case 'INACTIVE':
                    return 3;
                  default:
                    return 1;
                }
              })()}
            >
              <Radio.Button value={1}>Tất cả</Radio.Button>
              <Radio.Button value={2}>{STATUS_NAMES['ACTIVE']}</Radio.Button>
              <Radio.Button value={3}>{STATUS_NAMES['INACTIVE']}</Radio.Button>
            </Radio.Group>
          </Row>
        </Space>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}>
            <FilterCity
              value={query.cityId}
              // onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={groups.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className='permission-table'
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={groups}
                onChange={({ current, pageSize }) =>
                  onParamChange({ page: current, limit: pageSize })
                }
                pagination={{
                  ...paging,
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <SupplierGroupForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        id={groupId}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default SupplierGroups;
