export const GET_MEDICINE_UNITS_REQUEST = 'GET_MEDICINE_UNITS_REQUEST';
export const GET_MEDICINE_UNITS_SUCCESS = 'GET_MEDICINE_UNITS_SUCCESS';
export const GET_MEDICINE_UNITS_FAILED = 'GET_MEDICINE_UNITS_FAILED';

export const GET_MEDICINE_UNIT_REQUEST = 'GET_MEDICINE_UNIT_REQUEST';
export const GET_MEDICINE_UNIT_SUCCESS = 'GET_MEDICINE_UNIT_SUCCESS';
export const GET_MEDICINE_UNIT_FAILED = 'GET_MEDICINE_UNIT_FAILED';

export const CREATE_MEDICINE_UNIT_REQUEST =
  'CREATE_MEDICINE_UNIT_REQUEST';
export const CREATE_MEDICINE_UNIT_SUCCESS =
  'CREATE_MEDICINE_UNIT_SUCCESS';
export const CREATE_MEDICINE_UNIT_FAILED =
  'CREATE_MEDICINE_UNIT_FAILED';

export const UPDATE_MEDICINE_UNIT_REQUEST =
  'UPDATE_MEDICINE_UNIT_REQUEST';
export const UPDATE_MEDICINE_UNIT_SUCCESS =
  'UPDATE_MEDICINE_UNIT_SUCCESS';
export const UPDATE_MEDICINE_UNIT_FAILED =
  'UPDATE_MEDICINE_UNIT_FAILED';

export const DELETE_MEDICINE_UNIT_REQUEST =
  'DELETE_MEDICINE_UNIT_REQUEST';
export const DELETE_MEDICINE_UNIT_SUCCESS =
  'DELETE_MEDICINE_UNIT_SUCCESS';
export const DELETE_MEDICINE_UNIT_FAILED =
  'DELETE_MEDICINE_UNIT_FAILED';

export const RESET_MEDICINE_UNIT_STATE = 'RESET_MEDICINE_UNIT_STATE';
