import React, { useMemo, useEffect, useState } from 'react';
import {
  useResetEmployeeGroup,
  usePolicySystemBranches,
  useUpdatePermissionSystemBranches,
  useResourcesBranch,
  useResourcePermissionColumns,
  useProfile,
  useMatchPolicy
} from '~/hooks';
import { useParams, } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Table, Checkbox } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import POLICIES from '~/constants/policy';
import toastrNotify from '~/hooks/utils';

const Permission = ({ isActive, onChange }) => {
  return (
    <Checkbox
      checked={isActive}
      onChange={onChange}
      // disabled={disabled}
    ></Checkbox>
  );
};

// const getNextPath = (url) => {
//   const paths = url.split('/');
//   const nextPath = paths
//     .filter((x, index) => !!x && index !== paths.length - 1)
//     .join('/');

//   return `/${nextPath}`;
// };

const PermissionBranches  = () => {
  const { id,branchId, groupId } = useParams();
  const branchIdParam = useMemo(() => ({ branchId:  branchId ?? id }), [branchId, id]);

  const [resources, isResourcesLoading] = useResourcesBranch();
  useResetEmployeeGroup();

  const [group] = usePolicySystemBranches(branchIdParam);
  const [isUpdateLoading, handleUpdate] = useUpdatePermissionSystemBranches();

  const onPermisionChange = (isAssgined, resource, action) => {
    handleUpdate({ isAssgined, resource, action, branchId: branchIdParam.branchId });
  };

  const renderPermission = (action, rc) => {
    return (
      <Permission
        isActive={group?.policies?.[rc.resource.key]?.includes(action?.key)}
        onChange={(event) =>
          onPermisionChange(event.target.checked, rc.resource.key, action.key)
        }
        disabled={isUpdateLoading}
      />
    );
  };

  const columns = useResourcePermissionColumns(renderPermission);

  return (
    <Card>
      <CardBody>
        <div className="employee-group">
          <div className="employee-group__permission">
            {isResourcesLoading ? (
              <SkeletonTable columns={columns} />
            ) : (
                <Table
                className= "permission-table"
                columns={columns}
                dataSource={resources}
                size="small"
                rowKey={(rc) => rc.resource.key}
                pagination={{ hideOnSinglePage: true }}
              ></Table>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PermissionBranches;
