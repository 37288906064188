import React, { useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  useDamages,
  useDeleteDamage,
  useDamageQueryParams,
  useUpdateDamageParams,
  useResetDamage,
  useDamagePaging,
  useDamageColumnSetting,
  useResetMedicine,
  SERACH_DAMAGE
} from '~/hooks';
import { Table, Input, Button, Dropdown, Tabs } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE } from '~/constants/defaultValue';
import DamageInfo from './Detail/Information';
import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined
} from '@ant-design/icons';
import { exportCSV, formatCurrency, formatDateTime } from '~/utils/helper';
import './index.scss';
import { INVOICE_STATUS } from '~/constants/defaultValue';
import { PATH_CLINIC } from '~/routes/paths';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import { onSelectRow } from '~/utils/validators';

const { TabPane } = Tabs;

const Damage = () => {
  const history = useHistory();
  const [query] = useDamageQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateDamageParams(query);

  const [damages, isLoading] = useDamages(query); 
   const [selectTable, setSelectTable]=useState([])

  const paging = useDamagePaging();

  const onDeleteSuccess = () => {
    if (query.id) {
      history.push({
        pathname: PATH_CLINIC.damage.root
      });
    } else {
      const isDeletingLastItemInPage = damages.length === 2;
      const canDecreasePage = query.page > 0;

      if (isDeletingLastItemInPage && canDecreasePage) {
        onParamChange({ page: query.page - 1 });
      }
    }
  };

  const [, deleteDamage] = useDeleteDamage(onDeleteSuccess);

  useResetDamage();
  useResetMedicine();

  const [columnMenu, selectedColumnKeys] = useDamageColumnSetting();

  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Mã xuất huỷ',
        key: 'code',
        dataIndex: 'code'
      },

      {
        title: 'Tổng giá trị huỷ',
        key: 'totalCost',
        dataIndex: 'totalCost',
        align: 'end',
        render: formatCurrency
      },
      {
        title: 'Thời gian',
        key: 'time',
        dataIndex: 'processedAt',
        render: processedAt => formatDateTime(processedAt)
      },
      {
        title: 'Chi nhánh',
        key: 'branch',
        dataIndex: ['branch', 'name', 'vi']
      },

      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: status => INVOICE_STATUS[status]
      }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const exportCsvData = () => {
    const columnNames = columns.filter(({ key }) => key !== 'actions');
    exportCSV({
      columns: columnNames,
      data: damages,
      fileName: 'Phiếu xuất huỷ'
    });
  };

  const expandedRowRender = record => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <DamageInfo record={record} deleteDamage={deleteDamage} />
          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Phiếu xuất huỷ</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword}data={SERACH_DAMAGE} />
          </div>

          <div className="wc-page-header__actions">
            <WithPermission permission={POLICY.WRITE_DAMAGEITEM}>
              <Link to={PATH_CLINIC.damage.create}>
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: 10 }}
                >
                  <PlusOutlined />
                  Thêm mới
                </Button>
              </Link>

              <ButtonExportOption 
                router={API_LIST.damage} 
                label="Xuất hủy" 
                query={query} 
                fileName={["Danhsachxuathuy_",'Danhsachchitietxuathuy_']} />
              {/* <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={exportCsvData}
              >
                <DownloadOutlined /> CSV
              </Button> */}

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={damages.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                size="middle"
                rowClassName={record =>
                  record.isSummaryRow ? 'summary-row' : ''
                }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={damages}
                onChange={({ current,pageSize }) => onParamChange({ page: current , limit:pageSize })}
                pagination={
                  !query.id && {
                    ...paging,
                    // pageSize:paging.pageSize,
                    showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50' ,'100','1000','10000','100000'],
                    // total: Math.ceil(paging.total + paging.total / paging.pageSize),
                    showTotal: () => `Tổng cộng: ${paging.total} `
                  }
                }            
                onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}
                
                onRow={onSelectRow(setSelectTable)}

                expandable={{
                  expandedRowRender,
                  rowExpandable: record => !record.isSummaryRow,
                  defaultExpandAllRows: !!query.id,
                  expandedRowKeys:[selectTable]
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Damage;
