import React from 'react';
import { Row } from 'antd';
import { formatNumber } from '~/utils/helper';
import DiscountInput from '~/components/Common/DiscountInput';
import { DISCOUNT_TYPES } from '~/constants/defaultValue';
import WithRules from '~/components/Common/WithRules';
const { PERCENT, VALUE } = DISCOUNT_TYPES;

function SummarySale({
  totalAmount,
  totalItems,
  selectedSale,
  onDiscountTypeChange,
  onDiscountValueChange,
  discountValue,
  summaryBill,
  rules
}) {
  const isPercent = selectedSale?.summary?.discount?.type === PERCENT;
  return (
    <>
      <Row
        className="warehouse-form__row"
        style={{
          marginTop: 20,
          fontSize: '14px',
          fontWeight: '500',
          background: '#eff2f5',
          padding: '5px 10px'
        }}
      >
        Mua hàng
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Tổng tiền mua:
          <span className="sale__total-product">{totalItems}</span>
        </label>
        <label>{formatNumber(totalAmount)}</label>
      </Row>
      <Row style={{ marginTop: 20 }} justify="space-between" align="middle">
        <label>Giảm giá: </label>
        <DiscountInput
          discount={selectedSale?.summary?.discount}
          onTypeChange={onDiscountTypeChange}
          onValueChange={onDiscountValueChange}
          max={isPercent ? 100 : totalAmount}
        >
          <div className="warehouse-form-table__discount">{formatNumber(discountValue)}</div>
        </DiscountInput>
      </Row>
      <Row className="warehouse-form__row">
        <label className="fw-600">Giá trị sau giảm giá: </label>
        <label style={{ color: 'rgb(45, 183, 245)' }}>{formatNumber(summaryBill.totalSale)}</label>
      </Row>
      <WithRules rules={[rules.isReturn, rules.isUpdatingInvoice]}>
        <Row className="warehouse-form__row">
          <label className="fw-600">Khách đã trả: </label>
          <label style={{ color: 'rgb(45, 183, 245)' }}>{formatNumber(summaryBill.paid)}</label>
        </Row>
      </WithRules>
    </>
  );
}

export default SummarySale;
