import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Skeleton,
  InputNumber,
  Select
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { validatePrice } from '~/utils/validators';
import MedicineUnitForm from '../../MedicineUnit/Form';
import { filterAcrossAccents } from '~/hooks/utils';
import BaseBorderBox from '~/components/Common/BaseBorderBox';
import { RenderLoading } from '~/components/Common/RenderLoading';

const FormItem = Form.Item;
const { Option } = Select;
const verticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const Units = ({ isLoading, form, unitOptions, isUnitOptionLoading }) => {
  const [isMedicineUnitFormOpen, setMedicineUnitFormOpen] = useState(false);
  return (
    <div className="medicine-form-units-section">
      <h5 className="form-section__title">Đơn vị</h5>

      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <Row justify="space-between">
            {' '}
            <FormItem
              label="Đơn vị cơ bản"
              name={['defaultVariant', 'unitId']}
              rules={[
                { required: true, message: 'Xin mời nhập đơn vị cơ bản' }
              ]}
            >
              <Select
                loading={isUnitOptionLoading}
                showSearch
                optionFilterProp="children"
                style={{ width: 317, marginLeft: 12, padding: 0 }}
              >
                {unitOptions.map(({ name, _id }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <Button onClick={() => setMedicineUnitFormOpen(true)}>
              <PlusOutlined />
            </Button>
          </Row>
        </Col>
        <Col span={12}>
          <FormItem
            label="Bán trực tiếp"
            name="isLiveSell"
            valuePropName="checked"
          >
            <Checkbox />
          </FormItem>
        </Col>
      </Row>

      <Form.List name="productVariants">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <BaseBorderBox>
              <Row
                gutter={6}
                className="medicine-form-units-section__row"
                key={index}
                align="top"
                >
                <Form.Item
                  {...restField}
                  {...verticalLayout}
                  label={index === 0 && 'Tên đơn vị'}
                  name={[name, 'unitId']}
                  fieldKey={[fieldKey, 'unitId']}
                  rules={[
                    { required: true, message: 'Nhập tên đơn vị' },
                    () => ({
                      validator(_, value) {
                        if (
                          !value ||
                          unitOptions.find(({ _id }) => _id === value)?.name
                          ?.length < 20
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Tối đa 20 ký tự'));
                      }
                    })
                  ]}
                  >
                  <Select 
                  filterOption={filterAcrossAccents}
                  showSearch loading={isUnitOptionLoading}>
                    {unitOptions.map(unit => (
                      <Option key={unit._id} value={unit._id}>
                        {unit.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  {...verticalLayout}
                  label={index === 0 && 'Giá trị quy đổi'}
                  name={[name, 'exchangeValue']}
                  fieldKey={[fieldKey, 'exchangeValue']}
                  rules={[{ required: true, message: 'Nhập GT quy đổi' }]}
                  >
                  <InputNumber
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    style={{ width: '100%' }}
                    />
                </Form.Item>

                <FormItem
                  label={index === 0 && '	Giá vốn'}
                  fieldKey={[fieldKey, 'cost']}
                  name={[name, 'cost']}
                  {...restField}
                  {...verticalLayout}
                  // rules={[
                    //   { required: true, message: 'Nhập giá vốn' },
                    //   validatePrice
                    // ]}
                    >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <InputNumber
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    style={{ width: '100%' }}
                    />
                  )}
                </FormItem>
                <Form.Item
                  {...restField}
                  {...verticalLayout}
                  label={index === 0 && '	Giá bán'}
                  name={[name, 'price']}
                  fieldKey={[fieldKey, 'price']}
                  rules={[
                    { required: true, message: 'Nhập giá bán' },
                    validatePrice
                  ]}
                  >
                  <InputNumber
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                  {...restField}
                  {...verticalLayout}
                  label={index === 0 && 'Mã hàng'}
                  name={[name, 'variantCode']}
                  fieldKey={[fieldKey, 'variantCode']}
                  >
                  <Input disabled placeholder="Mã hàng tự động" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  {...verticalLayout}
                  label={index === 0 && '	Mã vạch'}
                  name={[name, 'barcode']}
                  fieldKey={[fieldKey, 'barcode']}
                  >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  {...verticalLayout}
                  label={index === 0 && 'Bán trực tiếp'}
                  name={[name, 'isLiveSell']}
                  fieldKey={[fieldKey, 'isLiveSell']}
                  valuePropName="checked"
                  className="medicine-form-units-section__form-item--check-box"
                  >
                  <Checkbox
                    style={{
                      alignSelf: 'center',
                      justifySelf: 'center'
                    }}
                    disabled
                    />
                </Form.Item>

                <FormItem
                  style={{
                    alignSelf: 'center',
                    marginBottom: index === 0 ? -20 : 25,
                    marginLeft: 10
                  }}
                  >
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </FormItem>
                </Row>
                <Row
                    gutter={6}
                    className="medicine-form-units-section__row"
                    key={index}
                    align="top">
                        <Form.Item
                          {...restField}
                          {...verticalLayout}
                          label={"Khối lượng"}
                          name={[name, "weight"]}
                          rules={[
                            {
                              required: false,
                              message: "Xin vui lòng nhập!",
                            },
                          ]}
                        >
                          {RenderLoading(isLoading,
                            <InputNumber
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            style={{ width: '100%', marginRight: 10 }} 
                            addonAfter="gram" min={0} />)}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          {...verticalLayout}
                          label={"Dài"}
                          name={[name, "long"]}
                          rules={[
                            {
                              required: false,
                              message: "Xin vui lòng nhập!",
                            },
                          ]}
                        >
                          {RenderLoading(isLoading,
                            <InputNumber min={0}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                              style={{ width: '100%' }}
                              addonAfter="cm" />)}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          {...verticalLayout}
                          label={"Rộng"}
                          name={[name, "wide"]}
                          rules={[
                            {
                              required: false,
                              message: "Xin vui lòng nhập!",
                            },
                          ]}
                        >
                          {RenderLoading(isLoading, <InputNumber
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            style={{ width: '100%' }}
                            min={0} addonAfter="cm" />)}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          {...verticalLayout}
                            label={"Cao"}
                          name={[name, "height"]}
                          rules={[
                            {
                              required: false,
                              message: "Xin vui lòng nhập!",
                            },
                          ]}
                          style={{
                            marginLeft: 15,
                            width: '11%',
                            maxWidth: '11%',
                          }}
                        >
                        {RenderLoading(isLoading,
                          <InputNumber
                          formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                            style={{ width: '100%'}}
                            min={0} addonAfter="cm" />)}
                        </Form.Item>
                </Row>
            </BaseBorderBox>
            ))}

            <Button
              type="dashed"
              onClick={() => {
                add({
                  price: form.getFieldValue('defaultVariant')?.price || 0,
                  exchangeValue: 1,
                  isLiveSell: true
                });
              }}
              block
              icon={<PlusOutlined />}
              style={{ width: 150 }}
              >
              Thêm đơn vị
            </Button>
          </>
        )}
        </Form.List>
        <MedicineUnitForm
        isOpen={isMedicineUnitFormOpen}
        onClose={() => setMedicineUnitFormOpen(false)}
        callback={({ _id }) => form.setFieldsValue({ unitId: _id })}
        />
        </div>
      );
};

export default Units;
