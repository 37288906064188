import requester from '../requester';
const fakedata = [{
  image: '',
  description:['a', 'b', 'c', 'd', 'e', 'f']
}]
const requester_= {
    get :()=>new Promise((resolve, reject) => { return  resolve(fakedata) }),
    post :()=>{},
    put :(value)=>{},
}

const informationPayment = {
  getAll: query => requester.get('/infoPayment', query),
  create: informationPayment => requester.post('/infoPayment', informationPayment),
  update: informationPayment => requester.put(`/infoPayment/${informationPayment._id}`, informationPayment),
  delete: id => requester.delete(`/infoPayment/${id}`),
};

export default informationPayment;
