import requester from '../requester';

const warehouse = {
  getAll: query => requester.get('/purchase-order', query),
  getById: id => requester.get(`/purchase-order/${id}`),
  create: warehouse => requester.post('/purchase-order', warehouse),
  update: warehouse =>
    requester.put(`/purchase-order/${warehouse._id}`, warehouse),
  delete: id => requester.delete(`/purchase-order/${id}`)
};

export default warehouse;
