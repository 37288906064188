import React, { useState, useMemo, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Skeleton,
  Modal,
  Radio
} from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import {
  useCreateCustomer,
  // useCustomer,
  // useInitCustomer,
  useResetCustomer,
  useUpdateCustomer,
  useSelectBranch,
  useCustomer,
  useInitCustomer
} from '~/hooks';
import './index.scss';
import CustomerAddress from '~/components/Common/CustomerAddress';
import { isNil } from 'lodash';

const { Option } = Select;

const FormItem = Form.Item;

const INDIVIDUAL = 'INDIVIDUAL';
const COMPANY = 'COMPANY';

const CustomerForm = ({ isOpen, onClose, id, callback, branch }) => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState();

  const createCallback = customer => {
    onClose();
    form.resetFields();

    if (isOpen) {
      callback?.(customer);
    }
  };

  const [isSubmitLoading, handleCreate] = useCreateCustomer(createCallback);
  const [, handleUpdate] = useUpdateCustomer(onClose);

  const [customerDetail, isGetCustomerLoading] = useCustomer(id);
  const initCustomer_ = useInitCustomer(customerDetail, id);

  const [, selectedBranch] = useSelectBranch();

  // const initCustomer = useMemo(() => {
  //   const { cityId, districtId, wardId } = initCustomer_.address;

  //   return {
  //     address: {
  //       cityId,
  //       districtId,
  //       wardId
  //     }
  //   };

  //   //eslint-disable-next-line
  // }, [isOpen, selectedBranch.address,initCustomer_]);

  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  const [customerType, setCustomerType] = useState(INDIVIDUAL);

  useEffect(() => {
    form.resetFields();
    if(!isNil(initCustomer_)){
      const { avatar, address } = initCustomer_;
      if (avatar) {
        setLogo(avatar);
      }
      if (address) {
        setCityCode(address.cityId);
        setDistrictCode(address.districtId);
      }
      form.setFieldsValue(initCustomer_)

    }
  }, [initCustomer_, form]);

  useResetCustomer();

  const onFinish = values => {
    const customer = {
      ...values,
      avatar: logo
    };

    if (id) {
      handleUpdate({
        ...customer,
        _id: id
      });
    } else {
      handleCreate(customer);
    }
  };

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }

    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }
  };

  const isLoading = false;

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="customer-form">
        <h4 style={{ marginRight: 'auto', paddingLeft: 27 }}>
          {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} khách hàng
        </h4>

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initCustomer_}
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="customer-form__logo-row"
          >
            <Col span={12}>
              <Row gutter={36}>
                <Col span={24}>
                  <FormItem label="Mã khách hàng" name="code">
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Input placeholder="Mã mặc định" disabled />
                    )}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label="Tên khách hàng"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Xin mời nhập tên khách hàng!'
                      }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Giới tính" name="gender">
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select>
                        <Option value="M" key="male">
                          Nam
                        </Option>
                        <Option value="F" key="female">
                          Nữ
                        </Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="customer-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <CustomerAddress
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                name="type"
                initialValue={customerType}
                label="Loại khách"
              >
                <Radio.Group
                  value={customerType}
                  onChange={e => {
                    setCustomerType(e.target.value);
                  }}
                >
                  <Radio value={INDIVIDUAL}>Cá nhân</Radio>
                  <Radio value={COMPANY}>Công ty</Radio>
                </Radio.Group>
              </FormItem>
            </Col>

            {customerType === COMPANY && (
              <Col span={12}>
                <FormItem name="companyName" label="Tên công ty">
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
              </Col>
            )}
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <Form.Item name="taxIdentificationNumber" label="Mã số thuế">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="facebook" label="Facebook">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <Form.Item name="group" label="Nhóm">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="note" label="Ghi chú">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </Form.Item>
            </Col>
          </Row>

          <Row className="customer-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default CustomerForm;
