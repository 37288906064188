import { useMemo, useEffect } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getAppointments,
  deleteAppointment,
  getAppointment,
  createAppointment,
  updateAppointment,
  resetAppointmentState
} from '~/redux/action';
import moment from 'moment';

const getSelector = key => state => state.appointment[key];

const loadingSelector = getSelector('isLoading');
const appointmentsSelector = getSelector('appointments');
const getAppointmentsFailedSelector = getSelector('getAppointmentsFailed');

const isGetAppointmentLoadingSelector = getSelector('isGetAppointmentLoading');
const appointmentSelector = getSelector('appointment');
const getAppointmentFailedSelector = getSelector('getAppointmentFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useAppointmentQueryParams = () => {
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  const hospitalId = query.get('hospitalId');
  const status = query.get('status');
  const source = query.get('source');
  const specialityId = query.get('specialityId');

  const deleteAppointmentSuccess = useSelector(deleteSuccessSelector);
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);

  return useMemo(() => {
    return {
      page,
      limit,
      hospitalId: hospitalId || undefined,
      status: status || undefined,
      source: source || undefined,
      specialityId: specialityId || undefined
    };
    //eslint-disable-next-line
  }, [
    page,
    limit,
    hospitalId,
    status,
    source,
    specialityId,
    deleteAppointmentSuccess,
    createSuccess,
    updateSuccess
  ]);
};

export const useAppointments = query => {
  return useFetchByParam({
    action: getAppointments,
    loadingSelector,
    dataSelector: appointmentsSelector,
    failedSelector: getAppointmentsFailedSelector,
    param: query
  });
};

const EVENT_COLORS = {
  CREATED: '#9CA3AF',
  CANCEL: '#EF4444',
  CONFIRMED: '#3B82F6',
  ARRIVED: '#10B981'
};

export const useAppointmentEvents = query => {
  const [appointments, isLoading] = useAppointments(query);

  const appointmentEvents = useMemo(
    () =>
      appointments.map(({ _id, customer, time, hospital, status }) => ({
        id: _id,
        title: customer?.name,
        start: moment(time).format('YYYY-MM-DDTHH:mm'),
        extendedProps: { hospital },
        color: EVENT_COLORS[status]
      })),
    [appointments]
  );

  return [appointmentEvents, appointments, isLoading];
};

export const useAppointment = id => {
  return useFetchByParam({
    action: getAppointment,
    loadingSelector: isGetAppointmentLoadingSelector,
    dataSelector: appointmentSelector,
    failedSelector: getAppointmentFailedSelector,
    param: id
  });
};

export const useCreateAppointment = callBack => {
  useSuccess(createSuccessSelector, 'Tạo mới lịch hẹn thành công', callBack);
  useFailed(createFailedSelector, 'Tạo mới lịch hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createAppointment
  });
};

export const useUpdateAppointment = callBack => {
  useSuccess(updateSuccessSelector, 'Cập nhật lịch hẹn thành công', callBack);
  useFailed(updateFailedSelector, 'Cập nhật lịch hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateAppointment
  });
};

export const useDeleteAppointment = () => {
  useSuccess(deleteSuccessSelector, 'Xoá lịch hẹn thành công');
  useFailed(deleteFailedSelector, 'Xoá lịch hẹn thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteAppointment
  });
};

export const useInitAppointment = (
  form,
  appointment,
  appointmentId,
  initStartTime,
  setSelectedHospitalId,
  setSelectedSpecialityId
) => {
  const { hospital, speciality } = appointment || {};

  const initAppointment = useMemo(() => {
    const {
      customer,
      source,
      status,
      time: dateTime,
      hospital,
      speciality,
      message
    } = appointment || {};

    const localDateTime = moment(dateTime).toISOString(true);
    const [date, time] = (localDateTime || '').split('T');

    return {
      customer,
      source,
      status,
      date: moment(date, 'YYYY-MM-DD'),
      time: moment(time, 'HH:mm:zzsS').format('HH:mm'),
      hospitalId: hospital?.id,
      specialityId: speciality?.id,
      message
    };
  }, [appointment]);

  useEffect(() => {
    form.resetFields();
    setSelectedHospitalId(hospital?.id);
    setSelectedSpecialityId({ specialityId: speciality?.id });
  }, [
    initAppointment,
    form,
    appointmentId,
    initStartTime,
    setSelectedHospitalId,
    hospital,
    speciality,
    setSelectedSpecialityId
  ]);

  return appointmentId ? initAppointment : { date: null };
};

export const useResetAppointment = () => {
  useResetState(resetAppointmentState);
};
