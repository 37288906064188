import React from 'react'
import './index.scss'

function ItemInformation({title,value,style}) {
    return (
        <div className='itemInformation-container'>
            <div className='field'>{title}</div>
            <div className='field-data' style={style}>
                  {value}
            </div>
        </div>
    )
}

export default ItemInformation
