import React, { useMemo, useState } from 'react';
import { Button, Form, Dropdown, Input, Select, Table, Popover } from 'antd';
import WithPermission from '../../Common/WithPermission';
import POLICY from '~/constants/policy';
import { DownloadOutlined, DownOutlined, UnorderedListOutlined, CaretDownOutlined,SearchOutlined } from '@ant-design/icons';

import {
  useDirectory, useDirectoryColumnSetting,
  useDirectoryPaging,
  useDirectoryQueryParams,
  useUpdateDirectoryParams
} from '~/hooks/worldClinic/directory';
import { API_LIST, CARD_STYLE } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { exportCSV } from '~/utils/helper';

import './index.scss';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
const { Search } = Input;

const MedicineDirectory = () => {
  const [columnMenu, selectedColumnKeys] = useDirectoryColumnSetting();
  const [query] = useDirectoryQueryParams();
  const [code, {setCode, onParamChange}] = useUpdateDirectoryParams(query);
  const [directory, isLoading] = useDirectory(query);
  const [isShowSettingColumn, setShowSettingColumn] = useState(false);
  const paging = useDirectoryPaging();
  const [form] = Form.useForm();
  const [openPopover, setOpenPopover] = useState(false);

  const hide = () => {
    setOpenPopover(false);
  };

  const columns = useMemo(() => {
    const allColumns = [
      {
        title:'Mã thuốc',
        key:'code',
        dataIndex:'code',
        width:120,
        className: 'mwpx-120'
      },
      {
        title:'Số đăng ký',
        key:'registrationNo',
        dataIndex:'registrationNo',
        width:120,
        className: 'mwpx-120'
      },
      {
        title:'Tên thuốc',
        key:'name',
        dataIndex:'name',
        width:160,
        className: 'mwpx-160'
      },
      {
        title:'Hoạt chất chính',
        key:'element',
        dataIndex:'element',
        width:210,
        className: 'mwpx-210'
      },
      {
        title:'Hàm lượng',
        key:'content',
        dataIndex:'content',
        width:210,
        className: 'mwpx-210'
      },
      {
        title:'Quy cách đóng gói',
        key:'package',
        dataIndex:'package',
        width:210,
        className: 'mwpx-210'
      },
      {
        title:'ĐVT',
        key:'unit',
        dataIndex: 'unit',
        width:130,
        className: 'mwpx-130'
      },
      {
        title:'Hãng sản xuất',
        key:'manufacturer',
        dataIndex: 'manufacturer',
        width:130,
        className: 'mwpx-130'
      }
      ,
      {
        title:'Nước sản xuất',
        key:'country',
        dataIndex: 'country',
        width:130,
        className: 'mwpx-130'
      }
    ]
    return allColumns.filter(({ key }) => !!selectedColumnKeys[key]);
  },[selectedColumnKeys]);

  const exportCsvData = () => {
    const columnNames = columns.filter(({ key }) => key !== 'actions');
    exportCSV({
      columns: columnNames,
      data: directory,
      fileName: 'Danh mục thuốc của Cục quản lý dược'
    });
  };

  const onFinishSearch = (value) => {
    onParamChange({ ...value })
    setOpenPopover(false);
  }

  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
    hide();
  };

  const content = (
    <Form
      onFinish={onFinishSearch}
      form={form}
      autoComplete="off"
      scrollToFirstError
      requiredMark={false}
      validateTrigger="onChange"
    >
      <Form.Item className={'form-item__multi'} name="name">
        <Input placeholder="Theo tên thuốc"/>
      </Form.Item>
      <Form.Item className={'form-item__multi'} name="registrationNo">
        <Input placeholder="Theo số đăng ký"/>
      </Form.Item>
      <Form.Item className={'form-item__multi'} name="code">
        <Input placeholder="Theo mã thuốc"/>
      </Form.Item>
      <Form.Item className={'form-item__multi'} name="element">
        <Input placeholder="Theo hoạt chất, hàm lượng"/>
      </Form.Item>
      <Form.Item className={'form-item__multi'} name="manufacturer">
        <Input placeholder="Theo hãng, nước sản xuất"/>
      </Form.Item>
      <Button type="primary" htmlType="submit" onClick={hide} icon={<SearchOutlined />}>Tìm kiếm</Button>
    </Form>
  );

  const SelectAfter = (
    <Popover
      style={{
        width: 300,
      }}
      className="popover-search-box"
      placement="bottomRight"
      content={content}
      trigger="click"
      open={openPopover}
      onOpenChange={handleOpenChange}
    >
      <Button style={{marginLeft: '-32px'}}>
        <CaretDownOutlined/>
      </Button>
    </Popover>
  )

  return (
    <div className="page-wraper page-content wc-page-content">
      <div className="container-fluid">
        <div className='wc-page-content__box'>
          <div className='wc-page-content__left'>
            <h1 className='wc-page-content__heading'>Danh mục thuốc của Cục Quản lý dược</h1>
            <div className={'quantity-record'}>
              <h6 className='quantity-record__title'>Số bản ghi</h6>
              <Select
                defaultValue="10"
                className={'quantity-record__select'}
                size={'small'}
                onSelect={(value, option) => {
                  return onParamChange({limit: parseInt(value), page: 1})
                }}
              >
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="20">20</Select.Option>
                <Select.Option value="30">30</Select.Option>
                <Select.Option value="50">50</Select.Option>
                <Select.Option value="100">100</Select.Option>
              </Select>
            </div>
          </div>
          <div className="wc-page-content__right">
            <div className='header-right'>
              <div className="wc-page-header__search">
                <Search
                  addonAfter={SelectAfter}
                  style={{ width: '100%'}}
                  placeholder="Theo mã thuốc"
                  onSearch={() => onParamChange({ code, isOnlyCode: true })}
                  onChange={e => setCode(e.target.value)}
                  value={code}
                />
              </div>
              <div className="wc-page-header__actions">
                <WithPermission permission={POLICY.READ_MEDICINE}>

                  {/* <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={exportCsvData}
                  >
                    <DownloadOutlined /> Xuất file
                  </Button> */}
                  <ButtonExport 
                      query={query} 
                      router={API_LIST.medicine} 
                      fileName={'Danhmucthuoccuacucquanlyduocquocgia'} 
                      label={'Danh mục thuốc của Cục Quản lý Dược Quốc gia'} 
                      exportOption={4}/>

                  <Dropdown
                    onVisibleChange={flag => setShowSettingColumn(flag)}
                    visible={isShowSettingColumn}
                    overlay={columnMenu}
                    trigger={['click']}
                    style={{ float: 'right' }}
                  >
                    <Button type="primary" onClick={e => e.stopPropagation()}>
                      <UnorderedListOutlined /> <DownOutlined />
                    </Button>
                  </Dropdown>
                </WithPermission>
              </div>
            </div>
            <div className='table-right' style={CARD_STYLE}>
              {
                isLoading ? (
                  <SkeletonTable
                    rowCount={directory.length}
                    columns={columns}
                    pagination={paging}
                  />
                ) : (
                  <Table
                    className="table--selectable permission-table "
                    size="middle"
                    rowClassName={record =>
                      record.isSummaryRow ? 'summary-row' : ''
                    }
                    rowKey={rc => rc._id}
                    columns={columns}
                    dataSource={directory}
                    onChange = {({current}) => onParamChange({page:current})}
                    pagination={
                      {
                        ...paging,
                        showTotal: total => `Tổng cộng: ${total} `
                      }
                    }
                    scroll={{ x: 500 }}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineDirectory;
