import ReceiptPaymentType from '.';

function Payment() {
  return (
    <>
      <ReceiptPaymentType type="PAYMENT" name={'chi'} permission="PAYMENT" />
    </>
  );
}

export default Payment;
