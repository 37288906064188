import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Row, Col, Button, Skeleton, Modal } from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import AddressFormSection from '~/components/Common/AddressFormSection';
import ModulesFormItem from '~/components/Common/ModulesFormItem';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreatePartner,
  usePartner,
  useInitPartner,
  useUpdatePartner,
  useResetPartner
} from '~/hooks';
import './index.scss';
import GetApiKeyForm from './GetApiKeyForm';

const FormItem = Form.Item;

const PartnerForm = ({ isOpen, onClose, id, handleUpdate }) => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState();
  useResetPartner();
  const [isSubmitLoading, handleCreate] = useCreatePartner(onClose);
  const [partner, isLoading] = usePartner(id);
  const initPartner = useInitPartner(partner, id);
  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  useEffect(() => {
    form.resetFields();
    const { logo, address } = initPartner;

    if (logo) {
      setLogo(logo);
    }

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initPartner, form]);

  const onFinish = useCallback(
    values => {
      const partner = {
        ...values,

        logo
      };

      if (id) {
        handleUpdate({ ...partner, _id: id });
      } else {
        handleCreate(partner);
      }
      onClose();
    },
    [handleCreate, handleUpdate, id, logo]
  );

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="partner-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values) => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initPartner}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} đối tác
            </h4>
          </Row>
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="partner-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên đối tác"
                name="name"
                rules={[
                  { required: true, message: 'Xin mời nhập tên đối tác' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem
                label="Mã đối tác"
                name="partnerCode"
                rules={[{ required: true, message: 'Xin mời nhập mã đối tác' }]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <ModulesFormItem isLoading={isLoading} />
            </Col>
            <Col span={12} className="partner-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} />
            </Col>
          </Row>

          <FormItem
            labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
            wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
            label="Mô tả ngắn"
            name="description"
            className="partner-form__description form-item-horizontal--max-width"
          >
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>
          <FormItem
            labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
            wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
            label="Mã liên kết"
            name="apikey"
            className="partner-form__description form-item-horizontal--max-width"
          >
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>

          <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />
          {/* <GetApiKeyForm branchIdQuery={partner?._id} /> */}

          <Row className="partner-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default PartnerForm;
