import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicineGroups({ payload: query }) {
  try {
    const data = yield call(Api.medicineGroup.getAll, query);

    yield put({ type: Types.GET_MEDICINE_GROUPS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_GROUPS_FAILED,
      payload: error
    });
  }
}

function* getMedicineGroupOptions() {
  try {
    const data = yield call(Api.medicineGroup.getAll);

    yield put({
      type: Types.GET_MEDICINE_GROUP_OPTIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_GROUP_OPTIONS_FAILED,
      payload: error
    });
  }
}

function* getMedicineGroup({ payload }) {
  try {
    const medicineGroup = yield call(Api.medicineGroup.getById, payload);
    yield put({
      type: Types.GET_MEDICINE_GROUP_SUCCESS,
      payload: medicineGroup
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_GROUP_FAILED,
      payload: error
    });
  }
}

function* createMedicineGroup(action) {
  try {
    const data = yield call(Api.medicineGroup.create, action.payload);
    yield put({ type: Types.CREATE_MEDICINE_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICINE_GROUP_FAILED,
      payload: error
    });
  }
}

function* updateMedicineGroup(action) {
  try {
    const data = yield call(Api.medicineGroup.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICINE_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICINE_GROUP_FAILED,
      payload: error
    });
  }
}

function* deleteMedicineGroup({ payload }) {
  try {
    yield call(Api.medicineGroup.delete, payload);
    yield put({ type: Types.DELETE_MEDICINE_GROUP_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICINE_GROUP_FAILED,
      payload: error
    });
  }
}

export default function* medicineGroup() {
  yield takeLatest(Types.GET_MEDICINE_GROUPS_REQUEST, getMedicineGroups);
  yield takeLatest(Types.GET_MEDICINE_GROUP_REQUEST, getMedicineGroup);
  yield takeLatest(Types.DELETE_MEDICINE_GROUP_REQUEST, deleteMedicineGroup);
  yield takeLatest(Types.CREATE_MEDICINE_GROUP_REQUEST, createMedicineGroup);
  yield takeLatest(Types.UPDATE_MEDICINE_GROUP_REQUEST, updateMedicineGroup);
  yield takeLatest(Types.GET_MEDICINE_GROUP_OPTIONS_REQUEST, getMedicineGroupOptions);
}
