import { useState, useEffect } from 'react';
import { Modal, Form, Select, Input } from 'antd';
import { useShippingVendor } from '~/hooks';

const { TextArea } = Input;

const OrderShipping = ({ visible, onSubmit, onClose }) => {
  const [shippingVendors] = useShippingVendor(visible);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setLoading(false);
      form.resetFields();
    }

    //SHOULD RECHECK
    //eslint-disable-next-line
  }, [visible]);

  const submitHandle = async values => {
    const submitData = { ...values };
    const vendor = shippingVendors?.find(
      item => item._id === values.shippingVendorId
    );
    submitData.shippingVendor = vendor?.name;
    setLoading(true);
    await onSubmit(submitData);
    onClose();
  };

  return (
    <Modal
      title="Giao hàng"
      onOk={() => form.submit()}
      okText="Lưu"
      cancelText="Hủy bỏ"
      visible={visible}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form
        form={form}
        name="assign-form"
        layout="vertical"
        onFinish={submitHandle}
      >
        <Form.Item
          label="Đơn vị vận chuyển"
          name="shippingVendorId"
          rules={[
            { required: true, message: 'Vui lòng chọn đơn vị vận chuyển' }
          ]}
        >
          <Select>
            {shippingVendors?.map(vendor => (
              <Select.Option key={vendor._id} value={vendor._id}>
                {vendor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tên nhân viên giao hàng"
          name="shipperName"
          rules={[{ required: true, message: 'Vui lòng nhập tên nhân viên' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Số điện thoại nhân viên giao hàng"
          name="shipperPhoneNumber"
          rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ID theo dõi"
          name="trackingId"
          rules={[{ required: true, message: 'Vui lòng nhập ID theo dõi' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Ghi chú" name="shippingNote">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderShipping;
