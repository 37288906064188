import {
  GET_ALL_ORDERS_REQUEST,
  GET_PENDING_ORDERS_REQUEST,
  GET_ORDER_REQUEST,
  GET_SHIPPING_VENDOR_REQUEST,
  DELETE_ORDER_REQUEST,
  ADD_MEDICINE_TO_ORDER_REQUEST,
  UPDATE_ORDER_MEDICINE_REQUEST,
  DELETE_ORDER_MEDICINE_REQUEST,
  RESET_ORDER_STATE
} from '~/constants/actionTypes';

export const getAllOrders = query => ({
  type: GET_ALL_ORDERS_REQUEST,
  payload: query
});

export const getPendingOrders = query => ({
  type: GET_PENDING_ORDERS_REQUEST,
  payload: query
});

export const getOrder = id => ({
  type: GET_ORDER_REQUEST,
  payload: id
});

export const getShippingVendor = () => ({
  type: GET_SHIPPING_VENDOR_REQUEST
});

export const deleteOrder = id => ({
  type: DELETE_ORDER_REQUEST,
  payload: id
});

export const addMedicineToOrder = medicine => ({
  type: ADD_MEDICINE_TO_ORDER_REQUEST,
  payload: medicine
});

export const updateOrderMedicine = medicine => ({
  type: UPDATE_ORDER_MEDICINE_REQUEST,
  payload: medicine
});

export const deleteOrderMedicine = id => ({
  type: DELETE_ORDER_MEDICINE_REQUEST,
  payload: id
});

export const resetOrderState = () => ({
  type: RESET_ORDER_STATE
});
