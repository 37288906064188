import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getMedicineLocations,
  createMedicineLocation,
  deleteMedicineLocation,
  getMedicineLocation,
  resetMedicineLocationState,
  updateMedicineLocation
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS } from '~/constants/defaultValue';
const MEDICINE_LOCATION_MODULE = 'medicineLocation';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(MEDICINE_LOCATION_MODULE);

export const useMedicineLocations = param => {
  return useFetchByParam({
    action: getMedicineLocations,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param
  });
};

export const useCreateMedicineLocation = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới vị trí thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới vị trí thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicineLocation
  });
};

export const useUpdateMedicineLocation = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật vị trí thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật vị trí thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicineLocation
  });
};

export const useDeleteMedicineLocation = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, 'Xoá vị trí thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá vị trí thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteMedicineLocation
  });
};

export const useMedicineLocation = params => {
  return useFetchByParam({
    action: getMedicineLocation,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitMedicineLocation = (medicineLocation, id) => {
  return useMemo(() => {
    if (!medicineLocation || !id) {
      return { status: true };
    }

    return {
      ...medicineLocation,
      status: medicineLocation.status === STATUS.ACTIVE ? true : false
    };
  }, [medicineLocation, id]);
};

export const useUpdateMedicineLocationParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    history.push({
      pathname: '/medicine-location',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useMedicineLocationQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const name = query.get('name');
  const page = query.get('page');
  const status = query.get('status');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== name) {
    prevKeyword.current = name;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      name,
      status
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, name, createSuccess, updateSuccess, deleteSuccess, status]);
};

export const useMedicineLocationPaging = () => useSelector(pagingSelector);

export const useResetMedicineLocation = () => {
  useResetState(resetMedicineLocationState);
};
