import requester from '../requester';

const samplePrescription = {
  getAll: query => requester.get('/sample-prescription', query),
  getById: id => requester.get(`/sample-prescription/${id}`),
  create: samplePrescription => requester.post('/sample-prescription', samplePrescription),
  update: data => requester.put(`/sample-prescription`, data),
  delete: id => requester.delete(`/sample-prescription/${id}`),
  updateNote: ({id,note}) => requester.patch(`/sample-prescription/${id}`,{
    note
  }),
};

export default samplePrescription;
