import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicineMethods({ payload: query }) {
  try {
    const data = yield call(Api.medicineMethod.getAll, query);
    yield put({ type: Types.GET_MEDICINE_METHODS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_METHODS_FAILED,
      payload: error
    });
  }
}

function* getMedicineMethod({ payload }) {
  try {
    const medicineMethod = yield call(
      Api.medicineMethod.getById,
      payload
    );
    yield put({
      type: Types.GET_MEDICINE_METHOD_SUCCESS,
      payload: medicineMethod
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_METHOD_FAILED,
      payload: error
    });
  }
}

function* createMedicineMethod(action) {
  try {
    const data = yield call(Api.medicineMethod.create, action.payload);
    yield put({ type: Types.CREATE_MEDICINE_METHOD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICINE_METHOD_FAILED,
      payload: error
    });
  }
}

function* updateMedicineMethod(action) {
  try {
    const data = yield call(Api.medicineMethod.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICINE_METHOD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICINE_METHOD_FAILED,
      payload: error
    });
  }
}

function* deleteMedicineMethod({ payload }) {
  try {
    yield call(Api.medicineMethod.delete, payload);
    yield put({ type: Types.DELETE_MEDICINE_METHOD_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICINE_METHOD_FAILED,
      payload: error
    });
  }
}

export default function* medicineMethod() {
  yield takeLatest(Types.GET_MEDICINE_METHODS_REQUEST, getMedicineMethods);
  yield takeLatest(Types.GET_MEDICINE_METHOD_REQUEST, getMedicineMethod);
  yield takeLatest(Types.DELETE_MEDICINE_METHOD_REQUEST, deleteMedicineMethod);
  yield takeLatest(Types.CREATE_MEDICINE_METHOD_REQUEST, createMedicineMethod);
  yield takeLatest(Types.UPDATE_MEDICINE_METHOD_REQUEST, updateMedicineMethod);
}
