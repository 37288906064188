import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicineLocations({ payload: query }) {
  try {
    const data = yield call(Api.medicineLocation.getAll, query);
    yield put({ type: Types.GET_MEDICINE_LOCATIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_LOCATIONS_FAILED,
      payload: error
    });
  }
}

function* getMedicineLocation({ payload }) {
  try {
    const medicineLocation = yield call(Api.medicineLocation.getById, payload);
    yield put({
      type: Types.GET_MEDICINE_LOCATION_SUCCESS,
      payload: medicineLocation
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_LOCATION_FAILED,
      payload: error
    });
  }
}

function* createMedicineLocation(action) {
  try {
    const data = yield call(Api.medicineLocation.create, action.payload);
    yield put({ type: Types.CREATE_MEDICINE_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICINE_LOCATION_FAILED,
      payload: error
    });
  }
}

function* updateMedicineLocation(action) {
  try {
    const data = yield call(Api.medicineLocation.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICINE_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICINE_LOCATION_FAILED,
      payload: error
    });
  }
}

function* deleteMedicineLocation({ payload }) {
  try {
    yield call(Api.medicineLocation.delete, payload);
    yield put({ type: Types.DELETE_MEDICINE_LOCATION_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICINE_LOCATION_FAILED,
      payload: error
    });
  }
}

export default function* medicineLocation() {
  yield takeLatest(Types.GET_MEDICINE_LOCATIONS_REQUEST, getMedicineLocations);
  yield takeLatest(Types.GET_MEDICINE_LOCATION_REQUEST, getMedicineLocation);
  yield takeLatest(
    Types.DELETE_MEDICINE_LOCATION_REQUEST,
    deleteMedicineLocation
  );
  yield takeLatest(
    Types.CREATE_MEDICINE_LOCATION_REQUEST,
    createMedicineLocation
  );
  yield takeLatest(
    Types.UPDATE_MEDICINE_LOCATION_REQUEST,
    updateMedicineLocation
  );
}
