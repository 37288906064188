import React, { useState } from 'react';
import { Skeleton, Row, Col, Image, Button } from 'antd';
import './index.scss';
import GetApiKeyForm from './GetApiKeyForm';
const addressFormater = address => {
  if (!address) {
    return '';
  }
  const { city, district, street, ward } = address;

  return `${street}, ${ward}, ${district}, ${city}`;
};
const index = ({ branch, isLoading }) => {
    // const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="branch-info">
          <Row gutter={48}>
            <Col span={24}>
              <div className="branch-info__logo">
                {branch?.logo ? (
                  <Image width={200} src={branch?.logo}></Image>
                ) : (
                  <Skeleton.Avatar />
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={48}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Tên chi nhánh:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.name?.vi}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Số điện thoại:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.phoneNumber}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">Email:</label>
                <div className="branch-info__item__content">
                  <strong>{addressFormater(branch?.address)}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Số đăng ký nhà thuốc:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.pharmacyRegistrationNumber}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Giấy phép kinh doanh:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.businessRegistrationCertificate}</strong>
                </div>
              </div>

              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Loại hình nhà thuốc:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.pharmacyType}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Chi nhánh quản lý:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.pharmacyType}</strong>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Tên người đại diện:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.representativeName}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Chứng minh nhân dân:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.representativeCertificateNumber}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  SĐT người đại diện:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.representativePhoneNumber}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Người chịu trách nhiệm:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.responsiblePersonName}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Số chứng chỉ hành nghề:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.practicingCertificateNumber}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Trình độ chuyên môn:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.professionalQualification}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  SĐT người chịu trách nhiệm:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.responsiblePersonPhone}</strong>
                </div>
              </div>
              <div className="branch-info__item">
                <label className="branch-info__item__title">
                  Email người chịu trách nhiệm:
                </label>
                <div className="branch-info__item__content">
                  <strong>{branch?.responsiblePersonEmail}</strong>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={48}>
            <Col span={24}>
              <div className="branch-info__photos">
                {isLoading && <Skeleton paragraph={{ rows: 1 }} active />}
                <Image.PreviewGroup>
                  {branch?.photos.map((photo, index) => {
                    return <Image width={200} src={photo} key={index} />;
                  })}
                </Image.PreviewGroup>
              </div>
            </Col>
            </Row>
          <GetApiKeyForm/>
        </div>
      )}
    </>
  );
};

export default index;
