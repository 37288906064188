import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { PATH_APP } from '~/routes/paths';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

const SidebarContent = props => {
  const ref = useRef();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    new MetisMenu('#side-menu');
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        ref={ref}
        className='sidebar-menu-scroll'>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li className='menu-title'>{props.t('Menu')} </li>
            <li>
              <Link to='/#' className='waves-effect'>
                <i className='uil-home-alt'></i>
                <span>{props.t('Dashboard')}</span>
              </Link>
            </li>

            <li className='menu-title'>{props.t('hospital')}</li>

            <li>
              <Link to={PATH_APP.hospital.root} className='waves-effect'>
                <i className='uil uil-hospital'></i>
                <span>{props.t('Hospital List')}</span>
              </Link>
            </li>

            <li>
              <Link to={PATH_APP.staff.root} className='waves-effect'>
                <i className='uil uil-user-nurse'></i>
                <span>{props.t('Staff List')}</span>
              </Link>
            </li>

            <li className='menu-title'>{props.t('appointment')}</li>
            <li>
              <Link to={PATH_APP.appointment.root} className='waves-effect'>
                <i className='uil uil-schedule'></i>
                <span>{props.t('Appointments')}</span>
              </Link>
            </li>

            <li className='menu-title'>{props.t('news')}</li>

            <li>
              <Link to={PATH_APP.newsCategory.root} className='waves-effect'>
                <i className='uil uil-list-ul'></i>
                <span>{props.t('News Categories')}</span>
              </Link>
            </li>

            <li>
              <Link to={PATH_APP.news.root} className='waves-effect'>
                <i className='uil uil-newspaper'></i>
                <span>{props.t('News List')}</span>
              </Link>
            </li>

            <li className='menu-title'>{props.t('configuration')}</li>

            <li>
              <Link to={PATH_APP.config.degree} className='waves-effect'>
                <i className='uil uil-graduation-cap'></i>
                <span>{props.t('Degree')}</span>
              </Link>
            </li>

            <li>
              <Link to={PATH_APP.config.speciality} className='waves-effect'>
                <i className='uil uil-swatchbook'></i>
                <span>{props.t('Speciality')}</span>
              </Link>
            </li>

            <li>
              <Link to={PATH_APP.config.staffGroup} className='waves-effect'>
                <i className='uil uil-user-arrows'></i>
                <span>{props.t('Staff Group')}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
