import { useFetchByParam } from '~/hooks/utils';
import { getNotificationService } from '~/redux/action';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const NOTIFICATION_SERVICE_MODULE = 'notificationService';
const getSelector = key => state => state[NOTIFICATION_SERVICE_MODULE][key];

const getNotificationServiceSelector = getSelector('notificationServiceInfo');
const hasNextPage = getSelector('hasNextPage');
const getNotificationServiceLoadingSelector = getSelector('isGetNotificationServiceLoading');
const getNotificationServiceFailedSelector = getSelector('getNotificationServiceFailed');

export const useNotificationService = query => {
  const jsonQuery = JSON.stringify(query);

  const queryRef = useRef(null);

  if (jsonQuery !== JSON.stringify(queryRef.current)) {
    queryRef.current = query;
  }
  const hasNextPageState = useSelector(hasNextPage);

  return useFetchByParam({
    action: getNotificationService,
    loadingSelector: getNotificationServiceLoadingSelector,
    dataSelector: getNotificationServiceSelector,
    failedSelector: getNotificationServiceFailedSelector,
    param: hasNextPageState ? queryRef.current : null
  });
};
