import requester from './requester';

const MyNotification = {
    subscribeToken: (token) => requester.post(`/devices_fcm`,{token}),
    unSubscribeToken: (token) => requester.delete(`/devices_fcm`,{token}),
    getMyNotification : (query) => requester.get(`/notifications`,query),
    updateStatus : ({id,status}) => requester.put(`/notifications/${id}`,{status}),
    updateManyStatus : ({ids,status}) => requester.put(`/notifications-many-status`,{ids,status}),
    updateAllToRead : () => requester.put(`/notifications-status-read`),
};
export default MyNotification;

