export const GET_MEDICINE_LOCATIONS_REQUEST = 'GET_MEDICINE_LOCATIONS_REQUEST';
export const GET_MEDICINE_LOCATIONS_SUCCESS = 'GET_MEDICINE_LOCATIONS_SUCCESS';
export const GET_MEDICINE_LOCATIONS_FAILED = 'GET_MEDICINE_LOCATIONS_FAILED';

export const GET_MEDICINE_LOCATION_REQUEST = 'GET_MEDICINE_LOCATION_REQUEST';
export const GET_MEDICINE_LOCATION_SUCCESS = 'GET_MEDICINE_LOCATION_SUCCESS';
export const GET_MEDICINE_LOCATION_FAILED = 'GET_MEDICINE_LOCATION_FAILED';

export const CREATE_MEDICINE_LOCATION_REQUEST =
  'CREATE_MEDICINE_LOCATION_REQUEST';
export const CREATE_MEDICINE_LOCATION_SUCCESS =
  'CREATE_MEDICINE_LOCATION_SUCCESS';
export const CREATE_MEDICINE_LOCATION_FAILED =
  'CREATE_MEDICINE_LOCATION_FAILED';

export const UPDATE_MEDICINE_LOCATION_REQUEST =
  'UPDATE_MEDICINE_LOCATION_REQUEST';
export const UPDATE_MEDICINE_LOCATION_SUCCESS =
  'UPDATE_MEDICINE_LOCATION_SUCCESS';
export const UPDATE_MEDICINE_LOCATION_FAILED =
  'UPDATE_MEDICINE_LOCATION_FAILED';

export const DELETE_MEDICINE_LOCATION_REQUEST =
  'DELETE_MEDICINE_LOCATION_REQUEST';
export const DELETE_MEDICINE_LOCATION_SUCCESS =
  'DELETE_MEDICINE_LOCATION_SUCCESS';
export const DELETE_MEDICINE_LOCATION_FAILED =
  'DELETE_MEDICINE_LOCATION_FAILED';

export const RESET_MEDICINE_LOCATION_STATE = 'RESET_MEDICINE_LOCATION_STATE';
