import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getMedicineMethods,
  createMedicineMethod,
  deleteMedicineMethod,
  getMedicineMethod,
  resetMedicineMethodState,
  updateMedicineMethod
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS } from '~/constants/defaultValue';
const MEDICINE_METHOD_MODULE = 'medicineMethod';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(MEDICINE_METHOD_MODULE);

export const useMedicineMethods = param => {
  return useFetchByParam({
    action: getMedicineMethods,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param
  });
};

export const useCreateMedicineMethod = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới đường dùng thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới đường dùng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicineMethod
  });
};

export const useUpdateMedicineMethod = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật đường dùng thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật đường dùng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicineMethod
  });
};

export const useDeleteMedicineMethod = onDeleteSuccess => {
  useSuccess( deleteSuccessSelector, 'Xoá đường dùng thành công', onDeleteSuccess );
  useFailed(deleteFailedSelector, 'Xoá đường dùng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteMedicineMethod
  });
};

export const useMedicineMethod = params => {
  return useFetchByParam({
    action: getMedicineMethod,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitMedicineMethod = (medicineMethod, id) => {
  return useMemo(() => {
    if (!medicineMethod || !id) {
      return { status: true };
    }

    return {
      ...medicineMethod,
      status: medicineMethod.status === STATUS.ACTIVE ? true : false
    };
  }, [medicineMethod, id]);
};

export const useUpdateMedicineMethodParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    history.push({
      pathname: '/medicine-method',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useMedicineMethodQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const name = query.get('name');
  const page = query.get('page');
  const status = query.get('status');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== name) {
    prevKeyword.current = name;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      status,
      name
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, name, createSuccess, updateSuccess, deleteSuccess, status]);
};

export const useMedicineMethodPaging = () => useSelector(pagingSelector);

export const useResetMedicineMethod = () => {
  useResetState(resetMedicineMethodState);
};
