export const GET_SPECIALITIES_REQUEST = 'GET_SPECIALITIES_REQUEST';
export const GET_SPECIALITIES_SUCCESS = 'GET_SPECIALITIES_SUCCESS';
export const GET_SPECIALITIES_FAILED = 'GET_SPECIALITIES_FAILED';

export const GET_SPECIALITY_REQUEST = 'GET_SPECIALITY_REQUEST';
export const GET_SPECIALITY_SUCCESS = 'GET_SPECIALITY_SUCCESS';
export const GET_SPECIALITY_FAILED = 'GET_SPECIALITY_FAILED';

export const CREATE_SPECIALITY_REQUEST = 'CREATE_SPECIALITY_REQUEST';
export const CREATE_SPECIALITY_SUCCESS = 'CREATE_SPECIALITY_SUCCESS';
export const CREATE_SPECIALITY_FAILED = 'CREATE_SPECIALITY_FAILED';

export const UPDATE_SPECIALITY_REQUEST = 'UPDATE_SPECIALITY_REQUEST';
export const UPDATE_SPECIALITY_SUCCESS = 'UPDATE_SPECIALITY_SUCCESS';
export const UPDATE_SPECIALITY_FAILED = 'UPDATE_SPECIALITY_FAILED';

export const DELETE_SPECIALITY_REQUEST = 'DELETE_SPECIALITY_REQUEST';
export const DELETE_SPECIALITY_SUCCESS = 'DELETE_SPECIALITY_SUCCESS';
export const DELETE_SPECIALITY_FAILED = 'DELETE_SPECIALITY_FAILED';

export const RESET_SPECIALITY_STATE = 'RESET_SPECIALITY_STATE';
