import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useMatchPolicy, useUserPolicy } from '~/hooks';
import { Spin } from 'antd';

const WithPolicies = ({ children, permission, layout: Layout }) => {
  const isMatchPolicy = useMatchPolicy(permission);
  const [, , policies] = useUserPolicy();
  const shouldMount = useMemo(() => !!Object.keys(policies || {}).length, [
    policies,
  ]);

  if (!shouldMount) {
    return (
      <Layout>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Spin size='large' tip='Loading...' />
        </div>
      </Layout>
    );
  }

  if (isMatchPolicy) {
    return <>{children}</>;
  }

  return <Redirect to='/' />;
};

export default WithPolicies;
