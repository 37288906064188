const COMPANY = 'company';
const PARTNER = 'partner';
const BRANCH = 'branch';
const COMPANY_INFO = 'companyInfo';
const EMPLOYEE = 'employee';
const USER = 'user';
const USER_GROUP = 'userGroup';
const CUSTOMER = 'customer';
const PATIENT = 'patient';
const ORDER = 'order';
const APPOINTMENT = 'appointment';
const CMS = 'cms';

// CLINIC
const PURCHASE_ORDER = 'purchaseOrder';
const PURCHASE_RECIPT = 'purchaseReceipt';
const SALE = 'sale';
const SUPPLIER = 'supplier';
const CLINIC_SERVICE = 'clinicService';
const PRODUCT_REPORT = 'productReport';
const INVENTORY = 'inventory';
const PRODUCT = 'product'
const MEDICINE = 'medicine';
const SAMPLE_PRESCRIPTION = 'samplePrescription';
const PURCHASE_RETURN = 'purchaseReturn';
const DAMAGE_ITEM = 'damageItem'; 
const SALE_ORDER = 'saleOrder';
const BILL = 'bill';
const RETURN = 'return';
const STOCK_TAKE = 'stocktake';
const CASHBOOK = 'cashbook';
const REPORT = 'report';
const PRODUCT_GROUP = 'productGroup';
const PRODUCT_TYPE = 'productType';
const PRODUCT_POSITION = 'productPosition';
const PRODUCT_UNIT = 'productUnit';
const SUPPLIER_GROUP = 'supplierGroup';
const INFOPAYMENT = 'infoPayment';
const ORDERFORM = 'orderForm';
const PARTNERUSEPMS = 'partnerUsePMS';
const MANUFACTER = 'manufacter';

const RESOURCES = [
  COMPANY,
  PARTNER,
  BRANCH,
  COMPANY_INFO,
  EMPLOYEE,
  USER,
  USER_GROUP,
  CUSTOMER,
  PATIENT,
  ORDER,
  APPOINTMENT,
  CMS,

  PURCHASE_ORDER,
  PURCHASE_RECIPT,
  SALE,
  SUPPLIER,
  CLINIC_SERVICE,
  PRODUCT_REPORT,
  INVENTORY,
  PRODUCT,
  MEDICINE,
  SAMPLE_PRESCRIPTION,
  PURCHASE_RETURN,
  DAMAGE_ITEM,
  SALE_ORDER,
  BILL,
  RETURN,
  STOCK_TAKE,
  CASHBOOK,
  REPORT,
  PRODUCT_GROUP,
  PRODUCT_TYPE,
  PRODUCT_POSITION,
  PRODUCT_UNIT,
  SUPPLIER_GROUP,
  INFOPAYMENT,
  ORDERFORM,
  PARTNERUSEPMS,
  MANUFACTER,
];

//ACTIONS
const READ = 'read';
const WRITE = 'write';
const UPDATE = 'update';
const DELETE = 'delete';

const ACTIONS = [READ, WRITE, UPDATE, DELETE];

const POLICIES = RESOURCES.reduce((policies, resource) => {
  const policy = ACTIONS.reduce(
    (actions, action) => ({
      ...actions,
      [`${action.toUpperCase()}_${resource.toUpperCase()}`]: [resource, action]
    }),
    {
      [resource.toUpperCase()]: [resource]
    }
  );

  return {
    ...policies,
    ...policy
  };
}, {});

export default POLICIES;
