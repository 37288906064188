import { useFetch } from '~/hooks/utils';
import { getServices } from '~/redux/action';

const getSelector = key => state => state.service[key];

const loadingSelector = getSelector('isLoading');
const servicesSelector = getSelector('services');
const getServicesFailedSelector = getSelector('getServicesFailed');

export const useServices = () => {
  return useFetch({
    action: getServices,
    loadingSelector,
    dataSelector: servicesSelector,
    failedSelector: getServicesFailedSelector
  });
};
