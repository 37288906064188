import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Skeleton,
  Modal,
  Select,
  InputNumber,
  Radio
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const FormItem = Form.Item;

const PrescriptionForm = props => {
  const { isOpen, onClose, id, onSubmit, initPrescription } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (initPrescription) {
      form.resetFields();
      onSubmit(initPrescription);
    }

    //eslint-disable-next-line
  }, [form, initPrescription]);

  const [, setDoctorFormOpen] = useState(false);

  const isSubmitLoading = false;
  const isLoading = false;

  const onFinish = values => {
    onSubmit(values);
    onClose();
  };
  return (
    <Modal
      visible={isOpen}
      width={1080}
      footer={[]}
      onCancel={onClose}
      className="form-modal wc-form form-modal--clinic"
    >
      <div className="medicine-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
          initialValues={initPrescription}
        >
          <Row className="mb-4">
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} đơn thuốc
            </h4>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Mã đơn thuốc" name="code">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input placeholder="Mã mặc định" disabled />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <Row justify="space-between">
                <FormItem label="Bác sĩ kê đơn" name="doctor">
                  <Select
                    showSearch
                    style={{ width: 318, marginLeft: 13, padding: 0 }}
                  >
                    <Option value="1">BS. Trần Văn C</Option>
                    <Option value="2">BS. Trần Văn D</Option>
                  </Select>
                </FormItem>

                <Button
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDoctorFormOpen(true);
                  }}
                >
                  <PlusOutlined />
                </Button>
              </Row>
            </Col>

            <Col span={12}>
              <FormItem label="Phòng khám" name="healthcareFacility">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Tên bệnh nhân" name="patientName">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Tuổi" name="age">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber style={{ width: '100%' }} />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="gender" label="Giới tính">
                <Radio.Group>
                  <Radio value="male">Nam</Radio>
                  <Radio value="female">Nữ</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Cân nặng" name="weight">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber style={{ width: '100%' }} />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="CMTND/CC" name="patientId">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Thẻ BHYT"
                name="patientHealthInsuranceCardNumber"
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Địa chỉ" name="address">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Điện thoại" name="phoneNumber">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Chẩn đoán" name="diagnosis">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Tên bệnh" name="diseaseName">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Mã bệnh" name="diseaseCode">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Hoạt chất" name="activeIngredient">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Liều dùng" name="dose">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row className="submit-box">
            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              Xong
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default PrescriptionForm;
