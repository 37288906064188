import * as Types from '~/constants/actionTypes';

export const getCustomers = query => ({
  type: Types.GET_CUSTOMERS_REQUEST,
  payload: query
});

export const getCustomer = id => ({
  type: Types.GET_CUSTOMER_REQUEST,
  payload: id
});

export const createCustomer = customer => ({
  type: Types.CREATE_CUSTOMER_REQUEST,
  payload: customer
});

export const updateCustomer = customer => ({
  type: Types.UPDATE_CUSTOMER_REQUEST,
  payload: customer
});

export const deleteCustomer = id => ({
  type: Types.DELETE_CUSTOMER_REQUEST,
  payload: id
});

export const getCustomerOptions = id => ({
  type: Types.GET_CUSTOMER_OPTIONS_REQUEST,
  payload: id
});

export const getCustomerSearch = id => ({
  type: Types.GET_CUSTOMER_SEARCH_REQUEST,
  payload: id
});

export const getCustomerDebt = query => ({
  type: Types.GET_CUSTOMER_DEBT_REQUEST,
  payload: query
});

export const getCustomerPurchases = query => ({
  type: Types.GET_CUSTOMER_PURCHASES_REQUEST,
  payload: query
});

export const getCustomerOrderHistory = query => ({
  type: Types.GET_CUSTOMER_ORDER_HISTORY_REQUEST,
  payload: query
});

export const resetCustomerState = () => ({
  type: Types.RESET_CUSTOMER_STATE
});
