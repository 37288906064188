import requester from './requester';

const manufacture = {
    getAll: (query) => requester.get('/manufacturer', query),
    getOptions: (query) => requester.get('/manufacturer-search', query),
    getById: (id) => requester.get(`/manufacturer/${id}`),
    create: (manufacture) => requester.post(`/manufacturer`, manufacture),
    update: (manufacture) => requester.put(`/manufacturer/${manufacture._id}`, manufacture),
    delete: (id) => requester.delete(`/manufacturer/${id}`)
};

export default manufacture;
