import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  Button,
  Skeleton
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import UploadImage from '~/components/Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { useCities, useDistricts, useWards } from '~/hooks/worldCare/geo';
import {
  useCreateStaff,
  useStaff,
  useInitStaff,
  useResetStaff,
  useUpdateStaff
} from '~/hooks/worldCare/staff';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { useStaffGroups } from '~/hooks/worldCare/staffGroup';
import './form.scss';
import { useSpecialities } from '~/hooks/worldCare/speciality';
import { useDegrees } from '~/hooks/worldCare/degree';
import { filterAcrossAccents, useFormItemMargin } from '~/hooks/utils';
import { useParams } from 'react-router-dom';
import { useHospitalOptions } from '~/hooks/worldCare/hospital';

const { Option } = Select;

const FormItem = Form.Item;

const verticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const StaffForm = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [logo, setLogo] = useState();

  const [selectedCityCode, setSelectedCityCode] = useState();
  const [selectedDistrictCode, setSelectedDistrictCode] = useState();

  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [wards, isWardsLoading] = useWards(selectedDistrictCode);
  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [degrees, isDegreesLoading] = useDegrees();
  const [hospitals, isHospitalsLoading] = useHospitalOptions();
  const [staffGroups, isStaffGroupLoading] = useStaffGroups();

  const [isSubmitLoading, handleCreate] = useCreateStaff();
  const [, handleUpdate] = useUpdateStaff();

  const { id } = useParams();
  const [staff, isGetStaffLoading] = useStaff(id);
  const initStaff = useInitStaff(staff);
  const margin = useFormItemMargin();

  useEffect(() => {
    form.resetFields();
    const { avatar, address } = initStaff;

    if (avatar) {
      setLogo(avatar);
    }

    if (address) {
      setSelectedCityCode(address.city);
      setSelectedDistrictCode(address.district);
    }
  }, [initStaff, form]);

  useResetStaff();

  const onFinish = (values) => {
    const staff = {
      ...values,
      avatar: logo
    };

    if (id) {
      handleUpdate({ ...staff, id });
    } else {
      handleCreate(staff);
    }
  };

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.city;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          district: null,
          ward: null
        }
      });
    }

    const shouldResetWards = address && address.district;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          ward: null
        }
      });
    }
  };

  const isLoading = isGetStaffLoading;

  return (
    <div className="staff-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} nhân viên`}
          routes={[{ path: PATH_APP.staff.root, title: 'Danh sách nhân viên' }]}
        />
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          initialValues={initStaff}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <Row justify="end">
            <Radio.Group
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <Radio.Button
                className="staff-form__select-langue-btn"
                value={LANGUAGE.VI}
              >
                VI
              </Radio.Button>
              <Radio.Button
                className="staff-form__select-langue-btn"
                value={LANGUAGE.EN}
              >
                EN
              </Radio.Button>
            </Radio.Group>
          </Row>

          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="staff-form__logo-row"
          >
            <Col span={12}>
              <Row gutter={36}>
                <Col span={24}>
                  <FormItem label="Tên nhân viên" name="firstName">
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Họ nhân viên" name="lastName">
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>

              <FormItem label="Đường dẫn" name="slug">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12} className="staff-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.VI]}
              className={`staff-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>

            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.EN]}
              className={`staff-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thành Phố/Tỉnh"
                name={['address', 'city']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    onChange={setSelectedCityCode}
                    disabled={isCitiesLoading}
                    loading={isCitiesLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {cities.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Quận/Huyện"
                name={['address', 'district']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Quận/Huyện!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isDistrictsLoading}
                    disabled={!form.getFieldValue(['address', 'city'])}
                    onChange={setSelectedDistrictCode}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {districts.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Phường/Xã"
                name={['address', 'ward']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Phường/Xã!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isWardsLoading}
                    disabled={!form.getFieldValue(['address', 'district'])}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {wards.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Đường phố" name={['address', 'street']}>
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Email bạn nhập không đúng định dạng!'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Số điện thoại"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,11}$/),
                    message: 'Xin vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Ngôn ngữ" name="lang">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select mode="multiple" style={{ width: '100%' }}>
                    <Option value={LANGUAGE.VI} key={LANGUAGE.VI}>
                      Việt nam
                    </Option>
                    <Option value={LANGUAGE.EN} key={LANGUAGE.EN}>
                      English
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Nhóm nhân sự"
                name="employeeGroup"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn nhóm nhân sự'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isStaffGroupLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {staffGroups.map(({ id, _id, name }) => (
                      <Option key={id || _id} value={id || _id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48}>
            <Col span={12}>
              <FormItem
                label="Phòng khám"
                name="hospital"
                autoComplete="off"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn phòng khám!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    showSearch
                    filterOption={filterAcrossAccents}
                    style={{ width: '100%' }}
                    loading={isHospitalsLoading}
                    disabled={isHospitalsLoading}
                  >
                    {hospitals.map(({ hospitalName, id }) => (
                      <Option value={id} key={id}>
                        {hospitalName}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Chuyên môn"
                name="speciality"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn chuyên môn!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    placeholder=""
                    style={{ width: '100%' }}
                    loading={isSpecialitiesLoading}
                    disabled={isSpecialitiesLoading}
                  >
                    {specialities.map(({ name, id }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Bằng cấp" name={['degree', 'degreeId']}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    disabled={isDegreesLoading}
                    loading={isDegreesLoading}
                  >
                    {degrees.map((degree) => (
                      <Option value={degree.id} key={degree.id}>
                        {degree.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Thời gian cấp" name={['degree', 'issuedAt']}>
                {isLoading ? <Skeleton.Input active /> : <DatePicker />}
              </FormItem>
            </Col>
          </Row>

          <h3 className="staff-form__section-title">Các chứng chỉ khác</h3>
          <Form.List name="certification">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Row gutter={48} align={index === 0 && 'middle'} key={key}>
                    <Col sm={12} lg={6}>
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Chứng chỉ'}
                        name={[name, 'name']}
                        fieldKey={[fieldKey, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập tên chứng chỉ'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <Input placeholder="Chứng chỉ" />
                      </Form.Item>
                    </Col>

                    <Col sm={12} lg={6}>
                      {' '}
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Nơi cấp'}
                        name={[name, 'certifiedBy']}
                        fieldKey={[fieldKey, 'certifiedBy']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập nơi cấp chứng chỉ'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <Input placeholder="Nơi cấp" />
                      </Form.Item>
                    </Col>

                    <Col sm={12} lg={6}>
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Thời gian cấp'}
                        name={[name, 'certifiedAt']}
                        fieldKey={[fieldKey, 'certifiedAt']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập thời gian cấp'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <DatePicker placeholder="Thời gian cấp" />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        className={index === 0 && 'top-5'}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm chứng chỉ
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>

          <h3 className="staff-form__section-title">Quá trình công tác</h3>
          <Form.List name="employeeHistory">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Row gutter={48} align={index === 0 && 'middle'} key={key}>
                    <Col sm={12} lg={6}>
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Chức vụ'}
                        name={[name, 'role']}
                        fieldKey={[fieldKey, 'role']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập Chức vụ'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <Input placeholder="Chức vụ" />
                      </Form.Item>
                    </Col>

                    <Col sm={12} lg={6}>
                      {' '}
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Địa điểm'}
                        name={[name, 'location']}
                        fieldKey={[fieldKey, 'location']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập dịa điểm'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <Input placeholder="Địa điểm" />
                      </Form.Item>
                    </Col>

                    <Col sm={12} lg={5}>
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Thời gian bắt đầu'}
                        name={[name, 'startTime']}
                        fieldKey={[fieldKey, 'startTime']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập thời gian bắt đầu'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <DatePicker placeholder="Thời gian bắt đầu" />
                      </Form.Item>
                    </Col>

                    <Col sm={10} lg={5}>
                      <Form.Item
                        {...restField}
                        label={index === 0 && 'Thời gian kết thúc'}
                        name={[name, 'endTime']}
                        fieldKey={[fieldKey, 'endTime']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập Thời gian kết thúc'
                          }
                        ]}
                        {...verticalLayout}
                      >
                        <DatePicker placeholder="Thời gian cấp" />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        className={index === 0 && 'top-5'}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm lịch sử công tác
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>

          <Row className="staff-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.staff.root}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default StaffForm;
