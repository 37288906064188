import React from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_APP, PATH_CLINIC } from './paths';

// Dashboard
import Dashboard from '~/pages/Dashboard/index';

// Hospital
import Hospital from '~/components/WorldCare/Hospital';
import CreateHospital from '~/components/WorldCare/Hospital/Form';

// Hospital
import Staff from '~/components/WorldCare/Staff';
import StaffForm from '~/components/WorldCare/Staff/Form';

// Appointment
import Appointment from '~/components/WorldCare/Appointment';

// NewsCategory
import NewsCategory from '~/components/WorldCare/NewsCategory';
import NewsCategoryForm from '~/components/WorldCare/NewsCategory/Form';

// News
import News from '~/components/WorldCare/News';
import NewsForm from '~/components/WorldCare/News/Form';

// Config
import StaffGroup from '~/components/WorldCare/StaffGroup';
import StaffGroupForm from '~/components/WorldCare/StaffGroup/Form';

import Degree from '~/components/WorldCare/Degree';
import DegreeForm from '~/components/WorldCare/Degree/Form';

import Speciality from '~/components/WorldCare/Speciality';
import SpecialityForm from '~/components/WorldCare/Speciality/Form';

// Authentication related pages
// import Branches from '~/pages/Authentication/Branches';
import Login from '~/pages/Authentication/Login';
import Logout from '~/pages/Authentication/Logout';

// Branch
import Branch from '~/components/WorldCare/Branch';
import BranchDetail from '~/components/WorldCare/Branch/Detail';

// Partner
import Partners from '~/components/WorldCare/Partner';
import PartnerDetail from '~/components/WorldCare/Partner/Detail';

// Order
import PendingOrders from '~/components/WorldCare/Order/PendingOrders';
import AllOrders from '~/components/WorldCare/Order/AllOrders';

// Employee
import Employee from '~/components/WorldCare/Employee';

// User Employee
import UserEmployee from '~/components/WorldCare/UserEmployee';

// Partner Ship
import Partnership from '~/components/WorldCare/Partnership';

// Coupon
import Coupon from '~/components/WorldCare/Coupon';

// Policy
import Policy from '~/components/WorldCare/Policy';

// Shipping Vendor
import ShippingVendor from '~/components/WorldCare/ShippingVendor';

import POLICY from '~/constants/policy';

import Branches from '~/components/WorldCare/Partner/Detail/Branches/Detail';


//-----------------------WORLD CLINIC-----------------------//
// Setting
import MedicineGroup from '~/components/WorldClinic/MedicineGroup';
import MedicineMethod from '~/components/WorldClinic/MedicineMethod';
import MedicineLocation from '~/components/WorldClinic/MedicineLocation';
import MedicineUnit from '~/components/WorldClinic/MedicineUnit';
import SupplierGroup from '~/components/WorldClinic/SupplierGroup';

// Supplier
import Supplier from '~/components/WorldClinic/Supplier';

// Medicine
import Medicine from '~/components/WorldClinic/Medicine';
import SamplePrescription from '~/components/WorldClinic/SamplePrescription';
import CreateSamplePrescription from '~/components/WorldClinic/SamplePrescription/Form';
// Warehouse
import Warehouse from '~/components/WorldClinic/Warehouse';
import CreateWarehouse from '~/components/WorldClinic/Warehouse/Form';

// Product order
import ProductOrder from '~/components/WorldClinic/ProductOrder';
import CreateProductOrder from '~/components/WorldClinic/ProductOrder/Form';

// Product invoice
import ProductInvoice from '~/components/WorldClinic/ProductInvoice';
import CreateProductInvoice from '~/components/WorldClinic/ProductInvoice/Form';

// Cash flow
import CashFlow from '~/components/WorldClinic/CashFlow';

//Sale
import Sale from '~/components/WorldClinic/Sale';
import InvoiceDetail from '~/components/WorldClinic/Sale/InvoiceTemplate';

import PurchaseReturn from '~/components/WorldClinic/PurchaseReturn';
import CreatePurchaseReturn from '~/components/WorldClinic/PurchaseReturn/Form';

import Damage from '~/components/WorldClinic/Damage';
import CreateDamage from '~/components/WorldClinic/Damage/Form';

import Customer from '~/components/WorldClinic/Customer';
import ProductReturn from '~/components/WorldClinic/ProductReturn';

import StockTake from '~/components/WorldClinic/StockTake';
import createStockTake from '~/components/WorldClinic/StockTake/Form';

//Medicine Directory
import MedicineDirectory from '~/components/WorldClinic/MedicineDirectory';

// Form Print Footer
import FormPrint from '~/components/WorldClinic/FormPrint'

//Product Report
import ProductReport from '~/components/WorldClinic/ProductReport';

// Imformation Payment
import ImformationPayment from '~/components/WorldClinic/ImformationPayment';

// Manufacture
import Manufacture from '~/components/WorldClinic/Manufacture';
import ManufactureForm from '~/components/WorldClinic/Manufacture/Form';

// Receipt Payment Type
import Payment from '~/components/WorldClinic/ReceiptPaymentType/Payment';
import Receipt from '~/components/WorldClinic/ReceiptPaymentType/Receipt';
// Process Import
import ProcessImport from '~/components/WorldClinic/ProcessImport';
import PurchaseOrderQuotation from '~/components/WorldClinic/PurchaseOrderQuotation';
import MyNotification from '~/components/WorldClinic/MyNotification';

const userRoutes = [
  { path: '/dashboard', component: Dashboard },

  // SHIPPING VENDOR
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.phippingVendor.root,
    component: ShippingVendor,
    permission: POLICY.CMS
  },

  // POLICY
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.policy.root,
    component: Policy,
    permission: POLICY.CMS
  },

  // COUPON
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.coupon.root,
    component: Coupon,
    permission: POLICY.CMS
  },

  // PARTNER SHIP
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.partnership.root,
    component: Partnership,
    permission: POLICY.CMS
  },

  // EMPLOYEE
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.employee.root,
    component: Employee,
    permission: POLICY.READ_EMPLOYEE
  },

  // USER
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.user.root,
    component: UserEmployee,
    permission: POLICY.READ_USER
  },

  // PARTNER
  // ----------------------------------------------------------------------
  {
    path: PATH_APP.partner.branches,
    component: Branches,
    permission: POLICY.READ_PARTNER
  },
  {
    exact: true,
    path: PATH_APP.partner.root,
    component: Partners,
    permission: POLICY.READ_PARTNER
  },

  {
    path: PATH_APP.partner.detail,
    component: PartnerDetail,
    permission: POLICY.READ_PARTNER
  },

  // BRANCH
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.branch.root,
    component: Branch,
    permission: POLICY.READ_BRANCH
  },

  {
    path: PATH_APP.branch.detail,
    component: BranchDetail,
    permission: POLICY.WRITE_BRANCH
  },

  // HOSPITAL
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.hospital.root,
    component: Hospital,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.hospital.create,
    component: CreateHospital,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.hospital.edit,
    component: CreateHospital,
    permission: POLICY.CMS
  },

  // STAFF
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.staff.root,
    component: Staff,
    permission: POLICY.CMS
  },
  {
    exact: true,
    path: PATH_APP.staff.create,
    component: StaffForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.staff.edit,
    component: StaffForm,
    permission: POLICY.CMS
  },

  // APPOINTMENT
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.appointment.root,
    component: Appointment,
    permission: POLICY.READ_APPOINTMENT
  },

  // NEWS CATEGORY
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.newsCategory.root,
    component: NewsCategory,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.newsCategory.create,
    component: NewsCategoryForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.newsCategory.edit,
    component: NewsCategoryForm,
    permission: POLICY.CMS
  },

  // NEWS
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.news.root,
    component: News,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.news.create,
    component: NewsForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.news.edit,
    component: NewsForm,
    permission: POLICY.CMS
  },

  // ORDERS
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.order.pendingOrder,
    component: PendingOrders,
    permission: POLICY.ORDER
  },
  {
    exact: true,
    path: PATH_APP.order.allOrder,
    component: AllOrders,
    permission: POLICY.ORDER
  },

  // CONFIG
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_APP.config.staffGroup,
    component: StaffGroup,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.createStaffGroup,
    component: StaffGroupForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.editStaffGroup,
    component: StaffGroupForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.degree,
    component: Degree,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.createDegree,
    component: DegreeForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.editDegree,
    component: DegreeForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.speciality,
    component: Speciality,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.createSpeciality,
    component: SpecialityForm,
    permission: POLICY.CMS
  },

  {
    exact: true,
    path: PATH_APP.config.editSpeciality,
    component: SpecialityForm,
    permission: POLICY.CMS
  },

  // WORLD CLINIC
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_CLINIC.medicineSetting.group,
    component: MedicineGroup,
    permission: POLICY.READ_PRODUCTGROUP
  },

  {
    exact: true,
    path: PATH_CLINIC.medicineSetting.method,
    component: MedicineMethod,
    permission: POLICY.READ_PRODUCTTYPE
  },

  {
    exact: true,
    path: PATH_CLINIC.medicineSetting.location,
    component: MedicineLocation,
    permission: POLICY.READ_PRODUCTPOSITION
  },

  {
    exact: true,
    path: PATH_CLINIC.medicineSetting.unit,
    component: MedicineUnit,
    permission: POLICY.READ_PRODUCTUNIT
  },
  {
    exact: true,
    path: PATH_CLINIC.formPrint.root,
    component: FormPrint,
    permission: POLICY.READ_ORDERFORM
  },
  {
    exact: true,
    path: PATH_CLINIC.imformationPayment.root,
    component: ImformationPayment,
    permission: POLICY.READ_INFOPAYMENT
  },

  {
    exact: true,
    path: PATH_CLINIC.supplier,
    component: Supplier,
    permission: POLICY.READ_SUPPLIER
  },
  {
    exact: true,
    path: PATH_CLINIC.medicineSetting.supplierGroup,
    component: SupplierGroup,
    permission: POLICY.READ_SUPPLIERGROUP
  },
  {
    exact: true,
    path: PATH_CLINIC.medicine.root,
    component: Medicine,
    permission: POLICY.READ_PRODUCT
  },
  {
    exact: true,
    path: PATH_CLINIC.medicineDirectory.root,
    component: MedicineDirectory,
    permission: POLICY.READ_MEDICINE
  },
  {
    exact: true,
    path: PATH_CLINIC.samplePrescription.root,
    component: SamplePrescription,
    permission: POLICY.READ_SAMPLEPRESCRIPTION
  },
  // {
  //   exact: true,
  //   path: PATH_CLINIC.samplePrescription.root,
  //   component: SamplePrescription,
  //   permission: POLICY.CMS
  // },
  {
    exact: true,
    path: PATH_CLINIC.samplePrescription.create,
    component: CreateSamplePrescription,
    permission: POLICY.WRITE_SAMPLEPRESCRIPTION
  },
  {
    exact: true,
    path: PATH_CLINIC.samplePrescription.edit,
    component: CreateSamplePrescription,
    permission: POLICY.UPDATE_SAMPLEPRESCRIPTION
  },
  { 
    exact: true,
    path: PATH_CLINIC.purchaseOrderQuotation.root,
    component: PurchaseOrderQuotation,
    // permission: POLICY.READ_PURCHASEORDER
  },
  {
    exact: true,
    path: PATH_CLINIC.warehouse.root,
    component: Warehouse,
    permission: POLICY.READ_PURCHASEORDER
  },
  {
    path: PATH_CLINIC.warehouse.create,
    component: CreateWarehouse,
    permission: POLICY.WRITE_PURCHASEORDER
  },
  {
    path: PATH_CLINIC.warehouse.edit,
    component: CreateWarehouse,
    permission: POLICY.UPDATE_PURCHASEORDER
  },

  {
    exact: true,
    path: PATH_CLINIC.productOrder.root,
    component: ProductOrder,
    permission: POLICY.READ_SALEORDER
  },
  {
    path: PATH_CLINIC.productOrder.create,
    component: CreateProductOrder,
    permission: POLICY.WRITE_SALEORDER
  },

  {
    exact: true,
    path: PATH_CLINIC.productInvoice.root,
    component: ProductInvoice,
    permission: POLICY.READ_BILL
  },
  {
    path: PATH_CLINIC.productInvoice.create,
    component: CreateProductInvoice,
    permission: POLICY.WRITE_BILL
  },

  {
    path: PATH_CLINIC.cashFlow.root,
    component: CashFlow,
    permission: POLICY.CASHBOOK
  },

  {
    exact: true,
    path: PATH_CLINIC.purchaseReturn.root,
    component: PurchaseReturn,
    permission: POLICY.READ_PURCHASERETURN
  },
  {
    path: PATH_CLINIC.purchaseReturn.create,
    component: CreatePurchaseReturn,
    permission: POLICY.WRITE_PURCHASERETURN
  },
  {
    path: PATH_CLINIC.purchaseReturn.edit,
    component: CreatePurchaseReturn,
    permission: POLICY.UPDATE_PURCHASERETURN
  },
  {
    exact: true,
    path: PATH_CLINIC.damage.root,
    component: Damage,
    permission: POLICY.DAMAGEITEM
  },
  {
    path: PATH_CLINIC.damage.create,
    component: CreateDamage,
    permission: POLICY.WRITE_DAMAGEITEM
  },
  {
    path: PATH_CLINIC.damage.edit,
    component: CreateDamage,
    permission: POLICY.UPDATE_DAMAGEITEM
  },
  {
    path: PATH_CLINIC.customer.root,
    component: Customer,
    permission: POLICY.CUSTOMER
  },
  {
    exact: true,
    path: PATH_CLINIC.productReturn.root,
    component: ProductReturn,
    permission: POLICY.READ_RETURN
  },
  {
    exact: true,
    path: PATH_CLINIC.productReport.root,
    component: ProductReport,
    permission: POLICY.READ_REPORT
  },
  {
    exact: true,
    path: PATH_CLINIC.stockTake.root,
    component: StockTake,
    permission: POLICY.READ_STOCKTAKE
  },
  {
    path: PATH_CLINIC.stockTake.create,
    component: createStockTake,
    permission: POLICY.WRITE_STOCKTAKE
  },
  {
    path: PATH_CLINIC.manufacturer.root,
    component: Manufacture,
    permission: POLICY.READ_MANUFACTER,
  },
  {
    path: PATH_CLINIC.manufacturer.create,
    component: ManufactureForm,
    permission: POLICY.WRITE_PRODUCT,
  },
  {
    path: PATH_CLINIC.manufacturer.edit,
    component: ManufactureForm,
    permission: POLICY.UPDATE_PRODUCT,
  },

  {
    path: PATH_CLINIC.processImport.root,
    component: ProcessImport,
    permission: POLICY.PROCESSIMPORT
  },

  {
    path: PATH_CLINIC.payment.root,
    component: Payment,
    permission: POLICY.PAYMENT
  },

  {
    path: PATH_CLINIC.receipt.root,
    component: Receipt,
    permission: POLICY.RECEIPT
  },

  {
    path: PATH_CLINIC.myNotification.root,
    component: MyNotification,
    // permission: POLICY.RECEIPT
  },
  
  // {
  //   path: PATH_CLINIC.productReturn.create,
  //   //component: CreateProductReturn,
  //   permission: POLICY.PURCHASE_ORDER
  // },
  // {
  //   path: PATH_CLINIC.productReturn.edit,
  //   //component: CreateProductReturn,
  //   permission: POLICY.PURCHASE_ORDER
  // },

  // { exact: true, path: '/branches', component: Branches },

  // this route should be at the end of all other routes
  { path: '/', component: () => <Redirect to="/dashboard" /> }
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login }
];

const saleRoutes = [
  {
    exact: true,
    path: PATH_CLINIC.sale.root,
    component: Sale,
    permission: POLICY.READ_SALE
  },
  {
    exact: true,
    path: '/invoice',
    component: InvoiceDetail,
    permission: POLICY.PURCHASEORDER
  }
];

export { userRoutes, authRoutes, saleRoutes };
