import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { Space } from 'antd';
import { useContext } from 'react';
import { createContext, useCallback, useState } from 'react';
import ExportController from './ExportController';
import moment from 'moment';

const ExportProvider = createContext();
export const useExport = () => useContext(ExportProvider);

function ModuleExport({ children }) {
  const [isOpenModelDetail, setIsOpenModel] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [data, setListHistoryExport] = useState([]);
  const handleCloseItem = useCallback(
    keyValue => {
      setListHistoryExport(current =>
        current.filter(value => value.keyValue !== keyValue)
      );
    },
    [setListHistoryExport]
  );

  const handleAddExport = useCallback(
    ({ query, router, label, fileName }) => {
      const title = {
        1: v => `Xuất file ${v} tổng quát`,
        2: v => `Xuất file ${v} chi tiết`,
        3: v => `Xuất phiếu ${v} chi tiết`,
        4: v => `Xuất file ${v}`,
        5: v => `Xuất file thuốc, hàng hoá mẫu`
      };
      setIsOpenModel(true);
      setIsShow(false);
      const keyValue = moment().format('HH_mm_ss').toString();
      setListHistoryExport(data => [
        ...data,
        {
          query,
          keyValue,
          router,
          label: title[query.exportOption](label),
          fileName
        }
      ]);
    },
    [setIsOpenModel, setListHistoryExport]
  );

  const handleClose = useCallback(
    e => {
      e.preventDefault();
      setIsShow(v => !v);
      setListHistoryExport([]);
    },
    [setIsShow]
  );

  return (
    <ExportProvider.Provider value={{ handleAddExport }}>
      {children}
      {data?.length && (
        <details
          open={isOpenModelDetail}
          className="export-container"
          style={{ display: !isShow ? 'block' : 'none' }}
        >
          <summary
            className="export-summary"
            onClick={e => {
              e.preventDefault();
              setIsOpenModel(v => !v);
            }}
          >
            Xử lý thiết lập, xuất file
            <Space>
              {isOpenModelDetail ? (
                <CaretDownOutlined
                  className="btn-detail"
                  onClick={() => setIsOpenModel(true)}
                />
              ) : (
                <CaretUpOutlined
                  className="btn-detail"
                  onClick={() => setIsOpenModel(false)}
                />
              )}
              <CloseOutlined onClick={e => handleClose(e)} />
            </Space>
          </summary>
          <div className="export-body">
            {data?.map(e => (
              <ExportController
                key={e.keyValue}
                {...e}
                handleCloseItem={handleCloseItem}
              />
            ))}
          </div>
        </details>
      )}
    </ExportProvider.Provider>
  );
}

export default ModuleExport;
