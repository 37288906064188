import React from 'react';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const verticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const Services = () => {
  return (
    <div style={{ marginBottom: 30 }}>
      <h4 className="hospital-form__working-hours-form-title">Dịch vụ:</h4>
      <Form.List name="services">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <Row gutter={48} align="start" key={key}>
                <Col span={6}>
                  <Form.Item
                    {...restField}
                    {...verticalLayout}
                    label={index === 0 && 'Tên dịch vụ'}
                    name={[name, 'name']}
                    fieldKey={[fieldKey, 'name']}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập Tên dịch vụ'
                      }
                    ]}
                  >
                    <Input placeholder="Tên dịch vụ" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    {...restField}
                    {...verticalLayout}
                    label={index === 0 && 'Mô tả'}
                    name={[name, 'description']}
                    fieldKey={[fieldKey, 'description']}
                  >
                    <Input placeholder="Mô tả" />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    {...verticalLayout}
                    {...restField}
                    label={index === 0 && 'Chi tiết'}
                    name={[name, 'items']}
                    fieldKey={[fieldKey, 'items']}
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Chi tiet"
                      dropdownClassName="u-hiden"
                    ></Select>
                  </Form.Item>
                </Col>

                <Col
                  span={2}
                  style={{ alignSelf: index === 0 && 'center', paddingTop: 5 }}
                >
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    className={index === 0 && 'top-5'}
                  />
                </Col>
              </Row>
            ))}

            <Row>
              <Col>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Thêm dịch vụ
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default Services;
