export const GET_STAFFS_REQUEST = 'GET_STAFFS_REQUEST';
export const GET_STAFFS_SUCCESS = 'GET_STAFFS_SUCCESS';
export const GET_STAFFS_FAILED = 'GET_STAFFS_FAILED';

export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILED = 'DELETE_STAFF_FAILED';

export const CREATE_STAFF_REQUEST = 'CREATE_STAFF_REQUEST';
export const CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS';
export const CREATE_STAFF_FAILED = 'CREATE_STAFF_FAILED';

export const UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILED = 'UPDATE_STAFF_FAILED';

export const GET_STAFF_REQUEST = 'GET_STAFF_REQUEST';
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';
export const GET_STAFF_FAILED = 'GET_STAFF_FAILED';

export const RESET_STAFF_STATE = 'RESET_STAFF_STATE';
