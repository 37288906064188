import { get, omit } from 'lodash';
import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import { STATUS_ORDER_QUOTATION } from '~/components/WorldClinic/PurchaseOrderQuotation/constants';
import * as Types from '~/constants/actionTypes';
// import {
//   TYPE_ACTIVITY,
//   MESSAGE_ACTIVITY,
//   ACTIVITY_NAME_EN,
//   ACTIVITY_NAME
// } from '~/constants/defaultValue';
function* getPurchaseOrderQuotations({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const purchaseOrderQuotation = yield call(Api.purchaseOrderQuotation.getById, query.id);
      data = { docs: [purchaseOrderQuotation], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.purchaseOrderQuotation.getAll, query);
    }

    yield put({ type: Types.GET_PURCHASE_ORDER_QUOTATIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PURCHASE_ORDER_QUOTATIONS_FAILED,
      payload: error
    });
  }
}

function* getPurchaseOrderQuotation({ payload }) {
  try {
    const purchaseOrderQuotation = yield call(Api.purchaseOrderQuotation.getById, payload);
    yield put({
      type: Types.GET_PURCHASE_ORDER_QUOTATION_SUCCESS,
      payload: purchaseOrderQuotation
    });
  } catch (error) {
    yield put({
      type: Types.GET_PURCHASE_ORDER_QUOTATION_FAILED,
      payload: error
    });
  }
}

function* createPurchaseOrderQuotation(action) {
  try {
    const data = yield call(Api.purchaseOrderQuotation.create, action.payload);
    yield put({ type: Types.CREATE_PURCHASE_ORDER_QUOTATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PURCHASE_ORDER_QUOTATION_FAILED,
      payload: error
    });
  }
}

function* updatePurchaseOrderQuotation(action) {
  let query = get(action.payload,'query',{})
  let payload = omit(action.payload,'query')
  try {

    const data = yield call( Api.purchaseOrderQuotation.update, payload);
    yield put({ type: Types.UPDATE_PURCHASE_ORDER_QUOTATION_SUCCESS, payload: data });
    
  } catch (error) {
    yield put({
      type: Types.UPDATE_PURCHASE_ORDER_QUOTATION_FAILED,
      payload: error
    });
  }
  finally {
    if(payload.status===STATUS_ORDER_QUOTATION.CONFIRMED){
      yield getPurchaseOrderQuotations({payload:query})
    }
  }

}

function* deletePurchaseOrderQuotation({ payload }) {
  try {
    yield call(Api.purchaseOrderQuotation.delete, payload);
    yield put({ type: Types.DELETE_PURCHASE_ORDER_QUOTATION_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PURCHASE_ORDER_QUOTATION_FAILED,
      payload: error
    });
  }
}

export default function* purchaseOrderQuotation() {
  yield takeLatest(Types.GET_PURCHASE_ORDER_QUOTATIONS_REQUEST, getPurchaseOrderQuotations);
  yield takeLatest(Types.GET_PURCHASE_ORDER_QUOTATION_REQUEST, getPurchaseOrderQuotation);
  yield takeLatest(Types.DELETE_PURCHASE_ORDER_QUOTATION_REQUEST, deletePurchaseOrderQuotation);
  yield takeLatest(Types.CREATE_PURCHASE_ORDER_QUOTATION_REQUEST, createPurchaseOrderQuotation);
  yield takeLatest(Types.UPDATE_PURCHASE_ORDER_QUOTATION_REQUEST, updatePurchaseOrderQuotation);
}
