import React, { useState, useMemo } from 'react';
import {
  Table,
  Popconfirm,
  Input,
  Button,
  Checkbox,
  Modal,
  Radio,
  Space,
  Typography,
  Row
} from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE, STATUS, STATUS_NAMES } from '~/constants/defaultValue';

import {
  useManufactures,
  useUpdateManufacture,
  useDeleteManufacture,
  useUpdateManufactureParams,
  useManufactureQueryParams,
  useManufacturePaging,
  useResetManufacture
} from '~/hooks';
import ManufactureForm from './Form';
import { get, omit } from 'lodash';

const { confirm } = Modal;
const { Search } = Input;

const ColumnActions = ({
  _id,
  deleteManufacture,
  onOpenForm,
  isSubmitLoading
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_MANUFACTER}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_MANUFACTER}>
        <WithPermission permission={POLICY.DELETE_MANUFACTER}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_MANUFACTER}>
        <Popconfirm
          title="Bạn muốn xoá hãng sản xuất này này?"
          onConfirm={() => deleteManufacture(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Manufacture = () => {
  const [query] = useManufactureQueryParams();
  const [name, { setKeyword, onParamChange }] = useUpdateManufactureParams(query);
  const [manufacturer, isLoading] = useManufactures(query);
  const paging = useManufacturePaging();
  const [manufactureId, setManufactureId] = useState(null);
  const [isSubmitLoading, updateManufacture] = useUpdateManufacture();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const numberOfMethods = manufacturer?.length;

  const canUpdate = useMatchPolicy(POLICY.UPDATE_MANUFACTER);
  const canDelete = useMatchPolicy(POLICY.DELETE_MANUFACTER);

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfMethods === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteManufacture] = useDeleteManufacture(onDeleteSuccess);
  const onChangeStatus = (record, _id, status, isSubmitLoading) => {
    updateManufacture({
      _id,
      status,
      isSubmitLoading,
      ...omit(record, ['_id', 'status'])
    });
  };

  const onChange = ({ target }) => {
    switch (target.value) {
      case 2:
        onParamChange({ ...query, status: STATUS['ACTIVE'] });
        break;
      case 3:
        onParamChange({ ...query, status: STATUS['INACTIVE'] });
        break;
      default:
        onParamChange({ ...query, status: '' });
        break;
    }
  };

  useResetManufacture();

  const onOpenForm = id => {
    setManufactureId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setManufactureId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên hãng sản xuất',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Mô tả',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: (status, record) => {
        return (
          <Checkbox
            checked={status === STATUS.ACTIVE}
            onChange={e => {
              return onChangeStatus(
                record,
                get(record, '_id'),
                e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE,
                isSubmitLoading
              );
            }}
          />
        );
      }
    },
    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '110px',
            render: record => {
              return (
                <ColumnActions
                  {...record}
                  deleteManufacture={deleteManufacture}
                  onOpenForm={onOpenForm}
                  // isSubmitLoading={isSubmitLoading}
                />
              );
            }
          }
        ]
      : [])
  ];
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Hãng sản xuất</h1>

          <div className="wc-page-header__search wclinic-search">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm hãng sản xuất"
              enterButton
              onSearch={() => onParamChange({ name, page: 1 })}
              onChange={e => setKeyword(e.target.value)}
              value={name}
              allowClear
            />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_MANUFACTER}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => onOpenForm()}
              >
                Thêm mới
              </Button>
            </WithPermission>
          </div>
        </div>

        <Space style={{ marginBottom: 20 }}>
          <Typography style={{ fontSize: 18, marginRight: 20 }}>
            Phân loại trạng thái theo :
          </Typography>
          <Row gutter={20}>
            <Radio.Group
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={(() => {
                switch (query?.status) {
                  case 'ACTIVE':
                    return 2;
                  case 'INACTIVE':
                    return 3;
                  default:
                    return 1;
                }
              })()}
            >
              <Radio.Button value={1}>Tất cả</Radio.Button>
              <Radio.Button value={2}>{STATUS_NAMES['ACTIVE']}</Radio.Button>
              <Radio.Button value={3}>{STATUS_NAMES['INACTIVE']}</Radio.Button>
            </Radio.Group>
          </Row>
        </Space>
        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={manufacturer.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={manufacturer}
                onChange={({ current, pageSize }) =>
                  onParamChange({ page: current, limit: pageSize })
                }
                pagination={{
                  ...paging,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100', paging?.total],
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <ManufactureForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        _id={manufactureId}
        updateManufacture={updateManufacture}
      />
    </div>
  );
};

export default Manufacture;
