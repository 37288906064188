import { useMemo, useEffect, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getWarehouses,
  createWarehouse,
  deleteWarehouse,
  getWarehouse,
  resetWarehouseState,
  updateWarehouse
} from '~/redux/action';
import { Menu, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import currencyFormatter from '~/utils/currencyFormatter';
import { formatNumber } from '~/utils/helper';
import { DISCOUNT_TYPES } from '~/constants/defaultValue';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import {
  caculateDiscountValueOnTypeChange,
  getPriceAfterDiscount,
  getTotalQuantity
} from '~/utils/caculator';
import { get, keyBy } from 'lodash';
import DiscountInput from '~/components/Common/DiscountInput';

const WAREHOUSE_MODULE = 'warehouse';

const CREATE_SUCCESS_MESS = 'Tạo mới đơn nhập hàng thành công';
const CREATE_FAILED_MESS = 'Tạo mới đơn nhập hàng thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật đơn nhập hàng thành công';
const UPDATE_FAILED_MESS = 'Cập nhật đơn nhập hàng thất bại';
const DELETE_SUCCESS_MESS = 'Xoá đơn nhập hàng thành công';
const DELETE_FAILED_MESS = 'Xoá đơn nhập hàng thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector,
  summarySelector
} = getSelectors(WAREHOUSE_MODULE);

export const useWarehouses = query => {
  return useFetchByParam({
    action: getWarehouses,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateWarehouse = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWarehouse
  });
};

export const useUpdateWarehouse = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWarehouse
  });
};

export const useDeleteWarehouse = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteWarehouse
  });
};

export const useWarehouse = params => {
  return useFetchByParam({
    action: getWarehouse,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitWarehouse = ({ warehouse, updatingId, setFormData }) => {
  return useEffect(() => {
    if (!warehouse || !updatingId) {
      return {};
    }
    // console.log(warehouse,'warehouse')
    const onInitMultipleVariant = purchaseOrderItems => {
      const addingVariants = purchaseOrderItems.map(variant => {
        const {
          discountValue,
          discountType,
          discountPercent,
          fullBatches
        } = variant;

        const batchDict = keyBy(fullBatches, '_id');
        const selectedBatches = variant.batches.map(batch => ({
          ...batch,
          lotNumber: batchDict[batch.batchId]?.lotNumber,
          expirationDate: batchDict[batch.batchId]?.expirationDate,
          manufacturingDate: batchDict[batch.batchId]?.manufacturingDate
        }));

        const quantity = getTotalQuantity(variant.batches);
        return {
          ...variant,
          _id: variant.variantId || variant._id,
          selectedBatches: selectedBatches,
          quantity,
          discount: {
            type: discountType,
            value:
              discountType === DISCOUNT_TYPES.VALUE
                ? discountValue
                : discountPercent
          },
          variantCode: variant.productVariant?.variantCode,
          batches: fullBatches
        };
      });

      setFormData(formData => [...addingVariants, ...formData]);
    };

    onInitMultipleVariant(warehouse.purchaseOrderItems);
  }, [warehouse, updatingId, setFormData]);
};

export const useUpdateWarehouseParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/warehouse',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          id: undefined
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWarehouseQueryParams = () => {
  // const prevKeyword = useRef(null);
  const query = useQueryParams();
  let limit = query.get('limit')||10;
  const getQuery=(key)=>query.get(key)
  let keyword = getQuery('keyword')
  let page = getQuery('page');
  let status = getQuery('status');  
  let id = getQuery('id');
  let code =  getQuery('code')
  let variantCodeProductName =  getQuery('variantCodeProductName')
  let supplierName =  getQuery('supplierName')
  let lotNumber =  getQuery('lotNumber')
  let expirationDate =  getQuery('expirationDate')
  let purchasedAt =  getQuery('purchasedAt')
  let deleteSuccess = useSelector(deleteSuccessSelector);


  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      code,
      variantCodeProductName,
      supplierName,
      lotNumber,
      expirationDate,
      purchasedAt,
      id,
      status
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page, 
    limit, 
    keyword, 
    deleteSuccess, 
    id,
    code, 
    status,
    variantCodeProductName,
    supplierName,
    lotNumber,
    expirationDate,
    purchasedAt 
  ]);
};

export const useWarehousePaging = () => useSelector(pagingSelector);

export const useResetWarehouse = () => {
  useResetState(resetWarehouseState);
};
export const useSummaryWarehouse = () => {
 const summary = useSelector(summarySelector)
 const result =[{
  value:summary?.debt ??0,
  title:"Cần trả nhà cung cấp",
  type:"green"
 }] 
 return [result]
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã nhập hàng',
    key: 'code'
  },
  {
    title: 'Mã nhập hàng từ World Pharma',
    key: 'billCodeSequence'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Nhà cung cấp',
    key: 'supplier'
  },
  {
    title: 'Cần trả NCC',
    key: 'debt'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  },
  {
    title: 'Thao tác',
    key: 'action'
  }
];

export const useWarehouseColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    billCodeSequence: true,
    time: true,
    supplier: true,
    debt: true,
    status: true,
    action: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

export const useWarehouseColumns = (setFormData, isShow) => {
  const columns = useMemo(() => {
    const onDeleteVariant = variant => {
      setFormData(variants =>
        variants.filter(({ variantId }) => variantId !== variant.variantId)
      );
    };

    const onDiscountTypeChange = (updatedIndex, type) => {
      setFormData(formData =>
        formData.map((item, index) => {
          if (index !== updatedIndex) {
            return item;
          }

          const { discount, price } = formData[index];

          const discountValue = caculateDiscountValueOnTypeChange({
            type,
            value: discount.value,
            price
          });

          return {
            ...item,
            discount: {
              type,
              value: discountValue
            }
          };
        })
      );
    };

    const onDiscountValueChange = (updatedIndex, value) => {
      setFormData(formData =>
        formData.map((item, index) => {
          if (index !== updatedIndex) {
            return item;
          }

          return {
            ...item,
            discount: {
              ...item.discount,
              value
            }
          };
        })
      );
    };

    const onFieldChange = (fieldName, value, updatedIndex) => {
      setFormData(formData =>
        formData.map((item, index) => {
          return index === updatedIndex
            ? {
                ...item,
                [fieldName]: value,
                selectedBatches:
                  fieldName === 'quantity'
                    ? [{ ...item.selectedBatches[0], quantity: value }]
                    : item.selectedBatches
              }
            : item;
        })
      );
    };

    return [
      {
        title: '',
        key: 'delete',
        align: 'center',
        render: rc => (
          <Button
            onClick={() => onDeleteVariant(rc)}
            size="small"
            shape="circle"
            className="warehouse-form__delete-btn"
            icon={<DeleteOutlined />}
          ></Button>
        )
      },
      {
        title: 'STT',
        key: 'STT',
        align: 'center',
        render: (_, x, index) => index + 1
      },
      {
        title: 'Mã hàng',
        key: 'variantCode',
        dataIndex: 'variantCode'
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['product', 'name']
      },
      {
        title: 'Đơn vị tính',
        key: 'productVariant',
        dataIndex: ['productVariant', 'unit','name'],
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        render: ({ quantity, selectedBatches }, _, index) => (
          <InputNumber
            size="small"
            className="warehouse-form__quantity"
            value={quantity}
            onChange={value => onFieldChange('quantity', value, index)}
            bordered={false}
            disabled={selectedBatches.length !== 1}
          />
        )
      },
      ...(isShow?[{
        title: 'Đơn giá',
        key: 'cost',

        render: ({ productVariant,cost}, _, index) => (
          <InputNumber
            size="small"
            value={get( productVariant,"cost" ,cost??0) }
            bordered={false}
            onChange={value => onFieldChange('cost', value, index)}
            {...currencyFormatter}
          />
        )
      }]:[]),
      ...(isShow?[{
        title: 'Giảm giá',
        key: 'discount',

        render: ({ discount, productVariant ,cost}, _, index) => (
          <DiscountInput
            discount={discount}
            onTypeChange={value => onDiscountTypeChange(index, value)}
            onValueChange={value => onDiscountValueChange(index, value)}
            max={discount.type === DISCOUNT_TYPES.PERCENT ? 100 : get(productVariant,"cost",cost??0)}
          >
            <div className="warehouse-form-table__discount warehouse-form-table__discount--width-auto">
              {discount.type === DISCOUNT_TYPES.VALUE
                ? formatNumber(discount.value)
                : formatNumber((discount.value / 100) * get(productVariant,"cost",0))}
            </div>
          </DiscountInput>
        )
      }]:[]),
      ...(isShow?[{
        title: 'Thành tiền',
        key: 'amount',
        align: 'end',
        render: ({ quantity, productVariant,cost, discount }) =>
          formatNumber(quantity * getPriceAfterDiscount(get(productVariant,"cost",cost??0), discount))
      }]:[]),
    ];
  }, [setFormData, isShow]);

  return columns;
};
export const SEARCH_PURCHASE_ORDER=[
  {
      name: "code",
      // label: "Mã kiểm kho",
      placeholder:"Theo mã phiếu nhập"
  },
  {
      name: "variantCodeProductName",
      // label: "Ngày cân bằng",
      placeholder:"Theo mã, tên hàng"
  },
  {
      name: "supplierName",
      placeholder:"Tên NCC"
  },
  {
      name: "lotNumber",
      placeholder:"Theo lô"
  },
  {
      name: "purchasedAt",
      type: "rangerTime",
      label:"Thời gian",
      placeholder:"Theo lô"
  },
  {
      name: "status",
      type: "radio",
      label: "Trạng thái",
  }    
]

