import * as Types from '~/constants/actionTypes';

export const getPurchaseReturns = query => ({
  type: Types.GET_PURCHASE_RETURNS_REQUEST,
  payload: query
});

export const getPurchaseReturn = id => ({
  type: Types.GET_PURCHASE_RETURN_REQUEST,
  payload: id
});

export const createPurchaseReturn = purchase => ({
  type: Types.CREATE_PURCHASE_RETURN_REQUEST,
  payload: purchase
});

export const updatePurchaseReturn = purchase => ({
  type: Types.UPDATE_PURCHASE_RETURN_REQUEST,
  payload: purchase
});

export const deletePurchaseReturn = id => ({
  type: Types.DELETE_PURCHASE_RETURN_REQUEST,
  payload: id
});

export const resetPurchaseReturnState = () => ({
  type: Types.RESET_PURCHASE_RETURN_STATE
});
