import requester from './requester';

const processImport = {
  getAll: query => requester.get('/process-import-list', query),
  getById: (id) => requester.get(`/process-import/${id}`),
  update: query => requester.put(`/process-import/${query._id}`, query),
  delete: id => requester.delete(`/process-import/${id}`)
};

export default processImport;
