export const GET_PARTNERSHIPS_REQUEST = 'GET_PARTNERSHIPS_REQUEST';
export const GET_PARTNERSHIPS_SUCCESS = 'GET_PARTNERSHIPS_SUCCESS';
export const GET_PARTNERSHIPS_FAILED = 'GET_PARTNERSHIPS_FAILED';

export const GET_PARTNERSHIP_REQUEST = 'GET_PARTNERSHIP_REQUEST';
export const GET_PARTNERSHIP_SUCCESS = 'GET_PARTNERSHIP_SUCCESS';
export const GET_PARTNERSHIP_FAILED = 'GET_PARTNERSHIP_FAILED';

export const CREATE_PARTNERSHIP_REQUEST = 'CREATE_PARTNERSHIP_REQUEST';
export const CREATE_PARTNERSHIP_SUCCESS = 'CREATE_PARTNERSHIP_SUCCESS';
export const CREATE_PARTNERSHIP_FAILED = 'CREATE_PARTNERSHIP_FAILED';

export const UPDATE_PARTNERSHIP_REQUEST = 'UPDATE_PARTNERSHIP_REQUEST';
export const UPDATE_PARTNERSHIP_SUCCESS = 'UPDATE_PARTNERSHIP_SUCCESS';
export const UPDATE_PARTNERSHIP_FAILED = 'UPDATE_PARTNERSHIP_FAILED';

export const DELETE_PARTNERSHIP_REQUEST = 'DELETE_PARTNERSHIP_REQUEST';
export const DELETE_PARTNERSHIP_SUCCESS = 'DELETE_PARTNERSHIP_SUCCESS';
export const DELETE_PARTNERSHIP_FAILED = 'DELETE_PARTNERSHIP_FAILED';

export const RESET_PARTNERSHIP_STATE = 'RESET_PARTNERSHIP_STATE';
