export const validatePrice = {
  validator(_, value) {
    if (!value || value <= 9999999999) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Tối đa 10 ký tự'));
  }
};
export const onSelectRow = (func) => (item)=>({
    onClick: (e) => {
      // e.preventDefault()
      func((v)=>{ 
        return String(v).includes(item._id) ? null : String(item._id) })}
  })
