import * as Types from '~/constants/actionTypes';

export const getMedicineMethods = query => ({
  type: Types.GET_MEDICINE_METHODS_REQUEST,
  payload: query
});

export const getMedicineMethod = id => ({
  type: Types.GET_MEDICINE_METHOD_REQUEST,
  payload: id
});

export const createMedicineMethod = method => ({
  type: Types.CREATE_MEDICINE_METHOD_REQUEST,
  payload: method
});

export const updateMedicineMethod = method => ({
  type: Types.UPDATE_MEDICINE_METHOD_REQUEST,
  payload: method
});

export const deleteMedicineMethod = id => ({
  type: Types.DELETE_MEDICINE_METHOD_REQUEST,
  payload: id
});

export const resetMedicineMethodState = () => ({
  type: Types.RESET_MEDICINE_METHOD_STATE
});
