import * as Types from '~/constants/actionTypes';

export const getFormPrints = query => {
  
  return {
    type: Types.GET_FORM_PRINTS_REQUEST,
    payload: query
  };
};

export const getFormPrint = id => ({
  type: Types.GET_FORM_PRINT_REQUEST,
  payload: id
});

export const createFormPrint = id => ({
  type: Types.CREATE_FORM_PRINT_REQUEST,
  payload: id
});

export const updateFormPrint = id => ({
  type: Types.UPDATE_FORM_PRINT_REQUEST,
  payload: id
});

export const deleteFormPrint = id => ({
  type: Types.DELETE_FORM_PRINT_REQUEST,
  payload: id
});

export const resetFormPrintState = () => ({
  type: Types.RESET_FORM_PRINT_STATE
});
