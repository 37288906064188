import * as Types from '~/constants/actionTypes';

export const getBatches = variantId => ({
  type: Types.GET_BATCHES_REQUEST,
  payload: variantId
});

export const createBatch = batch => ({
  type: Types.CREATE_BATCH_REQUEST,
  payload: batch
});

export const resetBatchState = () => ({
  type: Types.RESET_BATCH_STATE
});

export const updateBatchByOrder = (batch) => ({
  type: Types.UPDATE_BATCH_BY_ORDER_REQUEST,
  payload: batch
});
