import requester, { mockApi } from './requester';
import { BASE_URL } from '../../constants/defaultValue';
import { get, omit } from 'lodash';

const MEDICINE_OPTIONS = [
  {
    _id: 1,
    name: 'Hoạt huyết dưỡng não',
    aliasName: 'HHDN',
    medicineCode: 'MHH-1',
    cost: 1000,
    price: 120000,
    registrationNo: '012345',
    dosage: 'Vancomycin (dưới dạng Vancomycin HCl) 500mg',
    ingredient: '123123',
    packagingSize: 'Hộp 1 lọ, 10 lọ, 50 lọ',
    brand: 'Công ty cổ phần thực phẩm Trung Ương 1',
    country: 'Việt nam',
    countryId: 1,
    manufactureId: 1,
    type: 'Thuốc',
    remain: 100,
    unit: 'Viên',
    parcels: [{ name: '55 - 28/11/2021' }],
    code: 'SP000001',
    quantity: 1
  },
  {
    _id: 2,
    name: 'Sâm nhung bổ thận',
    aliasName: 'SNBT',
    medicineCode: 'MHH-2',
    cost: 2000,
    price: 220000,
    registrationNo: '6666666',
    dosage: 'Vancomycin (dưới dạng Vancomycin HCl) 1000mg',
    ingredient: '456456',
    packagingSize: 'Hộp 1 lọ, 10 lọ, 50 lọ',
    brand: 'Công ty cổ phần thực phẩm Trung Ương 2',
    country: 'Việt nam',
    countryId: 2,
    manufactureId: 2,
    type: 'Thuốc',
    remain: 200,
    unit: 'Viên',
    parcels: [{ name: '32 - 18/11/2021' }],
    code: 'SP000002',
    quantity: 1
  },
  {
    _id: 3,
    name: 'Sâm Alipas',
    aliasName: 'Alipas',
    medicineCode: 'MHH-3',
    cost: 3000,
    price: 300200,
    registrationNo: '555555',
    dosage: 'Vancomycin (dưới dạng Vancomycin HCl) 1000mg',
    ingredient: '555555',
    packagingSize: 'Hộp 1 lọ, 10 lọ, 50 lọ',
    brand: 'Công ty cổ phần thực phẩm Trung Ương 2',
    country: 'Việt nam',
    countryId: 3,
    manufactureId: 3,
    type: 'Thuốc',
    remain: 350,
    unit: 'Viên',
    parcels: [{ name: '32 - 18/11/2021' }],
    code: 'SP000003',
    quantity: 1
  }
];

const medicine = {
  getAllMock: () => mockApi.request(MEDICINE_OPTIONS),

  getAll: (query) =>
    requester.get(`${BASE_URL}/api/v2/product`, query),
    
  getAllByParams: (query) =>
    requester.get(`${BASE_URL}/api/v3/product`, query),

  getById: id =>
    requester.get(`${BASE_URL}/api/v2/product/${id}`),

  getOptions: keyword => requester.get('/medicine/search', { keyword }),

  create: medicine =>
    requester.post(`${BASE_URL}/api/v2/product`, medicine),

  getCountries: () => requester.get('/country'),

  delete: id =>
    requester.delete(`${BASE_URL}/api/v2/product/${id}`),

  update: product =>
    requester.put(
      `${BASE_URL}/api/v2/product/${product._id}`,
      product
    ),

  searchProducts: keyword =>
    requester.get(`${BASE_URL}/api/v3/product-variant/searchV3`, { keyword }),

  searchVariantsByProduct: productId =>
    requester.get(`/product-variant/product-id/${productId}`),

  getStock: id =>
    requester.get(
      `${BASE_URL}/api/v3/product-variant/stock/${id}`
    ),

  getStockCard: id =>
    requester.get(
      `${BASE_URL}/api/v1/product/${id}/history?populate=true`
    ),

  getReferenceDocsStockCard: params =>
    requester.get(
      `${BASE_URL}/api/v1/inventory-transaction/reference-doc/${params.id}?transactionType=${params.type}`
    ),

  getLot: query =>
    requester.get(
      `${BASE_URL}/api/v3/product-variant/lot/${query.id}`,query
    ),

  getPartner: partnerId =>
    requester.get(
      `${BASE_URL}/api/v1/partner/${partnerId}`
    ),
  getProductCardStockOutside: params =>
    requester.get(
    `${BASE_URL}/api/v2/product/${params.id}/history`,params
  ),
  submitDrugRequest: params =>
    requester.post(
    `${BASE_URL}/api/v1/drug-administ-request/create/${params.action}/${params.id}`,omit(params,['id','action'])
  ),
  submitTryDrugRequest: params =>requester.post(`${BASE_URL}/api/v1/drug-administ-request/${params}`),
  getProductDrugRequest: params => requester.get(`${BASE_URL}/api/v1/drug-administ-request/${get(params,'id')}`),
};

export default medicine;
