import requester from '../requester';

const branch = {
  getAll: (query) => requester.get('/branch_v2', query),
  getById: (id) => requester.get(`/branch/${id}?raw=true`),
  create: (branch) => requester.post('/branch', branch),
  update: (branch) => requester.put(`/branch/${branch._id}`, branch),
  // updateApiKey: (query) => requester.put(`/branch/${query.branchId}/apikey`, query),
  delete: (id) => requester.delete(`/branch/${id}`),
  getApiKey: (branchId) => requester.get(`/apikey/${branchId.branchId}`),
  updateApiKey: (query) =>  requester.put(`/apikey/${query.branchId}`, { apiKey: query.apiKey }),
  deleteApiKey: (data) =>  requester.delete(`/apikey/${data?.type}/${data?.id}`),
};

export default branch;
