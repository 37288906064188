import { useState } from 'react';
import { Modal, Form } from 'antd';
import { DebounceSelect } from '~/components/Common';
import Api from '~/api';

const OrderAssign = ({ visible, onSubmit, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const submitHandle = async (values) => {
    setLoading(true);
    const submitData = { branchId: values.branchId.value };
    await onSubmit(submitData);
    onClose();
  };

  return (
    <Modal
      title="Phân bổ đơn hàng cho nhà thuốc"
      onOk={() => form.submit()}
      visible={visible}
      onCancel={onClose}
      okText="Lưu"
      cancelText="Hủy bỏ"
      confirmLoading={loading}
    >
      <Form
        form={form}
        name="assign-form"
        layout="vertical"
        onFinish={submitHandle}
      >
        <Form.Item
          label="Nhà thuốc"
          name="branchId"
          rules={[{ required: true, message: 'Vui lòng chọn nhà thuốc' }]}
        >
          <DebounceSelect
            allowClear
            fetchOptions={Api.order.getPharmacies}
            valueKey="companyId"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderAssign;
