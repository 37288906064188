import requester from '../requester';

const hospital = {
  getHospitals: query => requester.get('/clinic', query),
  getById: id => requester.get(`/clinic/${id}?raw=true`),
  delete: id => requester.delete(`/clinic/${id}`),
  create: hospital => requester.post('/clinic', hospital),
  update: hospital => requester.put(`/clinic/${hospital.id}`, hospital),
  getAvailableTime: id => requester.get(`/clinic/${id}/available-slot`),
  getAll: () => requester.get('/clinic')
};

export default hospital;
