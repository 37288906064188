import requester from './requester';

const group = {
  getAll: query => requester.get(`/supplier-group`, query),
  getById: id => requester.get(`/supplier-group/${id}`),
  create: group => requester.post('/supplier-group', group),
  update: group => requester.put(`/supplier-group/${group._id}`, group),
  delete: id => requester.delete(`/supplier-group/${id}`)
};

export default group;
