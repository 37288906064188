import * as Types from '~/constants/actionTypes';

export const getMedicineLocations = query => ({
  type: Types.GET_MEDICINE_LOCATIONS_REQUEST,
  payload: query
});

export const getMedicineLocation = id => ({
  type: Types.GET_MEDICINE_LOCATION_REQUEST,
  payload: id
});

export const createMedicineLocation = location => ({
  type: Types.CREATE_MEDICINE_LOCATION_REQUEST,
  payload: location
});

export const updateMedicineLocation = location => ({
  type: Types.UPDATE_MEDICINE_LOCATION_REQUEST,
  payload: location
});

export const deleteMedicineLocation = id => ({
  type: Types.DELETE_MEDICINE_LOCATION_REQUEST,
  payload: id
});

export const resetMedicineLocationState = () => ({
  type: Types.RESET_MEDICINE_LOCATION_STATE
});
