import { Row } from 'antd'
import React from 'react'
import { formatNumber } from '~/utils/helper'

function SummaryReturn({
  totalItemsReturn,
  totalAmountReturn,
  selectedSale,
  setIsPaymentHistoryOpen
}) {
  return (
    <>
      <Row
        className="warehouse-form__row"
        style={{
          marginTop: 0,
          fontSize: '14px',
          fontWeight: '500',
          background: '#eff2f5',
          padding: '5px 10px'
        }}
      >
        Trả hàng
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Tổng tiền hàng:
          <span className="sale__total-product">{totalItemsReturn}</span>
        </label>
        <label>
          <label>{formatNumber(totalAmountReturn)}</label>
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Giảm giá:
          <span className="sale__total-product">{totalItemsReturn}</span>
        </label>
        <label>
          {formatNumber(selectedSale?.summary?.discount?.value || 0)}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Khách cần trả:
          <span className="sale__total-product">{totalItemsReturn}</span>
        </label>
        <label>
          {formatNumber(
            totalAmountReturn - (selectedSale?.summary?.discount?.value || 0)
          )}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label className="fw-600">Khách đã trả: </label>{' '}
        <label
          style={{ cursor: 'pointer', color: 'rgb(45, 183, 245)' }}
          onClick={() => setIsPaymentHistoryOpen(true)}
        >
          {formatNumber(selectedSale?.paid)}
        </label>
      </Row>
    </>
  );
}

export default SummaryReturn;
