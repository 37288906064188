import * as Types from '~/constants/actionTypes';

export const getOverViewReport = () => ({
  type: Types.GET_OVER_VIEW_REPORT_REQUEST
});

export const getMonthlyReport = () => ({
  type: Types.GET_MONTHLY_REPORT_REQUEST
});

export const getDailyReport = () => ({
  type: Types.GET_DAILY_REPORT_REQUEST
});

export const resetDashboardState = () => ({
  type: Types.RESET_DASHBOARD_STATE
});
