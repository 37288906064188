import React, { useState } from 'react';
import { useProductReport } from '~/hooks';
import PopoverNearDueReport from './PopoverNearDue';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  Table,
  Button,
  Radio,
  Space,
  Collapse,
  Input,
  DatePicker
} from 'antd';

import {
  FileTextOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { PRODUCT_REPORT_POPOVER_ITEM } from '~/constants/defaultValue';
import moment from 'moment';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import './index.scss'

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
const dateFormat2 = 'YYYY/MM/DD';
const dateNow = moment();
const dateNowFormat = dateNow.format(dateFormat);
const timeExpireValue = 'timeExpire';

const showStyle = [{
  title: 'Kiểu hiển thị',
  types: [
    {
      text: 'Báo cáo',
      value: 'report',
    }
  ]
}];

const relationInfo = [{
  title: 'Mối quan tâm',
  types: [
    {
      text: 'Lợi nhuận',
      value: 'ProductBySale',
    },
    {
      text: 'Bán hàng',
      value: 'ProductByProfit',
    },
    {
      text: 'Giá trị kho',
      value: 'ProducStockInOutStock',
    },
    {
      text: 'Xuất nhập tồn',
      value: 'ProducInOutStock',
    },
    {
      text: 'Xuất nhập tồn chi tiết',
      value: 'ProducInOutStockDetail',
    },
    {
      text: 'Xuất hủy',
      value: 'ProductByDamageItem',
    },
    {
      text: 'Nhân viên theo hàng bán',
      value: 'ProductByUser',
    },
    {
      text: 'Khách theo hàng bán',
      value: 'ProductByCustomer',
    },
    {
      text: 'NCC theo hàng nhập',
      value: 'ProductBySupplier',
    },
    {
      text: 'Hạn sử dụng',
      value: 'ProductByBatchExpire',
    }
  ]
}];

const groupProduct = [
  {
    label: 'Tất cả',
    value: 'all',
    active: true
  },
  {
    label: 'Thực phẩm chức năng',
    value: ''
  },
  {
    label: 'Dụng Cụ Y Tế',
    value: ''
  },
  {
    label: 'Thuốc chống viên',
    value: ''
  },
  {
    label: 'Thuốc dị ứng',
    value: ''
  },
  {
    label: 'Thuốc điều trị viêm xương, khớp',
    value: ''
  },
  {
    label: 'Dịch vụ làm thêm',
    value: ''
  }
];

function useQuery() {
  const { search } = useLocation();

  return new URLSearchParams(search).get('outdatedProductTimeRange');
}

const showDateRange = ({ endDate, isTimeExpireActive, dates }) => {
  let dateRangeText = '';
  if (isTimeExpireActive) {
    const startDate = dateNowFormat;

    if (endDate) {
      const newEndDate = JSON.parse(endDate);
      if (newEndDate.allTime) {
        dateRangeText= 'Toàn thời gian';
      } else if (newEndDate.expired) {
        dateRangeText= `đến ngày ${startDate}`;
      } else {
        dateRangeText = `Từ ngày ${startDate} đến ngày ${newEndDate.endDate}`;
      }
    }
  } else {
    const startDate = (dates && dates.length === 2 ? dates[0] : dateNow).format(dateFormat)
    const endDate = (dates && dates.length === 2 ? dates[1] : dateNow).format(dateFormat)
    dateRangeText = `Từ ngày ${startDate} đến ngày ${endDate}`;
  }

  return dateRangeText
};

const getEndDate = (value) => {
  let endDate;
  let allTime;
  let expired;

  switch(value) {
    case 'TODAY':
      endDate = moment();
      break;
    case 'THISWEEK':
      endDate = moment().day(0);
      break;
    case 'THISMONTH':
      endDate = moment().endOf('month');
      break;
    case 'ALLTIME':
      allTime = true;
      break;
    case '1MONTH':
      endDate = moment().add(1, 'months');
      break;
    case '2MONTH':
      endDate = moment().add(2, 'months');
      break;
    case '3MONTH':
      endDate = moment().add(3, 'months');
      break;
    case '6MONTH':
      endDate = moment().add(6, 'months');
      break;
    default:
      expired = true;
  }

  return JSON.stringify({
    endDate: endDate && endDate.format('DD/MM/YYYY'),
    allTime,
    expired
  });
};

const sortNearDueReports = (data = []) => {
  if (!data || !data.length) {
    return null;
  }

  let newData = [];
  const dataNearDue = data.filter(item => item.remainingDays > 0);
  const dataExpired = data.filter(item => item.remainingDays <= 0);

  if (dataNearDue) {
    newData = [...newData, ...(dataNearDue.slice().sort((a, b) => a.remainingDays - b.remainingDays))];
  }

  if (dataExpired) {
    newData = [...newData, ...(dataExpired.slice().sort((a, b) => b.remainingDays - a.remainingDays))];
  }

  return newData;
};

const expireInDefault = PRODUCT_REPORT_POPOVER_ITEM.find(
  (item) => item.value === 'THISWEEK').value

const ProductReport = () => {
  const outdatedProductTimeRange = useQuery();
  const expireInByParam = PRODUCT_REPORT_POPOVER_ITEM.find(
    (item) => item.params === outdatedProductTimeRange);

  const [expireIn, setExpireIn] = useState((expireInByParam && expireInByParam.value) ?? expireInDefault);
  const [openPopover, setOpenPopover] = useState(false);
  const [endDate, setEndDate] = useState(expireIn);
  const [dates, setDates] = useState(null);
  const [isTimeExpireActive, setTimeExpireActive] = useState(true);
  const [nameOrCodeProductValue, setNameOrCodeProductValue] = useState('');

  // Other Near due product
  const [valueShowStyle, setValueShowStyle] = useState('report');
  const [valueRelationInfo, setValueRelationInfo] = useState('ProductBySale');
  const [branch, ] = useState('Tất cả');

  // hooks api
  const [dataNearDueProducts, ] = useProductReport(
    isTimeExpireActive ? { expireIn, keyword: nameOrCodeProductValue } : {
      startDate: (dates && dates.length === 2 ? dates[0] : dateNow).format(dateFormat2),
      endDate: (dates && dates.length === 2 ? dates[1] : dateNow).format(dateFormat2),
      keyword: nameOrCodeProductValue,
    }
  );

  const onChangeShowStyle = (e) => {
    setValueShowStyle(e.target.value);
  };

  const onChangeRelationInfo = (e) => {
    setValueRelationInfo(e.target.value)
  };

  const onChangeTime = (e) => {
    if (e.target.value === timeExpireValue) {
      setTimeExpireActive(true);
    } else {
      setTimeExpireActive(false);
    }
  };

  const handlePrintFile = () => {};

  const handleOnChangeGroupProduct = () => {};

  const columns = React.useMemo(() => {
    return  [
      {
        title: 'Mã hàng',
        key: 'productCode',
        dataIndex: ['productId', 'batchId', 'branchId'],
        render: (_, records) => {
          const batchIdByBranchId = records.productVariant 

          return batchIdByBranchId?.variantCode;
        },
        width: '140px'
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['productId', 'batchId', 'branchId'],
        render: (_, records) => {
          const batchIdByBranchId = records?.productId?.find(record => record.branchId === records.branchId);

          return batchIdByBranchId?.name + ` ( ${records.productVariant.unitId.name} )`;
        }
      },
      {
        title: 'Lô',
        key: 'lotNumber',
        dataIndex: ['productId', 'batchId', 'branchId'],
        render: (_, records) => {
          const batchIdByBranchId = records?.batchId?.find(record => record.branchId === records.branchId);

          return batchIdByBranchId?.lotNumber;
        }
      },
      {
        title: 'Hạn sử dụng',
        key: 'expirationDate',
        dataIndex: ['productId', 'batchId', 'branchId'],
        align: 'center',
        render:  (_, records) => {
          const batchIdByBranchId = records.batchId 

          return moment(batchIdByBranchId?.expirationDate).format(dateFormat);
        },
        width: '120px'
      },
      {
        title: 'Số ngày còn lại',
        key: 'remainingDays',
        dataIndex: 'remainingDays',
        align: 'center',
        width: '135px'
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: ['productId', 'batchId', 'branchId'],
        render: (_, records) => {
          const batchIdByBranchId = records.batchId 

          return batchIdByBranchId?.quantity;
        },
        align: 'center',
        width: '100px'
      }
    ];
  }, []);

  const onChangeNameOrCodeProduct = (e) => {
    setNameOrCodeProductValue(e.target.value);
  };

  return (
    <div className="page-wraper page-content product-report">
      <div className="container-fluid">
        <Row>
          <Col xs="2">
            <aside>
              <h1 className="product-report__title">Báo cáo hàng hóa</h1>
              <WithPermission permission={POLICY.WRITE_REPORT}>
                <div className="product-report__box print-all-report">
                  <Button type="primary" icon={<FileTextOutlined />} size={'large'} onClick={handlePrintFile}>
                    Xuất tất cả
                  </Button>
                </div>
              </WithPermission>

              {showStyle?.map((radio, index) => (
                <div className="product-report__box" key={index}>
                  <h3>{radio.title}</h3>
                  <Radio.Group onChange={onChangeShowStyle} value={valueShowStyle}>
                    <Space direction="vertical" size={'middle'}>
                      {radio.types?.map((type, i) => (
                        <Radio value={type.value} key={index + i}>{type.text}</Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </div>
              ))}

              {relationInfo?.map((radio, index) => (
                <div className="product-report__box" key={index} style={{paddingBottom: '20px'}}>
                  <h3>{radio.title}</h3>
                  <Radio.Group onChange={onChangeRelationInfo} value={valueRelationInfo}>
                    <Space direction="vertical" size={'middle'}>
                      {radio.types?.map((type, i) => (
                        <Radio value={type.value} key={index + i}>{type.text}</Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </div>
              ))}

              <div className="product-report__box" style={{paddingBottom: '20px'}}>
                <h3>Thời gian</h3>

                <div className={'product-report__datetime'}>
                  <div className={'product-report__input-group'}>
                    <input
                      checked={isTimeExpireActive}
                      id="reportTimeExpire"
                      type="radio"
                      value={'timeExpire'}
                      name="reportTime"
                      onChange={onChangeTime}
                    />
                    <label htmlFor="reportTimeExpire" className="ant-radio-inner" />
                  </div>
                  <div className={'product-report__input-label is-popover'}>
                    <PopoverNearDueReport
                      setExpireIn={setExpireIn}
                      open={openPopover}
                      setOpen={setOpenPopover}
                      durationValue={
                        PRODUCT_REPORT_POPOVER_ITEM.find((item) => item.value === expireIn).text
                      }
                      position={'right'}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className={'product-report__datetime'}>
                  <div className={'product-report__input-group'}>
                    <input
                      checked={!isTimeExpireActive}
                      id="reportTimeDatetime"
                      type="radio"
                      value="otherTime"
                      name="reportTime"
                      onChange={onChangeTime}
                    />
                    <label htmlFor="reportTimeDatetime" className="ant-radio-inner" />
                  </div>
                  <div className="product-report__input-label">
                    <div
                      style={{
                        flex: '1 1 auto'
                      }}
                      className={!isTimeExpireActive ? 'hidden' : ''}
                    >
                      Lựa chọn khác
                    </div>
                    <RangePicker
                      className={'product-report__datatime-picker ' + (!isTimeExpireActive ? '' : 'isHidden')}
                      defaultValue={[moment(dateNowFormat, dateFormat), moment(dateNowFormat, dateFormat)]}
                      allowClear={false}
                      onCalendarChange={(val) => setDates(val)}
                    />
                  </div>

                </div>
              </div>

              <div className="product-report__box">
                <Collapse>
                  <Panel header="Hàng hóa" key="1">
                    <Input
                      placeholder="Theo tên hoặc mã hàng"
                      type={'text'}
                      onChange={(e) => onChangeNameOrCodeProduct(e)}
                    />
                  </Panel>
                </Collapse>
              </div>

              <div className="product-report__box">
                <Collapse>
                  <Panel header="Nhóm hàng" key="1">
                    <Input placeholder="Tìm kiếm nhóm hàng" type={'text'} prefix={<SearchOutlined />}/>

                    <ul className="product-report__group-product">
                      {groupProduct.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className={item.active ? 'active' : ''}
                            onClick={(e) => handleOnChangeGroupProduct(item.value)}>
                            {item.label}
                          </li>
                        )
                      })}
                    </ul>
                  </Panel>
                </Collapse>
              </div>
            </aside>
          </Col>
          <Col xs="10">
            <div className="product-report__page">
              {/*Add utils table here*/}
              <div className="product-report__scroll">
                <div className="product-report__content">
                  <div className="product-report__header">
                    <p>Ngày lập: {moment().format('DD/MM/YYYY HH:mm')}</p>
                    <h3>Báo cáo danh sách hàng hóa theo lô, hạn sử dụng</h3>
                    <div className="product-report__date-range">
                      {showDateRange({ endDate: getEndDate(endDate), isTimeExpireActive, dates })}
                    </div>
                    <div className="product-report__branch">Chi nhánh: {branch}</div>
                  </div>
                  <div className="product-report__table">
                    <Table
                      rowKey={rc => rc._id}
                      columns={columns}
                      dataSource={sortNearDueReports(dataNearDueProducts)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProductReport
