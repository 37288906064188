import produce from 'immer';
import { get } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';
// import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null,

  options: [],
  isGetOptionsLoading: false,
  getOptionsFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_MEDICINE_GROUPS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_MEDICINE_GROUPS_SUCCESS:
      state.isLoading = false;
      // state.list = get(payload,'docs',[]);
      // state.paging = getPaging(payload);
      state.list = payload;
      return;

    case Types.GET_MEDICINE_GROUPS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_MEDICINE_GROUP_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_MEDICINE_GROUP_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_MEDICINE_GROUP_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_MEDICINE_GROUP_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_MEDICINE_GROUP_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.options = [payload, ...state.options];
      return;

    case Types.CREATE_MEDICINE_GROUP_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_MEDICINE_GROUP_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_MEDICINE_GROUP_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_MEDICINE_GROUP_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_MEDICINE_GROUP_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_MEDICINE_GROUP_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_MEDICINE_GROUP_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_MEDICINE_GROUP_OPTIONS_REQUEST:
      state.isGetOptionsLoading = true;
      return;

    case Types.GET_MEDICINE_GROUP_OPTIONS_SUCCESS:
      state.isGetOptionsLoading = false;
      state.options = payload;
      return;

    case Types.GET_MEDICINE_GROUP_OPTIONS_FAILED:
      state.isGetOptionsLoading = false;
      state.getOptionsFailed = payload;
      return;

    case Types.RESET_MEDICINE_GROUP_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
