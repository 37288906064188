import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import toastr from 'toastr';
import ModuleExport from './components/Common/ModuleExport';
import TemplatePrintInvoice from './components/Common/templatePrint/TemplateInvoiceProvider';
import ModuleProcessImport from './components/Common/FormInportFile';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};

const app = (
  <Suspense fallback={'...'}>
    <Provider store={store}>
      <PersistGate loading={'...'} persistor={persistor}>
        <BrowserRouter>
          <ModuleProcessImport>
            <ModuleExport>
              <TemplatePrintInvoice>
                <App />
              </TemplatePrintInvoice>
            </ModuleExport>
          </ModuleProcessImport>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
