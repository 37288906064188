import * as Types from '~/constants/actionTypes';

export const getShippingVendors = (query) => ({
  type: Types.GET_SHIPPING_VENDORS_REQUEST,
  payload: query
});

export const getShippingVendorDetail = (id) => ({
  type: Types.GET_SHIPPING_VENDOR_DETAIL_REQUEST,
  payload: id
});

export const createShippingVendor = (shippingVendor) => ({
  type: Types.CREATE_SHIPPING_VENDOR_REQUEST,
  payload: shippingVendor
});

export const updateShippingVendor = (shippingVendor) => ({
  type: Types.UPDATE_SHIPPING_VENDOR_REQUEST,
  payload: shippingVendor
});

export const deleteShippingVendor = (id) => ({
  type: Types.DELETE_SHIPPING_VENDOR_REQUEST,
  payload: id
});

export const resetShippingVendorState = () => ({
  type: Types.RESET_SHIPPING_VENDOR_STATE
});
