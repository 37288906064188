import React, { useEffect, useCallback } from 'react';
import { Form, Input, Row, Button, Skeleton } from 'antd';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateDegree,
  useDegree,
  useInitDegree,
  useResetDegree,
  useUpdateDegree
} from '~/hooks/worldCare/degree';
import { useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';

const FormItem = Form.Item;

const DegreeForm = () => {
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreateDegree();
  const [, handleUpdate] = useUpdateDegree();

  const { id } = useParams();
  const [degree, isLoading] = useDegree(id);
  const initDegree = useInitDegree(degree);

  useEffect(() => {
    form.resetFields();
  }, [initDegree, form]);

  useResetDegree();

  const onFinish = useCallback(
    (values) => {
      if (id) {
        handleUpdate({ ...values, id });
      } else {
        handleCreate(values);
      }
    },
    [handleCreate, handleUpdate, id]
  );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="page-wraper hospital-form  page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} chứng chỉ`}
          routes={[
            { path: PATH_APP.config.degree, title: 'Danh sách chứng chỉ' }
          ]}
        />
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initDegree}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <FormItem label="Tên chứng chỉ (vi)" name={['name', LANGUAGE.VI]}>
            {renderInput(<Input />)}
          </FormItem>

          <FormItem label="Tên chứng chỉ (en)" name={['name', LANGUAGE.EN]}>
            {renderInput(<Input />)}
          </FormItem>
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.config.degree}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default DegreeForm;
