import ReceiptPaymentType from '.';

function Receipt() {
  return (
    <>
      <ReceiptPaymentType type="RECEIPT" name={'thu'} permission="RECEIPT" />
    </>
  );
}

export default Receipt;
