import requester from './requester';
import { BASE_URL } from '../../constants/defaultValue';

// const CUSTOMERS = [
//   {
//     _id: 1,
//     name: 'Khách hàng 1',
//     code: 'KH-1',
//     phoneNumber: '0122 555 555',
//     debt: 100000,
//     totalSale: 200000,
//     totalSaleAfterReturn: 500000,
//     createdBy: { name: 'Admin' },
//     createdDate: '31/12/2021',
//     address: '244 Hoàng Diệu - Hải châu - Đà Nẵng',
//     group: 'Thường xuyên',
//     email: 'khÏ1@gmail.com'
//   },
//   {
//     _id: 2,
//     name: 'Khách hàng 2',
//     code: 'KH-2',
//     phoneNumber: '0122 555 555',
//     debt: 300000,
//     totalSale: 400000,
//     totalSaleAfterReturn: 600000,
//     createdBy: { name: 'Admin' },
//     createdDate: '31/12/2021',
//     address: '244 Hoàng Diệu - Hải châu - Đà Nẵng',
//     group: 'Thường xuyên',
//     email: 'khÏ1@gmail.com'
//   },
//   {
//     _id: 3,
//     name: 'Khách hàng 3',
//     code: 'KH-3',
//     phoneNumber: '0122 555 555',
//     debt: 500000,
//     totalSale: 700000,
//     totalSaleAfterReturn: 300000,
//     createdBy: { name: 'Admin' },
//     createdDate: '31/12/2021',
//     address: '244 Hoàng Diệu - Hải châu - Đà Nẵng',
//     group: 'Thường xuyên',
//     email: 'khÏ1@gmail.com'
//   },
//   {
//     _id: 4,
//     name: 'Khách hàng 4',
//     code: 'KH-4',
//     phoneNumber: '0122 555 555',
//     debt: 60000,
//     totalSale: 70000,
//     totalSaleAfterReturn: 80000,
//     createdBy: { name: 'Admin' },
//     createdDate: '31/12/2021',
//     address: '244 Hoàng Diệu - Hải châu - Đà Nẵng',
//     group: 'Thường xuyên',
//     email: 'khÏ1@gmail.com'
//   }
// ];

const customer = {
  getAll: query => requester.get(`${BASE_URL}/api/v2/customer`, query),
  getOptions: id =>
    requester.get(`${BASE_URL}/api/v2/customer/${id}`),
  getCustomerSearch: keyword =>
    requester.get(`${BASE_URL}/api/v2/customer-search`, {
      keyword
    }),
  create: customer =>
    requester.post(`${BASE_URL}/api/v2/customer`, customer),
  getDebt: query => requester.get(`${BASE_URL}/api/v2/customer/${query.customerId}/debt-history`, {
    page: query.page,
    limit: query.limit
  }),
  getPurchases: query => requester.get(`${BASE_URL}/api/v2/customer/${query.customerId}/transaction-history`, {
    page: query.page,
    limit: query.limit
  }),
  getOrderHistory: query => requester.get(`${BASE_URL}/api/v2/customer/${query.customerId}/order-history`, {
    page: query.page,
    limit: query.limit
  }),
  update: param => requester.put(`${BASE_URL}/api/v2/customer/${param._id}`, param),
  delete: id => requester.delete(`${BASE_URL}/api/v2/customer/${id}`)
};

export default customer;
