import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useFetch,
  useQueryParams
} from '~/hooks/utils';

import {
  getProcessesImport,
  getProcessImport,
  createProcessImport,
  updateProcessImport,
  deleteProcessImport,
  fetchEventProcessImport,
  createEventProcessImport,
  updateEventProcessImport,
  resetProcessImportState
} from '~/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS, BASE_URL } from '~/constants/defaultValue';
import { useProfile } from '~/hooks/worldCare';
import { notification } from 'antd';

const PROCESS_IMPORT_MODULE = 'processImport';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,

  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,

  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,

  pagingSelector
} = getSelectors(PROCESS_IMPORT_MODULE);

export const useProcessImportPaging = () => useSelector(pagingSelector);

export const useProcessesImport = query => {
  return useFetchByParam({
    action: getProcessesImport,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};
export const useProcessImport = id => {
  return useFetchByParam({
    action: getProcessImport,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateProcessImportParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    history.push({
      pathname: '/process-import',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useProcessImportQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const name = query.get('name');
  const status = query.get('status');

  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== name) {
    prevKeyword.current = name;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      name: name || undefined,
      status
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, name, status, deleteSuccess]);
};

export const useCreateProcessImport = callback => {
  useSuccess(createSuccessSelector, 'Nhập file dữ liệu thành công', callback);
  useFailed(createFailedSelector, 'Nhập file dữ liệu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createProcessImport
  });
};

export const useUpdateProcessImport = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật file dữ liệu thành công');
  useFailed(updateFailedSelector, 'Cập nhật file dữ liệu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProcessImport
  });
};

export const useDeleteProcessImport = () => {
  useSuccess(deleteSuccessSelector, 'Xoá file dữ liệu thành công');
  useFailed(deleteFailedSelector, 'Xoá file dữ liệu thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteProcessImport
  });
};

export const useInitProcessImport = (processImport, id) => {
  return useMemo(() => {
    if (!processImport || !id) {
      return { status: true };
    }

    return {
      ...processImport,
      status: processImport.status === STATUS.ACTIVE ? true : false
    };
  }, [processImport, id]);
};
export const useEventProcessImport = (facts, setFacts) => {
  // const [listening, setListening] = useState(false);
  const [profile] = useProfile();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!profile) {
      const events = new EventSource(
        `${BASE_URL}/api/v1/process-import-listen?userId=${profile._id}&branchId=${profile.branchId}`
      );
      events.addEventListener('error', e => {
        events.close();
      });
      events.onmessage = event => {
        const parsedData = JSON.parse(event.data);

        switch (parsedData?.case) {
          case 'CREATE': {
            dispatch(createEventProcessImport(parsedData?.data));
            break;
          }
          // case 'FETCH': {
          //   dispatch(fetchEventProcessImport(parsedData?.data));
          //   break;
          // }
          case 'UPDATE': {
            dispatch(updateEventProcessImport(parsedData?.data));
            switch (parsedData?.data?.status) {
              case 'DONE':
                notification.success({ message: 'Xử lý file thành công' });
                break;
              case 'FAIL':
                notification.error({ message: 'Xử lý file không thành công', description: `${parsedData?.data?.message}` });
                break;
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      };
      return () => {
        events.close();
      };
    }
  }, [profile]);
  return [];
};

export const useResetProcessImport = () => {
  useResetState(resetProcessImportState);
};
