import * as Types from '~/constants/actionTypes';

export const getCashFlows = query => ({
  type: Types.GET_CASH_FLOWS_REQUEST,
  payload: query
});

export const createPaymentNoteType = paymentNoteType => ({
  type: Types.CREATE_PAYMENT_NOTE_TYPE_REQUEST,
  payload: paymentNoteType
});

export const createRecipientSubmitter = recipientSubmitter => ({
  type: Types.CREATE_RECIPIENT_SUBMITTER_REQUEST,
  payload: recipientSubmitter
});

export const createPayRecNote = data => ({
  type: Types.CREATE_PAY_REC_NOTE_REQUEST,
  payload: data
});

export const searchPayerReceivers = query => ({
  type: Types.SEARCH_PAYER_RECEIVERS_REQUEST,
  payload: query
});

export const searchPayReceiveType = query => ({
  type: Types.SEARCH_PAY_RECEIVE_TYPE_REQUEST,
  payload: query
});

export const getCashFlow = id => ({
  type: Types.GET_CASH_FLOW_REQUEST,
  payload: id
});

export const createCashFlow = cashFlow => ({
  type: Types.CREATE_CASH_FLOW_REQUEST,
  payload: cashFlow
});

export const updateCashFlow = cashFlow => ({
  type: Types.UPDATE_CASH_FLOW_REQUEST,
  payload: cashFlow
})

export const deleteCashFlow = id => ({
  type: Types.DELETE_CASH_FLOW_REQUEST,
  payload: id
});

export const resetCashFlowState = () => ({
  type: Types.RESET_CASH_FLOW_STATE
});
