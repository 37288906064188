import React, { useMemo, useState } from 'react';
import { Table, Input, Typography } from 'antd';
import { formatCurrency, formatNumber } from '~/utils/helper';
import { SearchOutlined } from '@ant-design/icons';
import { compact } from 'lodash';

const { Text } = Typography;

const DetailTable = ({ damage }) => {
  const [codeKeyword, setCodeKeyword] = useState(null);
  const [nameKeyword, setNameKeyword] = useState(null);

  const datasource = useMemo(() => {
    let displayedItems = damage.detailItems
      .filter(order =>
        !codeKeyword
          ? true
          : order.productVariant.variantCode
              .toLowerCase()
              .includes(codeKeyword.toLowerCase())
      )
      .filter(order =>
        !nameKeyword
          ? true
          : order.product?.name
              ?.toLowerCase()
              ?.includes?.(nameKeyword.toLowerCase())
      );

    const data = displayedItems.map(order => {
      return {
        ...order,
        name: `${order?.product?.name}`
      };
    });

    return [{ isSummaryRow: true, variantId: -1 }, ...data];
  }, [codeKeyword, nameKeyword, damage]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'code',
        render: record => {
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm mã hàng"
              value={codeKeyword}
              onChange={e => setCodeKeyword(e.target.value)}
            />
          ) : (
            record.productVariant?.variantCode
          );
        },
        width: 150
      },
      {
        title: 'Tên hàng',
        key: 'name',
        render: record => {
          const lotArea = [(compact(record?.batch?.batchSearch).join(" - "))]
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm tên hàng"
              value={nameKeyword}
              onChange={e => setNameKeyword(e.target.value)}
            />
          ) : (
            <>
                 {record.product?.name}
                 {lotArea && <p style={{marginTop:4}}>{lotArea?.map((e)=><span style={{backgroundColor:"#3d7ff3",padding:3,borderRadius:2 ,marginRight :10 ,color:'white'}}>{e}</span>) }</p>}
            </>
          );
        },
        width: 400
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'quantity',
        align: 'right'
      },
      {
        title: 'Giá nhập',
        key: 'cost',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.cost);
        },
        align: 'right'
      },

      {
        title: 'Thành tiền',
        key: 'totalCost',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.totalCost);
        },
        align: 'right'
      }
    ];
  }, [codeKeyword, nameKeyword]);

  const summary = useMemo(() => {
    const caculateTotal = (data, key) => {
      return data.reduce((total, record) => total + record[key], 0);
    };

    const totalQuantity = caculateTotal(damage.detailItems, 'quantity');
    const totalAmount = caculateTotal(damage.detailItems, 'totalCost');

    const summaryData = [
      {
        label: 'Tổng số lượng hủy:',
        value: formatNumber(totalQuantity)
      },

      {
        label: 'Tổng giá trị hủy:',
        value: formatCurrency(totalAmount)
      }
    ];

    return summaryData.map(({ label, value }, index) => {
      return (
        <Table.Summary.Row key={index}>
          <Table.Summary.Cell colSpan={4} align="right">
            {label}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{value}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });
  }, [damage]);

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        className="nested-table"
        columns={columns}
        dataSource={datasource}
        size="small"
        pagination={false}
        rowKey={rc => rc.variantId}
        summary={() => (
          <Table.Summary className="table-summary">{summary}</Table.Summary>
        )}
      />
    </div>
  );
};

export default DetailTable;
