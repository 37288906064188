import * as Types from '~/constants/actionTypes';

export const getStockTakes = query => ({
  type: Types.GET_STOCK_TAKES_REQUEST,
  payload: query
});

export const getStockTake = id => ({
  type: Types.GET_STOCK_TAKE_REQUEST,
  payload: id
});

export const createStockTake = order => ({
  type: Types.CREATE_STOCK_TAKE_REQUEST,
  payload: order
});

export const saveStockTakeLump = stock => ({
  type: Types.SAVE_STOCK_TAKE_LUMPED_REQUEST,
  payload: stock
});

export const updateStockTake = value => ({
  type: Types.UPDATE_STOCK_TAKE_REQUEST,
  payload: value
});

export const deleteStockTake = id => ({
  type: Types.DELETE_STOCK_TAKE_REQUEST,
  payload: id
});

export const resetStockTakeState = () => ({
  type: Types.RESET_STOCK_TAKE_STATE
});
