import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicineCategories({ payload: query }) {
  try {
    const data = yield call(Api.medicineCategory.getAll, query);

    yield put({ type: Types.GET_MEDICINE_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_CATEGORIES_FAILED,
      payload: error
    });
  }
}

function* getMedicineCategory({ payload }) {
  try {
    const medicineCategory = yield call(Api.medicineCategory.getById, payload);
    yield put({
      type: Types.GET_MEDICINE_CATEGORY_SUCCESS,
      payload: medicineCategory
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_CATEGORY_FAILED,
      payload: error
    });
  }
}

function* createMedicineCategory(action) {
  try {
    const data = yield call(Api.medicineCategory.create, action.payload);
    yield put({ type: Types.CREATE_MEDICINE_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICINE_CATEGORY_FAILED,
      payload: error
    });
  }
}

function* updateMedicineCategory(action) {
  try {
    const data = yield call(Api.medicineCategory.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICINE_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICINE_CATEGORY_FAILED,
      payload: error
    });
  }
}

function* deleteMedicineCategory({ payload }) {
  try {
    yield call(Api.medicineCategory.delete, payload);
    yield put({ type: Types.DELETE_MEDICINE_CATEGORY_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICINE_CATEGORY_FAILED,
      payload: error
    });
  }
}

export default function* medicineCategory() {
  yield takeLatest(
    Types.GET_MEDICINE_CATEGORIES_REQUEST,
    getMedicineCategories
  );
  yield takeLatest(Types.GET_MEDICINE_CATEGORY_REQUEST, getMedicineCategory);
  yield takeLatest(
    Types.DELETE_MEDICINE_CATEGORY_REQUEST,
    deleteMedicineCategory
  );
  yield takeLatest(
    Types.CREATE_MEDICINE_CATEGORY_REQUEST,
    createMedicineCategory
  );
  yield takeLatest(
    Types.UPDATE_MEDICINE_CATEGORY_REQUEST,
    updateMedicineCategory
  );
}
