export const GET_MEDICINE_METHODS_REQUEST = 'GET_MEDICINE_METHODS_REQUEST';
export const GET_MEDICINE_METHODS_SUCCESS = 'GET_MEDICINE_METHODS_SUCCESS';
export const GET_MEDICINE_METHODS_FAILED = 'GET_MEDICINE_METHODS_FAILED';

export const GET_MEDICINE_METHOD_REQUEST = 'GET_MEDICINE_METHOD_REQUEST';
export const GET_MEDICINE_METHOD_SUCCESS = 'GET_MEDICINE_METHOD_SUCCESS';
export const GET_MEDICINE_METHOD_FAILED = 'GET_MEDICINE_METHOD_FAILED';

export const CREATE_MEDICINE_METHOD_REQUEST = 'CREATE_MEDICINE_METHOD_REQUEST';
export const CREATE_MEDICINE_METHOD_SUCCESS = 'CREATE_MEDICINE_METHOD_SUCCESS';
export const CREATE_MEDICINE_METHOD_FAILED = 'CREATE_MEDICINE_METHOD_FAILED';

export const UPDATE_MEDICINE_METHOD_REQUEST = 'UPDATE_MEDICINE_METHOD_REQUEST';
export const UPDATE_MEDICINE_METHOD_SUCCESS = 'UPDATE_MEDICINE_METHOD_SUCCESS';
export const UPDATE_MEDICINE_METHOD_FAILED = 'UPDATE_MEDICINE_METHOD_FAILED';

export const DELETE_MEDICINE_METHOD_REQUEST = 'DELETE_MEDICINE_METHOD_REQUEST';
export const DELETE_MEDICINE_METHOD_SUCCESS = 'DELETE_MEDICINE_METHOD_SUCCESS';
export const DELETE_MEDICINE_METHOD_FAILED = 'DELETE_MEDICINE_METHOD_FAILED';

export const RESET_MEDICINE_METHOD_STATE = 'RESET_MEDICINE_METHOD_STATE';
