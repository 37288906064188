import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table, Typography, Tag, Row } from 'antd';
import { formatCurrency, formatNumber, formatDate } from '~/utils/helper';
import { get } from 'lodash';

const { Text } = Typography;

const DetailTable = ({ sale }) => {
  const dataSource = useMemo(() => sale?.invoiceDetail || [], [sale]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'code',
        render: record => {
          return <Link to="#">{record.variant?.variantCode}</Link>;
        },
        width: 150
      },
      {
        title: 'Tên hàng',
        key: 'name',
        width: 300,
        render: rc => {
          return `${rc.product?.name} - ${ get(get(rc,['variant','unit'],get(rc,['variant','productUnit'])),'name','')}`;
        }
      },

      {
        title: 'Liều dùng',
        key: 'dose',
        render: rc => {
          return rc.product?.productDetail?.dosage;
        }
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'quantity',
        align: 'right'
      },
      {
        title: 'Đơn giá',
        key: 'cost',
        render: record => {
          return formatCurrency(record.cost);
        },
        align: 'right'
      },
      {
        title: 'Giảm giá',
        key: 'discount',
        render: record => {
          return formatCurrency(record.discountValue);
        },
        align: 'right'
      },
      {
        title: 'Giá bán',
        key: 'sellPrice',
        render: record => {
          return formatCurrency(record.price);
        },
        align: 'right'
      },
      {
        title: 'Thành tiền',
        key: 'amount',
        render: record => {
          return formatCurrency(record.quantity * record.price - record.discountValue);
        },
        align: 'right'
      }
    ];
  }, []);

  const summary = useMemo(() => {
    const getTotal = (data, key) => {
      if (!data) return 0;

      return data.reduce((total, record) => total + record[key], 0);
    };

    const totalQuantity = getTotal(dataSource, 'quantity');

    const totalPaymentOfItems = dataSource.reduce((total, item) => {
      const payment = item.quantity * item.price - item.discountValue;
      return total + payment;
    }, 0);

    const summaryData = [
      {
        label: 'Tổng số lượng:',
        value: formatNumber(totalQuantity)
      },
      {
        label: 'Tổng tiền hàng:',
        value: formatCurrency(totalPaymentOfItems)
      },
      {
        label: 'Giảm giá hoá đơn:',
        value: formatCurrency(sale.discountValue)
      },
      {
        label: 'Tổng cộng:',
        value: formatCurrency(sale.total - sale.discountValue)
      },
      {
        label: 'Khách đã trả:',
        value: formatCurrency(getTotal(sale.paymentNotes, 'paymentAmount'))
      }
    ];

    return summaryData.map(({ label, value }, index) => {
      return (
        <Table.Summary.Row key={index}>
          <Table.Summary.Cell colSpan={8} align="right">
            {label}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{value}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });
  }, [dataSource, sale]);

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        className="nested-table"
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={false}
        rowKey={({ variantId }) => variantId}
        summary={() => {
          return (
            <Table.Summary className="table-summary">{summary}</Table.Summary>
          );
        }}
        expandable={{
          expandedRowRender: item => (
            <div style={{ paddingLeft: 150 }}>
              <Row>
                <Tag color="#2db7f5">
                  {`${item?.batch?.lotNumber} - ${formatDate(
                    item?.batch?.expirationDate
                  )}`}
                </Tag>
              </Row>
              {/* <Row className="list-inline-item" style={{ marginTop: 10 }}>
                <span> Liều dùng: </span>
                <span style={{ marginLeft: 5 }}>
                  {' '}
                  {item.product?.productDetail?.doseInUse}
                </span>
              </Row> */}
            </div>
          ),
          defaultExpandAllRows: true,
          expandedRowKeys: dataSource.map(({ variantId }) => variantId),
          expandIcon: () => <></>
        }}
      />
    </div>
  );
};

export default DetailTable;
