import { useState } from 'react';
import { Modal, Form, Input } from 'antd';

const { TextArea } = Input;

const OrderCancel = ({ visible, onSubmit, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const submitHandle = async (values) => {
    setLoading(true);
    await onSubmit(values);
    onClose();
  };

  return (
    <Modal
      title="Hủy đơn hàng"
      onOk={() => form.submit()}
      okText="Lưu"
      cancelText="Hủy bỏ"
      visible={visible}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form
        form={form}
        name="assign-form"
        layout="vertical"
        onFinish={submitHandle}
      >
        <Form.Item label="Lý do hủy" name="cancelReason">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderCancel;
