import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getPurchaseReturns,
  createPurchaseReturn,
  deletePurchaseReturn,
  getPurchaseReturn,
  resetPurchaseReturnState,
  updatePurchaseReturn
} from '~/redux/action';
import { Menu, Checkbox, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { formatNumber } from '~/utils/helper';
import { DISCOUNT_TYPES } from '~/constants/defaultValue';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import {
  caculateDiscountValueOnTypeChange,
  getPriceAfterDiscount,
  getTotalQuantity
} from '~/utils/caculator';
import { get, keyBy } from 'lodash';
import DiscountInput from '~/components/Common/DiscountInput';
import { PATH_CLINIC } from '~/routes/paths';

const PURCHASE_RETURN_MODULE = 'purchaseReturn';

const CREATE_SUCCESS_MESS = 'Tạo mới đơn trả hàng nhập thành công';
const CREATE_FAILED_MESS = 'Tạo mới đơn trả hàng nhập thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật đơn trả hàng nhập thành công';
const UPDATE_FAILED_MESS = 'Cập nhật đơn trả hàng nhập thất bại';
const DELETE_SUCCESS_MESS = 'Huỷ đơn trả hàng nhập thành công';
const DELETE_FAILED_MESS = 'Huỷ đơn trả hàng nhập thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(PURCHASE_RETURN_MODULE);

export const usePurchaseReturns = query => {
  return useFetchByParam({
    action: getPurchaseReturns,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreatePurchaseReturn = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPurchaseReturn
  });
};

export const useUpdatePurchaseReturn = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePurchaseReturn
  });
};

export const useDeletePurchaseReturn = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deletePurchaseReturn
  });
};

export const usePurchaseReturn = params => {
  return useFetchByParam({
    action: getPurchaseReturn,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitPurchaseReturn = ({
  purchaseReturn,
  updatingId,
  setFormData
}) => {
  return useEffect(() => {
    if (!purchaseReturn || !updatingId) {
      return {};
    }

    const onInitMultipleVariant = purchaseReturnItems => {
      const addingVariants = purchaseReturnItems.map(variant => {
        const {
          discountValue,
          discountType,
          discountPercent,
          fullBatches
        } = variant;

        const batchDict = keyBy(fullBatches, '_id');
        const selectedBatches = variant.batches.map(batch => ({
          ...batch,
          lotNumber: batchDict[batch.batchId]?.lotNumber,
          expirationDate: batchDict[batch.batchId]?.expirationDate,
          manufacturingDate: batchDict[batch.batchId]?.manufacturingDate
        }));

        const quantity = getTotalQuantity(variant.batches);
        return {
          ...variant,
          _id: variant.variantId || variant._id,
          selectedBatches: selectedBatches,
          quantity,
          discount: {
            type: discountType,
            value:
              discountType === DISCOUNT_TYPES.VALUE
                ? discountValue
                : discountPercent
          },
          variantCode: variant.productVariant?.variantCode,
          batches: fullBatches
        };
      });

      setFormData(formData => [...addingVariants, ...formData]);
    };

    onInitMultipleVariant(purchaseReturn.purchaseReturnItems);
  }, [purchaseReturn, updatingId, setFormData]);
};

export const useUpdatePurchaseReturnParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: PATH_CLINIC.purchaseReturn.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          id: undefined
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const usePurchaseReturnQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page');
  const lotNumber = query.get('lotNumber');
  const expirationDate = query.get('expirationDate');
  const variantCodeProductName = query.get('variantCodeProductName');
  const purchasedAt = query.get('purchasedAt');
  const supplierName = query.get('supplierName');
  const status = query.get('status');
  const id = query.get('id');

  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      id,
      lotNumber,
      expirationDate,
      variantCodeProductName,
      supplierName,
      purchasedAt,
      status
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page, 
    limit, 
    keyword, 
    deleteSuccess, 
    lotNumber,
    expirationDate,
    variantCodeProductName,
    supplierName,
    purchasedAt,
    id,
    status
  ]);
};

export const usePurchaseReturnPaging = () => useSelector(pagingSelector);

export const useResetPurchaseReturn = () => {
  useResetState(resetPurchaseReturnState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã nhập hàng',
    key: 'code'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Nhà cung cấp',
    key: 'supplier'
  },
  {
    title: 'Tổng tiền hàng',
    key: 'totalSupplierPayment'
  },
  {
    title: 'Giảm giá',
    key: 'discount'
  },
  {
    title: 'NCC cần trả',
    key: 'supplierDebt'
  },
  {
    title: 'NCC đã trả',
    key: 'totalSupplierPaid'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  }
];

export const usePurchaseReturnColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    time: true,
    supplier: true,
    totalSupplierPayment: true,
    discount: true,
    supplierDebt: true,
    totalSupplierPaid: true,
    status: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

export const usePurchaseReturnColumns = (setFormData, handleSelect) => {
  const columns = useMemo(() => {
    const onDeleteVariant = variant => {
      setFormData(variants =>
        variants.filter(({ variantId }) => variantId !== variant.variantId)
      );
    };

    const onDiscountTypeChange = (updatedIndex, type) => {
      setFormData(formData =>
        formData.map((item, index) => {
          if (index !== updatedIndex) {
            return item;
          }

          const { discount, price } = formData[index];

          const discountValue = caculateDiscountValueOnTypeChange({
            type,
            value: discount.value,
            price
          });

          return {
            ...item,
            discount: {
              type,
              value: discountValue
            }
          };
        })
      );
    };

    const onDiscountValueChange = (updatedIndex, value) => {
      setFormData(formData =>
        formData.map((item, index) => {
          if (index !== updatedIndex) {
            return item;
          }

          return {
            ...item,
            discount: {
              ...item.discount,
              value
            }
          };
        })
      );
    };

    const onFieldChange = (fieldName, value, updatedIndex) => {
      setFormData(formData =>
        formData.map((item, index) => {
          return index === updatedIndex
            ? {
                ...item,
                [fieldName]: value,
                selectedBatches:
                  fieldName === 'quantity'
                    ? [{ ...item.selectedBatches[0], quantity: value }]
                    : item.selectedBatches
              }
            : item;
        })
      );
    };

    return [
      {
        title: '',
        key: 'delete',
        align: 'center',
        render: rc => (
          <Button
            onClick={() => onDeleteVariant(rc)}
            size="small"
            shape="circle"
            className="warehouse-form__delete-btn"
            icon={<DeleteOutlined />}
          />
        )
      },
      {
        title: 'STT',
        key: 'STT',
        align: 'center',
        render: (_, x, index) => index + 1
      },
      {
        title: 'Mã hàng',
        key: 'variantCode',
        dataIndex: 'variantCode',
        render: (_, x, index) => {
          return x?.selectedVariant?.variantCode || _
        }
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['product', 'name']
      },
      {
        title: 'ĐVT',
        key: 'unit',
        dataIndex: ['unit', 'name'],
        render: (_,product) => {
          if(!product?.relationVariants) {
            return (
              <div className="sale-table__unit">
                {product?.unit?.name}
              </div>
            )
          }
          return (
            <div className="sale-table__unit">
              {product?.relationVariants.length <= 1 ? (
                <span>
                  {`${product.relationVariants[0]?.productUnit?.name}`}
                </span>
              ) : (
                <Dropdown
                  overlay={
                    <Menu>
                      {product.relationVariants
                        .filter(
                          (variant) => variant?._id !== product?.selectedVariant?._id
                        )
                        .map((variant) => {
                          return (
                            <Menu.Item
                              onClick={() =>
                                handleSelect({
                                  productId: product?.productId,
                                  variantId: variant?._id
                                })
                              }
                              style={{ minWidth: 120 }}
                              key={variant._id}
                            >
                              {variant?.productUnit?.name}
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <span
                    className="product-table__selected-variant"
                    onClick={e => e.preventDefault()}
                  >
                    {product.selectedVariant?.productUnit?.name || product.relationVariants[0]?.productUnit?.name}
                    <DownOutlined />
                  </span>
                </Dropdown>
              )}
            </div>
          )
        }
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        render: ({ quantity, selectedBatches }, _, index) => {
          const quantityConvertUnit = selectedBatches[0]?.quantityConvertUnit || quantity;
          return (
            <InputNumber
              size="small"
              className="warehouse-form__quantity"
              value={quantity >= quantityConvertUnit ? quantityConvertUnit : quantity}
              onChange={value => onFieldChange('quantity', value, index)}
              bordered={false}
              disabled={selectedBatches.length !== 1 }
              min = {quantityConvertUnit >= 1 ? 1 : 0}
            />
          )
        }
      },
      {
        title: 'Giá nhập',
        key: 'buyPrice',
        render: (_,product) => {
          return formatNumber(product.selectedVariant?.cost || product.buyPrice)
        }
      },
      {
        title: 'Giảm giá',
        key: 'discount',
        render: ({ discount, buyPrice, returnPrice }, _, index) => (
          <DiscountInput
            discount={discount}
            onTypeChange={value => onDiscountTypeChange(index, value)}
            onValueChange={value => onDiscountValueChange(index, value)}
            max={discount.type === DISCOUNT_TYPES.PERCENT ? 100 : returnPrice??0}
          >
            <div className="warehouse-form-table__discount warehouse-form-table__discount--width-auto">
              {discount.type === DISCOUNT_TYPES.VALUE
                ? formatNumber(discount.value)
                : formatNumber((discount.value / 100) * (returnPrice??0))}
            </div>
          </DiscountInput>
        )
      },

      {
        title: 'Giá trả lại',
        key: 'returnPrice',
        align: 'end',
        render: (_,records,index)=><InputNumber
        size="small"
        className="warehouse-form__quantity"
        value={get(records,'returnPrice',0)}
        formatter={(value)=> { return Number(value).toLocaleString('en-US')}}
        onChange={(value) => onFieldChange('returnPrice', Number(String(value).replace(/[.,]/g,'')), index)}
        bordered={false}
        step={10000}
        // disabled={selectedBatches.length !== 1 }
        // min = {quantityConvertUnit >= 1 ? 1 : 0}
      />
        
        //({ discount,selectedVariant, buyPrice }) =>
        //  formatNumber(getPriceAfterDiscount(selectedVariant?.cost || buyPrice, discount))
      },
      {
        title: 'Thành tiền',
        key: 'finalPrice',
        align: 'end',
        render: ({ quantity, returnPrice, discount, buyPrice }) =>
          formatNumber(quantity * getPriceAfterDiscount(returnPrice, discount))
      }
    ];
  }, [setFormData, handleSelect]);

  return columns;
};
export const SEARCH_PURCHASE_RETURN =[
  {
    name: "code",
    placeholder:"Theo mã phiếu trả hàng nhập"
},
{
    name: "variantCodeProductName",
    placeholder:"Theo mã, tên hàng"
},
{
    name: "supplierName",
    placeholder:"Tên NCC"
},
{
    name: "lotNumber",
    placeholder:"Theo lô"
},
{
    name: "expirationDate",
    type: "date",
    label:"Hạn sử dụng",
},
{
    name: "purchasedAt",
    type: "rangerTime",
    label:"Thời gian",
},
{
    name: "status",
    type: "radio",
    label: "Trạng thái",
}
]