import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getProductInvoices({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const productInvoice = yield call(Api.productInvoice.getById, query.id);
      data = { docs: [productInvoice], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.productInvoice.getAll, query);
    }

    yield put({ type: Types.GET_PRODUCT_INVOICES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_INVOICES_FAILED,
      payload: error.message
    });
  }
}

function* getProductInvoice({ payload }) {
  try {
    const productInvoice = yield call(Api.productInvoice.getById, payload);
    yield put({
      type: Types.GET_PRODUCT_INVOICE_SUCCESS,
      payload: productInvoice
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_INVOICE_FAILED,
      payload: error.message
    });
  }
}

function* createProductInvoice(action) {
  try {
    const data = yield call(Api.productInvoice.create, action.payload);
    yield put({ type: Types.CREATE_PRODUCT_INVOICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PRODUCT_INVOICE_FAILED,
      payload: error.message
    });
  }
}

function* updateProductInvoice(action) {
  try {
    const data = yield call(Api.productInvoice.update, action.payload);
    yield put({ type: Types.UPDATE_PRODUCT_INVOICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PRODUCT_INVOICE_FAILED,
      payload: error.message
    });
  }
}

function* deleteProductInvoice({ payload }) {
  try {
    yield call(Api.productInvoice.delete, payload);
    yield put({ type: Types.DELETE_PRODUCT_INVOICE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PRODUCT_INVOICE_FAILED,
      payload: error.message
    });
  }
}

export default function* productInvoice() {
  yield takeLatest(Types.GET_PRODUCT_INVOICES_REQUEST, getProductInvoices);
  yield takeLatest(Types.GET_PRODUCT_INVOICE_REQUEST, getProductInvoice);
  yield takeLatest(Types.DELETE_PRODUCT_INVOICE_REQUEST, deleteProductInvoice);
  yield takeLatest(Types.CREATE_PRODUCT_INVOICE_REQUEST, createProductInvoice);
  yield takeLatest(Types.UPDATE_PRODUCT_INVOICE_REQUEST, updateProductInvoice);
}
