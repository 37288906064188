import requester from '../requester';

const staffGroup = {
  getAll: query => requester.get('/employee-group', query),
  getById: id => requester.get(`/employee-group/${id}?raw=true`),
  delete: id => requester.delete(`/employee-group/${id}`),
  create: staffGroup => requester.post('/employee-group', staffGroup),
  update: staffGroup =>
    requester.put(`/employee-group/${staffGroup.id}`, staffGroup)
};

export default staffGroup;
