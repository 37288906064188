import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import {
    useCreatePurchaseOrderQuotation,
    useDeletePurchaseOrderQuotation,
    usePurchaseOrderQuotationQueryParams,
    usePurchaseOrderQuotations,
    useUpdatePurchaseOrderQuotation,
    useUpdatePurchaseOrderQuotationParams
} from './PurchaseOrderQuotation.hook';
import { Modal } from 'antd';
import ModalDetail from './ModalDetail';
import { PlusCircleFilled } from '@ant-design/icons';
import ModalCreate from './ModalCreate';
import moment from 'moment';
import api from '~/api';

export const ProviderPurchaseOrderQuotation = createContext({
    query: { limit: 10, page: 1 },
    dataTable: [],
    loadingTable: false,
    paging: { current: 1, pageSize: 10, total: 0 },
    keyword: '',
    setKeyword() {},
    onParamChange() {},
    handleCreate(){},
    loadingCreate:false,
    handleUpdate(){},
    loadingUpdate:false,
    handleDelete(){},
    loadingDelete:false,
    selectedId:{
        dataTable:[],
        detail:{
            supplier:null,
            note:'',
            createdAt:null,
        },
        id:'',
    },
    onCreate(){},
    closeModal(){},
    setSelectId({ dataTable,id,detail}){},
    openModal({id,dataTable,detail},code){},
    onUpdate(id,dataUpdate){},
    listUnit: [],
});
export const useGetValueContextPurchaseOrder = () =>
    useContext(ProviderPurchaseOrderQuotation);
function ContextProvider({ children }) {
    const [modal,contextProvider] = Modal.useModal();
    const refModal = useRef()
    const [selectedId, setSelectId ]=useState({
        dataTable:[],
        detail: {
            supplier: null,
            note: '' ,
            createdAt:null,
        },
        id:''
    })
    const [query] = usePurchaseOrderQuotationQueryParams();
    const [dataTable, loadingTable, paging] = usePurchaseOrderQuotations(query);
    const [keyword, { setKeyword, onParamChange }] = useUpdatePurchaseOrderQuotationParams(query);
    const [loadingCreate,handleCreate] = useCreatePurchaseOrderQuotation(closeModal);
    const [loadingUpdate,handleUpdate] = useUpdatePurchaseOrderQuotation();
    const [loadingDelete,handleDelete] = useDeletePurchaseOrderQuotation();
    const [listUnit, setListUnit] = useState([]);
    useEffect(() => {
        (()=>{
            api.purchaseOrderQuotation.suggestUnit().then((dataSearch)=>{
                setListUnit(dataSearch.map(({name,_id})=>({value:name,label:name,key:_id})))
            });
        })();
    
    }, []);
    const openModal=(data,code)=>{

        setSelectId(data)
       refModal.current = modal.info({
            icon:<></>,
            content:<ModalDetail/>,
            maskClosable:true,
            width: 800,
            afterClose:()=>{
                setSelectId({
                    dataTable:[],
                    detail:{
                        supplier:null,
                        note:'',
                        createdAt:null
                    },
                    id:''
                })
            },
            okText:'Xong',
            mask: true,
            closable:true,
            title: "Chi tiết đơn hàng " + code+ " || " + moment(data.detail.createdAt).format('YYYY-MM-DD HH:mm'),
            cancelText:'Huỷ'
        })

    }

      


    function closeModal (){
        refModal.current.destroy()
    };

    const onCreate = ( data)=> {
        handleCreate(data);      
    };
    
    const openModalCreate =()=>{
        refModal.current =  modal.info({
            icon:<PlusCircleFilled />,
            content: <ModalCreate />,
            maskClosable:true,
            afterClose:()=>{},
            width:1000,
            okButtonProps:{
                hidden:true
            },
            mask: true,
            closable:true,
            title: 'Tạo đơn hàng',
        })
    }

    const onUpdate = (id,dataUpdate)=>{
        handleUpdate({
            _id: id,
            ...dataUpdate
        })
    }
    return (
        <ProviderPurchaseOrderQuotation.Provider
            value={{
                query,
                dataTable,
                loadingTable,
                paging,
                keyword,
                setKeyword,
                onParamChange,
                handleCreate,
                loadingCreate,
                handleUpdate,
                loadingUpdate,
                handleDelete,
                loadingDelete,
                setSelectId,
                openModal,
                selectedId,
                onUpdate,
                onCreate,
                closeModal,
                openModalCreate,
                listUnit,
            }}
        >
            {children}
            {contextProvider}
            
        </ProviderPurchaseOrderQuotation.Provider>
    );
}

export default ContextProvider;
