import * as Types from '~/constants/actionTypes';

export const getDamages = query => ({
  type: Types.GET_DAMAGES_REQUEST,
  payload: query
});

export const getDamage = id => ({
  type: Types.GET_DAMAGE_REQUEST,
  payload: id
});

export const createDamage = purchase => ({
  type: Types.CREATE_DAMAGE_REQUEST,
  payload: purchase
});

export const updateDamage = purchase => ({
  type: Types.UPDATE_DAMAGE_REQUEST,
  payload: purchase
});

export const deleteDamage = id => ({
  type: Types.DELETE_DAMAGE_REQUEST,
  payload: id
});

export const resetDamageState = () => ({
  type: Types.RESET_DAMAGE_STATE
});
