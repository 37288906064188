import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME_EN,
  ACTIVITY_NAME
} from '~/constants/defaultValue';
function* getStockTakes({ payload: query }) {
  try {
    let data = yield call(Api.stockTake.getAll, query);
    yield put({ type: Types.GET_STOCK_TAKES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_STOCK_TAKES_FAILED,
      payload: error.message
    });
  }
}

function* getStockTake({ payload }) {
  try {
    const stockTake = yield call(Api.stockTake.getById, payload);
    yield put({
      type: Types.GET_STOCK_TAKE_SUCCESS,
      payload: stockTake
    });
  } catch (error) {
    yield put({
      type: Types.GET_STOCK_TAKE_FAILED,
      payload: error.message
    });
  }
}

function* createStockTake(action) {
  try {
    const data = yield call(Api.stockTake.create, action.payload);
    yield put({ type: Types.CREATE_STOCK_TAKE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_STOCK_TAKE_FAILED,
      payload: error.message
    });
  }
}
function* createStockTakeSuccessAndCreateActivity(action) {
  try {
    // console.log(action.payload,"action");
    // const { createdBy, branchId, totalSupplierPayment } = action.payload;
    // const message = `${createdBy.fullName} ${MESSAGE_ACTIVITY.STOCKTAKE} ${totalSupplierPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    // const dataSubmit = {
    //   type: TYPE_ACTIVITY.INTERNAL,
    //   branchId,
    //   data: { detail: action.payload, createdBy, message , action: ACTIVITY_NAME.STOCKTAKE},
    // }
    // const data = yield call(Api.notificationService.create, dataSubmit);
  } catch (error) {
    console.log(error);
  }
}
function* saveStockTakeLump(action) {
  try {
    const data = yield call(Api.stockTake.saveLumped, action.payload);
    yield put({ type: Types.SAVE_STOCK_TAKE_LUMPED_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SAVE_STOCK_TAKE_LUMPED_FAILED,
      payload: error.message
    });
  }
}

function* updateStockTake(action) {
  try {
    const data = yield call(Api.stockTake.update, action.payload);
    yield put({ type: Types.UPDATE_STOCK_TAKE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_STOCK_TAKE_FAILED,
      payload: error.message
    });
  }
}

function* deleteStockTake({ payload }) {
  try {
    yield call(Api.stockTake.delete, payload);
    yield put({ type: Types.DELETE_STOCK_TAKE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_STOCK_TAKE_FAILED,
      payload: error.message
    });
  }
}

function* searchStokeTakes({ payload: keyword }) {
  try {
    const data = yield call(Api.stockTake.searchStockTake, keyword);
    yield put({ type: Types.SEARCH_STOCK_TAKES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SEARCH_STOCK_TAKES_FAILED,
      payload: error
    });
  }
}

export default function* stockTake() {
  yield takeLatest(Types.GET_STOCK_TAKES_REQUEST, getStockTakes);
  yield takeLatest(Types.GET_STOCK_TAKE_REQUEST, getStockTake);
  yield takeLatest(Types.DELETE_STOCK_TAKE_REQUEST, deleteStockTake);
  yield takeLatest(Types.CREATE_STOCK_TAKE_REQUEST, createStockTake);
  yield takeLatest(Types.CREATE_STOCK_TAKE_SUCCESS, createStockTakeSuccessAndCreateActivity);
  yield takeLatest(Types.UPDATE_STOCK_TAKE_REQUEST, updateStockTake);
  yield takeLatest(Types.SAVE_STOCK_TAKE_LUMPED_REQUEST, saveStockTakeLump);
  yield takeLatest(Types.SEARCH_STOCK_TAKES_REQUEST, searchStokeTakes);
}
