import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Input, Row, Button, Table, Tag, Spin } from 'antd';
import {
  useSearchProduct,
  useWarehouseColumns,
  useWarehouse,
  useSearchVariantsByProduct,
  useResetMedicine,
  useResetBatch,
  useResetWarehouse,
  useInitWarehouse,
  useBatchActions,
  useUpdateBatchByIdOrder
} from '~/hooks';
import './index.scss';
import { CARD_STYLE, DISCOUNT_TYPES } from '~/constants/defaultValue';
import {
  UnorderedListOutlined,
  PlusOutlined,
  MedicineBoxOutlined
} from '@ant-design/icons';
import BatchForm from './BatchForm';
import UpdateBatchForm from '~/components/Utils/UpdateBatchForm';
import SelectBatch from './SelectBatch';
import SelectGroup from './SelectGroup';
import MedicineForm from '~/components/WorldClinic/Medicine/Form';
import SearchProductsBox from '~/components/Utils/SearchProductsBox';
import SummaryForm from './SummaryForm';
import { formatDate } from '~/utils/helper';
import { useHistory } from 'react-router-dom';

const WarehouseForm = props => {
  useResetMedicine();
  useResetBatch();
  useResetWarehouse();
  const history = useHistory();
  const [formData, setFormData] = useState([]);
  const [newProductId, setNewProductId] = useState();
  const [newVariants, isGettingNewVariants] = useSearchVariantsByProduct(
    newProductId
  );

  const initVariantId = props.location?.state?.variantId;

  const [initVariant, isSearchVariantLoading] = useSearchProduct(initVariantId);

  useEffect(() => {
    history.replace({ state: {} });
  }, [history]);

  const { id: updatingId } = props.match.params;
  const [warehouse, isGetWarehouseLoading] = useWarehouse(updatingId);
  const isShowWithPharma = useMemo(() => warehouse?.typePurchase !== 'PM' ? true : false, [warehouse]);
  const columns = useWarehouseColumns(setFormData, isShowWithPharma);
  useInitWarehouse({ warehouse, updatingId, setFormData });
  const [isSubmitLoading, onUpdateBatchByOrder] = useUpdateBatchByIdOrder();

  const onSelectVariant = variant => {
    
    const initBatch = variant.batches?.[0];
    const selectedBatches = initBatch ? [{ ...initBatch, quantity: 1 }] : [];
    const addedVariant = {
      ...variant,
      discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
      quantity: initBatch ? 1 : 0,
      selectedBatches,
      variantId: variant._id
    };
    
    setFormData(variants => {
      const currentVariants = variants.filter(
        ({ variantId }) => variantId !== variant._id
        );
        return [addedVariant, ...currentVariants];
    });
  };

  useEffect(() => {
    if (initVariant) {
      onSelectVariant(initVariant);
    }
    //eslint-disable-next-line
  }, [initVariant]);

  useEffect(() => {
    const onSelectMultipleVariant = variants => {
      const addingVariants = variants.map(variant => {
        const initBatch = variant.batches?.[0];
        const selectedBatches = initBatch
          ? [{ ...initBatch, quantity: 1 }]
          : [];
        return {
          ...variant,
          variantId: variant._id,
          selectedBatches,
          quantity: initBatch ? 1 : 0,
          discount: { type: DISCOUNT_TYPES.VALUE, value: 0 }
        };
      });

      setFormData(formData => [...addingVariants, ...formData]);
    };

    if (newVariants?.length) {
      onSelectMultipleVariant(newVariants);
    }
    //eslint-disable-next-line
  }, [newVariants]);

  const [isUpdateBatchFormOpen, setUpdateBatchFormOpen] = useState(false);
  const [updatingBatch, setUpdatingBatch] = useState(null);
  const [isBatchFormOpen, setBatchFormOpen] = useState(false);
  const [isSelectBatchOpen, setSelectBatchOpen] = useState(false);
  const [isSelectGroupOpen, setSelectGroupOpen] = useState(false);
  const [isMedicineFormOpen, setMedicineFormOpen] = useState(false);

  const [selectedVariant, setSelectedVariant] = useState(null);

  const updateVariant = useCallback(updatedVariant => {
    setFormData(formData => {
      return formData.map(item => {
        return item._id === updatedVariant._id ? updatedVariant : item;
      });
    });
  }, []);

  const {
    onRemoveBatch,
    onCreateBatchSuccess,
    onUpdateBatch,
    onSelectBatches
  } = useBatchActions({
    updateVariant,
    setFormData,
    formData,
    selectedVariant
  });

  const isLoading =
    isGetWarehouseLoading || isSearchVariantLoading || isGettingNewVariants;
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="warehouse-form">
          <div className="warehouse-form__left" style={CARD_STYLE}>
            <div className="wc-page-header">
              <h1
                className="wc-page-header__title"
                style={{ width: 180, minWidth: 180 }}
              >
                Nhập hàng
              </h1>

              <div className="wc-page-header__search" id="search-product-box">
                <SearchProductsBox onSelect={onSelectVariant}>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Tìm theo mã hàng hoá hoặc tên"
                    suffix={
                      <Row
                        className="warehouse-form__search"
                        onMouseDown={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Button
                          onClick={e => setMedicineFormOpen(true)}
                          size="small"
                          shape="circle"
                          icon={<MedicineBoxOutlined />}
                        ></Button>
                      </Row>
                    }
                  />
                </SearchProductsBox>
              </div>
            </div>

            <div className="warehouse-form-table">
              <Spin spinning={isLoading}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={formData}
                  rowKey={rc => rc.variantCode}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (variant, index) => (
                      <Row
                        key={index}
                        className="warehouse-form-table__parcel-box"
                      >
                        <div className="warehouse-form-table__parcel">Lô:</div>
                        {variant.selectedBatches.map((batch, i) => (
                          <Tag
                            style={{ cursor: 'pointer' }}
                            closable
                            color="#2db7f5"
                            key={i}
                            onClick={() => {
                              setUpdateBatchFormOpen(true);
                              setUpdatingBatch({
                                ...batch,
                                variantId: variant.variantId
                              });
                            }}
                            onClose={() =>
                              onRemoveBatch({
                                ...batch,
                                variantId: variant.variantId
                              })
                            }
                          >
                            {`${batch.lotNumber} - ${batch.expirationDate ? formatDate(
                              batch.expirationDate
                            ) : 'Không tồn tại ngày sử dụng'} - SL: ${batch.quantity}`}
                          </Tag>
                        ))}

                        <Button
                          size="small"
                          onClick={() => {
                            setBatchFormOpen(true);
                            setSelectedVariant(variant);
                          }}
                        >
                          <PlusOutlined />
                        </Button>

                        <Button
                          size="small"
                          onClick={() => {
                            setSelectBatchOpen(true);
                            setSelectedVariant(variant);
                          }}
                        >
                          <UnorderedListOutlined />
                        </Button>
                      </Row>
                    ),
                    defaultExpandAllRows: true,
                    expandedRowKeys: formData.map(
                      ({ variantCode }) => variantCode
                    ),
                    expandIcon: () => <></>
                  }}
                ></Table>
              </Spin>
            </div>
          </div>

          <SummaryForm
            formData={formData}
            initWarehouse={warehouse}
            isGetWarehouseLoading={isGetWarehouseLoading}
          />
        </div>
      </div>

      <BatchForm
        isOpen={isBatchFormOpen}
        onClose={() => setBatchFormOpen(false)}
        callBack={onCreateBatchSuccess}
        variant={selectedVariant}
      />

      {isUpdateBatchFormOpen && (
        <UpdateBatchForm
          isOpen={isUpdateBatchFormOpen}
          onClose={() => setUpdateBatchFormOpen(false)}
          batch={updatingBatch}
          onSubmit={onUpdateBatch}
          dataSource={warehouse}
          onUpdate={onUpdateBatchByOrder}
          isSubmitLoading={isSubmitLoading}
        />
      )}

      {isSelectBatchOpen && (
        <SelectBatch
          isOpen={isSelectBatchOpen}
          onClose={() => setSelectBatchOpen(false)}
          variant={selectedVariant}
          onSubmit={onSelectBatches}
        />
      )}

      <SelectGroup
        isOpen={isSelectGroupOpen}
        onClose={() => setSelectGroupOpen(false)}
      />

      {!initVariantId && (
        <MedicineForm
          key="100"
          isOpen={isMedicineFormOpen}
          onClose={() => setMedicineFormOpen(false)}
          callBack={product => {
            setNewProductId(product._id);
          }}
        />
      )}
    </div>
  );
};

export default WarehouseForm;
