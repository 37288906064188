import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getPartnerships({ payload: query }) {
  try {
    const data = yield call(Api.partnership.getAll, query);
    yield put({ type: Types.GET_PARTNERSHIPS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNERSHIPS_FAILED,
      payload: error.message
    });
  }
}

function* getPartnership({ payload }) {
  try {
    const { data: partnership } = yield call(Api.partnership.getById, payload);
    yield put({
      type: Types.GET_PARTNERSHIP_SUCCESS,
      payload: partnership
    });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNERSHIP_FAILED,
      payload: error.message
    });
  }
}

function* createPartnership(action) {
  try {
    const data = yield call(Api.partnership.create, action.payload);
    yield put({ type: Types.CREATE_PARTNERSHIP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PARTNERSHIP_FAILED,
      payload: error.message
    });
  }
}

function* updatePartnership(action) {
  try {
    const data = yield call(Api.partnership.update, action.payload);
    yield put({ type: Types.UPDATE_PARTNERSHIP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PARTNERSHIP_FAILED,
      payload: error.message
    });
  }
}

function* deletePartnership({ payload }) {
  try {
    yield call(Api.partnership.delete, payload);
    yield put({ type: Types.DELETE_PARTNERSHIP_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PARTNERSHIP_FAILED,
      payload: error.message
    });
  }
}

export default function* partnership() {
  yield takeLatest(Types.GET_PARTNERSHIPS_REQUEST, getPartnerships);
  yield takeLatest(Types.GET_PARTNERSHIP_REQUEST, getPartnership);
  yield takeLatest(Types.DELETE_PARTNERSHIP_REQUEST, deletePartnership);
  yield takeLatest(Types.CREATE_PARTNERSHIP_REQUEST, createPartnership);
  yield takeLatest(Types.UPDATE_PARTNERSHIP_REQUEST, updatePartnership);
}
