import { useMemo, useState, useRef, useCallback } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useFetch
} from '~/hooks/utils';

import {
  getSuppliers,
  createSupplier,
  deleteSupplier,
  getSupplier,
  resetSupplierState,
  updateSupplier,
  getSuppliersAuthenticated
} from '~/redux/action';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { Menu, Checkbox } from 'antd';
import api from '~/api';
import { get } from 'lodash';

const SUPPLIER_MODULE = 'supplier';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector,
  summarySelector,
} = getSelectors(SUPPLIER_MODULE);
const getSelector = key => state => state[SUPPLIER_MODULE][key];
const listAuthenticatedSuccessSelector = getSelector('listAuthenticated');
const getListAuthenticationFailedSelector = getSelector('getListAuthenticatedFailed');

export const useSuppliers = query => {
  return useFetchByParam({
    action: getSuppliers,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useSuppliersAuthenticated = () => {
  return useFetch({
    action: getSuppliersAuthenticated,
    loadingSelector,
    dataSelector: listAuthenticatedSuccessSelector,
    failedSelector: getListAuthenticationFailedSelector,
  });
};

export const useCreateSupplier = callback => {
  useSuccess(
    createSuccessSelector,
    'Tạo mới nhà cung cấp thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nhà cung cấp thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSupplier
  });
};

export const useUpdateSupplier = callback => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật nhà cung cấp thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật nhà cung cấp thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSupplier
  });
};

export const useDeleteSupplier = onDeleteSuccess => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá nhà cung cấp thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector, 'Xoá nhà cung cấp thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteSupplier
  });
};

export const useSupplier = params => {
  return useFetchByParam({
    action: getSupplier,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitSupplier = (supplier, id) => {
  return useMemo(() => {
    if (!supplier || !id) {
      return {};
    }

    return { ...supplier };
  }, [supplier, id]);
};

export const useUpdateSupplierParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/supplier',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useSupplierQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page');
  const name = query.get('name');
  const code = query.get('code');
  const group = query.get('group');
  const phoneNumber = query.get('phoneNumber');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      name,
      code,
      group,
      phoneNumber
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, updateSuccess, deleteSuccess,code,name,group,phoneNumber]);
};

export const useSupplierPaging = () => useSelector(pagingSelector);

export const useResetSupplier = () => {
  useResetState(resetSupplierState);
};
export const useGetSummarySupplier = ()=>{
  const summary =useSelector(summarySelector)
  return[summary]
}

const COLUMNS_OPTIONS = [
  {
    title: 'Tên nhà cung cấp',
    key: 'name'
  },
  {
    title: 'Mã nhà cung cấp',
    key: 'supplierCode'
  },
  {
    title: 'Nhóm nhà cung cấp',
    key: 'supplierGroup'
  },
  {
    title: 'Địa chỉ',
    key: 'address'
  },

  {
    title: 'Điện thoại',
    key: 'phoneNumber'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Nợ cần trả',
    key: 'currentDebt'
  },
  {
    title: 'Tổng mua',
    key: 'totalPurchase'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  },
  {
    title: 'Thao tác',
    key: 'action'
  }
];

export const useColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    name: true,
    supplierCode: true,
    supplierGroup: true,
    email: true,
    action: true,
    phoneNumber: true,
    status: true,
    currentDebt: true,
    totalPurchase: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

export const SEARCH_SUPPLIER =[
  {
    name:"name",
    placeholder : "Theo tên nhà cung cấp",
  },
  {
    name:"code",
    placeholder : "Theo mã nhà cung cấp",
  },
  {
    name:"group",
    placeholder : "Theo nhóm nhà cung cấp",
  },
  {
    name:"phoneNumber",
    placeholder : "Theo số điện thoại",
  },
  {
    name:"email",
    placeholder : "Theo số email",
  },
]

export const useDebounceSelectSupplier = (callback) => {
  
  const [initSupplier, setInitSupplier] = useState([])
  const getListSupplier = useCallback(async (query, setForm = false) => {
    let listSupplier = await api.supplier.getAll(query)
    // Fetch supplier
    const suppliers = get(listSupplier, "docs").map(item => {
      return {
        ...item,
        label: `${get(item, "supplierCode")} - ${get(item, "name")}`
      }
    });
    if (setForm) {
      // Set Init to Value to DebouneSelect must be a Array
      setInitSupplier(suppliers)
    }
    return suppliers
  }, [])

  const onSelectSupplier = (value) => {
    const { value: supplierId } = value;
    setInitSupplier([value])
    if(callback && typeof callback === 'function'){
      callback(supplierId)
    }
  }
  
  return {
    initSupplier,
    getListSupplier,
    onSelectSupplier
  }
}