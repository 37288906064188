export const GET_MEDICINES_REQUEST = 'GET_MEDICINES_REQUEST';
export const GET_MEDICINES_SUCCESS = 'GET_MEDICINES_SUCCESS';
export const GET_MEDICINES_FAILED = 'GET_MEDICINES_FAILED';

export const GET_MEDICINE_REQUEST = 'GET_MEDICINE_REQUEST';
export const GET_MEDICINE_SUCCESS = 'GET_MEDICINE_SUCCESS';
export const GET_MEDICINE_FAILED = 'GET_MEDICINE_FAILED';

export const CREATE_MEDICINE_REQUEST = 'CREATE_MEDICINE_REQUEST';
export const CREATE_MEDICINE_SUCCESS = 'CREATE_MEDICINE_SUCCESS';
export const CREATE_MEDICINE_FAILED = 'CREATE_MEDICINE_FAILED';

export const UPDATE_MEDICINE_REQUEST = 'UPDATE_MEDICINE_REQUEST';
export const UPDATE_MEDICINE_SUCCESS = 'UPDATE_MEDICINE_SUCCESS';
export const UPDATE_MEDICINE_FAILED = 'UPDATE_MEDICINE_FAILED';

export const DELETE_MEDICINE_REQUEST = 'DELETE_MEDICINE_REQUEST';
export const DELETE_MEDICINE_SUCCESS = 'DELETE_MEDICINE_SUCCESS';
export const DELETE_MEDICINE_FAILED = 'DELETE_MEDICINE_FAILED';

export const GET_MEDICINE_OPTIONS_REQUEST = 'GET_MEDICINE_OPTIONS_REQUEST';
export const GET_MEDICINE_OPTIONS_SUCCESS = 'GET_MEDICINE_OPTIONS_SUCCESS';
export const GET_MEDICINE_OPTIONS_FAILED = 'GET_MEDICINE_OPTIONS_FAILED';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const SET_SELECTED_PRODUCT_VARIANT = 'SET_SELECTED_PRODUCT_VARIANT';

export const RESET_MEDICINE_STATE = 'RESET_MEDICINE_STATE';

export const SEARCH_PRODUCTS_REQUEST = 'SEARCH_PRODUCTS_REQUEST';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILED = 'SEARCH_PRODUCTS_FAILED';

export const SEARCH_PRODUCT_REQUEST = 'SEARCH_PRODUCT_REQUEST';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const SEARCH_PRODUCT_FAILED = 'SEARCH_PRODUCT_FAILED';

export const SEARCH_VARIANTS_BY_PRODUCT_REQUEST =
  'SEARCH_VARIANTS_BY_PRODUCT_REQUEST';
export const SEARCH_VARIANTS_BY_PRODUCT_SUCCESS =
  'SEARCH_VARIANTS_BY_PRODUCT_SUCCESS';
export const SEARCH_VARIANTS_BY_PRODUCT_FAILED =
  'SEARCH_VARIANTS_BY_PRODUCT_FAILED';

export const GET_PRODUCT_STOCK_REQUEST = 'GET_PRODUCT_STOCK_REQUEST';
export const GET_PRODUCT_STOCK_SUCCESS = 'GET_PRODUCT_STOCK_SUCCESS';
export const GET_PRODUCT_STOCK_FAILED = 'GET_PRODUCT_STOCK_FAILED';

export const GET_PRODUCT_STOCK_CARD_REQUEST = 'GET_PRODUCT_STOCK_CARD_REQUEST';
export const GET_PRODUCT_STOCK_CARD_SUCCESS = 'GET_PRODUCT_STOCK_CARD_SUCCESS';
export const GET_PRODUCT_STOCK_CARD_FAILED = 'GET_PRODUCT_STOCK_CARD_FAILED';

export const GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_REQUEST =
  'GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_REQUEST';
export const GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_SUCCESS =
  'GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_SUCCESS';
export const GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_FAILED =
  'GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_FAILED';

export const GET_PRODUCT_LOT_REQUEST = 'GET_PRODUCT_LOT_REQUEST';
export const GET_PRODUCT_LOT_SUCCESS = 'GET_PRODUCT_LOT_SUCCESS';
export const GET_PRODUCT_LOT_FAILED = 'GET_PRODUCT_LOT_FAILED';
export const SET_SELECTED_PURCHASE_RETURN_PRODUCT_VARIANT =
  'SET_SELECTED_PURCHASE_RETURN_PRODUCT_VARIANT';

export const GET_PRODUCT_STOCK_CARD_OUTSIDE_REQUEST = 'GET_PRODUCT_STOCK_CARD_OUTSIDE_REQUEST';
export const GET_PRODUCT_STOCK_CARD_OUTSIDE_SUCCESS = 'GET_PRODUCT_STOCK_CARD_OUTSIDE_SUCCESS';
export const GET_PRODUCT_STOCK_CARD_OUTSIDE_FAILED = 'GET_PRODUCT_STOCK_CARD_OUTSIDE_FAILED';

export const SUBMIT_DRUG_REQUEST_REQUEST = 'SUBMIT_DRUG_REQUEST_REQUEST';
export const SUBMIT_DRUG_REQUEST_SUCCESS = 'SUBMIT_DRUG_REQUEST_SUCCESS';
export const SUBMIT_DRUG_REQUEST_FAILED = 'SUBMIT_DRUG_REQUEST_FAILED';

export const SUBMIT_TRY_DRUG_REQUEST_REQUEST = 'SUBMIT_TRY_DRUG_REQUEST_REQUEST';
export const SUBMIT_TRY_DRUG_REQUEST_SUCCESS = 'SUBMIT_TRY_DRUG_REQUEST_SUCCESS';
export const SUBMIT_TRY_DRUG_REQUEST_FAILED = 'SUBMIT_TRY_DRUG_REQUEST_FAILED';

export const GET_PRODUCT_DRUG_REQUEST_REQUEST = 'GET_PRODUCT_DRUG_REQUEST_REQUEST';
export const GET_PRODUCT_DRUG_REQUEST_SUCCESS = 'GET_PRODUCT_DRUG_REQUEST_SUCCESS';
export const GET_PRODUCT_DRUG_REQUEST_FAILED = 'GET_PRODUCT_DRUG_REQUEST_FAILED';
