import * as Types from '~/constants/actionTypes';

export const getSamplePrescriptions = query => ({
  type: Types.GET_SAMPLE_PRESCRIPTIONS_REQUEST,
  payload: query
});

export const getSamplePrescription = id => ({
  type: Types.GET_SAMPLE_PRESCRIPTION_REQUEST,
  payload: id
});

export const createSamplePrescription = data => ({
  type: Types.CREATE_SAMPLE_PRESCRIPTION_REQUEST,
  payload: data
});

export const updateSamplePrescription = value => ({
  type: Types.UPDATE_SAMPLE_PRESCRIPTION_REQUEST,
  payload: value
});

export const deleteSamplePrescription = id => ({
  type: Types.DELETE_SAMPLE_PRESCRIPTION_REQUEST,
  payload: id
});

export const resetSamplePrescriptionState = () => ({
  type: Types.RESET_SAMPLE_PRESCRIPTION_STATE
});

export const updateSamplePrescriptionNote = ({id,note}) => ({
  type: Types.UPDATE_SAMPLE_PRESCRIPTION_NOTE_REQUEST,
  payload: {
    id,
    note
  }
});
