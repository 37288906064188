import React, { useState, useEffect } from 'react';
import {
  useAppointmentEvents,
  useAppointmentQueryParams,
  useUpdateAppointment
} from '~/hooks/worldCare/appointment';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import viLocale from '@fullcalendar/core/locales/vi';
import { Modal, Row, Button, Select, Form, Col } from 'antd';
import { useSpecialityOptions } from '~/hooks/worldCare/speciality';
import { useAllHospitals } from '~/hooks/worldCare/hospital';
import AppointmentForm from './Form';
import './index.scss';
import { filterAcrossAccents } from '~/hooks/utils';
import {  PlusOutlined } from '@ant-design/icons';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import { CARD_STYLE } from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
const { Option } = Select;

function renderEventContent(eventInfo) {
  const { hospital } = eventInfo.event.extendedProps;
  return (
    <div className="apponintment-item">
      <div className="apponintment-item__title-box">
        <b>{eventInfo.timeText}</b>
        <p className="apponintment-item__title">{eventInfo.event.title}</p>
      </div>

      <p className="apponintment-item__hospital-name">
        {hospital?.hospitalName}
      </p>
    </div>
  );
}

const Appointments = ({ history }) => {
  const query = useAppointmentQueryParams();
  const [appointmentEvents, appointments] = useAppointmentEvents(query);
  const specialityOptions = useSpecialityOptions();
  const [, handleUpdate] = useUpdateAppointment();
  const [appointmentId, setAppointmentId] = useState(null);
  const [initStartTime, setInitStartTime] = useState(moment());
  const [isOpen, setIsOpen] = useState(false);
  const [allHostpital] = useAllHospitals();
  const [hospitalId, setHospitalId] = useState(null);
  const [status, setStatus] = useState(null);
  const [source, setSource] = useState(null);
  const [specialityId, setSpecialityId] = useState(null);

  const onAppointmentClick = (clickInfo) => {
    setIsOpen(true);
    setAppointmentId(clickInfo.event.id);
  };

  const onCreate = () => {
    setIsOpen(true);
    setAppointmentId(null);
  };

  useEffect(() => {
    const onSearch = () => {
      let searchParams = `?page=${query.page}&limit=${query.limit}`;
      if (hospitalId) searchParams += `&hospitalId=${hospitalId}`;
      if (status) searchParams += `&status=${status}`;
      if (source) searchParams += `&source=${source}`;
      if (specialityId) searchParams += `&specialityId=${specialityId}`;

      history.push({
        pathname: '/appointment',
        search: searchParams
      });
    };

    onSearch();
  }, [
    hospitalId,
    status,
    source,
    specialityId,
    history,
    query.limit,
    query.page
  ]);

  const onAppointmentChange = ({ event }) => {
    const updatedAppointment = appointments.find(({ _id }) => _id === event.id);
    handleUpdate({
      ...updatedAppointment,
      time: event.startStr,
      id: event.id
    });
  };

  return (
    <div className="page-wraper page-content appointment">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách lịch hẹn" />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <Row style={{ justifyContent: 'flex-end', marginBottom: 10 }}>
              {/* <Button type="primary" onClick={onSearch}>
                <SearchOutlined />
                Tìm kiếm
              </Button> */}

              <WithPermission permission={POLICY.WRITE_APPOINTMENT}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => {
                    setInitStartTime(moment());
                    onCreate();
                  }}
                >
                  <PlusOutlined />
                  Thêm mới
                </Button>
              </WithPermission>
            </Row>
            <Form layout="vertical" className="appointment-filter-box">
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Phòng khám"
                    className="appointment-filter-box__hospital"
                  >
                    <Select
                      value={hospitalId}
                      allowClear
                      onChange={(value) => setHospitalId(value)}
                      showSearch
                      autoComplete="off"
                      filterOption={filterAcrossAccents}
                    >
                      {allHostpital.map(({ _id, hospitalName }) => (
                        <Option key={_id} value={_id}>
                          {hospitalName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Dịch vụ"
                    className="appointment-filter-box__status"
                  >
                    {
                      <Select
                        allowClear
                        showSearch
                        value={specialityId}
                        onChange={(value) => setSpecialityId(value)}
                      >
                        {specialityOptions}
                      </Select>
                    }
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  label="Trạng thái"
                  className="appointment-filter-box__status"
                >
                  <Select
                    value={status}
                    onChange={(value) => setStatus(value)}
                    allowClear
                  >
                    <Option value="CREATED" key="CREATED">
                      Đã tạo
                    </Option>
                    <Option value="CONFIRMED" key="CONFIRMED">
                      Đã xác nhận
                    </Option>
                    <Option value="CANCEL" key="CANCEL">
                      Đã huỷ
                    </Option>
                    <Option value="ARRIVED" key="ARRIVED">
                      Đã hoàn thành
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Nguồn"
                  className="appointment-filter-box__status"
                >
                  <Select
                    value={source}
                    onChange={(value) => setSource(value)}
                    allowClear
                  >
                    <Option value="CALL" key="CALL">
                      Gọi điện
                    </Option>
                    <Option value="WEBSITE" key="WEBSITE">
                      Website
                    </Option>
                  </Select>
                </Form.Item>
              </Row>
            </Form>
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <FullCalendar
              headerToolbar={{
                left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                center: 'title',
                right: 'today prev,next'
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin
              ]}
              locales={[viLocale]}
              locale="vi"
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              events={appointmentEvents}
              eventContent={renderEventContent}
              eventClick={onAppointmentClick}
              eventChange={onAppointmentChange}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: 'short'
              }}
              eventDisplay="block"
            />
          </div>
        </div>
      </div>

      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <h1>{appointmentId ? ' Cập nhật' : 'Tạo mới'} lịch hẹn</h1>
        <AppointmentForm
          specialityOptions={specialityOptions}
          appointmentId={appointmentId}
          onClose={() => setIsOpen(false)}
          initStartTime={initStartTime}
        />
      </Modal>
    </div>
  );
};

export default Appointments;
