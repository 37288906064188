import React from 'react';
import { Popover, Form, Select, InputNumber } from 'antd';
import { DISCOUNT_TYPES } from '~/constants/defaultValue';
import currencyFormatter from '~/utils/currencyFormatter';

const FormItem = Form.Item;
const { Option } = Select;

const { PERCENT, VALUE } = DISCOUNT_TYPES;

const DiscountInput = ({
  discount,
  onTypeChange,
  onValueChange,
  disabled,
  max,
  children,
  long
}) => {
  return (
    <Popover
      placement="topRight"
      trigger="click"
      content={
        <FormItem label="Giảm giá" style={{ margin: 10 }}>
          <InputNumber
            size="small"
            style={{ width: 155 }}
            value={ discount?.type === DISCOUNT_TYPES.PERCENT ? discount?.percent : discount?.value }
            onChange={onValueChange}
            max={max}
            min={0}
            disabled={disabled}
            {...currencyFormatter}
          />
          <Select
            size="small"
            value={discount?.type}
            className="warehouse-form-table__discount-type"
            style={{ width: 70, height: 30 }}
            onChange={onTypeChange}
          >
            <Option value={VALUE}>VND</Option>
            <Option value={PERCENT}>%</Option>
          </Select>
        </FormItem>
      }
    >
      {children}
    </Popover>
  );
};

export default DiscountInput;
