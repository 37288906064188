import { DeleteOutlined, ExportOutlined, PrinterOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useState } from "react";
import { WithPermission } from "~/components/Common";
import ButtonExport from "~/components/Common/ModuleExport/ButtonExport";
import { API_LIST } from "~/constants/defaultValue";
import POLICIES from "~/constants/policy";

import HistoryPayment from "../Detail/HistoryPayment";
import Information from "../Detail/Information";  

const ExpandedRowRender = ({record,handleUpdate}) => {
    const [chooseUser,setChooseUser]=useState()
    const [saleChannel,setSaleChannel]=useState()
    const [note,setNote]=useState()

    const props= {
          chooseUser,
          saleChannel,
          setChooseUser,
          setSaleChannel
        }
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <Tabs.TabPane tab="Thông tin" key="1">
            <Information note={note} setNote={setNote} record={record}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Hóa đơn đổi hàng" key = "2">
            <HistoryPayment {...props} record={record}/>
          </Tabs.TabPane>
        </Tabs>
        <div className={'warehouse-history-payment product-return-detail-tab-item'}>
        <div className='cta' style={{textAlign:'right'}}>

        <WithPermission permission={POLICIES.UPDATE_RETURN}>
          <Button onClick={()=>handleUpdate({record,chooseUser,saleChannel,note})} type={'primary'}> <SaveOutlined /> <span className={'cta_name'}>Lưu</span></Button>
        </WithPermission>
        
        <WithPermission permission={POLICIES.WRITE_RETURN}>
          <Button type="secondary"> <PrinterOutlined /> <span className={'cta_name'}>In</span></Button>
          <ButtonExport router={API_LIST.productReturn} label="Trả hàng" id={record._id} fileName={`Chitiettrahang_${record.code}`}/>
        </WithPermission>

        {/* <Button type="secondary"> <ExportOutlined /> <span className={'cta_name'}>Xuất file</span></Button> */}
        <WithPermission permission={POLICIES.DELETE_RETURN}>
          <Button type="danger"> <DeleteOutlined /> <span className={'cta_name'}> Hủy bỏ</span></Button>
        </WithPermission>

      </div>
      </div>
      </div>
    );
  };
  export default ExpandedRowRender;