import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getNearDueProductReport({ payload: query }) {
  try {
    const data = yield call(Api.productReport.getAll, query);
    yield put({ type: Types.GET_NEAR_DUE_PRODUCTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_NEAR_DUE_PRODUCTS_FAILED,
      payload: error.message
    });
  }
}

export default function* productReport() {
  yield takeLatest(Types.GET_NEAR_DUE_PRODUCTS_REQUEST, getNearDueProductReport);
}
