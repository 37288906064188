export const GET_PURCHASE_ORDER_QUOTATIONS_REQUEST = 'GET_PURCHASE_ORDER_QUOTATIONS_REQUEST';
export const GET_PURCHASE_ORDER_QUOTATIONS_SUCCESS = 'GET_PURCHASE_ORDER_QUOTATIONS_SUCCESS';
export const GET_PURCHASE_ORDER_QUOTATIONS_FAILED = 'GET_PURCHASE_ORDER_QUOTATIONS_FAILED';

export const GET_PURCHASE_ORDER_QUOTATION_REQUEST = 'GET_PURCHASE_ORDER_QUOTATION_REQUEST';
export const GET_PURCHASE_ORDER_QUOTATION_SUCCESS = 'GET_PURCHASE_ORDER_QUOTATION_SUCCESS';
export const GET_PURCHASE_ORDER_QUOTATION_FAILED = 'GET_PURCHASE_ORDER_QUOTATION_FAILED';

export const CREATE_PURCHASE_ORDER_QUOTATION_REQUEST = 'CREATE_PURCHASE_ORDER_QUOTATION_REQUEST';
export const CREATE_PURCHASE_ORDER_QUOTATION_SUCCESS = 'CREATE_PURCHASE_ORDER_QUOTATION_SUCCESS';
export const CREATE_PURCHASE_ORDER_QUOTATION_FAILED = 'CREATE_PURCHASE_ORDER_QUOTATION_FAILED';

export const UPDATE_PURCHASE_ORDER_QUOTATION_REQUEST = 'UPDATE_PURCHASE_ORDER_QUOTATION_REQUEST';
export const UPDATE_PURCHASE_ORDER_QUOTATION_SUCCESS = 'UPDATE_PURCHASE_ORDER_QUOTATION_SUCCESS';
export const UPDATE_PURCHASE_ORDER_QUOTATION_FAILED = 'UPDATE_PURCHASE_ORDER_QUOTATION_FAILED';

export const DELETE_PURCHASE_ORDER_QUOTATION_REQUEST = 'DELETE_PURCHASE_ORDER_QUOTATION_REQUEST';
export const DELETE_PURCHASE_ORDER_QUOTATION_SUCCESS = 'DELETE_PURCHASE_ORDER_QUOTATION_SUCCESS';
export const DELETE_PURCHASE_ORDER_QUOTATION_FAILED = 'DELETE_PURCHASE_ORDER_QUOTATION_FAILED';

export const RESET_PURCHASE_ORDER_QUOTATION_STATE = 'RESET_PURCHASE_ORDER_QUOTATION_STATE';
