import {
  GET_USER_EMPLOYEES_REQUEST,
  GET_USER_EMPLOYEE_REQUEST,
  CREATE_USER_EMPLOYEE_REQUEST,
  UPDATE_USER_EMPLOYEE_REQUEST,
  DELETE_USER_EMPLOYEE_REQUEST,
  RESET_USER_EMPLOYEE_STATE,
  CREATE_USERNAME_REQUEST
} from '~/constants/actionTypes';

export const getUserEmployees = (query) => ({
  type: GET_USER_EMPLOYEES_REQUEST,
  payload: query
});

export const getUserEmployee = (id) => ({
  type: GET_USER_EMPLOYEE_REQUEST,
  payload: id
});

export const createUserEmployee = (userEmployee) => ({
  type: CREATE_USER_EMPLOYEE_REQUEST,
  payload: userEmployee
});

export const updateUserEmployee = (userEmployee) => ({
  type: UPDATE_USER_EMPLOYEE_REQUEST,
  payload: userEmployee
});

export const deleteUserEmployee = (id) => ({
  type: DELETE_USER_EMPLOYEE_REQUEST,
  payload: id
});

export const createUsername = (username) => ({
  type: CREATE_USERNAME_REQUEST,
  payload: username
})

export const resetUserEmployeeState = () => ({
  type: RESET_USER_EMPLOYEE_STATE
});
