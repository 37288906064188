import { useMemo } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors
} from '~/hooks/utils';

import {
  getEmployeeGroups,
  createEmployeeGroup,
  deleteEmployeeGroup,
  getEmployeeGroup,
  resetEmployeeGroupState,
  updateEmployeeGroup,
  getResources,
  updateGroupPermission,
  getResourcesBranch
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const STORE_MODULE = 'employeeGroup';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(STORE_MODULE);

const getSelector = (key) => (state) => state[STORE_MODULE][key];

const getResourcesLoadingSelector = getSelector('isGetResourcesLoading');
const resourcesSelector = getSelector('resources');
const getResourcesFailedSelector = getSelector('getResourcesFailed');

const getUpdateFailedSelector = getSelector('updatePermissionFailed');

const isUpdatePermissionLoadingSelector = getSelector(
  'isUpdatePermissionLoading'
);
const actionsSelector = getSelector('actions');

export const useResources = (param) => {
  const { id, branchId } = useParams();

  const branchParam = useMemo(() => !param ? ({
   branchId: branchId ?? id
  }) : {}, [branchId, id]);

  return useFetchByParam({
    action: getResources,
    loadingSelector: getResourcesLoadingSelector,
    dataSelector: resourcesSelector,
    failedSelector: getResourcesFailedSelector,
    param: branchParam 
  });
};


export const useResourceColumns = (renderPermission) => {
  const actions = useSelector(actionsSelector);
  const actionColumns = actions.map(({ name, key }) => {
    return({
    title: name,
    dataIndex: key,
    key: key,
    width: '12%',
    align: 'center',
    render: renderPermission
  })});

  return [
    {
      title: 'Chức năng',
      dataIndex: ['resource', 'name'],
      key: 'resource'
    },
    ...actionColumns
  ];
};

export const useEmployeeGroups = (param) => {
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  const memoParam = useMemo(() => {
    return { ...param };
    //eslint-disable-next-line
  }, [createSuccess, updateSuccess, deleteSuccess, param]);

  return useFetchByParam({
    action: getEmployeeGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: memoParam
  });
};

export const useCreateEmployeeGroup = (callback) => {
  useSuccess(
    createSuccessSelector,
    'Tạo mới nhóm nhân viên thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createEmployeeGroup
  });
};

export const useUpdateEmployeeGroup = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật nhóm nhân viên thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateEmployeeGroup
  });
};

export const useDeleteEmployeeGroup = (deleteCallback) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá nhóm nhân viên thành công',
    deleteCallback
  );
  useFailed(deleteFailedSelector, 'Xoá nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteEmployeeGroup
  });
};

export const useEmployeeGroup = (params) => {
  return useFetchByParam({
    action: getEmployeeGroup,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};


export const useInitEmployeeGroup = (employeeGroup, id) =>
  useMemo(() => {
    if (!employeeGroup || !id) {
      return {};
    }

    return employeeGroup;
  }, [employeeGroup, id]);

export const useResetEmployeeGroup = () => {
  useResetState(resetEmployeeGroupState);
};

export const useUpdateGroupPermission = () => {
  return useSubmit({
    loadingSelector: isUpdatePermissionLoadingSelector,
    action: updateGroupPermission
  });
};

