import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getMedicineUnits,
  createMedicineUnit,
  deleteMedicineUnit,
  getMedicineUnit,
  resetMedicineUnitState,
  updateMedicineUnit
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS } from '~/constants/defaultValue';
const MEDICINE_UNIT_MODULE = 'medicineUnit';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(MEDICINE_UNIT_MODULE);

export const useMedicineUnits = param => {
  return useFetchByParam({
    action: getMedicineUnits,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param
  });
};

export const useCreateMedicineUnit = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới đơn vị thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới đơn vị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicineUnit
  });
};

export const useUpdateMedicineUnit = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật đơn vị thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật đơn vị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicineUnit
  });
};

export const useDeleteMedicineUnit = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, 'Xoá đơn vị thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá đơn vị thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteMedicineUnit
  });
};

export const useMedicineUnit = id => {
  return useFetchByParam({
    action: getMedicineUnit,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitMedicineUnit = (medicineUnit, id) => {
  return useMemo(() => {
    if (!medicineUnit || !id) {
      return { status: true };
    }

    return {
      ...medicineUnit,
      status: medicineUnit.status === STATUS.ACTIVE ? true : false
    };
  }, [medicineUnit, id]);
};

export const useUpdateMedicineUnitParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    history.push({
      pathname: '/medicine-unit',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useMedicineUnitQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const name = query.get('name');
  const page = query.get('page') || 1;
  const status = query.get('status');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== name) {
    prevKeyword.current = name;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      name,
      status
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, name, createSuccess, updateSuccess, deleteSuccess, status]);
};

export const useMedicineUnitPaging = () => useSelector(pagingSelector);

export const useResetMedicineUnit = () => {
  useResetState(resetMedicineUnitState);
};
