import React from 'react';
import { Row, Col, Table, Button, Tag } from 'antd';
import { formatNumber, formatDate } from '~/utils/helper';
import {
  DeleteOutlined,
  ExportOutlined,
  PrinterOutlined,
  SaveOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { PAYMENTMETHOD } from '~/hooks';
import { INVOICE_STATUS } from '~/constants/defaultValue';
import ItemInformation from './ItemInformation';
import { get } from 'lodash';
const columns = [
  {
    title: 'Mã hàng',
    key: 'productCode',
    dataIndex: ['variantId', 'variantCode'],
    className: 'wpx85',
    width: 150
  },
  {
    title: 'Tên hàng',
    key: 'name',
    dataIndex: ['productId', 'name'],
    className: 'wpx120'
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    key: 'quantity',
    render: quantity => formatNumber(quantity),
    align: 'end',
    className: 'wpx85'
  },
  {
    title: 'Giá bán',
    dataIndex: 'price',
    key: 'price',
    align: 'end',
    className: 'wpx120',
    render: price => formatNumber(price)
  },
  {
    title: 'Thành tiền',
    dataIndex: 'price',
    key: 'total',
    align: 'end',
    className: 'wpx120',
    render: (price, _) => {
      return formatNumber(_?.quantity * price);
    }
  }
];
const Information = ({ record }) => {
  const invoiceReturnBefore = record?.productReturnDetail?.reduce(
    (acc, crr) => acc + crr.quantity * crr.price,
    0
  );
  const needPayment = record?.totalReturn - invoiceReturnBefore + record?.fee;
  return (
    <div className={'warehouse-info product-return-detail-tab-item'}>
      <Row gutter={48}>
        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Mã trả hàng'}
                  value={get(record, 'code', '')}
                  style={{ fontWeight: '600' }}
                />
              </Row>
            </Col>

            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Thời gian'}
                  value={moment(record?.createdAt).format('DD/MM/YYYY HH:mm')}
                />
              </Row>
            </Col>

            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Khách hàng'}
                  value={get(record, ['customer', 'name'], 'Khách lẻ')}
                />
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Trạng thái'}
                  value={INVOICE_STATUS[record?.invoice?.status]}
                />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Chi nhánh'}
                  value={get(record, ['branch', 'name', 'vi'], '')}
                />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Người nhận trả'}
                  value={get(record, ['receivedBy', 'fullName'], '')}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Kênh bán'}
                  value={PAYMENTMETHOD[record?.paymentNote?.paymentMethod]}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={48}>
        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation title={'Bảng giá'} value={'Bảng giá chung'} />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Mã đơn'}
                  value={get(record, ['invoice', 'code'])}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={48}>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Mã phiếu chi'}
                  value={get(record, ['paymentNote', 'code'], '')}
                />
              </Row>
            </Col>
            <Col span={24} className={'mb10px'}>
              <Row gutter={0}>
                <ItemInformation
                  title={'Người tạo'}
                  value={get(record, ['createdBy', 'name'], '')}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={'table-wrapper'}>
        <Table
          className="nested-table"
          dataSource={record?.productReturnDetail}
          columns={columns}
          size="small"
          pagination={false}
          rowKey={({ variantId }) => variantId}
          expandable={{
            expandedRowRender: item => (
              <div style={{ paddingLeft: 150 }}>
                <Row>
                  <Tag color="#2db7f5">
                    {`${item?.batchId?.lotNumber} - ${(item?.batchId?.batchSearch[2])}`}
                  </Tag>
                </Row>
              </div>
            ),
            defaultExpandAllRows: true,
            expandedRowKeys: record?.productReturnDetail?.map(
              ({ variantId }) => variantId
            ),
            expandIcon: () => <></>
          }}
        />
        <div className={'info-bill'} style={{ marginTop: 30 }}>
          <Row gutter={0}>
            <span>Tổng số lượng:</span>
            <span className="wpx90">
              {record?.productReturnDetail?.reduce(
                (acc, crr) => acc + crr.quantity,
                0
              )}
            </span>
          </Row>
          <Row gutter={0}>
            <span>Tổng tiền hàng trả:</span>
            <span className="wpx90"> {formatNumber(invoiceReturnBefore)}</span>
          </Row>
          {/*<Row gutter={0}>*/}
          {/*  <span>Giảm giá phiếu trả:</span>*/}
          {/*  <span className='wpx90'>0</span>*/}
          {/*</Row>*/}
          <Row gutter={0}>
            <span>Phí trả hàng:</span>
            <span className="wpx90">{formatNumber(record?.fee)}</span>
          </Row>
          <Row gutter={0}>
            <span>Tổng tiền hóa đơn trả:</span>
            <span className="wpx90"> {formatNumber(invoiceReturnBefore)}</span>
          </Row>
          <Row gutter={0}>
            <span>Tổng tiền hóa đơn đổi hàng:</span>
            <span className="wpx90">{formatNumber(record?.totalReturn)}</span>
          </Row>
          <Row gutter={0}>
            <span>Khách cần trả:</span>
            <span className="wpx90">{formatNumber(needPayment)}</span>
          </Row>
          <Row gutter={0}>
            <span>Khách đã trả:</span>
            <span className="wpx90">{formatNumber(invoiceReturnBefore)}</span>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Information;
