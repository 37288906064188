import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getPartners({ payload: companyId }) {
  try {
    const data = yield call(Api.partner.getAll, companyId);
    yield put({ type: Types.GET_PARTNERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNERS_FAILED,
      payload: error.message
    });
  }
}

function* getPartner({ payload }) {
  try {
    const partner = yield call(Api.partner.getById, payload);
    yield put({
      type: Types.GET_PARTNER_SUCCESS,
      payload: partner
    });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* createPartner(action) {
  try {
    const data = yield call(Api.partner.create, action.payload);
    yield put({ type: Types.CREATE_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* updatePartner(action) {
  try {
    const data = yield call(Api.partner.update, action.payload);
    yield put({ type: Types.UPDATE_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PARTNER_FAILED,
      payload: error.message
    });
  }
};

function* updateApiKeyPartner(action) {
  try {
    const data = yield call(Api.partner.updateApiKey, action.payload);
    yield put({ type: Types.UPDATE_API_KEY_PARTNER_SAVE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_API_KEY_PARTNER_SAVE_FAILED,
      payload: error.message
    });
  }
};

function* deletePartner({ payload }) {
  try {
    yield call(Api.partner.delete, payload);
    yield put({ type: Types.DELETE_PARTNER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* getBranches({ payload }) {
  try {
    const branches = yield call(Api.partner.getBranches, payload);
    yield put({ type: Types.GET_PARTNER_BRANCHES_SUCCESS, payload: branches });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNER_BRANCHES_FAILED,
      payload: error.message
    });
  }
}

export default function* partner() {
  yield takeLatest(Types.GET_PARTNERS_REQUEST, getPartners);
  yield takeLatest(Types.GET_PARTNER_REQUEST, getPartner);
  yield takeLatest(Types.DELETE_PARTNER_REQUEST, deletePartner);
  yield takeLatest(Types.CREATE_PARTNER_REQUEST, createPartner);
  yield takeLatest(Types.UPDATE_PARTNER_REQUEST, updatePartner);
  yield takeLatest(Types.GET_PARTNER_BRANCHES_REQUEST, getBranches);

  yield takeLatest(Types.UPDATE_API_KEY_PARTNER_SAVE_REQUEST, updateApiKeyPartner);

}
