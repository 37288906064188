import React, { useMemo, useState } from 'react';
import { Table, Input, Typography } from 'antd';
import { formatCurrency, formatNumber } from '~/utils/helper';
import { SearchOutlined } from '@ant-design/icons';
import { compact } from 'lodash';

const { Text } = Typography;

const DetailTable = ({ purchaseReturn }) => {
  const [codeKeyword, setCodeKeyword] = useState(null);
  const [nameKeyword, setNameKeyword] = useState(null);

  const datasource = useMemo(() => {
    let displayedOrders = purchaseReturn.purchaseReturnItems
      .filter(order =>
        !codeKeyword
          ? true
          : order.productVariant.variantCode
              .toLowerCase()
              .includes(codeKeyword.toLowerCase())
      )
      .filter(order =>
        !nameKeyword
          ? true
          : order.product?.name
              ?.toLowerCase()
              ?.includes?.(nameKeyword.toLowerCase())
      );

    const data = displayedOrders.map(order => {
      return {
        ...order,
        name: `${order?.product?.name}`
      };
    });

    return [{ isSummaryRow: true, variantId: -1 }, ...data];
  }, [codeKeyword, nameKeyword, purchaseReturn]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'code',
        render: record => {
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm mã hàng"
              value={codeKeyword}
              onChange={e => setCodeKeyword(e.target.value)}
            />
          ) : (
            record.productVariant?.variantCode
          );
        },
        width: 150
      },
      {
        title: 'Tên hàng',
        key: 'name',
        render: record => {
          const lotArea = record?.batches?.reduce((cur,pre)=> [...cur,(compact(pre?.batch?.batchSearch).join(" - "))],[])
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm tên hàng"
              value={nameKeyword}
              onChange={e => setNameKeyword(e.target.value)}
            />
          ) : (
            <>
             {record.product?.name}
             {lotArea && <p style={{marginTop:4}}>{lotArea?.map((e)=><span style={{backgroundColor:"#3d7ff3",padding:3,borderRadius:2 ,marginRight :10 ,color:'white'}}>{e}</span>) }</p>}
                         
            </>
          );
        },
        width: 400
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'quantity',
        align: 'right'
      },
      {
        title: 'Giá nhập',
        key: 'buyPrice',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.buyPrice);
        },
        align: 'right'
      },
      {
        title: 'Giảm giá',
        key: 'discountValue',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.discountValue);
        },
        align: 'right'
      },

      {
        title: 'Giá trả lại',
        key: 'returnPrice',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.returnPrice);
        },
        align: 'right'
      },

      {
        title: 'Thành tiền',
        key: 'finalPrice',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.finalPrice);
        },
        align: 'right'
      }
    ];
  }, [codeKeyword, nameKeyword]);

  const summary = useMemo(() => {
    const caculateTotal = (data, key) => {
      return data.reduce((total, record) => total + record[key], 0);
    };

    const totalQuantity = caculateTotal(
      purchaseReturn.purchaseReturnItems,
      'quantity'
    );
    const totalProduct = purchaseReturn.purchaseReturnItems.length;
    const totalAmount = caculateTotal(
      purchaseReturn.purchaseReturnItems,
      'finalPrice'
    );
    const totalPaid = caculateTotal(
      purchaseReturn.paymentNotes,
      'paymentAmount'
    );

    const summaryData = [
      {
        label: 'Tổng số lượng:',
        value: formatNumber(totalQuantity)
      },
      {
        label: 'Tổng số mặt hàng:',
        value: totalProduct
      },
      {
        label: 'Tổng tiền hàng:',
        value: formatCurrency(totalAmount)
      },
      {
        label: ' Giảm giá:',
        value: formatCurrency(purchaseReturn.discountValue)
      },
      {
        label: 'Tổng cộng:',
        value: formatCurrency(totalAmount - purchaseReturn.discountValue)
      },
      {
        label: 'Tiền NCC đã trả:',
        value: formatCurrency(totalPaid)
      }
    ];

    return summaryData.map(({ label, value }, index) => {
      return (
        <Table.Summary.Row key={index}>
          <Table.Summary.Cell colSpan={6} align="right">
            {label}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{value}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });
  }, [purchaseReturn]);

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        className="nested-table"
        columns={columns}
        dataSource={datasource}
        size="small"
        pagination={false}
        // showExpandColumn={true}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <p
        //       style={{
        //         margin: 0,
        //       }}
        //     >
        //       hjhj
        //     </p>
        //   ),
        //   rowExpandable: (record) =>!record.isSummaryRow,
        //   defaultExpandAllRows:()=>true
          
        
        // }}
        rowKey={rc => rc.variantId}
        summary={() => (
          <Table.Summary className="table-summary">{summary}</Table.Summary>
        )}
       
      />
    </div>
  );
};

export default DetailTable;
