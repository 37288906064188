import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getReceiptsPaymentType({ payload: query }) {
  try {
    const data = yield call(Api.receiptPaymentType.getAll, query);
    yield put({ type: Types.GET_RECEIPTS_PAYMENT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RECEIPTS_PAYMENT_TYPE_FAILED,
      payload: error
    });
  }
}

function* getReceiptPaymentType({ payload: id }) {
  try {
    const data = yield call(Api.receiptPaymentType.getById, id);
    yield put({ type: Types.GET_RECEIPT_PAYMENT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_RECEIPT_PAYMENT_TYPE_FAILED, payload: error });
  }
}

function* createReceiptPaymentType(action) {
  try {
    const data = yield call(Api.receiptPaymentType.create, action.payload);
    yield put({ type: Types.CREATE_RECEIPT_PAYMENT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_RECEIPT_PAYMENT_TYPE_FAILED,
      payload: error
    });
  }
}

function* updateReceiptPaymentType(action) {
  try {
    const data = yield call(Api.receiptPaymentType.update, action.payload);
    yield put({ type: Types.UPDATE_RECEIPT_PAYMENT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.UPDATE_RECEIPT_PAYMENT_TYPE_FAILED, payload: error });
  }
}

function* deleteReceiptPaymentType({ payload }) {
  try {
    yield call(Api.receiptPaymentType.delete, payload);
    yield put({ type: Types.DELETE_RECEIPT_PAYMENT_TYPE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.DELETE_RECEIPT_PAYMENT_TYPE_FAILED, payload: error });
  }
}

function* getReceiptsPaymentFilterType({ payload: query }) {
  try {
    const data = yield call(Api.receiptPaymentType.getAllFilter, query);
    yield put({ type: Types.GET_RECEIPTS_PAYMENT_FILTER_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RECEIPTS_PAYMENT_FILTER_TYPE_FAILED,
      payload: error
    });
  }
}

export default function* receiptPaymentType() {
  yield takeLatest(Types.GET_RECEIPTS_PAYMENT_TYPE_REQUEST, getReceiptsPaymentType);
  yield takeLatest(Types.GET_RECEIPT_PAYMENT_TYPE_REQUEST, getReceiptPaymentType);
  yield takeLatest(Types.CREATE_RECEIPT_PAYMENT_TYPE_REQUEST, createReceiptPaymentType);
  yield takeLatest(Types.UPDATE_RECEIPT_PAYMENT_TYPE_REQUEST, updateReceiptPaymentType);
  yield takeLatest(Types.DELETE_RECEIPT_PAYMENT_TYPE_REQUEST, deleteReceiptPaymentType);
  yield takeLatest(Types.GET_RECEIPTS_PAYMENT_FILTER_TYPE_REQUEST, getReceiptsPaymentFilterType);
}
