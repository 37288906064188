import requester from './requester';

const stockTake = {
  getAll: query => requester.get('/stocktake', query),
  getById: id => requester.get(`/stocktake/${id}`),
  create: stockTake => requester.post('/stocktake', stockTake),
  update: stockTake => requester.patch(`/stocktake/${stockTake._id}`, {
    ...stockTake
  }),
  searchStockTake: keyword =>
    requester.get('stocktake/search', { keyword }),
  saveLumped: stockTake => requester.post('/stocktake/saveStockTakeLumped', stockTake),
  delete: id => requester.delete(`/stocktake/${id}`)
};

export default stockTake;
