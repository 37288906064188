import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getProductOrders,
  createProductOrder,
  deleteProductOrder,
  getProductOrder,
  resetProductOrderState,
  updateProductOrder,
  updateStatusProductOrderBillPM
} from '~/redux/action';
import { Menu, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const PRODUCT_ORDER_MODULE = 'productOrder';

const CREATE_SUCCESS_MESS = 'Tạo mới đặt hàng thành công';
const CREATE_FAILED_MESS = 'Tạo mới đặt hàng thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật đặt hàng thành công';
const UPDATE_FAILED_MESS = 'Cập nhật đặt hàng thất bại';
const DELETE_SUCCESS_MESS = 'Xoá đặt hàng thành công';
const DELETE_FAILED_MESS = 'Xoá đặt hàng thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(PRODUCT_ORDER_MODULE);

export const useProductOrders = query => {
  return useFetchByParam({
    action: getProductOrders,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateProductOrder = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createProductOrder
  });
};

export const useUpdateProductOrder = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProductOrder
  });
};

export const useUpdateStatusProductOrderBillPM = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateStatusProductOrderBillPM
  });
};

export const useDeleteProductOrder = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteProductOrder
  });
};

export const useProductOrder = params => {
  return useFetchByParam({
    action: getProductOrder,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitProductOrder = (warehouse, id) => {
  return useMemo(() => {
    if (!warehouse || !id) {
      return {};
    }

    return { ...warehouse };
  }, [warehouse, id]);
};

export const useUpdateProductOrderParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/product-order',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useProductOrderQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page');
  const id = query.get('id');
  const lotNumber = query.get('lotNumber');
  const expirationDate = query.get('expirationDate');
  const code = query.get('code');
  const variantCodeProductName = query.get('variantCodeProductName');
  const purchasedAt = query.get('purchasedAt');
  const supplierName = query.get('supplierName');
  const status = query.get('status');
  const customer = query.get('customer');

  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      id,
      lotNumber,
      expirationDate,
      code,
      variantCodeProductName,
      supplierName,
      purchasedAt,
      status,
      customer
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, 
    limit, 
    keyword, 
    deleteSuccess, 
    id,
    lotNumber,
    expirationDate,
    code,
    variantCodeProductName,
    supplierName,
    purchasedAt,
    status,
    customer
  ]);
};

export const useProductOrderPaging = () => useSelector(pagingSelector);

export const useResetProductOrder = () => {
  useResetState(resetProductOrderState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã đặt hàng',
    key: 'code'
  },
  {
    title: 'Mã đặt hàng từ World Pharma',
    key: 'code'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Khách hàng',
    key: 'customer'
  },
  {
    title: 'Giảm giá',
    key: 'discount'
  },
  {
    title: 'Khách cần trả',
    key: 'customerNeedToPay'
  },
  {
    title: 'Khách đã trả',
    key: 'customerPaid'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  },
  {
    title: 'Thao tác',
    key: 'action'
  }
];

export const useProductOrderColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    billCode: true,
    time: true,
    customer: true,
    discount: true,
    customerNeedToPay: true,
    customerPaid: true,
    status: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};
export const SEARCH_PRODUCT_ORDER= [
  {
    name: "code",
    placeholder:"Theo mã đặt hàng"
},
{
    name: "variantCodeProductName",
    placeholder:"Theo mã, tên hàng"
},
{
    name: "lotNumber",
    placeholder:"Theo lô"
},
{
    name: "customer",
    placeholder:"Theo khách hàng hoặc số điện thoại"
},
{
    name: "expirationDate",
    type: "date",
    label:"Hạn sử dụng",
},
{
    name: "purchasedAt",
    type: "rangerTime",
    label:"Thời gian",
},
{
    name: "status",
    type: "radio",
    label: "Trạng thái",
}  
]