import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Input, Row, Button, Modal, Tag, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useBatches } from '~/hooks';
import { get } from 'lodash';

const findById = (data, id) =>
  (data || []).find(item => item.batchId === id || item._id === id);

const formatDate = time => moment(time).format('DD/MM/YYYY');
const isMatchFilter = (a, b) => a.toLowerCase().includes(b.toLowerCase());

const SelectBatch = ({ isOpen, onClose, variant, onSubmit, isSingle }) => {
  const [batches, setBatches] = useState([]);

  const [totalBatches] = useBatches(variant.productId);
  const initBatches = useCallback(
    batches => {
      const data = batches.map(batch => {
        const selectedBatch = findById(variant?.selectedBatches, batch._id);

        if (!selectedBatch) {
          return { ...batch, isSelected: false, quantity: batch.quantity };
        }

        return {
          ...batch,
          isSelected: true,
          quantity: selectedBatch.quantity,
        };
      });

      return data;
    },
    [variant?.selectedBatches]
  );

  useEffect(() => {
    if (totalBatches.length) {
      setBatches(initBatches(totalBatches.map(batch => ({
        ...batch,
        availableQuantity: batch.quantity
      }))));
    }
  }, [totalBatches, initBatches]);

  const [keyword, setKeyword] = useState(null);

  const onUpdateBatches = (prop, id, value) => {
    const nextBatches = batches.map(batch =>
      batch._id !== id
        ? {
            ...batch,
            isSelected:
              isSingle && prop === 'isSelected' ? false : batch.isSelected
          }
        : {
            ...batch,
            [prop]: value,
            isSelected: true,
            availableQuantity: batch.availableQuantity,
          }
    );
    setBatches(nextBatches);
  };

  const displayedBatches = useMemo(() => {
    if (!keyword) return batches;

    return batches.filter(
      ({ lotNumber, expirationDate }) =>
        isMatchFilter(lotNumber, keyword) ||
        isMatchFilter(formatDate(expirationDate), keyword)
    );
  }, [batches, keyword]);

  const onFinish = () => {
    onSubmit(batches);
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Row style={{ marginBottom: 20 }}>
          <h4 style={{ marginRight: 'auto' }}>Chọn lô, hạn sử dụng</h4>
        </Row>

        <Row>
          <Input
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            placeholder="Tìm lô, hạn sử dụng"
            prefix={<SearchOutlined style={{ color: '#ced4d9' }} />}
          />
        </Row>

        <div style={{ marginTop: 30 }}>
          {displayedBatches.map((batch, index) => (
            <Row className="sp-parcel-item" key={index}>
              <Tag
                onClick={() =>
                  onUpdateBatches(
                    'isSelected',
                    batch._id,
                    isSingle ? true : !batch.isSelected
                  )
                }
                color={batch.isSelected && '#2db7f5'}
              >
                {batch.lotNumber} - {formatDate(batch.expirationDate)} - TK: {get(batch,'availableQuantity',0)}
              </Tag>

              <InputNumber
                min={0}
                onChange={val => onUpdateBatches('quantity', batch._id, val)}
                value={batch.quantity}
                bordered={false}
                className="warehouse-form__custom-input"
              />
            </Row>
          ))}
        </div>

        <Row className="wc-setting-form__submit-box" style={{ marginTop: 40 }}>
          {false ? (
            <Button disabled>Huỷ</Button>
          ) : (
            <Button onClick={onClose}>Huỷ</Button>
          )}

          <Button type="primary" htmlType="submit" onClick={onFinish}>
            Chọn
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default SelectBatch;
