import { get, groupBy } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit
} from '~/hooks/utils';
import {
  getMyNotifications,
  resetMyNotificationState,
  updateManyStatusNotification,
  updateStatusNotification
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.myNotification[key];
const pagingSelector = getSelector('paging');
const countUnreadSelector = getSelector('countUnread');
export const useMyNotificationPaging = () => useSelector(pagingSelector);
export const useCountUnreadMyNotification = () => useSelector(countUnreadSelector);

const loadingSelector = getSelector('isLoading');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const getMyNotificationsFailedSelector = getSelector('getMyNotificationsFailed');

const MyNotificationsSelector = getSelector('myNotifications');


export const useMyNotificationQueryParams = (reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const status = query.get('status');

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      status,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    reFetch,
    status,
  ]);
};

export const useUpdateMyNotificationParams = (query) => {
  const history = useHistory();
  const {path} = useRouteMatch()
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: path,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};
export const useMyNotifications = (query) => {
  return useFetchByParam({
    action: getMyNotifications,
    loadingSelector,
    dataSelector: MyNotificationsSelector,
    failedSelector: getMyNotificationsFailedSelector,
    param: query
  });
};


export const useResetMyNotification = () => {
  useResetState(resetMyNotificationState);
};

export const useMergeInitNotifications = (notifications) => {
  const groupByDate = useMemo(() => {
    return groupBy(notifications,(e) => {
      const date = moment(get(e,'createdAt'));
      if(date.format('DD') === moment().format('DD')) {
        return 'Hôm nay'
      };
      if(moment().diff(date,'d') >= 2){
        return date.format('DD-MM-YYYY')
      }else{
        return date.fromNow()
      }
    });
  },[notifications])
  return groupByDate;
}

export const useChangeStatusNotification = () => {

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateStatusNotification
  });
};

export const useChangeManyStatusNotification = () => {

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateManyStatusNotification
  });
};
