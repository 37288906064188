import React, { useEffect, useMemo, useState } from 'react';
import { Spin, Table } from 'antd';
import { useCustomerDebt, useCustomerPaging, useCustomerQueryParams, useDebtPaging } from '~/hooks';
import { formatNumber } from '~/utils/helper';
import moment from 'moment';

const columns = [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Thời gian',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: createdAt => {
      return moment(createdAt).format('DD/MM/YYYY HH:mm:ss');
    }
  },
  {
    title: 'Loại',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Giá trị	',
    dataIndex: 'value',
    key: 'value',
    align: 'end',
    render: amount => formatNumber(amount)
  },
  {
    title: 'Dư nợ khách hàng',
    dataIndex: 'balance',
    key: 'balance',
    align: 'end',
    render: debt => formatNumber(debt)
  }
];

const Stock = ({ customerId }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [debt,isLoading] = useCustomerDebt({
    page: currentPage,
    limit
  }, customerId);
  const paging = useDebtPaging()
  return (
    <div className="medicine-detail-tab-item medicine-remain-tab">
      <Spin spinning={isLoading}>
        <Table
          className="nested-table"
          style={{ marginTop: 20, marginBottom: 30, marginLeft: -20 }}
          size="small"
          columns={columns}
          dataSource={debt}
          rowKey={rc => rc.code}
          bordered
          rowClassName={record => (record.isSummaryRow ? 'summary-row' : '')}
          onChange={({ current }) => {
            setCurrentPage(current)}
          }
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      </Spin>
    </div>
  );
};

export default Stock;
