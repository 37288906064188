import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, Form } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import EditableCell from '../EditableCell';
import useOuterClick from '~/hooks/useOuterClick';
import { SALE_UNITS } from '~/constants/defaultValue';

const MedicineTable = ({
  onSave,
  onDelete,
  dataSource,
  setDataSource,
  orderId,
  isLoading,
  updatingId,
  editable
}) => {
  const [form] = Form.useForm();
  const [editingId, setEditingId] = useState('');
  const [waitingToAdd, setWaitingToAdd] = useState(false);

  const innerRef = useOuterClick(async () => {
    if (editingId) {
      try {
        await form.validateFields();
        setEditingId('');
      } catch (error) {}
    }
  });

  useEffect(() => {
    setEditingId('');
    if (waitingToAdd) {
      setWaitingToAdd(false);
    }
    //SHOULD RECHECK
    //eslint-disable-next-line
  }, [orderId]);

  useEffect(() => {
    if (waitingToAdd) {
      handleAdd();
      setWaitingToAdd(false);
    }
    //SHOULD RECHECK
    //eslint-disable-next-line
  }, [dataSource]);

  const isEditing = record => record._id === editingId;

  const isFormValid = () => {
    return form
      .getFieldsError()
      .every(fieldErr => fieldErr.errors.length === 0);
  };

  const handleAdd = async () => {
    const check = dataSource.findIndex(item => item._id === -1);
    if (check !== -1) {
      /* if this flag is true, the app will add a new record after the current record finished */
      setWaitingToAdd(true);
      return;
    }
    try {
      await form.validateFields();
      const newData = {
        _id: -1,
        productName: '',
        saleQuantity: '',
        saleUnit: SALE_UNITS[0],
        price: ''
      };
      setDataSource([...dataSource, newData]);
      handleEdit(newData);
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  const handleEdit = record => {
    if (record._id === editingId) {
      return;
    }
    /* only allow the user update another record if the data of editing record is valid  */
    if (isFormValid()) {
      form.resetFields();
      form.setFieldsValue({ ...record });
      setEditingId(record._id);
    }
  };

  const handleDelete = record => {
    onDelete(record);
    /* if the user is editing the deleted record, set editingId to empty */
    if (record._id === editingId) {
      setEditingId('');
    }
  };

  const columnData = [
    {
      title: '#',
      dataIndex: '_id',
      align: 'center',
      width: '5%',
      render: (value, _, index) => {
        if (updatingId.includes(value)) {
          return <div className="dot-flashing" />;
        }
        return index + 1;
      }
    },
    {
      title: 'Tên',
      dataIndex: 'productName',
      width: '18%',
      editable: true,
      required: true
    },
    {
      title: 'Mô tả',
      dataIndex: 'productDescription',
      width: '15%',
      editable: true
      // inputType: 'textarea'
    },
    {
      title: 'Hướng dẫn',
      dataIndex: 'instruction',
      width: '15%',
      editable: true
      // inputType: 'textarea'
    },
    {
      title: 'Số lượng',
      dataIndex: 'saleQuantity',
      align: 'right',
      width: '10%',
      editable: true,
      required: true,
      inputType: 'number'
    },
    {
      title: 'Đơn vị',
      dataIndex: 'saleUnit',
      align: 'right',
      width: '10%',
      editable: true,
      inputType: 'select',
      selectOptions: SALE_UNITS
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      align: 'right',
      width: '15%',
      editable: true,
      required: true,
      inputType: 'number',
      render: value => value.toLocaleString()
    },
    {
      title: 'Tổng cộng',
      dataIndex: 'sub price',
      align: 'right',
      width: '15%',
      render: (_, record) =>
        record.saleQuantity * record.price
          ? (record.saleQuantity * record.price).toLocaleString()
          : ''
    },
    ...(editable
      ? [
          {
            title: '',
            dataIndex: '',
            width: '4%',
            align: 'center',
            render: (_, record) => (
              <Popconfirm
                title="Bạn có chắc muốn xóa?"
                onConfirm={() => handleDelete(record)}
              >
                <p className="remove-action text-danger">
                  <i className="mdi mdi-close-circle-outline" />
                </p>
              </Popconfirm>
            )
          }
        ]
      : [])
  ];

  const columns = columnData.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.inputType ?? 'text',
        required: col.required,
        dataIndex: col.dataIndex,
        title: col.title,
        selectOptions: col.selectOptions,
        onSave,
        form,
        editing: isEditing(record)
      })
    };
  });

  return (
    <>
      {editable && (
        <Button
          onClick={handleAdd}
          icon={<PlusCircleFilled />}
          style={{
            marginBottom: 16
          }}
        >
          Thêm thuốc
        </Button>
      )}
      <Form form={form} component={false}>
        <div ref={innerRef}>
          <Table
            rowKey={rc => rc._id}
            loading={isLoading}
            onRow={record => {
              return {
                className: editingId === record._id ? 'editing' : '',
                onClick: event => {
                  if (event.target.tagName.toLowerCase() === 'td' && editable) {
                    handleEdit(record);
                  }
                }
              };
            }}
            components={{ body: { cell: EditableCell } }}
            rowClassName="editable-row"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </div>
      </Form>
    </>
  );
};

export default MedicineTable;
