import { CheckOutlined, CloseOutlined, ExportOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import axios from 'axios'
import moment from "moment"
import { memo, useCallback, useEffect, useState } from 'react'
import { BASE_URL } from '~/constants/defaultValue'

function ExportController({handleCloseItem,query,label,router,fileName,keyValue}) {
    const [wait,setWait]=useState(false)
    const [fileNames,setFileName]=useState("")

    const triggerDowload = useCallback( async(query,callback)=>{
        const dateNow =  moment(Date.now()).format("DD-MM-YYYY")
        const fileName_ = `${fileName+ dateNow}.xlsx`
        const newQ = Object.entries(query).reduce((a,[key,value],i)=>{if(i===0){
            return a +"?"+ key+"="+value
            } else{ return a + '&' + key+'='+value }},"")
        const validRouter =()=>{
            switch (router) {
                case 'customer': return '/api/v2/' ;
                case 'product': return '/api/v3/' ;
            
                default: return '/api/v1/';
            }
        }
        const temp = String('').concat(BASE_URL,validRouter(),router,newQ) 
        axios.get(temp, {
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target',"_blank");
            link.setAttribute('download', fileName_);
            document.body.appendChild(link);
            link.click();
            if(callback) callback(1)
            setFileName(fileName_)
        }).catch((err) =>{callback(2)});
    },[setFileName,fileName,router])

    useEffect(()=>{
        triggerDowload(query,setWait)
        console.log("rerender")
    },[setWait,query,triggerDowload])
    return (
        <Row
        align={"middle"}
        justify={"center"}
        className='export-body__item' >
        <Col style={{textAlign:"center" ,fontSize:20}} span={3}><ExportOutlined /></Col>
        <Col flex={1} >
            <p>{label}</p>
           {fileNames && <Button onClick={()=>{triggerDowload(query)}} type='link'>{fileNames}</Button>}
        </Col>
        <Col>{
            function(wait){
                switch (wait) {
                    case 1: return <CheckOutlined style={{color:"green"}}/>;
                    case 2: return <CloseOutlined style={{color:"red"}} />;
                    default: return <LoadingOutlined style={{color:"black",fontSize:18}} spin/>;
                }
            }(wait)
            }
        </Col>
        <Col style={{textAlign:"right"}} span={3}>
            <CloseOutlined onClick={()=>handleCloseItem(keyValue)} />
        </Col>
    </Row>
    )
}

export default  memo(ExportController)