import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: []
};

export default produce((state, {type, payload}) => {
  switch (type){
    case Types.GET_NEAR_DUE_PRODUCTS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_NEAR_DUE_PRODUCTS_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      return;

    case Types.GET_NEAR_DUE_PRODUCTS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    default:
      return;
  }
}, initState)
