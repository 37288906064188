export const GET_PROCESSES_IMPORT_REQUEST = 'GET_PROCESSES_IMPORT_REQUEST';
export const GET_PROCESSES_IMPORT_SUCCESS = 'GET_PROCESSES_IMPORT_SUCCESS';
export const GET_PROCESSES_IMPORT_FAILED = 'GET_PROCESSES_IMPORT_FAILED';

export const GET_PROCESS_IMPORT_REQUEST = 'GET_PROCESS_IMPORT_REQUEST';
export const GET_PROCESS_IMPORT_SUCCESS = 'GET_PROCESS_IMPORT_SUCCESS';
export const GET_PROCESS_IMPORT_FAILED = 'GET_PROCESS_IMPORT_FAILED';

export const CREATE_PROCESS_IMPORT_REQUEST = 'CREATE_PROCESS_IMPORT_REQUEST';
export const CREATE_PROCESS_IMPORT_SUCCESS = 'CREATE_PROCESS_IMPORT_SUCCESS';
export const CREATE_PROCESS_IMPORT_FAILED = 'CREATE_PROCESS_IMPORT_FAILED';

export const UPDATE_PROCESS_IMPORT_REQUEST = 'UPDATE_PROCESS_IMPORT_REQUEST';
export const UPDATE_PROCESS_IMPORT_SUCCESS = 'UPDATE_PROCESS_IMPORT_SUCCESS';
export const UPDATE_PROCESS_IMPORT_FAILED = 'UPDATE_PROCESS_IMPORT_FAILED';

export const FETCH_EVENT_PROCESS_IMPORT = 'FETCH_EVENT_PROCESS_IMPORT';
export const CREATE_EVENT_PROCESS_IMPORT = 'CREATE_EVENT_PROCESS_IMPORT';
export const UPDATE_EVENT_PROCESS_IMPORT = 'UPDATE_EVENT_PROCESS_IMPORT';

export const DELETE_PROCESS_IMPORT_REQUEST = 'DELETE_PROCESS_IMPORT_REQUEST';
export const DELETE_PROCESS_IMPORT_SUCCESS = 'DELETE_PROCESS_IMPORT_SUCCESS';
export const DELETE_PROCESS_IMPORT_FAILED = 'DELETE_PROCESS_IMPORT_FAILED';

export const RESET_PROCESS_IMPORT_STATE = 'RESET_PROCESS_IMPORT_STATE';
