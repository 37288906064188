import * as React from 'react';
import PopoverNearDueReport from '../PopoverNearDue';
import { useProductReport } from '~/hooks';
import { WarningFilled } from '@ant-design/icons';
import { PRODUCT_REPORT_POPOVER_ITEM } from '~/constants/defaultValue';

import './notification-report.scss';

const expireInDefault = PRODUCT_REPORT_POPOVER_ITEM.find((item) => item.value === '3MONTH').value

const NotificationReport = () => {
  const [open, setOpen] = React.useState(false);
  const [expireIn, setExpireIn] = React.useState(expireInDefault);
  const [dataNearDueProducts, ] = useProductReport({ expireIn });

  return (
    <div className="notification-report">
      <h4 className="notification-report-title">Thông báo</h4>

      <div className="notification-report-box">
        <div className="notification-report-icon">
          <WarningFilled style={{ fontSize: '16px', color: '#ff466d' }}/>
        </div>
        <div className="notification-report-message">
          Có <a href={'/product-report?outdatedProductTimeRange=' +
            (PRODUCT_REPORT_POPOVER_ITEM.find((item) => item.value === expireIn).params)}>
          {dataNearDueProducts && dataNearDueProducts.length ? dataNearDueProducts.length : 0} sản phẩm</a> sẽ hết hạn trong
          <PopoverNearDueReport
            setExpireIn={setExpireIn}
            open={open}
            setOpen={setOpen}
            durationValue={
              PRODUCT_REPORT_POPOVER_ITEM.find((item) => item.value === expireIn).text
            }
            position={'bottomRight'}
          />
        </div>
      </div>
    </div>
  )
}

export default NotificationReport
