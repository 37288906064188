import React from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import InformationItem from '~/components/Common/InformationItem';
import DetailTable from './DetailTable';
import { Link } from 'react-router-dom';
import { formatDateTime } from '~/utils/helper';
import { API_LIST, INVOICE_STATUS } from '~/constants/defaultValue';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { get } from 'lodash';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';

const LEFT_INFO = [
  {
    label: 'Mã nhập hàng:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Thời gian:',
    dataIndex: 'purchasedAt',
    render: purchasedAt => formatDateTime(purchasedAt)
  },
  {
    label: 'Nhà cung cấp:',
    dataIndex: 'supplier',
    render: supplier => supplier?.name
  },
  {
    label: 'Người tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  }
];

const RIGHT_INFO = [
  {
    label: 'Trạng thái:',
    dataIndex: 'status',
    render: status => {
      return INVOICE_STATUS[status];
    }
  },
  {
    label: 'Chi nhánh:',
    dataIndex: 'branch',
    render: branch => branch?.name?.vi ?? branch?.name
  },
  {
    label: 'Người nhập:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  }
];

const Information = ({ record, deleteWarehouse }) => {
  return (
    <div className="warehouse-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={8}>
          <div className="warehouse-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <InformationItem
              key={'Ghi chú'}
              label="Ghi chú"
              value={record?.note}
            />
          </div>
        </Col>
      </Row>

      <DetailTable purchaseOrder={record} />

      <Row className="medicine-info__actions" justify="end">
        <WithPermission permission={POLICIES.UPDATE_PURCHASEORDER}>
          <Link to={`/warehouse/edit/${record._id}`}>
            {' '}
            <Button type="primary" style={{ marginRight: 10 }}>
              <EditOutlined style={{ marginRight: 0 }} /> Cập nhật
            </Button>
          </Link>
        </WithPermission>
        <WithPermission permission={POLICIES.WRITE_PURCHASEORDER}>
          <ButtonExport
            router={API_LIST.warehouse}
            label="Nhập kho"
            id={record._id}
            fileName={`Chitietphieunhap_${record?.code}`}
          />
        </WithPermission>
        {record?.typePurchase !== 'PM' && <WithPermission permission={POLICIES.DELETE_PURCHASEORDER}>
          <Popconfirm
            title="Bạn muốn xoá đơn hàng này?"
            onConfirm={() => deleteWarehouse(record._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger">
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>{' '}
        </WithPermission>}
      </Row>
    </div>
  );
};

export default Information;
