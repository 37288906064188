export const GET_SHIPPING_VENDORS_REQUEST = 'GET_SHIPPING_VENDORS_REQUEST';
export const GET_SHIPPING_VENDORS_SUCCESS = 'GET_SHIPPING_VENDORS_SUCCESS';
export const GET_SHIPPING_VENDORS_FAILED = 'GET_SHIPPING_VENDORS_FAILED';

export const GET_SHIPPING_VENDOR_DETAIL_REQUEST = 'GET_SHIPPING_VENDOR_DETAIL_REQUEST';
export const GET_SHIPPING_VENDOR_DETAIL_SUCCESS = 'GET_SHIPPING_VENDOR_DETAIL_SUCCESS';
export const GET_SHIPPING_VENDOR_DETAIL_FAILED = 'GET_SHIPPING_VENDOR_DETAIL_FAILED';

export const CREATE_SHIPPING_VENDOR_REQUEST = 'CREATE_SHIPPING_VENDOR_REQUEST';
export const CREATE_SHIPPING_VENDOR_SUCCESS = 'CREATE_SHIPPING_VENDOR_SUCCESS';
export const CREATE_SHIPPING_VENDOR_FAILED = 'CREATE_SHIPPING_VENDOR_FAILED';

export const UPDATE_SHIPPING_VENDOR_REQUEST = 'UPDATE_SHIPPING_VENDOR_REQUEST';
export const UPDATE_SHIPPING_VENDOR_SUCCESS = 'UPDATE_SHIPPING_VENDOR_SUCCESS';
export const UPDATE_SHIPPING_VENDOR_FAILED = 'UPDATE_SHIPPING_VENDOR_FAILED';

export const DELETE_SHIPPING_VENDOR_REQUEST = 'DELETE_SHIPPING_VENDOR_REQUEST';
export const DELETE_SHIPPING_VENDOR_SUCCESS = 'DELETE_SHIPPING_VENDOR_SUCCESS';
export const DELETE_SHIPPING_VENDOR_FAILED = 'DELETE_SHIPPING_VENDOR_FAILED';

export const RESET_SHIPPING_VENDOR_STATE = 'RESET_SHIPPING_VENDOR_STATE';
