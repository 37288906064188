import React, { useState } from 'react';
import {
  useReceiptsPaymentFilterType,
  useDeleteReceiptPaymentType,
  useReceiptPaymentTypeQueryParams,
  useReceiptPaymentTypePaging,
  useUpdateReceiptPaymentTypeParams,
  useResetReceiptPaymentType,
  useUpdateReceiptPaymentType,
} from '~/hooks';
import {
  Table,
  Popconfirm,
  Input,
  Button,
  Checkbox,
  Space,
  Typography,
  Row,
  Radio
} from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE, STATUS, STATUS_NAMES } from '~/constants/defaultValue';
import ReceiptPaymentTypeModal from './Form';
import { get, isNil, omit, omitBy } from 'lodash';
import { formatDate } from '~/utils/helper';

const { Search } = Input;

const ColumnActions = ({
  _id,
  deletePaymentType,
  shouldShowDivider,
  onOpenForm,
  permission
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={get(POLICY, `UPDATE_${permission}`)}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDivider && <p>|</p>}
      <WithPermission permission={get(POLICY, `DELETE_${permission}`)}>
        <Popconfirm
          title="Bạn muốn xoá loại này?"
          onConfirm={() => deletePaymentType(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const ReceiptPaymentType = ({ type, name, permission }) => {
  const [query] = useReceiptPaymentTypeQueryParams(type);
  const [keyword, { setKeyword, onParamChange }] = useUpdateReceiptPaymentTypeParams(query);
  const [paymentTypes, isLoading] = useReceiptsPaymentFilterType(query);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [isSubmitLoading, updateReceiptPaymentType] = useUpdateReceiptPaymentType();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const paging = useReceiptPaymentTypePaging();

  const hasUpdate = useMatchPolicy(get(POLICY, `UPDATE_${permission}`));
  const hasDelete = useMatchPolicy(get(POLICY, `DELETE_${permission}`));
  const shouldShowDivider = hasUpdate && hasDelete;

  const numberOfTypes = paymentTypes.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfTypes === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deletePaymentType] = useDeleteReceiptPaymentType(onDeleteSuccess);

  useResetReceiptPaymentType();

  const onChangeStatus = (record, _id, status, isSubmitLoading) => {
    updateReceiptPaymentType({
      _id,
      status,
      isSubmitLoading,
      ...omit(record, ['_id', 'status'])
    });
  };

  const onChange = ({ target }) => {
    switch (target.value) {
      case 2:
        onParamChange({ ...query, status: STATUS['ACTIVE'] });
        break;
      case 3:
        onParamChange({ ...query, status: STATUS['INACTIVE'] });
        break;
      default:
        onParamChange({ ...query, status: '' });
        break;
    }
  };

  const onOpenForm = id => {
    setPaymentTypeId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setPaymentTypeId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: `Tên loại ${name}`,
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      render: rc => rc ==='PAYMENT' ? 'Loại chi' : 'Loại thu'
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: rc => formatDate(rc),
      align: 'end'
    },

    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: (status, record) => {
        return (
          <Checkbox
            checked={status === STATUS.ACTIVE}
            onChange={e => {
              return onChangeStatus(
                record,
                get(record, '_id'),
                e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE,
                isSubmitLoading
              );
            }}
          />
        );
      }
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: record => {
        return (
          <ColumnActions
            {...record}
            deletePaymentType={deletePaymentType}
            shouldShowDivider={shouldShowDivider}
            onOpenForm={onOpenForm}
          />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">{`Loại ${name}`}</h1>
          <div className="wc-page-header__search wclinic-search">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm kiếm loại"
              enterButton
              onSearch={() => onParamChange({ keyword, page: 1 })}
              onChange={e => setKeyword(e.target.value)}
              value={keyword}
              allowClear
            />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={get(POLICY, `WRITE_${permission}`)}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => onOpenForm()}
              >
                Thêm mới
              </Button>
            </WithPermission>
          </div>
        </div>
        <Space style={{ marginBottom: 20 }}>
          <Typography style={{ fontSize: 18, marginRight: 20 }}>
            Phân loại trạng thái theo :
          </Typography>
          <Row gutter={20}>
            <Radio.Group
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={(() => {
                switch (query?.status) {
                  case 'ACTIVE':
                    return 2;
                  case 'INACTIVE':
                    return 3;
                  default:
                    return 1;
                }
              })()}
            >
              <Radio.Button value={1}>Tất cả</Radio.Button>
              <Radio.Button value={2}>{STATUS_NAMES['ACTIVE']}</Radio.Button>
              <Radio.Button value={3}>{STATUS_NAMES['INACTIVE']}</Radio.Button>
            </Radio.Group>
          </Row>
        </Space>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                columns={columns}
                rowCount={10}
              />
            ) : (
              <Table
                rowKey={rc => rc?._id}
                columns={columns}
                dataSource={paymentTypes}
                onChange={({ current,pageSize }) => onParamChange({ page: current, limit : pageSize })}
                pagination={{
                  ...{current:1,pageSize:10,total:11,...omitBy(paging,isNil)},
                  showSizeChanger: true, 
                  pageSizeOptions: ['10', '20', '50' ,'100'],
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                // pagination={{
                //   showTotal: total => `Tổng cộng: ${total} `
                // }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <ReceiptPaymentTypeModal
        isOpen={isOpenForm}
        onClose={onCloseForm}
        _id={paymentTypeId}
        isReceipt={type}
        updateReceiptPaymentType={updateReceiptPaymentType}
      />
    </div>
  );
};

export default ReceiptPaymentType;
