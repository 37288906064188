import { omit } from 'lodash';
import requester from '../requester';
import request from './requester';

const sale = {
  update: sale => request.put(`/sale/${sale._id}`, sale),
  create: sale => request.post('/sale', sale),
  getInvoice: id => requester.get(`/invoice/${id}`),
  getOrder: id => requester.get(`/sale-order/${id}`),
  updateLogisticCode: sale => request.put(`/sale/logistic-code/${sale.id}`, omit(sale, ['id'])),
  checkIn: sale => request.put(`/sale/check-in/${sale._id}`, omit(sale, ['_id', 'type'])),
  checkOut: sale => request.put(`/sale/check-out/${sale._id}`, omit(sale, ['_id', 'type'])),
};

export default sale;
