import * as Types from '~/constants/actionTypes';

export const getMedicineGroups = query => ({
  type: Types.GET_MEDICINE_GROUPS_REQUEST,
  payload: query
});

export const getMedicineGroupOptions = () => ({
  type: Types.GET_MEDICINE_GROUP_OPTIONS_REQUEST
});

export const getMedicineGroup = id => ({
  type: Types.GET_MEDICINE_GROUP_REQUEST,
  payload: id
});

export const createMedicineGroup = medicineGroup => ({
  type: Types.CREATE_MEDICINE_GROUP_REQUEST,
  payload: medicineGroup
});

export const updateMedicineGroup = medicineGroup => ({
  type: Types.UPDATE_MEDICINE_GROUP_REQUEST,
  payload: medicineGroup
});

export const deleteMedicineGroup = id => ({
  type: Types.DELETE_MEDICINE_GROUP_REQUEST,
  payload: id
});

export const resetMedicineGroupState = () => ({
  type: Types.RESET_MEDICINE_GROUP_STATE
});
