export const GET_BRANCHES_REQUEST = 'GET_BRANCHES_REQUEST';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILED = 'GET_BRANCHES_FAILED';

export const GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_FAILED = 'GET_BRANCH_FAILED';

export const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_FAILED = 'GET_API_KEY_FAILED';

export const UPDATE_API_KEY_SAVE_REQUEST = 'UPDATE_API_KEY_SAVE_REQUEST';
export const UPDATE_API_KEY_SAVE_SUCCESS = 'UPDATE_API_KEY_SAVE_SUCCESS';
export const UPDATE_API_KEY_SAVE_FAILED = 'UPDATE_API_KEY_SAVE_FAILED';

export const DELETE_API_KEY_REQUEST = 'DELETE_API_KEY_REQUEST';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAILED = 'DELETE_API_KEY_FAILED';

export const CREATE_BRANCH_REQUEST = 'CREATE_BRANCH_REQUEST';
export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_FAILED = 'CREATE_BRANCH_FAILED';

export const UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILED = 'UPDATE_BRANCH_FAILED';

export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED';

export const RESET_BRANCH_STATE = 'RESET_BRANCH_STATE';
