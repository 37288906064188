
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const Logout = props => {
  useEffect(() => {});

  return <></>;
};

export default withRouter(connect(null, {})(Logout));
