import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getSupplierGroups({ payload: query }) {
  try {
    const data = yield call(Api.supplierGroup.getAll, query);
    yield put({ type: Types.GET_SUPPLIER_GROUPS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SUPPLIER_GROUPS_FAILED,
      payload: error
    });
  }
}

function* getSupplierGroup({ payload }) {
  try {
    const supplierGroup = yield call(Api.supplierGroup.getById, payload);
    yield put({
      type: Types.GET_SUPPLIER_GROUP_SUCCESS,
      payload: supplierGroup
    });
  } catch (error) {
    yield put({
      type: Types.GET_SUPPLIER_GROUP_FAILED,
      payload: error
    });
  }
}

function* createSupplierGroup(action) {
  try {
    const data = yield call(Api.supplierGroup.create, action.payload);
    yield put({ type: Types.CREATE_SUPPLIER_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SUPPLIER_GROUP_FAILED,
      payload: error
    });
  }
}

function* updateSupplierGroup(action) {
  try {
    const data = yield call(Api.supplierGroup.update, action.payload);
    yield put({ type: Types.UPDATE_SUPPLIER_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SUPPLIER_GROUP_FAILED,
      payload: error
    });
  }
}

function* deleteSupplierGroup({ payload }) {
  try {
    yield call(Api.supplierGroup.delete, payload);
    yield put({ type: Types.DELETE_SUPPLIER_GROUP_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_SUPPLIER_GROUP_FAILED,
      payload: error
    });
  }
}

export default function* supplierGroup() {
  yield takeLatest(Types.GET_SUPPLIER_GROUPS_REQUEST, getSupplierGroups);
  yield takeLatest(Types.GET_SUPPLIER_GROUP_REQUEST, getSupplierGroup);
  yield takeLatest(Types.DELETE_SUPPLIER_GROUP_REQUEST, deleteSupplierGroup);
  yield takeLatest(Types.CREATE_SUPPLIER_GROUP_REQUEST, createSupplierGroup);
  yield takeLatest(Types.UPDATE_SUPPLIER_GROUP_REQUEST, updateSupplierGroup);
}
