import { useContext } from 'react';
import { createContext, useCallback, useState } from 'react';
import { useEventProcessImport } from '~/hooks';
import { notification } from 'antd';

const ProcessImportProvider = createContext();
export const useProcessImport = () => (useContext(ProcessImportProvider))

const ModuleProcessImport = ({children}) => {
  useEventProcessImport();
return(
  <ProcessImportProvider.Provider value={{}}>
    {children}
  </ProcessImportProvider.Provider>
)
}
export default ModuleProcessImport;