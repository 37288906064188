import React, { useState } from 'react';
import {
  useUserEmployees,
  useUpdateUserEmployee,
  useDeleteUserEmployee,
  useUserEmployeeQueryParams,
  useUserEmployeePaging,
  useUpdateUserEmployeeParams,
  useResetUserEmployee,
} from '~/hooks';
import { Table, Tag, Popconfirm, Input, Button, Switch } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import UserForm from './UserForm';
import WithOrPermission from '~/components/Common/WithOrPermission';

const { Search } = Input;

const ColumnActions = ({
  _id,
  deleteUserEmployee,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_USER}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}
      <WithPermission permission={POLICY.DELETE_USER}>
        <Popconfirm
          title="Bạn muốn xoá người dùng này?"
          onConfirm={() => deleteUserEmployee(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};
const ColumnStatus = ({
  _id,
  status,
  isSubmitLoading,
  handleUpdate,
  ...restProps
}) => {
  const [isChecked, setIsChecked] = useState(status === 'ACTIVE');
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_USER}>
        <Switch
          checked={isChecked}
          onChange={value => {
            setIsChecked(!isChecked);
            handleUpdate({ status: value ? 'ACTIVE' : 'INACTIVE', _id });
          }}
        />
      </WithPermission>
    </div>
  );
};
const UserEmployees = () => {
  const [query] = useUserEmployeeQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateUserEmployeeParams(query);
  const [userEmployees, isLoading] = useUserEmployees(query);
  const [userId, setUserEmployeeId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const [, handleUpdate] = useUpdateUserEmployee();

  const paging = useUserEmployeePaging();

  const hasWrite = useMatchPolicy(POLICY.UPDATE_USER);
  const hasDelete = useMatchPolicy(POLICY.DELETE_USER);
  const shouldShowDevider = hasWrite && hasDelete;

  const numberOfUserEmployees = userEmployees.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfUserEmployees === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteUserEmployee] = useDeleteUserEmployee(onDeleteSuccess);

  useResetUserEmployee();

  const onOpenForm = id => {
    setUserEmployeeId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setUserEmployeeId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên người dùng',
      dataIndex: 'fullName',
      key: 'fullName'
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },

    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Nhóm người dùng',
      dataIndex: 'groups',
      key: 'groups',
      render: groups => (
        <div className="speciality-tags">
          {groups?.map((group, index) => {
            return (
              <Tag color="blue" key={index}>
                {group.name}
              </Tag>
            );
          })}
        </div>
      )
    },
    {
      title: 'Trạng thái',
      key: 'status',
      // align: 'center',
      render: record => {
        return (
          <ColumnStatus {...record} handleUpdate={handleUpdate} />
        );
      }
    },

    ...( hasDelete || hasWrite ) ? [{
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions
            {...record}
            deleteUserEmployee={deleteUserEmployee}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
          />
        );
      }
    }] : []
  ];

  return (
    <div className="page-content__main">
      <div className="page-content__left" style={CARD_STYLE}>
        <FilterCity
          value={query.cityId}
          onChange={(value) => onParamChange({ cityId: value })}
          isDisabled={isLoading}
        />
      </div>

      <div className="page-content__right" style={CARD_STYLE}>
        <div className="page-wraper__header">
          <Search
            style={{ width: 300 }}
            placeholder="Tìm người dùng"
            enterButton
            allowClear
            onSearch={() => onParamChange({ keyword })}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <WithPermission permission={POLICY.WRITE_USER}>
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => onOpenForm()}
            >
              Thêm mới
            </Button>
          </WithPermission>
        </div>

        {isLoading ? (
          <SkeletonTable
            columns={columns}
            rowCount={userEmployees.length}
            pagination={paging}
          />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={userEmployees}
            onChange={({ current }) => onParamChange({ page: current })}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </div>
      <WithOrPermission permission={[POLICY.WRITE_USER, POLICY.UPDATE_USER]}>
        <UserForm
          isOpen={isOpenForm}
          onClose={onCloseForm}
          id={userId}
          handleUpdate={handleUpdate}
        />
      </WithOrPermission>
    </div>
  );
};

export default UserEmployees;
