export const GET_NEWS_CATEGORIES_REQUEST = 'GET_NEWS_CATEGORIES_REQUEST';
export const GET_NEWS_CATEGORIES_SUCCESS = 'GET_NEWS_CATEGORIES_SUCCESS';
export const GET_NEWS_CATEGORIES_FAILED = 'GET_NEWS_CATEGORIES_FAILED';

export const GET_NEWS_CATEGORY_REQUEST = 'GET_NEWS_CATEGORY_REQUEST';
export const GET_NEWS_CATEGORY_SUCCESS = 'GET_NEWS_CATEGORY_SUCCESS';
export const GET_NEWS_CATEGORY_FAILED = 'GET_NEWS_CATEGORY_FAILED';

export const CREATE_NEWS_CATEGORY_REQUEST = 'CREATE_NEWS_CATEGORY_REQUEST';
export const CREATE_NEWS_CATEGORY_SUCCESS = 'CREATE_NEWS_CATEGORY_SUCCESS';
export const CREATE_NEWS_CATEGORY_FAILED = 'CREATE_NEWS_CATEGORY_FAILED';

export const UPDATE_NEWS_CATEGORY_REQUEST = 'UPDATE_NEWS_CATEGORY_REQUEST';
export const UPDATE_NEWS_CATEGORY_SUCCESS = 'UPDATE_NEWS_CATEGORY_SUCCESS';
export const UPDATE_NEWS_CATEGORY_FAILED = 'UPDATE_NEWS_CATEGORY_FAILED';

export const DELETE_NEWS_CATEGORY_REQUEST = 'DELETE_NEWS_CATEGORY_REQUEST';
export const DELETE_NEWS_CATEGORY_SUCCESS = 'DELETE_NEWS_CATEGORY_SUCCESS';
export const DELETE_NEWS_CATEGORY_FAILED = 'DELETE_NEWS_CATEGORY_FAILED';

export const RESET_NEWS_CATEGORY_STATE = 'RESET_NEWS_CATEGORY_STATE';
