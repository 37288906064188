import requester from '../requester';

const policy = {
  getAll: (query) => requester.get('/policy', query),
  getById: (id) => requester.get(`/policy/${id}?raw=true`),
  create: (policy) => requester.post('/policy', policy),
  update: (policy) => requester.put(`/policy/${policy._id}`, policy),
  delete: (id) => requester.delete(`/policy/${id}`)
};

export default policy;
