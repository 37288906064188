import React, { useMemo, useState } from 'react';
import { STATUS_SAMPLE } from '~/constants/defaultValue';
import { Button, Col, Form, Row, Input, Popconfirm } from 'antd';
import InformationItem from '~/components/Common/InformationItem';
import DetailTable from './DetailTable'
import { SaveOutlined, ExportOutlined, DeleteOutlined,FormOutlined } from '@ant-design/icons';
import { useUpdateSamplePrescriptionNode } from '~/hooks';
import { exportCSV } from '~/utils/helper';
import { Link, useHistory } from 'react-router-dom';
import { WithPermission } from '~/components/Common';
import POLICY from '~/constants/policy';
const { TextArea } = Input;
const LEFT_INFO = [
  {
    label: 'Mã đơn thuốc:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Người tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  }
];

const RIGHT_INFO = [
  {
    label: 'Tên đơn thuốc:',
    dataIndex: 'name',
    render: name => name
  },
  {
    label: 'Trạng thái:',
    dataIndex: 'status',
    render: status => {
      return STATUS_SAMPLE[status];
    }
  }
];
const InformationSample = ({record,deleteSamplePrescription}) => {
  const history = useHistory()
  const [note, setNote] = useState(record.note)
  const [,handleUpdate] = useUpdateSamplePrescriptionNode();
  // const handleClickUpdate = (id,note) => {
  //   handleUpdate({id,note})
  // }
  const handleChangeNote = (event) => {
    const value = event.target.value
    setNote(value)
  }
  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'productCode',
        render: record => {
          return record.variantId.variantCode
        },
        width: 150
      },
      {
        title: 'Tên hàng',
        key: 'name',
        width: 400,
        render: rc => `${rc.productId?.name}`
      },
      {
        title: 'Đơn vị tính',
        key: 'unitName',
        dataIndex: 'unitName',
        align: 'left',
        render: (_,record) => {
          return record.variantId?.unitId.name
        }
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'quantity',
        align: 'right',
      },
      {
        title: 'Liều dùng',
        key: 'lieudung',
        dataIndex: 'lieudung',
        align: 'right',
      },
    ];
  }, []);
  const exportCsvData = () => {
    exportCSV({
      columns: columns,
      data: record?.samplePrescriptionDetail.reduce((newData,item) => {
        const itemData = {
          productCode: item.productId.productCode,
          name: item.productId.name,
          unitName:item.variantId.unitId.name,
          quantity: item.quantity,
        }
        return [...newData, itemData]
      },[]),
      fileName: 'Đơn thuốc mẫu'
    });
  };
  const handleRedirectEdit = () => {
    localStorage.setItem('item', JSON.stringify(record));
    // history.push(`/sample-prescription/edit/${record._id}`)
  }
  return (
    <div className="warehouse-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={8}>
          <div className="warehouse-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <Form.Item label="Ghi chú">
              <TextArea rows={4} value={note} onChange={handleChangeNote} bordered={false}/>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <DetailTable sample = {record} columns={columns}/>
      <div style={{marginTop:'50px'}} className={'box_cta_detail'}>
        <WithPermission permission={POLICY.UPDATE_SAMPLEPRESCRIPTION}>
          {/* <Button
            type="primary"
            style={{ marginRight: 10 }}
            onClick={() => handleClickUpdate(record._id, note)}
          >
            <SaveOutlined />
            Lưu
          </Button> */}
          <Link
            onClick={handleRedirectEdit}
            to={`/sample-prescription/edit/${record._id}`}
          >
            <Button type="primary" style={{ marginRight: 10 }}>
              <FormOutlined />
              Mở đơn thuốc
            </Button>
          </Link>
        </WithPermission>
        <WithPermission permission={POLICY.WRITE_SAMPLEPRESCRIPTION}>
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            onClick={exportCsvData}
          >
            <ExportOutlined />
            Xuất file
          </Button>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_SAMPLEPRESCRIPTION}>
          <Popconfirm
            title="Bạn muốn xoá hàng hoá này?"
            onConfirm={() => deleteSamplePrescription(record._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger">
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>
        </WithPermission>
      </div>
    </div>
  );
};

export default InformationSample;