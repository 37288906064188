import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILED,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILED,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILED,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILED
} from '~/constants/actionTypes';

function* getEmployees({ payload: query }) {
  try {
    const response = yield call(Api.employee.getAll, query);
    yield put({ type: GET_EMPLOYEES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_EMPLOYEES_FAILED, payload: error.message });
  }
}

function* getEmployee({ payload: id }) {
  try {
    const employee = yield call(Api.employee.getById, id);
    yield put({ type: GET_EMPLOYEE_SUCCESS, payload: employee });
  } catch (error) {
    yield put({ type: GET_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* createEmployee(action) {
  try {
    const data = yield call(Api.employee.create, action.payload);
    yield put({ type: CREATE_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* updateEmployee(action) {
  try {
    const data = yield call(Api.employee.update, action.payload);
    yield put({ type: UPDATE_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_EMPLOYEE_FAILED, payload: error.message });
  }
}

function* deleteEmployee({ payload }) {
  try {
    yield call(Api.employee.delete, payload);
    yield put({ type: DELETE_EMPLOYEE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_EMPLOYEE_FAILED, payload: error.message });
  }
}

export default function* employee() {
  yield takeLatest(GET_EMPLOYEES_REQUEST, getEmployees);
  yield takeLatest(GET_EMPLOYEE_REQUEST, getEmployee);
  yield takeLatest(DELETE_EMPLOYEE_REQUEST, deleteEmployee);
  yield takeLatest(CREATE_EMPLOYEE_REQUEST, createEmployee);
  yield takeLatest(UPDATE_EMPLOYEE_REQUEST, updateEmployee);
}
