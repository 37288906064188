import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useResetState, useFetchByParam } from '~/hooks/utils';

import {
  getOverViewReport,
  getMonthlyReport,
  getDailyReport,
  resetDashboardState
} from '~/redux/action';

const DASHBOARD_MODULE = 'dashboard';
const getSelector = (key) => (state) => state[DASHBOARD_MODULE][key];

const overViewSelector = getSelector('overView');
const overViewLoadingSelector = getSelector('isOverViewLoading');
const getOverViewFailedSelector = getSelector('getOverViewFailed');

const monthlySelector = getSelector('monthly');
const monthlyLoadingSelector = getSelector('isMonthlyLoading');
const getMonthlyFailedSelector = getSelector('getMonthlyFailed');

const dailySelector = getSelector('daily');
const dailyLoadingSelector = getSelector('isDailyLoading');
const getDailyFailedSelector = getSelector('getDailyFailed');

const policiesSelector = (state) => state.user.policy;

export const useOverView = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getOverViewReport,
    loadingSelector: overViewLoadingSelector,
    dataSelector: overViewSelector,
    failedSelector: getOverViewFailedSelector,
    param
  });
};

export const useMonthly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMonthlyReport,
    loadingSelector: monthlyLoadingSelector,
    dataSelector: monthlySelector,
    failedSelector: getMonthlyFailedSelector,
    param
  });
};

export const useDaily = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getDailyReport,
    loadingSelector: dailyLoadingSelector,
    dataSelector: dailySelector,
    failedSelector: getDailyFailedSelector,
    param
  });
};

export const useResetDashboard = () => {
  useResetState(resetDashboardState);
};
