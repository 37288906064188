import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicineUnits({ payload: query }) {
  try {
    const data = yield call(Api.medicineUnit.getAll, query);
    yield put({ type: Types.GET_MEDICINE_UNITS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_UNITS_FAILED,
      payload: error
    });
  }
}

function* getMedicineUnit({ payload }) {
  try {
    const medicineUnit = yield call(Api.medicineUnit.getById, payload);
    yield put({
      type: Types.GET_MEDICINE_UNIT_SUCCESS,
      payload: medicineUnit
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_UNIT_FAILED,
      payload: error
    });
  }
}

function* createMedicineUnit(action) {
  try {
    const data = yield call(Api.medicineUnit.create, action.payload);
    yield put({ type: Types.CREATE_MEDICINE_UNIT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICINE_UNIT_FAILED,
      payload: error
    });
  }
}

function* updateMedicineUnit(action) {
  try {
    const data = yield call(Api.medicineUnit.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICINE_UNIT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICINE_UNIT_FAILED,
      payload: error
    });
  }
}

function* deleteMedicineUnit({ payload }) {
  try {
    yield call(Api.medicineUnit.delete, payload);
    yield put({ type: Types.DELETE_MEDICINE_UNIT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICINE_UNIT_FAILED,
      payload: error
    });
  }
}

export default function* medicineUnit() {
  yield takeLatest(Types.GET_MEDICINE_UNITS_REQUEST, getMedicineUnits);
  yield takeLatest(Types.GET_MEDICINE_UNIT_REQUEST, getMedicineUnit);
  yield takeLatest(Types.DELETE_MEDICINE_UNIT_REQUEST, deleteMedicineUnit);
  yield takeLatest(Types.CREATE_MEDICINE_UNIT_REQUEST, createMedicineUnit);
  yield takeLatest(Types.UPDATE_MEDICINE_UNIT_REQUEST, updateMedicineUnit);
}
