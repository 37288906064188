export const GET_OVER_VIEW_REPORT_REQUEST = 'GET_OVER_VIEW_REPORT_REQUEST';
export const GET_OVER_VIEW_REPORT_SUCCESS = 'GET_OVER_VIEW_REPORT_SUCCESS';
export const GET_OVER_VIEW_REPORT_FAILED = 'GET_OVER_VIEW_REPORT_FAILED';

export const GET_MONTHLY_REPORT_REQUEST = 'GET_MONTHLY_REPORT_REQUEST';
export const GET_MONTHLY_REPORT_SUCCESS = 'GET_MONTHLY_REPORT_SUCCESS';
export const GET_MONTHLY_REPORT_FAILED = 'GET_MONTHLY_REPORT_FAILED';

export const GET_DAILY_REPORT_REQUEST = 'GET_DAILY_REPORT_REQUEST';
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS';
export const GET_DAILY_REPORT_FAILED = 'GET_DAILY_REPORT_FAILED';

export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE';
