import React from 'react';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
  useParams
} from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { PATH_APP } from '~/routes/paths';
import { NavItem, Nav } from 'reactstrap';
import Info from '~/components/WorldCare/Branch/Detail/Info';
import UserEmployee from '~/components/WorldCare/UserEmployee/Users';
import EmployeeGroups from '~/components/WorldCare/UserEmployee/Groups';
import { useBranch } from '~/hooks';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import PermissionGate from '~/routes/middleware/PermissionGate';
import PermissionBranches from '../../PermissionManagerment/PermissionBranches';
import WithOrPermission from '~/components/Common/WithOrPermission';

const Branches = () => {
  const { path, url } = useRouteMatch();  
  const {
    location: { pathname }
  } = useHistory();
  const { branchId} = useParams();
  const [branch, isLoading] = useBranch(branchId);

  const className = (path) => {
    return `nav-link ${pathname.split('/').includes(path) && 'active'}`;
  };

  return (
    <div className="branch-detail page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title="Thông tin chi nhánh"
          routes={[
            { path: PATH_APP.branch.root, title: 'Danh sách chi nhánh' }
          ]}
        />

        <Nav
          tabs
          className="nav-tabs-custom nav-justified mb-4"
          style={{
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: -16
          }}
        >
          <NavItem>
            <Link to={`${url}/info`} className={className('info')}>
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Thông tin chi tiết</span>
            </Link>
          </NavItem>

            <WithPermission permission={POLICY.READ_USER}>
              <NavItem>
                  <Link to={`${url}/user`} className={className('user')}>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Người dùng</span>
                  </Link>
              </NavItem>
            </WithPermission>

          <WithPermission permission={POLICY.READ_USERGROUP}>
            <NavItem>
              <Link
                to={`${url}/user-group`}
                className={className('user-group')}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Nhóm người dùng</span>
              </Link>
            </NavItem>
          </WithPermission>

          <WithOrPermission permission={[POLICY.READ_PARTNER]}>
              <NavItem>
                <Link to={`${url}/permission-branches`} className={className('permission-branches')}>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Phân quyền chi nhánh</span>
                </Link>
              </NavItem>
            </WithOrPermission>
        </Nav>

        <Switch>
          <Route
            path={`${path}/info`}
            component={() => <Info branch={branch} isLoading={isLoading} />}
          />

          <PermissionGate
            path={`${path}/user`}
            component={UserEmployee}
            permission={POLICY.READ_USER}
          />

          <PermissionGate
            path={`${path}/user-group`}
            component={EmployeeGroups}
            permission={POLICY.READ_USERGROUP}
          />
          <PermissionGate
            path={`${path}/permission-branches`}
            component={PermissionBranches}
            permission={POLICY.READ_PARTNER}
          />
          <Redirect to={`${path}/info`}></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default Branches;
