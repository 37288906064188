import React, { useEffect, useCallback } from 'react';
import { Form, Input, Row, Button, Modal, Col, Skeleton } from 'antd';
import {
  useResetProcessImport,
  useProcessImport,
  useInitProcessImport
} from '~/hooks';

const FormItem = Form.Item;

const ProcessImportForm = ({
  isOpen,
  onClose,
  _id,
  handleUpdate,
  isSubmitLoading
}) => {
  const [form] = Form.useForm();
  const [processImport, isLoading] = useProcessImport(_id);

  const initProcessImport = useInitProcessImport(processImport, _id);

  useResetProcessImport();
  useEffect(() => {
    form.resetFields();
  }, [initProcessImport, form]);

  const onFinish = values => {
    handleUpdate({
      ...values,
      _id: _id
    });

    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initProcessImport}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>Cập nhật</h4>
          </Row>
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem label="Ghi chú" name="note">
                <Input.TextArea rows={3} />
              </FormItem>
            </Col>
          </Row>

          <Row className="wc-setting-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              Cập nhật
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ProcessImportForm;
