import React, { useEffect, useState } from 'react';
import { Form, Row, Modal, Col, Input, DatePicker, Table, Button, Spin } from 'antd';
import moment from 'moment';
import { formatNumber } from '~/utils/helper';
import { PAYMENT_STATE } from '~/constants/defaultValue';
import {
  useProductInvoicePaging,
  useProductInvoices
} from '~/hooks';
let timming=null
const FormItem = Form.Item;

const ProductsReturn = ({ isOpen, onClose, handleAddProductReturn }) => {
  const [form] = Form.useForm();
  // const [pagingCurrent, setPagingCurrent] = useState(null); 
  const [tableParams, setTableParams] = useState({
    filters:{status:PAYMENT_STATE.COMPLETED, raw:true},
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const paging = useProductInvoicePaging();


  const [productInvoices, isLoading] = useProductInvoices({
    limit: 5,
    page: tableParams.pagination.current,
    ...tableParams.filters
  })

  // useEffect(() => {
  //   setPagingCurrent(paging)
  // }, [paging])

  const handleSelect = (value) => {
    handleAddProductReturn(value)
    onClose();
  }

  const columns = [
    {
      title: ' Mã hóa đơn',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_) => moment(_).format('DD-MM-YYYY')
    },
    {
      title: 'Nhân viên',
      key: 'fullName',
      dataIndex: ['createdBy', 'fullName'],
    },
    {
      title: 'Khách hàng',
      dataIndex: ['customer', 'name'],
      key: 'address',
    },
    {
      title: 'Tổng cộng',
      dataIndex: 'totalPayment',
      key: 'totalPayment',
      render: (record) => formatNumber(record)
    },
    {
      render: (_) => <Button onClick={() => handleSelect(_)}>Chọn</Button>
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const fieldValues = form.getFieldValue();
    setTableParams((table)=>({
      pagination,
      filters: {
        ...table.filters,
        ...fieldValues,
        fromDate: fieldValues?.fromDate? moment(fieldValues?.fromDate).format('DD-MM-YYYY'):'',
        toDate: fieldValues?.toDate ? moment(fieldValues?.toDate).format('DD-MM-YYYY') : ''
      },
      ...sorter,
    }));
  };

  const handleOnChangeToDate = () => {
    const fieldValues = form.getFieldValue();
    setTableParams((tableParams)=>({
      ...tableParams,
      pagination:{
        ...tableParams.pagination,
        page:1
      },
      filters: {
        ...tableParams.filters,
        ...fieldValues,
        fromDate: fieldValues?.fromDate? moment(fieldValues?.fromDate).format('DD-MM-YYYY'):'',
        toDate: fieldValues?.toDate ? moment(fieldValues?.toDate).format('DD-MM-YYYY') : ''
      }
    }));
  }

  return (
    <Modal
      visible={isOpen}
      width={1050}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form product-return">
        <h4 style={{ marginRight: 'auto' }}>Chọn hóa đơn trả hàng</h4>

        <Form
          form={form}
          style={{ paddingLeft: 0 }}
          autoComplete="off"
          scrollToFirstError
          requiredMark={false}
          onValuesChange={()=>{
            timming&& clearTimeout(timming)
            timming = setTimeout(()=>{
              handleOnChangeToDate()
            },1000)
          }}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 5, xl: 5 }}
          wrapperCol={{ sm: 24, md: 24, lg: 19, xl: 98 }}
        >
          <Row>
            <Col span={6}>
              <div style={{
                padding: '15px 10px',
                marginRight: '50px',
                marginBottom: '15px',
                fontWeight: '500',
                background: '#fafafa',
                borderBottom: '1px solid #f0f0f0'
              }}> Tìm kiếm </div>
              <FormItem name="code">
                <Input placeholder="Theo mã hóa đơn"/>
              </FormItem>
              <FormItem name="lotNumber">
                <Input placeholder="Theo lo hạn sửa dụng"/>
              </FormItem>
              <FormItem name="customer">
                <Input placeholder="Theo khách hàng hoặc điện thoại"/>
              </FormItem>
              <FormItem name="variantCode">
                <Input placeholder="Theo mã hàng"/>
              </FormItem>
              <FormItem name="productName">
                <Input placeholder="Theo tên hàng"/>
              </FormItem>
              <div style={{
                padding: '15px 10px',
                marginRight: '50px',
                fontWeight: '500',
                background: '#fafafa',
                marginBottom: '15px',
                borderBottom: '1px solid #f0f0f0'
              }}> Thời gian </div>
              <FormItem
                noStyle name="fromDate"
                >
                <DatePicker
                  bordered={true}
                  onChange={handleOnChangeToDate}
                  allowClear={true}
                  suffixIcon={null}
                  style={{
                    padding: '7px',
                    marginBottom: 15,
                    width: 'calc(100% - 50px)'
                  }}
                  format={'DD-MM-YYYY'}
                />
              </FormItem>
              <br/>
              <FormItem noStyle name="toDate">
                <DatePicker
                  onChange={handleOnChangeToDate}
                  bordered={true}
                  allowClear={true}
                  suffixIcon={null}
                  style={{
                    padding: '7px',
                    marginBottom: 15,
                    width: 'calc(100% - 50px)'
                  }}
                  format={'DD-MM-YYYY'}
                />
              </FormItem>
            </Col>
            <Col span={18}>
              <Spin spinning={isLoading}>
                <Table
                  rowClassName={record =>
                    record.isSummaryRow ? 'summary-row' : ''
                  }
                  columns={columns}
                  dataSource={productInvoices}
                  onChange={handleTableChange}
                  pagination={{
                    ...paging,
                    // total: Math.ceil(pagingCurrent?.total + pagingCurrent?.total / 10),
                    showTotal: () => `Tổng cộng: ${paging?.total}`
                  }}
                />
                <div style={{ marginBottom: 20, marginTop: 5 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleAddProductReturn();
                      onClose();
                    }}
                    style={{ marginLeft: 'auto', display: 'inherit', cursor: 'pointer' }}
                  >Trả Nhanh</Button>
                </div>
              </Spin>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ProductsReturn;
