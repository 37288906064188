import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getDegrees() {
  try {
    const data = yield call(Api.degree.getAll);
    yield put({ type: Types.GET_DEGREES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_DEGREES_FAILED, payload: error.message });
  }
}

function* getDegree({ payload: id }) {
  try {
    const degree = yield call(Api.degree.getById, id);
    yield put({ type: Types.GET_DEGREE_SUCCESS, payload: degree });
  } catch (error) {
    yield put({ type: Types.GET_DEGREE_FAILED, payload: error.message });
  }
}

function* createDegree(action) {
  try {
    const data = yield call(Api.degree.create, action.payload);
    yield put({ type: Types.CREATE_DEGREE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_DEGREE_FAILED,
      payload: error.message
    });
  }
}

function* updateDegree(action) {
  try {
    const data = yield call(Api.degree.update, action.payload);
    yield put({ type: Types.UPDATE_DEGREE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_DEGREE_FAILED,
      payload: error.message
    });
  }
}

function* deleteDegree({ payload }) {
  try {
    yield call(Api.degree.delete, payload);
    yield put({ type: Types.DELETE_DEGREE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.DELETE_DEGREE_FAILED, payload: error.message });
  }
}

export default function* degree() {
  yield takeLatest(Types.GET_DEGREES_REQUEST, getDegrees);
  yield takeLatest(Types.DELETE_DEGREE_REQUEST, deleteDegree);
  yield takeLatest(Types.GET_DEGREE_REQUEST, getDegree);
  yield takeLatest(Types.CREATE_DEGREE_REQUEST, createDegree);
  yield takeLatest(Types.UPDATE_DEGREE_REQUEST, updateDegree);
}
