import { Col, Row, Table } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import ListItem from './ListItem';

const columns = [
    {
        key:'createdAt',
        dataIndex:'createdAt',
        title: 'Thông tin',
        render :(_,record)=>{
            return  <div>
                <strong>
                    {(moment(_).format('YYYY-MM-DD // HH:mm'))}
                </strong>
                <p>
                    {record.response}
                </p>
            </div> 
        }
    },
    {
        key:'requestCode',
        title: 'Kết quả',
        dataIndex:'responseCode',
        width : 150,
        align:'center',
        render :(_)=>{
            switch (_) {
                case 401: return<ListItem.Wanning style={{backgroundColor:'orange'}}>{_}</ListItem.Wanning>
                case 400: return<ListItem.Error style={{backgroundColor:'red'}}>{_}</ListItem.Error>
                case 0: return<ListItem.Pending style={{backgroundColor:'blue'}}>{_}</ListItem.Pending>
                default: return <ListItem.Success style={{backgroundColor:'green'}}>{_}</ListItem.Success>
            }
        }
    },
]
function DetailItem({product,loading}) {
    
    return (
     <Row style={{width:'100%'}}>
        <Col span={9}>
            <p>Thông tin đính kèm :</p>
            <pre  style={{ fontSize:12, backgroundColor:'#33333324' , paddingBottom:12 }}>
                {JSON.stringify(get(product,'body',{}), null, 2).replace(/[{}]/g,'')}
            </pre >
        </Col>
        <Col span={15}>
            <Table 
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={get(product,'drugLogs',[])}
            />
        </Col>
     </Row>   
    )
}

export default DetailItem
