import requester from '../requester';

const employee = {
  getAll: (query) => requester.get(`/employee`, query),
  getById: (employeeNumber) => requester.get(`/employee/${employeeNumber}?raw=true`),
  create: (employee) => requester.post(`/employee`, employee),
  update: (employee) => requester.put(`/employee/${employee.employeeNumber}`, employee),
  delete: (employeeNumber) => requester.delete(`/employee/${employeeNumber}`)
};

export default employee;
