import * as Types from '~/constants/actionTypes';

export const getReceiptsPaymentType = query => ({
  type: Types.GET_RECEIPTS_PAYMENT_TYPE_REQUEST,
  payload: query
});

export const getReceiptPaymentType = id => ({
  type: Types.GET_RECEIPT_PAYMENT_TYPE_REQUEST,
  payload: id
});

export const createReceiptPaymentType = receiptPaymentType => ({
  type: Types.CREATE_RECEIPT_PAYMENT_TYPE_REQUEST,
  payload: receiptPaymentType
});

export const updateReceiptPaymentType = (id) => ({
  type: Types.UPDATE_RECEIPT_PAYMENT_TYPE_REQUEST,
  payload: id
});

export const deleteReceiptPaymentType = (id) => ({
  type: Types.DELETE_RECEIPT_PAYMENT_TYPE_REQUEST,
  payload: id
});

export const getReceiptsPaymentFilterType = query => ({
  type: Types.GET_RECEIPTS_PAYMENT_FILTER_TYPE_REQUEST,
  payload: query
});

export const resetReceiptPaymentTypeState = () => ({
  type: Types.RESET_RECEIPT_PAYMENT_TYPE_STATE
});
