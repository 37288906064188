import * as Types from '~/constants/actionTypes';

export const getProductOrders = query => ({
  type: Types.GET_PRODUCT_ORDERS_REQUEST,
  payload: query
});

export const getProductOrder = id => ({
  type: Types.GET_PRODUCT_ORDER_REQUEST,
  payload: id
});

export const createProductOrder = order => ({
  type: Types.CREATE_PRODUCT_ORDER_REQUEST,
  payload: order
});

export const updateProductOrder = order => ({
  type: Types.UPDATE_PRODUCT_ORDER_REQUEST,
  payload: order
});

export const deleteProductOrder = id => ({
  type: Types.DELETE_PRODUCT_ORDER_REQUEST,
  payload: id
});

export const resetProductOrderState = () => ({
  type: Types.RESET_PRODUCT_ORDER_STATE
});

export const updateStatusProductOrderBillPM = data => ({
  type: Types.UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_REQUEST,
  payload: data
});