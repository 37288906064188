import * as Types from '~/constants/actionTypes';

export const getWarehouses = query => ({
  type: Types.GET_WAREHOUSES_REQUEST,
  payload: query
});

export const getWarehouse = id => ({
  type: Types.GET_WAREHOUSE_REQUEST,
  payload: id
});

export const createWarehouse = unit => ({
  type: Types.CREATE_WAREHOUSE_REQUEST,
  payload: unit
});

export const updateWarehouse = unit => ({
  type: Types.UPDATE_WAREHOUSE_REQUEST,
  payload: unit
});

export const deleteWarehouse = id => ({
  type: Types.DELETE_WAREHOUSE_REQUEST,
  payload: id
});

export const resetWarehouseState = () => ({
  type: Types.RESET_WAREHOUSE_STATE
});
