import produce from 'immer';
import { get } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';
// import getPaging from '~/utils/getPaging';

const findById = (data, id) => data.find(({ _id }) => _id === id);

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null,

  options: [],
  isGetOptionsLoading: false,
  getOptionsFailed: null,

  countries: [],
  isGetCountriesLoading: false,
  getCountriesFailed: null,

  products: [],
  isSearchProductsLoading: false,
  searchProductsFailed: false,

  product: null,
  isSearchProductLoading: false,
  searchProductFailed: false,

  variantsByProduct: [],
  isSearchVariantsByProductLoading: false,
  searchVariantsByProductFailed: false,

  productStock: {},
  isGetProductStockLoading: false,
  getProductStockFailed: null,

  productStockCard: [],
  isGetProductStockCardLoading: false,
  getProductStockCardFailed: null,

  productReferenceDocStockCard: {},
  isGetReferenceDocProductStockCardLoading: false,
  getReferenceDocProductStockCardFailed: null,

  productLot: {},
  isGetProductLotLoading: false,
  getProductLotFailed: null,

  productStockCardOutside: {},
  isGetProductStockCardOutsideLoading: false,
  getProductStockCardOutsideFailed: null,
  loadingSubmitDrugRequest:false,
  loadingSubmitTryDrugRequest:false,
  getProductDrugRequestLoading:false,
  getProductDrugRequestFailed:null,

  productDrugRequestList:{},
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_MEDICINES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_MEDICINES_SUCCESS:
      state.isLoading = false;
      state.list = get(payload,['data','docs'],[]).map(medicine => {

        const checkVairant =  medicine.productVariants.find(({variantCode})=>{
          return String(variantCode).includes(get(payload.query,'keyword'))
        })
        const checkIsDefault = checkVairant ?? medicine.productVariants.find(({isDefault,variantCode})=>(!!isDefault||variantCode))
        return {
          ...medicine,
          
          selectedVariant: checkIsDefault
        }
      });
      state.paging = getPaging(payload.data);

      // state.paging = getPaging(payload);
      return;
    case Types.GET_MEDICINES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_MEDICINE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_MEDICINE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_MEDICINE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_MEDICINE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_MEDICINE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_MEDICINE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_MEDICINE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_MEDICINE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_MEDICINE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_MEDICINE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_MEDICINE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_MEDICINE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_MEDICINE_OPTIONS_REQUEST:
      state.isGetOptionsLoading = true;
      state.getOptionsFailed = null;
      return;

    case Types.GET_MEDICINE_OPTIONS_SUCCESS:
      state.isGetOptionsLoading = false;
      state.options = payload;

      return;

    case Types.GET_MEDICINE_OPTIONS_FAILED:
      state.isGetOptionsLoading = false;
      state.getOptionsFailed = payload;
      state.options = [];
      return;

    case Types.SEARCH_PRODUCTS_REQUEST:
      state.isSearchProductsLoading = true;
      state.searchProductsFailed = null;
      return;

    case Types.SEARCH_PRODUCTS_SUCCESS:
      state.isSearchProductsLoading = false;
      state.products = payload;

      return;

    case Types.SEARCH_PRODUCTS_FAILED:
      state.isSearchProductsLoading = false;
      state.searchProductsFailed = payload;
      state.products = [];
      return;

    case Types.SEARCH_PRODUCT_REQUEST:
      state.isSearchProductLoading = true;
      state.searchProductFailed = null;
      state.product = null;
      return;

    case Types.SEARCH_PRODUCT_SUCCESS:
      state.isSearchProductLoading = false;
      state.product = payload?.[0];
      return;

    case Types.SEARCH_PRODUCT_FAILED:
      state.isSearchProductLoading = false;
      state.searchProductFailed = payload;
      return;

    case Types.SEARCH_VARIANTS_BY_PRODUCT_REQUEST:
      state.isSearchVariantsByProductLoading = true;
      state.searchProductFailed = null;
      state.variantsByProduct = [];
      return;

    case Types.SEARCH_VARIANTS_BY_PRODUCT_SUCCESS:
      state.isSearchVariantsByProductLoading = false;
      state.variantsByProduct = payload;
      return;

    case Types.SEARCH_VARIANTS_BY_PRODUCT_FAILED:
      state.isSearchVariantsByProductLoading = false;
      state.searchVariantsByProductFailed = payload;
      return;

    case Types.GET_COUNTRIES_REQUEST:
      state.isGetCountriesLoading = true;
      state.getCountriesFailed = null;
      return;

    case Types.GET_COUNTRIES_SUCCESS:
      state.isGetCountriesLoading = false;
      state.countries = [...payload, { name: 'no country', _id: 'no-country' }];

      return;

    case Types.GET_COUNTRIES_FAILED:
      state.isGetCountriesLoading = false;
      state.getCountriesFailed = payload;
      state.countries = [];
      return;

    case Types.SET_SELECTED_PRODUCT_VARIANT:
      state.list = state.list.map(medicine => {
        if (medicine._id !== payload.productId) return medicine;

        return {
          ...medicine,
          selectedVariant: findById(medicine.productVariants, payload.variantId)
        };
      });
      return;

    case Types.GET_PRODUCT_STOCK_REQUEST:
      state.isGetProductStockLoading = true;
      state.getProductStockFailed = null;
      return;

    case Types.GET_PRODUCT_STOCK_SUCCESS:
      state.isGetProductStockLoading = false;
      state.productStock[payload.productId] = [payload.data];
      return;

    case Types.GET_PRODUCT_STOCK_FAILED:
      state.isGetProductStockLoading = false;
      state.getProductStockFailed = payload;
      return;

    case Types.GET_PRODUCT_STOCK_CARD_REQUEST:
      state.isGetProductStockCardLoading = true;
      state.getProductStockCardFailed = null;
      return;

    case Types.GET_PRODUCT_STOCK_CARD_SUCCESS:
      state.isGetProductStockCardLoading = false;
      state.productStockCard = [...payload.data];
      return;

    case Types.GET_PRODUCT_STOCK_CARD_FAILED:
      state.isGetProductStockCardLoading = false;
      state.getProductStockCardFailed = payload;
      return;

    case Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_REQUEST:
      state.isGetReferenceDocProductStockCardLoading = true;
      state.getReferenceDocProductStockCardFailed = null;
      return;

    case Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_SUCCESS:
      state.isGetReferenceDocProductStockCardLoading = false;
      state.productReferenceDocStockCard = payload;
      return;

    case Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_FAILED:
      state.isGetReferenceDocProductStockCardLoading = false;
      state.getReferenceDocProductStockCardFailed = payload;
      return;

    case Types.GET_PRODUCT_LOT_REQUEST:
      state.isGetProductLotLoading = true;
      state.getProductLotFailed = null;
      return;

    case Types.GET_PRODUCT_LOT_SUCCESS:
      state.isGetProductLotLoading = false;
      let {docs:docsLot,...pagingLot} = payload.data;
      state.productLot[payload.productId] ={ docs: docsLot ,paging: pagingLot};
      return;

    case Types.GET_PRODUCT_LOT_FAILED:
      state.isGetProductLotLoading = false;
      state.getProductLotFailed = payload;
      return;

    case Types.RESET_MEDICINE_STATE:
    case Types.RESET_STORE:
      return initState;
    case Types.SET_SELECTED_PURCHASE_RETURN_PRODUCT_VARIANT:
      const newProducts = state.products.map((product) => {
        if (product.productId !== payload.productId) return product;
        const newSelectedVariant = findById(product.productVariants, payload.variantId)

        const {exchangeValue} = newSelectedVariant
        const newBatches = product.batches.map((batch) => {
          const quantityConvertUnit = (typeof batch.exchangeValue === 'undefined') ? 0 : (batch.quantity * batch.exchangeValue) / exchangeValue
          return {
            ...batch,
            quantityConvertUnit:quantityConvertUnit
          }
        })
        return {
          ...product,
          batches: newBatches,
          selectedVariant: newSelectedVariant
        };
      })

      state.products = [...newProducts]
      return;
    case Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_REQUEST:
      state.isGetProductStockCardOutsideLoading = true;
      state.getProductStockCardOutsideFailed = null;
      return;

    case Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_SUCCESS:
      state.isGetProductStockCardOutsideLoading = false;
      const  {docs ,...paging } = payload.data
      state.productStockCardOutside[payload.params.id] =  {
        docs: [...docs],
        paging : paging
      };
      return;

    case Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_FAILED:
      state.isGetProductStockCardOutsideLoading = false;
      state.getProductStockCardOutsideFailed = payload;
      return;

    case Types.SUBMIT_DRUG_REQUEST_REQUEST:
      state.loadingSubmitDrugRequest = true;
      return;

    case Types.SUBMIT_DRUG_REQUEST_SUCCESS:
      state.loadingSubmitDrugRequest = false;
      return;

    case Types.SUBMIT_DRUG_REQUEST_FAILED:
      state.loadingSubmitDrugRequest = false;
      return;
      
    case Types.SUBMIT_TRY_DRUG_REQUEST_REQUEST:
      state.loadingSubmitTryDrugRequest = true;
      return;

    case Types.SUBMIT_TRY_DRUG_REQUEST_SUCCESS:
      state.loadingSubmitTryDrugRequest = false;
      return;

    case Types.SUBMIT_TRY_DRUG_REQUEST_FAILED:
      state.loadingSubmitTryDrugRequest = false;
      return;

    case Types.GET_PRODUCT_DRUG_REQUEST_REQUEST:
      state.getProductDrugRequestLoading = true;
      state.getProductDrugRequestFailed = null;
      return;

    case Types.GET_PRODUCT_DRUG_REQUEST_SUCCESS:
      state.getProductDrugRequestLoading = false;
      state.productDrugRequestList[payload.params.id] = payload.data;
      return;

    case Types.GET_PRODUCT_DRUG_REQUEST_FAILED:
      state.getProductDrugRequestLoading = false;
      state.getProductDrugRequestFailed = payload;
      return;
    default:
      return;
  }
}, initState);
