import { useMemo } from 'react';
import {
  useSubmit,
  useFetchByParam,
} from '~/hooks/utils';

import {
  getPolicySystemPartner,
  updatePermissionSystemPartnerPermission,
  getPolicySystemBranches,
  updatePermissionSystemBranchesPermission,
  getResourcesBranch
} from '~/redux/action';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const STORE_MODULE = 'permission';

const getSelector = (key) => (state) => state[STORE_MODULE][key];

const getResourcesLoadingSelector = getSelector('isGetResourcesLoading');
const resourcesSelector = getSelector('resources');
const getResourcesFailedSelector = getSelector('getResourcesFailed');

const getIsLoadingPolicy = getSelector('isLoadingPolicy')
const getPolicySystemPartnerFailed = getSelector('getPolicySystemPartnerFailed')
const policySystemPartner = getSelector('policySystemPartner')

const getIsLoadingPolicyBranches = getSelector('isLoadingPolicyBranches')
const getPolicySystemBranchesFailed = getSelector('getPolicySystemBranchesFailed')
const policySystemBranches = getSelector('policySystemBranches')

const isUpdatePermissionLoadingSystemSelector = getSelector(
  'isUpdatePermissionLoadingSystem'
);
const isUpdatePermissionLoadingSystemBranchesSelector = getSelector(
  'isUpdatePermissionLoadingBranchesSystem'
);

const actionsSelector = getSelector('actions');

export const useResourcePermissionColumns = (renderPermission) => {
  const actions = useSelector(actionsSelector);
  const actionColumns = actions.map(({ name, key }) => {
    return({
    title: name,
    dataIndex: key,
    key: key,
    width: '12%',
    align: 'center',
    render: renderPermission
  })});

  return [
    {
      title: 'Chức năng',
      dataIndex: ['resource', 'name'],
      key: 'resource'
    },
    ...actionColumns
  ];
};


export const useResourcesBranch = () => {
  const { id,  branchId } = useParams();

  const branchParam = useMemo(() => ({ branchId:  branchId ?? id }), [branchId, id]);

  return useFetchByParam({
    action: getResourcesBranch,
    loadingSelector: getResourcesLoadingSelector,
    dataSelector: resourcesSelector,
    failedSelector: getResourcesFailedSelector,
    param: branchParam
  });
};

export const usePolicySystemPartner = (params) => {
  return useFetchByParam({
    action: getPolicySystemPartner,
    loadingSelector: getIsLoadingPolicy,
    dataSelector: policySystemPartner,
    failedSelector: getPolicySystemPartnerFailed,
    param: params
  });
};

export const usePolicySystemBranches = (params) => {
  return useFetchByParam({
    action: getPolicySystemBranches,
    loadingSelector: getIsLoadingPolicyBranches,
    dataSelector: policySystemBranches,
    failedSelector: getPolicySystemBranchesFailed,
    param: params
  });
};

export const useUpdatePermissionSystemPartner = () => {
  return useSubmit({
    loadingSelector: isUpdatePermissionLoadingSystemSelector,
    action: updatePermissionSystemPartnerPermission
  });
};

export const useUpdatePermissionSystemBranches = () => {
  return useSubmit({
    loadingSelector: isUpdatePermissionLoadingSystemBranchesSelector,
    action: updatePermissionSystemBranchesPermission
  });
};
