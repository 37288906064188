import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isLoadingPolicy: false,
  getPolicySystemPartnerFailed: undefined,
  policySystemPartner: [],

  isLoadingPolicyBranches: false,
  getPolicySystemBranchesFailed: undefined,
  policySystemBranches: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  resources: [],
  isGetResourcesLoading: false,
  getResourcesFailed: null,
  actions: [],

  isUpdatePermissionLoading: false,
  updatePermissionSuccess: null,
  updatePermissionFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    
    case Types.GET_POLICY_SYSTEM_PARTNER_REQUEST:
      state.isLoadingPolicy = true;
      state.getPolicySystemPartnerFailed = null;
      return;

    case Types.GET_POLICY_SYSTEM_PARTNER_SUCCESS:
      state.isLoadingPolicy = false;
      state.policySystemPartner = payload;
      return;

    case Types.GET_POLICY_SYSTEM_PARTNER_FAILED:
      state.isLoading = false;
      state.getPolicySystemPartnerFailed = null;
      state.policySystemPartner = [];
      return;
    
      case Types.UPDATE_POLICY_SYSTEM_PARTNER_REQUEST:
      state.isUpdatePermissionLoading = true;
      state.updatePermissionSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_POLICY_SYSTEM_PARTNER_SUCCESS:
      state.isUpdatePermissionLoading = false;
      state.updatePermissionSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_POLICY_SYSTEM_PARTNER_FAILED:
      state.isUpdatePermissionLoading = false;
      state.updatePermissionFailed = payload;
      return;
    
    //PERMISSION BRANCH
    case Types.GET_POLICY_SYSTEM_BRANCHES_REQUEST:
      state.isLoadingPolicyBranches = true;
      state.getPolicySystemPartnerFailedBranches = null;
      return;

    case Types.GET_POLICY_SYSTEM_BRANCHES_SUCCESS:
      state.isLoadingPolicyBranches = false;
      state.policySystemBranches = payload;
      return;

    case Types.GET_POLICY_SYSTEM_BRANCHES_FAILED:
      state.isLoadingBranches = false;
      state.getPolicySystemPartnerFailedBranches = null;
      state.policySystemBranches = [];
      return;

    case Types.GET_RESOURCES_BRANCH_REQUEST:
      state.isGetResourcesLoading = true;
      state.getResourcesFailed = null;
      return;

    case Types.GET_RESOURCES_BRANCH_SUCCESS:
      const { actions: actionBranch, resources: resourcesBranch } = payload;

      const nextResourcesBranch = resourcesBranch.map((resource) => {
        const resourceWithKey = { resource };
        const nextResource = actionBranch.reduce((acc, action) => {
          const actionWithKey = {
            [action.key]: action
          };

          return {
            ...acc,
            ...actionWithKey
          };
        }, resourceWithKey);

        return nextResource;
      });

      state.isGetResourcesLoading = false;
      state.resources = nextResourcesBranch;
      state.actions = actionBranch;
      return;

    case Types.GET_RESOURCES_BRANCH_FAILED:
      state.isGetResourcesLoading = false;
      state.getResourcesFailed = payload;
      return;

    case Types.RESET_BRANCH_GROUP_STATE:
    case Types.RESET_PARTNER_GROUP_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
