import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  DISCOUNT_TYPES,
  SALE_CASES,
  SALE_TYPES
} from '~/constants/defaultValue';

const initList = [
  {
    name: 'Hoá đơn 1',
    medicines: [],
    summary: {
      discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
      payment: [{ method: 'CASH', value: 0 }]
    },
    uuid: uuidv4(),
    type: SALE_TYPES.DIRECT,
    isActive: true,
    case: SALE_CASES.NEW_INVOICE
  }
];

const initState = {
  list: initList,
  isLoading: false,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  createSuccess: null,
  createFailed: null,

  isSubmitLoading: false,
  updateSuccess: null,
  updateFailed: null,

  updateLogisticCodeSuccess: null,
  updateLogisticCodeFailed: null,

  updateCheckInOutSuccess: null,
  updateCheckInOutFailed: null,

  selectedSale: initList[0],
  submitingUUID: null,

  isNewBill: false,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_SALE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_SALE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_SALE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.UPDATE_SALE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.submitingUUID = payload.uuid;
      return;

    case Types.UPDATE_SALE_SUCCESS:
      state.updateSuccess = payload;
      return;

    case Types.UPDATE_SALE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      state.submitingUUID = null;
      return;

    case Types.CREATE_SALE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      state.submitingUUID = payload.uuid;

      return;

    case Types.CREATE_SALE_SUCCESS:
      state.createSuccess = payload;
      return;

    case Types.REMOVE_SUBMITED_SALE:
      const removedSale = state.list.find(
        ({ uuid }) => uuid === state.submitingUUID
      );

      const removedUuids = state.list.reduce((dict, item) => {
        return {
          ...dict,
          [item?.uuid]:
            removedSale?.uuid === item?.uuid ||
            (removedSale?.saleOrderId &&
              item?.saleOrderId === removedSale?.saleOrderId) || (item.type === 'RETURN' && item?.uuid)
        };
      }, {});

      let nextList = state.list.filter(({ uuid }) => !removedUuids[uuid]);

      if (nextList.length === 0) {
        nextList = initList;
      }

      state.list = nextList;
      state.selectedSale = nextList[0];
      state.submitingUUID = null;
      state.isSubmitLoading = false
      return;

    case Types.CREATE_SALE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      state.submitingUUID = null;
      return;

    case Types.UPDATE_SALES:
      state.list = payload;
      state.selectedSale = state.list.find(
        ({ uuid }) => uuid === state?.selectedSale?.uuid
      );
      return;

    case Types.CREATE_SALE:
      if (payload.saleOrderId) {
        state.list = state.list.filter(
          sale =>
            sale.saleOrderId !== payload.saleOrderId ||
            sale.case !== payload.case
        );
      }

      state.list = [...state.list, payload];
      state.selectedSale = payload;
      return;

    case Types.DELETE_SALE:
      if (state.list.length > 1) {
        const currentList = state.list;

        state.list = state.list.filter(({ uuid }) => uuid !== payload);

        const isDeletingSelectedSale = state?.selectedSale?.uuid === payload;

        if (isDeletingSelectedSale) {
          const deletedIndex = currentList.findIndex(
            ({ uuid }) => uuid === payload
          );
          const nextSelectedIndex = deletedIndex > 1 ? deletedIndex - 1 : 0;

          state.selectedSale = state.list[nextSelectedIndex];
        }
      } else {
        state.list = [...initList];
        state.selectedSale = state.list[0];
      }

      return;

    case Types.SET_SELECTED_SALE:
      state.selectedSale = state.list.find(sale => sale.uuid === payload);
      return;

    case Types.RESET_SALE_STATE:
      return {
        ...initState,
        // CLEAR OLD DATA THAT NOT HAS CASE PROPERTY
        list: state.list,
        selectedSale: state.selectedSale
      };

    case Types.RESET_STORE:
      return { ...initState };
    
      case Types.UPDATE_LOGISTIC_CODE_SALE_REQUEST:
        state.isSubmitLoading = true;
        return;
  
      case Types.UPDATE_LOGISTIC_CODE_SALE_SUCCESS:
        state.updateLogisticCodeSuccess = payload;
        return;
  
      case Types.UPDATE_LOGISTIC_CODE_SALE_FAILED:
        state.isSubmitLoading = false;
        state.updateLogisticCodeFailed = payload;
      return;
    
      case Types.SALE_CHECK_IN_OUT_REQUEST:
      state.isSubmitLoading = true;
      state.updateCheckInOutSuccess = null;
      state.updateCheckInOutFailed = null;
        return;
  
    case Types.SALE_CHECK_IN_OUT_SUCCESS:
        state.isSubmitLoading = false;
        state.updateCheckInOutSuccess = payload;
        state.selectedSale = {
          ...state.selectedSale,
          ...payload
        };
        return;
  
      case Types.SALE_CHECK_IN_OUT_FAILED:
        state.isSubmitLoading = false;
        state.updateCheckInOutFailed = payload;
      return;
    
      case Types.SET_NEW_BILL:
        state.isNewBill = payload;
        return;

    default:
      return;
  }
}, initState);
