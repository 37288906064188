import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import toastr from 'toastr';
import { isArray, isObject } from 'lodash';
import moment from 'moment';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};

export const useSuccess = (successSelector, mess, onSuccess) => {
  const success = useSelector(successSelector);

  useEffect(() => {
    if (success) {
      if (mess) {
        toastr.success(mess);
      }

      if (typeof onSuccess==='function') onSuccess(success);
    }
    //eslint-disable-next-line
  }, [success, mess]);
};

export const useFailed = (failedSelector, mess, onFailed) => {
  const failed = useSelector(failedSelector);

  useEffect(() => {
    if (failed) {
      // if (failed?.response?.data?.code === 'FORBIDDEN') {
      //   toastr.error(
      //     `Bạn không có quyền truy cập vào ${}`
      //   );
      // }
      toastr.error(
        failed?.response?.data?.message || mess || 'Something went wrong!'
      );
    }

    if (onFailed) onFailed(failed);
  }, [failed, mess, onFailed]);
};

export const useFetch = props => {
  const { action, dataSelector, failedSelector, loadingSelector } = props;

  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const isLoading = useSelector(loadingSelector);

  useEffect(() => {
    dispatch(action());
  }, [dispatch, action]);

  useFailed(failedSelector);

  return [data, isLoading];
};

export const useFetchByParam = props => {
  const {
    action,
    dataSelector,
    failedSelector,
    loadingSelector,
    pagingSelector=()=>{},
    param
  } = props;

  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const isLoading = useSelector(loadingSelector);
  const paging = useSelector(pagingSelector);

  useEffect(() => {
    if (param) dispatch(action(param));
  }, [dispatch, action, param]);

  useFailed(failedSelector);
  return [data, isLoading,paging];
};

export const useCustomFetch = props => {
  const {
    action,
    dataSelector,
    failedSelector,
    loadingSelector,
    param
  } = props;

  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const isLoading = useSelector(loadingSelector);

  const onRefresh = () => {
    dispatch(action(param));
  };

  useEffect(() => {
    dispatch(action(param));
  }, [dispatch, action, param]);

  useFailed(failedSelector);

  return [data, isLoading, onRefresh];
};

export const useSubmit = ({ loadingSelector, action }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);

  const handleSubmit = useCallback(
    values => {
      dispatch(action(values));
    },
    [action, dispatch]
  );

  return [isLoading, handleSubmit];
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useResetState = resetAction => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetAction());
    };
  }, [dispatch, resetAction]);
};

export function removeAccents(str) {
  return str
  .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a")
  .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e")
  .replace(/ì|í|ị|ỉ|ĩ/g,"i")
  .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o")
  .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u")
  .replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y")
  .replace(/đ/g,"d")
  .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
  .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
  .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
  .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
  .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
  .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
  .replace(/Đ/g, "D")
}

export const filterAcrossAccents = (input, option) => {
  return (
    removeAccents(option.children.toLowerCase()).indexOf(removeAccents(input.toLowerCase())) >=
    0
  );
};

export const filterGroupAcrossAccents = (input, group) => {
  return {
    ...group,
    options: group.options.filter(
      option =>
        removeAccents(option.children.toLowerCase()).indexOf(
          input.toLowerCase()
        ) >= 0
    )
  };
};

export const useFormItemMargin = () => {
  const [margin, setMargin] = useState(-7);

  useEffect(() => {
    const onLarge = media => {
      if (media.matches) {
        setMargin(-7);
      } else {
        setMargin(0);
      }
    };

    const media = window.matchMedia('(min-width: 982px)');
    onLarge(media);

    media.addListener(onLarge);
    return () => {
      media.removeListener(onLarge);
    };
  }, []);

  return margin;
};

//Config notification by Toastr

const config = {
  preventDuplicates: true,  
  closeButton: true,
  newestOnTop: true, 
  timeOut: '1200',
  hideDuration: '300',
}
const toastrNotify = {
  error: (message, title = '', fix) => toastr.error(message, title, { ...config, ...fix }),
  success: (message, title = '', fix) => toastr.success(message, title, { ...config, ...fix }),
  warning: (message, title = '', fix) => toastr.warning(message, title, { ...config, ...fix }),
};
export default toastrNotify
export const getSelectors = moduleName => {
  const getSelector = key => state => state[moduleName][key];
  return {
    loadingSelector: getSelector('isLoading'),
    listSelector: getSelector('list'),
    summarySelector: getSelector('summary'),
    getListFailedSelector: getSelector('getListFailed'),

    getByIdLoadingSelector: getSelector('isGetByIdLoading'),
    getByIdSelector: getSelector('byId'),
    getByIdFailedSelector: getSelector('getByIdFailed'),

    deleteSuccessSelector: getSelector('deleteSuccess'),
    deleteFailedSelector: getSelector('deleteFailed'),

    isSubmitLoadingSelector: getSelector('isSubmitLoading'),
    createSuccessSelector: getSelector('createSuccess'),
    createFailedSelector: getSelector('createFailed'),

    updateSuccessSelector: getSelector('updateSuccess'),
    updateFailedSelector: getSelector('updateFailed'),
    pagingSelector: getSelector('paging')
  };
};

/**
 * 
 * @param {Object} obj 
 * @param {Array|String} key // KEY CAN BE ARRAY OR STRING
 * create string from object and keys of object input 
 * EXAMPLE : obj = {a : {b : 1}} ,key = [a,b] , => 1
 */
export const deeplyObject = (obj, key) => {
  let value;
  if (isArray(key)) {
    key?.forEach((k => {
      if (isObject(value)) {
        value = value[k]
      } else {
        value = obj[k]
      }
    }))
  }
  else {
    value = obj[key]
  }
  return value
}

export const vietnamMoment =(v,formatTime)=> {
  if(v){
    const utcMoment = moment.utc(v);
    if(formatTime){
      return utcMoment.format(formatTime);
    }
    else{
      return utcMoment
    }
  }
  return null
 
}