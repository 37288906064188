import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getStockTakes,
  createStockTake,
  deleteStockTake,
  getStockTake,
  resetStockTakeState,
  updateStockTake, saveStockTakeLump, searchProducts
} from '~/redux/action';
import { Menu, Checkbox, Button, InputNumber } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp, formatNumber } from '~/utils/helper';
import { DeleteOutlined } from '@ant-design/icons';
import Api from '~/api';
import { DISCOUNT_TYPES } from '~/constants/defaultValue';
const STOCK_TAKE_MODULE = 'stockTake';

const CREATE_SUCCESS_MESS = 'Tạo phiếu phiếu thành công';
const CREATE_FAILED_MESS = 'Tạo phiếu phiếu thất bại';
const DELETE_SUCCESS_MESS = 'Xoá phiếu kiểm kho thành công';
const DELETE_FAILED_MESS = 'Xoá phiếu kiểm kho thất bại';
const UPDATE_SUCCESS_MESS = 'Update phiếu kiểm kho thành công';
const UPDATE_FAILED_MESS = 'Update phiếu kiểm kho thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(STOCK_TAKE_MODULE);

const getSelector = key => state => state[STOCK_TAKE_MODULE][key];

const saveSuccessSelector = getSelector('saveSuccess');
const saveFailedSelector = getSelector('saveFailed');

export const useStockTakes = query => {
  return useFetchByParam({
    action: getStockTakes,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateStockTake = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createStockTake
  });
};

export const useSaveStockTakeLump = callback => {
  useSuccess(saveSuccessSelector, 'Gộp phiếu kiểm kho thành công', callback);
  useFailed(saveFailedSelector, 'Gộp phiếu kiểm kho thất bạn');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: saveStockTakeLump
  });
};

export const useDeleteStockTake = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteStockTake
  });
};

export const useUpdateStockTake = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateStockTake
  });
};

const stockTakesSelector = getSelector('stockTakes');
const searchStockTakesLoadingSelector = getSelector('isSearchStockTakesLoading');
const searchStockTakesFailedSelector = getSelector('searchStockTakesFailed');

export const useSearchStockTakes = searchText => {
  return useFetchByParam({
    action: searchProducts,
    loadingSelector: searchStockTakesLoadingSelector,
    dataSelector: stockTakesSelector,
    failedSelector: searchStockTakesFailedSelector,
    param: searchText
  });
};


let timeOut;

export const useSearchStockTakeBox = () => {
  const [searchText, setSearchText] = useState('');
  const [products, isLoading] = useSearchStockTakes(searchText);

  const inputEl = useRef(null);

  useEffect(() => {
    return () => {
      timeOut = null;
    };
  }, []);

  const onSearch = value => {
    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      if (timeOut) {
        setSearchText(value);
      }
    }, 500);
  };

  return [
    {
      products,
      isLoading,
      inputEl,
      searchText
    },
    { onSearch }
  ];
};

export const useStockTake = params => {
  return useFetchByParam({
    action: getStockTake,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitStockTake = (warehouse, id) => {
  return useMemo(() => {
    if (!warehouse || !id) {
      return {};
    }

    return { ...warehouse };
  }, [warehouse, id]);
};
export const useInitStockTakeOld = ({state,setFormData})=>{
  const useStockTake =(state)=>( state.id ? useStockTakes :()=>([[],false]))
  
  const [data,isGetPurchaseReturnLoading] =  useStockTake(state)(state)
  let purchaseReturn =  useMemo(()=>(data[0]),[data]) 
  useEffect(()=>{
    if(purchaseReturn){
      let arr =[]
      const currentpurchaseReturn = purchaseReturn?.stocktakeDetail?.map((element)=>{
        const code = element?.variantId?.variantCode
        const  inventoryQuantity = element?.inventoryQuantity;
        const  actualCount = element?.actualCount;
        const  adjustmentValue = element?.adjustmentValue;
        const selectedBatches = [{
          ...element?.batchId,
          availableQuantity: inventoryQuantity,
          quantity: actualCount,
        }];
        
          arr.push(Api.medicine.searchProducts(code))
            return { code, inventoryQuantity,actualCount,  adjustmentValue,selectedBatches }
        })
    
        Promise.allSettled(arr).then((res)=>{
           const resultRes = res.filter((value)=>{
             return value.status ==="fulfilled"
          }).map((element)=>{
            const ressslt = element.value[0]
            const valueOld =  currentpurchaseReturn.find(({code})=> code === ressslt.variantCode)
            const batches = ressslt.batches.map(batch => ({
                ...batch,
                availableQuantity: batch.quantity || 0,
                cost: ressslt.cost
              }))
            return {
              ...ressslt,
              batches,
              discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
              quantity: valueOld.actualCount ??0,
              variantId: ressslt._id,
              buyPrice: ressslt.cost,
              ...valueOld
            }
          })
          setFormData(resultRes);
        })
    }
  },[purchaseReturn,setFormData])
  return [purchaseReturn,isGetPurchaseReturnLoading]
}

export const useUpdateStockTakeParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/stocktake',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useStockTakeQueryParams = (param) => {
  // const prevKeyword = useRef(null);
  const query = useQueryParams(); 
  // const value = query.get(param)
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const id = query.get('id');
  const keyword = query.get('keyword');
  const code = query.get('code');
  const status = query.get('status');
  const createdAt = query.get('createdAt');
  const updatedAt = query.get('updatedAt');
  const lotNumber = query.get('lotNumber');
  const variantCodeProductName = query.get('variantCodeProductName');
  // const status =  param === "status" ? query.get('status') : 'COMPLETED,CANCELED,DRAFT';

  const deleteSuccess = useSelector(deleteSuccessSelector);

  // if (prevKeyword.current !== keyword) {
  //   prevKeyword.current = keyword;
  // }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      status,
      createdAt,
      updatedAt,
      id,
      code,
      lotNumber,
      variantCodeProductName
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, 
      limit,
      deleteSuccess,
      keyword,
      status,
      createdAt,
      updatedAt, 
      id,
      code,
      lotNumber,
      variantCodeProductName
    ]);
};

export const useStockTakePaging = () => useSelector(pagingSelector);

export const useResetStockTake = () => {
  useResetState(resetStockTakeState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã đặt hàng',
    key: 'code'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Ngày cân bằng',
    key: 'dayBalance'
  },
  {
    title: 'Số lượng thực tế',
    key: 'totalActualCount'
  },
  {
    title: 'Tổng thực tế',
    key: 'totalActualPrice'
  },
  {
    title: 'Tổng chênh lệch',
    key: 'totalAdjustmentValue'
  },
  {
    title: 'SL lệch tăng',
    key: 'totalIncreaseValue'
  },
  {
    title: 'SL lệch giảm',
    key: 'totalReducedValue'
  },
  {
    title: 'Ghi chú',
    key: 'description'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  }
];

export const useStockTakeColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    time: true,
    dayBalance: true,
    totalActualCount: true,
    totalActualPrice: true,
    totalAdjustmentValue: true,
    totalIncreaseValue: true,
    totalReducedValue: true,
    description: true,
    status: true,
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

export const useStockTakeColumns = (setFormData, handleSelect) => {
  const columns = useMemo(() => {
    const onDeleteVariant = variant => {
      setFormData(variants =>
        variants.filter(({ variantId }) => variantId !== variant.variantId)
      );
    };

    const onFieldChange = (fieldName, value, updatedIndex) => {
      setFormData(formData =>
        formData.map((item, index) => {
          if (index === updatedIndex) {
            if(item.batches[0]){
              item.batches[0][fieldName] = value
            }
            if(item.selectedBatches[0]){
              item.selectedBatches[0][fieldName] = value
            }
          }

          return index === updatedIndex
            ? {
              ...item,
              [fieldName]: value,
            }
            : item;
        })
      );
    };

    return [
      {
        title: '',
        key: 'delete',
        align: 'center',
        render: rc => (
          <Button
            onClick={() => onDeleteVariant(rc)}
            size="small"
            shape="circle"
            className="warehouse-form__delete-btn"
            icon={<DeleteOutlined />}
          />
        )
      },
      {
        title: 'STT',
        key: 'STT',
        align: 'center',
        render: (_, x, index) => index + 1
      },
      {
        title: 'Mã hàng',
        key: 'variantCode',
        dataIndex: 'variantCode',
      },
      {
        title: 'Tên hàng',
        key: 'name',
        dataIndex: ['product', 'name']
      },
      {
        title: 'ĐVT',
        key: 'unit',
        dataIndex: ['unit', 'name'],
        render: (unit) => {
          return (
            <div className="sale-table__unit">
              { unit }
            </div>
          )
        }
      },
      {
        title: 'Tồn kho',
        key: 'inventoryQuantity',
        dataIndex: ['inventoryQuantity'],
        render: (_, product) => {
          const sumAvailableQuantity = product.selectedBatches.reduce(
            (previousValue, currentValue) => previousValue + currentValue.availableQuantity, 0
          )

          return (
            <div>{sumAvailableQuantity}</div>
          )
        }
      },
      {
        title: 'Thực tế',
        key: 'actualCount',
        dataIndex: ['actualCount'],
        render: (_, product, index) => {
          return (
            <InputNumber
              disabled={product.selectedBatches.length > 1}
              size="small"
              className="warehouse-form__quantity"
              defaultValue={product['quantity'] ?? 0}
              value={ product['quantity'] ?? 0}
              onChange={value => onFieldChange('quantity', value, index)}
              bordered={false}
              min = {0}
            />
          )
        }
      },
      {
        title: 'SL lệch',
        key: 'adjustmentValue',
        dataIndex: ['adjustmentValue'],
        render: (_, product) => {
          const sumAvailableQuantity = product.selectedBatches.reduce(
            (previousValue, currentValue) => previousValue + currentValue.availableQuantity, 0
          )

          const sumQuantity = product.selectedBatches.reduce(
            (previousValue, currentValue) => previousValue + currentValue.quantity, 0
          )


          return (
            <div>{sumQuantity - sumAvailableQuantity}</div>
          )
        }
      },
      {
        title: 'Giá trả lệch',
        key: 'adjustmentValue',
        dataIndex: ['adjustmentValue'],
        align: 'end',
        render: (_, product) => {
          const sumAvailableQuantity = product.selectedBatches.reduce(
            (previousValue, currentValue) => previousValue + currentValue.availableQuantity, 0
          )

          const sumQuantity = product.selectedBatches.reduce(
            (previousValue, currentValue) => previousValue + currentValue.quantity, 0
          )

          return (
            <div>{formatNumber((sumQuantity - sumAvailableQuantity) * product.cost)}</div>
          )
        }
      }
    ];
  }, [setFormData]);

  return columns;
};
export const SEARCH_STOCKTAKE =[
  {
      name: "code",
      placeholder:"Tìm theo mã kiểm kho"
  },
  {
      name: "variantCodeProductName",
      placeholder:"Tìm theo tên hoặc là mã sản phẩm"
  },
  {
      name: "lotNumber",
      placeholder:"Tìm theo lô"
  },
  {
      name: "updatedAt",
      type: "date",
      label: "Ngày cân bằng",
  },
  {
      name: "createdAt",
      type: "rangerTime",
      label: "Thời gian",
  },
  {
      name: "status",
      type: "radio",
      label: "Trạng thái",
  }    
]