import React, { useEffect } from 'react';
import { Form, Input, Row, Col, Button, Skeleton, Modal } from 'antd';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreateShippingVendor,
  useShippingVendorDetail,
  useInitShippingVendor,
  useUpdateShippingVendor
} from '~/hooks';
import './index.scss';

const FormItem = Form.Item;

const ShippingVendorForm = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreateShippingVendor(onClose);
  const [, handleUpdate] = useUpdateShippingVendor(onClose);
  const [shippingVendor, isLoading] = useShippingVendorDetail(id);
  const initShippingVendor = useInitShippingVendor(shippingVendor, id);

  useEffect(() => {
    form.resetFields();
  }, [initShippingVendor, form]);

  const onFinish = (values) => {
    const shippingVendor = {
      ...values
    };

    if (id) {
      handleUpdate({ ...shippingVendor, _id: id });
    } else {
      handleCreate(shippingVendor);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={700}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="shippingVendor-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values) => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initShippingVendor}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} nhà vận chuyển
            </h4>
          </Row>
          <Row
            gutter={60}
            align="middle"
            justify="space-between"
            className="shippingVendor-form__logo-row"
          >
            <Col span={24}>
              <FormItem
                label="Tên nhà vận chuyển"
                name="name"
                rules={[{ required: true, message: 'Xin mời nhập tên' }]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem label="Mô tả" name="description">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row className="shippingVendor-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ShippingVendorForm;
