import {
  Form,
  Col,
  Row,
  Input,
  Button,
  Modal, Select
} from 'antd';

import {
  FileTextOutlined,
  StopOutlined
} from '@ant-design/icons';
import React, { useState }from 'react';
import { filterAcrossAccents } from '~/hooks/utils';
import { useCities, useDistricts, useWards, useCreateRecipientSubmitter } from '~/hooks';

const { TextArea } = Input;

const FormItem = Form.Item;
const { Option } = Select;

const RecipientsSubmittersTypeModal = ({ onClose, isOpen, isReceipt }) => {
  const tileModal = isReceipt ? 'Thêm người nộp' : 'Thêm người nhận';

  const [form] = Form.useForm();

  const [, handleCreate] = useCreateRecipientSubmitter(onClose);

  const handleOnFinish = (values) => {
    handleCreate({
      type: isReceipt ? 'RECEIPT' : 'PAYMENT',
      ...values
    })
  }
  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();
  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(cityCode);
  const [wards, isWardsLoading] = useWards(districtCode);

  const handleSetCityCode = (value) => {
    setCityCode(value);
  }

  const handleSetDistrictCode = (value) => {
    setDistrictCode(value)
  }

  return (
    <Modal forceRender width={600} visible={isOpen} footer={null} onCancel={onClose} closable={false} destroyOnClose={true}>
      <h4 style={{marginBottom: 30, fontSize: '1.5rem' }}> {tileModal}</h4>
      <Form form={form} onFinish={handleOnFinish}>
        <Row gutter={48} align="middle" justify="space-between">
          <Col span={24}>
            <Form.Item name="name" label={<span style={{width: '6.5rem', textAlign: 'left'}}>{isReceipt ? 'Người nộp' : 'Người nhận'}</span>}>
              <Input autoFocus={true}/>
            </Form.Item>
            <FormItem
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Email</span>}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Email bạn nhập không đúng định dạng!'
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Thành Phố/Tỉnh</span>}
              name={['address', 'cityId']}
              rules={[
                {
                  message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                }
              ]}
            >
              <Select
                onChange={handleSetCityCode}
                disabled={isCitiesLoading}
                loading={isCitiesLoading}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {cities.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Quận/Huyện</span>}
              name={['address', 'districtId']}
              rules={[
                {
                  message: 'Xin vui lòng chọn Quận/Huyện!'
                }
              ]}
            >
              <Select
                loading={isDistrictsLoading}
                disabled={!form.getFieldValue(['address', 'cityId'])}
                onChange={handleSetDistrictCode}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {districts.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Phường/Xã</span>}
              name={['address', 'wardId']}
              rules={[
                {
                  message: 'Xin vui lòng chọn Phường/Xã!'
                }
              ]}
            >
              <Select
                loading={isWardsLoading}
                disabled={!form.getFieldValue(['address', 'districtId'])}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {wards.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Đường phố</span>}
              name={['address', 'street']}>
              <Input />
            </FormItem>
            <FormItem
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Số điện thoại</span>}
              name="phoneNumber"
              rules={[
                {
                  pattern: new RegExp(/^[0-9]{10,11}$/),
                  message: 'Xin vui lòng nhập đúng số điện thoại!'
                }
              ]}
            >
              <Input />
            </FormItem>
            <Form.Item
              label={<span style={{width: '6.5rem', textAlign: 'left'}}>Ghi chú</span>}
              name="description"
            >
              <TextArea rows={1} name="note"/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button htmlType="submit" type="primary" shape="round" icon={<FileTextOutlined size={'medium'}/>} size={'large'}
                  style={{display: 'flex', alignItems: 'center', paddingLeft: '30px', paddingRight: '30px',}}>
            Lưu
          </Button>
          <Button onClick={onClose} type="primary" shape="round" icon={<StopOutlined size={'medium'}/>} size={'large'}
                  style={{display: 'flex', alignItems: 'center', backgroundColor: '#898c8d', borderColor: '#898c8d'}}>
            Bỏ qua
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default RecipientsSubmittersTypeModal;
