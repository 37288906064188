import React from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import InformationItem from '~/components/Common/InformationItem';
import DetailTable from './DetailTable';
import { formatDateTime } from '~/utils/helper';
import { API_LIST, INVOICE_STATUS } from '~/constants/defaultValue';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import { PATH_CLINIC } from '~/routes/paths';

const LEFT_INFO = [
  {
    label: 'Mã kiểm kho:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Thời gian:',
    dataIndex: 'createdAt',
    render: createdAt => formatDateTime(createdAt)
  },
  {
    label: 'Ngày cân bằng',
    dataIndex: 'updatedAt',
    render: updateAt => formatDateTime(updateAt)
  }
];

const RIGHT_INFO = [
  {
    label: 'Trạng thái:',
    dataIndex: 'status',
    render: status => {
      return INVOICE_STATUS[status];
    }
  },
  {
    label: 'Người tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  }
];

const Information = ({ record, deleteRow, updateStockTake,history }) => {
  return (
    <div className="warehouse-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={8}>
          <div className="warehouse-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <InformationItem
              key={'Ghi chú'}
              label="Ghi chú"
              value={record?.description}
            />
          </div>
        </Col>
      </Row>

      <DetailTable datasource={record} />

      <Row className="medicine-info__actions" justify="end">
        <WithPermission permission={POLICIES.UPDATE_STOCKTAKE}>
        <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
            // updateStockTake({
            //   id: record._id,
            //   description: "updated"
            // })
            history.push({
              pathname: PATH_CLINIC.stockTake.create,
              state: {
                id: record._id
              }
            })
          }}>
            <EditOutlined style={{ marginRight: 0 }} /> Cập nhật
          </Button>
        </WithPermission>

        <WithPermission permission={POLICIES.WRITE_STOCKTAKE}>
          <ButtonExport
            router={API_LIST.stockTake}
            label="Kiểm kho"
            id={record._id}
            fileName={`Chitietkiemkho_${record.code}`}
          />
        </WithPermission>

        <WithPermission permission={POLICIES.DELETE_STOCKTAKE}>
          <Popconfirm
            title="Bạn muốn xoá đơn hàng này?"
            onConfirm={() => deleteRow(record._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger">
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>{' '}
        </WithPermission>
      </Row>
    </div>
  );
};

export default Information;
