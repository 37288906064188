import {
  getSelectors,
  useFetchByParam,
} from '~/hooks/utils';
import {
  getNearDueProduct,
} from '~/redux/action';
import { useRef } from 'react';

const PRODUCT_REPORT_MODULE = 'productReport';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
} = getSelectors(PRODUCT_REPORT_MODULE);

export const useProductReport = query => {
  const jsonQuery = JSON.stringify(query)

  const queryRef = useRef(null);

  if(jsonQuery !== JSON.stringify(queryRef.current)) {
    queryRef.current = query
  }

  return useFetchByParam({
    action: getNearDueProduct,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: queryRef.current
  })
};
