import React from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useNewses,
  useDeleteNews,
  useNewsQueryParams,
  useNewsPaging
} from '~/hooks/worldCare/news';
import { Table, Popconfirm, Button, Switch, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import Breadcrumb from '~/components/Common/Breadcrumb';

const ColumnActions = ({ id, deleteNews }) => {
  return (
    <div className="custom-table__actions">
      <Link to={`/news/edit/${id}`}>
        <p>Sửa</p>
      </Link>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá tin tức này?"
        onConfirm={() => deleteNews(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const News = () => {
  const [query, onTableChange] = useNewsQueryParams();
  const [news, isLoading] = useNewses(query);
  const [, deleteNews] = useDeleteNews();

  const paging = useNewsPaging();

  const columns = [
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title'
    },

    {
      title: 'Danh mục',
      dataIndex: 'category',
      key: 'category',
      render: (category) => category?.[0]?.name
    },

    {
      title: 'Đường dẫn',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Tác giả',
      key: 'author',
      dataIndex: 'author'
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Switch checked={status === 'PUBLISHED'} />
    },

    {
      title: 'Trạng thái',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      render: (isFeatured) => <Checkbox checked={isFeatured} />
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return <ColumnActions {...record} deleteNews={deleteNews} />;
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách tin tức" />

        <div className="page-wraper__header">
          <Link to={PATH_APP.news.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div>

        {isLoading ? (
          <SkeletonTable
            rowCount={news.length}
            columns={columns}
            pagination={paging}
          />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={news}
            onChange={onTableChange}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </div>
    </div>
  );
};

export default News;
