export const STATUS_READ = {
    unread:'unread',
    read:'read',
}
export const STATUS_READ_VI = {
    unread:'Chưa đọc',
    read:'Đã đọc',
}

export const TYPE_NOTIFICATION = {
    WH_BILL : "WH_BILL",
    WH_DELIVERY_PRODUCT : "WH_DELIVERY_PRODUCT",
    TASK_ITEM_COMMENT:'TASK_ITEM_COMMENT',
}
export const TYPE_NOTIFICATION_ICON = {
    WH_BILL : <i className="uil-shopping-basket"></i>,
    WH_DELIVERY_PRODUCT : <i className="uil uil-truck"></i>,
    TASK_ITEM_COMMENT:<i class="uil uil-comment-chart-line"></i>,
}
