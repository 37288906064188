import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getCashFlows,
  createPaymentNoteType,
  searchPayerReceivers,
  createRecipientSubmitter,
  createCashFlow,
  deleteCashFlow,
  getCashFlow,
  resetCashFlowState,
  updateCashFlow, searchPayReceiveType, createPayRecNote
} from '~/redux/action';
import { Menu, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const CASH_FLOW_MODULE = 'cashFlow';

const CREATE_SUCCESS_MESS = 'Tạo mới phiếu thu chi thành công';
const CREATE_FAILED_MESS = 'Tạo mới phiếu thu chi thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật phiếu thu chi thành công';
const UPDATE_FAILED_MESS = 'Cập nhật phiếu thu chi thất bại';
const DELETE_SUCCESS_MESS = 'Xoá phiếu thu chi thành công';
const DELETE_FAILED_MESS = 'Xoá phiếu thu chi thất bại';

const CREATE_SUCCESS_TYPE_MESS = 'Thêm loại thu/chi thành công';
const CREATE_FAILED_TYPE_MESS = 'Thêm loại thu/chi thất bại';

const CREATE_SUCCESS_PAY_REC_MESS = 'Thêm phiếu thu/chi thành công';
const CREATE_FAILED_PAY_REC_MESS = 'Thêm phiếu thu/chi thất bại';

const CREATE_REC_SUB_SUCCESS_MESS = 'Thêm mới người nhận/nộp thành công';
const CREATE_REC_SUB_FAIL_MESS = 'Thêm mới người nhận/nộp thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector,
  summarySelector
} = getSelectors(CASH_FLOW_MODULE);

const getSelector = key => state => state[CASH_FLOW_MODULE][key];

export const useCashFlows = query => {
  return useFetchByParam({
    action: getCashFlows,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreatePaymentNoteType = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_TYPE_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_TYPE_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPaymentNoteType
  });
};

const createRecipientSubmitterSuccess = getSelector('createRecipientSubmitterSuccess');
const isCreateRecipientSubmitterLoading = getSelector('isCreateRecipientSubmitterLoading');
const createRecipientSubmitterFailed = getSelector('createRecipientSubmitterFailed');

export const useCreateRecipientSubmitter = callback => {
  useSuccess(createRecipientSubmitterSuccess, CREATE_REC_SUB_SUCCESS_MESS, callback);
  useFailed(createRecipientSubmitterFailed, CREATE_REC_SUB_FAIL_MESS);

  return useSubmit({
    loadingSelector: isCreateRecipientSubmitterLoading,
    action: createRecipientSubmitter
  });
};

const createPayRecNoSuccess = getSelector('createPayRecNoSuccess');
const isCreatePayRecNoLoading = getSelector('isCreatePayRecNoLoading');
const createPayRecNoFailed = getSelector('createPayRecNoFailed');

export const useCreatePaymentReceivedNote = callback => {
  useSuccess(createPayRecNoSuccess, CREATE_SUCCESS_PAY_REC_MESS, callback);
  useFailed(createPayRecNoFailed, CREATE_FAILED_PAY_REC_MESS);

  return useSubmit({
    loadingSelector: isCreatePayRecNoLoading,
    action: createPayRecNote
  });
};

const payerReceivers = getSelector('payerReceivers');
const isSearchLoadingPayerReceivers = getSelector('isSearchPayerReceiversLoading');
const searchPayerReceiversFailedSelector = getSelector('searchPayerReceiversFailedSelector');

const payReceiveType = getSelector('payReceivesType');
const isSearchLoadingPayReceive = getSelector('isSearchPayReceiveLoading');
const searchPayReceiveTypeFailedSelector = getSelector('searchPayReceiveFailedSelector');

export const useSearchPayerReceivers = query => {
  return useFetchByParam({
    action: searchPayerReceivers,
    loadingSelector: isSearchLoadingPayerReceivers,
    dataSelector: payerReceivers,
    failedSelector: searchPayerReceiversFailedSelector,
    param: query
  });
};

export const useSearchPayReceiveType = query => {
  return useFetchByParam({
    action: searchPayReceiveType,
    loadingSelector: isSearchLoadingPayReceive,
    dataSelector: payReceiveType,
    failedSelector: searchPayReceiveTypeFailedSelector,
    param: query
  });
};

let timeOut;

export const useSearchPayerReceiversBox = () => {
  const [ searchValue, setSearchValue ] = useState({
    target: '',
    value: ''
  });

  const [receiptPayments, isLoading] = useSearchPayerReceivers(searchValue);
  const inputEl = useRef(null);

  useEffect(() => {
    return () => {
      timeOut = null;
    };
  }, []);

  const onSearch = data => {
    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      if (timeOut) {
        setSearchValue(data);
      }
    }, 500);
  };

  return [
    {
      receiptPayments,
      isLoading,
      inputEl,
    },
    { onSearch }
  ];
};

export const useCreateCashFlow = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createCashFlow
  });
};

export const useUpdateCashFlow = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateCashFlow
  });
};

export const useDeleteCashFlow = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteCashFlow
  });
};

export const useCashFlow = params => {
  return useFetchByParam({
    action: getCashFlow,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitCashFlow = (cashFlow, id) => {
  return useMemo(() => {
    if (!cashFlow || !id) {
      return {};
    }

    return { ...cashFlow };
  }, [cashFlow, id]);
};
export const useGetSummaryCashFlow =()=>{
  const summary = useSelector(summarySelector)
return [summary]
}

export const useUpdateCashFlowParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/cash-flow',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useCashFlowQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page');
  const code = query.get('code');
  const createdAt = query.get('createdAt');
  const customer = query.get('customer');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      code,
      createdAt,
      customer,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, updateSuccess, deleteSuccess,code,customer, createdAt]);
};

export const useCashFlowPaging = () => useSelector(pagingSelector);

export const useResetCashFlow = () => {
  useResetState(resetCashFlowState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã phiếu',
    key: 'code'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Loại thu chi',
    key: 'type'
  },
  {
    title: 'Người nộp/nhận',
    key: 'customer'
  },

  {
    title: 'Giá trị',
    key: 'amount'
  }
];

export const useCashFlowColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    time: true,
    customer: true,
    type: true,
    amount: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};
export const SEARCH_CASHFLOW=[
  {
      name: "code",
      placeholder:"Theo mã phiếu"
  },
  {
      name: "customer",
      placeholder:"Theo người nộp hoặc nhập"
  },
  {
      name: "createdAt",
      type: "rangerTime",
      label:"Thời gian",
  },
]
