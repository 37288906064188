import { Table } from 'antd';

const OrderTotal = ({ order }) => {
  const columns = [
    {
      dataIndex: 'name',
      align: 'right',
      width: '85%',
      render: (value) => <b>{value}</b>
    },
    {
      dataIndex: 'value',
      align: 'right',
      width: '15%',
      render: (value) => `${value?.toLocaleString()} đ`
    }
  ];

  const totalData = [
    { name: 'Thành tiền', value: order?.subTotal },
    { name: 'Giảm giá', value: order?.discountAmount },
    { name: 'Phí vận chuyển', value: order?.shippingFee },
    { name: 'Thuế', value: order?.vatAmount },
    { name: 'Tổng cộng', value: order?.grandTotal }
  ];

  return (
    <Table
      rowKey={(rc) => rc.name}
      showHeader={false}
      className="fee-table"
      columns={columns}
      dataSource={totalData}
      pagination={{ position: ['none', 'none'] }}
    />
  );
};

export default OrderTotal;
