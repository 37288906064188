import PaymentHistory from '~/components/WorldClinic/ProductInvoice/PaymentHistory';
import { Modal } from 'antd';

const PaymentHistoryModal = ({ isOpen, record, onClose }) => {
  return (
    <Modal width={980} visible={isOpen} footer={null} onCancel={onClose}>
      <h4 style={{ paddingLeft: 30, marginBottom: 0 }}>Lịch sử thanh toán</h4>
      <PaymentHistory record={record} bordered />
    </Modal>
  );
};

export default PaymentHistoryModal;
