import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
  RESET_EMPLOYEE_STATE
} from '~/constants/actionTypes';

export const getEmployees = (query) => ({
  type: GET_EMPLOYEES_REQUEST,
  payload: query
});

export const getEmployee = (id) => ({
  type: GET_EMPLOYEE_REQUEST,
  payload: id
});

export const createEmployee = (employee) => ({
  type: CREATE_EMPLOYEE_REQUEST,
  payload: employee
});

export const updateEmployee = (employee) => ({
  type: UPDATE_EMPLOYEE_REQUEST,
  payload: employee
});

export const deleteEmployee = (id) => ({
  type: DELETE_EMPLOYEE_REQUEST,
  payload: id
});

export const resetEmployeeState = () => ({
  type: RESET_EMPLOYEE_STATE
});
