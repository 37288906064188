import React, { useState, useCallback } from 'react';
import { Input, Row, Button, Table, Tag, Spin } from 'antd';
import {
  useDamageColumns,
  useDamage,
  useResetMedicine,
  useResetBatch,
  useResetDamage,
  useInitDamage,
  useBatchActions
} from '~/hooks';
import './index.scss';
import { CARD_STYLE, DISCOUNT_TYPES } from '~/constants/defaultValue';
import { UnorderedListOutlined } from '@ant-design/icons';
import UpdateBatchForm from '~/components/Utils/UpdateBatchForm';
import SelectBatch from './SelectBatch';
import SearchProductsBox from '~/components/Utils/SearchProductsBox';
import SummaryForm from './SummaryForm';
import { formatDate, formatQuantity } from '~/utils/helper';

const findById = (data, id) => data.find(({ _id }) => _id === id);

const DamageForm = props => {
  useResetMedicine();
  useResetBatch();
  useResetDamage();

  const [formData, setFormData] = useState([]);

  const handleSelect = ({productId, variantId}) => {
    const dataForm = formData.map(data => {
      if (data.productId !== productId) {
        return data
      }

      const newSelectedVariant = findById(data.relationVariants, variantId)
      return {
        ...data,
        selectedVariant: newSelectedVariant,
      };
    });

    setFormData([...dataForm])
  }

  const columns = useDamageColumns(setFormData,handleSelect);

  const { id: updatingId } = props.match.params;
  const [damage, isGetDamageLoading] = useDamage(updatingId);

  useInitDamage({ damage, updatingId, setFormData });

  const onSelectVariant = variant => {
    const currentProduct = formData.filter(
      ({ productId }) => productId !== variant.productId
    );

    const currentVariants = variant.relationVariants.find(
      ({ _id }) => _id === variant._id)

    const initBatch = variant.batches?.[0] || currentVariants.batches?.[0];
    const selectedBatches = initBatch ? [{ ...initBatch, quantity: 1 }] : [];

    const addedVariant = {
      ...variant,
      discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
      quantity: initBatch ? 1 : 0,
      selectedBatches,
      selectedVariant: currentVariants,
      variantId: variant._id,
      buyPrice: variant.cost
    };

    setFormData([addedVariant, ...currentProduct]);
  };

  const [isUpdateBatchFormOpen, setUpdateBatchFormOpen] = useState(false);
  const [updatingBatch, setUpdatingBatch] = useState(null);
  const [isSelectBatchOpen, setSelectBatchOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const updateVariant = useCallback(updatedVariant => {
    setFormData(formData => {
      return formData.map(item => {
        return item._id === updatedVariant._id ? updatedVariant : item;
      });
    });
  }, []);

  const { onRemoveBatch, onUpdateBatch, onSelectBatches } = useBatchActions({
    updateVariant,
    setFormData,
    formData,
    selectedVariant
  });

  const isLoading = isGetDamageLoading;

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="warehouse-form">
          <div className="warehouse-form__left" style={CARD_STYLE}>
            <div className="wc-page-header">
              <h1
                className="wc-page-header__title"
                style={{ width: 180, minWidth: 180 }}
              >
                Xuất hủy
              </h1>

              <div className="wc-page-header__search">
                <SearchProductsBox onSelect={onSelectVariant}>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Tìm theo mã hàng hoá hoặc tên"
                  />
                </SearchProductsBox>
              </div>
            </div>

            <div className="warehouse-form-table">
              <Spin spinning={isLoading}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={formData}
                  rowKey={rc => rc.variantCode}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (variant, index) => (
                      <Row
                        key={index}
                        className="warehouse-form-table__parcel-box"
                      >
                        <div className="warehouse-form-table__parcel">Lô:</div>
                        {variant.selectedBatches.map((batch, i) => {
                          const availableQuantity = variant.selectedVariant?.batches?.[0].quantity || variant.selectedVariant?.quantity || 0

                          return (
                            <Tag
                              style={{ cursor: 'pointer' }}
                              closable
                              color="#2db7f5"
                              key={i}
                              onClick={() => {
                                setUpdateBatchFormOpen(true);
                                setUpdatingBatch({
                                  ...batch,
                                  variantId: variant.variantId
                                });
                              }}
                              onClose={() =>
                                onRemoveBatch({
                                  ...batch,
                                  variantId: variant.variantId
                                })
                              }
                            >
                              {`${batch.lotNumber} - ${formatDate(
                                batch.expirationDate
                              )} - SL: ${batch.quantity} ${availableQuantity >= batch.quantity ? `- TK: ${formatQuantity(availableQuantity - batch.quantity)}` : ''} `}
                            </Tag>
                          )
                        })}

                        <Button
                          size="small"
                          onClick={() => {
                            setSelectBatchOpen(true);
                            setSelectedVariant(variant);
                          }}
                        >
                          <UnorderedListOutlined />
                        </Button>
                      </Row>
                    ),
                    defaultExpandAllRows: true,
                    expandedRowKeys: formData.map(
                      ({ variantCode }) => variantCode
                    ),
                    expandIcon: () => <></>
                  }}
                />
              </Spin>
            </div>
          </div>

          <SummaryForm
            formData={formData}
            initDamage={damage}
            isGetDamageLoading={isGetDamageLoading}
          />
        </div>
      </div>

      {isUpdateBatchFormOpen && (
        <UpdateBatchForm
          isOpen={isUpdateBatchFormOpen}
          onClose={() => setUpdateBatchFormOpen(false)}
          batch={updatingBatch}
          onSubmit={onUpdateBatch}
        />
      )}

      {isSelectBatchOpen && (
        <SelectBatch
          isOpen={isSelectBatchOpen}
          onClose={() => setSelectBatchOpen(false)}
          variant={selectedVariant}
          onSubmit={onSelectBatches}
          isSingle
        />
      )}
    </div>
  );
};

export default DamageForm;
