import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Input, Row, Button, Modal, Tag, InputNumber, Col, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import toastr from 'toastr';
import { useBatches } from '~/hooks';
import { formatQuantity } from '~/utils/helper';
import { get } from 'lodash';

const findById = (data, id) =>
  (data || []).find(item => item.batchId === id || item._id === id);

const formatDate = time => moment(time).format('DD/MM/YYYY');
const isMatchFilter = (a, b) => a.toLowerCase().includes(b.toLowerCase());

const SelectBatch = ({ isOpen, onClose, variant, onSubmit, isSingle }) => {
  const selectedVariantProduct = variant?.relationVariants?.find(product => product?.unitId === variant?.unitId)
  const [totalBatches] = useBatches(variant.productId);

  const batchesAll = selectedVariantProduct?.batches || totalBatches

  const initBatches = useCallback(
    batches => {
      const data = batches.map(batch => {
        let _batch = {...batch}
        const selectedBatch = findById(variant?.selectedBatches, _batch._id || _batch.id);
        _batch.axQuantity =  parseFloat((get(_batch,'quantity',0) / get(variant,'exchangeValue',0)).toFixed(3));
        if (!selectedBatch) {
          return { ..._batch, isSelected: false, quantity: 0 };
        }
        return { ..._batch, isSelected: selectedBatch.quantity===0 ?false:true, quantity: selectedBatch.quantity };

      });

      return data;
    },
    [variant?.selectedBatches]
  );

  const [batches, setBatches] = useState(() =>
    initBatches(variant.batches || [])
  );

  useEffect(() => {
    if (batchesAll.length) {
      setBatches(initBatches(batchesAll));
    }
  }, [batchesAll, initBatches]);

  const [keyword, setKeyword] = useState(null);
  const onUpdateBatches = (prop, id, value) => {
    const nextBatches = batches.map(batch =>{
      if( batch._id===id){
        switch (prop) {
          case 'isSelected': 
          batch.isSelected = !batch.isSelected;
            break
          case 'quantity': 
            batch.quantity = value
            batch.isSelected = value===0 ?false: true;
            break
          default:
             batch[prop] = value;
            break;
        }
      }
        return batch;
    });
    setBatches(nextBatches);
  };

  const displayedBatches = useMemo(() => {
    if (!keyword) return batches;

    return batches.filter(
      ({ lotNumber, expirationDate }) =>
        isMatchFilter(lotNumber, keyword) ||
        isMatchFilter(formatDate(expirationDate), keyword)
    );
  }, [batches, keyword]);


  const onFinish = () => {
    const checkIsSelectbactch = batches.every(({isSelected,quantity})=>!isSelected||quantity===0)
    if(checkIsSelectbactch){
      toastr.remove()
      toastr.error('Vui lòng chọn ít nhất một lô hoặc số lượng lớn hơn 0')
      return
    }
    onSubmit(batches);
    onClose();
  };
  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Row style={{ marginBottom: 20 }}>
          <h4 style={{ marginRight: 'auto' }}>Chọn lô, hạn sử dụng</h4>
        </Row>

        <Row>
          <Input
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            placeholder="Tìm lô, hạn sử dụng"
            prefix={<SearchOutlined style={{ color: '#ced4d9' }} />}
          />
        </Row>

        <div style={{ marginTop: 30 }}>
          {displayedBatches.map((batch, index) => (
            <Row className="sp-parcel-item" key={index}>
                <Tag
                  onClick={() =>
                    onUpdateBatches(
                      'isSelected',
                      batch._id || batch.id,
                      isSingle ? true : !batch.isSelected
                    )
                  }
                  color={batch.isSelected && '#2db7f5'} 
                >
                {batch.lotNumber} - {formatDate(batch.expirationDate)} -TK: {
                formatQuantity((batch.axQuantity + batch.quantity),1)}
               </Tag>
                <InputNumber
                  min={0}
                  onChange={val => onUpdateBatches('quantity', batch._id || batch.id, val)}
                  value={batch.quantity}
                  bordered={false}
                  style={{height: 38}}
                  className="warehouse-form__custom-input"
                />             
            </Row>
          ))}
        </div>

        <Row className="wc-setting-form__submit-box" style={{ marginTop: 40 }}>
          {false ? (
            <Button disabled>Huỷ</Button>
          ) : (
            <Button onClick={onClose}>Huỷ</Button>
          )}

          <Button type="primary" htmlType="submit" onClick={onFinish}>
            Chọn
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default SelectBatch;