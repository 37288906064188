import requester from '../requester';

const shippingVendor = {
  getAll: (query) => requester.get('/shipping-vendor', query),
  getById: (id) => requester.get(`/shipping-vendor/${id}`),
  create: (shippingVendor) =>
    requester.post('/shipping-vendor', shippingVendor),
  update: (shippingVendor) =>
    requester.put(`/shipping-vendor/${shippingVendor._id}`, shippingVendor),
  delete: (id) => requester.delete(`/shipping-vendor/${id}`)
};

export default shippingVendor;
