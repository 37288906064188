export const GET_PRODUCT_INVOICES_REQUEST = 'GET_PRODUCT_INVOICES_REQUEST';
export const GET_PRODUCT_INVOICES_SUCCESS = 'GET_PRODUCT_INVOICES_SUCCESS';
export const GET_PRODUCT_INVOICES_FAILED = 'GET_PRODUCT_INVOICES_FAILED';

export const GET_PRODUCT_INVOICE_REQUEST = 'GET_PRODUCT_INVOICE_REQUEST';
export const GET_PRODUCT_INVOICE_SUCCESS = 'GET_PRODUCT_INVOICE_SUCCESS';
export const GET_PRODUCT_INVOICE_FAILED = 'GET_PRODUCT_INVOICE_FAILED';

export const CREATE_PRODUCT_INVOICE_REQUEST = 'CREATE_PRODUCT_INVOICE_REQUEST';
export const CREATE_PRODUCT_INVOICE_SUCCESS = 'CREATE_PRODUCT_INVOICE_SUCCESS';
export const CREATE_PRODUCT_INVOICE_FAILED = 'CREATE_PRODUCT_INVOICE_FAILED';

export const UPDATE_PRODUCT_INVOICE_REQUEST = 'UPDATE_PRODUCT_INVOICE_REQUEST';
export const UPDATE_PRODUCT_INVOICE_SUCCESS = 'UPDATE_PRODUCT_INVOICE_SUCCESS';
export const UPDATE_PRODUCT_INVOICE_FAILED = 'UPDATE_PRODUCT_INVOICE_FAILED';

export const DELETE_PRODUCT_INVOICE_REQUEST = 'DELETE_PRODUCT_INVOICE_REQUEST';
export const DELETE_PRODUCT_INVOICE_SUCCESS = 'DELETE_PRODUCT_INVOICE_SUCCESS';
export const DELETE_PRODUCT_INVOICE_FAILED = 'DELETE_PRODUCT_INVOICE_FAILED';

export const RESET_PRODUCT_INVOICE_STATE = 'RESET_PRODUCT_INVOICE_STATE';
