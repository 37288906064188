import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getBranches,
  createBranch,
  deleteBranch,
  getBranch,
  resetBranchState,
  updateBranch,
  updateApiKey,
  getApiKey,
  deleteApiKey
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { initWorkingHours } from '~/hooks/worldCare/hospital';
import moment from 'moment';
import { get } from 'lodash';
import { useProfile } from '../user';

const BRANCH_MODULE = 'branch';


const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
} = getSelectors(BRANCH_MODULE);

const getSelector = (key) => (state) => state[BRANCH_MODULE][key];

const getApiKeyLoadingSelector =  getSelector("getApiKeyLoading")
const getApiKeyFailedSelector =  getSelector("getApiKeyFailed")
const apiKeySelector = getSelector("apiKey")

const isSubmitApiKeyLoadingSelector =getSelector("isSubmitApiKeyLoading")
const updateApiKeySuccessSelector =getSelector("updateApiKeySuccess")
const updateApiKeyFailedSelector = getSelector("updateApiKeyFailed")

const deleteApiKeySuccessSelector =getSelector("deleteApiKeySuccess")
const deleteApiKeyFailedSelector = getSelector("deleteApiKeyFailed")

const pagingSelector = getSelector('paging');

export const useBranches = (query) => {
  return useFetchByParam({
    action: getBranches,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateBranch = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới chi nhánh thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createBranch
  });
};

export const useUpdateBranch = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật chi nhánh thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateBranch
  });
};

export const useDeleteBranch = (onDeleteSuccess) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá chi nhánh thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector, 'Xoá chi nhánh thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteBranch
  });
};

export const useBranch = (params) => {
  return useFetchByParam({
    action: getBranch,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitBranch = (branch, id, isOpen) => {
  return useMemo(() => {
    if (!branch || !id || !isOpen) {
      return {
        workingHours: initWorkingHours,
        services: [
          {
            name: '',
            description: '',
            items: []
          }
        ]
      };
    }

    const initBranch = {
      ...branch,
      workingHours: branch.workingHours.map((hour) => ({
        ...hour,
        startTime: moment(hour.startTime, 'HH:mm'),
        endTime: moment(hour.endTime, 'HH:mm')
      }))
    };

    return initBranch;
  }, [branch, id, isOpen]);
};

export const useUpdateBranchParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

    history.push({
      pathname: '/branch',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useBranchQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const modules = query.get('modules');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      keyword,
      cityId,
      modules
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [keyword, cityId, modules, createSuccess, updateSuccess, deleteSuccess]);
};

export const useBranchPaging = () => useSelector(pagingSelector);

export const useResetBranch = () => {
  useResetState(resetBranchState);
};

export const useBranchTree = (branches) => {
  return useMemo(() => {
    if (!branches) {
      return [];
    }

    const list = JSON.parse(JSON.stringify(branches));
/**
 * old version :
      const keyIndexDict = list.reduce(
        (dict, { _id }, index) => ({ ...dict, [_id]: index }),
        {}
      );
      
      const tree = [];
      
      list.forEach((item) => {
        if (item.parentId) {
          const parentIndex = keyIndexDict[item.parentId];
      
          if (parentIndex || parentIndex === 0) {
            list[parentIndex].children = [
              ...(list[parentIndex].children || []),
              item
            ];
          }
        } else {
          tree.push(item);
        }
      });
 */
    const checkChildren=(item) => item.length ? { children: item } : null
    
    let tree = list.map((item)=>({
      ...item,
      ...checkChildren(get(item,'childBranches',[]))
    }))

    return tree;
  }, [branches]);
};

export const useApiKey = (branchId) => {
    return useFetchByParam({
      action: getApiKey,
      loadingSelector: getApiKeyLoadingSelector,
      dataSelector: apiKeySelector,
      failedSelector: getApiKeyFailedSelector,
      param: branchId
    });
};
export const useUpdateApiKey = (callback) => {
  useSuccess(updateApiKeySuccessSelector, 'Cập nhật mã liên kết thành công', callback);
  useFailed(updateApiKeyFailedSelector, 'Cập nhật mã liên kết thất bại');

  return useSubmit({
    loadingSelector: isSubmitApiKeyLoadingSelector,
    action: updateApiKey
  });
};


export const useDeleteApiKey = (onDeleteSuccess) => {
  useSuccess(
    deleteApiKeySuccessSelector,
    'Xoá mã liên kết thành công',
    onDeleteSuccess
  );
  useFailed(deleteApiKeyFailedSelector, 'Xoá mã liên kết thất bại');

  return useSubmit({
    loadingSelector: isSubmitApiKeyLoadingSelector,
    action: deleteApiKey
  });
};