import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME
} from '~/constants/defaultValue';

function* getWarehouses({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const warehouse = yield call(Api.warehouse.getById, query.id);
      data = { docs: [warehouse], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.warehouse.getAll, query);
    }

    yield put({ type: Types.GET_WAREHOUSES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WAREHOUSES_FAILED,
      payload: error
    });
  }
}

function* getWarehouse({ payload }) {
  try {
    const warehouse = yield call(Api.warehouse.getById, payload);
    yield put({
      type: Types.GET_WAREHOUSE_SUCCESS,
      payload: warehouse
    });
  } catch (error) {
    yield put({
      type: Types.GET_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

function* createWarehouse(action) {
  try {
    const data = yield call(Api.warehouse.create, action.payload);
    yield put({ type: Types.CREATE_WAREHOUSE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WAREHOUSE_FAILED,
      payload: error
    });
  }
}
function* createWarehouseSuccessAndCreateActivity(action) {
  try {
    const { createdBy, branchId, totalPayment } = action.payload;
    const message = `${createdBy.fullName} ${MESSAGE_ACTIVITY.WAREHOUSE} ${totalPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy, message,action:ACTIVITY_NAME.WAREHOUSE },
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
  } catch (error) {
    console.log(error);
    // yield put({
    //   type: Types.CREATE_WAREHOUSE_FAILED,
    //   payload: error
    // });
  }
}

function* updateWarehouse(action) {
  try {
    const data = yield call(Api.warehouse.update, action.payload);
    yield put({ type: Types.UPDATE_WAREHOUSE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

function* deleteWarehouse({ payload }) {
  try {
    yield call(Api.warehouse.delete, payload);
    yield put({ type: Types.DELETE_WAREHOUSE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

export default function* warehouse() {
  yield takeLatest(Types.GET_WAREHOUSES_REQUEST, getWarehouses);
  yield takeLatest(Types.GET_WAREHOUSE_REQUEST, getWarehouse);
  yield takeLatest(Types.DELETE_WAREHOUSE_REQUEST, deleteWarehouse);
  yield takeLatest(Types.CREATE_WAREHOUSE_REQUEST, createWarehouse);
  yield takeLatest(Types.CREATE_WAREHOUSE_SUCCESS, createWarehouseSuccessAndCreateActivity);
  yield takeLatest(Types.UPDATE_WAREHOUSE_REQUEST, updateWarehouse);
}
