import React from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import InformationItem from '~/components/Common/InformationItem';
import DetailTable from './DetailTable';
import { Link } from 'react-router-dom';
import { formatDateTime } from '~/utils/helper';
import { API_LIST, INVOICE_STATUS, PAYMENT_STATE } from '~/constants/defaultValue';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { get } from 'lodash';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';

const LEFT_INFO = [
  {
    label: 'Mã xuất hủy:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Thời gian:',
    dataIndex: 'processedAt',
    render: processedAt => formatDateTime(processedAt)
  },
  {
    label: 'Người tạo:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  }
];

const RIGHT_INFO = [
  {
    label: 'Trạng thái:',
    dataIndex: 'status',
    render: status => {
      return INVOICE_STATUS[status];
    }
  },
  {
    label: 'Chi nhánh:',
    dataIndex: 'branch',
    render: branch => branch?.name?.vi
  },
  {
    label: 'Người nhập:',
    dataIndex: 'createdBy',
    render: createdBy => createdBy?.fullName
  },
  {
    label: 'Mã liên thông:',
    dataIndex: 'drugAdministrationResponses',
    // nguyenpham: to be changed to another data field later
    render: drugAdministrationResponses => get(drugAdministrationResponses, '[0].ma_phieu_xuat_quoc_gia')
  },
];

const Information = ({ record, deleteDamage }) => {

  return (
    <div className="warehouse-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={8}>
          <div className="warehouse-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={record[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <InformationItem key={'Ghi chú'} label="Ghi chú" value={record.note} />
          </div>
        </Col>
      </Row>

      <DetailTable damage={record} />

      <Row className="medicine-info__actions" justify="end">
        <WithPermission permission={POLICIES.UPDATE_DAMAGEITEM}>
          {record.status === PAYMENT_STATE.DRAFT && (
            <Link to={`/damage/edit/${record._id}`}>
              {' '}
              <Button type="primary" style={{ marginRight: 10 }}>
                <EditOutlined style={{ marginRight: 0 }} /> Cập nhật
              </Button>
            </Link>
          )}
        </WithPermission>
        <WithPermission permission={POLICIES.WRITE_DAMAGEITEM}>
          <ButtonExport router={API_LIST.damage} label="Xuất hủy" id={record._id} fileName={`Chitietxuathuy_${record.code}`}/>
        </WithPermission>
        <WithPermission permission={POLICIES.DELETE_DAMAGEITEM}>
          {record.status !== PAYMENT_STATE.CANCELED && (
            <Popconfirm
              title="Bạn muốn huỷ phiếu huỷ này?"
              onConfirm={() => deleteDamage(record._id)}
              okText="Xoá"
              cancelText="Huỷ"
            >
              <Button type="danger">
                {' '}
                <DeleteOutlined style={{ marginLeft: 0 }} />
                Huỷ
              </Button>
            </Popconfirm>
          )}
        </WithPermission>
      </Row>
    </div>
  );
};

export default Information;
