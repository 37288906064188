import {
  CREATE_HOSPITAL_REQUEST,
  GET_HOSPITALS_REQUEST,
  DELETE_HOSPITAL_REQUEST,
  GET_HOSPITAL_REQUEST,
  RESET_HOSPITAL_STATE,
  UPDATE_HOSPITAL_REQUEST,
  GET_HOSPITAL_AVAILABLE_TIME_REQUEST,
  GET_ALL_HOSPITALS_REQUEST
} from '~/constants/actionTypes';

export const getHospitals = query => ({
  type: GET_HOSPITALS_REQUEST,
  payload: query
});

export const getHospital = id => ({
  type: GET_HOSPITAL_REQUEST,
  payload: id
});

export const createHospital = hospital => ({
  type: CREATE_HOSPITAL_REQUEST,
  payload: hospital
});

export const updateHospital = hospital => ({
  type: UPDATE_HOSPITAL_REQUEST,
  payload: hospital
});

export const deleteHospital = id => ({
  type: DELETE_HOSPITAL_REQUEST,
  payload: id
});

export const resetHopitalState = () => ({
  type: RESET_HOSPITAL_STATE
});

export const getHospitalAvailableTime = hospitalId => ({
  type: GET_HOSPITAL_AVAILABLE_TIME_REQUEST,
  payload: hospitalId
});

export const getAllHospitals = () => ({
  type: GET_ALL_HOSPITALS_REQUEST
});
