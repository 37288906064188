import {
  GET_CITIES_REQUEST,
  GET_DISTRICTS_REQUEST,
  GET_WARDS_REQUEST
} from '~/constants/actionTypes';

export const getCities = () => ({
  type: GET_CITIES_REQUEST
});

export const getDistricts = companyCode => ({
  type: GET_DISTRICTS_REQUEST,
  payload: companyCode
});

export const getWards = dictricCode => ({
  type: GET_WARDS_REQUEST,
  payload: dictricCode
});
