import {
  GET_STAFFS_REQUEST,
  GET_STAFF_REQUEST,
  CREATE_STAFF_REQUEST,
  UPDATE_STAFF_REQUEST,
  DELETE_STAFF_REQUEST,
  RESET_STAFF_STATE,
  GET_STAFF_GROUPS_REQUEST
} from '~/constants/actionTypes';

export const getStaffs = query => ({
  type: GET_STAFFS_REQUEST,
  payload: query
});

export const getStaff = id => ({
  type: GET_STAFF_REQUEST,
  payload: id
});

export const createStaff = hospital => ({
  type: CREATE_STAFF_REQUEST,
  payload: hospital
});

export const updateStaff = hospital => ({
  type: UPDATE_STAFF_REQUEST,
  payload: hospital
});

export const deleteStaff = id => ({
  type: DELETE_STAFF_REQUEST,
  payload: id
});

export const resetStaffState = () => ({
  type: RESET_STAFF_STATE
});

export const getStaffGroups = () => ({
  type: GET_STAFF_GROUPS_REQUEST
});
