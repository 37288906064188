import React, { useMemo, useEffect, useState } from 'react';
import {
  useEmployeeGroups,
  useEmployeeGroup,
  useResources,
  useUpdateGroupPermission,
  useResetEmployeeGroup,
  useDeleteEmployeeGroup,
  useResourceColumns
} from '~/hooks';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Menu, Skeleton, Table, Checkbox, Button, Popconfirm } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import GroupForm from '../GroupForm';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import toastrNotify from '~/hooks/utils';

const Permission = ({ isActive, onChange }) => {
  return (
    <Checkbox
      checked={isActive}
      onChange={onChange}
      // disabled={disabled}
    ></Checkbox>
  );
};

const getNextPath = (url) => {
  const paths = url.split('/');
  const nextPath = paths
    .filter((x, index) => !!x && index !== paths.length - 1)
    .join('/');

  return `/${nextPath}`;
};

const EmployeeGroups = () => {
  const { id, branchId, groupId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const branchIdParam = useMemo(() => ({ branchId: branchId ?? id }), [branchId, id]);

  const [groups, isLoading] = useEmployeeGroups(branchIdParam);
  const [resources, isResourcesLoading] = useResources();

  useResetEmployeeGroup();

  useEffect(() => {
    if (!groupId && groups.length) {
      history.push(`${url}/${groups[0]._id}`);
    }
  }, [groups, history, url, groupId]);

  const params = useMemo(() => {
    return groupId ? { groupId, branchId: branchId ?? id } : null;
  }, [groupId, branchId, id]);

  const [group] = useEmployeeGroup(params);

  const onSelectGroup = ({ key }) => {
    const nextPath = `${getNextPath(url)}/${key}`;
    history.push(nextPath);
  };

  const deleteCallback = () => {
    history.push(getNextPath(url));
  };

  const [isUpdateLoading, handleUpdate] = useUpdateGroupPermission();
  const [, deleteGroup] = useDeleteEmployeeGroup(deleteCallback);

  const [initGroup, setInitGroup] = useState(group);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const onOpenForm = (group) => {
    setInitGroup(group);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setInitGroup({});
    setIsOpenForm(false);
  };

  const onPermisionChange = (isAssgined, resource, action) => {
    if (!groupId) {
      toastrNotify.warning(("Chưa có nhóm người dùng!"))
    }
    handleUpdate({ isAssgined, resource, action, groupId });
  };
  const renderPermission = (action, rc) => {
    return (
      <Permission
        isActive={group?.policies?.[rc.resource.key]?.[action.key]}
        onChange={(event) =>
          onPermisionChange(event.target.checked, rc.resource.key, action.key)
        }
        disabled={isUpdateLoading}
      />
    );
  };

  const columns = useResourceColumns(renderPermission);

  return (
    <Card>
      <CardBody>
        <div className="employee-group">
          <div className="employee-group__list">
            <h5 className="employee-group__list-title">Nhóm người dùng</h5>
            <Menu
              style={{ width: 256 }}
              defaultSelectedKeys={['1']}
              selectedKeys={[groupId]}
              mode="inline"
              theme="light"
              onSelect={onSelectGroup}
            >
              {isLoading
                ? [1, 2, 3, 4].map((index) => (
                    <Skeleton.Input
                      active
                      key={index}
                      style={{ marginBottom: 10 }}
                    />
                  ))
                : groups.map(({ name, _id }) => (
                    <Menu.Item key={_id}>{name} </Menu.Item>
                  ))}
            </Menu>
          </div>

          <div className="employee-group__permission">
            <div className="employee-group__header">
              <h5 className="employee-group__list-title">
                Thiết lập quyền {group && `nhóm ${group.name}`}
              </h5>
              <WithPermission permission={POLICY.DELETE_USERGROUP}>
                <Popconfirm
                  title="Bạn muốn xoá chi nhánh này?"
                  onConfirm={() => deleteGroup(group._id)}
                  okText="Xoá"
                  cancelText="Huỷ"
                >
                  <Button size="small" type="danger">
                    <DeleteOutlined /> Xoá
                  </Button>
                </Popconfirm>{' '}
              </WithPermission>

              <WithPermission permission={POLICY.UPDATE_USERGROUP}>
                <Button
                  size="small"
                  onClick={() => onOpenForm(group)}
                  type="primary"
                >
                  <EditOutlined /> Cập nhật
                </Button>
              </WithPermission>

              <WithPermission permission={POLICY.WRITE_USERGROUP}>
                <Button
                  size="small"
                  onClick={() => onOpenForm({})}
                  type="primary"
                >
                  <PlusOutlined /> Tạo mới
                </Button>
              </WithPermission>
            </div>

            {isResourcesLoading ? (
              <SkeletonTable columns={columns} />
            ) : (
                <Table
                className= "permission-table"
                columns={columns}
                dataSource={resources}
                size="small"
                rowKey={(rc) => rc.resource.key}
                pagination={{ hideOnSinglePage: true }}
              ></Table>
            )}
          </div>
        </div>
        <GroupForm
          isOpen={isOpenForm}
          onClose={onCloseForm}
          initGroup={initGroup}
        />
      </CardBody>
    </Card>
  );
};

export default EmployeeGroups;
