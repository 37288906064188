export const GET_FORM_PRINTS_REQUEST = 'GET_FORM_PRINTS_REQUEST';
export const GET_FORM_PRINTS_SUCCESS = 'GET_FORM_PRINTS_SUCCESS';
export const GET_FORM_PRINTS_FAILED = 'GET_FORM_PRINTS_FAILED';

export const GET_FORM_PRINT_REQUEST = 'GET_FORM_PRINT_REQUEST';
export const GET_FORM_PRINT_SUCCESS = 'GET_FORM_PRINT_SUCCESS';
export const GET_FORM_PRINT_FAILED = 'GET_FORM_PRINT_FAILED';

export const CREATE_FORM_PRINT_REQUEST = 'CREATE_FORM_PRINT_REQUEST';
export const CREATE_FORM_PRINT_SUCCESS = 'CREATE_FORM_PRINT_SUCCESS';
export const CREATE_FORM_PRINT_FAILED = 'CREATE_FORM_PRINT_FAILED';

export const UPDATE_FORM_PRINT_REQUEST = 'UPDATE_FORM_PRINT_REQUEST';
export const UPDATE_FORM_PRINT_SUCCESS = 'UPDATE_FORM_PRINT_SUCCESS';
export const UPDATE_FORM_PRINT_FAILED = 'UPDATE_FORM_PRINT_FAILED';

export const DELETE_FORM_PRINT_REQUEST = 'DELETE_FORM_PRINT_REQUEST';
export const DELETE_FORM_PRINT_SUCCESS = 'DELETE_FORM_PRINT_SUCCESS';
export const DELETE_FORM_PRINT_FAILED = 'DELETE_FORM_PRINT_FAILED';

export const RESET_FORM_PRINT_STATE = 'RESET_FORM_PRINT_STATE';
