import {
  GET_NEWS_CATEGORIES_REQUEST,
  GET_NEWS_CATEGORY_REQUEST,
  CREATE_NEWS_CATEGORY_REQUEST,
  UPDATE_NEWS_CATEGORY_REQUEST,
  DELETE_NEWS_CATEGORY_REQUEST,
  RESET_NEWS_CATEGORY_STATE
} from '~/constants/actionTypes';

export const getNewsCategories = query => ({
  type: GET_NEWS_CATEGORIES_REQUEST,
  payload: query
});

export const getNewsCategory = id => ({
  type: GET_NEWS_CATEGORY_REQUEST,
  payload: id
});

export const createNewsCategory = hospital => ({
  type: CREATE_NEWS_CATEGORY_REQUEST,
  payload: hospital
});

export const updateNewsCategory = hospital => ({
  type: UPDATE_NEWS_CATEGORY_REQUEST,
  payload: hospital
});

export const deleteNewsCategory = id => ({
  type: DELETE_NEWS_CATEGORY_REQUEST,
  payload: id
});

export const resetNewsCategoryState = () => ({
  type: RESET_NEWS_CATEGORY_STATE
});
