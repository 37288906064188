import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getOverViewReport() {
  try {
    const data = yield call(Api.dashboard.getOverViewReport);
    yield put({ type: Types.GET_OVER_VIEW_REPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_OVER_VIEW_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getMonthlyReport() {
  try {
    const data = yield call(Api.dashboard.getMonthlyReport);
    yield put({
      type: Types.GET_MONTHLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MONTHLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getDailyReport() {
  try {
    const data = yield call(Api.dashboard.getDailyReport);
    yield put({
      type: Types.GET_DAILY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_DAILY_REPORT_FAILED,
      payload: error.message
    });
  }
}

export default function* coupon() {
  yield takeLatest(Types.GET_OVER_VIEW_REPORT_REQUEST, getOverViewReport);
  yield takeLatest(Types.GET_MONTHLY_REPORT_REQUEST, getMonthlyReport);
  yield takeLatest(Types.GET_DAILY_REPORT_REQUEST, getDailyReport);
}
