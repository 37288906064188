import * as Types from '~/constants/actionTypes';

export const getMedicines = query => ({
  type: Types.GET_MEDICINES_REQUEST,
  payload: query
});

export const getMedicine = id => ({
  type: Types.GET_MEDICINE_REQUEST,
  payload: id
});

export const createMedicine = medicine => ({
  type: Types.CREATE_MEDICINE_REQUEST,
  payload: medicine
});

export const updateMedicine = medicine => ({
  type: Types.UPDATE_MEDICINE_REQUEST,
  payload: medicine
});

export const deleteMedicine = id => ({
  type: Types.DELETE_MEDICINE_REQUEST,
  payload: id
});

export const resetMedicineState = () => ({
  type: Types.RESET_MEDICINE_STATE
});

export const getMedicineOptions = searchtext => ({
  type: Types.GET_MEDICINE_OPTIONS_REQUEST,
  payload: searchtext
});

export const createManufacture = manufacture => ({
  type: Types.CREATE_MANUFACTURE_REQUEST,
  payload: manufacture
});

export const setSelectedProductVariant = payload => ({
  type: Types.SET_SELECTED_PRODUCT_VARIANT,
  payload
});

export const getCountries = () => ({
  type: Types.GET_COUNTRIES_REQUEST
});

export const searchProducts = searchtext => ({
  type: Types.SEARCH_PRODUCTS_REQUEST,
  payload: searchtext
});

export const searchProduct = code => ({
  type: Types.SEARCH_PRODUCT_REQUEST,
  payload: code
});

export const searchVariantsByProduct = productId => ({
  type: Types.SEARCH_VARIANTS_BY_PRODUCT_REQUEST,
  payload: productId
});

export const getProductStock = variantId => ({
  type: Types.GET_PRODUCT_STOCK_REQUEST,
  payload: variantId
});

export const getProductStockCard = productId => ({
  type: Types.GET_PRODUCT_STOCK_CARD_REQUEST,
  payload: productId
});
export const getProductDrugRequest= productId => ({
  type: Types.GET_PRODUCT_DRUG_REQUEST_REQUEST,
  payload: productId
});

export const getReferenceDocsProductStockCard = params => ({
  type: Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_REQUEST,
  payload: params
});

export const getProductLot = params => ({
  type: Types.GET_PRODUCT_LOT_REQUEST,
  payload: params
});

export const getProductStockCardOutside = param => ({
  type: Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_REQUEST,
  payload: param
});

export const submitDrugRequest = param => ({
  type: Types.SUBMIT_DRUG_REQUEST_REQUEST,
  payload: param
});

export const submitTryDrugRequest = param => ({
  type: Types.SUBMIT_TRY_DRUG_REQUEST_REQUEST,
  payload: param
});
