import * as Types from '~/constants/actionTypes';

export const getMedicineCategories = query => ({
  type: Types.GET_MEDICINE_CATEGORIES_REQUEST,
  payload: query
});

export const getMedicineCategory = id => ({
  type: Types.GET_MEDICINE_CATEGORY_REQUEST,
  payload: id
});

export const createMedicineCategory = medicineCategory => ({
  type: Types.CREATE_MEDICINE_CATEGORY_REQUEST,
  payload: medicineCategory
});

export const updateMedicineCategory = medicineCategory => ({
  type: Types.UPDATE_MEDICINE_CATEGORY_REQUEST,
  payload: medicineCategory
});

export const deleteMedicineCategory = id => ({
  type: Types.DELETE_MEDICINE_CATEGORY_REQUEST,
  payload: id
});

export const resetMedicineCategoryState = () => ({
  type: Types.RESET_MEDICINE_CATEGORY_STATE
});
