export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILED = 'GET_CUSTOMERS_FAILED';

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';

export const GET_CUSTOMER_OPTIONS_REQUEST = 'GET_CUSTOMER_OPTIONS_REQUEST';
export const GET_CUSTOMER_OPTIONS_SUCCESS = 'GET_CUSTOMER_OPTIONS_SUCCESS';
export const GET_CUSTOMER_OPTIONS_FAILED = 'GET_CUSTOMER_OPTIONS_FAILED';

export const GET_CUSTOMER_SEARCH_REQUEST = 'GET_CUSTOMER_SEARCH_REQUEST';
export const GET_CUSTOMER_SEARCH_SUCCESS = 'GET_CUSTOMER_SEARCH_SUCCESS';
export const GET_CUSTOMER_SEARCH_FAILED = 'GET_CUSTOMER_SEARCH_FAILED';

export const GET_CUSTOMER_DEBT_REQUEST = 'GET_CUSTOMER_DEBT_REQUEST';
export const GET_CUSTOMER_DEBT_SUCCESS = 'GET_CUSTOMER_DEBT_SUCCESS';
export const GET_CUSTOMER_DEBT_FAILED = 'GET_CUSTOMER_DEBT_FAILED';

export const GET_CUSTOMER_ORDER_HISTORY_REQUEST =
  'GET_CUSTOMER_ORDER_HISTORY_REQUEST';
export const GET_CUSTOMER_ORDER_HISTORY_SUCCESS =
  'GET_CUSTOMER_ORDER_HISTORY_SUCCESS';
export const GET_CUSTOMER_ORDER_HISTORY_FAILED =
  'GET_CUSTOMER_ORDER_HISTORY_FAILED';

export const GET_CUSTOMER_PURCHASES_REQUEST = 'GET_CUSTOMER_PURCHASES_REQUEST';
export const GET_CUSTOMER_PURCHASES_SUCCESS = 'GET_CUSTOMER_PURCHASES_SUCCESS';
export const GET_CUSTOMER_PURCHASES_FAILED = 'GET_CUSTOMER_PURCHASES_FAILED';

export const RESET_CUSTOMER_STATE = 'RESET_CUSTOMER_STATE';
