export const GET_RESOURCES_BRANCH_REQUEST = 'GET_RESOURCES_BRANCH_REQUEST';
export const GET_RESOURCES_BRANCH_SUCCESS = 'GET_RESOURCES_BRANCH_SUCCESS';
export const GET_RESOURCES_BRANCH_FAILED = 'GET_RESOURCES_BRANCH_FAILED';

export const GET_POLICY_SYSTEM_BRANCHES_REQUEST = 'GET_POLICY_SYSTEM_BRANCHES_REQUEST';
export const GET_POLICY_SYSTEM_BRANCHES_SUCCESS = 'GET_POLICY_SYSTEM_BRANCHES_SUCCESS';
export const GET_POLICY_SYSTEM_BRANCHES_FAILED = 'GET_POLICY_SYSTEM_BRANCHES_FAILED';

export const GET_POLICY_SYSTEM_PARTNER_REQUEST = 'GET_POLICY_SYSTEM_PARTNER_REQUEST';
export const GET_POLICY_SYSTEM_PARTNER_SUCCESS = 'GET_POLICY_SYSTEM_PARTNER_SUCCESS';
export const GET_POLICY_SYSTEM_PARTNER_FAILED = 'GET_POLICY_SYSTEM_PARTNER_FAILED';

export const UPDATE_POLICY_SYSTEM_BRANCHES_REQUEST =
  'UPDATE_POLICY_SYSTEM_BRANCHES_REQUEST';
export const UPDATE_POLICY_SYSTEM_BRANCHES_SUCCESS =
'UPDATE_POLICY_SYSTEM_BRANCHES_SUCCESS';
export const UPDATE_POLICY_SYSTEM_BRANCHES_FAILED = 'UPDATE_POLICY_SYSTEM_BRANCHES_FAILED';

export const UPDATE_POLICY_SYSTEM_PARTNER_REQUEST =
    'UPDATE_POLICY_SYSTEM_PARTNER_REQUEST';
export const UPDATE_POLICY_SYSTEM_PARTNER_SUCCESS =
  'UPDATE_POLICY_SYSTEM_PARTNER_SUCCESS';
export const UPDATE_POLICY_SYSTEM_PARTNER_FAILED = 'UPDATE_POLICY_SYSTEM_PARTNER_FAILED';

export const RESET_PARTNER_GROUP_STATE = 'RESET_PARTNER_GROUP_STATE';

export const RESET_BRANCH_GROUP_STATE = 'RESET_BRANCH_GROUP_STATE';


