export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL || '%BACKEND_API_URL%';
export const CLINIC_BASE_URL = `${BASE_URL}/api/v1/`;

export const BASE_URL_UPLOAD_IMAGE = `${BASE_URL}/api/v1/file/clinic`

export const LANGUAGE = {
  VI: 'vi',
  EN: 'en'
};

export const SERVICES_TYPES = [
  'DOCTOR_AT_HOME',
  'MEDICAL_TEST',
  'CLINIC_APPOINTMENT'
];

export const SERVICES_NAME = {
  DOCTOR_AT_HOME: 'Điều dưỡng tại nhà',
  CLINIC_APPOINTMENT: 'Khám tại phòng khám',
  MEDICAL_TEST: 'Xét nghiệm'
};

export const CARD_STYLE = { boxShadow: '0 2px 4px rgb(15 34 58 / 12%)' };

export const ORDER_STATUS = {
  NEW: 'NEW',
  RECEIVED: 'RECEIVED',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  CONFIRMED: 'CONFIRMED',
  PACKAGED: 'PACKAGED',
  SHIPPING: 'SHIPPING',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED'
};

export const ORDER_STATUS_NAME = {
  NEW: 'Mới',
  RECEIVED: 'Đã tiếp nhận',
  PROCESSING: 'Đang xử lý',
  PROCESSED: 'Đã xử lý',
  CONFIRMED: 'Đã xác nhận',
  PACKAGED: 'Đã đóng gói',
  SHIPPING: 'Đang giao',
  DELIVERED: 'Đã nhận',
  CANCELLED: 'Đã hủy'
};

export const PAYMENT_METHOD = {
  COD: 'COD'
};

export const PAYMENT_STATUS = {
  AWAITING: 'AWAITING',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED,'
};

export const ORDER_ACTION_NAME = {
  ASSIGN: 'Phân bổ',
  UPDATE_LINE_ITEM: 'Cập nhật thuốc',
  PROCESS: 'Xử lý',
  CONFIRM: 'Xác nhận',
  PACKAGE: 'Đóng gói',
  SHIPPING: 'Giao hàng',
  CANCEL: 'Hủy bỏ',
  REJECT: 'Từ chối',
  COMPLETE: 'Hoàn thành'
};

export const ORDER_ACTIONS = {
  ASSIGN: 'ASSIGN',
  UPDATE_LINE_ITEM: 'UPDATE-ITEM',
  PROCESS: 'PROCESS',
  CONFIRM: 'CONFIRM',
  PACKAGE: 'PACKAGE',
  SHIPPING: 'SHIPPING',
  CANCEL: 'CANCEL',
  REJECT: 'REJECT',
  ORDER_COMPLETION: 'COMPLETE'
};

export const ORDER_TRANSITIONS = [
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.CONFIRMED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.PROCESSED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.PACKAGED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.NEW,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.PROCESSED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.CONFIRMED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.PACKAGED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.SHIPPING,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.ASSIGN,
    from: ORDER_STATUS.NEW,
    to: ORDER_STATUS.RECEIVED
  },
  {
    name: ORDER_ACTIONS.PROCESS,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.PROCESSED
  },
  {
    name: ORDER_ACTIONS.UPDATE_LINE_ITEM,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.RECEIVED
  },
  {
    name: ORDER_ACTIONS.CONFIRM,
    from: ORDER_STATUS.PROCESSED,
    to: ORDER_STATUS.CONFIRMED
  },
  {
    name: ORDER_ACTIONS.PACKAGE,
    from: ORDER_STATUS.CONFIRMED,
    to: ORDER_STATUS.PACKAGED
  },
  {
    name: ORDER_ACTIONS.SHIPPING,
    from: ORDER_STATUS.PACKAGED,
    to: ORDER_STATUS.SHIPPING
  },
  {
    name: ORDER_ACTIONS.ORDER_COMPLETION,
    from: ORDER_STATUS.SHIPPING,
    to: ORDER_STATUS.DELIVERED
  }
];

export const SALE_UNITS = ['viên', 'vỉ', 'lọ', 'hộp'];

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const STATUS_NAMES = {
  ACTIVE: 'Hoạt động',
  INACTIVE: 'Ngưng hoạt động'
};

export const SALE_TYPES = {
  DIRECT: 'DIRECT',
  ORDER: 'ORDER',
  RETURN: 'RETURN',
  EXCHANGE: 'EXCHANGE'
};
export const SALE_CHANNEL = {
  DIRECT: 'Bán Trực tiếp',
  OTHER:"Khác"
}

export const PAYMENT_METHODS = {
  CASH: 'CASH',
  CARD: 'CARD',
  TRANSFER: 'TRANSFER'
};

export const PRODUCT_TYPES = {
  MEDICINE: 'MEDICINE',
  PRODUCT: 'PRODUCT'
};

export const PRODUCT_TYPE_NAMES = {
  MEDICINE: 'Thuốc',
  PRODUCT: 'Hàng hóa'
};

export const PROCESS_STATUS_TYPES = {
  DONE: 'DONE',
  FAIL: 'FAIL',
  PROCESSING: 'PROCESSING'
}

export const PROCESS_STATUS_NAMES = {
  DONE: 'Hoàn thành',
  FAIL: 'Thất bại',
  PROCESSING: 'Đang xử lý'
}

export const DISCOUNT_TYPES = {
  PERCENT: 'PERCENT',
  VALUE: 'VALUE'
};

export const STOCK_STATUS = {
  ACTIVE: 'Đang kinh doanh',
  INACTIVE: 'Ngừng kinh doanh'
};

export const INVOICE_STATUS = {
  COMPLETED: 'Hoàn Thành',
  DRAFT: 'Nháp',
  DRAFT_PM: 'Chờ xác nhận',
  CANCELED: 'Đã huỷ',
  PACKAGE_EXPORT: 'Đang xuất hàng'
};

export const PAYMENT_STATE = {
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  PACKAGE_EXPORT: 'PACKAGE_EXPORT'
};

export const PURCHASE_ORDER_STATUS = {
  COMPLETED: 'Hoàn Thành',
  DRAFT: 'Phiếu tạm'
};

export const CASH_FLOW_TYPES_DICT = {
  RECEIPT: 'Phiếu thu',
  PAYMENT: 'Phiếu chi'
};

export const PAYMENT_METHOD_DICT = {
  CASH: 'Tiền mặt',
  CARD: 'Thẻ',
  TRANSFER: 'Chuyển khoản'
};

export const SALE_CASES = {
  EXCHANGE_PRODUCT: 'EXCHANGE_PRODUCT',
  RETURN_PRODUCT: 'RETURN_PRODUCT',
  NEW_INVOICE: 'NEW_INVOICE',
  NEW_ORDER: 'NEW_ORDER',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  UPDATE_ORDER: 'UPDATE_ORDER',
  INVOICE_FOR_ORDER: 'INVOICE_FOR_ORDER'
};

export const STATUS_SAMPLE = {
  ACTIVE: 'Áp dụng',
  INACTIVE: 'Không áp dụng'
};
export const FORM_PRINT_TYPES = {
  FORM_PRINTS: 'FORM_PRINT',
};
export const TYPE_SEARCH={
  date: 'date',
  radio:'radio',
  rangerTime:'rangerTime',
}

export const FORM_PRINT_REDUCER_MAP = {
  [FORM_PRINT_TYPES.FORM_PRINTS]: 'formPrints',
};
export const PRODUCT_REPORT_POPOVER_ITEM = [
  {
    text: 'Hôm nay',
    value: 'TODAY',
    params: 'today'
  },
  {
    text: 'Tuần này',
    value: 'THISWEEK',
    params: 'thisWeek'
  },
  {
    text: 'Tháng này',
    value: 'THISMONTH',
    params: 'thisMonth'
  },
  {
    text: 'Đã hết hạn',
    value: 'EXPIRED',
    params: 'expired'
  },
  {
    text: 'Tháng tới',
    value: '1MONTH',
    params: 'oneMonth'
  },
  {
    text: 'Hai tháng tới',
    value: '2MONTH',
    params: 'twoMonths'
  },
  {
    text: 'Ba tháng tới',
    value: '3MONTH',
    params: 'threeMonths'
  },
  {
    text: 'Sáu tháng tới',
    value: '6MONTH',
    params: 'sixMonths'
  },
  {
    text: 'Toàn thời gian',
    value: 'ALLTIME',
    params: 'allTime'
  }
];
export const ACTIVITY_NAME={
  WAREHOUSE: 'WAREHOUSE',
  SOLD_PRODUCT: 'SOLD_PRODUCT',
  PRODUCT_ORDER: 'PRODUCT_ORDER',
  PRODUCT_RETURN : 'PRODUCT_RETURN',
  DAMAGE:'DAMAGE',
  PURCHASE_RETURN : 'PURCHASE_RETURN',
  STOCKTAKE:'STOCKTAKE',
  CREATE_RECEIPT: 'CREATE_RECEIPT',
  CREATE_PAYMENT : 'CREATE_PAYMENT',
}
export const ACTIVITY_NAME_LINK={
  WAREHOUSE: 'warehouse',
  SOLD_PRODUCT: 'product-invoice',
  PRODUCT_ORDER: 'product-order',
  PRODUCT_RETURN : 'product-return',
  DAMAGE:'damage',
  PURCHASE_RETURN : 'purchase-return',
  STOCKTAKE:'stocktake',
  CREATE_RECEIPT: 'cash-flow',
  CREATE_PAYMENT : 'cash-flow',
}
export const ACTIVITY_NAME_EN={
  WAREHOUSE: 'warehouse',
  SOLD_PRODUCT: 'sold product',
  PRODUCT_ORDER: 'product order',
  PRODUCT_RETURN : 'product return',
  DAMAGE:'damage',
  PURCHASE_RETURN : 'purchase return',
  STOCKTAKE:'stocktake',
  CREATE_RECEIPT: 'create receipt',
  CREATE_PAYMENT : 'create payment',
}
export const ACTIVITY_NAME_VI={
  WAREHOUSE: 'Nhập kho',
  SOLD_PRODUCT: 'Bán hàng',
  PRODUCT_ORDER: 'Đặt hàng',
  PRODUCT_RETURN : 'Trả hàng',
  DAMAGE:'Tạo Xuất huỷ',
  PURCHASE_RETURN : 'Trả hàng nhập',
  STOCKTAKE:'Kiểm kho',
  CREATE_RECEIPT: 'Lập phiếu thu',
  CREATE_PAYMENT : 'Lập phiếu chi',
}
export const MESSAGE_ACTIVITY = {
  WAREHOUSE : `vừa ${ACTIVITY_NAME_VI.WAREHOUSE} giá trị`,
  SOLD_PRODUCT : `vừa ${ACTIVITY_NAME_VI.SOLD_PRODUCT} giá trị`,
  CREATE_RECEIPT : `vừa ${ACTIVITY_NAME_VI.CREATE_RECEIPT} giá trị`,
  CREATE_PAYMENT : `vừa ${ACTIVITY_NAME_VI.CREATE_PAYMENT} giá trị`,
  PRODUCT_RETURN : `vừa ${ACTIVITY_NAME_VI.PRODUCT_RETURN} giá trị`,
  DAMAGE : `vừa ${ACTIVITY_NAME_VI.DAMAGE} giá trị`,
  PURCHASE_RETURN : `vừa ${ACTIVITY_NAME_VI.PURCHASE_RETURN} giá trị`,
  STOCKTAKE : `vừa ${ACTIVITY_NAME_VI.STOCKTAKE} giá trị`,
  PRODUCT_ORDER : `vừa ${ACTIVITY_NAME_VI.PRODUCT_ORDER} giá trị`,
}
export const TYPE_ACTIVITY={
  INTERNAL:"INTERNAL"
}
export const ACTOR_GROUP={
  CUSTOMER:"Khách hàng",
  SUPPLIER:"Nhà cung cấp",
  EMPLOYEE:"Nhân viên",
  PARTNER:"Đối tác giao hàng",

}
export const TYPE_PRODUCT_ORDER = {
  SELL_PRODUCT : 'Bán hàng',
  PURCHASE_RECEIPT : 'Nhập hàng',
  PRICE_BLANCE : 'Cân bằng',
  STOCKTAKE_INCREASE:'Kiểm kho',
  PRODUCT_RETURN : 'Trả hàng',
  ORDER_PRODUCT : 'Đặt hàng',
}
export const API_LIST ={
  warehouse:"purchase-order",
  purchaseReturn:"purchase-return",
  productOrder:"sale-order",
  damage:"damage-item",
  productInvoice:"invoice",
  productReturn:"return",
  stockTake:"stockTake",
  cashFlow:"general-ledger",
  supplier:"supplier",
  customer:"customer",
  product:'product',
  medicine:'medicine'
}
export const ROOT_COMPANY_ID = process.env.REACT_APP_ROOT_COMPANY_ID  ?? 99999

export const MAX_LIMIT = 200

export const PAYER_LOGISTIC = {
  CUSTOMER: 'Khách hàng',
  SYSTEM:'Công ty'
}