import requester from '../requester';

const productReturn = {
  getAll: query => requester.get('/return', query),
  getById: id => requester.get(`/return/${id}`),
  getReceivers: (query) => requester.get(`/user`, query),
  returnProduct: productReturn => requester.post('/return', productReturn),
  exchangeProduct: productExchange => requester.post('/exchange', productExchange),
  update: infoProduct => requester.patch(`/return`, infoProduct),
};

export default productReturn;
