import { all } from 'redux-saga/effects';
import themeSaga from './worldCare/user/tasks';
import hospitalSaga from './worldCare/hospital/tasks';
import appointmentSaga from './worldCare/appointment/tasks';
import degreeSaga from './worldCare/degree/tasks';
import specialitySaga from './worldCare/speciality/tasks';
import geoSaga from './worldCare/geo/tasks';
import staffSaga from './worldCare/staff/tasks';
import serviceSaga from './worldCare/service/tasks';
import newsCategorySaga from './worldCare/newsCategory/tasks';
import newsSaga from './worldCare/news/tasks';
import staffGroupSaga from './worldCare/staffGroup/tasks';
import layoutSaga from './worldCare/layout/tasks';
import branchSaga from './worldCare/branch/tasks';
import employeeSaga from './worldCare/employee/tasks';
import employeeGroupSaga from './worldCare/employeeGroup/tasks';
import partnerSaga from './worldCare/partner/tasks';
import orderSaga from './worldCare/order/tasks';
import userEmployeeSaga from './worldCare/userEmployee/tasks';
import partnershipSaga from './worldCare/partnership/tasks';
import couponSaga from './worldCare/coupon/tasks';
import policySaga from './worldCare/policy/tasks';
import dashboardSaga from './worldCare/dashboard/tasks';
import shippingVendorSaga from './worldCare/shippingVendor/tasks';
import permission from './worldCare/permission/tasks';

// WORLD CLINIC
import medicineGroupSaga from './worldClinic/medicineGroup/tasks';
import medicineMethodSaga from './worldClinic/medicineMethod/tasks';
import medicineLocationSaga from './worldClinic/medicineLocation/tasks';
import medicineUnitSaga from './worldClinic/medicineUnit/tasks';
import supplierSaga from './worldClinic/supplier/tasks';
import supplierSagaGroup from './worldClinic/supplierGroup/tasks';
import medicineCategorySaga from './worldClinic/medicineCategory/tasks';
import medicineSaga from './worldClinic/medicine/tasks';
import warehouseSaga from './worldClinic/warehouse/tasks';
import productOrderSaga from './worldClinic/productOrder/tasks';
import productInvoiceSaga from './worldClinic/productInvoice/tasks';
import cashFlowSaga from './worldClinic/cashFlow/tasks';
import saleSaga from './worldClinic/sale/tasks';
import customerSaga from './worldClinic/customer/tasks';
import manufactureSaga from './worldClinic/manufacture/tasks';
import batchSaga from './worldClinic/batch/tasks';
import purchaseReturnSaga from './worldClinic/purchaseReturn/tasks';
import purchaseOrderQuotationSaga from './worldClinic/purchaseOrderQuotation/tasks';
import damageSaga from './worldClinic/damage/tasks';
import directorySaga from './worldClinic/directory/tasks';
import stockTake from './worldClinic/stockTake/tasks';
import productReturnSaga from './worldClinic/productReturn/tasks';
import productReportSaga from './worldClinic/productReport/tasks';
import samplePrescription from './worldClinic/samplePrescription/tasks';
import partnerClinicSaga from './worldClinic/partner/tasks';
import notificationServiceSaga from './worldClinic/notificationService/tasks';
import formPrintSaga from './worldClinic/formPrint/tasks';
import imformationPaymentSaga from './worldClinic/imformationPayment/tasks';
import receiptPaymentTypeSaga from './worldClinic/receiptPaymentType/tasks';
import processImportSaga from './worldClinic/processImport/tasks';
import myNotification from './worldClinic/myNotification/tasks';

export default function* rootSaga() {
  yield all([
    themeSaga(),
    hospitalSaga(),
    appointmentSaga(),
    degreeSaga(),
    specialitySaga(),
    geoSaga(),
    staffSaga(),
    serviceSaga(),
    newsCategorySaga(),
    newsSaga(),
    staffGroupSaga(),
    layoutSaga(),
    branchSaga(),
    employeeSaga(),
    orderSaga(),
    employeeGroupSaga(),
    partnerSaga(),
    userEmployeeSaga(),
    partnershipSaga(),
    couponSaga(),
    policySaga(),
    dashboardSaga(),
    shippingVendorSaga(),
    permission(),

    // WORLD CLINIC
    medicineGroupSaga(),
    medicineMethodSaga(),
    medicineLocationSaga(),
    medicineUnitSaga(),
    supplierSaga(),
    supplierSagaGroup(),
    medicineCategorySaga(),
    medicineSaga(),
    warehouseSaga(),
    productOrderSaga(),
    productInvoiceSaga(),
    cashFlowSaga(),
    saleSaga(),
    customerSaga(),
    manufactureSaga(),
    batchSaga(),
    purchaseReturnSaga(),
    purchaseOrderQuotationSaga(),
    damageSaga(),
    productReturnSaga(),
    productReportSaga(),
    stockTake(),
    samplePrescription(),
    directorySaga(),
    partnerClinicSaga(),
    notificationServiceSaga(),
    formPrintSaga(),
    imformationPaymentSaga(),
    receiptPaymentTypeSaga(),
    processImportSaga(),
    myNotification(),
  ]);
}
