import React from 'react';
import { Form, Select } from 'antd';
import { useCities } from '~/hooks';
import { filterAcrossAccents } from '~/hooks/utils';

const FilterCity = ({ onChange, value }) => {
  const [cities, isLoading] = useCities();

  return (
    <Form.Item label="Thành phố:" labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}>
      <Select
        allowClear
        value={value}
        onChange={onChange}
        loading={isLoading}
        showSearch
        autoComplete="off"
        filterOption={filterAcrossAccents}
        placeholder='Toàn quốc'
      >
        {cities.map(({ code, name }) => (
          <Select.Option key={code} value={code}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FilterCity;
