import React from 'react';
import { useMatchOrPolicy } from '~/hooks';

const WithOrPermission = ({ children, permission }) => {
    
  const isMatchPolicy = useMatchOrPolicy(permission);

  return <>{isMatchPolicy && children}</>;
};

export default WithOrPermission;