import React, { useState, useMemo, useCallback } from 'react';
import {
  useCashFlows,
  useCashFlowQueryParams,
  useUpdateCashFlowParams,
  useResetCashFlow,
  useCashFlowPaging,
  useCashFlowColumnSetting,
  useGetSummaryCashFlow,
  SEARCH_CASHFLOW,
} from '~/hooks';
import { Table, Input, Button, Dropdown, Row, Tabs } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE, CASH_FLOW_TYPES_DICT } from '~/constants/defaultValue';
import CashFlowInfo from './Detail/Information';

import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined
} from '@ant-design/icons';
import { formatCurrency } from '~/utils/helper';
import './index.scss';
import moment from 'moment';
import ReceiptPaymentModal from '~/components/WorldClinic/CashFlow/Detail/ReceiptPaymentModal';
import { get } from 'lodash';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ReceiptBillModal from './Detail/RecetptBillModal';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { onSelectRow } from '~/utils/validators';

const { Search } = Input;
const { TabPane } = Tabs;

const CashFlow = () => {
  const [isReceiptPaymentOpen, setIsReceiptPaymentOpen] = useState(false);
  const [isReceipt, setIsReceipt] = useState(null);
  const [dataView, setDataView] = useState([]);
  const [isShowReceiptBill, setShowReceiptBill] = useState(false);

  const [query] = useCashFlowQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCashFlowParams(
    query
  );
  const [summary] = useGetSummaryCashFlow()

  const [selectTable, setSelectTable]=useState([])
  const [cashFlows, isLoading] = useCashFlows(query);
  const paging = useCashFlowPaging();

  useResetCashFlow();

  const [columnMenu, selectedColumnKeys] = useCashFlowColumnSetting();

  const columns = useMemo(() => {
    const allCloumn = [
      {
        title: 'Mã phiếu',
        key: 'code',
        dataIndex: 'code'
      },
      {
        title: 'Thời gian',
        key: 'time',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD/MM/YYYY HH:mm')
      },
      {
        title: 'Loại thu chi',
        key: 'type',
        dataIndex: 'type',
        render: type => <>{CASH_FLOW_TYPES_DICT[type]} { type==='RECEIPT'? <ArrowDownOutlined style={{color:"blue",fontSize:18}} />:<ArrowUpOutlined style={{color:"red",fontSize:18}} /> } </> 
      },
      {
        title: 'Người nộp/nhận',
        key: 'customer',
        dataIndex: ['payerReceiver', 'name'],
        render : (name,{supplier,customer})=>{
        return name ?? get(supplier??customer,"name","")
        }
      },
      {
        title: 'Giá trị',
        key: 'amount',
        dataIndex: 'paymentAmount',
        align: 'end',
        render: paymentAmount => formatCurrency(paymentAmount)
      }
    ];

    return allCloumn.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const datasource = useMemo(() => {
    const getTotal = (data, key) =>
      data.reduce((acc, item) => (acc += item[key]), 0);

    const summaryRecord = {
      discount: getTotal(cashFlows, 'discount'),
      debt: getTotal(cashFlows, 'debt'),
      paid: getTotal(cashFlows, 'paid'),
      isSummaryRow: true,
      _id: -1
    };

    return selectedColumnKeys['debt']
      ? [summaryRecord, ...cashFlows]
      : cashFlows;
  }, [cashFlows, selectedColumnKeys]);


  const handleShowReceiptPaymentModal = (type) => {
    setIsReceipt(type === 'receipt');
    setIsReceiptPaymentOpen(true);
    setDataView(null)
  };

  const handleOpenReceiptPayment = (data) => {
    if(data.transactionType === 'TTHD' || get(data,'type','').toUpperCase() === 'RECEIPT') {
      setShowReceiptBill(true)
      setDataView(data)
      return
    }
    setIsReceiptPaymentOpen(true);
    setDataView(data)
  }

  const expandedRowRender = useCallback(record => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
             <CashFlowInfo
               record={record}
               handleOpenReceiptPayment={handleOpenReceiptPayment}/>
          </TabPane>
        </Tabs>
      </div>
    );
  }, []);

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div
          className="wc-page-header"
          style={{ paddingRight: 20, paddingLeft: 20 }}
        >
          <h1 className="wc-page-header__title">Sổ quỹ tiền mặt</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_CASHFLOW} />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_CASHBOOK}>
              <Button
                type="primary"
                style={{ float: 'right', marginRight: 10 }}
                onClick={() => handleShowReceiptPaymentModal('receipt')}
              >
                <PlusOutlined />
                Lập phiếu thu
              </Button>
              <Button
                type="primary"
                style={{ float: 'right', marginRight: 10 }}
                onClick={() => handleShowReceiptPaymentModal('payment')}
              >
                <PlusOutlined />
                Lập phiếu chi
              </Button>

              <ButtonExport router={API_LIST.cashFlow} label="Sổ quỹ" exportOption={1} fileName={'Soquy_'}/>

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}/>

          <div className="page-content__right" style={{ paddingLeft: 0 }}>
            <div style={CARD_STYLE}>
              <Row
                className="cash-flow-report"
                gutter={16}
                justify="end"
                align="middle"
              >
                <div className="cash-flow-report__item cash-flow-report-item">
                  <p className="cash-flow-report-item__label">Quỹ đầu kỳ</p>
                  <p className="cash-flow-report-item__value cash-flow-report-item__value--cyan">
                    {/* {formatCurrency(0)} */}
                  </p>
                </div>

                <div className="cash-flow-report__item cash-flow-report-item">
                  <p className="cash-flow-report-item__label">Tổng thu</p>
                  <p className="cash-flow-report-item__value cash-flow-report-item__value--info">
                    {/* {formatCurrency(4565000)} */}
                  </p>
                </div>

                <div className="cash-flow-report__item cash-flow-report-item">
                  <p className="cash-flow-report-item__label">Tổng chi</p>
                  <p className="cash-flow-report-item__value cash-flow-report-item__value--danger">
                    {/* - {formatCurrency(42290000)} */}
                  </p>
                </div>

                <div className="cash-flow-report__item cash-flow-report-item">
                  <p className="cash-flow-report-item__label">Tồn quỹ</p>
                  <p className="cash-flow-report-item__value cash-flow-report-item__value--green">
                    {/* -{formatCurrency(37725000)} */}
                  </p>
                </div>
              </Row>
            </div>

            <div style={{ ...CARD_STYLE, padding: 20 }}>
              {isLoading ? (
                <SkeletonTable
                  rowCount={cashFlows.length}
                  columns={columns}
                  pagination={paging}
                />
              ) : (
                <Table
                  className="table--selectable permission-table"
                  size="middle"
                  rowClassName={record =>
                    record.isSummaryRow ? 'summary-row' : ''
                  }
                  rowKey={rc => rc._id}
                  columns={columns}
                  dataSource={datasource}
                  onChange={({ current,pageSize }) => onParamChange({ page: current,limit:pageSize })}
                  pagination={{
                    ...paging,
                    // pageSize: 11,
                    showSizeChanger:true,
                    // total: Math.ceil(paging.total + paging.total / 10),
                    showTotal: () => `Tổng cộng: ${paging.total} `
                  }}
                  // rowSelection={{
                  //   renderCell: (checked, record, index, originNode) =>
                  //     record.isSummaryRow ? <></> : originNode,
                  //   getCheckboxProps: record => ({
                  //     disabled: record.isSummaryRow,
                  //     name: record.name
                  //   })
                  // }}
                  onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}
                  
                  onRow={onSelectRow(setSelectTable)}
                  
                  expandable={{
                    expandedRowRender,
                    rowExpandable: record => !record.isSummaryRow,
                    expandedRowKeys:[selectTable]
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      { isShowReceiptBill&& <ReceiptBillModal
       handleRenderCashFlow={setShowReceiptBill} 
       onClose={()=>setShowReceiptBill(false)} 
       isOpen={isShowReceiptBill}
       dataView={dataView}
       /> }
      
      <ReceiptPaymentModal
        dataView={dataView}
        isOpen={isReceiptPaymentOpen}
        isReceipt={isReceipt}
        onClose={() => setIsReceiptPaymentOpen(false)}
        handleRenderCashFlow={useCashFlowQueryParams}
      />
    </div>
  );
};

export default CashFlow;
