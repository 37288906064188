import React, {useEffect, useState, useMemo, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  useWarehouses,
  useDeleteWarehouse,
  useWarehouseQueryParams,
  useUpdateWarehouseParams,
  useResetWarehouse,
  useWarehousePaging,
  useWarehouseColumnSetting,
  useResetMedicine,
  useSummaryWarehouse,
  SEARCH_PURCHASE_ORDER
} from '~/hooks';
import { Table, Input, Button, Dropdown, Tabs, Form } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE } from '~/constants/defaultValue';
import WarehouseInfo from './Detail/Information';
import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined
} from '@ant-design/icons';
import { exportCSV, formatCurrency, formatDateTime } from '~/utils/helper';
import './index.scss';
import { INVOICE_STATUS } from '~/constants/defaultValue';
import { PATH_CLINIC } from '~/routes/paths';
import { get, merge } from 'lodash';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import SummaryTable from '~/components/Common/SummaryTable';
import { useExport } from '~/components/Common/ModuleExport';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import { onSelectRow } from '~/utils/validators';

// const { Search } = Input;
const { TabPane } = Tabs;

const Warehouse = () => {
  const history = useHistory();
  const [query] = useWarehouseQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateWarehouseParams(query);

  const [warehouses, isLoading] = useWarehouses(query);
  const [summary] = useSummaryWarehouse(query);

  const paging = useWarehousePaging();
  const [selectRow,setSelectRow] = useState()
  
  const onDeleteSuccess = () => {
    if (query.id) {
      history.push({
        pathname: PATH_CLINIC.warehouse.root
      });
    } else {
      const isDeletingLastItemInPage = warehouses.length === 2;
      const canDecreasePage = query.page > 0;

      if (isDeletingLastItemInPage && canDecreasePage) {
        onParamChange({ page: query.page - 1 });
      }
    }
  };

  const [, deleteWarehouse] = useDeleteWarehouse(onDeleteSuccess);

  useResetWarehouse();
  useResetMedicine();

  const [columnMenu, selectedColumnKeys] = useWarehouseColumnSetting();
  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Mã nhập hàng',
        key: 'code',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: 'Mã nhập hàng từ WorldPharma	',
        key: 'billCodeSequence',
        dataIndex: 'billCodeSequence',
        width: 300,
        align: 'center'
      },
      {
        title: 'Thời gian',
        key: 'time',
        width: 150,
        dataIndex: 'createdAt',
        render: (purchasedAt, record) =>
          !record.isSummaryRow && formatDateTime(purchasedAt)
      },
      {
        title: 'Nhà cung cấp',
        key: 'supplier',
        width: 250,
        dataIndex: ['supplier', 'name']
      },
      {
        title: 'Cần trả NCC',
        key: 'debt',
        dataIndex: 'debt',
        width: 150,
        align: 'end',
        render :(value, record)=> record?.typePurchase !== 'PM' ? formatCurrency(value) : formatCurrency(record?.totalPrice)
        // render: purchaseOrderItems => { 
        // const debt =  purchaseOrderItems?.reduce((a,b)=> a +(get(b,'debt',0)),0)
        //  return formatCurrency(debt)}
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        width: 150,
        align: 'center',
        render: status => INVOICE_STATUS[status]
      }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [selectedColumnKeys, warehouses]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  // const exportCsvData = async () => {
  //   handleAddExport({...query,isExport:1})
  //   // const columnNames = columns.filter(({ key }) => key !== 'actions');
  //   // const dataExport = warehouses.map((value,index)=>
  //   //  columnNames.reduce((a,b)=>{return merge(a, {[b.key]:get(warehouses[index],b.dataIndex,"")} )},{})
  //   //  )
  //   //  const data = await Api.warehouse.getAll({isExport:1})
  //   //  console.log(data)
  //   // history.push({hash:"sdfasdf",})
  //   // exportCSV({

  //   //   columns: columnNames,
  //   //   data: dataApi,
  //   //   fileName: 'Danhsachnhaphang_'
  //   // });
  // };

  const expandedRowRender = record => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <WarehouseInfo record={record} deleteWarehouse={deleteWarehouse} />
          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Hàng hoá</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_PURCHASE_ORDER}/>
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_PURCHASEORDER}>
              <Link to="/warehouse/create">
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: 10 }}
                >
                  <PlusOutlined />
                  Thêm mới
                </Button>
              </Link>

              <ButtonExportOption 
                router={API_LIST.warehouse} 
                label="Nhập kho" 
                query={query} 
                exportOption={[1,2]}
                fileName={["Danhsachnhaphang_",'Danhsachnhaphangchitiet_']} />

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>
    <SummaryTable summaryValue={summary}/>
        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}/>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={warehouses.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                size="middle"
                // rowClassName={record =>
                //   record.isSummaryRow ? 'summary-row' : ''
                // }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={warehouses}
                onChange={({ current, pageSize }) => onParamChange({ page: current ,limit:pageSize })}
                sticky={{offsetScroll:"0"}}
                pagination={
                  !query.id && {
                    ...paging,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50' ,'100','1000','10000','100000'],
                    showTotal: () => `Tổng cộng: ${paging.total} `
                  }
                }
                onExpandedRowsChange={(e)=>  {
                  setSelectRow(e.at(-1))}}

                onRow={onSelectRow(setSelectRow)}

                expandable={{
                  expandedRowRender,
                  rowExpandable: record => !record.isSummaryRow,
                  defaultExpandAllRows: !!query.id,
                  expandedRowKeys :[selectRow]
                }}
                  scroll={{ x: 'max-content' }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warehouse;
