import { Row, Col, Skeleton } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';

const columns = [
  { width: '5%' },
  { width: '15%' },
  { width: '15%' },
  { width: '15%' },
  { width: '13%' },
  { width: '10%' },
  { width: '17%' },
  { width: '20%' }
];

const OrderDetailsSkeleton = () => {
  return (
    <>
      <Row gutter={[24, 24]} className="order-details">
        <Col md={8}>
          <Skeleton.Avatar active size={200} shape={'square'} />
        </Col>
        <Col md={16}>
          <Skeleton title active description={{ rows: 5 }} />
        </Col>
      </Row>
      <SkeletonTable showHeader={false} pagination={false} columns={columns} />
    </>
  );
};

export default OrderDetailsSkeleton;
