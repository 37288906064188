import React, { useState, useMemo } from 'react';
import {
  useMedicineGroups,
  useUpdateMedicineGroup,
  useDeleteMedicineGroup,
  useMedicineGroupQueryParams,
  useUpdateMedicineGroupParams,
  useResetMedicineGroup,
  useMedicineGroupPaging
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Checkbox, Radio, Space, Typography, Row, } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE, MAX_LIMIT, STATUS, STATUS_NAMES } from '~/constants/defaultValue';
import MedicineGroupForm from './Form';
import { get, omit } from 'lodash';

const { Search } = Input;

const ColumnActions = ({ _id, deleteGroup, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_PRODUCTGROUP}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_PRODUCTGROUP}>
        <WithPermission permission={POLICY.DELETE_PRODUCTGROUP}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_PRODUCTGROUP}>
        <Popconfirm
          title="Bạn muốn xoá nhóm thuốc này?"
          onConfirm={() => deleteGroup(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const MedicineGroups = () => {
  const [query] = useMedicineGroupQueryParams(MAX_LIMIT);
  const [name, { setKeyword, onParamChange }] = useUpdateMedicineGroupParams(query);

  const [groups, isLoading] = useMedicineGroups(query);
  const paging = useMedicineGroupPaging();
  const [groupId, setGroupId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const numberOfGroups = groups.length;
  const canUpdate = useMatchPolicy(POLICY.UPDATE_PRODUCTGROUP);
  const canDelete = useMatchPolicy(POLICY.DELETE_PRODUCTGROUP);

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfGroups === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteGroup] = useDeleteMedicineGroup(onDeleteSuccess);

  useResetMedicineGroup();

  const onOpenForm = id => {
    setGroupId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setGroupId(null);
    setIsOpenForm(false);
  };

  const [isSubmitLoading, handleUpdate] = useUpdateMedicineGroup(onCloseForm);
  const onChangeStatus = (record, _id, status, isSubmitLoading) => {
    handleUpdate({
      _id,
      status,
      isSubmitLoading,
      ...omit(record, ['_id', 'status'])
    });
  };

  const onChange = ({ target }) => {
    switch (target.value) {
     case 2:
       onParamChange({...query, status: STATUS['ACTIVE']})
       break;
     case 3:
       onParamChange({...query, status: STATUS['INACTIVE']})
       break;
     default:
       onParamChange({...query ,status:'' })
       break;
    }
 }

  const columns = useMemo(
    () => [
      {
        title: 'Tên nhóm thuốc',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'Nhóm cha',
        key: 'superGroupId',
        dataIndex: ['superGroup', 'name']
      },
      {
        title: 'Ghi chú',
        key: 'description',
        dataIndex: 'description'
      },
      {
        title: 'Trạng thái',
        key: 'status',
        align: 'center',
        dataIndex: 'status',
        width: 150,
        render: (status, record) => {
          return (
            <Checkbox
              checked={status === STATUS.ACTIVE}
              onChange={e => {
                return onChangeStatus(
                  record,
                  get(record, '_id'),
                  e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE,
                  isSubmitLoading
                );
              }}
            />
          );
        }
      },
      ... (canUpdate || canDelete ) ? [{
        title: 'Thao tác',
        key: 'action',
        width: '110px',
        render: record => {
          return (
            <ColumnActions
              {...record}
              deleteGroup={deleteGroup}
              onOpenForm={onOpenForm}
            />
          );
        }
      }] : []
    ],
    [deleteGroup]
  );

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Nhóm thuốc</h1>

          <div className="wc-page-header__search wclinic-search">
            <Search
              style={{ width: '100%' }}
              placeholder="Tìm nhóm thuốc"
              enterButton
              allowClear
              onSearch={() => onParamChange({ name })}
              onChange={e => setKeyword(e.target.value)}
              value={name}
            />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_PRODUCTGROUP}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => onOpenForm()}
              >
                Thêm mới
              </Button>
            </WithPermission>
          </div>
        </div>

        <Space style={{ marginBottom: 20 }}>
          <Typography style={{ fontSize: 18, marginRight: 20 }}>
            Phân loại trạng thái theo :
          </Typography>
          <Row gutter={20}>
            <Radio.Group
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={(() => {
                switch (query?.status) {
                  case 'ACTIVE':
                    return 2;
                  case 'INACTIVE':
                    return 3;
                  default:
                    return 1;
                }
              })()}
            >
              <Radio.Button value={1}>Tất cả</Radio.Button>
              <Radio.Button value={2}>{STATUS_NAMES['ACTIVE']}</Radio.Button>
              <Radio.Button value={3}>{STATUS_NAMES['INACTIVE']}</Radio.Button>
            </Radio.Group>
          </Row>
        </Space>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={groups.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className='permission-table'
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={groups}
                // onChange={({ current }) => onParamChange({ page: current })}
                // pagination={{
                //   ...paging,
                //   showTotal: total => `Tổng cộng: ${total} `
                // }}
                  pagination={{
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <MedicineGroupForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        id={groupId}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default MedicineGroups;
