import React from 'react';
import './index.scss';
import { Row, Col } from 'antd';

const InfoItem = ({ label, value, render }) => (
  <div className="prescription-info-item">
    <label className="prescription-info-item__label">{label}</label>
    {render ? (
      render(value)
    ) : (
      <p className="prescription-info-item__value">{value}</p>
    )}
  </div>
);

const leftInfo = [
  {
    label: 'Mã đơn thuốc:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Bác sĩ kê đơn:',
    dataIndex: 'doctor'
  },
  {
    label: 'CS Khám bệnh:',
    dataIndex: 'healthcareFacility'
  },
  {
    label: 'Bệnh nhân:',
    dataIndex: 'patientName'
  },
  {
    label: 'Tuổi:',
    dataIndex: 'age'
  }
];

const centerInfo = [
  {
    label: 'Giới tính:',
    dataIndex: 'gender',
    render: gender => (gender === 'male' ? 'Nam' : 'Nữ')
  },
  {
    label: 'Cân nặng:',
    dataIndex: 'weight'
  },
  {
    label: 'CMND/Căn cước:',
    dataIndex: 'patientId'
  },
  {
    label: 'Thẻ BHYT',
    dataIndex: 'patientHealthInsuranceCardNumber'
  },
  {
    label: 'Địa chỉ:',
    dataIndex: 'address'
  }
];

const rightInfo = [
  {
    label: 'Điện thoại:',
    dataIndex: 'phoneNumber'
  },
  {
    label: 'Chẩn đoán:',
    dataIndex: 'diagnosis'
  },
  {
    label: 'Mã bệnh:',
    dataIndex: 'diseaseCode'
  },
  {
    label: 'Tên bệnh:',
    dataIndex: 'diseaseName'
  },
  {
    label: 'Hoạt chất:',
    dataIndex: 'activeIngredient'
  }
];

const Prescription = ({ prescription }) => {
  return (
    <div className="prescription">
      <h5 className="prescription__title">Thông tin đơn thuốc</h5>

      <Row gutter={60} className="prescription__info">
        <Col span={8}>
          <div>
            {leftInfo.map(({ label, dataIndex, render }) => (
              <InfoItem
                key={label}
                label={label}
                value={prescription[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8} style={{ marginLeft: 15 }}>
          <div>
            {centerInfo.map(({ label, dataIndex, render }) => (
              <InfoItem
                key={label}
                label={label}
                value={prescription[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7}>
          <div>
            {rightInfo.map(({ label, dataIndex, render }) => (
              <InfoItem
                key={label}
                label={label}
                value={prescription[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Prescription;
