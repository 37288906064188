export const GET_BATCHES_REQUEST = 'GET_BATCHES_REQUEST';
export const GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS';
export const GET_BATCHES_FAILED = 'GET_BATCHES_FAILED';

export const CREATE_BATCH_REQUEST = 'CREATE_BATCH_REQUEST';
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS';
export const CREATE_BATCH_FAILED = 'CREATE_BATCH_FAILED';

export const UPDATE_BATCH_BY_ORDER_REQUEST = 'UPDATE_BATCH_BY_ORDER_REQUEST';
export const UPDATE_BATCH_BY_ORDER_SUCCESS = 'UPDATE_BATCH_BY_ORDER_SUCCESS';
export const UPDATE_BATCH_BY_ORDER_FAILED = 'UPDATE_BATCH_BY_ORDER_FAILED';

export const RESET_BATCH_STATE = 'RESET_BATCH_STATE';
