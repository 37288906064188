import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getSupplierGroups,
  createSupplierGroup,
  deleteSupplierGroup,
  getSupplierGroup,
  resetSupplierGroupState,
  updateSupplierGroup
} from '~/redux/action';
import { STATUS } from '~/constants/defaultValue';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const SUPPLIER_GROUP_MODULE = 'supplierGroup';

const CREATE_SUCCESS_MESS = 'Tạo mới nhóm nhà cung cấp thành công';
const CREATE_FAILED_MESS = 'Tạo mới nhóm nhà cung cấp thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật nhóm nhà cung cấp thành công';
const UPDATE_FAILED_MESS = 'Cập nhật nhóm nhà cung cấp thất bại';
const DELETE_SUCCESS_MESS = 'Xoá nhóm nhà cung cấp thành công';
const DELETE_FAILED_MESS = 'Xoá nhóm nhà cung cấp thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(SUPPLIER_GROUP_MODULE);

export const useSupplierGroups = query => {
  return useFetchByParam({
    action: getSupplierGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateSupplierGroup = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSupplierGroup
  });
};

export const useUpdateSupplierGroup = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSupplierGroup
  });
};

export const useDeleteSupplierGroup = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteSupplierGroup
  });
};

export const useSupplierGroup = params => {
  return useFetchByParam({
    action: getSupplierGroup,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitSupplierGroup = (supplierGroup, id) => {
  return useMemo(() => {
    if (!supplierGroup || !id) {
      return { status: true };
    }

    return {
      ...supplierGroup,
      status: supplierGroup.status === STATUS.ACTIVE ? true : false
    };
  }, [supplierGroup, id]);
};

export const useUpdateSupplierGroupParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/supplier-group',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useSupplierGroupQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page') || 1;
  const status = query.get('status');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      status,
      keyword
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, updateSuccess, deleteSuccess, status]);
};

export const useSupplierGroupPaging = () => useSelector(pagingSelector);

export const useResetSupplierGroup = () => {
  useResetState(resetSupplierGroupState);
};
