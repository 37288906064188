import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getProductOrders({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const productOrder = yield call(Api.productOrder.getById, query.id);
      data = { docs: [productOrder], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.productOrder.getAll, query);
    }

    yield put({ type: Types.GET_PRODUCT_ORDERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_ORDERS_FAILED,
      payload: error.message
    });
  }
}

function* getProductOrder({ payload }) {
  try {
    const productOrder = yield call(Api.productOrder.getById, payload);
    yield put({
      type: Types.GET_PRODUCT_ORDER_SUCCESS,
      payload: productOrder
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_ORDER_FAILED,
      payload: error.message
    });
  }
}

function* createProductOrder(action) {
  try {
    const data = yield call(Api.productOrder.create, action.payload);
    yield put({ type: Types.CREATE_PRODUCT_ORDER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PRODUCT_ORDER_FAILED,
      payload: error.message
    });
  }
}

function* updateProductOrder(action) {
  try {
    const data = yield call(Api.productOrder.update, action.payload);
    yield put({ type: Types.UPDATE_PRODUCT_ORDER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PRODUCT_ORDER_FAILED,
      payload: error.message
    });
  }
};

function* updateStatusProductOrderBillPM(action) {
  try {
    const data = yield call(Api.productOrder.updateStatus, action.payload);
    yield put({ type: Types.UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_FAILED,
      payload: error.message
    });
  }
}

function* deleteProductOrder({ payload }) {
  try {
    yield call(Api.productOrder.delete, payload);
    yield put({ type: Types.DELETE_PRODUCT_ORDER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PRODUCT_ORDER_FAILED,
      payload: error.message
    });
  }
}

export default function* productOrder() {
  yield takeLatest(Types.GET_PRODUCT_ORDERS_REQUEST, getProductOrders);
  yield takeLatest(Types.GET_PRODUCT_ORDER_REQUEST, getProductOrder);
  yield takeLatest(Types.DELETE_PRODUCT_ORDER_REQUEST, deleteProductOrder);
  yield takeLatest(Types.CREATE_PRODUCT_ORDER_REQUEST, createProductOrder);
  yield takeLatest(Types.UPDATE_PRODUCT_ORDER_REQUEST, updateProductOrder);
  yield takeLatest(Types.UPDATE_STATUS_PRODUCT_ORDER_BILL_PM_REQUEST, updateStatusProductOrderBillPM);
}
