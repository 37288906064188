import { useMemo, useState } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useQueryParams,
  useFetch
} from '~/hooks/utils';
import { getInformationPayment } from '~/redux/worldClinic/imformationPayment/actions';
import { head } from 'lodash';
import { updateInformationPayment } from '~/redux/action';

const getSelector = key => state => state.imformationPayment[key];
const loadingSelector = getSelector('isLoading');
const dataImformationPaymentSelector = getSelector('list');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const getListFailed = getSelector('getListFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useInfoPaymentQueryParams = () => {
    const query = useQueryParams();
    
    const [page, setPage] = useState(query.get('page') || 1);
    const onTableChange = ({ current }) => setPage(current);

      if (page !== 1) {
        setPage(1);
      }
    return useMemo(() => {
      const queryParams = {
      };
      return [queryParams, onTableChange];
    }, []);
  };
  export const useInformationPayment = query => {
    return useFetch({
      action: getInformationPayment,
      loadingSelector,
      dataSelector: dataImformationPaymentSelector,
      failedSelector: getListFailed,
    });
  };
  export const useInitInformationPayment = params =>
     useMemo(()=> head(params) ?? { description: [],image:''},[params])
  ;

  
  export const useUpdateInfoPayment = () => {
    useSuccess(updateSuccessSelector,'Cập nhật thông tin thành công');
    useFailed(updateFailedSelector,'Cập nhật thông tin thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateInformationPayment
    });
  };
  