import React, { useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  useProductInvoices,
  useDeleteProductInvoice,
  useProductInvoiceQueryParams,
  useUpdateProductInvoiceParams,
  useResetProductInvoice,
  useProductInvoicePaging,
  useProductInvoiceColumnSetting,
  SEARCH_PRODUCTINVOICE
} from '~/hooks';

import { Table, Popconfirm, Input, Button, Dropdown, Tabs } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE, PAYMENT_METHOD_DICT } from '~/constants/defaultValue';
import ProductInvoiceInfo from './Detail/Information';
import PaymentHistory from '~/components/WorldClinic/ProductInvoice/PaymentHistory';
import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined
} from '@ant-design/icons';
import { exportCSV, formatCurrency, formatDate } from '~/utils/helper';
import './index.scss';
import { get } from 'lodash';
import { connect } from 'react-redux';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import { onSelectRow } from '~/utils/validators';

const { Search } = Input;
const { TabPane } = Tabs;

const ColumnActions = ({ _id, deleteProductInvoice }) => {
  return (
    <div className="custom-table__actions">
      <Link to={`/product-invoice/edit/${_id}`}> Sửa</Link>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá hoá đơn này?"
        onConfirm={() => deleteProductInvoice(_id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const ProductInvoice = ({summary}) => {
  const [query] = useProductInvoiceQueryParams();

  const [
    keyword,
    { setKeyword, onParamChange }
  ] = useUpdateProductInvoiceParams(query);
  const [selectTable, setSelectTable]=useState([])

  const [productInvoices, isLoading] = useProductInvoices(query);

  const paging = useProductInvoicePaging();

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = productInvoices.length === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteProductInvoice] = useDeleteProductInvoice(onDeleteSuccess);

  useResetProductInvoice();

  const [columnMenu, selectedColumnKeys] = useProductInvoiceColumnSetting();

  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Mã hóa đơn',
        key: 'code',
        dataIndex: 'code'
      },
      {
        title: 'Thời gian',
        key: 'time',
        render: rc => !rc.isSummaryRow && formatDate(rc.purchasedAt)
      },
      {
        title: 'Khách hàng',
        key: 'customer',
        dataIndex: 'customer',
        render: customer =>`${customer?.name??"" } ${ customer?.phoneNumber ? "- "+ get(customer,'phoneNumber',""):""}`
      },
      {
        title: 'Tổng tiền hàng',
        key: 'total',
        align: 'end',
        render: rc => formatCurrency(rc.total)
      },
      {
        title: 'Phương thức',
        key: 'payment',
        align: 'end',
        dataIndex:['paymentNotes'],
        render: rc => PAYMENT_METHOD_DICT?.[get(rc[0],'paymentMethod','CASH')]
      },
      {
        title: 'Giảm giá',
        key: 'discountValue',
        align: 'end',
        render: rc => formatCurrency(rc.discountValue)
      },

      {
        title: 'Khách cần trả',
        key: 'debt',
        align: 'end',
        render: rc => formatCurrency(rc.total - rc.discountValue)
      },

      {
        title: 'Khách đã trả',
        key: 'totalPayment',
        align: 'end',
        render: rc => {
          return formatCurrency(rc.totalPayment);
        }
      },

      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        align: 'center'
      },

      {
        title: 'Thao tác',
        key: 'action',
        width: '110px',
        render: record => {
          return (
            !record.isSummaryRow && (
              <ColumnActions
                {...record}
                deleteProductInvoice={deleteProductInvoice}
              />
            )
          );
        }
      }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [deleteProductInvoice, selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const exportCsvData = useCallback(() => {
    const columnNames = columns.filter(({ key }) => key !== 'actions');
    exportCSV({
      columns: columnNames,
      data: productInvoices,
      fileName: 'Hoá đơn'
    });
  }, [columns, productInvoices]);

  const expandedRowRender = useCallback(record => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <ProductInvoiceInfo
              record={record}
              deleteProductInvoice={deleteProductInvoice}
            />
          </TabPane>
          <TabPane tab="Lịch sử thanh toán" key="2">
            <PaymentHistory record={record} />
          </TabPane>
        </Tabs>
      </div>
    );
  }, [deleteProductInvoice]);

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Hoá đơn</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_PRODUCTINVOICE}/>
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_BILL}>
              <Link to="sale">
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: 10 }}
                >
                  <PlusOutlined />
                  Thêm mới
                </Button>
              </Link>
              <ButtonExportOption 
                router={API_LIST.productInvoice} 
                label="Hóa đơn" 
                query={query} 
                fileName={["Danhsachtongquanhoadon_",'Danhsachchitiethoadon_']} />

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={productInvoices.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                size="middle"
                rowClassName={record =>
                  record.isSummaryRow ? 'summary-row' : ''
                }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={productInvoices}
                onChange={({ current,pageSize }) => onParamChange({ page: current ,limit :pageSize})}
                // summary={()=>(<Table.Summary fixed={"top"} >
                //                   <Table.Summary.Row key={-1} >
                //                   <Table.Summary.Cell align='right'colSpan={5} ><strong>{summary?.total?.toLocaleString()}</strong></Table.Summary.Cell>
                //                       <Table.Summary.Cell align='right' ><b>
                //                         {summary?.discountValue?.toLocaleString()}
                //                       </b>
                //                         </Table.Summary.Cell> 
                //                       <Table.Summary.Cell align='right'><strong>{summary?.totalPayment?.toLocaleString()}</strong></Table.Summary.Cell>
                //                   </Table.Summary.Row>
                //             </Table.Summary>)}
                pagination={{
                  ...paging,
                  // pageSize:paging.pageSize,
                  showSizeChanger: true, 
                  // pageSizeOptions: ['11', '21', '31', '51' ,'101','1001','10000','100000'],
                  showTotal: () => `Tổng cộng: ${paging?.total} `
                }}
                onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}

                onRow={onSelectRow(setSelectTable)}

                expandable={{
                  expandedRowRender,
                  rowExpandable: record => !record.isSummaryRow,
                  defaultExpandAllRows: !!query.id,
                  expandedRowKeys:[selectTable]
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState =(state)=> ({
  summary: state.productInvoice.summary
})
export default connect(mapState)(ProductInvoice) ;
