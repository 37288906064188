import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Row, Space } from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithOrPermission from '~/components/Common/WithOrPermission';
import UploadImage from '~/components/Utils/UploadImage';
import { BASE_URL, BASE_URL_UPLOAD_IMAGE } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchOrPolicy, useMatchPolicy } from '~/hooks';
import { useInformationPayment, useInitInformationPayment, useUpdateInfoPayment } from '~/hooks/worldClinic/imformationPayment';

const FormItem = Form.Item;

function ImformationPayment() {
    const isMatchPolicyUpdate = useMatchPolicy(POLICIES.UPDATE_INFOPAYMENT)
    const isMatchPolicyUAndW = useMatchOrPolicy([POLICIES.WRITE_INFOPAYMENT,POLICIES.UPDATE_INFOPAYMENT])
    const [form] = Form.useForm();
    const [image, setImage] = useState('');
    const [indexDisabled, setIndexDisabled] = useState(null)

    const [informationPaymentdata,] = useInformationPayment();
    const initInfoPayment = useInitInformationPayment(informationPaymentdata);
    const [updateLoading, handleUpdate] = useUpdateInfoPayment()
    useEffect(() => {
        form.setFieldsValue(initInfoPayment)
        if (get(initInfoPayment, 'image')) {
            setImage(get(initInfoPayment, 'image'))
        }
    }, [initInfoPayment, form])

    useEffect(() => {
        if (!isMatchPolicyUpdate) {
          setIndexDisabled(get(initInfoPayment, 'description.length'))
        }
      }, [isMatchPolicyUpdate, initInfoPayment])

    const onFinish = values => {
        handleUpdate(values)
    };

    return (
        <div className="page-wraper page-content__right">
            <div className="container-fluid" style={{ marginTop: '132px' }}>
                <Breadcrumb title="Cập nhật thông tin thanh toán" />
                <Space style={{ marginTop: 20 }}></Space>
                <Form
                    form={form}
                    autoComplete="off"
                    scrollToFirstError
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <FormItem name={'_id'} hidden='true'><Input /></FormItem>
                    <Row align='center' gutter={20}>
                        <Col align={'center'} lg={{ span: 6 }} md={{ span: 10 }} sm={10} span={20} xl={{ span: 6 }} >
                            <FormItem hidden={image} labelCol={{ span: 24 }} labelAlign='center' label="Thêm ảnh" name="image">
                                <UploadImage
                                    onChange={(e) => { setImage(e) }}
                                    imgUrl={image}
                                    action={BASE_URL_UPLOAD_IMAGE} // use in localHost
                                    disabled={!isMatchPolicyUAndW}
                                >
                                </UploadImage>
                            </FormItem>
                            {
                                image &&
                                <Row gutter={16} align='middle'>
                                    <Col>
                                        <Image style={{ objectFit: 'contain' }} width={200} height={200} src={image} />
                                    </Col>
                                    <WithOrPermission permission={[POLICIES.DELETE_INFOPAYMENT, POLICIES.UPDATE_INFOPAYMENT]}>
                                    <Col>
                                        <Button type='dashed' htmlType='button' onClick={() => {
                                            setImage('')
                                            form.setFieldsValue({image : null})
                                        }} >Xóa</Button>
                                    </Col>
                                    </WithOrPermission>
                                </Row>
                            }
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={20} span={20} xl={{ span: 12 }} align="center">
                            <Form.List name="description">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {fields.map((field, index) => (
                                                <Row key={index} align='middle'>
                                                    <Col flex={1}>
                                                        <FormItem
                                                            style={{ marginBottom: 5 }}
                                                            name={[index]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: 'Vui lòng nhập hoặc xoá text.'
                                                                }
                                                            ]}>
                                                            <Input.TextArea disabled={index < indexDisabled} />
                                                        </FormItem>
                                                    </Col>
                                                    <WithOrPermission permission={[POLICIES.DELETE_INFOPAYMENT, POLICIES.UPDATE_INFOPAYMENT]}>
                                                        <Col span={2}>
                                                            {fields.length > 1 ? (
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(index)}
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    </WithOrPermission>
                                                </Row>
                                            ))}
                                            <Form.Item>
                                                <WithOrPermission permission={[POLICIES.WRITE_INFOPAYMENT, POLICIES.UPDATE_INFOPAYMENT]}>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            return add('');
                                                        }}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Thêm text
                                                    </Button>
                                                </WithOrPermission>
                                            </Form.Item>
                                        </>
                                    );
                                }}
                            </Form.List>
                            <WithOrPermission permission={[POLICIES.WRITE_INFOPAYMENT, POLICIES.UPDATE_INFOPAYMENT , POLICIES.DELETE_INFOPAYMENT]}>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={updateLoading}
                                >
                                    Cập nhật
                                </Button>
                            </WithOrPermission>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default ImformationPayment
