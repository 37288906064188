import requester from '../requester';

const userEmployee = {
  getAll: (query) => requester.get(`/user`, query),
  getById: (id) => requester.get(`/user/${id}?raw=true`),
  create: (user) => requester.post(`/user`, user),
  update: (user) => requester.put(`/user/${user._id}`, user),
  delete: (id) => requester.delete(`/user/${id}`),
  autoUsername: (username) => requester.post(`/user/valid-user`, { fullName: username })
};

export default userEmployee;
