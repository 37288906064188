import { Button, Modal, Row } from 'antd';
import React from 'react';
import { PAYER_LOGISTIC } from '~/constants/defaultValue';
import { useSelectedSale } from '~/hooks';
import { formatNumber } from '~/utils/helper';
import Logistic from './Logistic';

export default function InfoDelivery({ data }) {
  const [selectedSale] = useSelectedSale();
  const [isOpen, setIsOpen] = React.useState(false);
  const openForm = () => {
    setIsOpen(true);
  };
  const closeForm = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Row
        className="warehouse-form__row"
        style={{
          marginTop: 20,
          fontSize: '14px',
          fontWeight: '500',
          background: '#eff2f5',
          padding: '5px 10px'
        }}
      >
        Xuất hàng
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Tên khách hàng:
        </label>
        <label>
          {data?.customerName}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Số điện thoại:
        </label>
        <label>
          {data?.phoneNumber}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Địa chỉ giao hàng:
        </label>
        <label>
          {data?.address}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Đơn vị vận chuyển:
        </label>
        <label>
          {data?.deliveryTransport}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Người thanh toán:
        </label>
        <label>
          {PAYER_LOGISTIC[data?.payer]}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <Button type='link' style={{padding: 0}} onClick={openForm}>
          Phí vận chuyển:
        </Button>
        <label>
          {formatNumber(data?.logisticFee)}
        </label>
      </Row>
      <Row className="warehouse-form__row">
        <label>
          Giá trị đơn hàng:
        </label>
        <label>
          {!data?.payer ? formatNumber(data?.total) :
            (data?.payer === PAYER_LOGISTIC?.SYSTEM ? formatNumber(selectedSale?.totalPriceFromPM)
              : formatNumber(selectedSale?.totalPriceFromPM - data?.logisticFee))}
        </label>
      </Row>
      <Modal
        title='Phí vận chuyển'
        visible={isOpen}
        onOk={closeForm}
        onCancel={closeForm}
        footer={null}
        destroyOnClose
        width={900}
      >
        <Logistic data={data} totalPrice={selectedSale?.totalPriceFromPM} id = {selectedSale?.saleOrderId} closeForm={closeForm}/>
    </Modal>
    </>
  );
}
