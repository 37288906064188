import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
} from '~/hooks/utils';

import {
  getEmployees,
  createEmployee,
  deleteEmployee,
  getEmployee,
  resetEmployeeState,
  updateEmployee,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import moment from 'moment';

const EMPLOYEE_MODULE = 'employee';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
} = getSelectors(EMPLOYEE_MODULE);

const getSelector = key => state => state[EMPLOYEE_MODULE][key];
const pagingSelector = getSelector('paging');

export const useEmployees = query => {
  return useFetchByParam({
    action: getEmployees,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query,
  });
};

export const useCreateEmployee = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới nhân viên thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createEmployee,
  });
};

export const useUpdateEmployee = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật nhân viên thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateEmployee,
  });
};

export const useDeleteEmployee = onDeleteSuccess => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá nhân viên thành công',
    onDeleteSuccess,
  );
  useFailed(deleteFailedSelector, 'Xoá nhân viên thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteEmployee,
  });
};

export const useEmployee = params => {
  return useFetchByParam({
    action: getEmployee,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params,
  });
};

export const useUpdateEmployeeParams = query => {
  const history = useHistory();
  const { id: branchId } = useParams();
  const pathName = branchId
    ? `/branch/detail/${branchId}/employee`
    : '/employee';

  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

    history.push({
      pathName,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          branchId,
        }),
      ).toString(),
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useEmployeeQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const modules = query.get('modules');
  const page = query.get('page') || 1;

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId,
      modules,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    modules,
    createSuccess,
    updateSuccess,
    deleteSuccess,
  ]);
};

export const useEmployeePaging = () => useSelector(pagingSelector);

export const useInitEmployee = (employee, id) => {
  return useMemo(() => {
    if (!employee || !id) {
      return {
        certification: [
          {
            name: '',
            certifiedBy: '',
            certifiedAt: moment(),
          },
        ],
        employeeHistory: [
          {
            location: '',
            role: '',
            startTime: moment(),
            endTime: moment(),
          },
        ],
      };
    }

    const initValues = {
      ...employee,
      certification: employee.certification.map(cer => ({
        ...cer,
        certifiedAt: moment(cer.certifiedAt),
      })),

      degree: {
        degreeId: employee.degree?.degreeId,
        issuedAt: moment(employee.degree?.issuedAt),
      },

      employeeHistory: employee.employeeHistory?.map(history => ({
        ...history,
        startTime: moment(history.startTime),
        endTime: moment(history.endTime),
      })),
    };

    return initValues;
  }, [employee, id]);
};

export const useResetEmployee = () => {
  useResetState(resetEmployeeState);
};
