import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getPartnerProduct({ payload: partnerId }) {
  try {
    const data = yield call(Api.medicine.getPartner, partnerId);
    yield put({
      type: Types.GET_PRODUCT_PARTNER_SUCCESS,
      payload: { partnerId, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_PARTNER_FAILED,
      payload: error
    });
  }
}

export default function* productReport() {
  yield takeLatest(Types.GET_PRODUCT_PARTNER_REQUEST, getPartnerProduct);
}
