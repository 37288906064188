import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getProcessImports({ payload: query }) {
  try {
    const data = yield call(Api.processImport.getAll, query);
    yield put({ type: Types.GET_PROCESSES_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PROCESSES_IMPORT_FAILED,
      payload: error
    });
  }
}

function* getProcessImport({ payload: id }) {
  try {
    const data = yield call(Api.processImport.getById, id);
    yield put({ type: Types.GET_PROCESS_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_PROCESS_IMPORT_FAILED, payload: error });
  }
}

function* createProcessImport(action) {
  try {
    const data = yield call(Api.importFileMedicine.postFile, action.payload);
    yield put({ type: Types.CREATE_PROCESS_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PROCESS_IMPORT_FAILED,
      payload: error
    });
  }
}

function* updateProcessImport(action) {
  try {
    const data = yield call(Api.processImport.update, action.payload);
    yield put({ type: Types.UPDATE_PROCESS_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PROCESS_IMPORT_FAILED,
      payload: error
    });
  }
}

function* deleteProcessImport({ payload }) {
  try {
    yield call(Api.processImport.delete, payload);
    yield put({ type: Types.DELETE_PROCESS_IMPORT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PROCESS_IMPORT_FAILED,
      payload: error
    });
  }
}

export default function* processImport() {
  yield takeLatest(Types.GET_PROCESSES_IMPORT_REQUEST, getProcessImports);
  yield takeLatest(Types.GET_PROCESS_IMPORT_REQUEST, getProcessImport)
  yield takeLatest(Types.DELETE_PROCESS_IMPORT_REQUEST, deleteProcessImport);
  yield takeLatest(Types.CREATE_PROCESS_IMPORT_REQUEST, createProcessImport);
  yield takeLatest(Types.UPDATE_PROCESS_IMPORT_REQUEST, updateProcessImport);
}
