import React, { useState, useMemo } from 'react';
import {
  useMedicines,
  useDeleteMedicine,
  useMedicineQueryParams,
  useUpdateMedicineParams,
  useResetMedicine,
  useMedicinePaging,
  useMedicineColumnSetting,
  useSelectVariant,
  SEARCH_MEDICINE
} from '~/hooks';
import {
  Table,
  Input,
  Button,
  Dropdown,
  Tabs,
  Menu,
  Col,
  Row,
  Radio,
  Space,
  Typography,
} from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE, PRODUCT_TYPES, PRODUCT_TYPE_NAMES } from '~/constants/defaultValue';
import MedicineForm from './Form/index';
import MedicineInfo from './Detail/Information';
import Lot from './Detail/Lot';
import Stock from './Detail/Stock';
import StockCardOut from './Detail/StockCardOut';
import MedicineFormOutList from './FormOutList/FormOutList';
import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { formatNumber } from '~/utils/helper';
import './index.scss';
import SearchMulti from '~/components/Common/SearchMulti';
import { get } from 'lodash';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import FormImportFile from '~/components/Common/FormInportFile/FormImportFile';
import { onSelectRow } from '~/utils/validators';
import DrugRequestLogs from './Detail/DrugRequestLogs';
const { Search } = Input;
const { TabPane } = Tabs;
const arrField = [
  { field: 'name', placeholder: 'Theo tên hàng' },
  { field: 'registrationNo', placeholder: 'Theo số đăng ký' },
  { field: 'productCode', placeholder: 'Theo mã thuốc' },
  { field: 'lotNumber', placeholder: 'Theo Lô' },
  { field: 'expirationDate', placeholder: 'Theo hạn sử dụng' },
  { field: 'description', placeholder: 'Theo ghi chú' },
  { field: 'noteTemplate', placeholder: 'Theo mô tả đặt hàng' },
  { field: 'ingredient', placeholder: 'Theo hoạt chất' },
  { field: 'dosage', placeholder: 'Theo hàm lượng' },
  { field: 'manufacturer', placeholder: 'Theo hãng sản xuất' },
];
const Medicine = () => {
  // const [openPopover, setOpenPopover] = useState(false);
  const [query] = useMedicineQueryParams();
  const [isFormSearchOpen, setIsFormSearchOpen] = useState(false);
  const [itemActive, setItemActive] = useState(null);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateMedicineParams(query);
  const [medicines, isLoading] = useMedicines(query);
  
  const paging = useMedicinePaging();
  const [medicineId, setMedicineId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormOutList, setIsOpenFormOutList] = useState(false);

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = medicines.length === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteMedicine] = useDeleteMedicine(onDeleteSuccess);

  useResetMedicine();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onOpenForm = id => {
    setMedicineId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setMedicineId(null);
    setIsOpenForm(false);
  };

  const onOpenFormOutList = (id,category) => {
    setMedicineId(id);
    if(category==='MEDICINE'){
      setIsOpenForm(true);
      return
    }
    setIsOpenFormOutList(true);
  };

  const onCloseFormOutList = () => {
    setMedicineId(null);
    setIsOpenFormOutList(false);
  };

  const [columnMenu, selectedColumnKeys] = useMedicineColumnSetting();

  const [, onSelectVariant] = useSelectVariant();

  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Mã hàng',
        key: 'productCode',
        dataIndex: ['selectedVariant', 'variantCode'],
        render: (text, record) => {
          return (
            <div>
              {' '}
              {record.productDetail?.registrationNo === '' ? (
                `${text??''}`
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '6px' }}>{text??''}</span>{' '}
                  <HomeOutlined />
                </div>
              )}
            </div>
          );
        }
      },

      {
        title: 'Tên hàng',
        key: 'name',
        render: (medicine, _) => {
          return (
            <div className="product-table__name">
              <span>{medicine.name}</span>
              {medicine.productVariants.length <= 1 ? (
                <span>
                  {' '}
                  {`(${medicine.productVariants[0]?.unitId?.name??''})`}
                </span>
              ) : (
                <Dropdown
                  overlay={
                    <Menu>
                      {medicine.productVariants
                        .filter(
                          ({ _id }) => _id !== get(medicine,['selectedVariant','_id'],'')
                        )
                        .map(({ _id, unitId }) => {
                          return (
                            <Menu.Item
                              onClick={(e) =>{
                                // e.preventDefault();
                                e.domEvent.stopPropagation()
                                onSelectVariant({
                                  productId: medicine._id,
                                  variantId: _id
                                })}
                              }
                              style={{ minWidth: 120 }}
                              key={_id}
                            >
                              {unitId?.name}
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <span
                    className="product-table__selected-variant"
                    onClick={e => e.stopPropagation()}
                  >
                    - {medicine?.selectedVariant?.unitId?.name}
                    <DownOutlined />
                  </span>
                </Dropdown>
              )}
            </div>
          );
        }
      },
      {
        title: 'Tên viết tắt',
        key: 'aliasName',
        dataIndex: 'aliasName'
      },
      {
        title: 'Loại Hàng hoá',
        key: 'type',
        render: _ => {
          if(_.productDetail?.registrationNo !== ''){
            return  PRODUCT_TYPE_NAMES['MEDICINE']
          }
          return PRODUCT_TYPE_NAMES['PRODUCT']
        
        }
      },
      {
        title: 'Giá vốn',
        key: 'cost',
        render: rc => {
          return formatNumber(rc?.selectedVariant?.cost);
        }
      },
      {
        title: 'Giá bán',
        key: 'price',
        render: rc => {
          return formatNumber(rc?.selectedVariant?.price);
        }
      },
      {
        title: 'Tồn kho',
        key: 'remain',
        align:"center",
        render: (rc, products) => {
          let numberRic = Number(rc.totalQuantity / get(rc,['selectedVariant','exchangeValue'],1))
          let value =  Number.isInteger(numberRic) ? numberRic.toString() : numberRic.toFixed(1)
          return Number(value).toLocaleString('vi-VN')
        }
      }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [selectedColumnKeys, onSelectVariant]);
const onChange =({target})=>{
   switch (target.value) {
    case 2:
      onParamChange({...query, category :PRODUCT_TYPES['MEDICINE']})
      break;
    case 3:
      onParamChange({...query, category :PRODUCT_TYPES['PRODUCT']})
      break;
    default:
      onParamChange({...query ,category:'' })
      break;
   }
   
}

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const expandedRowRender = product => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <MedicineInfo
              product={product}
              deleteMedicine={deleteMedicine}
              onOpenForm={onOpenFormOutList}
            />
          </TabPane>
          <TabPane tab="Thẻ kho" key="2">
            <StockCardOut product={product} />
          </TabPane>
          <TabPane tab="Tồn kho" key="3">
            <Stock product={product} />
          </TabPane>
          <TabPane tab="Lô - hạn sử dụng" key="4">
            <Lot product={product} keySearch={query} />
          </TabPane>
          <TabPane tab="Lịch sử liên thông" key="5">
            <DrugRequestLogs product={product} />
          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <>
      <div className="page-wraper page-content">
        <div className="container-fluid">
          <div className="wc-page-header">
            <h1 className="wc-page-header__title ">Hàng hoá</h1>

            <div className="wc-page-header__search wclinic-search">
              <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_MEDICINE}/>
            </div>

            <div className="wc-page-header__actions">
              {' '}
              <WithPermission permission={POLICY.WRITE_PRODUCT}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key={'0'}>
                        <Button
                          type="primary"
                          style={{
                            float: 'right',
                            width: '100%',
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          onClick={() => onOpenForm()}
                        >
                          <PlusOutlined />
                          Thêm mới thuốc
                        </Button>
                      </Menu.Item>
                      <Menu.Item key={'1'}>
                        <Button
                          type="primary"
                          style={{
                            float: 'right',
                            width: '100%',
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          onClick={() => onOpenFormOutList()}
                        >
                          <PlusOutlined />
                          Thêm mới hàng hóa
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button onClick={e => e.preventDefault()} type="primary" style={{marginRight: '10px'}}>
                    Thêm mới
                    <DownOutlined />
                  </Button>
                </Dropdown>

                <ButtonExport
                  router={API_LIST.product}
                  label="Hàng hóa"
                  query={query}
                  exportOption={1}
                  fileName={'Danhsachhanghoa'}
                />
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={showModal}
                >
                  {' '}
                  Import File
                </Button>
                <Dropdown
                  onVisibleChange={flag => setShowSettingColumn(flag)}
                  visible={isShowSettingColumn}
                  overlay={columnMenu}
                  trigger={['click']}
                  style={{ float: 'right' }}
                >
                  <Button type="primary" onClick={e => e.stopPropagation()}>
                    <UnorderedListOutlined /> <DownOutlined />
                  </Button>
                </Dropdown>
              </WithPermission>
            </div>
          </div>
          <Space style={{marginBottom:20}}>
            <Typography style={{fontSize:18 , marginRight:20}}>
                Phân loại hàng hóa theo :
            </Typography>
            <Row gutter={20}>
            <Radio.Group onChange={onChange} 
                optionType="button"
                buttonStyle="solid"
                defaultValue={
                  (() => {
                    switch (query?.category) {
                      case "MEDICINE":
                        return 2;
                      case "PRODUCT":
                        return 3
                      default: return 1
                    }
                  })()
                }>
                    <Radio.Button value={1}>Tất cả</Radio.Button>  
                    <Radio.Button value={2}>{PRODUCT_TYPE_NAMES['MEDICINE']}</Radio.Button>  
                    <Radio.Button value={3}>{PRODUCT_TYPE_NAMES['PRODUCT']}</Radio.Button>  
                </Radio.Group>
            </Row>
          </Space>
          <div className="page-content__main">
            <div className="page-content__left hidden" style={CARD_STYLE} />
            <div className="page-content__right" style={CARD_STYLE}>
              {isLoading ? (
                <SkeletonTable
                  rowCount={medicines.length}
                  columns={columns}
                  pagination={paging}
                />
              ) : (
                <Table
                  className="table--selectable permission-table"
                  size="middle"
                  rowClassName={record =>
                    record.isSummaryRow ? 'summary-row' : ''
                  }
                  rowKey={rc => {
                    return rc._id ? rc._id : rc?.selectedVariant?._id;
                  }}
                  columns={columns}
                  dataSource={medicines}
                  onChange={({ current,pageSize }) => onParamChange({ page: current,limit : pageSize })}
                  pagination={{
                    ...paging,
                    showSizeChanger: true, 
                    pageSizeOptions: ['10', '20', '50' ,'100',paging?.total],
                    showTotal: total => `Tổng cộng: ${total} `
                  }}
                  // rowSelection={{
                  //   renderCell: (checked, record, index, originNode) =>
                  //     record.isSummaryRow ? <></> : originNode,
                  //   getCheckboxProps: record => ({
                  //     disabled: record.isSummaryRow,
                  //     name: record.name
                  //   })
                  // }}
                  onExpand={(expanded, record) => {
                    expanded ? setItemActive(record._id) : setItemActive(null);
                  }}
                  onRow={onSelectRow(setItemActive)}
                  expandable={{
                    expandedRowRender,
                    rowExpandable: record => !record.isSummaryRow,
                    expandedRowKeys: [itemActive]
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <MedicineForm
          isOpen={isOpenForm}
          onClose={onCloseForm}
          id={medicineId}
        />
        <MedicineFormOutList
          isOpen={isOpenFormOutList}
          onClose={onCloseFormOutList}
          id={medicineId}
        />
        <FormImportFile
          onModule={handleOk}
          isModalOpen={isModalOpen}
          onClose={handleCancel}
          query={query}
        />
      </div>
      <div
        onClick={() => setIsFormSearchOpen(!isFormSearchOpen)}
        style={{
          position: 'fixed',
          inset: 0,
          display: isFormSearchOpen ? 'block' : 'none'
        }}
      ></div>{' '}
    </>
  );
};

export default Medicine;
