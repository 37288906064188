import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import {
  FORM_PRINT_TYPES,
  FORM_PRINT_REDUCER_MAP
} from '~/constants/defaultValue';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  // deleteSuccess: null,
  // deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null,

  formPrints: [],
  isFormPrintsLoading: false,
  getFormPrintsFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_FORM_PRINTS_REQUEST:
      state.isFormPrintsLoading = true;
      state.getFormPrintsFailed = null;
      return;

    case Types.GET_FORM_PRINTS_SUCCESS:
      state.isFormPrintsLoading = false;
      state.formPrints = payload;
      return;

    case Types.GET_FORM_PRINTS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];

      return;

    // case Types.GET_FORM_PRINT_REQUEST:
    //   state.isGetByIdLoading = true;
    //   state.byId = null;
    //   state.getByIdFailed = null;
    //   return;

    // case Types.GET_FORM_PRINT_SUCCESS:
    //   state.isGetByIdLoading = false;
    //   state.byId = payload;
    //   return;

    // case Types.GET_FORM_PRINT_FAILED:
    //   state.isGetByIdLoading = false;
    //   state.getByIdFailed = payload;
    //   return;

    case Types.CREATE_FORM_PRINT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_FORM_PRINT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      const { key } = payload;
      const branch = FORM_PRINT_REDUCER_MAP[key];
      state[branch] = state[branch].concat(payload);
      return;

    case Types.CREATE_FORM_PRINT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_FORM_PRINT_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_FORM_PRINT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_FORM_PRINT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    // case Types.DELETE_FORM_PRINT_REQUEST:
    //   state.isLoading = true;
    //   state.deleteSuccess = null;
    //   state.deleteFailed = null;
    //   return;

    // case Types.DELETE_FORM_PRINT_SUCCESS:
    //   state.deleteSuccess = payload;
    //   return;

    // case Types.DELETE_FORM_PRINT_FAILED:
    //   state.isLoading = false;
    //   state.deleteFailed = payload;
    //   return;

    case Types.RESET_FORM_PRINT_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
