import requester from './requester';

const medicineUnit = {
  getAll: (query) => requester.get('/product-unit', query),
  getById: id => requester.get(`/product-unit/${id}`),
  create: unit => requester.post(`/product-unit`, unit),
  update: unit => requester.put(`/product-unit/${unit._id}`, unit),
  delete: id => requester.delete(`/product-unit/${id}`)
};

export default medicineUnit;
