import React, { useState, memo, useEffect } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Skeleton
} from 'antd';
import { filterAcrossAccents } from '~/hooks/utils';
import {
  useAppointment,
  useInitAppointment,
  useUpdateAppointment,
  useCreateAppointment,
  useResetAppointment
} from '~/hooks/worldCare/appointment';
import moment from 'moment';
import { useHospitals, useHospitalAvailableTimes } from '~/hooks/worldCare/hospital';

const { Option } = Select;
const FormItem = Form.Item;

const FORMAT_DATE = 'YYYYMMDD';

export const AppointmentForm = ({
  specialityOptions,
  appointmentId,
  onClose,
  initStartTime
}) => {
  const [form] = Form.useForm();
  const [appointment, isLoading] = useAppointment(appointmentId);

  const [selectedSpecialityId, setSelectedSpecialityId] = useState({
    specialityId: null
  });
  const [hospitals, isHospitalLoading] = useHospitals(selectedSpecialityId);

  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [availableTimes, isAvailableTimesLoading] = useHospitalAvailableTimes(
    selectedHospitalId
  );

  const initAppointment = useInitAppointment(
    form,
    appointment,
    appointmentId,
    initStartTime,
    setSelectedHospitalId,
    setSelectedSpecialityId
  );

  useEffect(() => {
    if (hospitals?.length === 1) {
      const hospitalId = hospitals?.[0]._id;
      setSelectedHospitalId(hospitalId);
      form.setFieldsValue({ hospitalId });
    }
  }, [hospitals, form]);

  const [availableTimesInDay, setAvailableTimesInDay] = useState([]);

  const [isSubmitLoading, handleUpdate] = useUpdateAppointment(onClose);

  useResetAppointment();

  const createSuccesCallback = () => {
    form.resetFields();
    onClose();
  };

  const [, handleCreate] = useCreateAppointment(createSuccesCallback);

  const onValuesChange = ({ specialityId, hospitalId, date }) => {
    if (specialityId) {
      setSelectedSpecialityId({ specialityId });
      form.setFieldsValue({
        hospitalId: null,
        date: null,
        time: null
      });
    }

    if (hospitalId) {
      setSelectedHospitalId(hospitalId);
      form.setFieldsValue({
        date: null,
        time: null
      });
    }

    if (date) {
      setAvailableTimesInDay(availableTimes[date.format(FORMAT_DATE)]);
      form.setFieldsValue({
        time: null
      });
    }
  };

  const onFinish = values => {
    const localTime = values.date.format('YYYY-MM-DD') + 'T' + values.time;
    const utcTime = moment(localTime, 'YYYY-MM-DDTHH:mm');

    if (appointmentId) {
      handleUpdate({ ...values, time: utcTime, id: appointmentId });
    } else {
      handleCreate({ ...values, time: utcTime });
    }
  };

  const renderInput = InputComponent =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      className="appointment-form"
      requiredMark={false}
      initialValues={initAppointment}
      onValuesChange={onValuesChange}
    >
      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Tên Khách hàng"
            name={['customer', 'name']}
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập tên!'
              }
            ]}
          >
            {renderInput(<Input />)}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Số điện thoại"
            name={['customer', 'phoneNumber']}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]{10,11}$/),
                message: 'Xin vui lòng nhập đúng số điện thoại!'
              }
            ]}
          >
            {renderInput(<Input />)}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem label="Nguồn" name="source">
            {renderInput(
              <Select>
                <Option value="CALL" key="CALL">
                  Gọi điện
                </Option>
                <Option value="WEBSITE" key="WEBSITE">
                  Website
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem label="Trạng thái" name="status">
            {renderInput(
              <Select>
                <Option value="CREATED" key="CREATED">
                  Đã tạo
                </Option>
                <Option value="CONFIRMED" key="CONFIRMED">
                  Đã xác nhận
                </Option>
                <Option value="CANCEL" key="CANCEL">
                  Đã huỷ
                </Option>
                <Option value="ARRIVED" key="ARRIVED">
                  Đã hoàn thành
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Dịch vụ"
            name="specialityId"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn dịch vụ!'
              }
            ]}
          >
            {renderInput(<Select showSearch>{specialityOptions}</Select>)}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Phòng khám"
                name="hospitalId"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn phòng khám!'
                  }
                ]}
              >
                {renderInput(
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    loading={isHospitalLoading}
                    disabled={
                      !form.getFieldValue('specialityId') || isHospitalLoading
                    }
                  >
                    {hospitals.map(({ hospitalName, id }) => (
                      <Option value={id} key={id}>
                        {hospitalName}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Ngày"
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ngày!'
                  }
                ]}
                shouldUpdate
              >
                {renderInput(
                  <DatePicker
                    allowClear={false}
                    format="DD-MM-YYYY"
                    span={12}
                    loading={isAvailableTimesLoading}
                    disabled={!form.getFieldValue('hospitalId')}
                    disabledDate={current =>
                      current && !availableTimes[current.format(FORMAT_DATE)]
                    }
                  />
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Giờ"
                name="time"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn giờ!'
                  }
                ]}
              >
                {renderInput(
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    loading={isHospitalLoading}
                    disabled={!form.getFieldValue('date')}
                  >
                    {availableTimesInDay &&
                      availableTimesInDay.map(time => (
                        <Option value={time} key={time}>
                          {time}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <FormItem name="message" label="Lời nhắn">
            <Input.TextArea rows={3}></Input.TextArea>
          </FormItem>
        </Col>
      </Row>

      <Row justify="end">
        <Button style={{ marginRight: 20 }} onClick={onClose}>
          Huỷ
        </Button>

        <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
          {appointmentId ? 'Cập nhật' : 'Tạo mới'}
        </Button>
      </Row>
    </Form>
  );
};

export default memo(AppointmentForm);
