import React, { useEffect, useCallback } from 'react';
import { Form, Input, Row, Button, Skeleton } from 'antd';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateStaffGroup,
  useStaffGroup,
  useInitStaffGroup,
  useResetStaffGroup,
  useUpdateStaffGroup
} from '~/hooks/worldCare/staffGroup';
import { useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';

const FormItem = Form.Item;

const StaffGroupForm = () => {
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreateStaffGroup();
  const [, handleUpdate] = useUpdateStaffGroup();

  const { id } = useParams();
  const [staffGroup, isLoading] = useStaffGroup(id);
  const initStaffGroup = useInitStaffGroup(staffGroup);

  useEffect(() => {
    form.resetFields();
  }, [initStaffGroup, form]);

  useResetStaffGroup();

  const onFinish = useCallback(
    (values) => {
      if (id) {
        handleUpdate({ ...values, id });
      } else {
        handleCreate(values);
      }
    },
    [handleCreate, handleUpdate, id]
  );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="hospital-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} nhóm nhân viên`}
          routes={[
            {
              path: PATH_APP.config.staffGroup,
              title: 'Danh sách nhóm nhân viên'
            }
          ]}
        />

        <Form
          form={form}
          labelCol={{ sm: 24, md: 7, lg: 5, xl: 4 }}
          wrapperCol={{ sm: 24, md: 17, lg: 19, xl: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initStaffGroup}
        >
          <FormItem
            label="Tên nhóm nhân viên (vi)"
            name={['name', LANGUAGE.VI]}
          >
            {renderInput(<Input />)}
          </FormItem>

          <FormItem
            label="Tên nhóm nhân viên (en)"
            name={['name', LANGUAGE.EN]}
          >
            {renderInput(<Input />)}
          </FormItem>
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.config.staffGroup}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default StaffGroupForm;
