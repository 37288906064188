import React from 'react';
import { useMatchPolicy } from '~/hooks';

const WithPermission = ({ children, permission }) => {
  const isMatchPolicy = useMatchPolicy(permission);

  return <>{isMatchPolicy && children}</>;
};

export default WithPermission;
