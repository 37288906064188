import React from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useNewsCategories,
  useDeleteNewsCategory,
  useNewsCategoryQueryParams
} from '~/hooks/worldCare/newsCategory';
import { Table, Popconfirm, Button } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import Breadcrumb from '~/components/Common/Breadcrumb';

const ColumnActions = ({ id, deleteNewsCategory }) => {
  return (
    <div className="custom-table__actions">
      <Link to={`/news-category/edit/${id}`}>
        <p>Sửa</p>
      </Link>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá danh mục tin tức này?"
        onConfirm={() => deleteNewsCategory(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const NewsCategories = () => {
  const query = useNewsCategoryQueryParams();
  const [newsCategories, isLoading] = useNewsCategories(query);
  const [, deleteNewsCategory] = useDeleteNewsCategory();

  const columns = [
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Đường dẫn',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'mô tả',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions {...record} deleteNewsCategory={deleteNewsCategory} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách danh mục tin tức" />

        <div className="page-wraper__header">
          <Link to={PATH_APP.newsCategory.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div>

        {isLoading ? (
          <SkeletonTable rowCount={newsCategories.length} columns={columns} />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={newsCategories}
            size="middle"
            pagination={{
              total: newsCategories.length,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NewsCategories;
