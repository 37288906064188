import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { formatCurrency, formatNumber } from '~/utils/helper';
import { compact } from 'lodash';

const { Text } = Typography;

const DetailTable = ({ sale }) => {
  const { saleOrderDetail } = sale;
  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'code',
        render: record => {
          return <Link to="#">{record.productVariant?.variantCode}</Link>;
        },
        width: 150
      },
      {
        title: 'Tên hàng',
        key: 'name',
        width: 400,
        render: rc =>{           
          const lotArea =[compact(rc?.batch?.batchSearch).join(" - ")]
          // const lotArea =rc.batch?.reduce((current,hint)=>([...current,compact(hint?.batchSearch).join(" - ")]),[]) ?? [compact(rc?.batch?.batchSearch).join(" - ")]
         return (
         <>
             {rc?.product?.name}
             {lotArea && <div style={{marginTop:4,display:"flex",flexWrap:"wrap"}}>{lotArea?.map((e)=><span style={{backgroundColor:"#3d7ff3",padding:3,borderRadius:2 ,marginRight :10 ,color:'white'}}>{e}</span>) }</div>}
          </>)
        }
                   
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'quantity',
        align: 'right'
      },
      {
        title: 'Đơn giá',
        key: 'cost',
        render: record => {
          return formatCurrency(record.cost);
        },
        align: 'right'
      },
      {
        title: 'Giảm giá',
        key: 'discount',
        render: record => {
          return formatCurrency(record.discountValue);
        },
        align: 'right'
      },
      {
        title: 'Giá bán',
        key: 'sellPrice',
        render: record => {
          return formatCurrency(record.price);
        },
        align: 'right'
      },
      {
        title: 'Thành tiền',
        key: 'amount',
        render: record => {
          return formatCurrency(record.quantity * record.price);
        },
        align: 'right'
      }
    ];
  }, []);

  const summary = useMemo(() => {
    const dataSource = saleOrderDetail;
    const getTotal = (data, key) => {
      return data.reduce((total, record) => total + record[key], 0);
    };
    const totalPayment = dataSource.reduce((total, item) => {
      const payment = item.quantity * (item.price - item.discountValue);
      return total + payment;
    }, 0);

    const totalQuantity = getTotal(dataSource, 'quantity');

    const summaryData = [
      {
        label: 'Tổng số lượng:',
        value: formatNumber(totalQuantity)
      },
      {
        label: 'Tổng tiền hàng:',
        value: formatCurrency(totalPayment)
      },
      {
        label: 'Giảm giá phiếu đặt:',
        value: formatCurrency(sale.discountValue)
      },
      {
        label: 'Tổng cộng:',
        value: formatCurrency(totalPayment - sale.discountValue)
      },
      {
        label: 'Khách đã trả:',
        value: formatCurrency(sale?.customerPaid)
      }
    ];
    const summaryDataWithPharma = [
      {
        label: 'Tổng số lượng:',
        value: formatNumber(totalQuantity)
      },
      {
        label: 'Tổng cộng:',
        value: formatCurrency(sale?.customerNeedToPay)
      },
    ];

    return  (sale?.typeSale === 'PM' ? summaryDataWithPharma : summaryData).map(({ label, value }, index) => {
      return (
        <Table.Summary.Row key={index}>
          <Table.Summary.Cell colSpan={6} align="right">
            {label}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{value}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });
  }, [saleOrderDetail, sale]);

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        className="nested-table"
        columns={columns}
        dataSource={saleOrderDetail}
        size="small"
        pagination={false}
        rowKey={({ variantId }) => variantId}
        summary={() => {
          return (
            <Table.Summary className="table-summary">{summary}</Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default DetailTable;
