import React from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import InformationItem from '~/components/Common/InformationItem';
import moment from 'moment';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';

const LEFT_INFO = [
  {
    label: 'Mã khách hàng:',
    dataIndex: 'code'
  },
  {
    label: 'Tên khách',
    dataIndex: 'name'
  },
  {
    label: 'Mã số thuế:',
    dataIndex: 'taxIdentificationNumber'
  },
  {
    label: 'Nhóm khách hàng:',
    dataIndex: 'group'
  },
  {
    label: 'Email:',
    dataIndex: 'email'
  },
  {
    label: 'Facebook:',
    dataIndex: 'facebook'
  },
];

const RIGHT_INFO = [
  {
    label: 'Điện thoại:',
    dataIndex: 'phoneNumber'
  },
  {
    label: 'Tỉnh - Thành phố:',
    dataIndex: 'address',
    render: (_) => _['city']
  },
  {
    label: 'Quận - Huyện:',
    dataIndex: 'address',
    render: (_) => _['district']
  },
  {
    label: 'Phường - Xã:',
    dataIndex: 'address',
    render: (_) => _['ward']
  },
  {
    label: 'Ngày tạo:',
    dataIndex: 'createdAt',
    render: createdAt => {
      return moment(createdAt).format('DD/MM/YYYY')
    }
  }
];

const Information = ({ customer, deleteCustomer, onOpenForm }) => {
  return (
    <div className="medicine-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={4}>
          <div className="medicine-info-images">
            <div className="medicine-info-images__main-image">
              <img
                className="responsive-image"
                src="./No-Image.svg"
                alt="main"
              />
            </div>
          </div>
        </Col>

        <Col span={10}>
          <div className="medicine-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={customer[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={10}>
          <div className="medicine-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={customer[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Row className="medicine-info__actions" justify="end">
        <WithPermission permission={POLICIES.UPDATE_CUSTOMER}>
          <Button type="primary" onClick={() => onOpenForm(customer._id)}>
            {' '}
            <EditOutlined style={{ marginLeft: 0 }} /> Cập nhật
          </Button>
        </WithPermission>
        <WithPermission permission={POLICIES.DELETE_CUSTOMER}>
          <Popconfirm
            title="Bạn muốn xoá khách hàng này?"
            onConfirm={() => deleteCustomer(customer._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger">
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>{' '}
        </WithPermission>
      </Row>
    </div>
  );
};

export default Information;
