import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Image, Button, Modal } from 'antd';
import toastr from 'toastr';

import Api from '~/api';
import { useOrder } from '~/hooks';
import { orderAction } from '~/utils';
import OrderTotal from './OrderTotal';
import MedicineTable from './MedicineTable';
import OrderDetailsSkeleton from './OrderDetailsSkeleton';
import { OrderAssign, OrderShipping, OrderCancel } from './OrderActions';
import {
  ORDER_ACTIONS,
  ORDER_STATUS,
  ORDER_STATUS_NAME,
  ORDER_ACTION_NAME
} from '~/constants/defaultValue';

import './order-details.scss';

const { confirm } = Modal;

const OrderDetails = ({
  orderNumber,
  onRefreshList,
  setSelectedOrderNumber,
  editable = true,
  showAction = true
}) => {
  const [orderData, isLoading] = useOrder(orderNumber);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [order, setOrder] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [updatingId, setUpdatingId] = useState([]);

  useEffect(() => {
    if (orderData) {
      setDataSource(orderData.items);
      setOrder(orderData);
    } else {
      setOrder(null);
    }
    setUpdatingId([]);
  }, [orderData, orderNumber]);

  const onSubmit = (action) => {
    setCurrentAction(action);
    switch (action.name) {
      case ORDER_ACTIONS.PROCESS:
        actionHandle(Api.order.processOrder, null, action);
        break;
      case ORDER_ACTIONS.CONFIRM:
        actionHandle(Api.order.confirmOrder, null, action);
        break;
      case ORDER_ACTIONS.PACKAGE:
        actionHandle(Api.order.packageOrder, null, action);
        break;
      case ORDER_ACTIONS.ORDER_COMPLETION:
        actionHandle(Api.order.completeOrder, null, action);
        break;
      case ORDER_ACTIONS.REJECT:
        actionHandle(Api.order.rejectOrder, null, action);
        break;
      default:
        return;
    }
  };

  const actionHandle = (apiAction, values, action) => {
    confirm({
      title: `Chuyển trạng thái đơn hàng sang ${ORDER_STATUS_NAME[action.to]}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      cancelText: 'Hủy bỏ',
      onOk: async function () {
        return callAction(apiAction, values, action);
      }
    });
  };

  const callAction = async (apiAction, values, action) => {
    try {
      const { data } = await apiAction(orderNumber, values);
      toastr.success(
        `Đơn hàng đã được chuyển sang trạng thái ${
          ORDER_STATUS_NAME[action.to]
        }`
      );
      if (
        action.name === ORDER_ACTIONS.CANCEL ||
        action.name === ORDER_ACTIONS.ORDER_COMPLETION
      ) {
        setSelectedOrderNumber(null);
      } else {
        setOrder({ ...data, prescription: order.prescription });
        if (isLoadingTable) {
          setIsLoadingTable(false);
        }
      }
      onRefreshList();
    } catch (error) {
      toastr.error(error?.response?.data?.message ?? 'Something went wrong!');
    }
  };

  const assignHandle = (values) =>
    callAction(Api.order.assignOrder, values, currentAction);

  const shippingHandle = (values) =>
    callAction(Api.order.shippingOrder, values, currentAction);

  const cancelHandle = (values) =>
    callAction(Api.order.cancelOrder, values, currentAction);

  const onClose = () => setCurrentAction(null);

  const deleteMedicine = async (row) => {
    try {
      if (row._id === -1) {
        const newData = [...dataSource];
        setDataSource(newData.filter((item) => item._id !== row._id));
      } else {
        const item = { ...row };
        item.isDeleted = true;
        const { data } = await Api.order.updateItem(orderNumber, { item });
        /* keep the new record if editing */
        const newData = data.items;
        const newRecord = dataSource.find((item) => item._id === -1);
        if (newRecord) {
          newData.push(newRecord);
        }
        setDataSource(newData);
        setOrder({ ...data, prescription: order.prescription });
      }
    } catch (error) {
      toastr.error(error?.response?.data?.message ?? 'Something went wrong!');
    }
  };

  const saveMedicine = async (row) => {
    try {
      setUpdatingId((prev) => [...prev, row._id]);
      const newData = [...dataSource];
      const item = { ...row };
      const index = dataSource.findIndex((item) => item._id === row._id);
      if (item._id === -1) {
        setIsLoadingTable(true);
        delete item._id;
        const { data } = await Api.order.updateItem(orderNumber, { item });
        setUpdatingId((prev) => prev.filter((d) => d !== -1));
        setDataSource(data.items);
        setOrder({ ...data, prescription: order.prescription });
        setIsLoadingTable(false);
      } else {
        newData.splice(index, 1, { ...item });
        setDataSource(newData);
        const { data } = await Api.order.updateItem(orderNumber, { item });
        setOrder({ ...data, prescription: order.prescription });
        setUpdatingId((prev) => prev.filter((d) => d !== item._id));
      }
    } catch (error) {
      if (isLoadingTable) {
        setIsLoadingTable(false);
      }
      toastr.error(error?.response?.data?.message ?? 'Something went wrong!');
    }
  };

  const actions = orderAction(order?.status);

  return (
    <>
      {isLoading && !order?.length && <OrderDetailsSkeleton />}
      {!isLoading && order && (
        <>
          <Row gutter={[24, 24]} className="order-details">
            <Col md={8}>
              <Image
                className="prescription-image"
                src={order.prescription?.images[0]}
              />
            </Col>
            <Col md={16}>
              <div className="order-title">
                <h4 className="font-size-20 mb-3">
                  #{order.orderNumber} {order.shippingAddress?.fullName}
                </h4>
                <div className={`order-status ${order.status.toLowerCase()}`}>
                  {ORDER_STATUS_NAME[order.status]}
                </div>
              </div>
              <div className="sub-details">
                <p>
                  <i className="mdi mdi-cash-multiple" />
                  Phương thức thanh toán: {order.paymentStatus}
                </p>
                <p>
                  <i className="mdi mdi-clock-outline" />
                  {moment(order.createdAt).format('YYYY-MM-DD HH:mm')}
                </p>
                <p>
                  <i className="mdi mdi-map-marker-radius-outline" />
                  {`${order.shippingAddress?.street}, ${order.shippingAddress?.ward}, ${order.shippingAddress?.district}, ${order.shippingAddress?.city}`}
                </p>
                <p className="phone">
                  <i className="mdi mdi-phone" />
                  {order.shippingAddress?.phoneNumber}
                </p>
                {order.customerNote && (
                  <p>
                    <i className="far fa-sticky-note" />
                    Lời nhắn: <strong>{order.customerNote}</strong>
                  </p>
                )}
                {order.couponCode && (
                  <p>
                    <i className="mdi mdi-gift" />
                    Mã giảm giá: <strong>{order.couponCode}</strong>
                  </p>
                )}
                {order.status === ORDER_STATUS.CANCELLED && (
                  <p className="cancel-reason">
                    <i className="mdi mdi-emoticon-sad-outline" />
                    Lý do hủy: <strong>{order.cancelReason}</strong>
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="order-form">
            <OrderAssign
              visible={currentAction?.name === ORDER_ACTIONS.ASSIGN}
              onSubmit={assignHandle}
              onClose={onClose}
            />
            <OrderShipping
              visible={currentAction?.name === ORDER_ACTIONS.SHIPPING}
              onSubmit={shippingHandle}
              onClose={onClose}
            />
            <OrderCancel
              visible={currentAction?.name === ORDER_ACTIONS.CANCEL}
              onSubmit={cancelHandle}
              onClose={onClose}
            />
            {order.status !== 'NEW' && (
              <>
                <MedicineTable
                  isLoading={isLoadingTable}
                  onSave={saveMedicine}
                  onDelete={deleteMedicine}
                  setDataSource={setDataSource}
                  dataSource={dataSource}
                  updatingId={updatingId}
                  orderId={order?._id}
                  editable={editable === true && order.status === 'RECEIVED'}
                />
                <OrderTotal order={order} dataSource={dataSource} />
              </>
            )}
            {showAction && (
              <div className="order-actions float-end">
                {actions?.map(
                  (action) =>
                    action.name !== ORDER_ACTIONS.UPDATE_LINE_ITEM && (
                      <Button
                        type={
                          action.name === ORDER_ACTIONS.CANCEL
                            ? ''
                            : action.name === ORDER_ACTIONS.REJECT
                            ? 'link'
                            : 'primary'
                        }
                        key={action.name}
                        onClick={() => onSubmit(action)}
                      >
                        {ORDER_ACTION_NAME[action.name]}
                      </Button>
                    )
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetails;
