import {
  TOGGLE_THEME,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  SELECT_BRANCH,
  GET_PROFILE_REQUEST,
  GET_USER_POLICY_REQUEST,
  RESET_STORE
} from '~/constants/actionTypes';

export const toggleTheme = () => ({
  type: TOGGLE_THEME
});

export const loginRequest = (user) => ({
  type: LOGIN_REQUEST,
  payload: user
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const selectBranch = (branchId) => ({
  type: SELECT_BRANCH,
  payload: branchId
});

export const getProfile = (token) => ({
  type: GET_PROFILE_REQUEST,
  payload: token
});

export const getPolicy = (branchId) => ({
  type: GET_USER_POLICY_REQUEST,
  payload: branchId
});

export const resetStore = () => ({
  type: RESET_STORE
});
