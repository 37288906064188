import requester from '../requester';

const receiptPaymentType = {
  getAll: query => requester.get('/payment-note-type', query),
  getById: id => requester.get(`/payment-note-type/${id}`),
  create: receiptPaymentType => requester.post('/payment-note-type', receiptPaymentType),
  update: receiptPaymentType => requester.put(`/payment-note-type/${receiptPaymentType._id}`, receiptPaymentType),
  delete: id => requester.delete(`/payment-note-type/${id}`),
  getAllFilter: query => requester.get('/payment-note-type-list', query)
};

export default receiptPaymentType;
