import React, { useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  useDeleteSamplePrescription,
  useSampleColumnSetting, useSamplePaging,
  useSamplePrescriptionQueryParams,
  useSamplePrescriptions,
  useUpdateSampleParams
} from '~/hooks';
import WithPermission from '../../Common/WithPermission';
import POLICY from '~/constants/policy';
import { Link } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';
import { Button, Dropdown, Form, Input, Popover, Select, Table, Tabs,Collapse,Radio   } from 'antd';
import {
  CaretDownOutlined,
  DownOutlined,
  PlusOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { CARD_STYLE, STATUS_SAMPLE } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';

import InformationSample from '~/components/WorldClinic/SamplePrescription/Detail/InformationSample';
import './index.scss'
import { debounce, forIn, isString } from 'lodash';
import { onSelectRow } from '~/utils/validators';
const { Search } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const SamplePrescription = () => {
  const [query] = useSamplePrescriptionQueryParams();
  const [openRowId , setRow ]=useState(null);
  const [samplePrescriptions,isLoading] = useSamplePrescriptions(query);
  const [keyword, { setKeyword, onParamChange }] = useUpdateSampleParams(query);
  //paging
  const paging = useSamplePaging();
  const [columnMenu, selectedColumnKeys] = useSampleColumnSetting();
  const [isShowSettingColumn, setShowSettingColumn] = useState(false);
  const columns = useMemo(() => {
    const allColumns = [
      {
        title:'Mã thuốc',
        key:'code',
        dataIndex:'code',
        width:120,
        className: 'mwpx-120'
      },
      {
        title:'Tên thuốc',
        key:'name',
        dataIndex:'name',
      },
      {
        title:'Trạng thái',
        key:'status',
        dataIndex:'status',
        width:140,
        className: 'mwpx-140',
        render: status => {
          return STATUS_SAMPLE[status];
        }
      },
      {
        title:'Ghi chú',
        key:'note',
        dataIndex:'note',
        width:210,
        className: 'mwpx-210'
      },
      {
        title:'Người tạo',
        key:'createdBy',
        dataIndex:['createdBy','fullName'],
        width:210,
        className: 'mwpx-210',
      }
    ]

    //lọc ẩn các trường
    return allColumns.filter(({ key }) => !!selectedColumnKeys[key]);
  },[selectedColumnKeys]);

  const [openPopover, setOpenPopover] = useState(false);
  const hide = () => {
    setOpenPopover(false);
  };
  const [form] = Form.useForm();
  const onFinishSearch = (value) => {
    for (const key in value) {
        value[key] =  isString(value[key]) ? value[key].trim() : value[key];
    }
    setKeyword('')
    onParamChange({...value,keyword:undefined})
    setOpenPopover(false);
  }
  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
    hide();
  };
  const onDedounce = debounce((value) =>{
    onParamChange({keyword:value.target.value})
    form.resetFields()
  },2500)
  //content search
  const content = (
    <Form
      onFinish={onFinishSearch}
      form={form}
      autoComplete="off"
      scrollToFirstError
      requiredMark={false}
      initialValues={{
        ...query
      }}
      validateTrigger="onChange"
    >
      <Form.Item name="code" className={'form-item__multi'}>
        <Input placeholder="Theo mã đơn thuốc"/>
      </Form.Item>
      <Form.Item name="name" className={'form-item__multi'}>
        <Input placeholder="Theo tên đơn thuốc"/>
      </Form.Item>
      <Form.Item name="variantCode" className={'form-item__multi'}>
        <Input placeholder="Theo mã hàng"/>
      </Form.Item>
      <Form.Item name="productName" className={'form-item__multi'}>
        <Input placeholder="Theo tên hàng"/>
      </Form.Item>
      <Form.Item name="note" className={'form-item__multi'}>
        <Input placeholder="Theo ghi chú"/>
      </Form.Item>
      <div style={{textAlign: 'right'}}>
        <Button htmlType="button" 
        onClickCapture={
          (e)=>{
            forIn(form.getFieldsValue(),(value,key)=>{
              form.setFieldsValue({[key]:undefined});
            })
            }
        }>Xoá</Button>
        <Button type="primary" htmlType="submit" onClick={hide} icon={<SearchOutlined />}>Tìm kiếm</Button>
      </div>
    </Form>
  );
  const SelectAfter = (
    <Popover
      style={{
        width: 300,
      }}
      className="popover-search-box"
      placement="bottomRight"
      content={content}
      trigger="click"
      open={openPopover}
      onOpenChange={handleOpenChange}
    >
      <Button style={{marginLeft: '0'}}>
        <CaretDownOutlined/>
      </Button>
    </Popover>
  )
  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = samplePrescriptions.length === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteSamplePrescription] = useDeleteSamplePrescription(onDeleteSuccess)

  //expand row
  const expandedRowRender = record => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <InformationSample record={record} deleteSamplePrescription={deleteSamplePrescription}/>
          </TabPane>
        </Tabs>
      </div>
    );
  };
  const [valueStatus, setValueStatus] = useState(query.status);
  const onChange = (e) => {
    const status = e.target.value;
    onParamChange({ page:1,status})
    setValueStatus(e.target.value);
  };
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className='wc-page-content__box'>
          <div className='wc-page-content__left'>
            <h1 className="wc-page-header__title">Đơn thuốc mẫu</h1>
            <Collapse bordered={false} expandIconPosition='right' style={{marginBottom:'16px'}}>
              <Panel header="Trạng thái" key="1" style={{fontWeight:'600'}}>
                <Radio.Group onChange={onChange} value={valueStatus}>
                  <Radio value={'ACTIVE,INACTIVE'} style={{display:'block'}}>Tất cả</Radio>
                  <Radio value={'ACTIVE'} style={{display:'block'}}>Áp dụng</Radio>
                  <Radio value={'INACTIVE'} style={{display:'block'}}>Không áp dụng</Radio>
                </Radio.Group>
              </Panel>

            </Collapse>
            <div className={'quantity-record'}>
              <h6 className='quantity-record__title'>Số bản ghi</h6>
              <Select
                defaultValue={query.limit}
                className={'quantity-record__select'}
                size={'small'}
                onSelect={(value, option) => {
                  return onParamChange({limit: parseInt(value), page: 1})
                }}
              >
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="20">20</Select.Option>
                <Select.Option value="30">30</Select.Option>
                <Select.Option value="50">50</Select.Option>
                <Select.Option value="100">100</Select.Option>
              </Select>
            </div>

          </div>
          <div className='wc-page-content__right'>
            <div className="wc-page-header" style={{ paddingRight: 0 }}>
              <div className="wc-page-header__search">
                <Search
                  addonAfter={SelectAfter}
                  style={{ width: '100%' }}
                  allowClear
                  enterButton
                  placeholder="Tìm tất cả"
                  onSearch={(e) => 
                   { onParamChange({ keyword:e })
                    form.resetFields()}}
                  onChange={onDedounce}
                  defaultValue={keyword}
                  // value={keyword}
                />
              </div>

              <div className="wc-page-header__actions">
                <WithPermission permission={POLICY.WRITE_SAMPLEPRESCRIPTION }>
                  <Link
                    to={PATH_CLINIC.samplePrescription.create}
                    // onClick={() => {
                    // localStorage.removeItem('item')
                    // }}
                  >
                    <Button
                      type="primary"
                      style={{ float: 'right', marginRight: 10 }}
                    >
                      <PlusOutlined />
                      Thêm mới
                    </Button>
                  </Link>
                  <Dropdown
                    onVisibleChange={flag => setShowSettingColumn(flag)}
                    visible={isShowSettingColumn}
                    overlay={columnMenu}
                    trigger={['click']}
                    style={{ float: 'right' }}
                  >
                    <Button type="primary" onClick={e => e.stopPropagation()}>
                      <UnorderedListOutlined /> <DownOutlined />
                    </Button>
                  </Dropdown>
                </WithPermission>
              </div>
            </div>

            <div className="page-content__main">
              <div className="page-content__left hidden" style={CARD_STYLE}></div>

              <div className="page-content__right" style={CARD_STYLE}>
                {isLoading ? (
                  <SkeletonTable
                    rowCount={samplePrescriptions.length}
                    columns={columns}
                    pagination={paging}
                  />
                ) : (
                  <Table
                    className="table--selectable permission-table"
                    size="middle"
                    // rowClassName={record =>
                    //   record.isSummaryRow ? 'summary-row' : ''
                    // }
                    rowKey={rc => rc._id}

                    columns={columns}
                    dataSource={samplePrescriptions}
                    onChange={({ current }) => onParamChange({ page: current })}
                    pagination={
                      !query.id && {
                        ...paging,
                        // pageSize: 11,
                        // total: Math.ceil(paging.total + paging.total / 10),
                        showTotal: () => `Tổng cộng: ${paging.total} `
                      }
                    }
                    // rowSelection={{
                    //   renderCell: (checked, record, index, originNode) =>
                    //     record.isSummaryRow ? <></> : originNode,
                    //   getCheckboxProps: record => ({
                    //     disabled: record.isSummaryRow,
                    //     name: record.name
                    //   })
                    // }}
                    onRow={onSelectRow(setRow)}
                    
                    onExpandedRowsChange={e=> setRow(e.at(-1)) }
                    expandable={{
                      expandedRowRender,
                      rowExpandable: record => !record.isSummaryRow,
                      defaultExpandAllRows: !!query.id,
                      expandedRowKeys: [openRowId]
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default SamplePrescription;
