import {
  GET_NEWSES_REQUEST,
  GET_NEWS_REQUEST,
  CREATE_NEWS_REQUEST,
  UPDATE_NEWS_REQUEST,
  DELETE_NEWS_REQUEST,
  RESET_NEWS_STATE
} from '~/constants/actionTypes';

export const getNewses = query => ({
  type: GET_NEWSES_REQUEST,
  payload: query
});

export const getNews = id => ({
  type: GET_NEWS_REQUEST,
  payload: id
});

export const createNews = news => ({
  type: CREATE_NEWS_REQUEST,
  payload: news
});

export const updateNews = news => ({
  type: UPDATE_NEWS_REQUEST,
  payload: news
});

export const deleteNews = id => ({
  type: DELETE_NEWS_REQUEST,
  payload: id
});

export const resetNewsState = () => ({
  type: RESET_NEWS_STATE
});
