import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import NumberToWord from '~/utils/readMoney';
import { Image } from 'antd';
import { formatDate, formatNumber, getAddress } from '~/utils/helper';
import { useSelector } from 'react-redux';
import { SALE_TYPES } from '~/constants/defaultValue';
import {
  useFormPrints,
  useFormPrintQueryParams
} from '~/hooks/worldClinic/formPrint';
const readMoney = new NumberToWord();

function InvoiceTemplate() {
  const createdSale = useSelector(state => state?.sale.createSuccess);
  const updatedSale = useSelector(state => state?.sale.updateSuccess);
  const selectedSale = useSelector(state => state?.sale.selectedSale);
  const [dataSource, setDataSource] = useState({});
  const [query] = useFormPrintQueryParams();
  const [formPrints] = useFormPrints(query);
  const mergedFormPrint = useMemo(() => {
    if (!formPrints || !formPrints.length) {
      return null;
    }
    if (Array.isArray(formPrints)) {
      return formPrints[0];
    }
  }, [formPrints]);
  useEffect(() => {
    if (selectedSale.type === 'RETURN') {
      return setDataSource(selectedSale);
    }
    if (createdSale) {
      setDataSource(createdSale);
    }
  }, [createdSale, selectedSale]);

  useEffect(() => {
    if (updatedSale) {
      setDataSource(updatedSale);
    }
  }, [updatedSale]);

  const { customer, branch, saleOrderDetail, invoiceDetail } = dataSource;

  const products = useMemo(
    () =>
      saleOrderDetail ||
      invoiceDetail ||
      dataSource?.medicines?.filter(data => !data.typeExchangeReturn) ||
      [],
    [saleOrderDetail, invoiceDetail, dataSource?.medicines]
  );

  const productsExchange = useMemo(
    () => dataSource?.medicines?.filter(data => data.typeExchangeReturn) || [],
    [dataSource?.medicines]
  );

  const totalAmount = useMemo(() => {
    return products.reduce((total, { price, quantity }) => {
      const amount = quantity * price;
      return total + amount;
    }, 0);
  }, [products]);

  const totalAmountExchange = useMemo(() => {
    return productsExchange.reduce((total, { price, quantity }) => {
      const amount = quantity * price;
      return total + amount;
    }, 0);
  }, [productsExchange]);

  const discountValue =
    dataSource?.discountValue || dataSource?.summary?.discount?.value || 0;
  const saleType = dataSource?.saleChannel || dataSource?.type;
  const isInvoice = saleType === SALE_TYPES.DIRECT;

  return (
    <div id="invoice-template">
      <Row style={{ width: '20rem' }}>
        <Col lg="1">
          <Card>
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col sm="6">
                  <div className="text-muted">
                    <div>{isInvoice ? 'Ngày :' : 'Ngày đặt hàng :'}</div>
                    <div
                      className="text-end"
                      style={{ fontSize: '13px', fontWeight: 'bold' }}
                    >
                      {formatDate(
                        saleType === 'RETURN'
                          ? dataSource?.customer?.createdAt
                          : dataSource?.purchasedAt
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="text-muted text-sm-end">
                    <div>
                      {isInvoice
                        ? 'Hoá đơn :'
                        : saleType === 'RETURN'
                        ? 'Trả hàng :'
                        : 'Đặt hàng :'}
                    </div>
                    <div
                      className="text-end"
                      style={{ fontSize: '13px', fontWeight: 'bold' }}
                    >
                      #{dataSource?.code}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="invoice-title">
                <div className="mb-4"></div>
                <div className="text-muted">
                  <h5 className="mb-1" style={{ textAlign: 'center' }}>
                    {branch?.name?.vi}
                  </h5>
                  <p className="mb-1" style={{ textAlign: 'center' }}>
                    {/* Địa chỉ:{' '} */}
                    <span className="ml-1">
                      {getAddress(branch?.address || {})}
                    </span>
                  </p>
                  <p className="mb-1" style={{ textAlign: 'center' }}>
                    {/* Email:  */}
                    <span className="ml-1">{branch?.email}</span>
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    {/* SĐT:  */}
                    <span className="ml-1">{branch?.phoneNumber}</span>
                  </p>
                </div>
              </div>
              <hr />
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
              {/* <Col sm="6"> */}
              {/* <div className="text-muted">
                <div className="mb-1">
                  Khách hàng:
                  <span
                    className="font-size-13 mb-2"
                    style={{ fontWeight: 'bold' }}
                  >
                    {customer?.name}
                  </span>
                </div>

                <p className="mb-1">
                  Địa chỉ:{' '}
                  <span className="ml-1" style={{ fontWeight: 'bold' }}>
                    {getAddress(customer?.address || {})}
                  </span>{' '}
                </p>
                <p className="mb-1">
                  {' '}
                  Email:{' '}
                  <span style={{ fontWeight: 'bold' }} className="ml-1">
                    {customer?.email}
                  </span>
                </p>
                <p>
                  {' '}
                  SĐT:{' '}
                  <span className="ml-1" style={{ fontWeight: 'bold' }}>
                    {customer?.phoneNumber}
                  </span>{' '}
                </p>
              </div> */}
              <div className="py-2 mt-3">
                <h5 className="mb-1" style={{ textAlign: 'center' }}>
                  Chi tiết {isInvoice ? 'hoá đơn' : 'đơn hàng'}{' '}
                </h5>
                <hr />
                {/* <div className="text-muted">
                  <div>
                    <h5 className="font-size-13">
                      {isInvoice ? 'Mã hoá đơn :' : 'Mã đặt hàng :'}
                      <span className="ml-1">{dataSource?.code}</span>
                    </h5>
                  </div>
                </div> */}
                <div className="table-responsive mt-1">
                  <Table className="table-centered mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: '100px' }}>SL</th>
                        <th style={{ width: '150px' }}>Đơn giá</th>
                        <th style={{ width: '250px' }}>Thành tiền</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((item, key) => (
                        <tr key={key}>
                          <td colspan="3">
                            <h5 className="font-size-13 mb-1">
                              {item.product?.name}
                              <span>(</span>
                              <span>
                                {' '}
                                {item.productVariant?.unit?.name ||
                                  item.variant?.unit?.name ||
                                  item?.unit?.name}
                              </span>
                              <span>)</span>
                            </h5>
                          </td>
                        </tr>
                      ))}
                      {products.map((item, key) => (
                        <tr key={key}>
                          {/* <h5 className="font-size-15 mb-1">
                              {item.product?.name}
                              <span>
                              {' '}
                            {item.productVariant?.unit?.name ||
                              item.variant?.unit?.name || item?.unit?.name}
                              </span>
                            </h5> */}
                          {/* <td>{key + 1}</td> */}
                          <td>{item.quantity}</td>
                          {/* <td>
                            <h5 className="font-size-15 mb-1">
                              {item.product?.name}
                              <span>
                              {' '}
                            {item.productVariant?.unit?.name ||
                              item.variant?.unit?.name || item?.unit?.name}
                              </span>
                            </h5>
                          </td> */}

                          <td>{formatNumber(item.price)}</td>
                          <td className="text-end">
                            {' '}
                            {formatNumber(item.price * item.quantity)}
                          </td>
                        </tr>
                      ))}
                      <tr className="invoice-summary-row">
                        <th colSpan="2" className="text-end">
                          Tổng tiền hàng:
                        </th>
                        <td className="text-end">
                          {formatNumber(totalAmount)}
                        </td>
                      </tr>
                      <tr className="invoice-summary-row  invoice-summary-row--mt-10">
                        <th colSpan="2" className="border-0 text-end">
                          Giảm giá :
                        </th>
                        <td className="border-0 text-end">
                          {formatNumber(discountValue)}
                        </td>
                      </tr>

                      <tr className="invoice-summary-row invoice-summary-row--mt-10">
                        <th colSpan="2" className="border-0 text-end">
                          Tổng thanh toán:
                        </th>
                        <td className="border-0 text-end">
                          {formatNumber(totalAmount - discountValue)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {dataSource._id && (
                    <h5 className="invoice-template__money-text">{`${'Bằng chữ:'} ${readMoney.getFullText(
                      totalAmount - discountValue
                    )} đồng chẵn./.`}</h5>
                  )}
                </div>
              </div>

              {productsExchange.length > 0 && (
                <div className="py-2 mt-3">
                  <h5 className="font-size-15">Chi tiết đơn hàng đổi trả</h5>
                  <div className="table-responsive mt-1">
                    <Table className="table-centered mb-0">
                      <thead>
                        <tr>
                          {/* <th style={{ width: '70px' }}>STT</th> */}
                          {/* <th style={{ width: '150px' }}>Sản phẩm</th> */}
                          {/* <th> Đơn vị</th> */}
                          <th style={{ width: '120px' }}>Số lượng</th>
                          <th style={{ width: '120px' }}>Đơn giá</th>
                          <th className="text-end" style={{ width: '120px' }}>
                            Thành tiền
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productsExchange.map((item, key) => (
                          <tr key={key}>
                            <td colspan="3">
                              <h5 className="font-size-15 mb-1">
                                {item.product?.name}
                                <span>
                                  {' '}
                                  {item.productVariant?.unit?.name ||
                                    item.variant?.unit?.name ||
                                    item?.unit?.name}
                                </span>
                              </h5>
                            </td>
                          </tr>
                        ))}
                        {productsExchange.map((item, key) => (
                          <tr key={key}>
                            {/* <td>{key + 1}</td> */}
                            <td>{item.quantity}</td>
                            <td>{formatNumber(item.price)}</td>
                            <td className="text-end">
                              {' '}
                              {formatNumber(item.price * item.quantity)}
                            </td>
                          </tr>
                        ))}
                        <tr className="invoice-summary-row">
                          <th colSpan="5" className="text-end">
                            Tổng tiền hàng đổi:
                          </th>
                          <td className="text-end">
                            {formatNumber(totalAmountExchange)}
                          </td>
                        </tr>
                        <tr className="invoice-summary-row  invoice-summary-row--mt-10">
                          <th colSpan="5" className="border-0 text-end">
                            Giảm giá :
                          </th>
                          <td className="border-0 text-end">
                            {formatNumber(
                              discountValue ||
                                dataSource?.summary?.discount?.value
                            )}
                          </td>
                        </tr>

                        <tr className="invoice-summary-row invoice-summary-row--mt-10">
                          <th colSpan="5" className="border-0 text-end">
                            Tổng thanh toán hàng đổi:
                          </th>
                          <td className="border-0 text-end">
                            <h5 className="m-0">
                              {formatNumber(totalAmount - discountValue)}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {dataSource._id && (
                      <h5 className="invoice-template__money-text">{`${'Bằng chữ:'} ${readMoney.getFullText(
                        totalAmount - discountValue
                      )} đồng chẵn./.`}</h5>
                    )}
                  </div>
                </div>
              )}
              <div className="invoice-template__money-text mt-3">
                Xin cám ơn và hẹn gặp lại quý khách!
              </div>
            </CardBody>
            <div className="form-information" style={{ margin: '1rem' }}>
              <div style={{ textAlign: 'center', margin: 'auto' }}>
                <Image src={mergedFormPrint?.image} width={130} />
              </div>
              <div style={{ textAlign: 'center', margin: 'auto' }}>
                {(mergedFormPrint?.description??[]).map(index => (
                  <div index={index}>{index}</div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default InvoiceTemplate;
