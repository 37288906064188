import * as Types from '~/constants/actionTypes';

export const getMedicineUnits = query => ({
  type: Types.GET_MEDICINE_UNITS_REQUEST,
  payload: query
});

export const getMedicineUnit = id => ({
  type: Types.GET_MEDICINE_UNIT_REQUEST,
  payload: id
});

export const createMedicineUnit = unit => ({
  type: Types.CREATE_MEDICINE_UNIT_REQUEST,
  payload: unit
});

export const updateMedicineUnit = unit => ({
  type: Types.UPDATE_MEDICINE_UNIT_REQUEST,
  payload: unit
});

export const deleteMedicineUnit = id => ({
  type: Types.DELETE_MEDICINE_UNIT_REQUEST,
  payload: id
});

export const resetMedicineUnitState = () => ({
  type: Types.RESET_MEDICINE_UNIT_STATE
});
