import React from 'react';
import { Form, Select, Skeleton } from 'antd';

const { Option } = Select;

const ModulesFormItem = ({ isLoading }) => {
  return (
    <Form.Item label="Phân loại" name="modules">
      {isLoading ? (
        <Skeleton.Input active />
      ) : (
        <Select mode="multiple">
          <Option value="PHARMACY" key="PHARMACY">
            Pharmacy
          </Option>
          <Option value="CLINIC" key="Clinic">
            Clinic
          </Option>
        </Select>
      )}
    </Form.Item>
  );
};

export default ModulesFormItem;
