import React from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import Employees from './Employees';

const index = () => {
  return (
    <div className="branch-detail page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhân viên" />
        <Employees />
      </div>
    </div>
  );
};

export default index;
