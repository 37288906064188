import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getSuppliers({ payload: query }) {
  try {
    const data = yield call(Api.supplier.getAll, query);
    yield put({ type: Types.GET_SUPPLIERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SUPPLIERS_FAILED,
      payload: error
    });
  }
}

function* getSuppliersAuthenticated({ payload: query }) {
  try {
    const data = yield call(Api.supplier.getAllAuthenticated, query);
    yield put({ type: Types.GET_SUPPLIERS_AUTHENTICATED_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SUPPLIERS_AUTHENTICATED_FAILED,
      payload: error
    });
  }
}

function* getSupplier({ payload }) {
  try {
    const supplier = yield call(Api.supplier.getById, payload);
    yield put({
      type: Types.GET_SUPPLIER_SUCCESS,
      payload: supplier
    });
  } catch (error) {
    yield put({
      type: Types.GET_SUPPLIER_FAILED,
      payload: error
    });
  }
}

function* createSupplier(action) {
  try {
    const data = yield call(Api.supplier.create, action.payload);
    yield put({ type: Types.CREATE_SUPPLIER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SUPPLIER_FAILED,
      payload: error
    });
  }
}

function* updateSupplier(action) {
  try {
    const data = yield call(Api.supplier.update, action.payload);
    yield put({ type: Types.UPDATE_SUPPLIER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SUPPLIER_FAILED,
      payload: error
    });
  }
}

function* deleteSupplier({ payload }) {
  try {
    yield call(Api.supplier.delete, payload);
    yield put({ type: Types.DELETE_SUPPLIER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_SUPPLIER_FAILED,
      payload: error
    });
  }
}

export default function* supplier() {
  yield takeLatest(Types.GET_SUPPLIERS_REQUEST, getSuppliers);
  yield takeLatest(Types.GET_SUPPLIERS_AUTHENTICATED_REQUEST, getSuppliersAuthenticated);
  yield takeLatest(Types.GET_SUPPLIER_REQUEST, getSupplier);
  yield takeLatest(Types.DELETE_SUPPLIER_REQUEST, deleteSupplier);
  yield takeLatest(Types.CREATE_SUPPLIER_REQUEST, createSupplier);
  yield takeLatest(Types.UPDATE_SUPPLIER_REQUEST, updateSupplier);
}
