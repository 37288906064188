import React, { useState, useMemo } from 'react';
import {
  useSuppliers,
  useDeleteSupplier,
  useSupplierQueryParams,
  useUpdateSupplierParams,
  useResetSupplier,
  useSupplierPaging,
  useColumnSetting,
  SEARCH_SUPPLIER,
  useGetSummarySupplier
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Dropdown } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE } from '~/constants/defaultValue';
import SupplierForm from './Form';
import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone
} from '@ant-design/icons';
import { getAddress, formatCurrency } from '~/utils/helper';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import SummaryTable from '~/components/Common/SummaryTable';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';

const { Search } = Input;

const ColumnActions = ({ _id, deleteSuplier, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_SUPPLIER}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      <WithPermission permission={POLICY.UPDATE_SUPPLIER}>
        <WithPermission permission={POLICY.DELETE_SUPPLIER}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_SUPPLIER}>
        <Popconfirm
          title="Bạn muốn xoá đường dùng này?"
          onConfirm={() => deleteSuplier(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Suppliers = () => {
  const [query] = useSupplierQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateSupplierParams(
    query
  );

  let [datasource, isLoading] = useSuppliers(query);

  const paging = useSupplierPaging();
  const [partnerId, setSuplierId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);

  const numberOfSupliers = datasource?.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfSupliers === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteSuplier] = useDeleteSupplier(onDeleteSuccess);

  useResetSupplier();

  const onOpenForm = id => {
    setSuplierId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setSuplierId(null);
    setIsOpenForm(false);
  };

  const [columnMenu, selectedColumnKeys] = useColumnSetting();

  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Tên nhà cung cấp',
        key: 'name',
        dataIndex: 'name'
      },

      {
        title: 'Mã nhà cung cấp',
        key: 'supplierCode',
        dataIndex: 'supplierCode'
      },

      {
        title: 'Nhóm nhà cung cấp',
        key: 'supplierGroup',
        dataIndex: ['supplierGroup', 'name']
      },

      {
        title: 'Địa chỉ',
        key: 'address',
        dataIndex: 'address',
        render: (address, _, index) => {
          return index !== 0 ? getAddress(address) : null;
        }
      },

      {
        title: 'Điện thoại',
        key: 'phoneNumber',
        dataIndex: 'phoneNumber'
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
      },
      {
        title: 'Nợ cần trả',
        key: 'currentDebt',
        dataIndex: 'currentDebt',
        align: 'end',
        render: currentDebt => formatCurrency(currentDebt)
      },
      {
        title: 'Tổng mua',
        key: 'totalPurchase',
        dataIndex: 'totalPurchase',
        align: 'end',
        render: totalPurchase => formatCurrency(totalPurchase)
      },
      {
        title: 'Trạng thái',
        key: 'status',
        align: 'center',
        width: 120,
        render: ({ isActive, isSummaryRow }) => {
          if (isSummaryRow) return <></>;

          return !isActive ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff4d4f" />
          );
        }
      },
      {
        title: 'Thao tác',
        key: 'action',
        width: '110px',
        render: record => {
          return record.isSummaryRow ? (
            <></>
          ) : (
            <ColumnActions
              {...record}
              deleteSuplier={deleteSuplier}
              onOpenForm={onOpenForm}
            />
          );
        }
      }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [deleteSuplier, selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const summary = useMemo(() => {
    const debt= datasource?.reduce((total, { currentDebt }) => total + currentDebt, 0);
    const totalPurchase= datasource?.reduce((total, { totalPurchase }) => total + totalPurchase, 0 );
     return [ 
      {
        title:"Tổng nợ cần trả",
        type:"danger",
        value:debt
     },
      {
        title:"Tổng mua",
        type:"green",
        value:totalPurchase
     },
     ]
  }, [datasource]);

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Nhà cung cấp</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_SUPPLIER} />
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_SUPPLIER}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => onOpenForm()}
              >
                <PlusOutlined />
                Thêm mới
              </Button>
              <ButtonExport
                router={API_LIST.supplier} 
                label="Nhà cung cấp" 
                query={query} 
                exportOption={1}
                fileName={"Danhsachnhacungcap_"} />

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>
        <SummaryTable summaryValue={summary} />

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={datasource?.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                rowClassName={record =>
                  record.isSummaryRow ? 'summary-row' : ''
                }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={datasource}
                onChange={({ current,pageSize }) => onParamChange({ page: current,limit:pageSize })}
                pagination={{
                  ...paging,
                  showSizeChanger:true,
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                size="middle"
                // rowSelection={{
                //   onChange: (selectedRowKeys, selectedRows) => {},
                //   renderCell: (checked, record, index, originNode) =>
                //     record.isSummaryRow ? <></> : originNode,
                //   getCheckboxProps: record => ({
                //     disabled: record.isSummaryRow,
                //     name: record.name
                //   })
                // }}
              />
            )}
          </div>
        </div>
      </div>

      <SupplierForm isOpen={isOpenForm} onClose={onCloseForm} id={partnerId} />
    </div>
  );
};

export default Suppliers;
