import { Button, Typography,DatePicker } from 'antd'
import React, { useState } from 'react'

const { RangePicker } = DatePicker
const FillterByDay = ({inputValue,setInputValue}) => {
    return(
        <RangePicker
        value={inputValue}
        allowEmpty={[true, true]}
        style={{marginTop:'8px',width:'98%'}}
        onChange={(e, v) => {
            setInputValue((e??[null,null]).map(el=>!!(el)?el:null))
        }}
      />
    )
}
const ButtonStatus = ({title,keyActive,handleClick,status}) =>   <Button onClick={() => handleClick(keyActive)} className={status === keyActive ? "activeNotify" : ""} type='text'>
<Typography.Text strong>{title}</Typography.Text>
</Button>
export default function GroupButtonNotify({setStatus,status,inputValue,setInputValue}) {
    
    const [filter,setFilter] = useState(false);
    const handleClick = (key) => {
       
        if(setStatus && typeof setStatus === 'function'){
            setStatus(key);
            setInputValue([null,null])
            if(key==="filterDay") setFilter(true)
            else setFilter(false)
        }
    }
    return (
        <div className='GroupButtonNotify'>
          <ButtonStatus handleClick={handleClick} status={status} keyActive={null} title="Tất cả"/>
          <ButtonStatus handleClick={handleClick} status={status} keyActive={'unread'} title="Chưa đọc"/>
          <ButtonStatus handleClick={handleClick} status={status} keyActive={'filterDay'} title="Lọc theo ngày"/>
          {filter?<FillterByDay inputValue={inputValue} setInputValue={setInputValue}/>:null}
        </div>
    )
}

