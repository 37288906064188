import {
  Form,
  Col,
  Row,
  Input,
  Button,
  Modal,
  Checkbox,
  Switch,
  Skeleton,
  Select
} from 'antd';
import React, { useEffect } from 'react';
import {
  useInitReceiptPaymentType,
  useCreateReceiptPaymentType,
  useReceiptPaymentType,
  useResetReceiptPaymentType
} from '~/hooks';
import { STATUS } from '~/constants/defaultValue';

const FormItem = Form.Item;

const { Option } = Select;

const ReceiptPaymentTypeModal = ({
  onClose,
  isOpen,
  isReceipt,
  _id,
  updateReceiptPaymentType
}) => {
  const [form] = Form.useForm();
  const [paymentType, isLoading] = useReceiptPaymentType(_id);

  const [isSubmitLoading, handleCreate] = useCreateReceiptPaymentType(onClose);
  const initReceiptPaymentType = useInitReceiptPaymentType(paymentType, _id);
  useResetReceiptPaymentType();

  const tileModal =
    isReceipt === 'RECEIPT'
      ? `${!_id ? 'Tạo mới ' : 'Cập nhật'}` + ' loại thu'
      : `${!_id ? 'Tạo mới ' : 'Cập nhật'}` + ' loại chi';

  const onChangeCheckbox = () => {};

  useEffect(() => {
    form.resetFields();
  }, [initReceiptPaymentType, form]);

  const handleOnFinish = values => {
    form.resetFields();
    const getStatus = ({ status }) =>
      status ? STATUS.ACTIVE : STATUS.INACTIVE;

    if (_id) {
      updateReceiptPaymentType({
        ...values,
        _id: _id,
        status: getStatus(values)
      });
    } else {
      handleCreate({
        ...values,
        type: isReceipt === 'RECEIPT' ? 'RECEIPT' : 'PAYMENT',
        status: getStatus(values)
      });
    }
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      width={740}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <h4 style={{ marginBottom: 30, fontSize: '1.5rem' }}> {tileModal}</h4>
      <div className="wc-setting-form">
        <Form
          form={form}
          onFinish={handleOnFinish}
          colon={false}
          scrollToFirstError
          requiredMark={false}
          initialValues={initReceiptPaymentType}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row>
            <Col span={24}>
              <FormItem
                name="name"
                rules={[
                  {
                    required: true,
                    message: `${
                      isReceipt === 'RECEIPT'
                        ? 'Vui lòng nhập loại thu!'
                        : 'Vui lòng nhập loại chi!'
                    }`
                  }
                ]}
                label={isReceipt === 'RECEIPT' ? 'Loại thu' : 'Loại chi'}
              >
                <Input autoFocus={true} />
              </FormItem>
              <FormItem label="Phân loại" name="type">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    <Option value="RECEIPT" key="RECEIPT">
                      Loại thu
                    </Option>
                    <Option value="PAYMENT" key="PAYMENT">
                      Loại chi
                    </Option>
                  </Select>
                )}
              </FormItem>

              <FormItem label="Mô tả" name="description">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input.TextArea rows={3} />
                )}
              </FormItem>

              <FormItem name="checkResult">
                <Checkbox
                  defaultChecked
                  onChange={onChangeCheckbox}
                  style={{ marginLeft: '5.7rem' }}
                >
                  Hạch toán vào kết quả hoạt động kinh doanh
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Trạng thái"
                name="status"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Hoạt động"
                  unCheckedChildren="Ngưng hoạt động"
                  disabled={isLoading}
                />
              </FormItem>
            </Col>
          </Row>

          <Row className="wc-setting-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {_id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ReceiptPaymentTypeModal;
