import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Row,
  Button,
  InputNumber,
  Select,
  Col,
  DatePicker,
  TimePicker,
  Radio
} from 'antd';
import moment from 'moment';
import { formatCurrency, formatNumber, getUtcDateTime } from '~/utils/helper';
import {
  CARD_STYLE,
  DISCOUNT_TYPES,
  PAYMENT_STATE,
  PAYMENT_METHODS,
  PURCHASE_ORDER_STATUS
} from '~/constants/defaultValue';
import { PlusOutlined } from '@ant-design/icons';
import {
  getPriceAfterDiscount,
  getDiscountValue,
  caculateDiscountValueOnTypeChange
} from '~/utils/caculator';
import {
  useSuppliers,
  useResetSupplier,
  useCreateWarehouse,
  useUpdateWarehouse,
  useProfile,
  useDebounceSelectSupplier
} from '~/hooks';
import SupplierForm from '~/components/WorldClinic/Supplier/Form';
import currencyFormatter from '~/utils/currencyFormatter';
import toastr from 'toastr';
import DiscountInput from '~/components/Common/DiscountInput';
import PaymentHistoryModal from '~/components/Common/PaymentHistoryModal';
import { get } from 'lodash';
import DebounceSelectInitValue from '~/components/Utils/DebounceSelectInitValue';

const { CASH, CARD, TRANSFER } = PAYMENT_METHODS;
const { PERCENT, VALUE } = DISCOUNT_TYPES;
const { DRAFT, COMPLETED } = PAYMENT_STATE;

const FormItem = Form.Item;
const { Option } = Select;

const query = { limit: 1000 };

const SummaryForm = ({ formData, initWarehouse, isGetWarehouseLoading }) => {
  const isEditingCompletedWarehouse =
    initWarehouse?._id && initWarehouse?.status === COMPLETED;

  const isEditingDrafWarehouse =
    initWarehouse && initWarehouse?.status === DRAFT;

  const isDraftWarehouse =
    !initWarehouse || initWarehouse?.status !== COMPLETED;

  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);

  const [profile] = useProfile();
  const [form] = Form.useForm();
  const statusRef = useRef(DRAFT);
  const history = useHistory();
  const ref = useRef()
  const onSubmitSuccess = warehouse => {
    history.push({
      pathname: '/warehouse',
      search: new URLSearchParams({ id: warehouse._id }).toString()
    });
  };

  const [isCreatingWarehouse, createWarehouse] = useCreateWarehouse(
    onSubmitSuccess
  );
  const [isUpdatingWarehouse, updateWarehouse] = useUpdateWarehouse(
    onSubmitSuccess
  );

  const [suppliers, isGetSuppliersLoading] = useSuppliers(query);
  const [isSupplierFormOpen, setSupplierFormOpen] = useState(false);
  const [summaryDiscount, setSummaryDiscount] = useState({
    type: VALUE,
    value: 0
  });

  const [payment, setPayment] = useState({ method: CASH, value: 0 });

  const onPaymentChange = (key, value) => {
    setPayment(payment => ({
      ...payment,
      [key]: value
    }));
  };
  const {getListSupplier,initSupplier,onSelectSupplier} = useDebounceSelectSupplier((args) => form.setFieldsValue({supplierId : args}))
  useEffect(() => {
    if (initWarehouse) {
      const { discountValue, discountType, discountPercent,supplierId } = initWarehouse;
      setSummaryDiscount({
        type: discountType,
        value: discountType === VALUE ? discountValue : discountPercent
      });

      form.setFieldsValue({
        supplierId: initWarehouse.supplierId,
        code: initWarehouse.code,
        note: initWarehouse.note,
        date: moment(initWarehouse.purchasedAt),
        time: moment(initWarehouse.purchasedAt)
      });
      if (supplierId) { // Init fetch first
        getListSupplier({ getMore: 1, ids: supplierId }, true)
      }
    }
  }, [initWarehouse, form]);

  useResetSupplier();
  

  const totalAmount = useMemo(() => {
    return formData.reduce((amount, { quantity, productVariant,cost, discount }) => {
      return (amount += quantity * getPriceAfterDiscount(get(productVariant,"cost",cost??0), discount));
    }, 0);
  }, [formData]);

  const totalPaid = useMemo(() => {
    if (!initWarehouse) {
      return 0;
    }

    return initWarehouse.paymentNotes?.reduce(
      (acc, { paymentAmount }) => acc + paymentAmount,
      0
    );
  }, [initWarehouse]);

  const onSummaryDiscountChange = value => {
    setSummaryDiscount(discount => ({ ...discount, value }));
  };

  const onSummaryDiscountTypeChange = type => {
    const discountValue = caculateDiscountValueOnTypeChange({
      type,
      value: summaryDiscount.value,
      price: totalAmount
    });

    setSummaryDiscount({ type, value: discountValue });
  };
  const onFinishFailed =({errorFields})=>{
    if(errorFields.some(e=>e.name[0]==='supplierId')){
      toastr.clear()
      toastr.error("Thiếu nhà cung cấp")
      if (ref.current?.focus && typeof ref.current?.focus() === 'function') {
        ref.current.focus()
      }
    }
  }

  const onFinish = values => {

    const variantHasNoBatch = formData.find(
      ({ selectedBatches }) => !selectedBatches.length
    );

    if (variantHasNoBatch) {
      toastr.error('Vui lòng nhập số lô cho tất cả sản phẩm');
      return;
    }

    const converSummaryDiscount = type => {
      return caculateDiscountValueOnTypeChange({
        value: summaryDiscount.value,
        price: totalAmount,
        type
      });
    };

    const model = {
      ...values,

      status: statusRef.current,
      purchaseItems: formData.map(variant => {
        const {
          discount: { type, value },
          cost
        } = variant;

        const convertDiscount = type => {
          return caculateDiscountValueOnTypeChange({ type, value, cost });
        };

        const supplyPrice = getPriceAfterDiscount(cost, variant.discount);

        return {
          productId: variant.productId,
          variantId: initWarehouse ? variant.variantId : variant._id,
          batches: variant.selectedBatches.map(({ _id, batchId, quantity }) => {
            return {
              batchId: batchId || _id,
              quantity: quantity
            };
          }),
          price:cost,
          discountValue: type === VALUE ? value : convertDiscount(VALUE),
          discountPercent: type === PERCENT ? value : convertDiscount(PERCENT),

          discountType: type,
          supplyPrice,
          total: supplyPrice * variant.quantity
        };
      }),

      discountValue:
        summaryDiscount.type === VALUE
          ? summaryDiscount.value
          : converSummaryDiscount(VALUE),
      discountPercent:
        summaryDiscount.type === PERCENT
          ? summaryDiscount.value
          : converSummaryDiscount(PERCENT),
      discountType: summaryDiscount.type
    };

    model.payment = {
      method: payment.method,
      amount: payment.value
    };

    model.purchasedAt = model.purchasedAt = getUtcDateTime(
      values.date,
      values.time
    );

    model.date = undefined;
    model.time = undefined;

    const { _id } = initWarehouse || {};
    console.log(model,'model')
    if (_id) {
      updateWarehouse({ ...model, _id });
    } else {
      createWarehouse(model);
    }
  };

  const isActionLoading = isCreatingWarehouse || isUpdatingWarehouse;

  const needToPay =
    getPriceAfterDiscount(totalAmount, summaryDiscount) - totalPaid;

  const typePurchaseIsPharma = initWarehouse?.typePurchase === 'PM';
  return (
    <div className="warehouse-form__right" style={CARD_STYLE}>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row justify="space-between">
          <Col span={9} style={{ borderBottom: '1px solid #dedede' }}>
            <Select
              className="warehouse-form__user"
              bordered={false}
              value={profile?.fullName}
            ></Select>
          </Col>
          <Col span={14}>
            <Row justify="end">
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="date" initialValue={moment()}>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    style={{
                      paddingRight: 0
                    }}
                    format={'DD-MM-YYYY'}
                  />
                </FormItem>
              </Col>
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="time" initialValue={moment()}>
                  <TimePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    placeholder=""
                    format={'HH:mm'}
                    style={{ width: 45, paddingRight: 0, paddingLeft: 0 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ marginTop: 20 }}>
            <Row style={{ width: '100%' }}>
            <FormItem shouldUpdate noStyle>
                {() => <FormItem
                  name="supplierId"
                  rules={[{ required: true, message: 'Nhập nhà cung cấp!' }]}
                >
                  <DebounceSelectInitValue
                    // disabled={mode === MODE_VOUCHER_WAREHOUSE.VIEW}
                    initOptions={initSupplier}
                    fetchOptions={query => getListSupplier({ ...query, page: 1, limit: 10, getMore: 1 })}
                    labelKey="label"
                    searchKey="name"
                    valueInit={form.getFieldValue('supplierId')}
                    valueKey={"_id"}
                    onSelect={onSelectSupplier}
                    style={{ width: 328 }}
                  />
                </FormItem>}
              </FormItem>

              <Button
                size="small"
                style={{ width: 32, height: 32 }}
                onClick={setSupplierFormOpen}
              >
                <PlusOutlined size="small" />
              </Button>
            </Row>
          </Col>
        </Row>

        <Row className="warehouse-form__row" style={{ marginTop: 0 }}>
          <label>Mã phiếu nhập</label>

          <FormItem noStyle name="code">
            <Input
              bordered={false}
              className="warehouse-form__custom-input warehouse-form__code"
              style={{ width: 155 }}
              placeholder="Mã phiếu tự động"
              disabled
            />
          </FormItem>
        </Row>
        <Row style={{ marginTop: 20 }}> Mã đặt hàng nhập</Row>
        <Row className="warehouse-form__row">
          <label>Trạng thái</label>
          <label>
            {!isGetWarehouseLoading &&
              (PURCHASE_ORDER_STATUS[initWarehouse?.status] ||
                PURCHASE_ORDER_STATUS.DRAFT)}{' '}
          </label>
        </Row>

        <Row className="warehouse-form__row">
          <label>Tổng tiền hàng: </label>
          <label>{formatCurrency(totalAmount)}</label>
        </Row>

      {!typePurchaseIsPharma &&  <Row className="warehouse-form__row">
          <label>Giảm giá: </label>
          <DiscountInput
            discount={summaryDiscount}
            onTypeChange={onSummaryDiscountTypeChange}
            onValueChange={onSummaryDiscountChange}
            max={summaryDiscount.type === PERCENT ? 100 : totalAmount}
          >
            {' '}
            <div className="warehouse-form-table__discount">
              {' '}
              {formatCurrency(getDiscountValue(totalAmount, summaryDiscount))}
            </div>
          </DiscountInput>
        </Row>}

        {!typePurchaseIsPharma && <Row className="warehouse-form__row">
          {' '}
          <label>Cần trả nhà cung cấp </label>{' '}
          <label style={{ color: 'rgb(45, 183, 245)' }}>
            {formatCurrency(
              getPriceAfterDiscount(totalAmount, summaryDiscount)
            )}
          </label>
        </Row>}

        { typePurchaseIsPharma &&  isEditingDrafWarehouse  && (
          <Row className="warehouse-form__row">
            {' '}
            <label>Đã trả nhà cung cấp </label>{' '}
            <label
              style={{ cursor: 'pointer', color: 'rgb(45, 183, 245)' }}
              onClick={() => setIsPaymentHistoryOpen(true)}
            >
              {formatCurrency(totalPaid)}
            </label>
          </Row>
        )}

        {needToPay > 0 && (
          <div className="sale-payment-method">
            <Row className="warehouse-form__row" align="middle">
              <label className="fw-600">Tiền Trả nhà cung cấp </label>{' '}
              {isEditingCompletedWarehouse ? (
                <InputNumber
                  disabled
                  style={{ width: 155, padding: 0 }}
                  bordered={false}
                  className="warehouse-form__custom-input sale-payment-value"
                  value={totalPaid}
                  {...currencyFormatter}
                />
              ) : (
                <InputNumber
                  onChange={val => onPaymentChange('value', val)}
                  style={{ width: 155, padding: 0 }}
                  bordered={false}
                  className="warehouse-form__custom-input sale-payment-value"
                  value={payment.value}
                  {...currencyFormatter}
                />
              )}
            </Row>

            <div>
              <Row className="warehouse-form__row">
                <Radio.Group
                  onChange={e => onPaymentChange('method', e.target.value)}
                  value={payment.method}
                  disabled={isEditingCompletedWarehouse}
                >
                  <Radio value={CASH}>Tiền mặt</Radio>
                  <Radio value={CARD}>Thẻ</Radio>
                  <Radio value={TRANSFER}>Chuyển khoản</Radio>
                </Radio.Group>
              </Row>
            </div>
          </div>
        )}

        <Row className="warehouse-form__row">
          <label className="fw-600">Tính vào công nợ </label>{' '}
          <label>
            {' '}
            {formatNumber(
              payment.value +
                totalPaid -
                getPriceAfterDiscount(totalAmount, summaryDiscount)
            )}
          </label>
        </Row>

        <Row className="warehouse-form__row">
          <FormItem style={{ width: '100%' }} name="note">
            <Input.TextArea
              bordered={false}
              placeholder="Ghi chú"
              style={{ width: '100%', paddingLeft: 0 }}
              className="warehouse-form__custom-input"
              rows={1}
            ></Input.TextArea>
          </FormItem>
        </Row>

        {!isGetWarehouseLoading && (
          <Row className="warehouse-form__submit">
            {isActionLoading ? (
              <Button disabled>Thoát</Button>
            ) : (
              <Link to="/warehouse">
                <Button>Thoát</Button>
              </Link>
            )}

            {isDraftWarehouse && (
              <Button
                onClick={() => (statusRef.current = DRAFT)}
                htmlType="submit"
                type="primary"
                style={{ marginLeft: 10 }}
                loading={isActionLoading}
              >
                Lưu
              </Button>
            )}

            <Button
              onClick={() => (statusRef.current = COMPLETED)}
              htmlType="submit"
              type="primary"
              style={{ marginLeft: 10 }}
              loading={isActionLoading}
            >
              {isEditingCompletedWarehouse ? 'Cập nhật' : 'Hoàn thành'}
            </Button>
          </Row>
        )}
      </Form>

      <SupplierForm
        callback={supplier => form.setFieldsValue({ supplierId: supplier._id })}
        isOpen={isSupplierFormOpen}
        onClose={() => setSupplierFormOpen(false)}
      />

      <PaymentHistoryModal
        isOpen={isPaymentHistoryOpen}
        record={{ paymentNotes: initWarehouse?.paymentNotes }}
        onClose={() => setIsPaymentHistoryOpen(false)}
      />
    </div>
  );
};

export default SummaryForm;
