import { Col, Dropdown, Menu, Row, notification } from 'antd';
import { memo, useMemo } from 'react';
import { useSubmitDrugRequest } from '~/hooks';
import { useDrugValue } from '.';


  
function ButtonRequest() {
    const { product} =useDrugValue();
    const [loading,handle]=useSubmitDrugRequest()
    const memoId = useMemo(()=> product._id,[product._id])
    const onMenuClick =(e)=>{
        switch (e.key) {
            case '2': handleSubmit(true);
                break;
            default: handleSubmit();
                break;
        }
    }
    const handleSubmit =(isUpdate)=>{
        handle({action:'saveProduct',id:memoId,isUpdate:Boolean(isUpdate)});
        notification.success({
            message:'Đã tạo yêu cầu liên thông',
            duration:2,
        });
    }
    const menu = (
        <Menu onClick={onMenuClick}>
          <Menu.Item key="1">
             Liên thông mới
          </Menu.Item>
          <Menu.Item key="2">
             Liên thông cập nhật
          </Menu.Item>
        </Menu>
      );
    return (
        <Row style={{width:'100%'}}>
                <Col span={24} align={'center'}>
                    <Dropdown.Button
                        overlay={menu}
                        trigger={['click']}
                        type='primary' 
                        disabled={loading}
                        onClick={()=>handleSubmit()}
                        style={{borderRadius:8}}>
                        Tạo liên thông
                    </Dropdown.Button>
                </Col>
            </Row>
    )
}

export default memo(ButtonRequest)
