import { Col, Divider, Image, Modal, Row, Space, Typography } from 'antd'
import { get } from 'lodash'
import React, { useContext } from 'react'
import { useState } from 'react'
import { createContext } from 'react'
import { useInformationPayment, useInitInformationPayment } from '~/hooks/worldClinic/imformationPayment'
const InfoPaymentProvider = createContext()
export const useInfoPaymentContext  = ()=>useContext(InfoPaymentProvider)
function InfoPaymentContext({children}) {
    const [visible,setVisibleModalInfoPayment]=useState(false)
    const [dataInfoPayment] =useInformationPayment()
    const initInfoPayment = useInitInformationPayment(dataInfoPayment)

    return (
        <InfoPaymentProvider.Provider value={{setVisibleModalInfoPayment}}>
            {children}
            <Modal
                visible={visible}
                onCancel={()=>setVisibleModalInfoPayment(false)}
                destroyOnClose={()=>setVisibleModalInfoPayment(false)}
                footer={false}
            >
                <Row>
                    <Typography.Title level={4}>Thông tin thanh toán</Typography.Title>
                </Row>
                <Row align='center'>
                    <Col align='center'>
                        <Space direction='vertical' align='start' >
                            {get(initInfoPayment,'description',[]).map((value,idx)=>(
                                <Typography.Text key={idx}>
                                    {value}
                                </Typography.Text>))}
                        </Space>
                    </Col>
                </Row>

                <Divider/>

                <Row align='center'>
                    <Col span={22} sm={20} md={17}>
                        <Image preview={false} src={get(initInfoPayment,'image','')} alt='Image' style={{borderRadius:8}} />
                    </Col>
                </Row>
                
            
            </Modal>
        </InfoPaymentProvider.Provider>
    )
}

export default InfoPaymentContext
