import requester from '../requester';

const permission = {

  getAllPolicySystemPartner: (partnerId) => requester.get('/sysPartnerPolicy', partnerId),
  getAllPolicySystemBranches: ({branchId}) => requester.get(`/resource-permission-filter/${branchId}`),

  removePermissionSystemPartner: ({ partnerId, ...rest }) =>
    requester.delete(`/resources-partner/${partnerId}/permission`, { ...rest }),
  updatePermissionSystemPartner: ({ partnerId, ...rest }) =>
    requester.put(`/resources-partner/${partnerId}/permission`, { ...rest }),
  
  removePermissionSystemBranches: ({ branchId, ...rest }) =>
    requester.delete(`/resources-branch/${branchId}/permission`, { ...rest }),
  updatePermissionSystemBranches: ({ branchId, ...rest }) =>
   requester.put(`/resources-branch/${branchId}/permission`, { ...rest }),

  getResources: (branchId) => requester.get('/resource-permission', branchId),
  getResourcesBranch: (branchId) => requester.get('/sysBranchPolicy', branchId)
};

export default permission;
