export const GET_RECEIPTS_PAYMENT_TYPE_REQUEST = 'GET_RECEIPTS_PAYMENT_TYPE_REQUEST';
export const GET_RECEIPTS_PAYMENT_TYPE_SUCCESS = 'GET_RECEIPTS_PAYMENT_TYPE_SUCCESS';
export const GET_RECEIPTS_PAYMENT_TYPE_FAILED = 'GET_RECEIPTS_PAYMENT_TYPE_FAILED';

export const GET_RECEIPT_PAYMENT_TYPE_REQUEST = 'GET_RECEIPT_PAYMENT_TYPE_REQUEST';
export const GET_RECEIPT_PAYMENT_TYPE_SUCCESS = 'GET_RECEIPT_PAYMENT_TYPE_SUCCESS';
export const GET_RECEIPT_PAYMENT_TYPE_FAILED = 'GET_RECEIPT_PAYMENT_TYPE_FAILED';

export const CREATE_RECEIPT_PAYMENT_TYPE_REQUEST = 'CREATE_RECEIPT_PAYMENT_TYPE_REQUEST';
export const CREATE_RECEIPT_PAYMENT_TYPE_SUCCESS = 'CREATE_RECEIPT_PAYMENT_TYPE_SUCCESS';
export const CREATE_RECEIPT_PAYMENT_TYPE_FAILED = 'CREATE_RECEIPT_PAYMENT_TYPE_FAILED';

export const UPDATE_RECEIPT_PAYMENT_TYPE_REQUEST = 'UPDATE_RECEIPT_PAYMENT_TYPE_REQUEST';
export const UPDATE_RECEIPT_PAYMENT_TYPE_SUCCESS = 'UPDATE_RECEIPT_PAYMENT_TYPE_SUCCESS';
export const UPDATE_RECEIPT_PAYMENT_TYPE_FAILED = 'UPDATE_RECEIPT_PAYMENT_TYPE_FAILED';

export const DELETE_RECEIPT_PAYMENT_TYPE_REQUEST = 'DELETE_RECEIPT_PAYMENT_TYPE_REQUEST';
export const DELETE_RECEIPT_PAYMENT_TYPE_SUCCESS = 'DELETE_RECEIPT_PAYMENT_TYPE_SUCCESS';
export const DELETE_RECEIPT_PAYMENT_TYPE_FAILED = 'DELETE_RECEIPT_PAYMENT_TYPE_FAILED';

export const GET_RECEIPTS_PAYMENT_FILTER_TYPE_REQUEST = 'GET_RECEIPTS_PAYMENT_FILTER_TYPE_REQUEST';
export const GET_RECEIPTS_PAYMENT_FILTER_TYPE_SUCCESS = 'GET_RECEIPTS_PAYMENT_FILTER_TYPE_SUCCESS';
export const GET_RECEIPTS_PAYMENT_FILTER_TYPE_FAILED = 'GET_RECEIPTS_PAYMENT_FILTER_TYPE_FAILED';

export const RESET_RECEIPT_PAYMENT_TYPE_STATE = 'RESET_RECEIPT_PAYMENT_TYPE_STATE';
