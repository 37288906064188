import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
} from '~/hooks/utils';

import {
  getPartners,
  createPartner,
  deletePartner,
  getPartner,
  resetPartnerState,
  updatePartner,
  getPartnerBranches,
  updateApiKeyPartner,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { useProfile } from '../user';

const PARTNER_MODULE = 'partner';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
} = getSelectors(PARTNER_MODULE);

const getSelector = key => state => state[PARTNER_MODULE][key];

const getBranchesLoadingSelector = getSelector('isGetBranchesLoading');
const branchesSelector = getSelector('branches');
const getBranchesFailedSelector = getSelector('getBranchesFailed');

const isSubmitApiKeyLoadingSelector =getSelector("isSubmitApiKeyLoading")
const updateApiKeySuccessSelector =getSelector("updateApiKeySuccess")
const updateApiKeyFailedSelector = getSelector("updateApiKeyFailed")

const pagingSelector = getSelector('paging');

// export const usePartnerBranchesParams = (key) => {
//   const query = useQueryParams();
//   const page = query.get('page') || 1;
//   const limit = query.get('limit') || 10;
//   const keyword = query.get('keyword');
//   const partnerId = key;

//   // const createSuccess = useSelector(createSuccessSelector);
//   // const updateSuccess = useSelector(updateSuccessSelector);

//   return useMemo(() => {
//     return {
//       page,
//       limit,
//       keyword,
//       partnerId
//     };
//     //eslint-disable-next-line
//   }, [
//     page,
//     limit,
//     keyword,
//     partnerId
//     // createSuccess,
//     // updateSuccess
//   ]);
// };

// export const usePartnerBranchesQuery = (query) => {
//   const history = useHistory();
//   const {path, url} = useRouteMatch()
//   const [keyword, setKeyword] = useState(query.keyword);

//   const onParamChange = (param) => {
//     // if (param['modules'] && !param['modules'].length) {
//     //   param = { modules: undefined };
//     // }

//     history.push({
//       pathname: `#/`,
//       search: new URLSearchParams(
//         getExistProp({
//           ...query,
//           ...param
//         })
//       ).toString()
//     });
//   };

//   return [keyword, { setKeyword, onParamChange }];
// };

export const usePartners = query => {
  return useFetchByParam({
    action: getPartners,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query,
  });
};

export const useCreatePartner = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới đối tác thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới đối tác thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPartner,
  });
};

export const useUpdatePartner = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật đối tác thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật đối tác thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePartner,
  });
};

export const useDeletePartner = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, 'Xoá đối tác thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá đối tác thất bại');

  return useSubmit({
    loadingSelector,
    action: deletePartner,
  });
};

export const usePartner = params => {
  return useFetchByParam({
    action: getPartner,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params,
  });
};

export const useInitPartner = (partner, id) => {
  return useMemo(() => {
    if (!partner || !id) {
      return {};
    }

    const initCompany = {
      ...partner,
    };

    return initCompany;
  }, [partner, id]);
};

const getBranchSelector = key => state => state.branch[key];

export const usePartnerBranches = id => {
  const createBranchSuccess = useSelector(getBranchSelector('createSuccess'));
  const updateBranchSuccess = useSelector(getBranchSelector('updateSuccess'));
  const deleteBranchSuccess = useSelector(getBranchSelector('updateFaield'));

  //eslint-disable-next-line
  const param = useMemo(() => (id ? { id } : null), [
    id,
    createBranchSuccess,
    updateBranchSuccess,
    deleteBranchSuccess,
  ]);

  return useFetchByParam({
    action: getPartnerBranches,
    loadingSelector: getBranchesLoadingSelector,
    dataSelector: branchesSelector,
    failedSelector: getBranchesFailedSelector,
    param,
  });
};

export const useUpdatePartnerParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

    history.push({
      pathname: '/partner',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
        }),
      ).toString(),
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const usePartnerQueryParams = () => {
  // const [profile] = useProfile()
  // const partnerId = profile.partnerId
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const modules = query.get('modules');
  const page = query.get('page');
  const searchBranch = query.get('searchBranch');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId,
      modules,
      searchBranch,
      // partnerId
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    modules,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    searchBranch,
    // partnerId
  ]);
};

export const usePartnerPaging = () => useSelector(pagingSelector);

export const useResetPartner = () => {
  useResetState(resetPartnerState);
};


export const useUpdateApiKeyPartner = (callback) => {
  useSuccess(updateApiKeySuccessSelector, 'Cập nhật mã liên kết đối tác thành công', callback);
  useFailed(updateApiKeyFailedSelector, 'Cập nhật mã liên kết đối tác thất bại');

  return useSubmit({
    loadingSelector: isSubmitApiKeyLoadingSelector,
    action: updateApiKeyPartner
  });
};