import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Row,
  Button,
  InputNumber,
  Select,
  Col,
  DatePicker,
  TimePicker,
  Radio
} from 'antd';
import moment from 'moment';
import {
  formatCurrency,
  formatNumber,
  getUtcDateTime,
  getTotal
} from '~/utils/helper';
import {
  CARD_STYLE,
  DISCOUNT_TYPES,
  PAYMENT_STATE,
  PAYMENT_METHODS,
  PURCHASE_ORDER_STATUS
} from '~/constants/defaultValue';
import { PlusOutlined } from '@ant-design/icons';
import {
  getPriceAfterDiscount,
  getDiscountValue,
  caculateDiscountValueOnTypeChange
} from '~/utils/caculator';
import {
  useSuppliers,
  useResetSupplier,
  useCreatePurchaseReturn,
  useUpdatePurchaseReturn,
  useProfile,
  useDebounceSelectSupplier
} from '~/hooks';
import SupplierForm from '~/components/WorldClinic/Supplier/Form';
import currencyFormatter from '~/utils/currencyFormatter';
import toastr from 'toastr';
import DiscountInput from '~/components/Common/DiscountInput';
import { PATH_CLINIC } from '~/routes/paths';
import PaymentHistoryModal from '~/components/Common/PaymentHistoryModal';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import DebounceSelectInitValue from '~/components/Utils/DebounceSelectInitValue';
import api from '~/api';
import { get } from 'lodash';

const { CASH, CARD, TRANSFER } = PAYMENT_METHODS;
const { PERCENT, VALUE } = DISCOUNT_TYPES;
const { DRAFT, COMPLETED } = PAYMENT_STATE;

const FormItem = Form.Item;
const { Option } = Select;

const query = { limit: 200 };

const SummaryForm = ({
  formData,
  initPurchaseReturn,
  isGetPurchaseReturnLoading,
}) => {
  const isEditingCompletedPurchaseReturn =
    initPurchaseReturn?._id && initPurchaseReturn?.status === COMPLETED;

  const isEditingDrafPurchaseReturn =
    initPurchaseReturn && initPurchaseReturn?.status === DRAFT;

  const isDraftPurchaseReturn =
    !initPurchaseReturn || initPurchaseReturn?.status !== COMPLETED;

  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);
  const [profile] = useProfile();
  const [form] = Form.useForm();
  const statusRef = useRef(DRAFT);
  const history = useHistory();
  const onSubmitSuccess = purchaseReturn => {
    history.push({
      pathname: PATH_CLINIC.purchaseReturn.root,
      search: new URLSearchParams({ id: purchaseReturn._id }).toString()
    });
  };

  const [
    isCreatingPurchaseReturn,
    createPurchaseReturn
  ] = useCreatePurchaseReturn(onSubmitSuccess);
  const [
    isUpdatingPurchaseReturn,
    updatePurchaseReturn
  ] = useUpdatePurchaseReturn(onSubmitSuccess);

  const [suppliers, isGetSuppliersLoading] = useSuppliers(query);
  const {getListSupplier,initSupplier,onSelectSupplier} = useDebounceSelectSupplier((args) => form.setFieldsValue({supplierId : args}))
  const [isSupplierFormOpen, setSupplierFormOpen] = useState(false);
  const [summaryDiscount, setSummaryDiscount] = useState({
    type: VALUE,
    value: 0
  });

  const [payment, setPayment] = useState({ method: CASH, value: 0 });

  const onPaymentChange = (key, value) => {
    setPayment(payment => ({
      ...payment,
      [key]: value
    }));
  };
  useEffect(() => {
    if (initPurchaseReturn) {
      const {
        discountValue,
        discountType,
        discountPercent,
        supplierId
      } = initPurchaseReturn;

      setSummaryDiscount({
        type: discountType,
        value: discountType === VALUE ? discountValue : discountPercent
      });

      form.setFieldsValue({
        supplierId: initPurchaseReturn.supplierId,
        code: initPurchaseReturn.code,
        note: initPurchaseReturn.note,
        date: moment(initPurchaseReturn.purchasedAt),
        time: moment(initPurchaseReturn.purchasedAt)
      });
      if (supplierId) { // Init fetch first
        getListSupplier({ getMore: 1, ids: supplierId }, true)
      }
    }
  }, [initPurchaseReturn, form]);

  useResetSupplier();
  
  const totalAmount = useMemo(() => {
    return formData.reduce((amount, { quantity, returnPrice, discount }) => {
      return (amount += quantity * getPriceAfterDiscount(returnPrice, discount));
    }, 0);
  }, [formData]);

  const totalPaid = useMemo(() => {
    if (!initPurchaseReturn) {
      return 0;
    }

    return getTotal(initPurchaseReturn.paymentNotes || [], 'paymentAmount');
  }, [initPurchaseReturn]);

  const onSummaryDiscountChange = value => {
    setSummaryDiscount(discount => ({ ...discount, value }));
  };

  const onSummaryDiscountTypeChange = type => {
    const discountValue = caculateDiscountValueOnTypeChange({
      type,
      value: summaryDiscount.value,
      price: totalAmount
    });

    setSummaryDiscount({ type, value: discountValue });
  };

  const onFinish = values => {
    const variantHasNoBatch = formData.find(
      ({ selectedBatches }) => !selectedBatches.length
    );

    if (variantHasNoBatch) {
      toastr.error('Vui lòng nhập số lô cho tất cả sản phẩm');
      return;
    }

    const converSummaryDiscount = type => {
      return caculateDiscountValueOnTypeChange({
        value: summaryDiscount.value,
        price: totalAmount,
        type
      });
    };

    const model = {
      ...values,

      status: statusRef.current,
      purchaseReturnItems: formData.map(variant => {
        let {
          discount: { type, value },
          buyPrice,
          returnPrice
        } = variant;

        returnPrice = Number(String(returnPrice).replace(/[.,]/g, ''))

        const convertDiscount = type => {
          return caculateDiscountValueOnTypeChange({
            type,
            value,
            price: returnPrice
          });
        };

        // const returnPrice = getPriceAfterDiscount(buyPrice, variant.returnPrice);

        return {
          productId: variant.productId,
          variantId: initPurchaseReturn ? variant.variantId : variant._id,
          batches: variant.selectedBatches.map(({ _id, batchId, quantity }) => {
            return {
              batchId: batchId || _id,
              quantity: quantity
            };
          }),
          buyPrice,
          returnPrice,
          finalPrice: variant.quantity * getPriceAfterDiscount(returnPrice, variant.discount),
          discountValue: type === VALUE ? value : convertDiscount(VALUE),
          discountPercent: type === PERCENT ? value : convertDiscount(PERCENT),
          discountType: type,
          total: getPriceAfterDiscount(returnPrice, variant.discount) * variant.quantity
        };
      }),

      discountValue:
        summaryDiscount.type === VALUE
          ? summaryDiscount.value
          : converSummaryDiscount(VALUE),
      discountPercent:
        summaryDiscount.type === PERCENT
          ? summaryDiscount.value
          : converSummaryDiscount(PERCENT),
      discountType: summaryDiscount.type
    };

    model.payment = {
      method: payment.method,
      amount: payment.value
    };

    model.purchasedAt = getUtcDateTime(values.date, values.time);
    model.date = undefined;
    model.time = undefined;

    const { _id } = initPurchaseReturn || {};

    if (_id) {
      updatePurchaseReturn({ ...model, _id });
    } else {
      createPurchaseReturn(model);
    }
  };
  const onFinishFailed = (errors) => {
    toastr.clear()
    toastr.error('Vui lòng nhập nhà cung cấp')
  }

  const isActionLoading = isCreatingPurchaseReturn || isUpdatingPurchaseReturn;
  const needToPay =
    getPriceAfterDiscount(totalAmount, summaryDiscount) - totalPaid;
  return (
    <div className="warehouse-form__right" style={CARD_STYLE}>
      <Form form={form} onFinish={onFinish} scrollToFirstError={true} onFinishFailed={onFinishFailed} >
        <Row justify="space-between">
          <Col span={9} style={{ borderBottom: '1px solid #dedede' }}>
            <Select
              className="warehouse-form__user"
              bordered={false}
              value={profile?.fullName}
            ></Select>
          </Col>
          <Col span={14}>
            <Row justify="end">
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="date" initialValue={moment()}>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    style={{
                      paddingRight: 0
                    }}
                    format={'DD-MM-YYYY'}
                  />
                </FormItem>
              </Col>
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="time" initialValue={moment()}>
                  <TimePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    placeholder=""
                    format={'HH:mm'}
                    style={{ width: 45, paddingRight: 0, paddingLeft: 0 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ marginTop: 20 }}>
            <Row style={{ width: '100%' }}>
              {/* <FormItem
                name="supplierId"
                rules={[{ required: true, message: 'Nhập nhà cung cấp!'}]}
              >
                <Select
                  loading={isGetSuppliersLoading}
                  showSearch
                  style={{ width: 328 }}
                  on
                >
                  {suppliers.map(({ name, _id }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem> */}
              <FormItem shouldUpdate noStyle>
                {() => <FormItem
                  name="supplierId"
                  rules={[{ required: true, message: 'Nhập nhà cung cấp!' }]}
                >
                  <DebounceSelectInitValue
                    // disabled={mode === MODE_VOUCHER_WAREHOUSE.VIEW}
                    initOptions={initSupplier}
                    fetchOptions={query => getListSupplier({ ...query, page: 1, limit: 10, getMore: 1 })}
                    labelKey="label"
                    searchKey="name"
                    valueInit={form.getFieldValue('supplierId')}
                    valueKey={"_id"}
                    onSelect={onSelectSupplier}
                    style={{ width: 328 }}
                  />
                </FormItem>}
              </FormItem>

              <Button
                size="small"
                style={{ width: 32, height: 32 }}
                onClick={setSupplierFormOpen}
              >
                <PlusOutlined size="small" />
              </Button>
            </Row>
          </Col>
        </Row>

        <Row className="warehouse-form__row" style={{ marginTop: 0 }}>
          <label>Mã trả hàng nhập</label>

          <FormItem noStyle name="code">
            <Input
              bordered={false}
              className="warehouse-form__custom-input warehouse-form__code"
              style={{ width: 155 }}
              placeholder="Mã phiếu tự động"
              disabled
            />
          </FormItem>
        </Row>

        <Row className="warehouse-form__row">
          <label>Trạng thái</label>
          <label>
            {!isGetPurchaseReturnLoading &&
              (PURCHASE_ORDER_STATUS[initPurchaseReturn?.status] ||
                PURCHASE_ORDER_STATUS.DRAFT)}{' '}
          </label>
        </Row>

        <Row className="warehouse-form__row">
          <label>Tổng tiền hàng: </label>
          <label>{formatCurrency(totalAmount)}</label>
        </Row>

        <Row className="warehouse-form__row">
          <label>Giảm giá: </label>
          <DiscountInput
            discount={summaryDiscount}
            onTypeChange={onSummaryDiscountTypeChange}
            onValueChange={onSummaryDiscountChange}
            max={summaryDiscount.type === PERCENT ? 100 : totalAmount}
          >
            {' '}
            <div className="warehouse-form-table__discount">
              {' '}
              {formatCurrency(getDiscountValue(totalAmount, summaryDiscount))}
            </div>
          </DiscountInput>
        </Row>

        <Row className="warehouse-form__row">
          {' '}
          <label>Nhà cung cấp cần trả </label>{' '}
          <label style={{ color: 'rgb(45, 183, 245)' }}>
            {formatCurrency(
              getPriceAfterDiscount(totalAmount, summaryDiscount)
            )}
          </label>
        </Row>

        {isEditingDrafPurchaseReturn && (
          <Row className="warehouse-form__row">
            {' '}
            <label>Nhà cung cấp đã trả </label>{' '}
            <label
              style={{ cursor: 'pointer', color: 'rgb(45, 183, 245)' }}
              onClick={() => setIsPaymentHistoryOpen(true)}
            >
              {formatCurrency(totalPaid)}
            </label>
          </Row>
        )}

        {needToPay > 0 && (
          <div className="sale-payment-method">
            <Row className="warehouse-form__row" align="middle">
              <label className="fw-600">Tiền nhà cung cấp trả </label>{' '}
              {isEditingCompletedPurchaseReturn ? (
                <InputNumber
                  disabled
                  style={{ width: 155, padding: 0 }}
                  bordered={false}
                  className="warehouse-form__custom-input sale-payment-value"
                  value={totalPaid}
                  {...currencyFormatter}
                />
              ) : (
                <InputNumber
                  onChange={val => onPaymentChange('value', val)}
                  style={{ width: 155, padding: 0 }}
                  bordered={false}
                  className="warehouse-form__custom-input sale-payment-value"
                  value={payment.value}
                  {...currencyFormatter}
                />
              )}
            </Row>

            <div>
              <Row className="warehouse-form__row">
                <Radio.Group
                  onChange={e => onPaymentChange('method', e.target.value)}
                  value={payment.method}
                  disabled={isEditingCompletedPurchaseReturn}
                >
                  <Radio value={CASH}>Tiền mặt</Radio>
                  <Radio value={CARD}>Thẻ</Radio>
                  <Radio value={TRANSFER}>Chuyển khoản</Radio>
                </Radio.Group>
              </Row>
            </div>
          </div>
        )}

        <Row className="warehouse-form__row">
          <label className="fw-600">Tính vào công nợ </label>{' '}
          <label>
            {' '}
            {formatNumber(
              getPriceAfterDiscount(totalAmount, summaryDiscount) -
              payment.value -
              totalPaid
            )}
          </label>
        </Row>

        <Row className="warehouse-form__row">
          <FormItem style={{ width: '100%' }} name="note">
            <Input.TextArea
              bordered={false}
              placeholder="Ghi chú"
              style={{ width: '100%', paddingLeft: 0 }}
              className="warehouse-form__custom-input"
              rows={1}
            ></Input.TextArea>
          </FormItem>
        </Row>
        {!isGetPurchaseReturnLoading && (
          <Row className="warehouse-form__submit">
            {isActionLoading ? (
              <Button disabled>Thoát</Button>
            ) : (
              <Link to={PATH_CLINIC.purchaseReturn.root}>
                <Button>Thoát</Button>
              </Link>
            )}

            {isDraftPurchaseReturn && (
              <Button
                onClick={() => (statusRef.current = DRAFT)}
                htmlType="submit"
                type="primary"
                style={{ marginLeft: 10 }}
                loading={isActionLoading}
              >
                Lưu
              </Button>
            )}

            <Button
              onClick={() => (statusRef.current = COMPLETED)}
              htmlType="submit"
              type="primary"
              style={{ marginLeft: 10 }}
              loading={isActionLoading}
            >
              {isEditingCompletedPurchaseReturn ? 'Cập nhật' : 'Hoàn thành'}
            </Button>
          </Row>
        )}
      </Form>

      <SupplierForm
        callback={supplier => form.setFieldsValue({ supplierId: supplier._id })}
        isOpen={isSupplierFormOpen}
        onClose={() => setSupplierFormOpen(false)}
      />

      <PaymentHistoryModal
        isOpen={isPaymentHistoryOpen}
        record={{ paymentNotes: initPurchaseReturn?.paymentNotes }}
        onClose={() => setIsPaymentHistoryOpen(false)}
      />
    </div>
  );
};

export default SummaryForm;
