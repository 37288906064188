export const STATUS_ORDER_QUOTATION = {
    NEW: 'NEW',
    CONFIRMED :  'CONFIRMED',
    COMPLETED :  'COMPLETED',
    CANCELLED:  'CANCELLED',
}
export const STATUS_ORDER_QUOTATION_CONTENT = {
    NEW:'Mới',
    CONFIRMED : 'Đã xác nhận',
    COMPLETED : 'Hoàn thành',
    CANCELLED: 'Đã huỷ'
}
export const STATUS_ORDER_QUOTATION_COLOR = {
    NEW:'default',
    CONFIRMED : 'blue',
    COMPLETED : 'green',
    CANCELLED: 'blue'
}

export const ORDER_STATUS_VI = {
    [STATUS_ORDER_QUOTATION.NEW]: { name: STATUS_ORDER_QUOTATION_CONTENT.NEW, color: 'default',colorStyle : '#1d39c4' },
    [STATUS_ORDER_QUOTATION.CONFIRMED]: { name: STATUS_ORDER_QUOTATION_CONTENT.CONFIRMED , color: 'blue' ,colorStyle : '#1890ff'},
    [STATUS_ORDER_QUOTATION.CANCELLED]: { name: STATUS_ORDER_QUOTATION_CONTENT.CANCELLED, color: 'blue',colorStyle : '#f5222d' },
    [STATUS_ORDER_QUOTATION.COMPLETED]: { name: STATUS_ORDER_QUOTATION_CONTENT.COMPLETED, color: 'green',colorStyle : 'rgba(0, 0, 0, 0.85)' },
};
  