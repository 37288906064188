export const GET_PRODUCT_RETURNS_REQUEST = 'GET_PRODUCT_RETURNS_REQUEST';
export const GET_PRODUCT_RETURNS_SUCCESS = 'GET_PRODUCT_RETURNS_SUCCESS';
export const GET_PRODUCT_RETURNS_FAILED = 'GET_PRODUCT_RETURNS_FAILED';

export const GET_RECEIVERS_REQUEST = 'GET_RECEIVERS_REQUEST';
export const GET_RECEIVERS_SUCCESS = 'GET_RECEIVERS_SUCCESS';
export const GET_RECEIVERS_FAILED = 'GET_RECEIVERS_FAILED';

export const RETURN_PRODUCT_REQUEST = 'RETURN_PRODUCT_REQUEST';
export const RETURN_PRODUCT_SUCCESS = 'RETURN_PRODUCT_SUCCESS';
export const RETURN_PRODUCT_FAILED = 'RETURN_PRODUCT_FAILED';

export const EXCHANGE_PRODUCT_REQUEST = 'EXCHANGE_PRODUCT_REQUEST';
export const EXCHANGE_PRODUCT_SUCCESS = 'EXCHANGE_PRODUCT_SUCCESS';
export const EXCHANGE_PRODUCT_FAILED = 'EXCHANGE_PRODUCT_FAILED';

export const UPDATE_PRODUCT_INFO_RETURN_REQUEST = 'UPDATE_PRODUCT_INFO_RETURN_REQUEST';
export const UPDATE_PRODUCT_INFO_RETURN_SUCCESS = 'UPDATE_PRODUCT_INFO_RETURN_SUCCESS';
export const UPDATE_PRODUCT_INFO_RETURN_FAILED = 'UPDATE_PRODUCT_INFO_RETURN_FAILED';
