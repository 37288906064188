import { useMemo } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getNewsCategories,
  createNewsCategory,
  deleteNewsCategory,
  getNewsCategory,
  resetNewsCategoryState,
  updateNewsCategory
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.newsCategory[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useNewsCategoryQueryParams = () => {
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const deleteNewsCategorySuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    return {
      page,
      limit,
      keyword: keyword || undefined
    };
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteNewsCategorySuccess]);
};

export const useNewsCategories = query => {
  return useFetchByParam({
    action: getNewsCategories,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateNewsCategory = () => {
  const history = useHistory();
  const callback = () => history.push('/news-category');

  useSuccess(
    createSuccessSelector,
    'Tạo mới danh mục tin tức thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới danh mục tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createNewsCategory
  });
};

export const useUpdateNewsCategory = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật danh mục tin tức thành công');
  useFailed(updateFailedSelector, 'Cập nhật danh mục tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateNewsCategory
  });
};

export const useDeleteNewsCategory = () => {
  useSuccess(deleteSuccessSelector, 'Xoá danh mục tin tức thành công');
  useFailed(deleteFailedSelector, 'Xoá danh mục tin tức thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteNewsCategory
  });
};

export const useNewsCategory = id => {
  return useFetchByParam({
    action: getNewsCategory,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitNewsCategory = newsCategory =>
  useMemo(() => newsCategory || {}, [newsCategory]);

export const useResetNewsCategory = () => {
  useResetState(resetNewsCategoryState);
};
