import React, { useCallback } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useExport } from '.'
import { has } from 'lodash'

function ButtonExport({query,id,router,label,fileName,exportOption}) {
  const {handleAddExport} =useExport()
  const handle = useCallback(()=>{
    const tranFormQuery = ()=>{
       const newQuery ={...query}
       if(has(newQuery,"limit")){delete newQuery["limit"]}
       if(has(newQuery,"page")){ delete newQuery["page"]}
       return newQuery
    }
    if(!!id){
      handleAddExport({query:{...tranFormQuery(),exportOption,id:id},router,label,fileName:fileName.concat("_")})
      return
    }else{
      handleAddExport({query:{...tranFormQuery(),exportOption},router,label,fileName})
    }
    console.log("export fail!")
  },[query,id])
    return (
        <Button
        type="primary"
        onClick={()=>{handle()}}
        >
          <DownloadOutlined /> Xuất file
        </Button>
    )
}
ButtonExport.defaultProps ={
  exportOption : 3
}
export default ButtonExport