import React, { useState } from 'react';
import {
  useShippingVendors,
  useDeleteShippingVendor,
  useShippingVendorQueryParams,
  useUpdateShippingVendorParams,
  useResetShippingVendor,
  useShippingVendorPaging
} from '~/hooks';
import { Table, Popconfirm, Input, Button } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import ShippingVendorForm from './Form';

const { Search } = Input;

const ColumnActions = ({ _id, deleteShippingVendor, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
      <p onClick={() => onOpenForm(_id)}>Sửa</p>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá nhà vận chuyển này?"
        onConfirm={() => deleteShippingVendor(_id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const ShippingVendors = () => {
  const [query] = useShippingVendorQueryParams();
  const [
    keyword,
    { setKeyword, onParamChange }
  ] = useUpdateShippingVendorParams(query);
  const [shippingVendors, isLoading] = useShippingVendors(query);
  const paging = useShippingVendorPaging();
  const [shippingVendorId, setShippingVendorId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const numberOfShippingVendors = shippingVendors.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfShippingVendors === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteShippingVendor] = useDeleteShippingVendor(onDeleteSuccess);

  useResetShippingVendor();

  const onOpenForm = (id) => {
    setShippingVendorId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setShippingVendorId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên',
      key: 'name',
      dataIndex: 'name'
    },

    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    }
  ];

  columns.push({
    title: 'Thao tác',
    key: 'action',
    width: '110px',
    render: (record) => {
      return (
        <ColumnActions
          {...record}
          deleteShippingVendor={deleteShippingVendor}
          onOpenForm={onOpenForm}
        />
      );
    }
  });

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhà vận chuyển" />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <FilterCity
              value={query.cityId}
              // onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <Search
                style={{ width: 300 }}
                placeholder="Tìm nhà vận chuyển"
                enterButton
                onSearch={() => onParamChange({ keyword })}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />

              <WithPermission permission={POLICY.WRITE_PARTNER}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm()}
                >
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable
                rowCount={shippingVendors.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={shippingVendors}
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <ShippingVendorForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        id={shippingVendorId}
      />
    </div>
  );
};

export default ShippingVendors;
