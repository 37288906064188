import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDate, formatNumber } from '~/utils/helper';
import { Button, Spin, Table, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useProductStockCardOutside } from '~/hooks';
import { API_LIST, TYPE_PRODUCT_ORDER } from '~/constants/defaultValue';
import api from '~/api';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import { get } from 'lodash';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';

//  function FetCustomer( _,record) {
//   const [vale, setVal] = useState(record.supplier?.name);
//   const fetchh=useCallback(async()=>{ const {name} = record.customerId ? await api.customer.getOptions(record.customerId??"") :{name:null}
//    name &&  setVal(name)
// },[])
//   useEffect(()=>{
//     fetchh()
//   },[fetchh])
//   return (
//     <div>{vale}</div>
//   )
// }


const StockCardOut = ({product}) => {
  const [queryParams, setQueryParams] = useState({ id: product?._id,page:1,limit:10 });
  const [dataSource] = useProductStockCardOutside(queryParams);
  const stockData = get(dataSource[product?._id],'docs',[]);
  const paging = get(dataSource[product?._id],'paging',{})
  
  const {exchangeValue} = product?.selectedVariant ?? { 
    exchangeValue:1
  };
  // useEffect(() => {
  //   const newStockData = stockData?.map(e => {
  //     const supplier = product?.suppliers.find(prod => {
  //       if(!!e.supplierId){
  //        return e.supplierId === prod.supplierId
  //       }
  //       else return false
  //     })
  //     return {...e,supplier:supplier?.supplier}
  //   })
  //   const fetchCustomer = () => {
  //     let promise =[]
  //     newStockData?.map(e => {
  //       if(e.customerId){
  //         promise.push(api.customer.getOptions(e.customerId))
  //       }
  //     })
  //     Promise.allSettled(promise).then(res => {
  //       const newData = newStockData?.map(e => {
  //         if(e.customerId){
  //           const customerFind = res.find(f => {
  //             if(f.status === 'fulfilled'){
  //              return f.value._id === e.customerId
  //             }
  //           })
  //           return {...e,customer : customerFind?.value}
  //         }
  //         return e
  //       })
  //       setData(newData)
  //     })
  //   }
  //   fetchCustomer()
  // },[product.suppliers,stockData])

  const columns = useMemo(() => {
    return  [
      {
        title: 'Chứng từ',
        dataIndex: 'referenceCode',
        key: ['referenceCode'],
        render: (_, records) => {
          return records?.referenceDoc?.code
        }
      },
      {
        title: 'Phương thức',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render : (text, record) => TYPE_PRODUCT_ORDER[text] || ""
      },
      {
        title: 'Đối tác',
        // dataIndex: 'supplier ',
        key: 'supplier ',
        align: 'center',
        render: (records) => {
          const customer = get(records,'customerId',null)
          const result = get( get(records,'supplierId',customer),'name','')
          return  result
        }
      },
      {
        title: 'Thời gian',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: date => formatDate(date),
      },
      {
        title: 'Giá vốn',
        dataIndex: 'cost',
        key: 'cost',
        align: 'center',
        render: rc => {
          return formatNumber(rc);
        }
      },
      {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        render: (rc,record) => {
          const quantity = get(record,'quantity',0) / exchangeValue
          return Number(quantity).toLocaleString('vi-VN')
        }
      },
      {
        title: 'Tồn cuối',
        dataIndex: 'latestQuantity',
        key: 'latestQuantity',
        align: 'center',
        render: rc => Number((!!rc??0) / exchangeValue).toFixed(1)
      }
    ];
  }, [exchangeValue]);

  const exportCsvData = () => {
    // exportCSV({
    //   columns: columns,
    //   data: stockData,
    //   fileName: 'Danh sách thẻ kho'
    // });

    setQueryParams({ id: product?._id, isExport: 1 });
  };

  return (
    <div className="medicine-detail-tab-item medicine-remain-tab">
      <Spin spinning={!stockData}>
        <Table
          className="nested-table"
          style={{ marginTop: 20, marginBottom: 30, marginLeft: -20 }}
          size="small"
          columns={columns}
          dataSource={stockData}
          rowKey={rc => rc._id}
          onChange={(e)=>{setQueryParams((current)=>({...current,limit:e.pageSize , page : e.current}))}}
          pagination={ get(paging,'totalDocs',0) <= 10 ? false :{
            current : paging.page,
            pageSize:paging.limit,
            total:paging.totalDocs,
            showSizeChanger: false,
            showTotal: total => `Tổng cộng: ${total} `
          }}
          bordered
          rowClassName={record => (record.isSummaryRow ? 'summary-row' : '')}
        />
        <WithPermission permission={POLICIES.WRITE_PRODUCT}>
          <Typography.Paragraph style={{ textAlign: 'right' }}>
            <ButtonExport 
              fileName={'Danhsachthekho'}
              router={API_LIST.product} 
              label="Hàng hóa" 
              id={queryParams.id}
              exportOption={2}
            />
          </Typography.Paragraph>
        </WithPermission>
      </Spin>
    </div>
  );
};

export default StockCardOut;
