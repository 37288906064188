import React from 'react';
import './Dashboard.css';
import NotificationList from './NotificationList';
import Navigate from '../../components/TopNavigation/Navigation';
import NotificationReport from '~/components/WorldClinic/ProductReport/Notification';
import { Link } from 'react-router-dom';
import { Button, Layout, Space } from 'antd';
import { PATH_CLINIC } from '~/routes/paths';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container } from 'reactstrap';

import {
  MedicineBoxOutlined,
  ShopOutlined,
  ProfileOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';

const {Header} = Layout;

const buttons = [
  {
    title : 'Thuốc',
    IconStyle: {
      IconTitle: <MedicineBoxOutlined/>,
  },
  link : PATH_CLINIC.medicine.root,
  },
  {
    title : 'Nhập kho',
    IconStyle: {
      IconTitle: <ShopOutlined/>,
  },
  link : PATH_CLINIC.warehouse.root,
  },
  {
    title : 'Đặt hàng',
    IconStyle: {
      IconTitle: <ProfileOutlined/>,
  },
  link : PATH_CLINIC.productOrder.root,
  },
  {
    title : 'Hoá đơn',
    IconStyle: {
      IconTitle: <SnippetsOutlined />,
    },
    link : PATH_CLINIC.productInvoice.root,
  }
];

function App() {
  return (
  <div className='backgr'>
    <Header>
      <Navigate />
    </Header>
    <React.Fragment>
      <div className="page-content-dashboard">
        <Container fluid>
          <Breadcrumbs title="Dashboard" />
        </Container>
      </div>
    </React.Fragment>
    <div id='dashboard-btn-wrapper'>
      <div className="container-fluid">
        <div className='dashboard-row'>
          <div className='dashboard-left'>
            <Space wrap className='dashboard' size={30}>
              {
                buttons.map((bttons)=>(
                  <div>
                    <Link to = {bttons.link}>
                      <Button className='dashboard-btn' icon = {bttons.IconStyle.IconTitle}>
                        <br/>
                        {`${bttons.title}`}
                      </Button>
                    </Link>
                  </div>
                ))
              }
            </Space>
          </div>

          <div className='dashboard-right'>
            <div className="dashboard-right-box">
              <WithPermission permission={POLICIES.REPORT}>
                <NotificationReport />
                <NotificationList />
              </WithPermission>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  );
}
export default App;
