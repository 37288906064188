import React, { useEffect, useMemo, useState } from 'react';
import { Table, Input, Typography } from 'antd';
import { formatCurrency, formatNumber } from '~/utils/helper';
import { SearchOutlined } from '@ant-design/icons';
import { compact, get } from 'lodash';
import moment from 'moment'

const { Text } = Typography;

const DetailTable = ({ purchaseOrder }) => {
  const [codeKeyword, setCodeKeyword] = useState(null);
  const [nameKeyword, setNameKeyword] = useState(null);
     useEffect(()=>{
    },[purchaseOrder])
  const datasource = useMemo(() => {
    let displayedOrders = purchaseOrder?.purchaseOrderItems
      ?.filter(order =>
        !codeKeyword
          ? true
          : order?.productVariant?.variantCode
              .toLowerCase()
              .includes(codeKeyword.toLowerCase())
      )
      .filter(order =>
        !nameKeyword
          ? true
          : order?.product?.name.toLowerCase().includes(nameKeyword.toLowerCase())
      );

    const data = displayedOrders.map(order => {
      return {
        ...order,
        name: `${order?.product?.name}`
      };
    });

    return [{ isSummaryRow: true, variantId: -1 }, ...data];
  }, [codeKeyword, nameKeyword, purchaseOrder]);
  const isTypePurchasePharma = useMemo(() => purchaseOrder?.typePurchase === 'PM' , [purchaseOrder]);
  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hàng',
        key: 'code',
        render: record => {
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm mã hàng"
              value={codeKeyword}
              onChange={e => setCodeKeyword(e.target.value)}
            />
          ) : (
            record.productVariant?.variantCode
          );
        },
        width: 200
      },
      {
        title: 'Tên hàng',
        key: 'name',
        width: purchaseOrder?.typePurchase !== 'PM'? 500: 1000,
        render: record => {
          const lotArea = record?.batches?.reduce((cur, pre) => {
            const momentInvalid = (value, message) => value ? moment(value).format('DD/MM/YYYY') : message;
            const batch = pre?.batch;
            const batchRender = [
              batch?.lotNumber,
              momentInvalid(batch?.manufacturingDate,  'Không tồn tại ngày sản xuất'),
              momentInvalid(batch?.expirationDate ,'Không tồn tại hạn sử dụng'),
            ]
            return[...cur, `${(compact(batchRender).join(" - "))} - SL: ${get(pre, 'quantity', 0)}`]
          }, [])
          return record.isSummaryRow ? (
            <Input
              suffix={<SearchOutlined style={{ color: '#ced4d9' }} />}
              placeholder="Tìm tên hàng"
              value={nameKeyword}
              onChange={e => setNameKeyword(e.target.value)}
            />
          ) : (
            <>
             {record?.name}
             {lotArea && <p style={{marginTop:4}}>{lotArea?.map((e)=><p style={{marginBottom : 5}}>
              <span style={{backgroundColor:"#3D7FF3",padding:3,borderRadius:2,color:'white'}}>{e}</span>
             </p>) }</p>}
            </>
          );
        },
      },
      {
        title: 'Số lượng',
        key: 'quantity',
        dataIndex: 'quantity',
        align: 'right',
        width: purchaseOrder?.typePurchase !== 'PM'? 150: 500
      },
      ...(!isTypePurchasePharma ? [{
        title: 'Đơn giá',
        key: 'cost',
        render: record => {
          return  !record.isSummaryRow && formatCurrency(get(record,["price"],get(record,["productVariant","cost"],0)));
        },
        align: 'right'
      },
      {
        title: 'Giảm giá',
        key: 'discountValue',
        render: record => {
          return !record.isSummaryRow && formatCurrency(record.discountValue);
        },
        align: 'right'
      },
      // {
      //   title: 'Giá nhập',
      //   key: 'cost',
      //   render: record => {
      //     return !record.isSummaryRow && formatCurrency(record.cost);
      //   },
      //   align: 'right'
      // },
      {
        title: 'Thành tiền',
        key: 'amount',
        render: record => {
          // const debt =  get(record,"price",0) * record.quantity
          // return !record.isSummaryRow && formatCurrency(debt);
          return  !record.isSummaryRow && formatCurrency(record.total)
        },
        align: 'right'
      }]: [])
    ];
  }, [codeKeyword, nameKeyword,isTypePurchasePharma]);

  const summary = useMemo(() => {
    const caculateTotal = (data, key) => {
      return (data??[]).reduce((total, record) => total + record[key], 0);
    };
    const caculateTotalC = (data, key) => {
      return data.reduce((total, record) => total + (get(record,"price")*record.quantity), 0);
    };

    const totalQuantity = caculateTotal(
      purchaseOrder.purchaseOrderItems,
      'quantity'
    );
    const totalProduct = purchaseOrder.purchaseOrderItems.length;
    const totalAmount = caculateTotalC(purchaseOrder.purchaseOrderItems);
    const totalPaid = caculateTotal(
      purchaseOrder.paymentNotes,
      'paymentAmount'
    );

    const summaryData = [
      {
        label: 'Tổng số lượng:',
        value: formatNumber(totalQuantity)
      },
      {
        label: 'Tổng số mặt hàng:',
        value: totalProduct
      },
      {
        label: 'Tổng tiền hàng:',
        value: formatCurrency(totalAmount)
      },
      {
        label: ' Giảm giá:',
        value: formatCurrency(purchaseOrder.discountValue)
      },
      {
        label: 'Tổng cộng:',
        value: formatCurrency(totalAmount - purchaseOrder.discountValue)
      },
      {
        label: 'Tiền đã trả NCC:',
        value: formatCurrency(totalPaid)
      }
    ];
    const summaryDataWithPharma = [
      {
        label: 'Tổng số lượng:',
        value: formatNumber(totalQuantity || 0)
      },
      {
        label: 'Tổng cộng:',
        value: formatCurrency(purchaseOrder?.totalPrice || 0)
      },
    ];

    return  (isTypePurchasePharma ? summaryDataWithPharma : summaryData).map(({ label, value }, index) => {
      return (
        <Table.Summary.Row  style={{width:'100%'}} key={index}>
          <Table.Summary.Cell colSpan={isTypePurchasePharma ? 2 : 5} align="right">
            {label}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{value}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });
  }, [purchaseOrder]);

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        style={{width: '100%'}}
        className="nested-table"
        columns={columns}
        dataSource={datasource}
        size="small"
        pagination={false}
        rowKey={rc => rc.variantId}
        summary={() => (
          <Table.Summary className="table-summary">{summary}</Table.Summary>
        )}
      />
    </div>
  );
};

export default DetailTable;
