import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './index.scss';
import { Button, Table, Row, Tag, Input, Spin } from 'antd';
import { UnorderedListOutlined, SearchOutlined, HomeOutlined } from '@ant-design/icons';
import { CARD_STYLE, DISCOUNT_TYPES } from '~/constants/defaultValue';

import {
  useSales,
  useAddLocalSale,
  getSaleName,
  useSelectedSale,
  useSaleColumns,
  useResetSale,
  useSale,
} from '~/hooks';
import SaleSummary from './Summary';
import SearchProductsBox from '~/components/Utils/SearchProductsBox';
import SelectBatch from './Summary/SelectBatch';
import ProductsReturn from '~/components/WorldClinic/Sale/ProductsReturn';

import {
  SALE_TYPES,
  PAYMENT_METHODS,
  SALE_CASES
} from '~/constants/defaultValue';
import InvoiceTemplate from './InvoiceTemplate';
import { formatDate, formatQuantity, getTotal } from '~/utils/helper';
import { getTotalQuantity } from '~/utils/caculator';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Header from './Header';
import {
  getSaleParam,
  getDiscount,
  getSaleCase,
  getOrderItems
} from '~/utils/saleHelper';
import { connect } from 'react-redux';
import WithRules from '~/components/Common/WithRules';
import { get, head, isArray } from 'lodash';
import Api from '~/api';
import InfoPaymentContext from './InfoPayment';

const { DIRECT, RETURN } = SALE_TYPES;
const { CASH } = PAYMENT_METHODS;

const Sale = props => {
  const history = useHistory();
  const columns = useSaleColumns();
  const [sales, onSaleChange] = useSales();
  const [, addSale] = useAddLocalSale();
  const [selectedSale, selectSale] = useSelectedSale();
  const [isSelectBatchOpen, setSelectBatchOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isOpenProductsReturn, setOpenProductsReturn] = useState(false);
  const [medicineSelect, setMedicineSelect] = useState(null);
  const [medicineExchangeReturnSelect, setMedicineExchangeReturnSelect] = useState(null);

  const isReturn = selectedSale.type === RETURN

  useResetSale();

  useEffect(() => {
    history.replace({ state: {} });

  }, [history]);

  useEffect(() => {
    if(localStorage.getItem('isReturnProduct') === 'true') {
      setOpenProductsReturn(true)
    }
  }, [])

  useEffect(() => {
        setMedicineSelect([...selectedSale.medicines].filter(medicine => !medicine.typeExchangeReturn))
        setMedicineExchangeReturnSelect([...selectedSale.medicines].filter(medicine => !!medicine.typeExchangeReturn))
  }, [selectedSale])

  const saleParam = useMemo(() => {
    return getSaleParam(props.location?.state);
  }, [props.location?.state]);

  const [updatingSale, isGettingSale] = useSale(saleParam);
  useEffect( () => {
    (async()=>{
      if (updatingSale) {
        const orderItems = getOrderItems(updatingSale);
        const variantList =  orderItems.map((value)=> Api.medicine.getById(value?.product._id))
        const batchesList =  orderItems.map((value)=> Api.batch.getAll(value?.product._id))

        let relationVariants_ = []
        let batches_ = [];

         await Promise.allSettled(variantList).then(result => result.map(({status,value})=>{
          if(status === 'fulfilled'){
            relationVariants_.push(value.productVariants)
          }
        }))
         await Promise.allSettled(batchesList).then(result => result.map(({status,value})=>{
          if(status === 'fulfilled'){
            batches_.push(value)
          }
        }))
        const newSale = {
          saleOrderId: updatingSale._id,
          name: `Update_${updatingSale.code}`,
          medicines: orderItems.map((orderItem, index) => {
            return {
              ...orderItem,
              price: orderItem.price,
              discount: getDiscount(orderItem),
              selectedBatches: [
                { ...orderItem.batch, quantity: orderItem.quantity }
              ],
              batches: batches_[index],
              relationVariants: relationVariants_[index],
              selectedVariant: relationVariants_[index].find((variant) => variant._id === orderItem.variantId),
              variantCode:
                orderItem.productVariant?.variantCode ||
                orderItem.variant?.variantCode,
              unit: orderItem.productVariant?.unit,
              typeExchangeReturn: false,
            }
          }),
          summary: {
            discount: getDiscount(updatingSale),
            payment: [{ method: CASH, value: 0 }]
          },
          uuid: uuidv4(),
          type: updatingSale.saleChannel,
          customer: updatingSale.customer,
          canNotUpdateCustomer: updatingSale.paymentNoteIds.length,
          paid: getTotal(updatingSale.paymentNoteIds, 'paymentAmount'),
          date: moment(updatingSale.purchasedAt),
          time: moment(updatingSale.purchasedAt),
          paymentNoteIds: updatingSale.paymentNoteIds,
          case: getSaleCase(updatingSale),
          prescription: updatingSale.prescription,
          typeSale: updatingSale?.typeSale,
          totalPriceFromPM: updatingSale?.customerNeedToPay || 0,
          ...(updatingSale?.typeSale === 'PM' && 
            {
            deliveryInfo: {
                customerName: updatingSale?.customerInfo?.name,
                phoneNumber: updatingSale?.customerInfo?.phoneNumber,
                address: updatingSale?.deliveryAddress || '',
                deliveryTransport: updatingSale?.dataTransportUnit?.transportUnit || '',
                payer: updatingSale?.dataTransportUnit?.payer || '',
                logisticFee: updatingSale?.dataTransportUnit?.totalFee || 0,
                logisticCode: updatingSale?.dataTransportUnit?.code || ''
              }
            }
          ),
        ...(updatingSale?.typeSale === 'PM' &&{checkIn: updatingSale?.checkIn,
            checkOut: updatingSale?.checkOut
          }),
          status: updatingSale.status
        };
  
        addSale(newSale);
      }
    })()
  }, [updatingSale, addSale]);

  const onAddMedicine = (medicine, type)=> {
    const currentMedicines = selectedSale.medicines.filter(
      ({ productId }) => productId !== medicine.productId);
      /// get batch last time
    let selectedVariant = medicine?.relationVariants.find((variant)=>variant._id===medicine?._id),
      axQuantity = medicine.quantity ?? 1;
    let initBatch =  get(medicine,'batches',[]).reduce((final,finalCurrent,i)=>{
      let dateB = new Date(final?.expirationDate),
          dateA = new Date(finalCurrent.expirationDate)
          // Add condition exPairDate valid for sale -
          if((finalCurrent.quantity/selectedVariant.exchangeValue>=axQuantity && dateB > dateA)|| i===0  ){
            return finalCurrent
          }
          return final
    },{}) 
    const selectedBatches = Object.values(initBatch).length ? [{ ...initBatch, quantity: 1 }] : []

    const addedMedicine = {
      ...medicine,
      cost : get(medicine,'price',0),
      price : get(medicine,'price',0),
      discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
      quantity: Object.values(initBatch).length ? 1 : 0,
      selectedBatches,
      variantId: medicine._id,
      typeExchangeReturn: false,
      selectedVariant
    };

    onSaleChange(selectedSale.uuid, {
      medicines: [addedMedicine, ...currentMedicines]
    });
  };

  const onAddMedicineExchangeReturn = (medicine) => {
    
    onAddMedicine(medicine)
  }

  const onSelectBatch = batches => {
    const selectedBatches = batches.filter(({ isSelected }) => isSelected);
    const updatedVariant = {
      ...selectedVariant,
      selectedBatches,
      quantity: getTotalQuantity(selectedBatches)
    };

    const nextMedicines = selectedSale?.medicines.map(variant => {
      return variant.variantCode !== updatedVariant.variantCode
        ? variant
        : updatedVariant;
    });

    onSaleChange(selectedSale.uuid, {
      medicines: nextMedicines
    });
  };

  const onCreateInvoiceFromSaleOrder = () => {
    const invoice = JSON.parse(JSON.stringify(selectedSale));

    addSale({
      ...invoice,
      type: DIRECT,
      name: getSaleName(sales, DIRECT),
      uuid: uuidv4(),
      case: SALE_CASES.INVOICE_FOR_ORDER
    });
  };

  const handleAddProductReturn = (value) => {
     const medicines = value?.invoiceDetail.map(medicine => {
       return {
         _id: medicine.product?._id,
         variantId: medicine.variantId,
        //  ...medicine.variant,
        //  ...medicine,
         cost : get(medicine,'price',0),
         price : get(medicine,'price',0),
         selectedVariant : {...medicine.variant},
         customerId: value.customerId,

         productId: medicine.productId,
         product: medicine.product,
         batch: value.batch,
         availableQuantity: null,
         discount: {
           type: medicine.discountType,
           value: medicine.discountValue,
           percent: medicine.discountPercent
         },
         quantity: medicine.quantity,
         selectedBatches: [medicine.batch],
         typeExchangeReturn: true,
         disableFields: true
       }
     })

      const newSale = {
        name: getSaleName(sales, SALE_TYPES.RETURN),
        medicines: value ? medicines : [],
        code: value.code,
        branch: value.branch,
        createdBy: value.createdBy,
        receivedById: props.user,
        invoiceId: value._id,
        summary: {
          discount: { type: get(DISCOUNT_TYPES, get(value,'discountType','CASH' )), value: get(value,'discountValue',0 ) },
          payment: [{ method: get(head(get(value,'paymentNoteIds')),'paymentMethod',0), value: get(head(get(value,'paymentNoteIds')),'totalPayment',0) }]
        },
        uuid: uuidv4(),
        type: RETURN,
        customer: value.customer,
        involvedBy: value.involvedBy,
        paid : value.totalPayment||value.total,
      };

      newSale.case = getSaleCase(newSale);
      addSale(newSale);
  }

  return (
    <Fragment>
      <InfoPaymentContext>
      <div className="sale">
        <div className="sale__header sale-header">
          <Button type='link'  style={{fontSize:24, height:'100%', lineHeight:1.5}} href='/dashboard' ><HomeOutlined/></Button>
          <div className="sale__search">
            <SearchProductsBox onSelect={onAddMedicine}>
              <Input
                placeholder="Tìm hàng hoá"
                suffix={<i className="uil uil-receipt-alt input-icon"></i>}
                prefix={<SearchOutlined className="input-icon" />}
              />
            </SearchProductsBox>

            <i className="uil uil-qrcode-scan"></i>
          </div>

          <Header
            sales={sales}
            selectSale={selectSale}
            addSale={addSale}
            selectedSale={selectedSale}
            onSaleChange={onSaleChange}
          />
        </div>

        <Spin spinning={isGettingSale}>
          <div className="sale__main">
            <div className="warehouse-form__left" style={CARD_STYLE}>

              <WithRules rules={[isReturn]} >
                <div className={`warehouse-form-table ${selectedSale.type === RETURN && 'table-row'}`}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={medicineExchangeReturnSelect}
                    rowKey={rc => rc?.variantId}
                    pagination={false}
                    expandable={{
                      expandedRowRender: (variant, index) => {
                        return (
                        <div>
                          <Row>
                            <div className="warehouse-form-table__parcel">
                              Lô:
                            </div>
                            { isArray(variant?.selectedBatches) && variant?.selectedBatches?.map((batch, index) => {
                              const batchSelected = variant.batches?.find((item) => (item._id === batch._id))
                              const exchangeValue = variant.selectedVariant?.exchangeValue || variant.exchangeValue;
                              const availableQuantity = batchSelected?.availableTotal / exchangeValue;
                              return (
                                <Tag
                                  style={{ cursor: 'pointer' }}
                                  color="#2db7f5"
                                  key={index}
                                >
                                  {`${batch?.lotNumber ? `${batch?.lotNumber} -` : ''} ${formatDate(
                                    batch.expirationDate
                                  )} - SL: ${batch.quantity} ${availableQuantity >= batch?.quantity ? `- TK: ${formatQuantity((availableQuantity - batch.quantity), 1)}` : ''} `}
                                </Tag>
                              )
                            })}

                            <Button
                              size="small"
                              onClick={() => {
                                setSelectedVariant(variant);
                                setSelectBatchOpen(true);
                              }}
                            >
                              <UnorderedListOutlined />
                            </Button>
                          </Row>
                          {selectedSale?.type === DIRECT && (
                            <Row style={{ alignItems: 'center', marginTop: 10 }}>
                              <div
                                className="warehouse-form-table__parcel"
                                style={{
                                  marginRight: 0,
                                  width: 95,
                                  textAlign: 'left',
                                  paddingLeft: 10
                                }}
                              >
                                Liều dùng 1:
                              </div>
                              <div>
                                {variant.product?.productDetail?.doseInUse}
                              </div>
                            </Row>
                          )}
                        </div>
                      )},
                      defaultExpandAllRows: true,
                      expandedRowKeys: selectedSale && selectedSale?.medicines?.map(
                        (medicine) => medicine?.variantId ? medicine.variantId : <></>
                      ),
                      expandIcon: () => <></>
                    }}
                  />
                </div>
              </WithRules>

              <WithRules>
                <div className={`warehouse-form-table table-row`}>
                  <WithRules rules={[isReturn]}>
                    <div className="warehouse-form-table__search">
                      <SearchProductsBox onSelect={onAddMedicineExchangeReturn}>
                        <Input
                          placeholder="Tìm hàng đổi"
                          suffix={<i className="uil uil-receipt-alt input-icon"></i>}
                          prefix={<SearchOutlined className="input-icon" />}
                        />
                      </SearchProductsBox>
                    </div>
                  </WithRules>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={medicineSelect}
                    rowKey={rc => rc?.variantId}
                    pagination={false}
                    expandable={{
                      expandedRowRender: (variant, index) => {
                       return (
                        <div>
                          <Row>
                            <div className="warehouse-form-table__parcel">
                              Lô:
                            </div>
                            { isArray(variant?.selectedBatches) && variant?.selectedBatches?.map((batch, index) => {
                              const batchSelected = variant.batches?.find((item) => (item._id === batch._id))
                              const exchangeValue = variant.selectedVariant?.exchangeValue || variant.exchangeValue;
                              const availableQuantity = batchSelected?.availableTotal / exchangeValue;
                              return (
                                <Tag
                                  style={{ cursor: 'pointer' }}
                                  color="#2db7f5"
                                  key={index}
                                >
                                  {`${batch?.lotNumber ? `${batch?.lotNumber} -` : ''} ${formatDate(
                                    batch.expirationDate
                                  )} - SL: ${batch.quantity} ${availableQuantity >= batch?.quantity ? `- TK: ${formatQuantity((availableQuantity - batch.quantity),1)}` : ''} `}
                                </Tag>
                              )
                            })}

                            <Button
                              size="small"
                              onClick={() => {
                                setSelectedVariant(variant);
                                setSelectBatchOpen(true);
                              }}
                            >
                              <UnorderedListOutlined />
                            </Button>
                          </Row>
                          {selectedSale?.type === DIRECT && (
                            <Row style={{ alignItems: 'center', marginTop: 10 }}>
                              <div
                                className="warehouse-form-table__parcel"
                                style={{
                                  marginRight: 0,
                                  width: 95,
                                  textAlign: 'left',
                                  paddingLeft: 10
                                }}
                              >
                                Liều dùng:
                              </div>
                              <div>
                                {variant.product?.productDetail?.doseInUse}
                              </div>
                            </Row>
                          )}
                        </div>
                      )},
                      defaultExpandAllRows: true,
                      expandedRowKeys: selectedSale && selectedSale?.medicines?.map(
                        (medicine) => medicine?.variantId ? medicine.variantId : <></>
                      ),
                      expandIcon: () => <></>
                    }}
                  />
                </div>
              </WithRules>

            </div>
            <div
              className="warehouse-form__right sale__right-content"
              style={CARD_STYLE}
            >
              <SaleSummary
                user={props.user}
                selectedSale={selectedSale}
                onSaleChange={onSaleChange}
                onCreateInvoiceFromSaleOrder={onCreateInvoiceFromSaleOrder}
              />
            </div>
          </div>
        </Spin>
        {isSelectBatchOpen && (
          <SelectBatch
            isOpen={isSelectBatchOpen}
            onClose={() => setSelectBatchOpen(false)}
            variant={selectedVariant}
            onSubmit={batches => {
              onSelectBatch(batches);
            }}
            isSingle
          />
        )}
      </div>
      </InfoPaymentContext>

      <InvoiceTemplate sale={selectedSale} />
      <ProductsReturn
        isOpen={isOpenProductsReturn}
        selectedSale={selectedSale}
        handleAddProductReturn={handleAddProductReturn}
        onClose={() => {
          setOpenProductsReturn(false)
          localStorage.removeItem('isReturnProduct');
        } }/>
    </Fragment>
  );
};
const mapStateToProps = (state)=>({
    user: state.user.profile
  })
export default connect(mapStateToProps)(Sale) ;
