import React, { createContext } from 'react'
import { useContext } from 'react';
import TemplateInvoice from './TemplateInvoice';
import { useState } from 'react';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import POLICIES from '../../../constants/policy'
import WithPermission from '../WithPermission';

const Template = createContext();
export const usePrintTemplateInvoice =()=> useContext(Template);

const TemplatePrintInvoice =({children})=> {
    const printInvoice = useRef()
    const [dataSource , setDataSource ] = useState()
    const handlePrintInvoice = useReactToPrint({
        content: () => printInvoice.current,
      });

    return (
        <Template.Provider value={{setDataSource,handlePrintInvoice}}>
            {children}
            <WithPermission permission={POLICIES.ORDERFORM}>
                <TemplateInvoice refComponent={printInvoice} handlePrintInvoice={handlePrintInvoice} data ={dataSource}/>
            </WithPermission>
        </Template.Provider>
    )
}

export default TemplatePrintInvoice
