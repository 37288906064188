import * as Types from '~/constants/actionTypes';

export const getNotificationService = query => ({
  type: Types.GET_NOTIFICATION_SERVICE_REQUEST,
  payload: query
});
export const resetNotificationServiceState = () => ({
  type: Types.RESET_NOTIFICATION_SERVICE_STATE
});

