import React, { useMemo, useEffect, useState } from 'react';
import {
  useResources,
  useResetEmployeeGroup,
  useResourceColumns,
  usePolicySystemPartner,
  useUpdatePermissionSystemPartner
} from '~/hooks';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Menu, Skeleton, Table, Checkbox, Button, Popconfirm } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import toastrNotify from '~/hooks/utils';

const Permission = ({ isActive, onChange }) => {
  return (
    <Checkbox
      checked={isActive}
      onChange={onChange}
      // disabled={disabled}
    ></Checkbox>
  );
};

// const getNextPath = (url) => {
//   const paths = url.split('/');
//   const nextPath = paths
//     .filter((x, index) => !!x && index !== paths.length - 1)
//     .join('/');

//   return `/${nextPath}`;
// };

const PermissionGroup  = () => {
  const { id: partnerId, groupId } = useParams();
  // const history = useHistory();
  // const { url } = useRouteMatch();

  const branchIdParam = useMemo(() => ({ partnerId }), [partnerId]);

  const [resources, isResourcesLoading] = useResources("admin");

  useResetEmployeeGroup();

  const [group] = usePolicySystemPartner(branchIdParam );

  const [isUpdateLoading, handleUpdate] = useUpdatePermissionSystemPartner();

  const onPermisionChange = (isAssgined, resource, action) => {
  
    handleUpdate({ isAssgined, resource, action, partnerId });
  };
  const renderPermission = (action, rc) => {
    return (
      <Permission
        isActive={group?.policies?.[rc.resource.key]?.includes(action?.key)}
        onChange={(event) =>
          onPermisionChange(event.target.checked, rc.resource.key, action.key)
        }
        disabled={isUpdateLoading}
      />
    );
  };

  const columns = useResourceColumns(renderPermission);

  return (
    <Card>
      <CardBody>
        <div className="employee-group">
          <div className="employee-group__permission">
            {isResourcesLoading ? (
              <SkeletonTable columns={columns} />
            ) : (
              <Table
                columns={columns}
                className= "permission-table"
                dataSource={resources}
                size="small"
                rowKey={(rc) => rc.resource.key}
                pagination={{ hideOnSinglePage: true }}
              ></Table>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PermissionGroup;
