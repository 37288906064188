import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Row,
  Button,
  Select,
  Col,
  DatePicker,
  TimePicker
} from 'antd';
import moment from 'moment';
import { formatCurrency, getUtcDateTime } from '~/utils/helper';
import {
  CARD_STYLE,
  PAYMENT_STATE,
  PURCHASE_ORDER_STATUS
} from '~/constants/defaultValue';

import {
  useResetSupplier,
  useCreateDamage,
  useUpdateDamage,
  useProfile
} from '~/hooks';
import SupplierForm from '~/components/WorldClinic/Supplier/Form';
import toastr from 'toastr';
import { PATH_CLINIC } from '~/routes/paths';

const { DRAFT, COMPLETED } = PAYMENT_STATE;

const FormItem = Form.Item;

const SummaryForm = ({ formData, initDamage, isGetDamageLoading }) => {
  const isEditingCompletedDamage =
    initDamage?._id && initDamage?.status === COMPLETED;

  const isDraftDamage = !initDamage || initDamage?.status !== COMPLETED;

  const [profile] = useProfile();
  const [form] = Form.useForm();
  const statusRef = useRef(DRAFT);
  const history = useHistory();
  const onSubmitSuccess = damage => {
    history.push({
      pathname: PATH_CLINIC.damage.root,
      search: new URLSearchParams({ id: damage._id }).toString()
    });
  };

  const [isCreatingDamage, createDamage] = useCreateDamage(onSubmitSuccess);
  const [isUpdatingDamage, updateDamage] = useUpdateDamage(onSubmitSuccess);

  const [isSupplierFormOpen, setSupplierFormOpen] = useState(false);

  useEffect(() => {
    if (initDamage) {
      form.setFieldsValue({
        code: initDamage.code,
        note: initDamage.note,
        date: moment(initDamage.processedAt),
        time: moment(initDamage.processedAt)
      });
    }
  }, [initDamage, form]);

  useResetSupplier();

  const totalAmount = useMemo(() => {
    return formData.reduce((amount, { quantity, cost }) => {
      return (amount += quantity * cost);
    }, 0);
  }, [formData]);

  const onFinish = values => {
    const variantHasNoBatch = formData.find(
      ({ selectedBatches }) => !selectedBatches.length
    );

    if (variantHasNoBatch) {
      toastr.error('Vui lòng nhập số lô cho tất cả sản phẩm');
      return;
    }

    const model = {
      ...values,
      status: statusRef.current,
      detailItems: formData.map(variant => {
        const batch = variant.selectedBatches[0];
        return {
          productId: variant.productId,
          variantId: initDamage ? variant.variantId : variant._id,
          batchId: batch.batchId || batch._id,
          quantity: batch.quantity
        };
      }),
      processedAt: getUtcDateTime(values.date, values.time),
      date: undefined,
      time: undefined
    };

    const { _id } = initDamage || {};

    if (_id) {
      updateDamage({ ...model, _id });
    } else {
      createDamage(model);
    }
  };

  const isActionLoading = isCreatingDamage || isUpdatingDamage;
  const isDisableSubmit = !formData?.length;

  return (
    <div className="warehouse-form__right" style={CARD_STYLE}>
      <Form form={form} onFinish={onFinish}>
        <Row justify="space-between">
          <Col span={9} style={{ borderBottom: '1px solid #dedede' }}>
            <Select
              className="warehouse-form__user"
              bordered={false}
              value={profile?.fullName}
            ></Select>
          </Col>
          <Col span={14}>
            <Row justify="end">
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="date" initialValue={moment()}>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    style={{
                      paddingRight: 0
                    }}
                    format={'DD-MM-YYYY'}
                  />
                </FormItem>
              </Col>
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="time" initialValue={moment()}>
                  <TimePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    placeholder=""
                    format={'HH:mm'}
                    style={{ width: 45, paddingRight: 0, paddingLeft: 0 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="warehouse-form__row" style={{ marginTop: 24 }}>
          <label>Mã xuất huỷ</label>

          <FormItem noStyle name="code">
            <Input
              bordered={false}
              className="warehouse-form__custom-input warehouse-form__code"
              style={{ width: 155 }}
              placeholder="Mã phiếu tự động"
              disabled
            />
          </FormItem>
        </Row>

        <Row className="warehouse-form__row">
          <label>Trạng thái</label>
          <label>
            {!isGetDamageLoading &&
              (PURCHASE_ORDER_STATUS[initDamage?.status] ||
                PURCHASE_ORDER_STATUS.DRAFT)}{' '}
          </label>
        </Row>

        <Row className="warehouse-form__row">
          <label>
            Tổng giá trị hủy:{' '}
            <span className="sale__total-product">{formData?.length}</span>{' '}
          </label>
          <label>{formatCurrency(totalAmount)}</label>
        </Row>

        <Row className="warehouse-form__row">
          <FormItem style={{ width: '100%' }} name="note">
            <Input.TextArea
              bordered={false}
              placeholder="Ghi chú"
              style={{ width: '100%', paddingLeft: 0 }}
              className="warehouse-form__custom-input"
              rows={1}
            ></Input.TextArea>
          </FormItem>
        </Row>

        {!isGetDamageLoading && (
          <Row className="warehouse-form__submit">
            {isActionLoading ? (
              <Button disabled>Thoát</Button>
            ) : (
              <Link to={PATH_CLINIC.damage.root}>
                <Button>Thoát</Button>
              </Link>
            )}

            {isDraftDamage && (
              <Button
                onClick={() => (statusRef.current = DRAFT)}
                htmlType="submit"
                type="primary"
                style={{ marginLeft: 10 }}
                loading={isActionLoading}
                disabled={isDisableSubmit}
              >
                Lưu
              </Button>
            )}

            <Button
              onClick={() => (statusRef.current = COMPLETED)}
              htmlType="submit"
              type="primary"
              style={{ marginLeft: 10 }}
              loading={isActionLoading}
              disabled={isDisableSubmit}
            >
              {isEditingCompletedDamage ? 'Cập nhật' : 'Hoàn thành'}
            </Button>
          </Row>
        )}
      </Form>

      <SupplierForm
        callback={supplier => form.setFieldsValue({ supplierId: supplier._id })}
        isOpen={isSupplierFormOpen}
        onClose={() => setSupplierFormOpen(false)}
      />
    </div>
  );
};

export default SummaryForm;
