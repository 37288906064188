import produce from 'immer';
import {
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAILED,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAILED,
  DELETE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILED,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_FAILED,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILED,
  RESET_STORE
} from '~/constants/actionTypes';

const INIT_STATE = {
  isLoading: false,
  getAppointmentsFailed: undefined,
  appointments: [],

  isGetAppointmentLoading: false,
  appointment: null,
  getAppointmentFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_APPOINTMENTS_REQUEST:
      state.isLoading = true;
      state.getAppointmentsFailed = null;
      return;

    case GET_APPOINTMENTS_SUCCESS:
      state.isLoading = false;
      state.appointments = payload;
      return;

    case GET_APPOINTMENTS_FAILED:
      state.isLoading = false;
      state.getAppointmentsFailed = payload;
      return;

    case GET_APPOINTMENT_REQUEST:
      state.isGetAppointmentLoading = true;
      state.appointment = null;
      state.getAppointmentFailed = null;
      return;

    case GET_APPOINTMENT_SUCCESS:
      state.isGetAppointmentLoading = false;
      state.appointment = payload;
      return;

    case GET_APPOINTMENT_FAILED:
      state.isGetAppointmentLoading = false;
      state.getAppointmentFailed = payload;
      return;

    case DELETE_APPOINTMENT_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_APPOINTMENT_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_APPOINTMENT_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_APPOINTMENT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_APPOINTMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_APPOINTMENT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_APPOINTMENT_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_APPOINTMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.hospital = payload;
      return;

    case UPDATE_APPOINTMENT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case RESET_STORE:
      return INIT_STATE

    default:
      return;
  }
}, INIT_STATE);
