import React from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BASE_URL_UPLOAD_IMAGE } from '~/constants/defaultValue';
import { connect } from 'react-redux';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: []
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  };

  handleChange = ({ fileList }) => {
    const getImageUrl = file => file.url || file.response?.url;
    this.props.onImagesChange(fileList.map(getImageUrl));
    this.setState({ fileList });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.initFilelist &&
      prevProps.initFilelist !== this.props.initFilelist
    ) {
      const nextFileList = this.props.initFilelist.map((url, index) => ({
        url,
        uid: url + index
      }));

      this.setState({
        fileList: nextFileList
      });
    }
  }

  render() {
    const { previewVisible, previewImage, previewTitle, fileList } = this.state;
    const { token } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <>
        <Upload
          action={BASE_URL_UPLOAD_IMAGE}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          headers={{ Authorization: 'Bearer ' + token }}
          showUploadList
          onChange={this.handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

export default connect(mapStateToProps)(PicturesWall);
