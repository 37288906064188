import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getAppointments({ payload: query }) {
  try {
    const data = yield call(Api.appointment.getAll, query);
    yield put({ type: Types.GET_APPOINTMENTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_APPOINTMENTS_FAILED, payload: error.message });
  }
}

function* getAppointment({ payload: id }) {
  try {
    const data = yield call(Api.appointment.getById, id);
    yield put({ type: Types.GET_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* createAppointment(action) {
  try {
    const data = yield call(Api.appointment.create, action.payload);
    yield put({ type: Types.CREATE_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_APPOINTMENT_FAILED,
      payload: error.message
    });
  }
}

function* updateAppointment(action) {
  try {
    const data = yield call(Api.appointment.update, action.payload);
    yield put({ type: Types.UPDATE_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_APPOINTMENT_FAILED,
      payload: error.message
    });
  }
}

function* deleteAppointment({ payload }) {
  try {
    yield call(Api.appointment.delete, payload);
    yield put({ type: Types.DELETE_APPOINTMENT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_APPOINTMENT_FAILED,
      payload: error.message
    });
  }
}

export default function* appointment() {
  yield takeLatest(Types.GET_APPOINTMENTS_REQUEST, getAppointments);
  yield takeLatest(Types.GET_APPOINTMENT_REQUEST, getAppointment);
  yield takeLatest(Types.CREATE_APPOINTMENT_REQUEST, createAppointment);
  yield takeLatest(Types.UPDATE_APPOINTMENT_REQUEST, updateAppointment);
  yield takeLatest(Types.DELETE_APPOINTMENT_REQUEST, deleteAppointment);
}
