import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import InformationItem from '~/components/Common/InformationItem';
import DetailTable from './DetailTable';
import { formatDate } from '~/utils/helper';
import { API_LIST, INVOICE_STATUS } from '~/constants/defaultValue';
import { PATH_CLINIC } from '~/routes/paths';
import Prescription from '~/components/WorldClinic/Prescription';
import { useProfile } from '~/hooks';
import ButtonExport from '~/components/Common/ModuleExport/ButtonExport';
import { get } from 'lodash';
import { WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import { usePrintTemplateInvoice } from '~/components/Common/templatePrint/TemplateInvoiceProvider';

const LEFT_INFO = [
  {
    label: 'Mã nhập hàng:',
    dataIndex: 'code',
    render: code => <strong>{code}</strong>
  },
  {
    label: 'Thời gian:',
    dataIndex: 'purchasedAt',
    render: purchasedAt => formatDate(purchasedAt)
  },
  {
    label: 'Khách hàng:',
    dataIndex: 'customer',
    render: customer => <Link to="#">{customer?.name}</Link>
  },
  {
    label: 'Bảng giá:',
    dataIndex: 'priceList'
  }
];


const Information = ({ record, deleteProductInvoice }) => {
  console.log(record,'record')
  const [profile] = useProfile();
   const  {handlePrintInvoice,setDataSource}=usePrintTemplateInvoice()
  const onPrintInvoice = (data)=>{
    setDataSource(data)
    process.nextTick(handlePrintInvoice);
  }
  const RIGHT_INFO = [
    {
      label: 'Trạng thái:',
      dataIndex: 'status',
      render: status => INVOICE_STATUS[status]
    },
    {
      label: 'Chi nhánh:',
      dataIndex: 'branch',
      render: branch => branch?.name?.vi
    },
    {
      label: 'Người nhận đặt:',
      dataIndex: 'involvedBy',
      render: () => profile?.fullName
  
    },
    {
      label: 'Người tạo:',
      dataIndex: 'createdBy',
      render: createdBy => createdBy?.username
    }
  ];
  return (
    <div className="warehouse-info medicine-detail-tab-item">
      <Row gutter={48}>
        <Col span={8}>
          <div className="warehouse-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={record[dataIndex]}
                render={render}
              />
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="warehouse-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }) => (
              <InformationItem
                key={label}
                label={label}
                value={record[dataIndex]}
                right
                render={render}
              />
            ))}
          </div>
        </Col>

        <Col span={7} style={{ marginLeft: 'auto' }}>
          <div className="warehouse-info__general">
            <InformationItem
              key={'Ghi chú'}
              label="Ghi chú"
              value={record.note}
            />
          </div>
        </Col>
      </Row>

      {record.prescription && (
        <Prescription prescription={record.prescription} />
      )}

      <DetailTable sale={record} />

      <Row className="medicine-info__actions" justify="end">
        <WithPermission permission={POLICIES.UPDATE_BILL}>
          <Link
            to={{
              pathname: PATH_CLINIC.sale.root,
              state: { invoiceId: record._id }
            }}
          >
            <Button type="primary" style={{ marginRight: 10 }}>
              {' '}
              <EditOutlined style={{ marginLeft: 0 }} /> Cập nhật
            </Button>
          </Link>
        </WithPermission>

        <WithPermission permission={POLICIES.WRITE_BILL}>
          <Button onClick={()=>{onPrintInvoice(record) }}>In hóa đơn</Button>
        </WithPermission>

        <WithPermission permission={POLICIES.WRITE_BILL}>
          <ButtonExport router={API_LIST.productInvoice} label="Hóa đơn" id={record._id} fileName={`Chitiethoadon_${record.code}`}/>
        </WithPermission>

        <WithPermission permission={POLICIES.DELETE_BILL}>
          <Popconfirm
            title="Bạn muốn xoá hoá đơn này?"
            onConfirm={() => deleteProductInvoice(record._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger">
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>{' '}
        </WithPermission>
      </Row>
    </div>
  );
};

export default Information;
