import React, { useMemo, useState, useRef } from 'react';
import {
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';
import {
  getDirectorys
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';
import { Checkbox, Menu } from 'antd';

const DIRECTORY_MODULE = 'directory';
const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  pagingSelector,
} = getSelectors(DIRECTORY_MODULE);

export const useDirectory = query => {
  return useFetchByParam({
    action: getDirectorys,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  })
}
export const useDirectoryPaging = () => useSelector(pagingSelector);

export const useDirectoryQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const code = query.get('code');
  const page = query.get('page') || 1;
  const name = query.get('name');
  const registrationNo = query.get('registrationNo');
  const element = query.get('element');
  const manufacturer = query.get('manufacturer');

  if (prevKeyword.current !== code) {
    prevKeyword.current = code;
  }

  return useMemo(() => {
    const dataExit = {
      page,
      limit,
    }

    if (code) {
      dataExit.code = code
    }

    if (name) {
      dataExit.name = name
    }

    if (registrationNo) {
      dataExit.registrationNo = registrationNo
    }

    if (element) {
      dataExit.element = element
    }

    if (manufacturer) {
      dataExit.manufacturer = manufacturer
    }

    const queryParams = getExistProp(dataExit);
    return [queryParams];
  },[page, limit, code, name, manufacturer, registrationNo, element])
};

export const useUpdateDirectoryParams = query => {
  const history = useHistory();
  const [code, setCode] = useState(query.code)

  let dataExit = {}

  const onParamChange = param => {
    if(param.isOnlyCode) {
      dataExit = {
        code: param.code
      }
    } else {
      dataExit = {
        ...query,
        ...param
      }
    }

    history.push({
      pathname: PATH_CLINIC.medicineDirectory.root,
      search: new URLSearchParams(
        getExistProp(dataExit)
      ).toString()
    });
  };

  return [code, {setCode, onParamChange}];
}
const COLUMNS_OPTIONS = [
  {
    title:'Mã thuốc',
    key:'code',
  },
  {
    title:'Tên thuốc',
    key:'name',
  },
  {
    title:'Hàm lượng',
    key:'content',
  },
  {
    title:'Hoạt chất chính',
    key:'element',
  },
  {
    title:'Quy cách đóng gói',
    key:'package',
  },
  {
    title:'ĐVT',
    key:'unit',
  },
  {
    title:'Số đăng ký',
    key:'registrationNo',
  },
  {
    title:'Hãng sản xuất',
    key:'manufacturer',
  },
  {
    title:'Nước sản xuất',
    key:'country',
  },
]
export const useDirectoryColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    registrationNo:false,
    manufacturer:false,
    country:false,
    name: true,
    content: true,
    element: true,
    package: true,
    unit: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
}
