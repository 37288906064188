import { Button, Form, Input, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useGetValueContextPurchaseOrder } from './contextProvider';

function ModalDetail() {
    const [form] = Form.useForm();

    const [hidden, setHidden] = useState(true);
    const { selectedId, handleUpdate } = useGetValueContextPurchaseOrder();

    const column = useMemo(
        () => [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (e, rc, i) => i + 1,
                width: 60,
                align: 'center'
            },
            {
                title: 'Tên mặt hàng',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Số lượng',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (quantity, rc) => {
                    return (
                        Number(quantity)
                            .toLocaleString('vi')
                            .replace(/[.]/gi, ',') +
                        ` ( ${get(rc, 'unit', '')} )`
                    );
                }
            }
        ],
        []
    );
    return (
        <div>
            <Form
                form={form}
                initialValues={selectedId.detail}
                labelCol={{ span: 5 }}
                labelAlign="left"
                onValuesChange={(value)=>{
                    // eslint-disable-next-line eqeqeq
                    let checkDiff = JSON.stringify(value.note)==JSON.stringify(selectedId.detail.note)
                    setHidden(checkDiff)
                }}
                onFinish={(value)=>{
                    handleUpdate(selectedId.id,value)
                }}
            >
                <Form.Item name={'supplier'} label="Nhà cung cấp">
                    <Input bordered={false} readOnly></Input>
                </Form.Item>
                <Form.Item name={'note'} label="Ghi chú">
                    <TextArea rows={2}></TextArea>
                </Form.Item>
                    <Button hidden={hidden} style={{float:'right'}} htmlType='submit' type='primary' size='small'>Lưu ghi chú</Button>
            </Form>
            <Table
                bordered
                columns={column}
                scroll={{ y: '50vh' }}
                dataSource={selectedId.dataTable}
                pagination={false}
            />
        </div>
    );
}

export default ModalDetail;
