import React from 'react';
import { LANGUAGE } from '~/constants/defaultValue';
import { Row, Radio } from 'antd';

const Select = ({ onChange, value }) => {
  return (
    <Row justify='end'>
      <Radio.Group value={value} onChange={e => onChange(e.target.value)}>
        <Radio.Button
          style={{ width: 80, textAlign: 'center' }}
          value={LANGUAGE.VI}>
          VI
        </Radio.Button>
        <Radio.Button
          style={{ width: 80, textAlign: 'center' }}
          value={LANGUAGE.EN}>
          EN
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

export default Select;
