import React, { useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Skeleton,
  Modal,
  DatePicker,
  InputNumber,
  Switch,
  Select
} from 'antd';
import moment from 'moment';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreateCoupon,
  useCoupon,
  useInitCoupon,
  useUpdateCoupon
} from '~/hooks';
import './index.scss';

const FormItem = Form.Item;

const longLabelCol = { sm: 24, md: 24, lg: 10, xl: 10 };
const longWrapperCol = { sm: 24, md: 24, lg: 14, xl: 14 };

const CouponForm = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreateCoupon(onClose);
  const [, handleUpdate] = useUpdateCoupon(onClose);
  const [coupon, isLoading] = useCoupon(id);
  const initCoupon = useInitCoupon(coupon, id);

  useEffect(() => {
    form.resetFields();
  }, [initCoupon, form]);

  const onFinish = (values) => {
    const coupon = {
      ...values,
      startTime: values.startTime?.valueOf() || undefined,
      endTime: values.endTime?.valueOf() || undefined
    };

    if (id) {
      handleUpdate({ ...coupon, _id: id });
    } else {
      handleCreate(coupon);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="coupon-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values) => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initCoupon}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 10, xl: 10 }}
          wrapperCol={{ sm: 24, md: 24, lg: 14, xl: 14 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} phiếu giảm giá
            </h4>
          </Row>
          <Row
            gutter={60}
            align="middle"
            justify="space-between"
            className="coupon-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên giảm giá"
                name="name"
                rules={[
                  { required: true, message: 'Xin mời nhập tên phiếu giảm giá' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Mã giảm giá"
                name="code"
                rules={[
                  { required: true, message: 'Xin mời nhập mã giảm giá' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Thời gian bắt đầu"
                name="startTime"
                rules={[
                  { required: true, message: 'Xin mời nhập thời gian bắt đầu' }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <DatePicker
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Thời gian kết thúc"
                name="endTime"
                rules={[
                  { required: true, message: 'Xin mời nhập thời gian kết thúc' }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <DatePicker
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Hình thức giảm giá" name="discountType">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    <Select.Option value="value">Theo số tiền</Select.Option>
                    <Select.Option value="percent">
                      Theo phần trăm
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {() =>
                  form.getFieldValue('discountType') === 'value' ? (
                    <FormItem
                      label="Số tiền giảm"
                      name="discountValue"
                      rules={[
                        { required: true, message: 'Xin mời nhập số tiền giảm' }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <InputNumber min={0} />
                      )}
                    </FormItem>
                  ) : (
                    <FormItem
                      label="Số % giảm"
                      name="discountPercent"
                      rules={[
                        { required: true, message: 'Xin mời nhập số % giảm' }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <InputNumber min={0} max={100} />
                      )}
                    </FormItem>
                  )
                }
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormItem label="Số tiền giảm tối đa" name="maxDiscount">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber min={0} />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                labelCol={longLabelCol}
                wrapperCol={longWrapperCol}
                label="Số lần sử dụng tối đa"
                name="maxUsage"
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber min={0} />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                labelCol={longLabelCol}
                wrapperCol={longWrapperCol}
                label="Giảm giá ship tối đa"
                name="maxShippingDiscount"
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber min={0} />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Mô tả ngắn" name="description">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Free ship"
                name="isFreeShipping"
                valuePropName="checked"
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Switch checkedChildren="Có" unCheckedChildren="Không" />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row className="coupon-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default CouponForm;
