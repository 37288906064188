import React from 'react';
import { Table } from 'antd';

const DetailTable = ({sample,columns}) => {
  const {samplePrescriptionDetail} = sample

  return (
    <div style={{ marginLeft: -20, marginTop: 50 }}>
      <Table
        className="nested-table"
        columns={columns}
        dataSource={samplePrescriptionDetail}
        size="small"
        pagination={false}
        rowKey={({ variantId }) => variantId.variantCode}
      />
    </div>
  );
};

export default DetailTable;