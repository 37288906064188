import produce from 'immer';
import {
  GET_BRANCHES_REQUEST,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAILED,
  GET_BRANCH_REQUEST,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAILED,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_SUCCESS,
  CREATE_BRANCH_FAILED,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILED,
  RESET_BRANCH_STATE,
  RESET_STORE,
  GET_API_KEY_REQUEST,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_FAILED,
  UPDATE_API_KEY_SAVE_REQUEST,
  UPDATE_API_KEY_SAVE_SUCCESS,
  UPDATE_API_KEY_SAVE_FAILED,
  DELETE_API_KEY_REQUEST,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_FAILED
} from '~/constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,
  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  isSubmitApiKeyLoading: false,
  updateApiKeySuccess: null,
  updateApiKeyFailed: null,

  deleteApiKeySuccess: null,
  deleteApiKeyFailed: null,

  availableTimes: [],
  isGetAvailableTimesLoading: false,
  getAvailableTimesFailed: null,

  getApiKeyLoading: false,
  getApiKeyFailed: undefined,
  apiKey: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_BRANCHES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case GET_BRANCHES_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      // state.list = payload.docs;
      // state.paging = getPaging(payload);
      return;

    case GET_BRANCHES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case GET_BRANCH_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case GET_BRANCH_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case GET_BRANCH_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case DELETE_BRANCH_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_BRANCH_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_BRANCH_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case UPDATE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    
    //API KEY

    case GET_API_KEY_REQUEST:
      state.getApiKeyLoading = true;
      state.getApiKeyFailed = null;
      state.apiKey = null;
      return;

    case GET_API_KEY_SUCCESS:
      state.getApiKeyLoading = false;
      state.apiKey = payload;
      return;

    case GET_API_KEY_FAILED:
      state.getApiKeyLoading = false;
      state.getApiKeyFailed = payload;
      return;

      case UPDATE_API_KEY_SAVE_REQUEST:
        state.isSubmitApiKeyLoading = true;
        state.updateApiKeyFailed = null;
        state.updateApiKeySuccess = null;
        return;
  
      case UPDATE_API_KEY_SAVE_SUCCESS:
        state.isSubmitApiKeyLoading = false;
        state.updateApiKeySuccess = payload;
        return;
  
      case UPDATE_API_KEY_SAVE_FAILED:
        state.isSubmitApiKeyLoading = false;
        state.updateApiKeyFailed = payload;
      return;
    
      case DELETE_API_KEY_REQUEST:
        state.isSubmitApiKeyLoading = true;
        return;
  
      case DELETE_API_KEY_SUCCESS:
        state.isSubmitApiKeyLoading = false;
        state.deleteApiKeySuccess = payload;
        return;
  
      case DELETE_API_KEY_FAILED:
        state.isSubmitApiKeyLoading = false;
        state.deleteApiKeyFailed = payload;
        return;

    case RESET_BRANCH_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
