import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  allOrders: [],

  isGetPendingOrdersLoading: false,
  getPendingOrdersFailed: undefined,
  pendingOrders: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,

  isSubmitMedicineLoading: false,
  addMedicineSuccess: null,
  addMedicineFailed: null,

  updateMedicineSuccess: null,
  updateMedicineFailed: null,

  deleteMedicineSuccess: null,
  deleteMedicineFailed: null,

  isGetShippingVendorsLoading: false,
  getShippingVendorsFailed: undefined,
  shippingVendors: [],

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_ALL_ORDERS_REQUEST:
      state.isLoading = true;
      state.allOrders = [];
      state.getListFailed = null;
      return;

    case Types.GET_ALL_ORDERS_SUCCESS:
      state.isLoading = false;
      state.allOrders = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_ALL_ORDERS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      return;

    case Types.GET_PENDING_ORDERS_REQUEST:
      state.isGetPendingOrdersLoading = true;
      state.pendingOrders = [];
      state.getPendingOrdersFailed = null;
      return;

    case Types.GET_PENDING_ORDERS_SUCCESS:
      state.isGetPendingOrdersLoading = false;
      state.pendingOrders = payload.docs;
      return;

    case Types.GET_PENDING_ORDERS_FAILED:
      state.isGetPendingOrdersLoading = false;
      state.getPendingOrdersFailed = payload;
      return;

    case Types.GET_ORDER_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_ORDER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_ORDER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.ADD_MEDICINE_TO_ORDER_REQUEST:
      state.isSubmitMedicineLoading = true;
      state.addMedicineSuccess = null;
      state.addMedicineFailed = null;
      return;

    case Types.ADD_MEDICINE_TO_ORDER_SUCCESS:
      state.isSubmitMedicineLoading = false;
      state.addMedicineSuccess = payload;
      return;

    case Types.ADD_MEDICINE_TO_ORDER_FAILED:
      state.isSubmitMedicineLoading = false;
      state.addMedicineFailed = payload;
      return;

    case Types.UPDATE_ORDER_MEDICINE_REQUEST:
      state.isSubmitMedicineLoading = true;
      state.updateMedicineSuccess = null;
      state.updateMedicineFailed = null;
      return;

    case Types.UPDATE_ORDER_MEDICINE_SUCCESS:
      state.isSubmitMedicineLoading = false;
      state.updateMedicineSuccess = payload;
      return;

    case Types.UPDATE_ORDER_MEDICINE_FAILED:
      state.isSubmitMedicineLoading = false;
      state.updateMedicineFailed = payload;
      return;

    case Types.DELETE_ORDER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_ORDER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_ORDER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.DELETE_ORDER_MEDICINE_REQUEST:
      state.isSubmitMedicineLoading = true;
      state.deleteMedicineSuccess = null;
      state.deleteMedicineFailed = null;
      return;

    case Types.DELETE_ORDER_MEDICINE_SUCCESS:
      state.deleteMedicineSuccess = payload;
      return;

    case Types.DELETE_ORDER_MEDICINE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_SHIPPING_VENDOR_REQUEST:
      state.isGetShippingVendorsLoading = true;
      state.shippingVendors = [];
      state.getShippingVendorsFailed = null;
      return;

    case Types.GET_SHIPPING_VENDOR_SUCCESS:
      state.isGetShippingVendorsLoading = false;
      state.shippingVendors = payload;
      return;

    case Types.GET_SHIPPING_VENDOR_FAILED:
      state.isGetShippingVendorsLoading = false;
      state.getShippingVendorsFailed = payload;
      return;

    case Types.RESET_ORDER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
