import moment from 'moment';

export const getExistProp = data => {
  const result = Object.keys(data).reduce((nextData, key) => {
    if (data[key]) {
      return {
        ...nextData,
        [key]: data[key]
      };
    }

    return nextData;
  }, {});

  return result;
};

export const onFormAddressChange = ({ address }, form) => {
  const shouldResetDistrictAndWards = address && address.cityId;
  if (shouldResetDistrictAndWards) {
    form.setFieldsValue({
      address: {
        districtId: null,
        wardId: null
      }
    });
  }

  const shouldResetWards = address && address.districtId;
  if (shouldResetWards) {
    form.setFieldsValue({
      address: {
        wardId: null
      }
    });
  }
};
export function removeAccents(str) {
  return str
  .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a")
  .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e")
  .replace(/ì|í|ị|ỉ|ĩ/g,"i")
  .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o")
  .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u")
  .replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y")
  .replace(/đ/g,"d")
  .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
  .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
  .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
  .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
  .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
  .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
  .replace(/Đ/g, "D")
}

export const filterAcrossAccents = (input, option) => {
  return (
    removeAccents(option.children.toLowerCase()).indexOf(removeAccents(input.toLowerCase())) >=
    0
  );
};

export const searchByName = (data, searchText) => {
  if (!data || !searchText) {
    return data;
  }

  return data.filter(
    ({ name }) =>
      removeAccents(name.toLowerCase()).indexOf(removeAccents(searchText.toLowerCase())) >= 0
  );
};

const getCsvRows = (columns, data) => {
  const csvRows = data.map(row => {
    const csvRow = columns.map(({ key }) => {
      return row[key];
    });

    return csvRow;
  });

  return csvRows;
};

const getCsvHeader = columns => {
  const csvHeader = columns.reduce((currentHeader, { title }, index) => {
    const isLastColumn = index !== columns.length - 1;

    return `${currentHeader}${title}${isLastColumn ? ',' : '\n'}`;
  }, '');

  return csvHeader;
};

const mergeCsvData = (csvHeader, csvRows) => {
  csvRows.forEach(row => {
    csvHeader += row.join(',');
    csvHeader += '\n';
  });

  return csvHeader;
};

const getCsvData = (columns, data) => {
  const csvHeader = getCsvHeader(columns);
  const csvRows = getCsvRows(columns, data);

  return mergeCsvData(csvHeader, csvRows);
};

export const exportCSV = ({ columns, data, fileName = 'csvFile' }) => {
  // const csvData = getCsvData(columns, data);
  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
  link.target = '_blank';

  link.download = `${fileName}.csv`;
  link.click();
};

// const currencyFormater = new Intl.NumberFormat('vi-VN', {
//   style: 'currency',
//   currency: 'vnd'
// });

const numbeFormater = new Intl.NumberFormat('vi-VN');

export const formatCurrency = number => {
  if (number || number === 0) {
    return numbeFormater.format(number);
  }

  return '0';
};

export const formatNumber = number => {
  if (number || number === 0) {
    return numbeFormater.format(number);
  }

  return '0';
};

const FIVE_HUNDRED = 500000;
const TWO_HUNDRED = 200000;
const ONE_HUNDRED = 100000;

export const getMoneySuggestion = value => {
  const suggestions = value ? [value] : [];

  if (value === FIVE_HUNDRED) {
    return [FIVE_HUNDRED];
  }

  if (value < FIVE_HUNDRED) {
    if (value < ONE_HUNDRED) {
      suggestions.push(ONE_HUNDRED);
    }

    if (value < TWO_HUNDRED) {
      suggestions.push(TWO_HUNDRED);
    }

    suggestions.push(FIVE_HUNDRED);
  } else {
    const old = value % FIVE_HUNDRED;
    const rounded = value - old;

    if (old < ONE_HUNDRED) {
      suggestions.push(rounded + ONE_HUNDRED);
    }

    if (old < TWO_HUNDRED) {
      suggestions.push(rounded + TWO_HUNDRED);
    }

    suggestions.push(rounded + FIVE_HUNDRED);
  }

  return suggestions;
};

export const getAddress = address => {
  var str = '';
  if (address) {
    var strStreet = address?.street ? address?.street : ' ';
    var strWard = address?.ward ? ' - ' + address?.ward : ' ';
    var strDistrict = address?.district ? ' - ' + address?.district : ' ';
    var strCity = address.city ? ' - ' +address?.city : ' ';
    str = strStreet + strWard + strDistrict + strCity;
  } else {
    return '';
  }
  return str;
};

export const formatDateTime = date => moment(date).utc().format('DD/MM/YYYY HH:mm');
export const formatDate = date => moment(date).utc().format('DD/MM/YYYY');

export const isMatchFilter = (a, b) =>
  a?.toLowerCase().includes(b.toLowerCase());

export const getTotal = (data, key) =>
  data.reduce((acc, item) => (acc += !isNaN(item[key]) ?item[key]:0), 0);

export const getUtcDateTime = (date, time) => {
  const localDateTime =
    date.format('YYYY-MM-DD') + 'T' + time.format('HH:mm:ss');
  const utcDateTime = moment(localDateTime).toISOString();

  return utcDateTime;
};

export const formatQuantity = (quantity,fraction=3) => {
  return Number.isInteger(quantity) ? quantity : quantity?.toLocaleString("sv-SE", { maximumFractionDigits: 3, minimumFractionDigits: fraction })
}

export const convertQueryString = (queryString) => {
  const queryJson = Object.entries(getExistProp(queryString));
  const stringQuery = queryJson.reduce((total, cur, i) => (
    total.concat((i === 0 ? cur[0] ? '?' : '' : '&'), cur[0], '=', encodeURIComponent(cur[1]))
  ), '');
  return stringQuery;
};