import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  cities: [],
  isCitiesLoading: false,
  getCitiesFailed: null,

  districts: [],
  isDistrictsLoading: false,
  getDistrictsFailed: null,

  wards: [],
  isWardsLoading: false,
  getWardsFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CITIES_REQUEST:
      state.isCitiesLoading = true;
      state.cities = [];
      state.getCitiesFailed = null;
      return;

    case Types.GET_CITIES_SUCCESS:
      state.isCitiesLoading = false;
      state.cities = payload;
      return;

    case Types.GET_CITIES_FAILED:
      state.isCitiesLoading = false;
      state.getCitiesFailed = payload;
      return;

    case Types.GET_DISTRICTS_REQUEST:
      state.isDistrictsLoading = true;
      state.districts = [];
      state.getDistrictsFailed = null;
      return;

    case Types.GET_DISTRICTS_SUCCESS:
      state.isDistrictsLoading = false;
      state.districts = payload;
      return;

    case Types.GET_DISTRICTS_FAILED:
      state.isDistrictsLoading = false;
      state.getDistrictsFailed = payload;
      return;

    case Types.GET_WARDS_REQUEST:
      state.isWardsLoading = true;
      state.wards = [];
      state.getWardsFailed = null;
      return;

    case Types.GET_WARDS_SUCCESS:
      state.isWardsLoading = false;
      state.wards = payload;

      return;

    case Types.GET_WARDS_FAILED:
      state.isWardsLoading = false;
      state.getWardsFailed = payload;
      return;

    default:
      return;
  }
}, initState);
