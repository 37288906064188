import React, { useEffect } from 'react';
import { Form, Input, Row, Switch, Button, Skeleton, Modal, Col } from 'antd';

import {
  useCreateMedicineUnit,
  useMedicineUnit,
  useInitMedicineUnit
} from '~/hooks';
import { STATUS } from '~/constants/defaultValue';
const FormItem = Form.Item;

const MedicineUnitForm = ({ isOpen, onClose, id, handleUpdate }) => {
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateMedicineUnit(onClose);
  const [method, isLoading] = useMedicineUnit(id);
  const initMedicineUnit = useInitMedicineUnit(method, id);

  useEffect(() => {
    form.resetFields();
  }, [initMedicineUnit, form]);

  const onFinish = values => {
    const getStatus = ({ status }) =>
      status ? STATUS.ACTIVE : STATUS.INACTIVE;

    if (id) {
      handleUpdate({
        ...values,
        _id: id,
        status: getStatus(values)
      });
    } else {
      handleCreate({
        ...values,
        status: getStatus(values)
      });
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initMedicineUnit}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} đơn vị
            </h4>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Tên đơn vị"
                name="name"
                rules={[{ required: true, message: 'Xin mời nhập tên đơn vị' }]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem label="Ghi chú" name="description">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Trạng thái"
                name="status"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Hoạt động"
                  unCheckedChildren="Ngưng hoạt động"
                  disabled={isLoading}
                />
              </FormItem>
            </Col>
          </Row>

          <Row className="wc-setting-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default MedicineUnitForm;
