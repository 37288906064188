import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classname from 'classnames';
import { PATH_APP, PATH_CLINIC } from '~/routes/paths';
//i18n
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import WithOrPermission from '../Common/WithOrPermission';

const MenuItem = ({ link, label }) => (
  <Link to={link} className="dropdown-item dropdown-toggle arrow-none">
    {label}
  </Link>
);

// const SubMenuWraper = ({ label, icon, children, onClick }) => (
//   <div className="dropdown">
//     <Link
//       to="/#"
//       className="dropdown-item dropdown-toggle arrow-none"
//       onClick={e => {
//         e.preventDefault();
//         onClick?.();
//       }}
//     >
//       {icon}
//       {label} <div className="arrow-down"></div>
//     </Link>

//     <div className="dropdown-menu">{children}</div>
//   </div>
// );

const NavItem = ({ label, icon, children, onClick, isShow }) => (
  <li className="nav-item dropdown">
    <Link
      to="/#"
      className="nav-link dropdown-togglez arrow-none"
      onClick={e => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {icon}
      {label} <div className="arrow-down"></div>
    </Link>

    <div
      className={classname('dropdown-menu', {
        show: isShow
      })}
    >
      {children}
    </div>
  </li>
);

const Navbar = props => {
  const { t } = props;

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById('navigation');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      const isMatchPath = props.location.pathname === items[i].pathname;
      const isMatchSubpath =
        items[i].pathname !== '/' &&
        props.location.pathname.indexOf(items[i].pathname) >= 0;

      if (isMatchPath && isMatchSubpath) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="container-fluid topnav-wraper">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="fas fa-tachometer-alt me-2"></i>{' '}
                    {props.t('Tổng Quan')}
                  </Link>
                </li>

                <WithOrPermission
                  permission={
                    ([POLICY.READ_PRODUCT,
                    POLICY.READ_SAMPLEPRESCRIPTION,
                    POLICY.READ_MEDICINE])
                  }
                >
                  <NavItem
                    label={t('Hàng hoá')}
                    icon={<i className="uil uil-shopping-bag me-2"></i>}
                  >
                    <WithPermission permission={POLICY.READ_PRODUCT}>
                      <MenuItem
                        link={PATH_CLINIC.medicine.root}
                        label={t('Thuốc')}
                      />
                    </WithPermission>
                    <WithPermission permission={POLICY.READ_SAMPLEPRESCRIPTION}>
                      <MenuItem
                        link={PATH_CLINIC.samplePrescription.root}
                        label={t('Đơn thuốc mẫu')}
                      />
                    </WithPermission>
                    <WithPermission permission={POLICY.READ_MEDICINE}>
                      <MenuItem
                        link={PATH_CLINIC.medicineDirectory.root}
                        label={t('Danh mục thuốc')}
                      />
                    </WithPermission>
                    <WithPermission permission={POLICY.WRITE_PRODUCT}>
                      <MenuItem
                        link={PATH_CLINIC.processImport.root}
                        label={t('Nhập thuốc, hàng hoá')}
                      />
                    </WithPermission>
                  </NavItem>
                </WithOrPermission>

                <WithOrPermission
                  permission={
                    ([POLICY.READ_PURCHASEORDER,
                    POLICY.READ_PURCHASERETURN,
                    POLICY.READ_DAMAGEITEM,
                    POLICY.READ_SALEORDER,
                    POLICY.READ_BILL,
                    POLICY.READ_RETURN,
                    POLICY.READ_STOCKTAKE])
                  }
                >
                  <NavItem
                    label={t('Quản lý kho')}
                    icon={<i className="uil uil-store-alt me-2"></i>}
                  >
                      <MenuItem
                        link={PATH_CLINIC.purchaseOrderQuotation.root}
                        label={t('Yêu cầu nhập kho')}
                      />
                    <WithPermission permission={POLICY.READ_PURCHASEORDER}>
                      <MenuItem
                        link={PATH_CLINIC.warehouse.root}
                        label={t('Nhập kho')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_PURCHASERETURN}>
                      <MenuItem
                        link={PATH_CLINIC.purchaseReturn.root}
                        label={t('Trả hàng nhập')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_DAMAGEITEM}>
                      <MenuItem
                        link={PATH_CLINIC.damage.root}
                        label={t('Xuất huỷ')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_SALEORDER}>
                      <MenuItem
                        link={PATH_CLINIC.productOrder.root}
                        label={t('Đặt hàng')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_BILL}>
                      <MenuItem
                        link={PATH_CLINIC.productInvoice.root}
                        label={t('Hoá đơn')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_RETURN}>
                      <MenuItem
                        link={PATH_CLINIC.productReturn.root}
                        label={t('Trả hàng')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_STOCKTAKE}>
                      <MenuItem
                        link={PATH_CLINIC.stockTake.root}
                        label={t('Kiểm kho')}
                      />
                    </WithPermission>
                  </NavItem>
                </WithOrPermission>

                <WithPermission permission={POLICY.READ_CASHBOOK}>
                  <li className="nav-item">
                    <Link className="nav-link" to={PATH_CLINIC.cashFlow.root}>
                      <i className="uil uil-receipt-alt me-2"></i>
                      {t('Sổ quỹ')}
                    </Link>
                  </li>
                </WithPermission>

                <WithPermission permission={POLICY.READ_REPORT}>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={PATH_CLINIC.productReport.root}
                    >
                      <i className="uil uil-chart-line me-2" />
                      {t('Báo cáo')}
                    </Link>
                  </li>
                </WithPermission>

                <WithPermission permission={POLICY.READ_SALE}>
                  <li className="nav-item">
                    <Link className="nav-link" to={PATH_CLINIC.sale.root}>
                      <i className="uil uil-shopping-cart me-2"></i>
                      {t('Bán hàng')}
                    </Link>
                  </li>
                </WithPermission>

                <WithOrPermission
                  permission={([POLICY.READ_SUPPLIER, POLICY.READ_CUSTOMER])}
                >
                  <NavItem
                    label={t('Đối tác')}
                    icon={<i className="uil uil-building me-2"></i>}
                  >
                    <WithPermission permission={POLICY.READ_SUPPLIER}>
                      <MenuItem
                        link={PATH_CLINIC.supplier}
                        label={t('Nhà cung cấp')}
                      />
                    </WithPermission>
                    <WithPermission permission={POLICY.READ_CUSTOMER}>
                      <MenuItem
                        link={PATH_CLINIC.customer.root}
                        label={t('Khách hàng')}
                      />
                    </WithPermission>
                  </NavItem>
                </WithOrPermission>

                <WithOrPermission
                  permission={
                    [POLICY.READ_PRODUCTGROUP,
                    POLICY.READ_PRODUCTTYPE,
                    POLICY.READ_PRODUCTPOSITION,
                    POLICY.READ_PRODUCTUNIT,
                    POLICY.READ_SUPPLIERGROUP,
                    POLICY.READ_EMPLOYEE,
                    POLICY.READ_USER, 
                    POLICY.READ_BRANCH,
                    POLICY.READ_INFOPAYMENT,
                    POLICY.READ_ORDERFORM]
                  }
                >
                  <NavItem
                    label={t('Thiết lập')}
                    icon={<i className="uil uil-setting me-2"></i>}
                  >
                    <WithPermission permission={POLICY.READ_PRODUCTGROUP}>
                      <MenuItem
                        link={PATH_CLINIC.medicineSetting.group}
                        label={t('Nhóm thuốc')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_PRODUCTTYPE}>
                      <MenuItem
                        link={PATH_CLINIC.medicineSetting.method}
                        label={t('Đường dùng')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_PRODUCTPOSITION}>
                      <MenuItem
                        link={PATH_CLINIC.medicineSetting.location}
                        label={t('Vị trí kệ hàng')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_PRODUCTUNIT}>
                      <MenuItem
                        link={PATH_CLINIC.medicineSetting.unit}
                        label={t('Đơn vị')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_SUPPLIERGROUP}>
                      <MenuItem
                        link={PATH_CLINIC.medicineSetting.supplierGroup}
                        label={t('Nhóm nhà cung cấp')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_BRANCH}>
                      <MenuItem
                        link={PATH_APP.branch.root}
                        label={t('Chi nhánh')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_EMPLOYEE}>
                      <MenuItem
                        link={PATH_APP.employee.root}
                        label={t('Nhân viên')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_USER}>
                      <MenuItem
                        link={PATH_APP.user.root}
                        label={t('Người dùng')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_ORDERFORM}>
                      <MenuItem
                        link={PATH_CLINIC.formPrint.root}
                        label={t('Quản lý phiếu in')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_INFOPAYMENT}>
                      <MenuItem
                        link={PATH_CLINIC.imformationPayment.root}
                        label={t('Thông tin thanh toán')}
                      />
                    </WithPermission>

                    <WithPermission permission={POLICY.READ_MANUFACTER}>
                      <MenuItem
                        link={PATH_CLINIC.manufacturer.root}
                        label={t('Hãng sản xuất')}
                      />
                    </WithPermission>

                    {/* <WithPermission permission={POLICY.READ_MANUFACTER}> */}
                      <MenuItem
                        link={PATH_CLINIC.receipt.root}
                        label={t('Loại thu')}
                      />
                    {/* </WithPermission> */}

                    {/* <WithPermission permission={POLICY.READ_MANUFACTER}> */}
                      <MenuItem
                        link={PATH_CLINIC.payment.root}
                        label={t('Loại chi')}
                      />
                    {/* </WithPermission> */}

                  </NavItem>
                </WithOrPermission>

                <WithOrPermission permission={[POLICY.READ_PARTNER]}>
                  <NavItem
                    label={t('Đối tác dùng PMS')}
                    icon={<i className="uil uil-building me-2"></i>}
                  >
                      <MenuItem
                        link={PATH_APP.partner.root}
                        label={t('Quản lý cửa hàng đối tác')}
                      />
                  </NavItem>
                </WithOrPermission>

                <WithPermission permission={['HIDDEN']}>
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => e.preventDefault()}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="fas fa-file-invoice me-2"></i>
                      {props.t('Đơn Hàng')} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu')}>
                      <Link
                        to={PATH_APP.order.allOrder}
                        className="dropdown-item"
                      >
                        {props.t('Đơn Hàng')}
                      </Link>

                      <Link
                        to={PATH_APP.order.pendingOrder}
                        className="dropdown-item"
                      >
                        {props.t('Đơn Hàng Đang Xử Lý')}
                      </Link>
                    </div>
                  </li>
                </WithPermission>
                <WithPermission permission={['HIDDEN']}>
                  <li className="nav-item">
                    <Link to={PATH_APP.appointment.root} className="nav-link">
                      <i className="uil uil-schedule"></i> {props.t('Lịch hẹn')}{' '}
                    </Link>
                  </li>
                </WithPermission>

                {/* <WithPermission permission={POLICY.READ_PARTNER}>
                  <li className="nav-item">
                    <Link to={PATH_APP.partner.root} className="nav-link">
                      <i className="uil uil-building"></i> {props.t('Đối tác')}
                    </Link>
                  </li>
                </WithPermission> */}

                {/* <WithPermission permission={POLICY.READ_BRANCH}>
                  <li className="nav-item">
                    <Link to={PATH_APP.branch.root} className="nav-link">
                      <i className="uil uil-hospital "></i>{' '}
                      {props.t('Chi nhánh')}
                    </Link>
                  </li>
                </WithPermission>

                <WithPermission permission={POLICY.READ_EMPLOYEE}>
                  <li className="nav-item">
                    <Link to={PATH_APP.employee.root} className="nav-link">
                      <i className="uil uil-users-alt "></i>{' '}
                      {props.t('Nhân viên')}
                    </Link>
                  </li>
                </WithPermission>

                <WithPermission permission={POLICY.READ_USER}>
                  <li className="nav-item">
                    <Link to={PATH_APP.user.root} className="nav-link">
                      <i className="uil uil-user"></i> {props.t('Người dùng')}
                    </Link>
                  </li>
                </WithPermission> */}

                <WithPermission permission={['HIDDEN']}>
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="uil-apps me-2"></i>
                      {props.t('CMS')} <div className="arrow-down"></div>
                    </Link>

                    <div className={classname('dropdown-menu')}>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          {props.t('Phòng khám')}{' '}
                          <div className="arrow-down"></div>
                        </Link>

                        <div className={classname('dropdown-menu')}>
                          {/* <Link
                            to={PATH_APP.hospital.root}
                            className="dropdown-item"
                          >
                            {props.t('Danh sách phòng khám')}
                          </Link> */}

                          <Link
                            to={PATH_APP.staff.root}
                            className="dropdown-item"
                          >
                            {props.t('Danh sách nhân viên')}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          {props.t('Tin tức')}{' '}
                          <div className="arrow-down"></div>
                        </Link>
                        <div className={classname('dropdown-menu')}>
                          <Link
                            to={PATH_APP.newsCategory.root}
                            className="dropdown-item"
                          >
                            {props.t('Danh mục tin tức')}
                          </Link>
                          <Link
                            to={PATH_APP.news.root}
                            className="dropdown-item"
                          >
                            {props.t('Danh sách tin tức')}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          {props.t('Thiết lập')}{' '}
                          <div className="arrow-down"></div>
                        </Link>
                        <div className={classname('dropdown-menu')}>
                          <Link
                            to={PATH_APP.config.degree}
                            className="dropdown-item"
                          >
                            {props.t('Bằng cấp')}
                          </Link>
                          <Link
                            to={PATH_APP.config.speciality}
                            className="dropdown-item"
                          >
                            {props.t('Chuyên môn')}
                          </Link>
                          <Link
                            to={PATH_APP.config.staffGroup}
                            className="dropdown-item"
                          >
                            {props.t('Nhóm nhân viên')}
                          </Link>
                        </div>
                      </div>

                      <Link
                        to={PATH_APP.partnership.root}
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t('Đối tác hàng đầu')}{' '}
                      </Link>

                      <Link
                        to={PATH_APP.coupon.root}
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t('Mã giảm giá')}{' '}
                      </Link>

                      <Link
                        to={PATH_APP.policy.root}
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t('Chính sách')}{' '}
                      </Link>

                      <Link
                        to={PATH_APP.phippingVendor.root}
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t('Nhà vận chuyển')}{' '}
                      </Link>
                    </div>
                  </li>
                </WithPermission>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { leftMenu } = state.layout;
  const { policy } = state.user;
  return { leftMenu, policy };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
