import requester from '../requester';

const purchaseReturn = {
  getAll: query => requester.get('/purchase-return', query),
  getById: id => requester.get(`/purchase-return/${id}`),
  create: purchaseReturn => requester.post('/purchase-return', purchaseReturn),
  update: purchaseReturn =>
    requester.put(`/purchase-return/${purchaseReturn._id}`, purchaseReturn),
  delete: id => requester.delete(`/purchase-return/${id}`)
};

export default purchaseReturn;
