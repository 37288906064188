
import { Button, Row, Table } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react'
import DetailItem from './DetailItem';
import { onSelectRow } from '~/utils/validators';
import { RedoOutlined } from '@ant-design/icons';
import { useDrugValue } from '.';
import { useFetchDrugRequest, useSubmitDrugRequest, useSubmitTryDrugRequest } from '~/hooks';
import { head } from 'lodash';
import ListItem from './ListItem';



function TableDrugRequest() {
    const { product} =useDrugValue();
    const param = useMemo(()=>({id:product._id}),[product._id])
    const [itemActive, setItemActive] = useState(null);
    const [loadingSubmitTry,handleSubmitTryDrugRequest]=useSubmitTryDrugRequest()
    const [loadingSubmit,]=useSubmitDrugRequest()
    const [dataSource,loading] = useFetchDrugRequest(param)
    
    const columns = [
        {
            title: 'Ngày tạo',
            dataIndex : 'createdAt',
            key: 'createdAt',
            align: 'start',
            width :'14%',
            render :(record)=>{
                return moment(record).format('YYYY-MM-DD / HH:mm');
            }
        },
        {
            title: 'Loại yêu cầu',
            key: 'params',
            dataIndex : 'params',
            align: 'center',
            render :(record)=>{
                if(!record?.isUpdate){
                    return 'Tạo mới'
                }
                return 'Cập nhật'
            }
        },
        {
            title: 'Kết quả',
            key: 'drugLogs',
            dataIndex : 'drugLogs',
            width:100,
            align: 'center',
            render :(record)=>{
                switch (head(record).responseCode) {
                    case 401: return<ListItem.Wanning style={{backgroundColor:'orange'}}>{''}</ListItem.Wanning>
                    case 400: return<ListItem.Error style={{backgroundColor:'red'}}>{''}</ListItem.Error>
                    case 0: return<ListItem.Pending style={{backgroundColor:'blue'}}>{''}</ListItem.Pending>
                    default: return <ListItem.Success style={{backgroundColor:'green'}}>{''}</ListItem.Success>
                }
            }
        },
        {
            title: 'Hành động',
            key: 'action',
            align: 'center',
            width : 120,
            render :(record)=>{
                return <div style={{ marginLeft:'-9px', width:'100%', borderLeft:'1px solid #333',textAlign:'center'}}>
                    <Button loading={[loadingSubmitTry,loading].includes(true)} style={{borderRadius:6}} onClick={(e)=>{e.stopPropagation();handleSubmitTryDrugRequest({drugId:record._id,productId:product._id});}} ><RedoOutlined /></Button>
                </div>
            }
        },

    ]

    const  expandedRowRender =(_)=>{
        return <DetailItem product={_} loading={[loadingSubmitTry,loading].includes(true)}/>
    }
    
    return (
        <Row style={{width:'100%'}}>
            <Table 
                style={{width:'100%'}}
                loading={[loading,loadingSubmit].includes(true)}
                columns={columns}
                dataSource={dataSource}
                rowKey={rc =>  rc._id}
                onRow={onSelectRow(setItemActive)}
                expandable={{
                expandedRowRender,
                expandedRowKeys: [itemActive]
                }}
            />
        </Row>
    )
}

export default TableDrugRequest
