import { omit } from 'lodash';
import requester from '../requester';

const partner = {
  getAll: (query) => requester.get('/partner', query),
  getById: (id) => requester.get(`/partner/${id}?raw=true`),
  create: (partner) => requester.post('/partner', partner),
  update: (partner) => requester.put(`/partner/${partner._id}`, partner),
  delete: (id) => requester.delete(`/partner/${id}`),
  getBranches: (partnerId) => requester.get(`/partner/${partnerId}/branch`),
  getApiKey: (partnerId) => requester.get(`/apikey/partner/${partnerId}`),
  updateApiKey: (data) => requester.put(`/apikey/partner/${data?.id}`, omit(data, ['id'])),
};

export default partner;
