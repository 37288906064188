export const GET_DEGREES_REQUEST = 'GET_DEGREES_REQUEST';
export const GET_DEGREES_SUCCESS = 'GET_DEGREES_SUCCESS';
export const GET_DEGREES_FAILED = 'GET_DEGREES_FAILED';

export const GET_DEGREE_REQUEST = 'GET_DEGREE_REQUEST';
export const GET_DEGREE_SUCCESS = 'GET_DEGREE_SUCCESS';
export const GET_DEGREE_FAILED = 'GET_DEGREE_FAILED';

export const CREATE_DEGREE_REQUEST = 'CREATE_DEGREE_REQUEST';
export const CREATE_DEGREE_SUCCESS = 'CREATE_DEGREE_SUCCESS';
export const CREATE_DEGREE_FAILED = 'CREATE_DEGREE_FAILED';

export const UPDATE_DEGREE_REQUEST = 'UPDATE_DEGREE_REQUEST';
export const UPDATE_DEGREE_SUCCESS = 'UPDATE_DEGREE_SUCCESS';
export const UPDATE_DEGREE_FAILED = 'UPDATE_DEGREE_FAILED';

export const DELETE_DEGREE_REQUEST = 'DELETE_DEGREE_REQUEST';
export const DELETE_DEGREE_SUCCESS = 'DELETE_DEGREE_SUCCESS';
export const DELETE_DEGREE_FAILED = 'DELETE_DEGREE_FAILED';

export const RESET_DEGREE_STATE = 'RESET_DEGREE_STATE';
