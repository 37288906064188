import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getMedicineCategories,
  createMedicineCategory,
  deleteMedicineCategory,
  getMedicineCategory,
  resetMedicineCategoryState,
  updateMedicineCategory
} from '~/redux/action';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { Menu, Checkbox } from 'antd';

const SUPPLIER_MODULE = 'tradingPartner';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(SUPPLIER_MODULE);

export const useMedicineCategories = query => {
  return useFetchByParam({
    action: getMedicineCategories,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateMedicineCategory = callback => {
  useSuccess(
    createSuccessSelector,
    'Tạo mới danh mục thuốc thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới danh mục thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicineCategory
  });
};

export const useUpdateMedicineCategory = callback => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật danh mục thuốc thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật danh mục thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicineCategory
  });
};

export const useDeleteMedicineCategory = onDeleteSuccess => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá danh mục thuốc thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector, 'Xoá danh mục thuốc thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteMedicineCategory
  });
};

export const useMedicineCategory = params => {
  return useFetchByParam({
    action: getMedicineCategory,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitMedicineCategory = (tradingPartner, id) => {
  return useMemo(() => {
    if (!tradingPartner || !id) {
      return {};
    }

    return { ...tradingPartner };
  }, [tradingPartner, id]);
};

export const useUpdateMedicineCategoryParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/trading-partner',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useMedicineCategoryQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, updateSuccess, deleteSuccess]);
};

export const useMedicineCategoryPaging = () => useSelector(pagingSelector);

export const useResetMedicineCategory = () => {
  useResetState(resetMedicineCategoryState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Tên danh mục thuốc',
    key: 'name'
  },
  {
    title: 'Mã danh mục thuốc',
    key: 'supplierCode'
  },

  {
    title: 'Địa chỉ',
    key: 'address'
  },

  {
    title: 'Điện thoại',
    key: 'phoneNumber'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Nợ cần trả',
    key: 'debt'
  },
  {
    title: 'Tổng mua',
    key: 'totalPurchase'
  },
  {
    title: 'Trạng thái',
    key: 'status'
  },
  {
    title: 'Thao tác',
    key: 'action'
  }
];

export const useColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    name: true,
    supplierCode: true,
    address: true,
    action: true,
    phoneNumber: true,
    status: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};
