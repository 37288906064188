export const GET_ALL_ORDERS_REQUEST = 'GET_ALL_ORDERS_REQUEST';
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS';
export const GET_ALL_ORDERS_FAILED = 'GET_ALL_ORDERS_FAILED';

export const GET_PENDING_ORDERS_REQUEST = 'GET_PENDING_ORDERS_REQUEST';
export const GET_PENDING_ORDERS_SUCCESS = 'GET_PENDING_ORDERS_SUCCESS';
export const GET_PENDING_ORDERS_FAILED = 'GET_PENDING_ORDERS_FAILED';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED';

export const GET_SHIPPING_VENDOR_REQUEST = 'GET_SHIPPING_VENDOR_REQUEST';
export const GET_SHIPPING_VENDOR_SUCCESS = 'GET_SHIPPING_VENDOR_SUCCESS';
export const GET_SHIPPING_VENDOR_FAILED = 'GET_SHIPPING_VENDOR_FAILED';

export const ADD_MEDICINE_TO_ORDER_REQUEST = 'ADD_MEDICINE_TO_ORDER_REQUEST';
export const ADD_MEDICINE_TO_ORDER_SUCCESS = 'ADD_MEDICINE_TO_ORDER_SUCCESS';
export const ADD_MEDICINE_TO_ORDER_FAILED = 'ADD_MEDICINE_TO_ORDER_FAILED';

export const UPDATE_ORDER_MEDICINE_REQUEST = 'UPDATE_ORDER_MEDICINE_REQUEST';
export const UPDATE_ORDER_MEDICINE_SUCCESS = 'UPDATE_ORDER_MEDICINE_SUCCESS';
export const UPDATE_ORDER_MEDICINE_FAILED = 'UPDATE_ORDER_MEDICINE_FAILED';

export const DELETE_ORDER_MEDICINE_REQUEST = 'DELETE_ORDER_MEDICINE_REQUEST';
export const DELETE_ORDER_MEDICINE_SUCCESS = 'DELETE_ORDER_MEDICINE_SUCCESS';
export const DELETE_ORDER_MEDICINE_FAILED = 'DELETE_ORDER_MEDICINE_FAILED';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILED = 'DELETE_ORDER_FAILED';

export const RESET_ORDER_STATE = 'RESET_ORDER_STATE';
