import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Modal,
  Radio,
  InputNumber,
  Skeleton
} from 'antd';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreatePolicy,
  usePolicy,
  useInitPolicy,
  useUpdatePolicy
} from '~/hooks';
import './index.scss';
import { LANGUAGE } from '~/constants/defaultValue';
import Editor from '~/components/Utils/Editor';

const FormItem = Form.Item;

const maxWidthLayout = {
  labelCol: { sm: 24, md: 24, lg: 3 },
  wrapperCol: { sm: 24, md: 24, lg: 21 }
};

const PolicyForm = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreatePolicy(onClose);
  const [, handleUpdate] = useUpdatePolicy(onClose);
  const [policy, isLoading] = usePolicy(id);
  const initPolicy = useInitPolicy(policy, id);
  const [language, setLanguage] = useState(LANGUAGE.VI);

  useEffect(() => {
    form.resetFields();
  }, [initPolicy, form]);

  const onFinish = (values) => {
    if (id) {
      handleUpdate({ ...values, _id: id });
    } else {
      handleCreate(values);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="policy-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values) => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initPolicy}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
        >
          <Row style={{ marginBottom: 20, marginTop: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} chính sách
            </h4>
            <Radio.Group
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <Radio.Button
                className="policy-form__select-langue-btn"
                value={LANGUAGE.VI}
              >
                VI
              </Radio.Button>
              <Radio.Button
                className="policy-form__select-langue-btn"
                value={LANGUAGE.EN}
              >
                EN
              </Radio.Button>
            </Radio.Group>
          </Row>

          <Row>
            <Col span={12}>
              <FormItem
                label="Tiêu đề"
                name={['title', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem
                label="Tiêu đề"
                name={['title', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <FormItem label="Thứ tự" name="index">
                {isLoading ? <Skeleton.Input active /> : <InputNumber />}
              </FormItem>
            </Col>
          </Row>

          <Row className="news-category-form__editor">
            <FormItem
              label="Nội dung"
              name={['content', LANGUAGE.VI]}
              className={`news-category-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              <Editor />
            </FormItem>

            <FormItem
              label="Nội dung"
              name={['content', LANGUAGE.EN]}
              className={`news-category-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              <Editor />
            </FormItem>
          </Row>

          <Row className="policy-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default PolicyForm;
