import { useMemo } from 'react';
import {
  useFailed,
  useFetch,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';

import {
  getStaffGroups,
  createStaffGroup,
  deleteStaffGroup,
  getStaffGroup,
  resetStaffGroupState,
  updateStaffGroup
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.staffGroup[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useStaffGroups = () => {
  return useFetch({
    action: getStaffGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};

export const useCreateStaffGroup = () => {
  const history = useHistory();
  const callback = () => history.push('/staffGroup');

  useSuccess(
    createSuccessSelector,
    'Tạo mới nhóm nhân viên thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createStaffGroup
  });
};

export const useUpdateStaffGroup = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật nhóm nhân viên thành công');
  useFailed(updateFailedSelector, 'Cập nhật nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateStaffGroup
  });
};

export const useDeleteStaffGroup = () => {
  useSuccess(deleteSuccessSelector, 'Xoá nhóm nhân viên thành công');
  useFailed(deleteFailedSelector, 'Xoá nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteStaffGroup
  });
};

export const useStaffGroup = id => {
  return useFetchByParam({
    action: getStaffGroup,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitStaffGroup = staffGroup =>
  useMemo(() => {
    if (!staffGroup) {
      return {};
    }

    return staffGroup;
  }, [staffGroup]);

export const useResetStaffGroup = () => {
  useResetState(resetStaffGroupState);
};
