import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getUserEmployees,
  createUserEmployee,
  deleteUserEmployee,
  getUserEmployee,
  resetUserEmployeeState,
  updateUserEmployee,
  createUsername,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const USER_EMPLOYEE_MODULE = 'userEmployee';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(USER_EMPLOYEE_MODULE);

const getSelector = key => state => state[USER_EMPLOYEE_MODULE][key];
const pagingSelector = getSelector('paging');

export const useUserEmployees = (query) => {
  return useFetchByParam({
    action: getUserEmployees,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateUserEmployee = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới người dùng thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới người dùng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createUserEmployee
  });
};

export const useUpdateUserEmployee = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật người dùng thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật người dùng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateUserEmployee
  });
};

export const useDeleteUserEmployee = onDeleteSuccess => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá người dùng thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector, 'Xoá người dùng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteUserEmployee
  });
};

export const useUserEmployee = params => {
  return useFetchByParam({
    action: getUserEmployee,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useUpdateUserEmployeeParams = query => {
  const history = useHistory();
  const { id: branchId } = useParams();
  const pathName = branchId
    ? `/branch/detail/${branchId}/userEmployee`
    : '/userEmployee';

  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

    history.push({
      pathName,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          branchId
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useUserEmployeeQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const page = query.get('page') || 1;
  const { id, branchId } = useParams();

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId,
      branchId: branchId ?? id
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    branchId,
    id,
    createSuccess,
    updateSuccess,
    deleteSuccess
  ]);
};

export const useUserEmployeePaging = () => useSelector(pagingSelector);

export const useInitUserEmployee = (userEmployee, id) => {
  return useMemo(() => {
    if (!userEmployee || !id) {
      return {};
    }

    const initValues = {
      ...userEmployee,
      groups: userEmployee?.groups?.map(({ _id }) => _id),
    };

    return initValues;
  }, [userEmployee, id]);
};

export const useCreateUsername = () => {
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createUsername,
  });
}

export const useResetUserEmployee = () => {
  useResetState(resetUserEmployeeState);
};
