import produce from 'immer';
import { omit } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  isSubmitApiKeyLoading: false,
  updateApiKeySuccess: null,
  updateApiKeyFailed: null,

  branches: null,
  isGetBranchesLoading: false,
  getBranchesFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PARTNERS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PARTNERS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PARTNERS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_PARTNER_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_PARTNER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_PARTNER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    
    case Types.UPDATE_API_KEY_PARTNER_SAVE_REQUEST:
      state.isSubmitApiKeyLoading = true;
      return;

    case Types.UPDATE_API_KEY_PARTNER_SAVE_SUCCESS:
      state.isSubmitApiKeyLoading = false;
      state.updateApiKeySuccess = payload;
      // state.byId = payload;
      return;

    case Types.UPDATE_API_KEY_PARTNER_SAVE_FAILED:
      state.isSubmitApiKeyLoading = false;
      state.updateApiKeyFailed = payload;
      return;

    case Types.DELETE_PARTNER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_PARTNER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_PARTNER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_PARTNER_BRANCHES_REQUEST:
      state.isGetBranchesLoading = true;
      state.getBranchesFailed = null;
      return;

    case Types.GET_PARTNER_BRANCHES_SUCCESS:
      state.isGetBranchesLoading = false;
      state.branches = payload;
      return;

    case Types.GET_PARTNER_BRANCHES_FAILED:
      state.isGetBranchesLoading = false;
      state.getBranchesFailed = payload;
      return;

    case Types.RESET_PARTNER_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
