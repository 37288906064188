import * as Types from '~/constants/actionTypes';

export const getSale = id => ({
  type: Types.GET_SALE_REQUEST,
  payload: id
});

export const createSaleRequest = sale => ({
  type: Types.CREATE_SALE_REQUEST,
  payload: sale
});

export const createSale = sale => ({
  type: Types.CREATE_SALE,
  payload: sale
});

export const updateSale = sale => ({
  type: Types.UPDATE_SALE_REQUEST,
  payload: sale
});

export const updateSales = sales => ({
  type: Types.UPDATE_SALES,
  payload: sales
});

export const deleteSale = uuid => ({
  type: Types.DELETE_SALE,
  payload: uuid
});

export const setSelectedSale = uuid => ({
  type: Types.SET_SELECTED_SALE,
  payload: uuid
});

export const setSelectedExchangeReturn = uuid => ({
  type: Types.SET_SELECTED_EXCHANGE_RETURN,
  payload: uuid
});

export const resetSaleState = () => ({
  type: Types.RESET_SALE_STATE
});

export const removeSubmitedSale = () => ({
  type: Types.REMOVE_SUBMITED_SALE
});
export const setSelectedSaleProductVariant = payload => ({
  type: Types.SET_SELECTED_SALE_PRODUCT_VARIANT,
  payload
});

export const updateLogisticCodeSale = sale => ({
  type: Types.UPDATE_LOGISTIC_CODE_SALE_REQUEST,
  payload: sale
});

export const updateSaleCheckInOut = sale => ({
  type: Types.SALE_CHECK_IN_OUT_REQUEST,
  payload: sale
});

export const setNewBill = (status) => ({
  type: Types.SET_NEW_BILL,
  payload : status
});