import React, { useState, useCallback } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { BASE_URL_UPLOAD_IMAGE } from '~/constants/defaultValue';
import { useUser } from '~/hooks';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const UploadImage = ({ onChange, imgUrl, title, action = BASE_URL_UPLOAD_IMAGE , disabled = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, token] = useUser();
  const handleChange = useCallback(
    (info) => {
      if (info.file.status === 'uploading') {
        setIsLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        const imageUrl = info.file?.response?.url;
        setIsLoading(false);
        onChange(imageUrl);
      }
    },
    [onChange]
  );

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{title || 'Logo'}</div>
    </div>
  );

  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={action}
      headers={{Authorization: 'Bearer '+ token}}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      disabled={disabled}
    >
      {imgUrl && !isLoading ? (
        <img src={imgUrl} alt="avatar" style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadImage;
