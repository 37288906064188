import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
import {composeWithDevTools} from 'redux-devtools-extension';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const middlewareEnhancers = applyMiddleware(...middlewares)

const composeEnhancers = composeWithDevTools(middlewareEnhancers)
// mount it on the Store
const store = createStore(rootReducer, composeEnhancers);

// then run the saga
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
