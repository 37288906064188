import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useFetch
} from '~/hooks/utils';

import {
  getMedicines,
  createMedicine,
  deleteMedicine,
  getMedicine,
  resetMedicineState,
  updateMedicine,
  getMedicineOptions,
  getCountries,
  setSelectedProductVariant,
  searchProducts,
  searchProduct,
  searchVariantsByProduct,
  getProductStock,
  getProductStockCard,
  getReferenceDocsProductStockCard,
  getProductLot,
  getProductStockCardOutside,
  submitDrugRequest,
  getProductDrugRequest,
  submitTryDrugRequest
} from '~/redux/action';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { Menu, Checkbox } from 'antd';
import { PATH_CLINIC } from '~/routes/paths';
import { TYPE_SEARCH } from '~/constants/defaultValue';
import { get } from 'lodash';

const MEDICINE_MODULE = 'medicine';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(MEDICINE_MODULE);

export const useMedicines = query => {
  return useFetchByParam({
    action: getMedicines,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateMedicine = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới thuốc thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicine
  });
};

export const useUpdateMedicine = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật thuốc thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicine
  });
};

export const useDeleteMedicine = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, 'Xoá thuốc thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá thuốc thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteMedicine
  });
};

export const useMedicine = params => {
  return useFetchByParam({
    action: getMedicine,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitMedicine = (medicine, id) => {
  return useMemo(() => {
    if (!medicine || !id) {
      return { isRequest : get(medicine,'isRequest',false)};
    }

    return {
      ...medicine,
      defaultVariant: medicine.productVariants.find(
        ({ isDefault }) => isDefault
      ),
      productVariants: medicine.productVariants.filter(
        ({ isDefault }) => !isDefault
      ),
      isRequest : get(medicine,'isRequest',false)
    };
  }, [medicine, id]);
};

export const useUpdateMedicineParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);
  useEffect(() => {
    setKeyword(query.keyword)
  },[query.keyword])
  const onParamChange = param => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: PATH_CLINIC.medicine.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useMedicineQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page') || 1;
  const searchAll = query.get('searchAll');
  const name = query.get('name');
  const group = query.get('group');
  const createdAt = query.get('createdAt');

  const registrationNo = query.get('registrationNo');
  const productCode = query.get('productCode');
  const lotNumber = query.get('lotNumber');
  const expirationDate = query.get('expirationDate');
  const description = query.get('description');
  const category = query.get('category');
  const noteTemplate = query.get('noteTemplate');
  const ingredient = query.get('ingredient');
  const dosage = query.get('dosage');
  const manufacturer = query.get('manufacturer');
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  // if (prevKeyword.current !== keyword) {
  //   prevKeyword.current = keyword;
  // }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      searchAll,
      name,
      registrationNo,
      productCode,
      lotNumber,
      category,
      expirationDate,
      description,
      noteTemplate,
      ingredient,
      dosage,
      manufacturer,
      group,
      createdAt
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, updateSuccess, deleteSuccess,searchAll,name,
    registrationNo,
    productCode,
    lotNumber,
    expirationDate,
    description,
    noteTemplate,
    category,
    ingredient,
    dosage,
    group,
    createdAt,
    manufacturer]);
};

export const useMedicinePaging = () => useSelector(pagingSelector);

export const useResetMedicine = () => {
  useResetState(resetMedicineState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã hàng',
    key: 'productCode'
  },
  {
    title: 'Tên hàng',
    key: 'name'
  },

  {
    title: 'Tên viết tắt',
    key: 'aliasName'
  },
  {
    title: 'Loại hàng hoá',
    key: 'type'
  },
  {
    title: 'Giá vốn',
    key: 'cost'
  },
  {
    title: 'Giá bán',
    key: 'price'
  },
  {
    title: 'Tồn kho',
    key: 'remain'
  }
];

export const useMedicineColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    productCode: true,
    name: true,
    aliasName: true,
    cost: true,
    price: true,

    remain: true,
    type: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

const getSelector = key => state => state[MEDICINE_MODULE][key];
const _getSelector=deepkey=> key => state => state[MEDICINE_MODULE][key][deepkey];

const optionsSelector = getSelector('options');
const getOptionsLoadingSelector = getSelector('isGetOptionsLoading');
const getOptionsFailedSelector = getSelector('getOptionsFailed');

export const useMedicineOptions = searchText => {
  return useFetchByParam({
    action: getMedicineOptions,
    loadingSelector: getOptionsLoadingSelector,
    dataSelector: optionsSelector,
    failedSelector: getOptionsFailedSelector,
    param: searchText
  });
};

const countriesSelector = getSelector('countries');
const getCountriesLoadingSelector = getSelector('isGetCountriesLoading');
const getCountriesFailedSelector = getSelector('getCountriesFailed');

export const useCountries = () => {
  return useFetch({
    action: getCountries,
    loadingSelector: getCountriesLoadingSelector,
    dataSelector: countriesSelector,
    failedSelector: getCountriesFailedSelector
  });
};

export const useSelectVariant = () => {
  return useSubmit({
    loadingSelector,
    action: setSelectedProductVariant
  });
};

const productsSelector = getSelector('products');
const searchProductsLoadingSelector = getSelector('isSearchProductsLoading');
const searchProductsFailedSelector = getSelector('searchProductsFailed');

export const useSearchProducts = searchText => {
  return useFetchByParam({
    action: searchProducts,
    loadingSelector: searchProductsLoadingSelector,
    dataSelector: productsSelector,
    failedSelector: searchProductsFailedSelector,
    param: searchText
  });
};

let timeOut;

export const useSearchProductBox = () => {
  const [searchText, setSearchText] = useState('');
  const [products, isLoading] = useSearchProducts(searchText);

  const inputEl = useRef(null);

  useEffect(() => {
    return () => {
      timeOut = null;
    };
  }, []);

  const onSearch = value => {
    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      if (timeOut) {
        setSearchText(value);
      }
    }, 500);
  };

  return [
    {
      products,
      isLoading,
      inputEl,
      searchText
    },
    { onSearch }
  ];
};

const productSelector = getSelector('product');
const searchProductLoadingSelector = getSelector('isSearchProductLoading');
const searchProductFailedSelector = getSelector('searchProductFailed');

export const useSearchProduct = code => {
  return useFetchByParam({
    action: searchProduct,
    loadingSelector: searchProductLoadingSelector,
    dataSelector: productSelector,
    failedSelector: searchProductFailedSelector,
    param: code
  });
};

const variantsByProductSelector = getSelector('variantsByProduct');
const searchVariantsByProductLoadingSelector = getSelector(
  'isSearchVariantsByProductLoading'
);
const searchVariantsByProductFailedSelector = getSelector(
  'searchVariantsByProductFailed'
);

export const useSearchVariantsByProduct = productId => {
  return useFetchByParam({
    action: searchVariantsByProduct,
    loadingSelector: searchVariantsByProductLoadingSelector,
    dataSelector: variantsByProductSelector,
    failedSelector: searchVariantsByProductFailedSelector,
    param: productId
  });
};

const productStockSelector = getSelector('productStock');
const getProductStockLoadingSelector = getSelector('isGetProductStockLoading');
const getProductStockFailedSelector = getSelector('getProductStockFailed');

export const useProductStock = variantId => {
  return useFetchByParam({
    action: getProductStock,
    loadingSelector: getProductStockLoadingSelector,
    dataSelector: productStockSelector,
    failedSelector: getProductStockFailedSelector,
    param: variantId
  });
};

const productLotSelector = getSelector('productLot');
const getProductLotLoadingSelector = getSelector('isGetProductLotLoading');
const getProductLotFailedSelector = getSelector('getProductLotFailed');

export const useProductLot = variantId => {
  return useFetchByParam({
    action: getProductLot,
    loadingSelector: getProductLotLoadingSelector,
    dataSelector: productLotSelector,
    failedSelector: getProductLotFailedSelector,
    param: variantId
  });
};

const productStockCardSelector = getSelector('productStockCard');
const getProductStockCardLoadingSelector = getSelector('isGetProductStockCardLoading');
const getProductStockCardFailedSelector = getSelector('getProductStockCardFailed');
const productDrugRequestSelector =(v)=>_getSelector(v)('productDrugRequestList');
const getProductDrugRequestLoadingSelector = getSelector('getProductDrugRequestLoading');
const getProductDrugRequestFailedSelector = getSelector('getProductDrugRequestFailed');

export const useProductStockCard = productId => {
  return useFetchByParam({
    action: getProductStockCard,
    loadingSelector: getProductStockCardLoadingSelector,
    dataSelector: productStockCardSelector,
    failedSelector: getProductStockCardFailedSelector,
    param: productId
  });
};

const getReferenceDocsStockCardSelector = getSelector('productReferenceDocStockCard');
const getReferenceDocsStockCardLoadingSelector = getSelector('isGetReferenceDocProductStockCardLoading');
const getReferenceDocsFailedSelector = getSelector('getReferenceDocProductStockCardFailed');

export const useReferenceDocStockCard = params => {
  const jsonQuery = JSON.stringify(params);

  const queryRef = useRef(null);

  if(jsonQuery !== JSON.stringify(queryRef.current)) {
    queryRef.current = params
  }

  return useFetchByParam({
    action: getReferenceDocsProductStockCard,
    loadingSelector: getReferenceDocsStockCardLoadingSelector,
    dataSelector: getReferenceDocsStockCardSelector,
    failedSelector: getReferenceDocsFailedSelector,
    param: queryRef.current
  });
};

const productStockCardOutsideSelector = getSelector('productStockCardOutside');
const getProductStockCardOutsideLoadingSelector = getSelector('isGetProductStockCardOutsideLoading');
const getProductStockCardOutsideFailedSelector = getSelector('getProductStockCardOutsideFailed');
export const useProductStockCardOutside = param => {
  const jsonQuery = JSON.stringify(param);

  const queryRef = useRef(null);

  if(jsonQuery !== JSON.stringify(queryRef.current)) {
    queryRef.current = param
  }

  return useFetchByParam({
    action: getProductStockCardOutside,
    loadingSelector: getProductStockCardOutsideLoadingSelector,
    dataSelector: productStockCardOutsideSelector,
    failedSelector: getProductStockCardOutsideFailedSelector,
    param: queryRef.current
  });
};

export const useSubmitDrugRequest=()=>{
  return useSubmit({
    loadingSelector:(state)=>state[MEDICINE_MODULE]['loadingSubmitDrugRequest'],
    action: submitDrugRequest
  });
}
export const useSubmitTryDrugRequest=()=>{
  return useSubmit({
    loadingSelector:(state)=>state[MEDICINE_MODULE]['loadingSubmitTryDrugRequest'],
    action: submitTryDrugRequest
  });
}

export const useFetchDrugRequest=(productId) => {
  return useFetchByParam({
    action: getProductDrugRequest,
    loadingSelector: getProductDrugRequestLoadingSelector,
    dataSelector: productDrugRequestSelector(productId.id), 
    failedSelector: getProductDrugRequestFailedSelector,
    param: productId
  });
}

export const SEARCH_MEDICINE =[
  { name: 'name', placeholder: 'Theo tên hàng' },
  { name: 'registrationNo', placeholder: 'Theo số đăng ký' },
  { name: 'productCode', placeholder: 'Theo mã thuốc' },
  { name: 'group', placeholder: 'Theo nhóm thuốc' },
  { name: 'lotNumber', placeholder: 'Theo Lô' },
  { name: 'expirationDate', placeholder: 'Theo hạn sử dụng', type: TYPE_SEARCH.date, label: "Hạn sử dụng" },
  { name: 'ingredient', placeholder: 'Theo hoạt chất' },
  { name: 'dosage', placeholder: 'Theo hàm lượng' },
  { name: 'manufacturer', placeholder: 'Theo hãng sản xuất' },
  {
    name: "createdAt",
    type: "rangerTime",
    label:"Thời gian",
},
]
