import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import {
  DELETE_HOSPITAL_REQUEST,
  DELETE_HOSPITAL_SUCCESS,
  DELETE_HOSPITAL_FAILED,
  GET_HOSPITALS_REQUEST,
  GET_HOSPITALS_SUCCESS,
  GET_HOSPITALS_FAILED,
  CREATE_HOSPITAL_REQUEST,
  CREATE_HOSPITAL_SUCCESS,
  CREATE_HOSPITAL_FAILED,
  GET_HOSPITAL_REQUEST,
  GET_HOSPITAL_SUCCESS,
  GET_HOSPITAL_FAILED,
  UPDATE_HOSPITAL_REQUEST,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_FAILED,
  GET_HOSPITAL_AVAILABLE_TIME_REQUEST,
  GET_HOSPITAL_AVAILABLE_TIME_SUCCESS,
  GET_HOSPITAL_AVAILABLE_TIME_FAILED,
  GET_ALL_HOSPITALS_REQUEST,
  GET_ALL_HOSPITALS_SUCCESS,
  GET_ALL_HOSPITALS_FAILED
} from '~/constants/actionTypes';

function* getHospitals({ payload: query }) {
  try {
    const response = yield call(Api.hospital.getHospitals, query);
    yield put({ type: GET_HOSPITALS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_HOSPITALS_FAILED, payload: error.message });
  }
}

function* getHospital({ payload: id }) {
  try {
    const hospital = yield call(Api.hospital.getById, id);
    yield put({ type: GET_HOSPITAL_SUCCESS, payload: hospital });
  } catch (error) {
    yield put({ type: GET_HOSPITAL_FAILED, payload: error.message });
  }
}

function* createHospital(action) {
  try {
    const data = yield call(Api.hospital.create, action.payload);
    yield put({ type: CREATE_HOSPITAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_HOSPITAL_FAILED, payload: error.message });
  }
}

function* updateHospital(action) {
  try {
    const data = yield call(Api.hospital.update, action.payload);
    yield put({ type: UPDATE_HOSPITAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_HOSPITAL_FAILED, payload: error.message });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.hospital.delete, payload);
    yield put({ type: DELETE_HOSPITAL_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_HOSPITAL_FAILED, payload: error.message });
  }
}

function* getAvailableTime({ payload }) {
  try {
    const data = yield call(Api.hospital.getAvailableTime, payload);
    yield put({ type: GET_HOSPITAL_AVAILABLE_TIME_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: GET_HOSPITAL_AVAILABLE_TIME_FAILED,
      payload: error.message
    });
  }
}

function* getAllHospitals() {
  try {
    const { docs } = yield call(Api.hospital.getAll);
    yield put({ type: GET_ALL_HOSPITALS_SUCCESS, payload: docs });
  } catch (error) {
    yield put({ type: GET_ALL_HOSPITALS_FAILED, payload: error.message });
  }
}

export default function* hospital() {
  yield takeLatest(GET_HOSPITALS_REQUEST, getHospitals);
  yield takeLatest(GET_HOSPITAL_REQUEST, getHospital);
  yield takeLatest(DELETE_HOSPITAL_REQUEST, deleteHopital);
  yield takeLatest(CREATE_HOSPITAL_REQUEST, createHospital);
  yield takeLatest(UPDATE_HOSPITAL_REQUEST, updateHospital);
  yield takeLatest(GET_HOSPITAL_AVAILABLE_TIME_REQUEST, getAvailableTime);
  yield takeLatest(GET_ALL_HOSPITALS_REQUEST, getAllHospitals);
}
