export const GET_MEDICINE_GROUPS_REQUEST = 'GET_MEDICINE_GROUPS_REQUEST';
export const GET_MEDICINE_GROUPS_SUCCESS = 'GET_MEDICINE_GROUPS_SUCCESS';
export const GET_MEDICINE_GROUPS_FAILED = 'GET_MEDICINE_GROUPS_FAILED';

export const GET_MEDICINE_GROUP_OPTIONS_REQUEST =
  'GET_MEDICINE_GROUP_OPTIONS_REQUEST';
export const GET_MEDICINE_GROUP_OPTIONS_SUCCESS =
  'GET_MEDICINE_GROUP_OPTIONS_SUCCESS';
export const GET_MEDICINE_GROUP_OPTIONS_FAILED =
  'GET_MEDICINE_GROUP_OPTIONS_FAILED';

export const GET_MEDICINE_GROUP_REQUEST = 'GET_MEDICINE_GROUP_REQUEST';
export const GET_MEDICINE_GROUP_SUCCESS = 'GET_MEDICINE_GROUP_SUCCESS';
export const GET_MEDICINE_GROUP_FAILED = 'GET_MEDICINE_GROUP_FAILED';

export const CREATE_MEDICINE_GROUP_REQUEST = 'CREATE_MEDICINE_GROUP_REQUEST';
export const CREATE_MEDICINE_GROUP_SUCCESS = 'CREATE_MEDICINE_GROUP_SUCCESS';
export const CREATE_MEDICINE_GROUP_FAILED = 'CREATE_MEDICINE_GROUP_FAILED';

export const UPDATE_MEDICINE_GROUP_REQUEST = 'UPDATE_MEDICINE_GROUP_REQUEST';
export const UPDATE_MEDICINE_GROUP_SUCCESS = 'UPDATE_MEDICINE_GROUP_SUCCESS';
export const UPDATE_MEDICINE_GROUP_FAILED = 'UPDATE_MEDICINE_GROUP_FAILED';

export const DELETE_MEDICINE_GROUP_REQUEST = 'DELETE_MEDICINE_GROUP_REQUEST';
export const DELETE_MEDICINE_GROUP_SUCCESS = 'DELETE_MEDICINE_GROUP_SUCCESS';
export const DELETE_MEDICINE_GROUP_FAILED = 'DELETE_MEDICINE_GROUP_FAILED';

export const RESET_MEDICINE_GROUP_STATE = 'RESET_MEDICINE_GROUP_STATE';
