// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/',
  app: ''
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },

  comingSoon: '/coming-soon',
  maintenance: '/maintenance'
};

export const PATH_APP = {
  root: ROOTS.app,

  main: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard')
  },

  phippingVendor: {
    root: path(ROOTS.app, '/shipping-vendor')
  },

  policy: {
    root: path(ROOTS.app, '/policy')
  },

  coupon: {
    root: path(ROOTS.app, '/coupon')
  },

  partnership: {
    root: path(ROOTS.app, '/partnership')
  },

  employee: {
    root: path(ROOTS.app, '/employee')
  },

  user: {
    root: path(ROOTS.app, '/user')
  },

  partner: {
    root: path(ROOTS.app, '/partner'),
    create: path(ROOTS.app, '/partner/create'),
    edit: path(ROOTS.app, '/partner/edit/:id'),
    detail: path(ROOTS.app, '/partner/detail/:id'),
    branches: path(ROOTS.app, '/partner/detail/:id/branches/:branchId')
  },

  branch: {
    root: path(ROOTS.app, '/branch'),
    detail: path(ROOTS.app, '/branch/detail/:id') 
  },

  hospital: {
    root: path(ROOTS.app, '/hospital'),
    create: path(ROOTS.app, '/hospital/create'),
    edit: path(ROOTS.app, '/hospital/edit/:id')
  },

  staff: {
    root: path(ROOTS.app, '/staff'),
    create: path(ROOTS.app, '/staff/create'),
    edit: path(ROOTS.app, '/staff/edit/:id')
  },

  appointment: {
    root: path(ROOTS.app, '/appointment'),
    createAppointment: path(ROOTS.app, '/appointment/create')
  },

  news: {
    root: path(ROOTS.app, '/news'),
    create: path(ROOTS.app, '/news/create'),
    edit: path(ROOTS.app, '/news/edit/:id')
  },

  newsCategory: {
    root: path(ROOTS.app, '/news-category'),
    create: path(ROOTS.app, '/news-category/create'),
    edit: path(ROOTS.app, '/news-category/edit/:id')
  },

  order: {
    pendingOrder: path(ROOTS.app, '/pending-orders'),
    allOrder: path(ROOTS.app, '/all-orders')
  },

  config: {
    degree: path(ROOTS.app, '/degree'),
    createDegree: path(ROOTS.app, '/degree/create'),
    editDegree: path(ROOTS.app, '/degree/edit/:id'),

    speciality: path(ROOTS.app, '/speciality'),
    createSpeciality: path(ROOTS.app, '/speciality/create'),
    editSpeciality: path(ROOTS.app, '/speciality/edit/:id'),

    staffGroup: path(ROOTS.app, '/staff-group'),
    createStaffGroup: path(ROOTS.app, '/staff-group/create'),
    editStaffGroup: path(ROOTS.app, '/staff-group/edit/:id')
  }
};

export const PATH_CLINIC = {
  medicineSetting: {
    group: path(ROOTS.app, '/medicine-group'),
    method: path(ROOTS.app, '/medicine-method'),
    location: path(ROOTS.app, '/medicine-location'),
    unit: path(ROOTS.app, '/medicine-unit'),
    supplierGroup: path(ROOTS.app, '/supplier-group')
  },

  supplier: path(ROOTS.app, '/supplier'),
  medicineCategory: path(ROOTS.app, '/medicine-category'),

  medicineDirectory: {
    root: path(ROOTS.app, '/medicine')
  },

  medicine: {
    root: path(ROOTS.app, '/product'),
  },

  samplePrescription: {
    root: path(ROOTS.app, '/sample-prescription'),
    create: path(ROOTS.app, '/sample-prescription/create'),
    edit: path(ROOTS.app, '/sample-prescription/edit/:id'),
  },

  purchaseOrderQuotation: {
    root: path(ROOTS.app, '/purchase-order-quotation'),
    // create: path(ROOTS.app, '/purchase-order-quotation/create'),
    // edit: path(ROOTS.app, '/purchase-order-quotation/edit/:id')
  },
  warehouse: {
    root: path(ROOTS.app, '/warehouse'),
    create: path(ROOTS.app, '/warehouse/create'),
    edit: path(ROOTS.app, '/warehouse/edit/:id')
  },

  productOrder: {
    root: path(ROOTS.app, '/product-order'),
    create: path(ROOTS.app, '/product-order/create')
  },

  productInvoice: {
    root: path(ROOTS.app, '/product-invoice'),
    create: path(ROOTS.app, '/product-invoice/create')
  },

  cashFlow: {
    root: path(ROOTS.app, '/cash-flow')
  },

  sale: {
    root: path(ROOTS.app, '/sale')
  },

  formPrint :{
    root: path(ROOTS.app,'/formPrint'),
    create: path(ROOTS.app,'/formPrint/create'),
    edit: path(ROOTS.app, '/formPrint/edit/:id')
  },

  imformationPayment :{
    root: path(ROOTS.app,'/imformationPayment'),
  },

  manufacturer :{
    root: path(ROOTS.app,'/manufacturer'),
    create: path(ROOTS.app, '/manufacturer/create'),
    edit: path(ROOTS.app, '/manufacturer/edit/:id')
  },

  purchaseReturn: {
    root: path(ROOTS.app, '/purchase-return'),
    create: path(ROOTS.app, '/purchase-return/create'),
    edit: path(ROOTS.app, '/purchase-return/edit/:id')
  },

  damage: {
    root: path(ROOTS.app, '/damage'),
    create: path(ROOTS.app, '/damage/create'),
    edit: path(ROOTS.app, '/damage/edit/:id')
  },

  customer: {
    root: path(ROOTS.app, '/customer')
  },

  productReturn: {
    root: path(ROOTS.app, '/product-return'),
    create: path(ROOTS.app, '/product-return/create'),
  },

  productReport: {
    root: path(ROOTS.app, '/product-report')
  },

  stockTake: {
    root: path(ROOTS.app, '/stocktake'),
    create: path(ROOTS.app, '/stocktake/create')
  },

  payment: {
    root: path(ROOTS.app, '/payment'),
  },

  receipt: {
    root: path(ROOTS.app, '/receipt'),
  },
  processImport: {
    root: path(ROOTS.app, '/process-import'),
  },
  myNotification: {
    root: path(ROOTS.app, '/my-notification')
  },
};
