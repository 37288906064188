import { getSelectors, useFailed, useFetchByParam, useQueryParams, useSubmit, useSuccess } from '~/hooks/utils';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getExistProp } from '~/utils/helper';
import { useHistory } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';
import {
  returnProduct,
  exchangeProduct,
  getProductReturns,
  updateProductInfoReturn,
  getReceivers
} from '~/redux/action';
import { Checkbox, Menu } from 'antd';

const PRODUCT_RETURN_MODULE = 'productReturn';
const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  deleteSuccessSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  pagingSelector,
  updateSuccessSelector,
  updateFailedSelector,
} = getSelectors(PRODUCT_RETURN_MODULE);

const CREATE_SUCCESS_RETURN_MESS = 'Trả hàng thành công';
const CREATE_FAILED_FAIL_MESS = 'Trả hàng thất bại';
const CREATE_SUCCESS_EXCHANGE_MESS = 'Đổi hàng thành công';
const CREATE_FAILED_EXCHANGE_MESS = 'Đổi hàng thất bại';
const UPDATE_SUCCESS_MESS = 'Update danh sách phiếu trả hàng thành công';
const UPDATE_FAILED_MESS = 'Update danh sách phiếu trả hàng thất bại';
export const PAYMENTMETHOD={
  CASH: 'Bán trực tiếp',
  CREDITCARD: 'Thanh toán chuyển khoản'
}

const getSelector = key => state => state[PRODUCT_RETURN_MODULE][key];

export const useProductReturns = query => {
  return useFetchByParam({
    action: getProductReturns,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  })
}

export const useReturnProduct = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_RETURN_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_FAIL_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: returnProduct
  });
};

export const useExchangeProduct = callback => {
  useSuccess(updateSuccessSelector, CREATE_SUCCESS_EXCHANGE_MESS, callback);
  useFailed(updateFailedSelector, CREATE_FAILED_EXCHANGE_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: exchangeProduct
  });
};

export const useUpdateProductInfoReturn = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProductInfoReturn
  });
};

export const useProductReturnQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page') || 1;
  const status =  query.get('status')
  const id = query.get('id');
  const expirationDate = query.get('expirationDate');
  const code = query.get('code');
  const variantCodeProductName = query.get('variantCodeProductName');
  const purchasedAt = query.get('purchasedAt');
  const customer = query.get('customer');

  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      status,
      keyword,
      id,
      expirationDate,
      code,
      variantCodeProductName,
      purchasedAt,
      customer
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, 
      limit, 
      keyword, 
      deleteSuccess, 
      id,
      status,
      expirationDate,
      code,
      variantCodeProductName,
      purchasedAt,
      customer
    ]);
};

//update params
export const useUpdateProductReturnParams = query => {
  const history = useHistory();
  const [keyword,setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: PATH_CLINIC.productReturn.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          id: undefined
        })
      ).toString()
    })
  };
  return [keyword, { setKeyword, onParamChange }];
}

//get page
export const useProductReturnPaging = () => useSelector(pagingSelector);

//setting column
const COLUMNS_OPTIONS = [
  {
    title: 'Mã trả hàng',
    key: 'code'
  },
  {
    title: 'Người bán',
    key: 'receivedBy',
    dataIndex: ['receivedBy', 'fullName']
  },
  {
    title: 'Thời gian',
    key: 'time'
  },

  {
    title: 'Khách hàng',
    key: 'customer',
    dataIndex: ['customer', 'name']
  },
  {
    title: 'Cần trả khách',
    key: 'needToPay',
    dataIndex: 'needToPay',
  },
  {
    title: 'Đã trả khách',
    key: 'paid',
    dataIndex: 'paid',
  },
  {
    title: 'Trạng thái',
    key: 'status'
  }
];

const receivers = getSelector('receivers');
const isSearchReceivers = getSelector('isSearchReceivers');
const searchReceiversFailedSelector = getSelector('searchReceiversFailedSelector');

export const useSearchReceivers = (query) => {
  return useFetchByParam({
    action: getReceivers,
    loadingSelector: isSearchReceivers,
    dataSelector: receivers,
    failedSelector: searchReceiversFailedSelector,
    param: query
  });
};

let timeOut;

export const useSearchReceiversBox = () => {
  const [ searchValue, setSearchValue ] = useState('');
  const [receivers, isLoading] = useSearchReceivers(searchValue);

  const inputEl = useRef(null);

  useEffect(() => {
    return () => {
      timeOut = null;
    };
  }, []);

  const onSearch = data => {
    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      if (timeOut) {
        setSearchValue(data);
      }
    }, 500);
  };

  return [
    {
      receivers,
      isLoading,
      inputEl,
    },
    { onSearch }
  ];
};

export const useProductReturnColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    receivedBy: true,
    time: true,
    customer: true,
    totalReturn: true,
    status: true,
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};
export const SEARCH_PRODUCT_RETURN = [
  {
    name: "code",
    placeholder:"Theo mã trả hàng"
},
{
    name: "variantCodeProductName",
    placeholder:"Theo mã, tên hàng"
},
{
    name: "customer",
    placeholder:"Theo tên khách hàng hoặc số điện thoại"
},
{
    name: "expirationDate",
    type: "date",
    label:"Hạn sử dụng",
},
{
    name: "updatedAt",
    type: "rangerTime",
    label:"Thời gian",
},
{
    name: "status",
    type: "radio",
    label: "Trạng thái",
}   
]