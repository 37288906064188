import React from 'react';
import {
  Switch,
  Link,
  useHistory,
  useRouteMatch,
  Redirect
} from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { PATH_APP } from '~/routes/paths';
import { NavItem, Nav } from 'reactstrap';
import Users from './Users';
import Groups from './Groups';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import PermissionGate from '~/routes/middleware/PermissionGate';

const UserEmployee = () => {
  const { path, url } = useRouteMatch();
  const {
    location: { pathname }
  } = useHistory();

  const className = (path) => {
    return `nav-link ${pathname.split('/').includes(path) && 'active'}`;
  };

  return (
    <div className="branch-detail page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          routes={[
            { path: PATH_APP.branch.root, title: 'Danh sách người dùng' }
          ]}
        />

        <Nav
          tabs
          className="nav-tabs-custom nav-justified mb-4"
          style={{
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: -16
          }}
        >
          <WithPermission permission={POLICY.READ_USER}>
            <NavItem>
              <Link to={`${url}/list`} className={className('list')}>
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Người dùng</span>
              </Link>
            </NavItem>
          </WithPermission>

          <WithPermission permission={POLICY.READ_USERGROUP}>
            <NavItem>
              <Link to={`${url}/group`} className={className('group')}>
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Nhóm người dùng</span>
              </Link>
            </NavItem>
          </WithPermission>
        </Nav>

        <Switch>
          <PermissionGate
            path={`${path}/list`}
            component={Users}
            permission={POLICY.READ_USER}
          />
          <PermissionGate
            path={`${path}/group`}
            component={Groups}
            permission={POLICY.READ_USERGROUP}
          />

          <Redirect to={`${path}/list`}></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default UserEmployee;
