import React, { useState, useMemo } from 'react';
import {
  usePartnerBranches,
  useDeleteBranch,
  useBranchTree,
  usePartnerBranchesParams,
  usePartnerBranchesQuery,
  useMatchOrPolicy,
  useUpdateBranch
} from '~/hooks';
import { Table, Popconfirm, Input, Button, Tag, Switch } from 'antd';
import { Link, useParams,  useRouteMatch, } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import FilterModules from '~/components/Common/FilterModules';
import { searchByName } from '~/utils/helper';
import BranchForm from '~/components/WorldCare/Branch/Form';
import WithOrPermission from '~/components/Common/WithOrPermission';

const { Search } = Input;

const ColumnActions = ({
  _id,
  deleteBranch,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithOrPermission permission={[POLICY.UPDATE_BRANCH,POLICY.UPDATE_PARTNER ]}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithOrPermission>
      {shouldShowDevider && <p>|</p>}

      <WithOrPermission permission={[POLICY.DELETE_BRANCH,POLICY.DELETE_PARTNER ] }>
        <Popconfirm
          title="Bạn muốn xoá chi nhánh này?"
          onConfirm={() => deleteBranch(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithOrPermission>
    </div>
  );
};

const ColumnStatus = ({
  _id,
  status,
  isSubmitLoading,
  updateBranch,
  statusPartner,
  ...restProps
}) => {
  const [isChecked, setIsChecked] = useState(status === 'ACTIVE');
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_CUSTOMER}>
        {statusPartner === 'INACTIVE' ? (
          <Switch
            checked={isChecked}
            onChange={value => {
              setIsChecked(!isChecked);
              updateBranch({ status: 'INACTIVE', _id });
            }}
            disabled
          />
        ) : (
          <Switch
            checked={isChecked}
            onChange={value => {
              setIsChecked(!isChecked);
              updateBranch({ status: value ? 'ACTIVE' : 'INACTIVE', _id });
            }}
          />
        )}
      </WithPermission>
    </div>
  );
};

const Branches = ({ statusPartner }) => {
  const { path, url } = useRouteMatch();
  const { id: partnerId } = useParams();
  const [branches, isLoading] = usePartnerBranches(partnerId);
  // const branchTree = useBranchTree(branches);
  const [, deleteBranch] = useDeleteBranch();
  const [, updateBranch] = useUpdateBranch();

  const hasUpdate = useMatchOrPolicy([POLICY.UPDATE_PARTNER,POLICY.UPDATE_BRANCH ]);
  const hasDelete = useMatchOrPolicy([POLICY.DELETE_BRANCH, POLICY.DELETE_PARTNER]);
  const shouldShowDevider = hasUpdate && hasDelete;
  const [searchText, setSearchText] = useState('');

  // const [keyword, setKeyword] = useState(null)

  const data = useMemo(() => searchByName(branches, searchText), [
    branches,
    searchText
  ]);
  const [branchId, setBranchId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const onOpenForm = id => {
    setBranchId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setBranchId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên chi nhánh',
      key: 'branchName',
      render: (rc) => <Link  onClick={(e) =>{e.stopPropagation();}} to={`${url}/${rc._id}/info`}>{rc.name}</Link>
          },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>
          {address &&
            `${address?.street} - ${address?.ward} - ${address?.district} - ${address?.city}`}
        </p>
      )
    },
    // {
    //   title: 'Dịch Vụ',
    //   key: 'speciality',
    //   dataIndex: 'speciality',
    //   render: (speciality) => (
    //     <div className="speciality-tags">
    //       {speciality?.map((spec, index) => {
    //         return (
    //           <Tag color="blue" key={index}>
    //             {spec?.name}
    //           </Tag>
    //         );
    //       })}
    //     </div>
    //   )
    // }

    {
      title: 'Trạng thái',
      key: 'status',
      align: 'center',
      render: record => {
        return (
          <ColumnStatus
            {...record}
            updateBranch={updateBranch}
            statusPartner={statusPartner}
          />
        );
      }
    }
  ];

  if (hasUpdate || hasDelete) {
    columns.push({
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: record => {
        return (
          <ColumnActions
            {...record}
            deleteBranch={deleteBranch}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
          />
        );
      }
    });
  }

  return (
    <div className="">
      <div className="">
        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <FilterCity
              // value={query.cityId}
              // onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />
            {/* <FilterModules
            // value={query?.modules?.split(',')}
            // onChange={(value) => onParamChange({ modules: value })}
            /> */}
          </div>
          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <Search

                style={{ width: 300 }}
                placeholder="Tìm chi nhánh"
                enterButton
                onSearch={(e)=>{setSearchText(e)}}
                // onChange={(e) => {
                //   setSearchText(e.target.value)

                // }}
                // value={searchText}
              />

              <WithOrPermission permission={[POLICY.WRITE_BRANCH, POLICY.WRITE_PARTNER]}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm(null)}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithOrPermission>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={data?.length} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={data}
                size="middle"
                pagination={{
                  total: data?.length,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <BranchForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        id={branchId}
        handleUpdate={updateBranch}
      />
    </div>
  );
};

export default Branches;
