import React, { useState } from 'react';
import {
  useBranches,
  useDeleteBranch,
  useBranchQueryParams,
  useBranchPaging,
  useUpdateBranchParams,
  useResetBranch,
  useBranchTree,
  useUpdateBranch
} from '~/hooks';
import { Table, Popconfirm, Input, Button } from 'antd';
import { Link ,useRouteMatch} from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import FilterModules from '~/components/Common/FilterModules';
import BrachForm from './Form';
import { onSelectRow } from '~/utils/validators';

const { Search } = Input;

const ColumnActions = ({
  _id,
  deleteBranch,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_BRANCH}>
        <p onClick={(e) =>{e.stopPropagation(); onOpenForm(_id)}}> Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}

      <WithPermission permission={POLICY.DELETE_BRANCH}>
        <Popconfirm
          title="Bạn muốn xoá chi nhánh này?"
          onConfirm={() => deleteBranch(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p onClick={(e) =>{e.stopPropagation();}}>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Branches = () => {
  const { path, url } = useRouteMatch();

  const [query] = useBranchQueryParams();
  console.log(query)
  const [keyword, { setKeyword, onParamChange }] = useUpdateBranchParams(query);
  const [branches, isLoading] = useBranches(query);
  const branchTree = useBranchTree(branches);

  const [branchId, setBranchId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [selectRow,setSelectRow]=useState(null)

  const paging = useBranchPaging();

  const hasUpdate = useMatchPolicy(POLICY.UPDATE_BRANCH);
  const hasDelete = useMatchPolicy(POLICY.DELETE_BRANCH);
  const shouldShowDevider = hasUpdate && hasDelete;

  const numberOfBranchs = branches.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfBranchs === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteBranch] = useDeleteBranch(onDeleteSuccess);
  const [, updateBranch] = useUpdateBranch();

  useResetBranch();

  const onOpenForm = (id) => {
    setBranchId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setBranchId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên Chi Nhánh',
      key: 'branchName',
      render: (rc) => <Link onClick={(e) =>{e.stopPropagation();}} to={`/branch/detail/${rc._id}`}>{rc.name}</Link>
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>
          {address &&
            `${address?.street} - ${address?.ward} - ${address?.district} - ${address?.city}`}
        </p>
      )
    },
    // {
    //   title: 'Dịch Vụ',
    //   key: 'speciality',
    //   dataIndex: 'speciality',
    //   render: (speciality) => (
    //     <div className="speciality-tags">
    //       {speciality?.map((spec, index) => {
    //         return (
    //           <Tag color="blue" key={index}>
    //             {spec?.name}
    //           </Tag>
    //         );
    //       })}
    //     </div>
    //   )
    // }
  ];

  if (hasUpdate || hasDelete) {
    columns.push({
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions
            {...record}
            deleteBranch={deleteBranch}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
          />
        );
      }
    });
  }

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách chi nhánh" />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <FilterCity
              value={query.cityId}
              onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />

            {/* <FilterModules
              value={query?.modules?.split(',')}
              onChange={(value) => onParamChange({ modules: value })}
            /> */}
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <Search
                defaultValue={query?.keyword ?? ''}
                allowClear
                style={{ width: 300 }}
                placeholder="Tìm chi nhánh"
                enterButton
                onSearch={() => onParamChange({ keyword })}
                onChange={(e) => {
                  setKeyword(e.target.value)
                  if (!e.target.value) {
                    onParamChange({ keyword: ''})
                  }
                }}
                value={keyword}
              />

              <WithPermission permission={POLICY.WRITE_BRANCH}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm()}
                >
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable
                columns={columns}
                rowCount={branches.length}
                // pagination={paging}
                pagination={false}
              />
            ) : (
              <Table
                rowKey={(rc) => String(rc._id)}
                columns={columns}
                dataSource={branchTree}
                // pagination={{
                //   ...paging,
                //   showTotal: () => `Tổng cộng: ${branches.length} `
                // }}
                pagination={{
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                onRow={onSelectRow(setSelectRow)}
                onExpand={(expanded, record) => {
                  expanded ? setSelectRow(String(record._id)) : setSelectRow(null);
                }}
                expandable={{
                  expandedRowKeys:[selectRow],
                }}
                size="middle"
              />
              
            )}
          </div>
     
        </div>
      </div>
      
      <BrachForm isOpen={isOpenForm} onClose={onCloseForm} id={branchId} handleUpdate={updateBranch}/>
    </div>
  );
};

export default Branches;
