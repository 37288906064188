import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getFormPrints({ payload: query }) {
  try {
    const data = yield call(Api.formPrint.getAll, query);
    yield put({ type: Types.GET_FORM_PRINTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_FORM_PRINTS_FAILED,
      payload: error
    });
  }
}

// function* getFormPrint({ payload }) {
//   try {
//     const formPrint = yield call(Api.formPrint.getById, payload);
//     yield put({
//       type: Types.GET_FORM_PRINT_SUCCESS,
//       payload: formPrint
//     });
//   } catch (error) {
//     yield put({
//       type: Types.GET_FORM_PRINT_FAILED,
//       payload: error.message
//     });
//   }
// }

function* createFormPrint(action) {
  try {
    const data = yield call(Api.formPrint.create, action.payload);
    yield put({ type: Types.CREATE_FORM_PRINT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_FORM_PRINT_FAILED,
      payload: error
    });
  }
}

function* updateFormPrint(action) {
  try {
    const data = yield call(Api.formPrint.update, action.payload);
    yield put({ type: Types.UPDATE_FORM_PRINT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_FORM_PRINT_FAILED,
      payload: error
    });
  }
}

// function* deleteFormPrint({ payload }) {
//   try {
//     yield call(Api.formPrint.delete, payload);
//     yield put({ type: Types.DELETE_FORM_PRINT_SUCCESS, payload });
//   } catch (error) {
//     yield put({
//       type: Types.DELETE_FORM_PRINT_FAILED,
//       payload: error.message
//     });
//   }
// }

export default function* formPrint() {
  yield takeLatest(Types.GET_FORM_PRINTS_REQUEST, getFormPrints);
  // yield takeLatest(Types.GET_FORM_PRINT_REQUEST, getFormPrint);
  // yield takeLatest(Types.DELETE_FORM_PRINT_REQUEST, deleteFormPrint);
  yield takeLatest(Types.CREATE_FORM_PRINT_REQUEST, createFormPrint);
  yield takeLatest(Types.UPDATE_FORM_PRINT_REQUEST, updateFormPrint);
}
