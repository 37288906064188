import requester from '../requester';

const formPrint = {
  getAll: query => requester.get('/orderform', query),
  getById: id => requester.get(`/orderform/${id}`),
  create: formPrint => requester.post('/orderform', formPrint),
  update: formPrint => requester.put(`/orderform/${formPrint._id}`, formPrint),
  delete: id => requester.delete(`/orderform/${id}`)
};

export default formPrint;