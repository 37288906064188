import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Radio, Button, Select, Skeleton } from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateSpeciality,
  useSpeciality,
  useInitSpeciality,
  useResetSpeciality,
  useUpdateSpeciality
} from '~/hooks/worldCare/speciality';
import { useFormItemMargin } from '~/hooks/utils';

import './form.scss';
import { SERVICES_TYPES, SERVICES_NAME } from '~/constants/defaultValue';
import { useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';

const { Option } = Select;

const FormItem = Form.Item;

const maxWidthLayout = {
  labelCol: { sm: 24, md: 24, lg: 3 },
  wrapperCol: { sm: 24, md: 24, lg: 21 }
};

const SpecialityForm = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [icon, setIcon] = useState();

  const [isSubmitLoading, handleCreate] = useCreateSpeciality();
  const [, handleUpdate] = useUpdateSpeciality();

  const { id } = useParams();
  const [hospital, isGetSpecialityLoading] = useSpeciality(id);
  const initSpeciality = useInitSpeciality(hospital);
  const margin = useFormItemMargin();

  useEffect(() => {
    form.resetFields();
    const { icon } = initSpeciality;

    if (icon) {
      setIcon(icon);
    }
  }, [initSpeciality, form]);

  useResetSpeciality();

  const onFinish = (values) => {
    if (id) {
      handleUpdate({ ...values, id, icon });
    } else {
      handleCreate({ ...values, icon });
    }
  };

  const isLoading = isGetSpecialityLoading;

  return (
    <div className="hospital-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} chuyên môn`}
          routes={[
            { path: PATH_APP.config.speciality, title: 'Danh sách chuyên môn' }
          ]}
        />

        <Form
          form={form}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initSpeciality}
          validateTrigger="onChange"
        >
          <Row justify="end">
            <Radio.Group
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <Radio.Button
                className="hospital-form__select-langue-btn"
                value={LANGUAGE.VI}
              >
                VI
              </Radio.Button>
              <Radio.Button
                className="hospital-form__select-langue-btn"
                value={LANGUAGE.EN}
              >
                EN
              </Radio.Button>
            </Radio.Group>
          </Row>

          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="hospital-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên chuyên môn"
                name={['name', LANGUAGE.VI]}
                className={`${language === LANGUAGE.EN && 'hiden'}`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
              <FormItem
                label="Tên chuyên môn"
                name={['name', LANGUAGE.EN]}
                className={`${language === LANGUAGE.VI && 'hiden'}`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem label="Dịch vụ" name="service">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    {SERVICES_TYPES.map((type) => (
                      <Option value={type} key={type}>
                        {SERVICES_NAME[type]}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12} className="hospital-form__upload-logo">
              <UploadImage onChange={setIcon} imgUrl={icon} />
            </Col>
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.VI]}
              className={`hospital-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>

            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.EN]}
              className={`hospital-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>
          </Row>

          <Row className="hospital-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.config.speciality}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default SpecialityForm;
