import { call, put, takeLatest } from 'redux-saga/effects';
import * as Types from '~/constants/actionTypes/worldClinic';
import { get } from 'lodash';
import Api from '~/api';
function* getMyNotifications({ payload: query }) {
    try {
      const response = yield call(Api.myNotification.getMyNotification, query);
      yield put({ type: Types.GET_MY_NOTIFICATIONS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_MY_NOTIFICATIONS_FAILED, payload: error });
    }
  }

function* changeStatusMyNotifications({ payload: query }) {
    try {
      const response = yield call(Api.myNotification.updateStatus, query);
      yield put({ type: Types.CHANGE_STATUS_NOTIFICATION_SUCCESS, payload: {...response,status:get(query,'status')} });
    } catch (error) {
      yield put({ type: Types.CHANGE_STATUS_NOTIFICATION_FAILED, payload: error });
    }
  }


function* changeManyStatusMyNotifications({ payload: query }) {
    try {
      const response = yield call(Api.myNotification.updateManyStatus, query);
      yield put({ type: Types.CHANGE_MANY_STATUS_NOTIFICATION_SUCCESS, payload: get(query,'ids') });
    } catch (error) {
      yield put({ type: Types.CHANGE_MANY_STATUS_NOTIFICATION_FAILED, payload: error });
    }
  }
export default function* MyNotifications() {
    yield takeLatest(Types.GET_MY_NOTIFICATIONS_REQUEST, getMyNotifications);
    yield takeLatest(Types.CHANGE_STATUS_NOTIFICATION_REQUEST, changeStatusMyNotifications);
    yield takeLatest(Types.CHANGE_MANY_STATUS_NOTIFICATION_REQUEST, changeManyStatusMyNotifications);
  }
