import {
  Form,
  Col,
  Row,
  Input,
  Button,
  Select,
  DatePicker,
  Modal,
  Checkbox, TimePicker, Spin
} from 'antd';
import { FileTextOutlined, StopOutlined } from '@ant-design/icons';
import { filterAcrossAccents } from '~/hooks/utils';
import ReceiptPaymentTypeModal from '../../ReceiptPaymentType/Form';
import RecipientsSubmittersTypeModal from '~/components/WorldClinic/CashFlow/Detail/RecipientsSubmittersTypeModal';
import {
  useSearchPayerReceiversBox,
  useReceiptsPaymentType,
  useCreatePaymentReceivedNote,
  useUpdateReceiptPaymentType,
} from '~/hooks';

import moment from 'moment';
import React, { useState, useRef, useEffect, useMemo } from 'react';
const { TextArea  } = Input;
const FormItem = Form.Item;
const { Option } = Select;

const ReceiptPaymentModal = ({ isOpen, isReceipt, onClose, handleRenderCashFlow, dataView }) => {
  const [isReceiptPaymentTypeOpen, setIsReceiptPaymentTypeOpen] = useState(false)
  const [isRecipientsSubmittersOpen, setIsRecipientsSubmittersOpen] = useState(false)
  const [nameReceiptPayment, setNameReceiptPayments] = useState('')
  const [payRecTypeId, setPayRecTypeId] = useState(null)
  const [optionReceiptPayments, setOptionReceiptPayments] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [isSubmitLoading, updateReceiptPaymentType] = useUpdateReceiptPaymentType();
  const [isCheckReceipt, setIsCheckReceipt] = useState(isReceipt);


  const tileModalCreate = isCheckReceipt ? 'Lập phiếu thu (tiền mặt)' : 'Lập phiếu chi (tiền mặt)'; // Create voucher
  const tileModalView = isCheckReceipt === 'RECEIPT' ? 'Phiếu thu' : 'Phiếu chi'; // have Data
  const tileModalHasDataView = dataView ? tileModalView : tileModalCreate;

  const [form] = Form.useForm();
  const formRef = useRef(null)

  const [
    { receiptPayments, isLoading, inputEl, searchText },
    { onSearch }
  ] = useSearchPayerReceiversBox();

  useEffect(() => {
    setOptionReceiptPayments([receiptPayments])
  }, [receiptPayments])

  useEffect(() => {
    setIsCheckReceipt(isReceipt);
    form.setFieldsValue({ paymentNoteTypeId: dataView?.paymentMethod || '' });
  }, [form, isReceipt, dataView?.paymentMethod])

  const querySearch = useMemo(()=>{return {
    type:isCheckReceipt ? (isReceiptPaymentTypeOpen ? '' : 'RECEIPT') : (isReceiptPaymentTypeOpen ? '' : 'PAYMENT')
  }},[isCheckReceipt, isReceiptPaymentTypeOpen])
  const [dataPayReceiveType] = useReceiptsPaymentType( querySearch );

  const onShowModalRecipientsSubmitters = () => {
    setIsRecipientsSubmittersOpen(true)
  };

  const onShowModalType = () => {
    setIsReceiptPaymentTypeOpen(true)
  }

  const handleSelect = option => {
    inputEl.current.blur();
    setNameReceiptPayments(option.name);
    setPayRecTypeId(option._id);
  };

  const [, handleCreatePayRec] = useCreatePaymentReceivedNote(onClose);

  const onFinish = (value) => {
    const { date, time } = value

    handleCreatePayRec({
      type: isCheckReceipt? 'RECEIPT' : 'PAYMENT',
      paymentMethod: dataPayReceiveType.find(payRecType => payRecType._id === value['paymentNoteTypeId'])?.name,
      paymentAmount: parseInt(value.paymentAmount),
      payerReceiver: {
        target: value.payerReceiver.target.toUpperCase(),
        _id: String(payRecTypeId),
        name: nameReceiptPayment,
      },
      paymentNoteTypeId: value['paymentNoteTypeId'],
      note: value.note,
      createdDate: `${moment(`${date}`).format('YYYY-MM-DD')} ${moment(`${time}`).format('HH:mm:ss')}`
    })

    handleRenderCashFlow()
  }

  const handleOnSearch = (value) => {
    onSearch({
      target: formRef.current.getFieldValue('payerReceiver')?.target || null,
      keyword: value
    })
  }

  const onChangeCheckBox = (checkedValues) => {
    console.log('Values = ', checkedValues);
  };

  useEffect(() => {
    if(isOpen) {
      form.resetFields();
      setNameReceiptPayments('')
    }
  }, [isOpen, form])

  return (
    <>
      <Modal forceRender width={980} visible={isOpen} footer={null} onCancel={onClose} closable={false}>
        <h4 style={{ paddingLeft: 30, marginBottom: 30, fontSize: '1.5rem' }}> {tileModalHasDataView}</h4>

        <Form
          form={form}
          ref={formRef}
          onFinish={onFinish}
        >
          <Row gutter={48}>
            <Col md={12}>
              <FormItem name="code" label={<label style={{width: '7rem', fontWeight: 600}}>Mã phiếu</label>}>
                <Input autoFocus={true} placeholder='Mã phiếu thu tự động' disabled/>
              </FormItem>
              <FormItem label={<label style={{width: '7rem', fontWeight: 600}}>Thời gian</label>}>
                <Row justify="start" style={{ borderBottom: '1px solid #dedede' }}>
                  <Col style={{width: '100px'}}>
                    <FormItem
                      noStyle
                      name="date"
                      initialValue={dataView?.createdDate ? moment(dataView.createdDate) : moment()}>
                      <DatePicker
                        bordered={false}
                        allowClear={false}
                        suffixIcon={null}
                        style={{
                          paddingRight: 0,
                        }}
                        format={'DD-MM-YYYY'}
                      />
                    </FormItem>
                  </Col>
                  <Col>
                    <FormItem
                      noStyle
                      name="time"
                      initialValue={dataView?.createdDate ? moment(dataView.createdDate) : moment()}>
                      <TimePicker
                        bordered={false}
                        allowClear={false}
                        suffixIcon={null}
                        placeholder=""
                        format={'HH:mm'}
                        style={{ width: 45, paddingRight: 0, paddingLeft: 0 }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
              {
                dataView && dataView?.paymentMethod ? (
                  <FormItem
                    className={"cash-flow__type-receipt-payment"}
                    name={"paymentNoteTypeId"}
                    label={<label style={{width: '7rem', fontWeight: 600}}>
                      {isCheckReceipt ? 'Loại thu' : 'Loại chi'}</label>}
                    initialValue={dataView?.paymentMethod}>
                    <Input/>
                  </FormItem>
                ) : (
                  <FormItem
                    className={"cash-flow__type-receipt-payment"}
                    name={"paymentNoteTypeId"}
                    label={<label style={{width: '7rem', fontWeight: 600}}>
                      {isCheckReceipt ? 'Loại thu' : 'Loại chi'}</label>}>

                    <Select
                      onChange={(value) => {
                        form.setFieldsValue({ paymentNoteTypeId: value });
                      }}
                      name={"paymentNoteTypeId"}
                      allowClear
                      filterOption={filterAcrossAccents}
                    >
                      {
                        dataPayReceiveType.map((type, i) => <Option key={i} value={type._id}>{type.name}</Option>)
                      }
                    </Select>
                    <span onClick={onShowModalType} className={"cash-flow__show-type-receipt-payment"}>+</span>
                  </FormItem>
                )
              }
              <FormItem
                initialValue={dataView?.paymentAmount}
                name={"paymentAmount"}
                label={<label style={{width: '7rem', fontWeight: 600}}>Giá trị</label>}
                >
                <Input style={{ width: '100%' }}/>
              </FormItem>
              <FormItem
                initialValue={dataView?.note}
                name="note"
                label={<label style={{width: '7rem', fontWeight: 600}}>Ghi chú</label>}>
                <TextArea rows={1} />
              </FormItem>
            </Col>

            <Col md={12}>
              <FormItem
                initialValue={dataView?.payerReceiver?.target}
                name={['payerReceiver', 'target']}
                label={<label style={{width: '7rem', fontWeight: 600}}>{!isCheckReceipt ? 'Đối tượng nhận' : 'Đối tượng nộp'}</label>}
              >
                <Select
                  onChange={(value) => {
                    setNameReceiptPayments('')
                    setOptionReceiptPayments([])
                  }}>
                  <Option value="CUSTOMER">Khách hàng</Option>
                  <Option value="SUPPLIER">Nhà cung cấp</Option>
                  <Option value="EMPLOYEE">Nhân viên</Option>
                  <Option value="PARTNER">Đối tác giao hàng</Option>
                </Select>
              </FormItem>

              <FormItem
                name="personal-receipt-payment"
                className={"cash-flow__type-receipt-payment"}
                label={<label style={{width: '7rem', fontWeight: 600}}>{isCheckReceipt ? 'Tên người nộp' : 'Tên người nhận'}</label>}>
                <Select
                  showSearch
                  ref={inputEl}
                  onSearch={handleOnSearch}
                  options={optionReceiptPayments}
                  value={dataView?.payerReceiver?.name || nameReceiptPayment}
                  autoClearSearchValue={true}
                  name={['payerReceiver', 'name']}
                  style={{
                    width: '100%',
                    maxHeight: 100
                  }}
                  optionFilterProp="children"
                  dropdownRender={() => {
                    if (!optionReceiptPayments?.length) {
                      return (
                        <Spin spinning={isLoading}>
                          <div className="receipt-payment-option">
                            <p className="receipt-payment-option__detail">
                              {isLoading
                                ? 'Đang tìm'
                                : searchText
                                  ? 'Không tìm thấy dữ liệu'
                                  : 'Tìm kiếm'}
                            </p>
                          </div>
                        </Spin>
                      );
                    }

                    return receiptPayments.map((option) => {
                      return (
                        <div
                          className="receipt-payment-option"
                          key={option._id}
                          onClick={() => handleSelect(option)}
                        >
                          <div className="receipt-payment-option__info">
                            <span className="receipt-payment-option__name">{option.name} </span>
                            <span className="receipt-payment-option__code">
                              Mã: {option.code}
                            </span>
                          </div>
                        </div>
                      );
                    });
                  }}
                >
                </Select>
                <span onClick={onShowModalRecipientsSubmitters} className={"cash-flow__show-type-receipt-payment"}>+</span>
              </FormItem>

              <FormItem name="check-result">
                <Checkbox defaultChecked onChange={onChangeCheckBox} style={{marginLeft: '8rem'}}>
                  Hạch toán vào kết quả hoạt động kinh doanh
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
          <Row justify="end">
            <Button disabled={dataView ? 'disabled' : false } htmlType="submit" type="primary" shape="round" icon={<FileTextOutlined size={'medium'}/>} size={'large'}
              style={{display: 'flex', alignItems: 'center', paddingLeft: '30px', paddingRight: '30px',}}>
              Lưu
            </Button>
            <Button onClick={onClose} type="primary" shape="round" icon={<StopOutlined size={'medium'}/>} size={'large'}
              style={{display: 'flex', alignItems: 'center', backgroundColor: '#898c8d', borderColor: '#898c8d'}}>
              Bỏ qua
            </Button>
          </Row>
        </Form>
      </Modal>
      <ReceiptPaymentTypeModal
        isOpen={isReceiptPaymentTypeOpen}
        isReceipt={isCheckReceipt}
        onClose={() => setIsReceiptPaymentTypeOpen(false)}
        updateReceiptPaymentType={updateReceiptPaymentType}
      />
      <RecipientsSubmittersTypeModal
        isOpen={isRecipientsSubmittersOpen}
        isReceipt={isCheckReceipt}
        onClose={() => setIsRecipientsSubmittersOpen(false)}
      />
    </>
  );
};

export default ReceiptPaymentModal;
