import * as Types from '~/constants/actionTypes';

export const getSuppliers = query => ({
  type: Types.GET_SUPPLIERS_REQUEST,
  payload: query
});

export const getSuppliersAuthenticated = query => ({
  type: Types.GET_SUPPLIERS_AUTHENTICATED_REQUEST,
  payload: query
});

export const getSupplier = id => ({
  type: Types.GET_SUPPLIER_REQUEST,
  payload: id
});

export const createSupplier = unit => ({
  type: Types.CREATE_SUPPLIER_REQUEST,
  payload: unit
});

export const updateSupplier = unit => ({
  type: Types.UPDATE_SUPPLIER_REQUEST,
  payload: unit
});

export const deleteSupplier = id => ({
  type: Types.DELETE_SUPPLIER_REQUEST,
  payload: id
});

export const resetSupplierState = () => ({
  type: Types.RESET_SUPPLIER_STATE
});
