import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import { useCustomerPaging, useCustomerPurchases, useCustomerQueryParams } from '~/hooks';
import { formatNumber } from '~/utils/helper';
import moment from 'moment';
import { INVOICE_STATUS } from '~/constants/defaultValue';

const columns = [
  {
    title: 'Mã hóa đơn',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Thời gian',
    dataIndex: 'createAt',
    key: 'createAt',
    render: (_) => moment(_).format('DD-MM-YYYY')
  },
  {
    title: 'Người bán',
    dataIndex: ['createdBy', 'fullName'],
    key: 'fullName'
  },
  {
    title: 'Tổng cộng',
    dataIndex: 'total',
    key: 'total',
    align: 'end',
    render: total => formatNumber(total)
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render : (status) => INVOICE_STATUS[status]
  }
];

const Purchases = ({ customerId }) => {
  const [dataSource, setDataSource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const paging = useCustomerPaging();

  const [query] = useCustomerQueryParams();
  const [purchases] = useCustomerPurchases({
    ...query,
    page: currentPage
  }, customerId);

  useEffect(() => {
    setDataSource(purchases)
  }, [purchases])
  return (
    <div className="medicine-detail-tab-item medicine-remain-tab">
      <Spin spinning={!dataSource}>
        <Table
          className="nested-table"
          style={{ marginTop: 20, marginBottom: 30, marginLeft: -20 }}
          size="small"
          columns={columns}
          dataSource={dataSource}
          rowKey={rc => rc.code}
          bordered
          rowClassName={record => (record.isSummaryRow ? 'summary-row' : '')}
          onChange={({ current }) => {
            setCurrentPage(current)}
          }
          pagination={{
            ...paging,
            pageSize: 11,
            total: Math.ceil(paging.total + paging.total / 10),
            showTotal: () => `Tổng cộng: ${paging.total} `
          }}
        />
      </Spin>
    </div>
  );
};

export default Purchases;
