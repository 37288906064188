import React from 'react';
import moment from 'moment';
import { Form, DatePicker } from 'antd';

const FilterDatePicker = ({ onChange, value, label }) => {
  return (
    <Form.Item
      label={`${label ?? 'Ngày:'}`}
      labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
    >
      <DatePicker
        onChange={(value) =>
          onChange(value ? moment(value).format('YYYY-MM-DD') : null)
        }
        value={value ? moment(value) : undefined}
        placeholder="Chọn ngày"
      />
    </Form.Item>
  );
};

export default FilterDatePicker;
