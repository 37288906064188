import React, { useState, useEffect, useRef } from 'react';
import { Form, AutoComplete, Table } from 'antd';
import { useMedicineOptions } from '~/hooks';

let timeOut = null;
const FormItem = Form.Item;

const MedicineName = ({ form, unitOptions }) => {
  const [search, setSearch] = useState('');
  const [medicineOptions, isLoading] = useMedicineOptions(search);

  const inputEl = useRef(null);

  useEffect(() => {
    return () => {
      timeOut = null;
    };
  }, []);

  const onSearch = value => {
    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      if (timeOut) {
        setSearch(value);
      }
    }, 800);
  };

  const handleSelect = value => {
    inputEl.current.blur();
    form.setFieldsValue({
      name: value.name,
      manufactureId: value.manufacturer,
      productDetail: {
        registrationNo: value.registrationNo,
        ingredient: value.element,
        dosage: value.content,
        medicineCode: value.code,
        packagingSize: value.package
      }
    });

    const nextUnitValue = unitOptions.find(({ name }) => name === value.unit);

    if (nextUnitValue) {
      form.setFieldsValue({
        defaultVariant: {
          unitId: nextUnitValue._id
        }
      });
    }
  };

  return (
    <div>
      <FormItem
        label="Tên thuốc"
        name="name"
        rules={[
          { required: true, message: 'Xin mời nhập tên thuốc' },
          { max: 100, message: 'Tối đa 100 ký tự' }
        ]}
      >
        <AutoComplete
          style={{ width: '100%' }}
          onSearch={onSearch}
          onSelect={handleSelect}
          dropdownMatchSelectWidth={false}
          options={medicineOptions || []}
          ref={inputEl}
          notFoundContent={<div></div>}
          getPopupContainer={() =>
            document.getElementById('medicine-autocomple-container')
          }
          dropdownRender={() => {
            return (
              <Table
                scroll={{ y: 600 }}
                className="autocomple-table"
                size="small"
                loading={isLoading}
                dataSource={medicineOptions}
                pagination={false}
                rowKey={rc => rc._id}
                columns={[
                  {
                    title: 'Tên thuốc',
                    dataIndex: 'name',
                    key: 'name'
                  },
                  {
                    title: 'Số đăng ký',
                    dataIndex: 'registrationNo',
                    key: 'registrationNo'
                  },
                  {
                    title: 'Hoạt chất',
                    dataIndex: 'element',
                    key: 'element'
                  },
                  {
                    title: 'Hàm lượng',
                    dataIndex: 'content',
                    key: 'content'
                  },
                  {
                    title: 'Quy cách đóng gói',
                    dataIndex: 'package',
                    key: 'package'
                  },
                  {
                    title: 'Hãng sản xuất',
                    dataIndex: 'manufacturer',
                    key: 'manufacturer'
                  }
                ]}
                onRow={record => {
                  return {
                    onClick: () => {
                      handleSelect(record);
                    }
                  };
                }}
              />
            );
          }}
        ></AutoComplete>
      </FormItem>
    </div>
  );
};

export default MedicineName;
