import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getStaffGroups() {
  try {
    const data = yield call(Api.staffGroup.getAll);
    yield put({ type: Types.GET_STAFF_GROUPS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_STAFF_GROUPS_FAILED,
      payload: error.message
    });
  }
}

function* getStaffGroup({ payload: id }) {
  try {
    const staffGroup = yield call(Api.staffGroup.getById, id);
    yield put({ type: Types.GET_STAFF_GROUP_SUCCESS, payload: staffGroup });
  } catch (error) {
    yield put({ type: Types.GET_STAFF_GROUP_FAILED, payload: error.message });
  }
}

function* createStaffGroup(action) {
  try {
    const data = yield call(Api.staffGroup.create, action.payload);
    yield put({ type: Types.CREATE_STAFF_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_STAFF_GROUP_FAILED,
      payload: error.message
    });
  }
}

function* updateStaffGroup(action) {
  try {
    const data = yield call(Api.staffGroup.update, action.payload);
    yield put({ type: Types.UPDATE_STAFF_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_STAFF_GROUP_FAILED,
      payload: error.message
    });
  }
}

function* deleteStaffGroup({ payload }) {
  try {
    yield call(Api.staffGroup.delete, payload);
    yield put({ type: Types.DELETE_STAFF_GROUP_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_STAFF_GROUP_FAILED,
      payload: error.message
    });
  }
}

export default function* staffGroup() {
  yield takeLatest(Types.GET_STAFF_GROUPS_REQUEST, getStaffGroups);
  yield takeLatest(Types.GET_STAFF_GROUP_REQUEST, getStaffGroup);
  yield takeLatest(Types.DELETE_STAFF_GROUP_REQUEST, deleteStaffGroup);
  yield takeLatest(Types.CREATE_STAFF_GROUP_REQUEST, createStaffGroup);
  yield takeLatest(Types.UPDATE_STAFF_GROUP_REQUEST, updateStaffGroup);
}
