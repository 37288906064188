export const GET_SAMPLE_PRESCRIPTIONS_REQUEST = 'GET_SAMPLE_PRESCRIPTIONS_REQUEST';
export const GET_SAMPLE_PRESCRIPTIONS_SUCCESS = 'GET_SAMPLE_PRESCRIPTIONS_SUCCESS';
export const GET_SAMPLE_PRESCRIPTIONS_FAILED = 'GET_SAMPLE_PRESCRIPTIONS_FAILED';

export const GET_SAMPLE_PRESCRIPTION_REQUEST = 'GET_SAMPLE_PRESCRIPTION_REQUEST';
export const GET_SAMPLE_PRESCRIPTION_SUCCESS = 'GET_SAMPLE_PRESCRIPTION_SUCCESS';
export const GET_SAMPLE_PRESCRIPTION_FAILED = 'GET_SAMPLE_PRESCRIPTION_FAILED';

export const CREATE_SAMPLE_PRESCRIPTION_REQUEST = 'CREATE_SAMPLE_PRESCRIPTION_REQUEST';
export const CREATE_SAMPLE_PRESCRIPTION_SUCCESS = 'CREATE_SAMPLE_PRESCRIPTION_SUCCESS';
export const CREATE_SAMPLE_PRESCRIPTION_FAILED = 'CREATE_SAMPLE_PRESCRIPTION_FAILED';

export const UPDATE_SAMPLE_PRESCRIPTION_REQUEST = 'UPDATE_SAMPLE_PRESCRIPTION_REQUEST';
export const UPDATE_SAMPLE_PRESCRIPTION_SUCCESS = 'UPDATE_SAMPLE_PRESCRIPTION_SUCCESS';
export const UPDATE_SAMPLE_PRESCRIPTION_FAILED = 'UPDATE_SAMPLE_PRESCRIPTION_FAILED';

export const DELETE_SAMPLE_PRESCRIPTION_REQUEST = 'DELETE_SAMPLE_PRESCRIPTION_REQUEST';
export const DELETE_SAMPLE_PRESCRIPTION_SUCCESS = 'DELETE_SAMPLE_PRESCRIPTION_SUCCESS';
export const DELETE_SAMPLE_PRESCRIPTION_FAILED = 'DELETE_SAMPLE_PRESCRIPTION_FAILED';

export const SEARCH_SAMPLE_PRESCRIPTION_REQUEST = 'SEARCH_SAMPLE_PRESCRIPTION_REQUEST';
export const SEARCH_SAMPLE_PRESCRIPTION_SUCCESS = 'SEARCH_SAMPLE_PRESCRIPTION_SUCCESS';
export const SEARCH_SAMPLE_PRESCRIPTION_FAILED = 'SEARCH_SAMPLE_PRESCRIPTION_FAILED';

export const RESET_SAMPLE_PRESCRIPTION_STATE = 'RESET_SAMPLE_PRESCRIPTION_STATE';

export const UPDATE_SAMPLE_PRESCRIPTION_NOTE_REQUEST = 'UPDATE_SAMPLE_PRESCRIPTION_NOTE_REQUEST';
export const UPDATE_SAMPLE_PRESCRIPTION_NOTE_SUCCESS = 'UPDATE_SAMPLE_PRESCRIPTION_NOTE_SUCCESS';
export const UPDATE_SAMPLE_PRESCRIPTION_NOTE_FAILED = 'UPDATE_SAMPLE_PRESCRIPTION_NOTE_FAILED';
