import React, { useState, useMemo, useEffect } from 'react';
import { Link, useHistory, } from 'react-router-dom';
import {
  useStockTakeQueryParams,
  useUpdateStockTakeParams,
  useDeleteStockTake,
  useSaveStockTakeLump,
  useUpdateStockTake,
  useStockTakes, useStockTakeColumnSetting, useStockTakePaging, SEARCH_STOCKTAKE
} from '~/hooks';
import { Table, Input, Button, Dropdown, Tabs, Select, DatePicker } from 'antd';
import './index.scss';
import WithPermission from '../../Common/WithPermission';
import POLICY from '~/constants/policy';
import { PATH_CLINIC } from '~/routes/paths';
import { DownloadOutlined, DownOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { API_LIST, CARD_STYLE, INVOICE_STATUS } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { exportCSV, formatCurrency, formatDateTime } from '~/utils/helper';
import StockTakeInfo from '~/components/WorldClinic/StockTake/Detail/Information';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import SummaryTable from '~/components/Common/SummaryTable';
import { connect } from 'react-redux';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import { onSelectRow } from '~/utils/validators';

const { TabPane } = Tabs;

const StockTake = ({summary}) => {
  const [query] = useStockTakeQueryParams();
  const [lumpInventoryStock, setLumpInventoryStock] = useState([]);

  const [keyword, { setKeyword, onParamChange }] = useUpdateStockTakeParams(
    query
  );
  const [selectTable, setSelectTable]=useState([])

  //Get danh sách kiểm kho
  const [stockTakes, isLoading] = useStockTakes(query);

  // Update kiểm kho
  const [, handleUpdate] = useUpdateStockTake();

  // Gộp kiểm kho
  const [, handleSave] = useSaveStockTakeLump()

  // Delete phiếu kiểm kho

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = stockTakes.length === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };
  const history = useHistory()

  const [, deleteStockTake] = useDeleteStockTake(onDeleteSuccess);

  const convertSummary = [
    {
      title:"Tổng số lượng thực tế ",
      value:summary?.sumTotalActualCount
    },
    {
      title:"Tổng giá trị thực tế ",
      value:summary?.sumTotalActualPrice
    },
    {
      title:"Tổng chênh lệch ",
      value:summary?.sumTotalAdjustmentValue
    },
    {
      title:"Tổng chênh lệch Tăng ",
      value:summary?.sumTotalIncreaseValue
    },
    {
      title:"Tổng chênh lệch giảm ",
      value:summary?.sumTotalReducedValue
    },
  ]

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);
  const [columnMenu, selectedColumnKeys] = useStockTakeColumnSetting();
  const paging = useStockTakePaging();

  const columns = useMemo(() => {
    const allColumns = [
      {
        title: 'Mã kiểm kho',
        key: 'code',
        dataIndex: 'code',
      },
      {
        title: 'Thời gian',
        key: 'time',
        dataIndex: 'createdAt',
        render: (createdAt, record) => {
          return !record.isSummaryRow && formatDateTime(createdAt)
        }
      },
      {
        title: 'Ngày cân bằng',
        key: 'dayBalance',
        dataIndex: 'updatedAt',
        render: (updatedAt, record) => {
          return !record.isSummaryRow && formatDateTime(updatedAt)
        }
      },
      {
        title: 'SL thực tế',
        key: 'totalActualCount',
        dataIndex: 'totalActualCount',
        align: 'end',
        render: formatCurrency
      },
      {
        title: 'Tổng thực tế',
        key: 'totalActualPrice',
        dataIndex: 'totalActualPrice',
        align: 'end',
        render: formatCurrency
      },      {
        title: 'Tổng chênh lệch',
        key: 'totalAdjustmentValue',
        dataIndex: 'totalAdjustmentValue',
        align: 'end',
        render: formatCurrency
      },  {
        title: 'SL lệch tăng',
        key: 'totalIncreaseValue',
        dataIndex: 'totalIncreaseValue',
        align: 'end',
        render: formatCurrency
      },
      {
        title: 'SL lệch giảm',
        key: 'totalReducedValue',
        dataIndex: 'totalReducedValue',
        align: 'end',
        render: formatCurrency
      },
      {
        title: 'Ghi chú',
        key: 'description',
        dataIndex: 'description',
        align: 'end',
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: status => INVOICE_STATUS[status]
      }
    ];

    return allColumns.filter(({key}) => !!selectedColumnKeys[key]);
  },[selectedColumnKeys]);

  const expandedRowRender = record => {
    return (
      <div className="card-container expanded-table">
        <Tabs type="card">
          <TabPane tab="Thông tin" key="1">
            <StockTakeInfo record={record} deleteRow={deleteStockTake} history={history} updateStockTake={handleUpdate}/>
          </TabPane>
        </Tabs>
      </div>
    );
  };

  const handleSelectedRow = (key, row) => {
    setLumpInventoryStock(key)
  }

  const handleLumpStockTake = () => {
    handleSave({
        ids: lumpInventoryStock
      }
    )
  }

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Phiếu kiểm kho</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_STOCKTAKE}/>
          </div>
          {!!lumpInventoryStock.length && (
            <div className="wc-page-header__search wclinic-search">
              Có {lumpInventoryStock.length} phiếu kiểm được chọn
            </div>
          )}

          <div className="wc-page-header__actions">
            {!!lumpInventoryStock.length && (
              <Button
                onClick={handleLumpStockTake}
                type="primary"
                style={{ float: 'right', marginRight: 10 }}
              >
                <PlusOutlined />
                Gộp kho
              </Button>
            )}
            <WithPermission permission={POLICY.WRITE_STOCKTAKE}>
              <Link to={PATH_CLINIC.stockTake.create} state={null}>
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: 10 }}
                >
                  <PlusOutlined />
                  Kiểm kho
                </Button>
              </Link>
              </WithPermission>
              <WithPermission permission={POLICY.READ_STOCKTAKE}>
              <ButtonExportOption 
                router={API_LIST.stockTake} 
                label="Kiểm kho" 
                query={query} 
                fileName={["Danhsachkiemkho_",'Danhsachchitietkiemkho_']} />

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>
          <SummaryTable summaryValue={convertSummary} />
        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={stockTakes.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                size="middle"
                rowClassName={record =>
                  record.isSummaryRow ? 'summary-row' : ''
                }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={stockTakes}
                onChange={({ current,pageSize }) => onParamChange({ page: current,limit: pageSize })}
                pagination={
                  !query.id && {
                    ...paging,
                    // pageSize: 11,
                    showSizeChanger:true,
                    // totalBoundaryShowSizeChanger:paging?.total??0,
                    // total: Math.ceil(paging.total + paging.total / 10),
                    showTotal: () => `Tổng cộng: ${paging.total} `
                  }
                }
                // rowSelection={{
                //   onChange: handleSelectedRow,
                //   renderCell: (checked, record, index, originNode) =>
                //     record.isSummaryRow ? <></> : originNode,
                //   getCheckboxProps: record => ({
                //     disabled: record.isSummaryRow,
                //     name: record.name
                //   })
                // }}
                onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}

                onRow={onSelectRow(setSelectTable)}

                expandable={{
                  expandedRowRender,
                  rowExpandable: record => !record.isSummaryRow,
                  defaultExpandAllRows: !!query.id,
                  expandedRowKeys:[selectTable]
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateTo =(state)=>({
  summary : state.stockTake.summary
})
export default connect(mapStateTo)(StockTake) ;
