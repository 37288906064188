import React, { useState, useMemo, useEffect } from 'react';
import {
  Row,
  Button,
  InputNumber,
  Select,
  Col,
  DatePicker,
  TimePicker,
  Form,
  Checkbox,
  Radio,
  // Input,
} from 'antd';
import {
  DISCOUNT_TYPES,
  PAYMENT_METHODS,
  SALE_CASES,
  SALE_TYPES
} from '~/constants/defaultValue';
import moment from 'moment';
import {
  formatNumber,
  getMoneySuggestion,
  getUtcDateTime
} from '~/utils/helper';
import {
  caculateDiscountValueOnTypeChange
} from '~/utils/caculator';
import currencyFormatter from '~/utils/currencyFormatter';
import SelectCustomer from './SelectCustomer';
import SelectReceiver from './SelectReceiver';
import PrescriptionForm from '~/components/WorldClinic/Prescription/Form';
import toastr from 'toastr';
import {
  useCreateSale,
  useUpdateSale,
  useRemoveSubmitedSale,
  useSaleChange,
  useExchangeProduct,
  useProfile,
  useUpdateSaleCheckInOut,
  useResetSale,
} from '~/hooks';

import PaymentHistoryModal from '~/components/Common/PaymentHistoryModal';
import { getSaleCase, getSaleIdProperty } from '~/utils/saleHelper';
import WithRules from '~/components/Common/WithRules';
import SummaryReturn from './SummaryReturn';
import SummarySale from './SummarySale';
import { get } from 'lodash';
import { useInfoPaymentContext } from '../InfoPayment';
import InfoDelivery from './InfoDelivery';
import ImagesModal from './ImagesModal';
import { useDispatch } from 'react-redux';
import { resetSaleState } from '~/redux/action';

const { CASH, CARD, TRANSFER } = PAYMENT_METHODS;
const { PERCENT, VALUE } = DISCOUNT_TYPES;
const { RETURN } = SALE_TYPES;

const FormItem = Form.Item;

const SaleSummary = props => {
  const { selectedSale, onSaleChange, onCreateInvoiceFromSaleOrder } = props;
  const saleCase = getSaleCase(selectedSale);
  const isUpdating =
    saleCase === SALE_CASES.UPDATE_INVOICE ||
    saleCase === SALE_CASES.UPDATE_ORDER;
  const isSaleOrder = selectedSale?.type === SALE_TYPES.ORDER;
  const isInvoice = selectedSale?.type === SALE_TYPES.DIRECT;
  const isReturn = selectedSale?.type === RETURN

  const isUpdatingSaleOrder = isSaleOrder && isUpdating;

  const isCreateNewSaleOrder = isSaleOrder && !isUpdating;
  const isUpdatingInvoice = isInvoice && isUpdating;

  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);
  const [form] = Form.useForm();

  const [isPrescription, setIsPrescription] = useState(false);
  const [profile] = useProfile();
  const [customer, setCustomer] = useState(null);

  const [isPrescriptionFormOpen, setPrescriptionFormOpen] = useState(false);
  const [prescription, setPrescription] = useState(null);
  const [, removeSumitedSale] = useRemoveSubmitedSale();
  const {setVisibleModalInfoPayment}=useInfoPaymentContext()
  useResetSale();
  const onResetForm = () => {
    // setTimeout(() => {
    //   window.print();
    //   removeSumitedSale();
    //   setIsPrescription(selectedSale.isPrescription);
    //   setCustomer(null);
    // }, 500);
  };

  // const [, handleReturnProduct] = useReturnProduct(onResetForm);
  const [, handleExchangeProduct] = useExchangeProduct(onResetForm);

  useEffect(() => {
    if (!selectedSale) {
      return;
    }
    if (selectedSale?.customer) {
      setCustomer(selectedSale.customer);
      form.setFieldsValue({customer:selectedSale?.customer?.name})
    }

    const { date, time } = selectedSale;

    if (date && time) {
      form.setFieldsValue({
        date: moment(date),
        time: moment(time)
      });
    }

    setIsPrescription(!!selectedSale.prescription);
  }, [selectedSale, form]);
  
  const [isSubmitLoading, createSale] = useCreateSale(onResetForm);
  const [, updateSale] = useUpdateSale(onResetForm);

  const [
    totalAmount, discountValue, totalItems, onDiscountTypeChange, onDiscountValueChange, onPaymentChange
  ] = useSaleChange(selectedSale, onSaleChange);

  const [
    totalAmountReturn, discountValueReturn, totalItemsReturn ] = useSaleChange(selectedSale, onSaleChange, true);

  const singlePayment = selectedSale?.summary?.payment[0];

  ////calculator///
  const isDisableSubmit = !selectedSale?.medicines?.length || isSubmitLoading;

  const summaryBill = useMemo (()=>{
    const totalSale = totalAmount - discountValue
    const paid = get(selectedSale,'paid',0)
    const needToPay = totalSale - paid
    // onPaymentChange('value', needToPay)
      return {totalSale, paid,needToPay}
  }, [totalAmount, discountValue, selectedSale])
  

  useEffect(()=>{
        onPaymentChange('value', summaryBill.needToPay)
  },[summaryBill.needToPay])

  const moneySuggestion = useMemo(() => getMoneySuggestion(summaryBill.needToPay), [summaryBill]);
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const dispatch = useDispatch();
  const openModalImage = () => {
    setIsOpenModalImage(true)
  };
  const closeModalImage = () => {
    setIsOpenModalImage(false)
  };  

  const [isLoadingSubmit, onUpdateCheckInOut] = useUpdateSaleCheckInOut(() => {
    closeModalImage();
    dispatch(resetSaleState());
  });

  const onFinish = values => {
    if (!customer && selectedSale?.typeSale !== 'PM') {
      toastr.error('Vui lòng nhập khách hàng!');
      return;
    }

    if(selectedSale.medicines.some( props=> props.quantity===0)){
      toastr.error('Vui lòng nhập số lượng cho tất cả sản phẩm!');
      return;
    }

    const variants = selectedSale?.medicines.map(variant => {
      const { type, value, percent } = variant.discount;

      const converDiscount = type => {
        return caculateDiscountValueOnTypeChange({
          value,
          price: variant?.selectedVariant?.price || variant.price,
          type
        });
      };

      return {
        variantId: variant.variantId,
        productId: variant.productId,
        batchId: variant.selectedBatches[0]._id,
        quantity: variant.quantity,
        cost: get(variant,['selectedVariant','price']) || variant.price,  // Use the selling price as the new cost
        price:  get(variant,['selectedVariant','price'],0) ,//getPriceAfterDiscount(variant.selectedVariant?.price || variant.price, variant.discount), // Use the new price payment as the new price
        // discountValue: type === VALUE ? value : converDiscount(VALUE),
        // discountPercent: type === PERCENT ? value : converDiscount(PERCENT),
        discountValue: value,
        discountPercent: percent,
        discountType: type
      };
    });

    const { discount: summaryDiscount } = selectedSale.summary;
    const converSummaryDiscount = type => {
      return caculateDiscountValueOnTypeChange({
        value: summaryDiscount.value,
        price: totalAmount,
        type
      });
    };

    const {
      summary: { payment }
    } = selectedSale;

    const totalPayment = summaryBill.totalSale;

    if(selectedSale.type === RETURN) {
      toastr.clear()
      let isTotalItems = totalItems > 0,
          isTotalItemReturn = totalItemsReturn!==0,
          isUneven = totalAmount >= totalAmountReturn

      if(isTotalItems){
        if(isTotalItemReturn){
          if(isUneven){
            handleExchangeProduct({
              refund: {
                saleChannel: "DIRECT",
                customerId: customer?._id,
                receivedById: props?.user?._id,
                invoiceId: selectedSale.invoiceId,
                productReturnDetail: selectedSale.medicines
                 .filter(medicine => medicine.typeExchangeReturn &&(medicine.quantity!==0) )
                 .map(medicine => ({
                  ...medicine,
                  //  variantId: medicine.variantId,
                  //  productId: medicine.productId,
                   batchId: medicine.selectedBatches[0]._id,
                   batch: medicine.selectedBatches[0],
                  //  quantity: medicine.quantity,
                  //  cost: medicine.cost,
                  //  price: medicine.price
                })),
                fee: 0,
                discountValue:
                  summaryDiscount.type === VALUE
                    ? summaryDiscount.value
                    : converSummaryDiscount(VALUE),
                discountPercent:
                  summaryDiscount.type === PERCENT
                    ? summaryDiscount.value
                    : converSummaryDiscount(PERCENT),
                discountType: summaryDiscount.type,
                note: '',
                totalReturn: totalAmount
              },
              invoice: {
                saleChannel: "DIRECT",
                customerId: selectedSale.customer._id,
                receivedById: customer?.id,
                invoiceDetail: selectedSale.medicines
                  .filter(medicine => !medicine.typeExchangeReturn && (medicine.quantity!==0))
                  .map(medicine => ({
                    ...medicine,
                    batch : medicine.selectedBatches[0],
                    // variantId: medicine.variantId,
                    // productId: medicine.productId,
                    variant: medicine?.selectedVariant,
                    batchId: medicine.selectedBatches[0]._id,
                    cost : medicine?.variant?.price??medicine.price,
                    price : medicine?.variant?.price??medicine.price,
                    discountValue: medicine?.discountValue,
                    discountPercent: medicine?.discountPercent,
                    discountType: medicine?.discountType
                  })),
                discountValue:
                  summaryDiscount.type === VALUE
                    ? summaryDiscount.value
                    : converSummaryDiscount(VALUE),
                discountPercent:
                  summaryDiscount.type === PERCENT
                    ? summaryDiscount.value
                    : converSummaryDiscount(PERCENT),
                discountType: summaryDiscount.type,
                total: totalAmount,
                totalPayment: totalAmount,
                purchasedAt: getUtcDateTime(values.date, values.time),
              },
              payment: {
                method: payment[0].method,
                amount: totalAmount,
                totalPayment: totalAmount,
              },
            });
            return
          }
          toastr.error('Đơn hàng đổi chưa đủ điều kiện đổi!');
          return
        }
        toastr.error('Không có đơn hàng trả!');
        return
      }
      toastr.error('Vui lòng chọn sản phẩm đổi!');
      return;
    }

    const model = {
      uuid: selectedSale.uuid,
      type: selectedSale.type,
      ...(selectedSale?.typeSale !== 'PM' && {customerId: customer._id}),
      items: variants,
      payment: {
        method: payment[0].method,
        totalPayment,
        amount: totalPayment 
      },
      discountValue:
        summaryDiscount.type === VALUE
          ? summaryDiscount.value
          : converSummaryDiscount(VALUE),
      discountPercent:
        summaryDiscount.type === PERCENT
          ? summaryDiscount.value
          : converSummaryDiscount(PERCENT),
      discountType: summaryDiscount.type,
      involvedBy: 'Trần Văn Thông',
      purchasedAt: getUtcDateTime(values.date, values.time),
      case: selectedSale.case,
      ...(selectedSale?.typeSale === 'PM' && {typeSale: selectedSale.typeSale}),
    };

    const variantHasNoBatch = selectedSale.medicines.find(
      ({ selectedBatches }) => !selectedBatches.length
    );

    if (variantHasNoBatch) {
      toastr.error('Vui lòng nhập số lô cho tất cả sản phẩm!');
      return;
    }

    if (selectedSale.saleOrderId) {
      const idProperty = getSaleIdProperty(selectedSale);
      model[idProperty] = selectedSale.saleOrderId;
      model._id = selectedSale.saleOrderId;

      // FOR COMPLETED PURCHASE ORDER
      if (selectedSale.case === SALE_CASES.INVOICE_FOR_ORDER) {
        model.saleOrderId = selectedSale.saleOrderId;
      }
    }

    if (isPrescription) {
      const isPrescriptionFormValid = Object.values(prescription).find(
        val => !!val
      );

      if (!isPrescriptionFormValid) {
        toastr.error('Vui lòng nhập thông tin đơn thuốc!');
        return;
      }

      model.prescription = prescription;
      model.isPrescriptionFilled = true;
    }

    if (isUpdating) {
      updateSale(model);
    } else {
      createSale(model);
    }
  };

  const onPrescriptionChange = checked => {
    setIsPrescription(checked);
    if (checked) {
      setPrescriptionFormOpen(true);
    }
  };
  const onFinishFailed =(e)=>{
    // const namePath = e.errorFields[0].name;
    // form.getFieldInstance(namePath)?.focus();
    let arr = get(e,'errorFields',[])
       arr.forEach((value)=>{
          let _ = value.errors[0]
          toastr.error(_)
       })
  }

  const handleSelectInfoPayment =()=>{
    setVisibleModalInfoPayment(true)
  }

  const onCheckBillFromWorldPharmacy = (value, key) => {
    return selectedSale?.typeSale === 'PM' ? (key ? selectedSale[key] : 0) : value
  };
  return (
    <div style={{ height: '100%' }}>
      <Form
        onFinish={onFinish}
        scrollToFirstError
        onFinishFailed={onFinishFailed}
        form={form}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Row justify="space-between">
          <Col span={9} style={{ borderBottom: '1px solid #dedede' }}>
            <Select
              className="warehouse-form__user"
              bordered={false}
              value={profile?.fullName}
            ></Select>
          </Col>
          <Col span={14}>
            <Row justify="end">
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="date" initialValue={moment()}>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    style={{
                      paddingRight: 0
                    }}
                    format={'DD-MM-YYYY'}
                  />
                </FormItem>
              </Col>
              <Col style={{ borderBottom: '1px solid #dedede' }}>
                <FormItem noStyle name="time" initialValue={moment()}>
                  <TimePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    placeholder=""
                    format={'HH:mm'}
                    style={{ width: 45, paddingRight: 0, paddingLeft: 0 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>

        { selectedSale?.typeSale !== 'PM' && <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
            <FormItem
                name={'customer'}
                rules={[{
                  required: true,
                  message: 'Vui lòng nhập khách hàng!',
                }]}
            >
              <Row justify="end" align="bottom">
                { selectedSale?.type !== RETURN ? (
                  <SelectCustomer
                    customer={customer}
                    setCustomer={setCustomer}
                    form={form}
                    canUpdate={!selectedSale?.canNotUpdateCustomer}
                  />
                ) :
                  <SelectReceiver
                    customer={customer}
                    form={form}
                    setCustomer={setCustomer}
                    canUpdate={!selectedSale?.canNotUpdateCustomer}
                />}

              </Row>
            </FormItem>
          </Col>}
        </Row>
        <WithRules rules={[isInvoice]}>
          <Row className="warehouse-form__row">
              <Checkbox
                checked={isPrescription}
                onChange={e => onPrescriptionChange(e.target.checked)}
              >
                Bán thuốc theo đơn{' '}
                {isPrescription && (
                  <span
                    onClick={e => {
                      e.preventDefault();
                      setPrescriptionFormOpen(true);
                    }}
                    style={{ color: 'rgb(45, 183, 245)', cursor: 'pointer' }}
                  >
                    (cập nhật)
                  </span>
                )}
              </Checkbox>
            </Row>
        </WithRules>

        <WithRules rules={[isReturn]}>
            <SummaryReturn
              totalItemsReturn={totalItemsReturn}
              totalAmountReturn={totalAmountReturn}
              selectedSale={selectedSale}
              setIsPaymentHistoryOpen={setIsPaymentHistoryOpen}
            />
        </WithRules>

        {selectedSale?.typeSale === 'PM' ? 
          <InfoDelivery
          data = {selectedSale?.deliveryInfo}
          />
          : <WithRules>
            <SummarySale
              totalItems={totalItems}
              totalAmount={totalAmount}
              summaryBill={summaryBill}
              discountValue={discountValue}
              onDiscountValueChange={onDiscountValueChange}
              onDiscountTypeChange={onDiscountTypeChange}
              selectedSale={selectedSale}
              isReturn={isReturn}
              rules={{
                isReturn,
                isInvoice,
                isUpdatingSaleOrder,
                isUpdatingInvoice,
                isCreateNewSaleOrder
              }}
            />
        </WithRules> }
        <WithRules >
            <Row className="warehouse-form__row">
              <label className="fw-600">Khách cần trả: </label>{' '}
              <label style={{ color: 'rgb(45, 183, 245)' }}>
                {formatNumber(onCheckBillFromWorldPharmacy(summaryBill.needToPay, 'totalPriceFromPM'))}
              </label>
            </Row>
        </WithRules>
      {selectedSale?.typeSale === 'PM' &&  <WithRules >
            <Row className="warehouse-form__row">
            <Button
              style={{padding: 0}}
              type='link'
              onClick={openModalImage}
            >Hình ảnh </Button>{' '}
            </Row>
        </WithRules>}

        {selectedSale?.typeSale !== 'PM' &&  <div>
            <div className="sale-payment-method">
              <Row className="warehouse-form__row" align="middle">
                <label className="fw-600">Khách thanh toán: </label>{' '}
                {isUpdatingInvoice ? (
                  <p>{formatNumber(singlePayment.value)}</p>
                ) : (
                  <InputNumber
                    onChange={val => onPaymentChange('value', val)}
                    style={{ width: 100, padding: 0 }}
                    bordered={false}
                    className="warehouse-form__custom-input sale-payment-value"
                    value={singlePayment.value}
                    {...currencyFormatter}
                  />
                )}
              </Row>
              <Row className="warehouse-form__row">
                <label className="fw-600">Tiền thừa trả khách: </label>{' '}
                <label>
                  {formatNumber( singlePayment.value - summaryBill.needToPay)}
                </label>
              </Row>

                <div>
                  <Row className="warehouse-form__row">
                    <Radio.Group
                      onChange={e => onPaymentChange('method', e.target.value)}
                      value={singlePayment.method}
                    >
                      <Radio value={CASH}>Tiền mặt</Radio>
                      <Radio value={CARD}>Thẻ</Radio>
                      <Radio onClick={()=>handleSelectInfoPayment()} value={TRANSFER}>Chuyển khoản</Radio>
                    </Radio.Group>
                  </Row>

                  <Row className="warehouse-form__row sale-payment-method__suggest-money">
                    {moneySuggestion.map(num => (
                      <Button
                        key={num}
                        onClick={() => onPaymentChange('value', num)}
                        className={`sale-payment-method__suggest-money-item ${
                          num === singlePayment.value &&
                          'sale-payment-method__suggest-money-item--selected'
                        }`}
                      >
                        {formatNumber(num)}
                      </Button>
                    ))}
                  </Row>
                </div>
            </div>
          </div>}

        <Row className="warehouse-form__submit sale__submit cancelPositon">
          {isUpdatingSaleOrder && selectedSale?.status !== 'COMPLETED' && (
            <>
              <Button
                disabled={isDisableSubmit}
                size="large"
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={onCreateInvoiceFromSaleOrder}
              >
              Tạo hoá đơn
              </Button>

              <Button
                disabled={isDisableSubmit}
                htmlType="submit"
                size="large"
                type="primary"
                style={{ marginLeft: 10, width: 100 }}
              >
                Lưu
              </Button>
            </>
          )}
          
          {!isUpdatingSaleOrder && 
            <Button
              disabled={isDisableSubmit}
              htmlType="submit"
              size="large"
              type="primary"
              style={{ marginLeft: 10 }}
            >
              {isCreateNewSaleOrder ? 'Đặt hàng' : (`${selectedSale?.type === RETURN ? `${totalItemsReturn > 0 ? 'Đổi hàng' : 'Trả Hàng'}` : (selectedSale?.typeSale === 'PM' ? 'Xuất hàng' : 'Thanh toán')}`)}
            </Button>
          }
        </Row>
      </Form>

      <PrescriptionForm
        isOpen={isPrescriptionFormOpen}
        onClose={() => setPrescriptionFormOpen(false)}
        onSubmit={setPrescription}
        initPrescription={selectedSale?.prescription}
      />

      <PaymentHistoryModal
        isOpen={isPaymentHistoryOpen}
        record={{ paymentNotes: selectedSale?.paymentNotes }}
        onClose={() => setIsPaymentHistoryOpen(false)}
      />
      <ImagesModal
        isOpen={isOpenModalImage}
        onClose={() => setIsOpenModalImage(false)}
        selectedSale={selectedSale}
        isLoadingSubmit={isLoadingSubmit}
        onSubmit={onUpdateCheckInOut}
        _id = {selectedSale?.saleOrderId}
      />
    </div>
  );
};

export default SaleSummary;
