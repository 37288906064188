import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  notificationServiceInfo: [],
  isGetNotificationServiceLoading: false,
  getNotificationServiceFailed: null,
  hasNextPage : true,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_NOTIFICATION_SERVICE_REQUEST:
      state.isGetNotificationServiceLoading = true;
      state.getNotificationServiceFailed = null;
      return;

    case Types.GET_NOTIFICATION_SERVICE_SUCCESS:
      state.isGetNotificationServiceLoading = false;
      state.notificationServiceInfo = [...state.notificationServiceInfo, ...payload.data?.docs];
      state.hasNextPage= payload.data.hasNextPage
      return;

    case Types.GET_NOTIFICATION_SERVICE_FAILED:
      state.isGetNotificationServiceLoading = false;
      state.getNotificationServiceFailed = payload;
      return;
    case Types.RESET_NOTIFICATION_SERVICE_STATE:
        return initState;
    default:
      return;
  }
}, initState)
