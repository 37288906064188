import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import NumberToWord from '~/utils/readMoney';
import { Image } from 'antd';
import { formatDate, formatNumber, getAddress } from '~/utils/helper';
import { useFormPrintQueryParams, useFormPrints } from '~/hooks';
import { get } from 'lodash';
const readMoney = new NumberToWord();


function TemplateInvoice({data,refComponent,handlePrintInvoice}) {
    const [dataSource, setDataSource] = useState({});

    const [query] = useFormPrintQueryParams();
    const [formPrints] = useFormPrints(query);

    const mergedFormPrint = useMemo(() => {
      if (!formPrints || !formPrints.length) {
        return null;
      }
      if (Array.isArray(formPrints)) {
        return formPrints[0];
      }
    }, [formPrints]);

    useEffect(()=>{
      if(data){
        setDataSource(data);
      }
    },[data])

    return (
        <div id="invoice-template" ref={refComponent}>
        <Row style={{ width: '20rem' }}>
          <Col lg={24}>
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Col sm="6">
                    <div className="text-muted">
                      <div>{'Ngày :'}</div>
                      {/* <div>{isInvoice ? 'Ngày :' : 'Ngày đặt hàng :'}</div> */}
                      <div
                        className="text-end"
                        style={{ fontSize: '13px', fontWeight: 'bold' }}
                      >
                        {formatDate(get(dataSource,'purchasedAt',0))}
                    
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="text-muted text-sm-end">
                      <div>
                        Hóa đơn
                        {/* {isInvoice
                          ? 'Hoá đơn :'
                          : saleType === 'RETURN'
                          ? 'Trả hàng :'
                          : 'Đặt hàng :'} */}
                      </div>
                      <div
                        className="text-end"
                        style={{ fontSize: '13px', fontWeight: 'bold' }}
                      >
                        #{dataSource?.code}
                      </div>
                    </div>
                  </Col>
                </div>
                <div className="invoice-title">
                  <div className="mb-4"></div>
                  <div className="text-muted">
                    <h5 className="mb-1" style={{ textAlign: 'center' }}>
                      {get(dataSource,['branch','name','vi'],'')}
                    </h5>
                    <p className="mb-1" style={{ textAlign: 'center' }}>
                      {/* Địa chỉ:{' '} */}
                      <span className="ml-1">
                        {getAddress(get(dataSource,['branch','address'], {}))}
                      </span>
                    </p>
                    <p className="mb-1" style={{ textAlign: 'center' }}>
                      {/* Email:  */}
                      <span className="ml-1">
                        {get(dataSource,['branch','email'],'')}
                      </span>
                    </p>
                    <p style={{ textAlign: 'center' }}>
                      {/* SĐT:  */}
                      <span className="ml-1">
                        {get( dataSource,['branch','phoneNumber'],'')}
                        </span>
                    </p>
                  </div>
                </div>
                <hr />
                <div className="py-2 mt-3">
                  <h5 className="mb-1" style={{ textAlign: 'center' }}>
                  Chi tiết hóa đơn
                    {/* Chi tiết {isInvoice ? 'hoá đơn' : 'đơn hàng'}{' '} */}
                  </h5>
                  <hr />

                  <div className="table-responsive mt-1">
                    <Table className="table-centered mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: '100px' }}>SL</th>
                          <th style={{ width: '150px' }}>Đơn giá</th>
                          <th style={{ width: '250px' }}>Thành tiền</th>
                        </tr>
                      </thead>
                      <tbody>
                        {get(dataSource,['invoiceDetail'],[]).map((item, key) => (
                          <tr key={key}>
                            <td colSpan="3">
                              <h5 className="font-size-13 mb-1">
                                {item.product?.name}
                                <span>(</span>
                                <span>
                                  {' '}
                                  {get(item,['productVariant','unit','name'], get(item,['variant','unit','name'], get(item,['variant','productUnit','name'],'')) )}
                                </span>
                                <span>)</span>
                              </h5>
                            </td>
                          </tr>
                        ))}
                        {get(dataSource,['invoiceDetail'],[]).map((item, key) => (
                          <tr key={key}>
                            <td>{get(item,'quantity')}</td>

  
                            <td>{formatNumber(get(item,'price',0))}</td>
                            <td className="text-end">
                              {' '}
                              {formatNumber(get(item,'price',0) * get(item,'quantity',0))}
                            </td>
                          </tr>
                        ))}
                        <tr className="invoice-summary-row">
                          <th colSpan="2" className="text-end">
                            Tổng tiền hàng:
                          </th>
                          <td className="text-end">
                            {formatNumber(get(dataSource,'total',0))}
                          </td>
                        </tr>
                        <tr className="invoice-summary-row  invoice-summary-row--mt-10">
                          <th colSpan="2" className="border-0 text-end">
                            Giảm giá :
                          </th>
                          <td className="border-0 text-end">
                            {formatNumber(get(dataSource,'discountValue',0))}
                          </td>
                        </tr>
  
                        <tr className="invoice-summary-row invoice-summary-row--mt-10">
                          <th colSpan="2" className="border-0 text-end">
                            Tổng thanh toán:
                          </th>
                          <td className="border-0 text-end">
                            {formatNumber(get(dataSource,'totalPayment',0))}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
  
                    {dataSource._id && (
                      <h5 className="invoice-template__money-text">
                        {`${'Bằng chữ:'} ${readMoney.getFullText(
                        get(dataSource,'totalPayment',0)
                      )} đồng chẵn./.`}
                      </h5>
                    )}
                  </div>
                </div>
                
  
                {/* {productsExchange.length > 0 && (
                  <div className="py-2 mt-3">
                    <h5 className="font-size-15">Chi tiết đơn hàng đổi trả</h5>
                    <div className="table-responsive mt-1">
                      <Table className="table-centered mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: '120px' }}>Số lượng</th>
                            <th style={{ width: '120px' }}>Đơn giá</th>
                            <th className="text-end" style={{ width: '120px' }}>
                              Thành tiền
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productsExchange.map((item, key) => (
                            <tr key={key}>
                              <td colspan="3">
                                <h5 className="font-size-15 mb-1">
                                  {item.product?.name}
                                  <span>
                                    {' '}
                                    {item.productVariant?.unit?.name ||
                                      item.variant?.unit?.name ||
                                      item?.unit?.name}
                                  </span>
                                </h5>
                              </td>
                            </tr>
                          ))}
                          {productsExchange.map((item, key) => (
                            <tr key={key}>
                              <td>{item.quantity}</td>
                              <td>{formatNumber(item.price)}</td>
                              <td className="text-end">
                                {' '}
                                {formatNumber(item.price * item.quantity)}
                              </td>
                            </tr>
                          ))}
                          <tr className="invoice-summary-row">
                            <th colSpan="5" className="text-end">
                              Tổng tiền hàng đổi:
                            </th>
                            <td className="text-end">
                              {formatNumber(totalAmountExchange)}
                            </td>
                          </tr>
                          <tr className="invoice-summary-row  invoice-summary-row--mt-10">
                            <th colSpan="5" className="border-0 text-end">
                              Giảm giá :
                            </th>
                            <td className="border-0 text-end">
                              {formatNumber(
                                discountValue ||
                                  dataSource?.summary?.discount?.value
                              )}
                            </td>
                          </tr>
  
                          <tr className="invoice-summary-row invoice-summary-row--mt-10">
                            <th colSpan="5" className="border-0 text-end">
                              Tổng thanh toán hàng đổi:
                            </th>
                            <td className="border-0 text-end">
                              <h5 className="m-0">
                                {formatNumber(totalAmount - discountValue)}
                              </h5>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
  
                      {dataSource._id && (
                        <h5 className="invoice-template__money-text">{`${'Bằng chữ:'} ${readMoney.getFullText(
                          totalAmount - discountValue
                        )} đồng chẵn./.`}</h5>
                      )}
                    </div>
                  </div>
                )} */}
                <div className="invoice-template__money-text mt-3">
                  Xin cám ơn và hẹn gặp lại quý khách!
                </div>
              </CardBody>
              <div className="form-information" style={{ margin: '1rem' }}>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                { get(mergedFormPrint,'image')&&  <Image src={mergedFormPrint?.image} alt='image' width={130} />}
                </div>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                  {(mergedFormPrint?.description??[]).map(index => (
                    <div index={index}>{index}</div>
                  ))}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    )
}

export default TemplateInvoice
