export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAILED = 'GET_PARTNERS_FAILED';

export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_FAILED = 'GET_PARTNER_FAILED';

export const CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_FAILED = 'CREATE_PARTNER_FAILED';

export const UPDATE_PARTNER_REQUEST = 'UPDATE_PARTNER_REQUEST';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED';

export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_FAILED = 'DELETE_PARTNER_FAILED';

export const GET_PARTNER_BRANCHES_REQUEST = 'GET_PARTNER_BRANCHES_REQUEST';
export const GET_PARTNER_BRANCHES_SUCCESS = 'GET_PARTNER_BRANCHES_SUCCESS';
export const GET_PARTNER_BRANCHES_FAILED = 'GET_PARTNER_BRANCHES_FAILED';

export const RESET_PARTNER_STATE = 'RESET_PARTNER_STATE';

export const UPDATE_API_KEY_PARTNER_SAVE_REQUEST = 'UPDATE_API_KEY_PARTNER_SAVE_REQUEST';
export const UPDATE_API_KEY_PARTNER_SAVE_SUCCESS = 'UPDATE_API_KEY_PARTNER_SAVE_SUCCESS';
export const UPDATE_API_KEY_PARTNER_SAVE_FAILED = 'UPDATE_API_KEY_PARTNER_SAVE_FAILED';
