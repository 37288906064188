import { DISCOUNT_TYPES } from '~/constants/defaultValue';

export const getDiscountValue = (price, discount) => {
  if (discount?.type === DISCOUNT_TYPES.VALUE) {
    return discount?.value;
  }

  return discount?.percent ? (discount.percent / 100) * price : 0;
};

export const getPriceAfterDiscount = (price, discount) => {
  return price - getDiscountValue(price, discount);
};

export const caculateDiscountValueOnTypeChange = ({ type, value, price }) => {
  const isFromValueToPercen = type === DISCOUNT_TYPES.PERCENT;
  return isFromValueToPercen ? (value / price) * 100 : (value * price) / 100;
};

export const getTotalQuantity = data => {
  return data.reduce((total, { quantity }) => total + quantity, 0);
};

export const getDiscountValueProd = (price, discount, quantity) => {
  if (discount?.type === DISCOUNT_TYPES.VALUE) {
    return discount?.value / quantity;
  }

  return discount?.percent ? (discount?.percent / 100) * price * (quantity || 1) : 0;
};

export const getPriceAfterDiscountProd = (price, discount, quantity) => {
  return price - getDiscountValueProd(price, discount, quantity);
};
