import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
// import NumberToWord from '~/utils/readMoney';
import moment from 'moment';
// import { formatDate, formatNumber, getAddress } from '~/utils/helper';

// const readMoney = new NumberToWord();

const InvoiceTemplate = () => {


  return (
    <div id="invoice-template">
      <Row style={{ width: 900, marginLeft: 'auto', marginRight: 'auto' }}>
        <Col lg="12">
          <Card>
            <CardBody>
              <div style={{display: 'flex'}}>
                <div style={{display: 'inline-flex'}}>
                  {moment(new Date()).format('HH:mm, DD/MM/YYYY')}
                </div>
                <div style={{display: 'inline-flex', marginLeft: 'auto'}}>Trả Hàng</div>
              </div>
              <div className="invoice-title">
                <div className="mb-4"></div>
                <div className="text-muted">
                  <p className="mb-1" style={{ fontWeight: 700 }}>
                  </p>
                  <p className="mb-1">
                    Địa chỉ:
                    <span className="ml-1">
                      Việt Name
                    </span>
                  </p>
                  <p className="mb-1">
                    Email: <span className="ml-1">Email</span>
                  </p>
                  <p>
                    SĐT: <span className="ml-1">SĐT</span>
                  </p>
                </div>
              </div>
              <hr className="my-4" />
              <h5 style={{textAlign: 'center'}}>HÓA ĐƠN TRẢ HÀNG</h5>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col sm="6">
                  <div className="text-muted">
                    <h5 className="font-size-16 mb-3">Khách hàng:</h5>
                    <h5 className="font-size-15 mb-2">Khách hàng</h5>
                    <p className="mb-1">
                      Địa chỉ:{' '}
                      <span className="ml-1">
                       địa chỉ
                      </span>{' '}
                    </p>
                    <p className="mb-1">
                      Email: <span className="ml-1">email</span>
                    </p>
                    <p>
                      SĐT: <span className="ml-1">
                        123
                      </span>{' '}
                    </p>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="text-muted text-sm-end">
                    <div>
                      <h5 className="font-size-16 mb-1 text-sm-end text-end">
                        Mã hóa đơn
                      </h5>
                      <p className="text-end">#123</p>
                    </div>
                    <div className="mt-3">
                      <h5 className="font-size-16 mb-1 text-sm-end text-end">
                        Ngày trả hàng
                      </h5>
                      <p className="text-end">
                        Ngày trả hàng
                      </p>
                    </div>
                    {/* <div className="mt-3">
                      <h5 className="font-size-16 mb-1 text-sm-end text-end">
                        Mã đặt hàng:
                      </h5>
                      <p className="text-end">#{invoiceDetail.orderId}</p>
                    </div> */}
                  </div>
                </Col>
              </div>

              <div className="py-2 mt-3">
                <h5 className="font-size-15">
                  {/*Chi tiết {isInvoice ? 'hoá đơn' : 'đơn hàng'}{' '}*/}
                </h5>
                <div className="table-responsive mt-1">
                  <Table className="table-nowrap table-centered mb-0">
                    <thead>
                    <tr>
                      <th style={{ width: '70px' }}>STT</th>
                      <th style={{ width: '150px' }}>Sản phẩm</th>
                      <th> Đơn vị</th>
                      <th>Đơn giá</th>
                      <th>Số lượng</th>
                      <th className="text-end" style={{ width: '120px' }}>
                        Thành tiền
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="invoice-summary-row">
                      <th colSpan="5" className="text-end">
                        Tổng tiền hàng:
                      </th>
                      <td className="text-end">
                        {/*{formatNumber(totalAmount)}*/}
                      </td>
                    </tr>
                    <tr className="invoice-summary-row  invoice-summary-row--mt-10">
                      <th colSpan="5" className="border-0 text-end">
                        Giảm giá :
                      </th>
                      <td className="border-0 text-end">
                        {/*{formatNumber(discountValue)}*/}
                      </td>
                    </tr>

                    <tr className="invoice-summary-row invoice-summary-row--mt-10">
                      <th colSpan="5" className="border-0 text-end">
                        Tổng thanh toán:
                      </th>
                      <td className="border-0 text-end">
                        <h5 className="m-0">
                          {/*{formatNumber(totalAmount - discountValue)}*/}
                        </h5>
                      </td>
                    </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="invoice-template__money-text mt-3">
                Xin cám ơn và hẹn gặp lại quý khách!
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default InvoiceTemplate;
