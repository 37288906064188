import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';
import { get } from 'lodash';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: { current: 1, pageSize: 11 ,total: 0,}
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PURCHASE_ORDER_QUOTATIONS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PURCHASE_ORDER_QUOTATIONS_SUCCESS:
      state.isLoading = false;
      // const dataSource = payload.docs.map(purchaseReturn => {
      //   const returnItems = purchaseReturn.purchaseReturnItems.map(
      //     orderItem => {
      //       const quantity = getTotalQuantity(orderItem.batches);
      //       const finalPrice = quantity * orderItem.returnPrice;

      //       return {
      //         ...orderItem,
      //         quantity,
      //         finalPrice
      //       };
      //     }
      //   );

      //   return {
      //     ...purchaseReturn,
      //     purchaseReturnItems: returnItems
      //   };
      // });

      // const summaryRecord = {
      //   isSummaryRow: true,
      //   _id: -1,
      //   ...payload.summary
      // };

      // // state.list = payload.summary
      // //   ? [summaryRecord, ...dataSource]
      // //   : dataSource;
      state.list = get(payload,'docs')
        
      state.paging = getPaging(payload);
      return;

    case Types.GET_PURCHASE_ORDER_QUOTATIONS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];

      return;

    case Types.GET_PURCHASE_ORDER_QUOTATION_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_PURCHASE_ORDER_QUOTATION_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_PURCHASE_ORDER_QUOTATION_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_PURCHASE_ORDER_QUOTATION_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PURCHASE_ORDER_QUOTATION_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PURCHASE_ORDER_QUOTATION_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_PURCHASE_ORDER_QUOTATION_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PURCHASE_ORDER_QUOTATION_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map(item => item?._id !== payload?._id ? item : {...payload});
      return;

    case Types.UPDATE_PURCHASE_ORDER_QUOTATION_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_PURCHASE_ORDER_QUOTATION_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_PURCHASE_ORDER_QUOTATION_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_PURCHASE_ORDER_QUOTATION_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_PURCHASE_ORDER_QUOTATION_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
