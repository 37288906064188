import requester from './requester';

const medicineMethod = {
  getAll: (query) => requester.get('/product-type', query),
  getById: id => requester.get(`/product-type/${id}`),
  create: type => requester.post(`/product-type`, type),
  update: type => requester.put(`/product-type/${type._id}`, type),
  delete: id => requester.delete(`/product-type/${id}`)
};

export default medicineMethod;
