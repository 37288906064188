import { put, call, takeLatest, delay } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicines({ payload: query }) {
  try {
    let data = yield call(Api.medicine.getAllByParams, query);
    yield put({ type: Types.GET_MEDICINES_SUCCESS, payload:{ data,query} });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINES_FAILED,
      payload: error.message
    });
  }
}

function* getMedicine({ payload }) {
  try {
    const medicine = yield call(Api.medicine.getById, payload);
    yield put({
      type: Types.GET_MEDICINE_SUCCESS,
      payload: medicine
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_FAILED,
      payload: error
    });
  }
}

function* createMedicine(action) {
  try {
    const data = yield call(Api.medicine.create, action.payload);
    yield put({ type: Types.CREATE_MEDICINE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICINE_FAILED,
      payload: error
    });
  }
}

function* updateMedicine(action) {
  try {
    const data = yield call(Api.medicine.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICINE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICINE_FAILED,
      payload: error
    });
  }
}

function* deleteMedicine({ payload }) {
  try {
    yield call(Api.medicine.delete, payload);
    yield put({ type: Types.DELETE_MEDICINE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICINE_FAILED,
      payload: error
    });
  }
}

function* getMedicineOptions({ payload: query }) {
  try {
    const data = yield call(Api.medicine.getOptions, query);
    yield put({ type: Types.GET_MEDICINE_OPTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICINE_OPTIONS_FAILED,
      payload: error
    });
  }
}

function* getCountries() {
  try {
    const data = yield call(Api.medicine.getCountries);
    yield put({ type: Types.GET_COUNTRIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_COUNTRIES_FAILED,
      payload: error
    });
  }
}

function* searchProducts({ payload: keyword }) {
  try {
    const data = yield call(Api.medicine.searchProducts, keyword);
    yield put({ type: Types.SEARCH_PRODUCTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SEARCH_PRODUCTS_FAILED,
      payload: error
    });
  }
}

function* searchProduct({ payload: code }) {
  try {
    const data = yield call(Api.medicine.searchProducts, code);
    yield put({ type: Types.SEARCH_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SEARCH_PRODUCT_FAILED,
      payload: error
    });
  }
}

function* searchVariantsByProduct({ payload: productId }) {
  try {
    const data = yield call(Api.medicine.searchVariantsByProduct, productId);
    yield put({
      type: Types.SEARCH_VARIANTS_BY_PRODUCT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.SEARCH_VARIANTS_BY_PRODUCT_FAILED,
      payload: error
    });
  }
}

function* getProductStock({ payload: productId }) {
  try {
    const data = yield call(Api.medicine.getStock, productId);
    yield put({
      type: Types.GET_PRODUCT_STOCK_SUCCESS,
      payload: { productId, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_STOCK_FAILED,
      payload: error
    });
  }
}

function* getProductStockCard({ payload: productId }) {
  try {
    const data = yield call(Api.medicine.getStockCard, productId);
    yield put({
      type: Types.GET_PRODUCT_STOCK_CARD_SUCCESS,
      payload: { productId, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_STOCK_CARD_FAILED,
      payload: error
    });
  }
}

function* getReferenceDocsProductStockCard({ payload }) {
  try {
    const data = yield call(Api.medicine.getReferenceDocsStockCard, payload);
    yield put({
      type: Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_FAILED,
      payload: error
    });
  }
}

function* getProductLot({ payload: query }) {
  try {
    const data = yield call(Api.medicine.getLot, query);
    yield put({
      type: Types.GET_PRODUCT_LOT_SUCCESS,
      payload: { productId:query.id, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_LOT_FAILED,
      payload: error
    });
  }
}

function* getProductStockCardOutside({ payload: params }) {
  try {
    const data = yield call(Api.medicine.getProductCardStockOutside, params);
    
    yield put({
      type: Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_SUCCESS,
      payload: { params, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_FAILED,
      payload: error
    });
  }
}

function* submitDrugRequest({payload: params}) {
  try {
    const data = yield call(Api.medicine.submitDrugRequest, params);
    yield delay(1500);
    yield put({
      type: Types.SUBMIT_DRUG_REQUEST_SUCCESS,
      payload: { params, data }
    });

  } catch (error) {
    yield delay(1000);
    yield put({
      type: Types.SUBMIT_DRUG_REQUEST_FAILED,
      payload: error
    });
  }

  yield put({
    type: Types.GET_PRODUCT_DRUG_REQUEST_REQUEST,
    payload: {id: params.id}
  });
}

function* submitTryDrugRequest({payload: params}) {
  try {
    const data = yield call(Api.medicine.submitTryDrugRequest, params.drugId);
    yield delay(1500);
    yield put({
      type: Types.SUBMIT_TRY_DRUG_REQUEST_SUCCESS,
      payload: { params, data }
    });

  } catch (error) {
    yield delay(1000);
    yield put({
      type: Types.SUBMIT_TRY_DRUG_REQUEST_FAILED,
      payload: error
    });
  }

  yield put({
    type: Types.GET_PRODUCT_DRUG_REQUEST_REQUEST,
    payload: {id:params.productId}
  });
}

function* getProductDrugRequest({payload: params}) {
  try {
    const data = yield call(Api.medicine.getProductDrugRequest, params);
    yield put({
      type: Types.GET_PRODUCT_DRUG_REQUEST_SUCCESS,
      payload: { params, data }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_DRUG_REQUEST_FAILED,
      payload: error
    });
  }
}
export default function* medicine() {
  yield takeLatest(Types.GET_MEDICINES_REQUEST, getMedicines);
  yield takeLatest(Types.GET_MEDICINE_REQUEST, getMedicine);
  yield takeLatest(Types.DELETE_MEDICINE_REQUEST, deleteMedicine);
  yield takeLatest(Types.CREATE_MEDICINE_REQUEST, createMedicine);
  yield takeLatest(Types.UPDATE_MEDICINE_REQUEST, updateMedicine);
  yield takeLatest(Types.GET_MEDICINE_OPTIONS_REQUEST, getMedicineOptions);
  yield takeLatest(Types.GET_COUNTRIES_REQUEST, getCountries);
  yield takeLatest(Types.SEARCH_PRODUCTS_REQUEST, searchProducts);
  yield takeLatest(Types.SEARCH_PRODUCT_REQUEST, searchProduct);
  yield takeLatest(
    Types.SEARCH_VARIANTS_BY_PRODUCT_REQUEST,
    searchVariantsByProduct
  );

  yield takeLatest(Types.GET_PRODUCT_STOCK_REQUEST, getProductStock);
  yield takeLatest(Types.GET_PRODUCT_STOCK_CARD_REQUEST, getProductStockCard);
  yield takeLatest(Types.GET_REFERENCE_DOCS_PRODUCT_STOCK_CARD_REQUEST, getReferenceDocsProductStockCard);
  yield takeLatest(Types.GET_PRODUCT_LOT_REQUEST, getProductLot);
  yield takeLatest(Types.GET_PRODUCT_STOCK_CARD_OUTSIDE_REQUEST, getProductStockCardOutside);
  yield takeLatest(Types.SUBMIT_DRUG_REQUEST_REQUEST, submitDrugRequest);
  yield takeLatest(Types.SUBMIT_TRY_DRUG_REQUEST_REQUEST, submitTryDrugRequest);
  yield takeLatest(Types.GET_PRODUCT_DRUG_REQUEST_REQUEST, getProductDrugRequest);
}
