import React from 'react';
import { PATH_APP } from '~/routes/paths';
import { useStaffGroups, useDeleteStaffGroup } from '~/hooks/worldCare/staffGroup';
import { Table, Popconfirm, Button } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import Breadcrumb from '~/components/Common/Breadcrumb';

const ColumnActions = ({ id, deleteStaffGroup }) => {
  return (
    <div className="custom-table__actions">
      <Link to={`/staff-group/edit/${id}`}>
        <p>Sửa</p>
      </Link>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá nhóm nhân viên này?"
        onConfirm={() => deleteStaffGroup(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const StaffGroups = () => {
  const [staffGroups, isLoading] = useStaffGroups();
  const [, deleteStaffGroup] = useDeleteStaffGroup();

  const columns = [
    {
      title: 'Tên nhóm nhân viên',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions {...record} deleteStaffGroup={deleteStaffGroup} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhóm nhân viên" />

        <div className="page-wraper__header">
          <Link to={PATH_APP.config.createStaffGroup}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div>

        {isLoading ? (
          <SkeletonTable columns={columns} rowCount={staffGroups.length} />
        ) : (
          <Table
            rowKey={(rc) => rc._id || rc.id}
            columns={columns}
            dataSource={staffGroups}
            pagination={{
              total: staffGroups.length,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </div>
    </div>
  );
};

export default StaffGroups;
