import React from 'react';
import {
  Form,
  Input,
  Row,
  Button,
  Modal,
  Col,
  DatePicker,
  InputNumber
} from 'antd';
import moment from 'moment';
import toastr from 'toastr';
import { useResetBatch } from '~/hooks';
const FormItem = Form.Item;

const UpdateBatchForm = ({ isOpen, onClose, batch, onSubmit, dataSource, onUpdate, isSubmitLoading }) => {
  useResetBatch();
  console.log(batch,'batch')
  const [form] = Form.useForm();
  const onFinish = values => {
    console.log(values,'values')
    try {
      onUpdate({
        ...values,
        expirationDate: moment(values.expirationDate)?.format('YYYY-MM-DD'),
        manufacturingDate: moment(values.manufacturingDate)?.format('YYYY-MM-DD'),
        id: batch?._id || batch?.batchId,
        callback: () => {
          onSubmit({ ...batch, ...values });
          onClose();
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={640}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="wc-setting-form">
        <Row style={{ marginBottom: 20 }}>
          <h4 style={{ marginRight: 'auto' }}>{batch.lotNumber}</h4>
        </Row>

        <Form
          onFinish={onFinish}
          form={form}
          initialValues={{
            ...batch,
            ...(batch?.expirationDate && {expirationDate: moment(batch?.expirationDate)}),
            ...( batch?.manufacturingDate && {manufacturingDate: moment(batch?.manufacturingDate)}),
          }}
          style={{ paddingLeft: 0 }}
          autoComplete="off"
          scrollToFirstError
          requiredMark={false}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
        >
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Lô"
                name="lotNumber"
                rules={[{ required: true, message: 'Xin mời nhập tên lô' }]}
              >
                <Input  disabled = {dataSource?.typePurchase !== 'PM'} />
              </FormItem>
            </Col>
          </Row>

      {dataSource?.typePurchase === 'PM' &&  <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Ngày sản xuất"
                name="manufacturingDate"
                rules={[
                  { required: true, message: 'Xin mời nhập ngày sản xuất' }
                ]}
              >
                <DatePicker style={{ width: '100%' }}/>
              </FormItem>
            </Col>
          </Row>}
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Hạn sử dụng"
                name="expirationDate"
                rules={[
                  { required: true, message: 'Xin mời nhập hạn sử dụng' }
                ]}
              >
                <DatePicker style={{ width: '100%' }} disabled = {dataSource?.typePurchase !== 'PM'} />
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Số lượng"
                name="quantity"
                rules={[{ required: true, message: 'Xin mời nhập số lượng' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1}   readOnly = {dataSource?.typePurchase === 'PM'}/>
              </FormItem>
            </Col>
          </Row>

          <Row
            className="wc-setting-form__submit-box"
            style={{ marginTop: 10 }}
          >
            {false ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateBatchForm;
