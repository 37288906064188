export const GET_MEDICINE_CATEGORIES_REQUEST =
  'GET_MEDICINE_CATEGORIES_REQUEST';
export const GET_MEDICINE_CATEGORIES_SUCCESS =
  'GET_MEDICINE_CATEGORIES_SUCCESS';
export const GET_MEDICINE_CATEGORIES_FAILED = 'GET_MEDICINE_CATEGORIES_FAILED';

export const GET_MEDICINE_CATEGORY_REQUEST = 'GET_MEDICINE_CATEGORY_REQUEST';
export const GET_MEDICINE_CATEGORY_SUCCESS = 'GET_MEDICINE_CATEGORY_SUCCESS';
export const GET_MEDICINE_CATEGORY_FAILED = 'GET_MEDICINE_CATEGORY_FAILED';

export const CREATE_MEDICINE_CATEGORY_REQUEST =
  'CREATE_MEDICINE_CATEGORY_REQUEST';
export const CREATE_MEDICINE_CATEGORY_SUCCESS =
  'CREATE_MEDICINE_CATEGORY_SUCCESS';
export const CREATE_MEDICINE_CATEGORY_FAILED =
  'CREATE_MEDICINE_CATEGORY_FAILED';

export const UPDATE_MEDICINE_CATEGORY_REQUEST =
  'UPDATE_MEDICINE_CATEGORY_REQUEST';
export const UPDATE_MEDICINE_CATEGORY_SUCCESS =
  'UPDATE_MEDICINE_CATEGORY_SUCCESS';
export const UPDATE_MEDICINE_CATEGORY_FAILED =
  'UPDATE_MEDICINE_CATEGORY_FAILED';

export const DELETE_MEDICINE_CATEGORY_REQUEST =
  'DELETE_MEDICINE_CATEGORY_REQUEST';
export const DELETE_MEDICINE_CATEGORY_SUCCESS =
  'DELETE_MEDICINE_CATEGORY_SUCCESS';
export const DELETE_MEDICINE_CATEGORY_FAILED =
  'DELETE_MEDICINE_CATEGORY_FAILED';

export const RESET_MEDICINE_CATEGORY_STATE = 'RESET_MEDICINE_CATEGORY_STATE';
