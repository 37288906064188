import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';
import { getTotalQuantity } from '~/utils/caculator';
import { getTotal } from '~/utils/helper';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],
  summary:null,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: { current: 1, pageSize: 11 }
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WAREHOUSES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_WAREHOUSES_SUCCESS:
      state.isLoading = false;
      const dataSource = payload.docs.map(warehouse => {
        const orderItems = warehouse.purchaseOrderItems.map(orderItem => {
          const quantity = getTotalQuantity(orderItem.batches);
          const debt = quantity * (orderItem.price - orderItem.discountValue);

          return {
            ...orderItem,
            quantity,
            debt
          };
        });

        const totalDebt =
          getTotal(orderItems, 'debt') - warehouse.discountValue;

        return {
          ...warehouse,
          purchaseOrderItems: orderItems,
          debt: totalDebt
        };
      });
      const summaryRecord ={debt: getTotal(dataSource,"debt")}
      state.summary = summaryRecord 
      state.list = dataSource
      state.paging = getPaging(payload);
      return;

    case Types.GET_WAREHOUSES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];

      return;

    case Types.GET_WAREHOUSE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_WAREHOUSE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_WAREHOUSE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WAREHOUSE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WAREHOUSE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WAREHOUSE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_WAREHOUSE_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
