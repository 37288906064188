import React, { useState, useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  useProductOrders,
  useDeleteProductOrder,
  useProductOrderQueryParams,
  useUpdateProductOrderParams,
  useResetProductOrder,
  useProductOrderPaging,
  useProductOrderColumnSetting,
  SEARCH_PRODUCT_ORDER,
  useOnAddSale,
  useUpdateStatusProductOrderBillPM,
} from '~/hooks';
import { Table, Input, Button, Dropdown, Tabs } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { API_LIST, CARD_STYLE, PAYMENT_STATE } from '~/constants/defaultValue';
import ProductOrderInfo from './Detail/Information';
import PaymentHistory from '~/components/WorldClinic/ProductInvoice/PaymentHistory';
import {
  PlusOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  DownOutlined
} from '@ant-design/icons';
import { exportCSV, formatCurrency, formatDate } from '~/utils/helper';
import './index.scss';
import { INVOICE_STATUS } from '~/constants/defaultValue';
import { PATH_CLINIC } from '~/routes/paths';
import { get } from 'lodash';
import { connect } from 'react-redux';
import SearchInputMulti from '~/components/Common/SearchInputMulti';
import ButtonExportOption from '~/components/Common/ModuleExport/ButtonExportOption';
import { onSelectRow } from '~/utils/validators';

const { Search } = Input;
const { TabPane } = Tabs;

// const ColumnActions = ({ _id, deleteProductOrder }) => {
//   return (
//     <div className="custom-table__actions">
//       <Link to={`/productOrder/edit/${_id}`}> Sửa</Link>
//       <p>|</p>
//       <Popconfirm
//         title="Bạn muốn xoá đơn hàng này?"
//         onConfirm={() => deleteProductOrder(_id)}
//         okText="Xoá"
//         cancelText="Huỷ"
//       >
//         <p>Xóa</p>
//       </Popconfirm>{' '}
//     </div>
//   );
// };

const ProductOrder = ({summary}) => {
  const history = useHistory();
  const [query] = useProductOrderQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateProductOrderParams(
    query
  );

  const [productOrders, isLoading] = useProductOrders(query);
  const [selectTable, setSelectTable]=useState([])
  const {onAddSale} = useOnAddSale()

  const paging = useProductOrderPaging();
  const onDeleteSuccess = () => {
    if (query.id) {
      history.push({
        pathname: PATH_CLINIC.productOrder.root
      });
    } else {
      const isDeletingLastItemInPage = productOrders.length === 1;
      const canDecreasePage = query.page > 0;

      if (isDeletingLastItemInPage && canDecreasePage) {
        onParamChange({ page: query.page - 1 });
      }
    }
  };

  const [, deleteProductOrder] = useDeleteProductOrder(onDeleteSuccess);

  useResetProductOrder();

  const [columnMenu, selectedColumnKeys] = useProductOrderColumnSetting();
  const [isLoadingSubmit, updateStatus] = useUpdateStatusProductOrderBillPM()

  const columns = useMemo(() => {
    const allClolumns = [
      {
        title: 'Mã đặt hàng',
        key: 'code',
        dataIndex: 'code'
      },
      {
        title: 'Mã đặt hàng từ World Pharma',
        key: 'billCode',
        dataIndex: 'billCode'
      },
      {
        title: 'Thời gian',
        key: 'time',
        render: rc => !rc.isSummaryRow && formatDate(rc.purchasedAt)
      },
      {
        title: 'Khách hàng',
        key: 'customer',
        dataIndex: 'customer',
        render: (customer, record) => (
          get(record, 'caseCompany' ) ?? (`${record?.customer?.name || '' } ${record?.customer?.phoneNumber ? `- ${record?.customer?.phoneNumber }`: ""}`)
        )
      },
      {
        title: 'Giảm giá',
        key: 'discount',
        align: 'end',
        render: rc => !rc.isSummaryRow && formatCurrency(rc.discountValue)
      },
      {
        title: 'Khách cần trả',
        key: 'customerNeedToPay',
        align: 'end',
        render: rc => formatCurrency(rc.customerNeedToPay)
      },
      {
        title: 'Khách đã trả',
        key: 'customerPaid',
        align: 'end',
        render: rc => formatCurrency(rc.customerPaid)
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: (value, record) => {
          const status = value === PAYMENT_STATE.DRAFT ? value.concat(record?.typeSale === 'PM' ? '_PM' : '') : value
          return INVOICE_STATUS[status]
        }
      }

      // {
      //   title: 'Thao tác',
      //   key: 'action',
      //   width: '110px',
      //   render: record => {
      //     return (
      //       !record.isSummaryRow && (
      //         <ColumnActions
      //           {...record}
      //           deleteProductOrder={deleteProductOrder}
      //         />
      //       )
      //     );
      //   }
      // }
    ];

    return allClolumns.filter(({ key }) => !!selectedColumnKeys[key]);
  }, [selectedColumnKeys]);

  const [isShowSettingColumn, setShowSettingColumn] = useState(false);

  const exportCsvData = useCallback(() => {
    const columnNames = columns.filter(({ key }) => key !== 'actions');
    exportCSV({
      columns: columnNames,
      data: productOrders,
      fileName: 'Đặt hàng'
    });
  }, [columns, productOrders]);

  const expandedRowRender = useCallback(
    record => {
      const newRecord = {
        ...record,
        customer: record?.typeSale !== 'PM' ? record?.customer : {
          name: record?.customerInfo?.name,
          phoneNumber: record?.customerInfo?.phoneNumber
        }
      }
      return (
        <div className="card-container expanded-table">
          <Tabs type="card">
            <TabPane tab="Thông tin" key="1">
              <ProductOrderInfo
                deleteProductOrder={deleteProductOrder}
                record={newRecord}
                updateStatus={updateStatus}
                isLoadingSubmit = {isLoadingSubmit}
              />
            </TabPane>
            <TabPane tab="Lịch sử thanh toán" key="2">
              <PaymentHistory record={newRecord} />
            </TabPane>
          </Tabs>
        </div>
      );
    },
    [deleteProductOrder]
  );

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1 className="wc-page-header__title">Phiếu đặt hàng</h1>

          <div className="wc-page-header__search wclinic-search">
            <SearchInputMulti onParamChange={onParamChange} keyword={keyword} data={SEARCH_PRODUCT_ORDER}/>
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <WithPermission permission={POLICY.WRITE_SALEORDER}>
              <Link to="/sale">
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: 10 }}
                  onClick={onAddSale}
                >
                  <PlusOutlined />
                  Thêm mới
                </Button>
              </Link>
              <ButtonExportOption 
                router={API_LIST.productOrder} 
                label="Đặt hàng" 
                query={query} 
                fileName={["Danhsachdathang_",'Danhsachchitietdathang_']} />

              <Dropdown
                onVisibleChange={flag => setShowSettingColumn(flag)}
                visible={isShowSettingColumn}
                overlay={columnMenu}
                trigger={['click']}
                style={{ float: 'right' }}
              >
                <Button type="primary" onClick={e => e.stopPropagation()}>
                  <UnorderedListOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            </WithPermission>
          </div>
        </div>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {isLoading ? (
              <SkeletonTable
                rowCount={productOrders.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="table--selectable permission-table"
                size="middle"
                rowClassName={record =>
                  record.isSummaryRow ? 'summary-row' : ''
                }
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={productOrders}
                onChange={({ current,pageSize }) => onParamChange({ page: current ,limit:pageSize})}
                summary={()=>(<Table.Summary fixed={"top"} >
                                <Table.Summary.Row key={-1} >
                                    <Table.Summary.Cell align='right' index={4} colSpan={6} >Tổng khách cần trả : <b>{summary?.customerNeedToPay?.toLocaleString()}</b></Table.Summary.Cell> 
                                    <Table.Summary.Cell align='right'> Tổng trả lại khách : <strong>{summary?.customerPaid?.toLocaleString()}</strong></Table.Summary.Cell>
                                  </Table.Summary.Row>
                          </Table.Summary>)}
                pagination={
                 {
                    ...paging,
                    // pageSize:paging.pageSize,
                    showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50' ,'100','1000','10000','100000'],
                    showTotal: () => `Tổng cộng: ${paging.total} `
                  }
                }
                onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}

                onRow={onSelectRow(setSelectTable)}

                expandable={{
                  expandedRowRender,
                  rowExpandable: record => !record.isSummaryRow,
                  defaultExpandAllRows: !!query.id,
                  expandedRowKeys:[selectTable]
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState =(state)=>{
  return {
    summary: state.productOrder.summary
  }
}


export default connect(mapState)(ProductOrder);
