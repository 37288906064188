import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  partnerInfo: {},
  isGetPartnerLoading: false,
  getPartnerFailed: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PRODUCT_PARTNER_REQUEST:
      state.isGetPartnerLoading = true;
      state.getPartnerFailed = null;
      return;

    case Types.GET_PRODUCT_PARTNER_SUCCESS:
      state.isGetPartnerLoading = false;
      state.partnerInfo = payload.data;
      return;

    case Types.GET_PRODUCT_PARTNER_FAILED:
      state.isGetPartnerLoading = false;
      state.getPartnerFailed = payload;
      return;

    default:
      return;
  }
}, initState)
