import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getProductInvoices,
  createProductInvoice,
  deleteProductInvoice,
  getProductInvoice,
  resetProductInvoiceState,
  updateProductInvoice
} from '~/redux/action';
import { Menu, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const PRODUCT_INVOICE_MODULE = 'productInvoice';

const CREATE_SUCCESS_MESS = 'Tạo mới hoá đơn thành công';
const CREATE_FAILED_MESS = 'Tạo mới hoá đơn thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật hoá đơn thành công';
const UPDATE_FAILED_MESS = 'Cập nhật hoá đơn thất bại';
const DELETE_SUCCESS_MESS = 'Xoá hoá đơn thành công';
const DELETE_FAILED_MESS = 'Xoá hoá đơn thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(PRODUCT_INVOICE_MODULE);

export const useProductInvoices = query => {
  const jsonParam = JSON.stringify(query)
  const paramRef = useRef(null);
  const [queryParam, setQueryParam] = useState(query)

  if(jsonParam !== JSON.stringify(paramRef.current)) {
    paramRef.current = query
    setQueryParam(paramRef.current)
  }

  return useFetchByParam({
    action: getProductInvoices,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: queryParam
  });
};

export const useCreateProductInvoice = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createProductInvoice
  });
};

export const useUpdateProductInvoice = callback => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProductInvoice
  });
};

export const useDeleteProductInvoice = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deleteProductInvoice
  });
};

export const useProductInvoice = params => {
  return useFetchByParam({
    action: getProductInvoice,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitProductInvoice = (warehouse, id) => {
  return useMemo(() => {
    if (!warehouse || !id) {
      return {};
    }

    return { ...warehouse };
  }, [warehouse, id]);
};

export const useUpdateProductInvoiceParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: '/product-invoice',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useProductInvoiceQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const page = query.get('page');
  const code = query.get('code');
  const id = query.get('id');
  const lotNumber = query.get('lotNumber');
  const expirationDate = query.get('expirationDate');
  const variantCodeProductName = query.get('variantCodeProductName');
  const purchasedAt = query.get('purchasedAt');
  const customer = query.get('customer');

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      code,
      id,
      lotNumber,
      expirationDate,
      variantCodeProductName,
      purchasedAt,
      customer

    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, 
    limit, 
    keyword, 
    createSuccess,
    updateSuccess, 
    deleteSuccess, 
    id,
    lotNumber,
    expirationDate,
    variantCodeProductName,
    purchasedAt,
    customer

  ]);
};

export const useProductInvoicePaging = () => useSelector(pagingSelector);

export const useResetProductInvoice = () => {
  useResetState(resetProductInvoiceState);
};

const COLUMNS_OPTIONS = [
  {
    title: 'Mã hoá đơn',
    key: 'code'
  },
  {
    title: 'Thời gian',
    key: 'time'
  },
  {
    title: 'Khách hàng',
    key: 'customer'
  },
  {
    title: 'Tổng tiền hàng',
    key: 'total'
  },

  {
    title: 'Giảm giá',
    key: 'discountValue'
  },

  {
    title: 'Khách cần trả',
    key: 'debt'
  },

  {
    title: 'Khách đã trả',
    key: 'totalPayment'
  },
  {
    title: 'Thao tác',
    key: 'action'
  }
];

export const useProductInvoiceColumnSetting = () => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState({
    code: true,
    time: true,
    customer: true,
    debt: true,
    payment: true,
    totalPayment: true,
    discountValue: true,
    total: true
  });

  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = key => {
      setSelectedColumnKeys({
        ...selectedColumnKeys,
        [key]: !selectedColumnKeys[key]
      });
    };

    const menu = (
      <Menu>
        {COLUMNS_OPTIONS.map(({ title, key }) => (
          <Menu.Item
            key={key}
            onClick={() => {
              toggleSelectColumnKey(key);
            }}
          >
            <Checkbox checked={!!selectedColumnKeys[key]} />
            <span style={{ marginLeft: 10 }}>{title}</span>
          </Menu.Item>
        ))}
      </Menu>
    );

    return menu;
  }, [selectedColumnKeys]);

  return [columnMenu, selectedColumnKeys];
};

export const SEARCH_PRODUCTINVOICE = [
  {
    name : "code",
    placeholder:"Theo mã hóa đơn"
  },
  {
    name : "variantCodeProductName",
    placeholder:"Theo mã, tên hàng"
  },
  {
    name : "lotNumber",
    placeholder:"Theo lô"
  },
  {
    name : "customer",
    placeholder:"Theo tên khách hàng hoặc số điện thoại"
  },
  {
    name : "expirationDate",
    type: "date",
    label: "Hạn sử dụng"
  },
  {
    name : "purchasedAt",
    type: "rangerTime",
    label: "Thời gian"
  },
]