import React, { useMemo } from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatCurrency } from '~/utils/helper';
import InformationItem from '~/components/Common/InformationItem';
import { Link } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';
import { WithPermission } from '~/components/Common';
import POLICY from '~/constants/policy';
import { get, isArray, isObject, isPlainObject, uniqBy } from 'lodash';
import { deeplyObject } from '~/hooks/utils';

const LEFT_INFO = [
  {
    label: 'Mã hàng:',
    dataIndex: 'variantCode'
  },
  {
    label: 'Mã vạch:',
    dataIndex: 'barcode'
  },
  {
    label: 'Mã sản phẩm:',
    dataIndex: 'codeBySupplier'
  },
  {
    label: 'Nhóm hàng:',
    dataIndex: 'group'
  },
  {
    label: 'Loại hàng:',
    dataIndex: 'type'
  },
  {
    label: 'Tên viết tắt:',
    dataIndex: 'aliasName'
  },
  {
    label: 'Định mức tồn',
    dataIndex: 'remain',
    

  },
  {
    label: 'Giá bán:',
    dataIndex: 'price',
    render: price => formatCurrency(price)
  },
  {
    label: 'Giá vốn:',
    dataIndex: 'cost',
    render: cost => formatCurrency(cost)
  },
  {
    label: 'Trọng lượng:',
    dataIndex: 'weight'
  },
  {
    label: 'Đường dùng:',
    dataIndex: 'method'
  },
  {
    label: 'Vị trí:',
    dataIndex: 'location'
  }
];

const RIGHT_INFO = [
  {
    label: 'Mã dược quốc gia:',
    dataIndex: 'medicineCode'
  },
  {
    label: 'Số đăng ký:',
    dataIndex: 'registrationNo'
  },
  {
    label: 'Hoạt chất:',
    dataIndex: 'ingredient'
  },
  {
    label: 'Hàm lượng:',
    dataIndex: 'dosage'
  },
  {
    label: 'Hãng sản xuất:',
    dataIndex: 'manufacturer'
  },
  {
    label: 'Nước sản xuất:',
    dataIndex: 'country'
  },
  {
    label: 'Quy cách đóng gói:',
    dataIndex: 'packagingSize'
  },
  {
    label: 'Mô tả',
    dataIndex: ['detailDescription','description',]
  },

  {
    label: 'Ghi chú đặt hàng:',
    dataIndex: 'noteTemplate',
  },
  // {
  //   label: 'Nhà cung cấp:',
  //   dataIndex: ['supplier', 'name']
  // }
];

const Information = ({ product, deleteMedicine, onOpenForm }) => {
  const productInfo = useMemo(() => {
    const { selectedVariant, detailDescription } = product;
    return {
      ...product,
      ...product.productDetail,
      ...selectedVariant,
      group: product?.productGroup?.name,
      type: product?.productType?.name || 'Thuốc',
      remain: detailDescription
        ? detailDescription.quantityMin??"..." + ' -> ' + (detailDescription?.quantityMax ?detailDescription?.quantityMax:"...")
        : '',
      description: product?.detailDescription?.description,
      noteTemplate : product?.detailDescription?.noteTemplate,
      location: product?.productPosition?.name,
      manufacturer: product.manufacturer?.name,
      country: product.country?.name,
      method: product.productType?.name
    };
  }, [product]);

  return (
    <div className="medicine-info medicine-detail-tab-item">
      <h4 className="medicine-info__name">{product.name}</h4>
      <Row gutter={48}>
        <Col span={8}>
          <div className="medicine-info-images">
            <div className="medicine-info-images__main-image">
              <img
                className="responsive-image"
                src={product && product?.image && product?.image[0]?.response?.url||"./No-Image.svg"}
                alt="main"
              />
            </div>

            <div className="medicine-info-images__list">
            {product?.image?.map((image, index) =>
            index !== 0 && <figure className="medicine-info-images__sub-image">
                <img
                  className="responsive-image"
                  src={image?.response?.url ||"./No-Image.svg"}
                  alt="main"
                />
              </figure>)
            }
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="medicine-info__general">
            {LEFT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={deeplyObject(productInfo, dataIndex)}
                render={render}
              />
            )
            )}
          </div>
        </Col>
        <Col span={8}>
          <div className="medicine-info__general">
            {RIGHT_INFO.map(({ label, dataIndex, render }, index) => (
              <InformationItem
                key={index}
                label={label}
                value={deeplyObject(productInfo, dataIndex)}
                right
                render={render}
              />
            )
            )}

            <div
              className="medicine-information-item"
              // style={{ paddingLeft: 10, display: 'block', opacity: 0.7 }}
            >
              <label style={{width: '200px'}}>
                Nhà cung cấp:
              </label>
              <span>
              {productInfo?.supplier?.name}
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="medicine-info__actions" justify="end">
        <WithPermission permission={POLICY.WRITE_PRODUCT}>
          <Link
            to={{
              pathname: PATH_CLINIC.warehouse.create,
              state: { variantId: product?.selectedVariant?.variantCode }
            }}
          >
        <WithPermission permission={POLICY.WRITE_PURCHASEORDER}>
            <Button type="primary" style={{ marginRight: 10 }}>
              {' '}
              Nhập hàng
            </Button>
        </WithPermission>
          </Link>
        </WithPermission>
        <WithPermission permission={POLICY.UPDATE_PRODUCT}>
          <Button type="primary" onClick={() => onOpenForm(product._id,product.category)}>
            {' '}
            <EditOutlined style={{ marginLeft: 0 }} /> Cập nhật
          </Button>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_PRODUCT}>
          <Popconfirm
            title="Bạn muốn xoá hàng hoá này?"
            onConfirm={() => deleteMedicine(product._id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <Button type="danger">
              {' '}
              <DeleteOutlined style={{ marginLeft: 0 }} />
              Xoá
            </Button>
          </Popconfirm>{' '}
        </WithPermission>
      </Row>
    </div>
  );
};

export default Information;

