import React from 'react';
import { Input, Row, Col, Select, Skeleton, Form } from 'antd';
import { useCities, useDistricts, useWards } from '~/hooks/worldCare/geo';
import { filterAcrossAccents } from '~/hooks/utils';

const FormItem = Form.Item;
const { Option } = Select;

const Address = props => {
  const {
    isLoading,
    form,
    cityCode,
    setCityCode,
    districtCode,
    setDistrictCode
  } = props;

  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(cityCode);
  const [wards, isWardsLoading] = useWards(districtCode);

  return (
    <>
      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem label="Thành Phố/Tỉnh" name={['address', 'cityId']}>
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                onChange={setCityCode}
                disabled={isCitiesLoading}
                loading={isCitiesLoading}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {cities.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem label="Quận/Huyện" name={['address', 'districtId']}>
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                loading={isDistrictsLoading}
                disabled={!form.getFieldValue(['address', 'cityId'])}
                onChange={setDistrictCode}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {districts.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem label="Phường/Xã" name={['address', 'wardId']}>
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                loading={isWardsLoading}
                disabled={!form.getFieldValue(['address', 'districtId'])}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {wards.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Đường phố" name={['address', 'street']}>
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Email bạn nhập không đúng định dạng!'
              }
            ]}
          >
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Số điện thoại"
            name="phoneNumber"
            rules={[
              {
                pattern: new RegExp(/^[0-9]{10,11}$/),
                message: 'Xin vui lòng nhập đúng số điện thoại!'
              }
            ]}
          >
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default Address;
