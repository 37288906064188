import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  paging: {current: 1, pageSize: 11}
}

export default produce((state, {type, payload}) => {
  switch (type){
    case Types.GET_DIRECTORY_REQUEST :
      state.isLoading = true;
      state.getListFailed = null;
      return;
    case Types.GET_DIRECTORY_SUCCESS :
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;
    case Types.GET_DIRECTORY_FAILED:
      state.isLoading = true;
      state.getListFailed = payload;
      state.list = [];
      return;
    default:
      return;
  }
},initState)
