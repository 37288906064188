import React from 'react'
import { Row } from 'antd'
import PropTypes from 'prop-types'
import { CARD_STYLE } from '~/constants/defaultValue'
import { get } from 'lodash'
import { formatCurrency } from '~/utils/helper'
const type = {
    cyan:"--cyan",
    info:"--info",
    danger:"--danger",
    green:"--green",

}

function SummaryTable({summaryValue}) {

    return (
        <div style={CARD_STYLE}>
              <Row
                className="cash-flow-report"
                gutter={16}
                justify="end"
                align="middle"
              >
                {summaryValue&& summaryValue?.map((item,index)=><div key={index} className="cash-flow-report__item cash-flow-report-item">
                  <p className="cash-flow-report-item__label">{get(item,"title","")}</p>
                  <p className={"cash-flow-report-item__value cash-flow-report-item__value"+get(type,item["type"])??"--cyan"}>
                    {formatCurrency(get(item,"value",0))}
                  </p>
                </div>)}
              </Row>
            </div>
    )
}
SummaryTable.propTypes = {
    summaryValue: PropTypes.array
}
SummaryTable.defaultProps = {
    summaryValue : [],
}
export default SummaryTable


