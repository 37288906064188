import { omit } from 'lodash';
import requester from './requester';

const productOrder = {
  getAll: query => requester.get('/sale-order', query),
  getById: id => requester.get(`/sale-order/${id}`),
  delete: id => requester.delete(`/sale-order/${id}`),
  updateStatus: (data) => requester.put(`/sale-order-status/${data?.id}`, omit(data, ['id']))
};

export default productOrder;
