import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { has } from 'lodash';
import { memo, useMemo } from 'react';
import { useExport } from '.';

function MenuExportOption ({handle}){
    return <Menu>
        <Menu.Item>
            <Button onClick={handle?.export} style={{width:"100%"}}>Xuất file tổng quát</Button>
        </Menu.Item>
        <Menu.Item>
            <Button onClick={handle?.exportDetail} style={{width:"100%"}}>Xuất file chi tiết</Button>
        </Menu.Item>
    </Menu>
}
function ButtonExportOption({query,router,label,fileName}) {
    const {handleAddExport} =useExport()
      const tranFormQuery =useMemo(()=>{
        const newQuery ={...query}
        if(has(newQuery,"limit")){delete newQuery["limit"]}
        if(has(newQuery,"page")){ delete newQuery["page"]}
        return newQuery
     },[query])
    const handle = {
        export :()=>{
            handleAddExport({ query:{ ...tranFormQuery,exportOption:1},router,label,fileName:fileName[0]})
         },
        exportDetail :()=>{ 
            handleAddExport({query:{...tranFormQuery,exportOption:2},router,label,fileName:fileName[1]})
        }
    }
    return (
        <Dropdown.Button
            style={{marginRight:10}}
            onClick={handle.export}
            overlay={<MenuExportOption handle={handle}/>}
            type={"primary"}
            trigger={["hover"]}
            icon={<DownOutlined />}>
            Xuất file
        </Dropdown.Button>
    )
}
ButtonExportOption.defaultProps = {
    fileName: "Export"
}

export default memo(ButtonExportOption)

