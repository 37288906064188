import { Popconfirm, Table } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Link,  useParams,  useRouteMatch } from "react-router-dom";
import { WithPermission } from "~/components/Common";
import SkeletonTable from "~/components/Utils/SkeletonTable";
import POLICY from '~/constants/policy';


const ColumnActions = ({
  _id,
  deletePartner,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_PARTNER}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}

      <WithPermission permission={POLICY.DELETE_PARTNER}>
        <Popconfirm
          title="Bạn muốn xoá đối tác này?"
          onConfirm={() => deletePartner(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

function ListBranches({ customer }) {
  const {path, url} = useRouteMatch()
  const [childrens, setChildrens]= useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setChildrens(customer?.childrenBranches)
    setIsLoading(false)
},[customer])
console.log(childrens)
  const columns = [
    {
      title: 'Tên chi nhánh',
      key: 'name',
      render: (rc) => <Link to={`${path}/detail/${rc.partnerId}/branches/${rc._id}`}>{get(rc, 'name',get(rc, 'name.vi'))}</Link>
    },
    // {
    //   title: 'Loại',
    //   key: 'modules',
    //   render: (rc) => {
    //     return Object.values(rc.modules).join(', ');
    //   }
    // },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>
          {address &&
            `${address?.street} - ${address?.ward} - ${address?.district} - ${address?.city}`}
        </p>
      )
    },

    {
      title: 'Điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    }
  ];

  // if (hasUpdate || hasDelete) {
  //   columns.push({
  //     title: 'Thao tác',
  //     key: 'action',
  //     width: '110px',
  //     render: (record) => {
  //       return (
  //         <ColumnActions
  //           {...record}
  //           deletePartner={deletePartner}
  //           shouldShowDevider={shouldShowDevider}
  //           onOpenForm={onOpenForm}
  //         />
  //       );
  //     }
  //   });
  // }
  return (
  
    <div className="medicine-detail-tab-item medicine-remain-tab">
    {isLoading ? (
      <SkeletonTable
        columns={columns}
        // rowCount={partners.length}
        // pagination={paging}
      />
    ) : (
          <Table
        className="nested-table permission permission-table"
        rowKey={(rc) => String(rc._id)}
        columns={columns}
        dataSource={childrens}
        // onChange={({ current }) => onParamChange({ page: current })}
        // onRow={onSelectRow(setSelectTable)}
        // onExpandedRowsChange={(e)=>setSelectTable(e?.at(-1))}

       
            pagination={ false}
        size="middle"
      />
        )}
   </div>
  )
}

export default ListBranches;