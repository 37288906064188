import React, { useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import OrderDetails from '../OrderDetails';
import { FilterOrderStatus, FilterDatePicker } from '~/components/Common';
import { Table, Popconfirm, Button, Input, Modal } from 'antd';
import { ORDER_STATUS_NAME } from '~/constants/defaultValue';
import {
  useAllOrders,
  useDeleteOrder,
  useAllOrdersQueryParams,
  useOrderPaging,
  useUpdateOrderParams
} from '~/hooks/worldCare/order';
import './all-order.scss';

const { Search } = Input;

const ColumnActions = ({ id, deleteOrder, openDetails }) => {
  return (
    <div className="custom-table__actions">
      <p onClick={openDetails}>Chi tiết</p>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá đơn hàng này?"
        onConfirm={() => deleteOrder(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const AllOrders = () => {
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const [query] = useAllOrdersQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateOrderParams(query);
  const [orders, isLoading] = useAllOrders(query);
  const [, deleteOrder] = useDeleteOrder();
  const paging = useOrderPaging();

  const handleCancel = () => {
    setSelectedOrderNumber(null);
  };

  const columns = [
    {
      title: 'Mã',
      dataIndex: 'orderNumber',
      key: 'orderNumber'
    },

    {
      title: 'Tên',
      dataIndex: 'shippingAddress',
      key: 'name',
      render: value => value.fullName
    },

    {
      title: 'Điện thoại',
      dataIndex: 'shippingAddress',
      key: 'phoneNumber',
      render: value => value.phoneNumber
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'shippingAddress',
      key: 'address',
      render: value =>
        `${value.street}, ${value.ward}, ${value.district}, ${value.city}`
    },
    {
      title: 'Nhà thuốc',
      dataIndex: 'shopInfo',
      key: 'shopInfo',
      render: value => value?.name
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: value => (
        <span className={`order-status ${value.toLowerCase()}`}>
          {ORDER_STATUS_NAME[value]}
        </span>
      )
    },
    {
      title: 'Giá trị đơn hàng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      align: 'right',
      width: '140px',
      render: value => value?.toLocaleString()
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: record => {
        return (
          <ColumnActions
            {...record}
            deleteOrder={deleteOrder}
            openDetails={() => setSelectedOrderNumber(record.orderNumber)}
          />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content orders-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách đơn hàng" />
        <div className="page-content__main">
          <div className="page-content__left">
            <FilterOrderStatus
              value={query.status}
              onChange={value => onParamChange({ status: value })}
            />
            <FilterDatePicker
              label="Từ"
              value={query.startTime}
              onChange={value => onParamChange({ startTime: value })}
            />
            <FilterDatePicker
              label="Đến"
              value={query.endTime}
              onChange={value => onParamChange({ endTime: value })}
            />
          </div>
          <div className="page-content__right">
            <div className="page-wraper__header">
              <Search
                style={{ width: 300 }}
                placeholder="Tìm đơn hàng"
                enterButton
                allowClear
                onSearch={() => onParamChange({ keyword })}
                onChange={e => setKeyword(e.target.value)}
                value={keyword}
              />
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={orders}
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showSizeChanger: false,
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
          <Modal
            title="Chi tiết đơn hàng"
            className="order-details-modal"
            visible={selectedOrderNumber !== null}
            onCancel={handleCancel}
            style={{ top: 40 }}
            width="90%"
            footer={[
              <Button key="back" onClick={handleCancel}>
                Đóng
              </Button>
            ]}
          >
            <OrderDetails
              orderNumber={selectedOrderNumber}
              editable={false}
              showAction={false}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
