import React from 'react';

const InformationItem = ({ label, value, right, render, record }) => {
  return(
  <div
    className="medicine-information-item"
    style={!label ? { height: 100 } : {}}
  >
    <label
      className={`medicine-information-item__label ${
        right && 'medicine-information-item__label--right'
      }`}
    >
      {label}
    </label>
    <span className="medicine-information-item__value">
      {render ? render(value,record) : value}
    </span>
  </div>
)};

export default InformationItem;
