import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  useQueryParams
} from '~/hooks/utils';

import {
  getFormPrints,
  createFormPrint,
  // getFormPrint,
  resetFormPrintState,
  updateFormPrint,
  deleteFormPrint
} from '~/redux/action';
import { useSelector } from 'react-redux';

const getSelector = key => state => state.printForm[key];

const loadingSelector = getSelector('isLoading');
const formPrintsSelector = getSelector('formPrints');
const getFormPrintsFailedSelector = getSelector('getFormPrintsFailed');

const isGetFormPrintLoadingSelector = getSelector('isGetFormPrintLoading');
const formPrintSelector = getSelector('formPrint');
const getFormPrintFailedSelector = getSelector('getFormPrintFailed');

// const deleteSuccessSelector = getSelector('deleteSuccess');
// const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useFormPrintQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  
  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current }) => setPage(current);

  // const deleteFormPrintSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
  }, [page, limit, keyword]);
};

export const useFormPrints = query => {
  return useFetchByParam({
    action: getFormPrints,
    loadingSelector,
    dataSelector: formPrintsSelector,
    failedSelector: getFormPrintsFailedSelector,
    param: query
  });
};

export const useCreateFormPrint = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createFormPrint
  });
};

export const useUpdateFormPrint = callback => {
  useSuccess(updateSuccessSelector, 'Cập nhật thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateFormPrint
  });
};

// export const useDeleteFormPrint = () => {
//   useSuccess(deleteSuccessSelector, 'Xoá thành công');
//   useFailed(deleteFailedSelector, 'Xoá thất bại');

//   return useSubmit({
//     loadingSelector,
//     action: deleteFormPrint
//   });
// };

// export const useFormPrint = id => {
//   return useFetchByParam({
//     action: getFormPrint,
//     loadingSelector: isGetFormPrintLoadingSelector,
//     dataSelector: formPrintSelector,
//     failedSelector: getFormPrintFailedSelector,
//     param: id
//   });
// };

export const useInitFormPrint = formPrint => {
  return useMemo(() => {
    if (!formPrint) {
      return {};
    }

    const initValues = {
      ...formPrint
    };

    return initValues;
  }, [formPrint]);
};

export const useFormPrintPaging = () => useSelector(pagingSelector);

export const useResetFormPrint = () => {
  useResetState(resetFormPrintState);
};
