import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useFetch,
  useQueryParams
} from '~/hooks/utils';

import {
  getManufactures,
  getManufacture,
  createManufacture,
  updateManufacture,
  deleteManufacture,
  resetManufactureState,
  getManufactureOptions,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS } from '~/constants/defaultValue';

const MANUFACTURE_MODULE = 'manufacture';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,

  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,

  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,

  pagingSelector
} = getSelectors(MANUFACTURE_MODULE);

const getSelector = key => state => state[MANUFACTURE_MODULE][key];

const optionsSelector = getSelector('options');
const getOptionsLoadingSelector = getSelector('isGetOptionsLoading');
const getOptionsFailedSelector = getSelector('getOptionsFailed');

export const useManufacturePaging = () => useSelector(pagingSelector);

export const useManufactures = query => {
  return useFetchByParam({
    action: getManufactures,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};
export const useManufacture = id => {
  return useFetchByParam({
    action: getManufacture,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateManufactureParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    history.push({
      pathname: '/manufacturer',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useManufactureQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const name = query.get('name');
  const status = query.get('status');

  const deleteSuccess = useSelector(deleteSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const createSuccess = useSelector(createSuccessSelector);
  if (prevKeyword.current !== name) {
    prevKeyword.current = name;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      name: name || undefined,
      status
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, name, status, deleteSuccess, updateSuccess, createSuccess]);
};

export const useCreateManufacture = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới hãng sản xuất thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới hãng sản xuất thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createManufacture
  });
};

export const useUpdateManufacture = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật hãng sản xuất thành công');
  useFailed(updateFailedSelector, 'Cập nhật hãng sản xuất thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateManufacture
  });
};

export const useDeleteManufacture = () => {
  useSuccess(deleteSuccessSelector, 'Xoá hãng sản xuất thành công');
  useFailed(deleteFailedSelector, 'Xoá hãng sản xuất thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteManufacture
  });
};

export const useInitManufacturer = (manufacturer, id) => {
  return useMemo(() => {
    if (!manufacturer || !id) {
      return { status: true };
    }

    return {
      ...manufacturer,
      status: manufacturer.status === STATUS.ACTIVE ? true : false
    };
  }, [manufacturer, id]);
};

export const useManufactureOptions = () => {
  return useFetch({
    action: getManufactureOptions,
    loadingSelector: getOptionsLoadingSelector,
    dataSelector: optionsSelector,
    failedSelector: getOptionsFailedSelector
  });
};

export const useResetManufacture = () => {
  useResetState(resetManufactureState);
};
