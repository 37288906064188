import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  options: [],
  isGetOptionsLoading: false,
  getOptionsFailed: null,

  search: [],
  isGeSearchLoading: false,
  getSearchFailed: null,

  paging: { current: 1, pageSize: 11 },

  pagingDebt: null,

  customerDebt: [],
  isGetcustomerDebtLoading: false,
  getcustomerDebtFailed: null,

  customerPurchases: [],
  isGetcustomerPurchasesLoading: false,
  getcustomerPurchasesFailed: null,

  customerOrderHistory: [],
  isGetcustomerOrderHistoryLoading: false,
  getcustomerOrderHistoryFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CUSTOMERS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_CUSTOMERS_SUCCESS:
      state.isLoading = false;

      const summaryRecord = {
        isSummaryRow: true,
        _id: -1,
        ...payload.summary
      };
      state.list = payload.summary
        ? [summaryRecord, ...payload.docs]
        : payload.docs;

      state.paging = getPaging(payload);
      return;

    case Types.GET_CUSTOMERS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_CUSTOMER_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_CUSTOMER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_CUSTOMER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_CUSTOMER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_CUSTOMER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.options = [...state.options, payload];
      return;

    case Types.CREATE_CUSTOMER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_CUSTOMER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_CUSTOMER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_CUSTOMER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_CUSTOMER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_CUSTOMER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_CUSTOMER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_CUSTOMER_OPTIONS_REQUEST:
      state.isGetOptionsLoading = true;
      state.getOptionsFailed = null;
      return;

    case Types.GET_CUSTOMER_OPTIONS_SUCCESS:
      state.isGetOptionsLoading = false;
      state.options = payload;
      return;

    case Types.GET_CUSTOMER_OPTIONS_FAILED:
      state.isGetOptionsLoading = false;
      state.getOptionsFailed = payload;
      state.options = [];
      return;

    case Types.GET_CUSTOMER_SEARCH_REQUEST:
      state.isGetSearchLoading = true;
      state.getSearchFailed = null;
      return;

    case Types.GET_CUSTOMER_SEARCH_SUCCESS:
      state.isGetSearchLoading = false;
      state.search = payload;
      return;

    case Types.GET_CUSTOMER_SEARCH_FAILED:
      state.isGetSearchLoading = false;
      state.getSearchFailed = payload;
      state.search = [];
      return;

    case Types.GET_CUSTOMER_DEBT_REQUEST:
      state.isGetCustomerDebtLoading = true;
      state.getCustomerDebtFailed = null;
      return;

    case Types.GET_CUSTOMER_DEBT_SUCCESS:
      state.isGetCustomerDebtLoading = false;
      state.customerDebt = payload.docs;
      state.pagingDebt = getPaging(payload);
      return;

    case Types.GET_CUSTOMER_DEBT_FAILED:
      state.isGetCustomerDebtLoading = false;
      state.getCustomerDebtFailed = payload;
      state.customerDebt = []
      return;

    case Types.GET_CUSTOMER_PURCHASES_REQUEST:
      state.isGetCustomerPurchasesLoading = true;
      state.getCustomerPurchasesFailed = null;
      return;

    case Types.GET_CUSTOMER_PURCHASES_SUCCESS:
      state.isGetCustomerPurchasesLoading = false;
      state.customerPurchases = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CUSTOMER_PURCHASES_FAILED:
      state.isGetCustomerPurchasesLoading = false;
      state.getCustomerPurchasesFailed = payload;
      state.customerPurchases = [];
      return;

    case Types.GET_CUSTOMER_ORDER_HISTORY_REQUEST:
      state.isGetCustomerOrderHistoryLoading = true;
      state.getCustomerOrderHistoryFailed = null;
      return;

    case Types.GET_CUSTOMER_ORDER_HISTORY_SUCCESS:
      state.isGetCustomerOrderHistoryLoading = false;
      state.customerOrderHistory = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CUSTOMER_ORDER_HISTORY_FAILED:
      state.isGetCustomerOrderHistoryLoading = false;
      state.getcustomerOrderHistoryFailed = payload;
      state.customerOrderHistory = [];
      return;

    case Types.RESET_CUSTOMER_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
