import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getSamplePrescriptions({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const SamplePrescription = yield call(Api.samplePrescription.getById, query.id);
      data = { docs: [SamplePrescription], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.samplePrescription.getAll, query);
    }

    yield put({ type: Types.GET_SAMPLE_PRESCRIPTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SAMPLE_PRESCRIPTIONS_FAILED,
      payload: error.message
    });
  }
}

function* createSamplePrescription(action) {
  try {
    const data = yield call(Api.samplePrescription.create, action.payload);
    yield put({ type: Types.CREATE_SAMPLE_PRESCRIPTION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SAMPLE_PRESCRIPTION_FAILED,
      payload: error.message
    });
  }
}

function* updateSamplePrescription(action) {
  try {
    const data = yield call(Api.samplePrescription.update, action.payload);
    yield put({ type: Types.UPDATE_SAMPLE_PRESCRIPTION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SAMPLE_PRESCRIPTION_FAILED,
      payload: error.message
    });
  }
}

function* deleteSamplePrescription({ payload }) {
  try {
    yield call(Api.samplePrescription.delete, payload);
    yield put({ type: Types.DELETE_SAMPLE_PRESCRIPTION_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_SAMPLE_PRESCRIPTION_FAILED,
      payload: error.message
    });
  }
}
function* updateSamplePrescriptionNote(action) {
  try {
    const data = yield call(Api.samplePrescription.updateNote, action.payload);
    yield put({ type: Types.UPDATE_SAMPLE_PRESCRIPTION_NOTE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SAMPLE_PRESCRIPTION_NOTE_FAILED,
      payload: error.message
    });
  }
}
function* getSamplePrescription({ payload }) {
  try {
    const samplePrescription = yield call(Api.samplePrescription.getById, payload);
    yield put({
      type: Types.GET_SAMPLE_PRESCRIPTION_SUCCESS,
      payload: samplePrescription
    });
  } catch (error) {
    yield put({
      type: Types.GET_SAMPLE_PRESCRIPTION_FAILED,
      payload: error.message
    });
  }
}
export default function* SamplePrescription() {
  yield takeLatest(Types.GET_SAMPLE_PRESCRIPTIONS_REQUEST, getSamplePrescriptions);
  yield takeLatest(Types.DELETE_SAMPLE_PRESCRIPTION_REQUEST, deleteSamplePrescription);
  yield takeLatest(Types.CREATE_SAMPLE_PRESCRIPTION_REQUEST, createSamplePrescription);
  yield takeLatest(Types.UPDATE_SAMPLE_PRESCRIPTION_REQUEST, updateSamplePrescription);
  yield takeLatest(Types.UPDATE_SAMPLE_PRESCRIPTION_NOTE_REQUEST, updateSamplePrescriptionNote);
  yield takeLatest(Types.GET_SAMPLE_PRESCRIPTION_REQUEST, getSamplePrescription);
}
