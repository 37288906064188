import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useFetch,
  useQueryParams
} from '~/hooks/utils';

import {
  getReceiptsPaymentType,
  getReceiptPaymentType,
  createReceiptPaymentType,
  updateReceiptPaymentType,
  deleteReceiptPaymentType,
  resetReceiptPaymentTypeState,
  getReceiptsPaymentFilterType
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { STATUS } from '~/constants/defaultValue';

const RECEIPT_PAYMENT_TYPE_MODULE = 'receiptPaymentType';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,

  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,

  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,

  pagingSelector
} = getSelectors(RECEIPT_PAYMENT_TYPE_MODULE);

export const useReceiptPaymentTypePaging = () => useSelector(pagingSelector);

export const useReceiptsPaymentType = query => {
  return useFetchByParam({
    action: getReceiptsPaymentType,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useReceiptsPaymentFilterType = query => {
  return useFetchByParam({
    action: getReceiptsPaymentFilterType,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useReceiptPaymentType = id => {
  return useFetchByParam({
    action: getReceiptPaymentType,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateReceiptPaymentTypeParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.name);

  const onParamChange = param => {
    if (param['type'] && !param['type'].length) {
      param = { type: undefined };
    }
    let path = '';
    query.type === 'RECEIPT' ? (path = 'receipt') : (path = 'payment');
    history.push({
      pathname: `/${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useReceiptPaymentTypeQueryParams = types => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const status = query.get('status');
  const type = types;

  const deleteSuccess = useSelector(deleteSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const createSuccess = useSelector(createSuccessSelector);
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      status,
      type,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, status, type, deleteSuccess, updateSuccess, createSuccess]);
};

export const useCreateReceiptPaymentType = callback => {
  useSuccess(createSuccessSelector, 'Tạo mới loại thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới loại thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createReceiptPaymentType
  });
};

export const useUpdateReceiptPaymentType = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật loại thành công');
  useFailed(updateFailedSelector, 'Cập nhật loại thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateReceiptPaymentType
  });
};

export const useDeleteReceiptPaymentType = () => {
  useSuccess(deleteSuccessSelector, 'Xoá loại thành công');
  useFailed(deleteFailedSelector, 'Xoá loại thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteReceiptPaymentType
  });
};

export const useInitReceiptPaymentType = (receiptPaymentType, id) => {
  return useMemo(() => {
    if (!receiptPaymentType || !id) {
      return { status: true };
    }

    return {
      ...receiptPaymentType,
      status: receiptPaymentType.status === STATUS.ACTIVE ? true : false
    };
  }, [receiptPaymentType, id]);
};

export const useResetReceiptPaymentType = () => {
  useResetState(resetReceiptPaymentTypeState);
};
