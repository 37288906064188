import requester from '../requester';

const coupon = {
  getAll: (query) => requester.get('/coupon', query),
  getById: (id) => requester.get(`/coupon/${id}`),
  create: (coupon) => requester.post('/coupon', coupon),
  update: (coupon) => requester.put(`/coupon/${coupon._id}`, coupon),
  delete: (id) => requester.delete(`/coupon/${id}`)
};

export default coupon;
