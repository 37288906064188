import requester from '../requester';

const damage = {
  getAll: query => requester.get('/damage-item', query),
  getById: id => requester.get(`/damage-item/${id}`),
  create: damage => requester.post('/damage-item', damage),
  update: damage => requester.put(`/damage-item/${damage._id}`, damage),
  delete: id => requester.delete(`/damage-item/${id}`)
};

export default damage;
