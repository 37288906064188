export const GET_SUPPLIER_GROUPS_REQUEST = 'GET_SUPPLIER_GROUPS_REQUEST';
export const GET_SUPPLIER_GROUPS_SUCCESS = 'GET_SUPPLIER_GROUPS_SUCCESS';
export const GET_SUPPLIER_GROUPS_FAILED = 'GET_SUPPLIER_GROUPS_FAILED';

export const GET_SUPPLIER_GROUP_REQUEST = 'GET_SUPPLIER_GROUP_REQUEST';
export const GET_SUPPLIER_GROUP_SUCCESS = 'GET_SUPPLIER_GROUP_SUCCESS';
export const GET_SUPPLIER_GROUP_FAILED = 'GET_SUPPLIER_GROUP_FAILED';

export const CREATE_SUPPLIER_GROUP_REQUEST = 'CREATE_SUPPLIER_GROUP_REQUEST';
export const CREATE_SUPPLIER_GROUP_SUCCESS = 'CREATE_SUPPLIER_GROUP_SUCCESS';
export const CREATE_SUPPLIER_GROUP_FAILED = 'CREATE_SUPPLIER_GROUP_FAILED';

export const UPDATE_SUPPLIER_GROUP_REQUEST = 'UPDATE_SUPPLIER_GROUP_REQUEST';
export const UPDATE_SUPPLIER_GROUP_SUCCESS = 'UPDATE_SUPPLIER_GROUP_SUCCESS';
export const UPDATE_SUPPLIER_GROUP_FAILED = 'UPDATE_SUPPLIER_GROUP_FAILED';

export const DELETE_SUPPLIER_GROUP_REQUEST = 'DELETE_SUPPLIER_GROUP_REQUEST';
export const DELETE_SUPPLIER_GROUP_SUCCESS = 'DELETE_SUPPLIER_GROUP_SUCCESS';
export const DELETE_SUPPLIER_GROUP_FAILED = 'DELETE_SUPPLIER_GROUP_FAILED';

export const RESET_SUPPLIER_GROUP_STATE = 'RESET_SUPPLIER_GROUP_STATE';
