import * as React from 'react';
import { Spin, Tabs } from 'antd';
import { resetNotificationServiceState } from '~/redux/action';
import './notification-list.scss';
import { useNotificationService } from '~/hooks';
import {
  FileDoneOutlined,
  FileExcelOutlined,
  FileSyncOutlined
} from '@ant-design/icons';
import { ACTIVITY_NAME_LINK, ACTIVITY_NAME_VI } from '~/constants/defaultValue';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const NotificationList = ({ dispatchList }) => {
  const [notificationParam, setNotificationParam] = React.useState({
    type: null,
    page: 1,
    limit: 10
  });
  const [keyTabs, setKeyTabs] = React.useState('ALL');
  const [dataNotification, isLoading] =
    useNotificationService(notificationParam);

  const callbackTabClicked = (key, event) => {
    dispatchList();
    setSet(false);
    if (key === 'ALL') {
      setNotificationParam({ type: null, page: 1, limit: 10 });
    } else {
      setNotificationParam({ type: key, page: 1, limit: 10 });
    }
    setKeyTabs(key);
  };

  const [state, setSet] = React.useState(false);
  React.useEffect(() => {
    const cards = document.querySelector('.notification-list__box');
    const t = document.querySelectorAll('.notification-list__end-point');
    const card = new IntersectionObserver(
      en => {
        en.forEach(element => {
          if (element.isIntersecting) {
            setNotificationParam(e => ({ ...e, page: e.page + 1 }));
          }
        });
      },
      { root: cards, rootMargin: '0px 0px 120px 0px' }
    );
    if (t.length && !state) {
      setSet(true);
      t.forEach(e => {
        card.observe(e);
      });
    }

    return () => {
      if (t.length && state) {
        t.forEach(e => {
          card.unobserve(e);
        });
      }
    };
  }, [dataNotification]);
  return (
    <div className="notification-list">
      <h4 className="notification-list__title">CÁC HOẠT ĐỘNG GẦN ĐÂY</h4>

      <div className="notification-list__box">
        <Tabs
          defaultActiveKey={'ALL'}
          activeKey={keyTabs}
          onTabClick={callbackTabClicked}
        >
          <Tabs.TabPane tab="Tất cả" key="ALL">
            <TemplateNotificationList
              dataNotification={dataNotification}
              isLoading={isLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Cục dược quốc gia" key="MOH">
            <TemplateNotificationList
              dataNotification={dataNotification}
              isLoading={isLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Nội bộ" key="INTERNAL">
            <TemplateNotificationList
              dataNotification={dataNotification}
              isLoading={isLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchList: () => dispatch(resetNotificationServiceState())
});

export default connect(() => {}, mapDispatchToProps)(NotificationList);

const ConvertActionMess = ({ mess, value, code }) => {
  const newMess = mess.split(ACTIVITY_NAME_VI[value]);
  return (
    <div className="notification-list__message">
      {newMess[0]}{' '}
      <Link to={`/${ACTIVITY_NAME_LINK[value]}?limit=10&keyword=${code}`}>
        {ACTIVITY_NAME_VI[value]}
      </Link>{' '}
      {newMess[1]}
    </div>
  );
};
const TemplateNotificationList = ({ dataNotification, isLoading, props }) => {
  return dataNotification && dataNotification.length ? (
    <ul className="notification-list__tab">
      {dataNotification.map((item, index) => (
        <li key={index} className="notification-list__item">
          {item?.data?.detail?.purchaseOrderItems && (
            <FileDoneOutlined className={'is-blue'} />
          )}
          {item?.data?.detail?.invoiceDetail && (
            <FileExcelOutlined className={'is-light-blue'} />
          )}
          {item?.data?.detail?.productReturnDetail && (
            <FileSyncOutlined className={'is-orange'} />
          )}
          {!item?.data?.detail?.purchaseOrderItems &&
            !item?.data?.detail?.invoiceDetail &&
            !item?.data?.detail?.productReturnDetail && (
              <FileDoneOutlined className={'is-blue'} />
            )}
          <ConvertActionMess
            mess={item?.data?.message}
            value={item?.data?.action}
            code={item?.data?.detail?.code}
          />
        </li>
      ))}
      <p
        key={'notification-list__end-point'}
        className="notification-list__end-point"
      >
        <Spin spinning={isLoading}></Spin>
      </p>
    </ul>
  ) : (
    'Không có thông báo mới'
  );
};
