import React, { useState, useRef, useEffect, useMemo, memo } from 'react';

import {
    Form,
    Col,
    Row,
    Input,
    Button,
    Select,
    DatePicker,
    Modal,
    Table,
    Space,
  } from 'antd';
import Text from 'antd/lib/typography/Text';
import { INVOICE_STATUS, PAYMENT_METHOD_DICT,  } from '~/constants/defaultValue';
import moment from 'moment';
import { transform } from 'lodash';
import { connect } from 'react-redux';

import { getUserEmployees } from '~/redux/action';
import { useReactToPrint } from 'react-to-print';
import { useCallback } from 'react';

const ReceiptBillModal = memo(({ isOpen, onClose, dataView,listUsers,userNow ,handleGetUser})=>{
    console.log(dataView)
    const [form]=Form.useForm()
    const ref=useRef()

    const onUpdateForm = useCallback((value)=>{
        console.log(value)
    },[])
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        pageStyle: `@media print {
            @page {
              size: A4 landscape;
              margin: 20 mm;
            }
          }`,
        
      });
    
    useEffect(()=>{
        if(!!dataView){
            const newDataView  ={...dataView, updatedAt: moment(dataView?.updatedAt),note:dataView?.note??""}
            form.setFieldsValue(newDataView)
        }
        if(!!isOpen) handleGetUser({limit:100 })
    },[isOpen,dataView,handleGetUser,form])

    const columns = useMemo(() => {
        const allCloumn = [
          {
            title: 'Mã hóa đơn',
            key: 'code',
            dataIndex: 'code'
          },
          {
            title: 'Thời gian',
            key: 'purchasedAt',
            dataIndex: 'purchasedAt',
            render: purchasedAt => moment(purchasedAt).format('DD/MM/YYYY')
          },
          {
            title: 'Giá trị',
            key: 'total',
            dataIndex: 'total',
          },
          {
            title: 'Tiền thu/chi',
            key: 'totalPayment',
            dataIndex: 'totalPayment',
          },
          {
            title: 'Trạng thái',
            key: 'status',
            dataIndex: 'status',
            render:(status)=>(INVOICE_STATUS[status])
          },
        ];
    
        return allCloumn
      }, []);
      const paymentMethodOptions = transform(PAYMENT_METHOD_DICT,(obj,value,key)=>{obj.push({value:key,label:value})},[])

    const summary=()=>{
    
        return <Table.Summary.Row>
            <Table.Summary.Cell align='right' colSpan={12}>
                <Space size={20} >  <Text> Tổng tiền thu:</Text> <Text> {dataView?.totalPayment?.toLocaleString()}</Text> </Space> 
            </Table.Summary.Cell>
        </Table.Summary.Row>}
    

    const footerBtn =[
        <Button key={"update"} style={{backgroundColor:"green",color:"white",borderRadius:8}} onClick={()=>{onClose()}} >Update</Button>,
        <Button key={"print"} style={{borderRadius:8}} onClick={()=>{handlePrint()}} >In</Button>,
        <Button key={"danger"} style={{backgroundColor:"red",color:"white",borderRadius:8}} onClick={()=>{onClose()}} >Hủy</Button>,
    ]
    return <Modal forceRender width={980} visible={isOpen} footer={footerBtn} onCancel={onClose}  >
        <Row>
            <Col span={24} ref={ref}>
                <h4 style={{ paddingLeft: 30, marginBottom: 30, fontSize: '1.5rem' }}> {"Phiếu thu"}</h4>
                <Row >
                    <Col span={24}>
                    <Form
                        form={form}
                        onFinish={onUpdateForm}
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        className ={"form-receptBill-custom"}
                        >
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label={"Mã phiếu thu:"} name={"code"}><Input/></Form.Item>
                                    <Form.Item label={"Thời gian:"} name={"updatedAt"}><DatePicker format={"DD/MM/YYYY HH:mm"}/></Form.Item>
                                    <Form.Item label={"Khách hàng:"} name={["customer","name"]}><Input /></Form.Item>
                                    <Form.Item label={"Người tạo:"} name={["createdBy",'fullName']}><Input /></Form.Item>

                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"Nhân viên:"} dependencies={[userNow,listUsers]} name={"employ"}>
                                        <Select defaultValue={userNow?._id}  options={listUsers??[]}/> 
                                    </Form.Item>
                                    <Form.Item label={"Phương thức:"} dependencies={dataView?.paymentMethod} name={"paymentMethod"}>
                                        <Select defaultValue={dataView?.paymentMethod} options={paymentMethodOptions}/> 
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                <Form.Item name={"note"}><Input.TextArea rows={5} bordered={false} placeholder={"Ghi chú...✒️"}/></Form.Item>
                                </Col>
                            </Row>
                        </Form> 
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table 
                        dataSource={[dataView?.referenceDoc]}
                        columns={columns}
                        summary={summary}
                        pagination={false}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        
    </Modal>
  })
  const mapDispatchToProps =(dispatch)=>{
    return {
        handleGetUser : (param)=>dispatch(getUserEmployees(param))
    }
  }
  const mapState = (state,currentData) =>{
    return {
        userNow: state.user.profile,
        listUsers:  state.userEmployee.list.map((value)=>{return {value:value?._id, label:value?.fullName}})
    }
  }
  export default connect (mapState,mapDispatchToProps)(ReceiptBillModal) 
  
