import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME_EN,
  ACTIVITY_NAME
} from '~/constants/defaultValue';
function* getProductReturns({ payload: query }) {
  try {
    let data;
    if (query.id) {
      const productReturn = yield call(Api.productReturn.getById, query.id);
      data = { docs: [productReturn], limit: 1, page: 1, totalDocs: 1 };
    } else {
      data = yield call(Api.productReturn.getAll, query);
    }

    yield put({ type: Types.GET_PRODUCT_RETURNS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_RETURNS_FAILED,
      payload: error.message
    });
  }
}

function* getReceivers({ payload: query }) {
  try {
    const data = yield call(Api.productReturn.getReceivers, query);
    yield put({ type: Types.GET_RECEIVERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RECEIVERS_FAILED,
      payload: error.message
    });
  }
}

function* returnProduct(action) {
  try {
    const data = yield call(Api.productReturn.returnProduct, action.payload);
    yield put({ type: Types.RETURN_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.RETURN_PRODUCT_FAILED,
      payload: error.message
    });
  }
}
function* createReturnProductSuccessAndCreateActivity(action) {
  const getProfile = (state) => state.user.profile

  try {
    let profile = yield select(getProfile); 
    const { branchId, totalReturn } = action.payload;
    const message = `${profile.fullName} ${MESSAGE_ACTIVITY.PRODUCT_RETURN} ${totalReturn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy : profile, message , action: ACTIVITY_NAME.PRODUCT_RETURN},
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
  } catch (error) {
    console.log(error);
  }
}
function* exchangeProduct(action) {
  try {
    const data = yield call(Api.productReturn.exchangeProduct, action.payload);
    yield put({ type: Types.EXCHANGE_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.EXCHANGE_PRODUCT_FAILED,
      payload: error.message
    });
  }
}

function* updateProductInfoReturn(action) {
  try {
    const data = yield call(Api.productReturn.update, action.payload);
    yield put({ type: Types.UPDATE_PRODUCT_INFO_RETURN_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PRODUCT_INFO_RETURN_FAILED,
      payload: error
    });
  }
}

export default function* productReturn() {
  yield takeLatest(Types.GET_PRODUCT_RETURNS_REQUEST, getProductReturns);
  yield takeLatest(Types.GET_RECEIVERS_REQUEST, getReceivers);
  yield takeLatest(Types.RETURN_PRODUCT_REQUEST, returnProduct);
  yield takeLatest(Types.RETURN_PRODUCT_SUCCESS, createReturnProductSuccessAndCreateActivity);
  yield takeLatest(Types.EXCHANGE_PRODUCT_REQUEST, exchangeProduct);
  yield takeLatest(Types.UPDATE_PRODUCT_INFO_RETURN_REQUEST, updateProductInfoReturn);
}
