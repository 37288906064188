import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import userReducer from './worldCare/user/reducer';
import hospitalReducer from './worldCare/hospital/reducer';
import appointment from './worldCare/appointment/reducer';
import degree from './worldCare/degree/reducer';
import speciality from './worldCare/speciality/reducer';
import geo from './worldCare/geo/reducer';
import staff from './worldCare/staff/reducer';
import service from './worldCare/service/reducer';
import newsCategory from './worldCare/newsCategory/reducer';
import news from './worldCare/news/reducer';
import staffGroup from './worldCare/staffGroup/reducer';
import layout from './worldCare/layout/reducer';
import branch from './worldCare/branch/reducer';
import employee from './worldCare/employee/reducer';
import employeeGroup from './worldCare/employeeGroup/reducer';
import partner from './worldCare/partner/reducer';
import order from './worldCare/order/reducer';
import userEmployee from './worldCare/userEmployee/reducer';
import partnership from './worldCare/partnership/reducer';
import coupon from './worldCare/coupon/reducer';
import policy from './worldCare/policy/reducer';
import dashboard from './worldCare/dashboard/reducer';
import shippingVendor from './worldCare/shippingVendor/reducer';
import permission from './worldCare/permission/reducer';

// WORLD CLINIC
import medicineGroup from './worldClinic/medicineGroup/reducer';
import medicineMethod from './worldClinic/medicineMethod/reducer';
import medicineLocation from './worldClinic/medicineLocation/reducer';
import medicineUnit from './worldClinic/medicineUnit/reducer';
import supplier from './worldClinic/supplier/reducer';
import supplierGroup from './worldClinic/supplierGroup/reducer';
import medicineCategory from './worldClinic/medicineCategory/reducer';
import medicine from './worldClinic/medicine/reducer';
import warehouse from './worldClinic/warehouse/reducer';
import productOrder from './worldClinic/productOrder/reducer';
import productInvoice from './worldClinic/productInvoice/reducer';
import cashFlow from './worldClinic/cashFlow/reducer';
import sale from './worldClinic/sale/reducer';
import customer from './worldClinic/customer/reducer';
import manufacture from './worldClinic/manufacture/reducer';
import batch from './worldClinic/batch/reducer';
import purchaseReturn from './worldClinic/purchaseReturn/reducer';
import purchaseOrderQuotation from './worldClinic/purchaseOrderQuotation/reducer';
import damage from './worldClinic/damage/reducer';
import productReturn from './worldClinic/productReturn/reducer';
import productReport from './worldClinic/productReport/reducer';
import stockTake from './worldClinic/stockTake/reducer';
import samplePrescription from './worldClinic/samplePrescription/reducer';
import directory from './worldClinic/directory/reducer';
import partnerClinic from './worldClinic/partner/reducer';
import notificationService from './worldClinic/notificationService/reducer';
import printForm from './worldClinic/formPrint/reducer';
import imformationPayment from './worldClinic/imformationPayment/reducer';
import receiptPaymentType from './worldClinic/receiptPaymentType/reducer';
import processImport from './worldClinic/processImport/reducer';
import myNotification from './worldClinic/myNotification/reducer';

const userPersistConfig = {
  key: 'user',
  storage: storage,
  blacklist: [
    'loginFailed',
    'isLoading',
    'isGetProfileLoading',
    'isGetPolicyLoading',
    'getPolicySuccess',
    'policy',
    'getPolicyFailed',
    'getProfileFailed'
  ]
};

const salePersistConfig = {
  key: 'sale',
  storage: storage,
  blacklist: [
    'isLoading',
    'isGetByIdLoading',
    'byId',
    'getByIdFailed',
    'isSubmitLoading',
    'updateSuccess',
    'updateFailed',
    'createSuccess',
    'createFailed',
    'submitingUUID'
  ]
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  hospital: hospitalReducer,
  appointment,
  degree,
  speciality,
  geo,
  staff,
  service,
  newsCategory,
  news,
  staffGroup,
  layout,
  branch,
  employee,
  employeeGroup,
  partner,
  order,
  userEmployee,
  partnership,
  coupon,
  policy,
  dashboard,
  shippingVendor,
  permission,

  // WORLD CLINIC
  medicineGroup,
  medicineMethod,
  medicineLocation,
  medicineUnit,
  supplier,
  medicineCategory,
  medicine,
  supplierGroup,
  warehouse,
  productOrder,
  productInvoice,
  cashFlow,
  sale: persistReducer(salePersistConfig, sale),
  customer,
  manufacture,
  batch,
  purchaseReturn,
  purchaseOrderQuotation,
  productReturn,
  productReport,
  stockTake,
  samplePrescription,
  damage,
  directory,
  partnerClinic,
  notificationService,
  printForm,
  imformationPayment,
  receiptPaymentType,
  processImport,
  myNotification,
});

export default rootReducer;
