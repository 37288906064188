import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  isOverViewLoading: false,
  overView: null,
  getOverViewFailed: null,

  isMonthlyLoading: false,
  monthly: [],
  getMonthlyFailed: null,

  isDailyLoading: false,
  daily: [],
  getDailyFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_OVER_VIEW_REPORT_REQUEST:
      state.isOverViewLoading = true;
      return;

    case Types.GET_OVER_VIEW_REPORT_SUCCESS:
      state.isOverViewLoading = false;
      state.overView = payload;
      return;

    case Types.GET_OVER_VIEW_REPORT_FAILED:
      state.isOverViewLoading = false;
      state.getOverViewFailed = payload;
      state.overView = {};
      return;

    case Types.GET_MONTHLY_REPORT_REQUEST:
      state.isMonthlyLoading = true;
      return;

    case Types.GET_MONTHLY_REPORT_SUCCESS:
      state.isMonthlyLoading = false;
      state.monthly = payload;
      return;

    case Types.GET_MONTHLY_REPORT_FAILED:
      state.isMonthlyLoading = false;
      state.getMonthlyFailed = payload;
      state.monthly = [];
      return;

    case Types.GET_DAILY_REPORT_REQUEST:
      state.isDailyLoading = true;
      return;

    case Types.GET_DAILY_REPORT_SUCCESS:
      state.isDailyLoading = false;
      state.daily = payload;
      return;

    case Types.GET_DAILY_REPORT_FAILED:
      state.isDailyLoading = false;
      state.getDailyFailed = payload;
      state.daily = [];
      return;

    case Types.RESET_DASHBOARD_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
