export const GET_PURCHASE_RETURNS_REQUEST = 'GET_PURCHASE_RETURNS_REQUEST';
export const GET_PURCHASE_RETURNS_SUCCESS = 'GET_PURCHASE_RETURNS_SUCCESS';
export const GET_PURCHASE_RETURNS_FAILED = 'GET_PURCHASE_RETURNS_FAILED';

export const GET_PURCHASE_RETURN_REQUEST = 'GET_PURCHASE_RETURN_REQUEST';
export const GET_PURCHASE_RETURN_SUCCESS = 'GET_PURCHASE_RETURN_SUCCESS';
export const GET_PURCHASE_RETURN_FAILED = 'GET_PURCHASE_RETURN_FAILED';

export const CREATE_PURCHASE_RETURN_REQUEST = 'CREATE_PURCHASE_RETURN_REQUEST';
export const CREATE_PURCHASE_RETURN_SUCCESS = 'CREATE_PURCHASE_RETURN_SUCCESS';
export const CREATE_PURCHASE_RETURN_FAILED = 'CREATE_PURCHASE_RETURN_FAILED';

export const UPDATE_PURCHASE_RETURN_REQUEST = 'UPDATE_PURCHASE_RETURN_REQUEST';
export const UPDATE_PURCHASE_RETURN_SUCCESS = 'UPDATE_PURCHASE_RETURN_SUCCESS';
export const UPDATE_PURCHASE_RETURN_FAILED = 'UPDATE_PURCHASE_RETURN_FAILED';

export const DELETE_PURCHASE_RETURN_REQUEST = 'DELETE_PURCHASE_RETURN_REQUEST';
export const DELETE_PURCHASE_RETURN_SUCCESS = 'DELETE_PURCHASE_RETURN_SUCCESS';
export const DELETE_PURCHASE_RETURN_FAILED = 'DELETE_PURCHASE_RETURN_FAILED';

export const RESET_PURCHASE_RETURN_STATE = 'RESET_PURCHASE_RETURN_STATE';
