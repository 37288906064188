import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Skeleton,
} from 'antd';
const FormItem = Form.Item;
export default function ManagerInfoForm({isLoading}) {
    return (
        <>
          <Row gutter={48} align='middle' className="branch-form__managerInfo" justify="space-between">
            <Col span={12}>
                <FormItem
                
                  label="Tên người đại diện"
                  name="representativeName"
                  
                >
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
            </Col>
            <Col span={12}>
                <FormItem 
                label="SĐT người đại diện"
                name="representativePhoneNumber"
                rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,11}$/),
                        message: "Xin vui lòng nhập đúng số điện thoại!"
                    }
                ]}
                >
                {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
            </Col>
          </Row>

          <Row gutter={48} className="branch-form__managerInfo" >
            <Col span={24}>
                <FormItem
                
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                  label="CMND/CCCD"
                  name="representativeCertificateNumber"
                  
                  
                  rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^([0-9]{9,12})$/),
                        message: "Xin vui lòng nhập đúng số chứng minh nhân dân!"
                      }
                  ]}
                
                  
                >
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
                
            </Col>
            
          </Row>
          <Row gutter={48} className="branch-form__managerInfo">
            <Col span={24}>
                <FormItem
                
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                  label="Người chịu trách nhiệm"
                  name="responsiblePersonName"
                >
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
                
            </Col>
            
          </Row>

          <Row gutter={48} className="branch-form__managerInfo">
            <Col span={24}>
                <FormItem
                
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                  label="Số chứng chỉ hành nghề"
                  name="practicingCertificateNumber"
                >
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
                
            </Col>
            
          </Row>

          <Row gutter={48} className="branch-form__managerInfo">
            <Col span={24}>
                <FormItem
                
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                  label="Trình độ chuyên môn"
                  name="professionalQualification"
                >
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
                
            </Col>
            
          </Row>

          <Row gutter={48} className="branch-form__managerInfo">
            <Col span={24}>
                <FormItem
                
                  label="SĐT người chịu trách nhiệm"
                  name="responsiblePersonPhone"
                  rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,11}$/),
                        message: "Xin vui lòng nhập đúng số điện thoại!"
                    }
                ]}
                  labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                  wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                
                >
                  {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
            </Col>
            
          </Row>
          <Row gutter={48} className="branch-form__managerInfo">
            <Col span={24}>
                <FormItem 
                label="Email người chịu trách nhiệm"
                name="responsiblePersonEmail"
                rules={[
                  {
                    type: "email",
                    message: 'Email bạn nhập không đúng định dạng!'
                  }
                ]}
                labelCol={{ sm: 24, md: 24, lg: 4, xl: 4 }}
                wrapperCol={{ sm: 24, md: 24, lg: 20, xl: 20 }}
                >
                {isLoading ? <Skeleton.Input active /> : <Input />}
                </FormItem>
            </Col>
          </Row>
          
        </>
    )
}
