import React from 'react';
import { PATH_APP } from '~/routes/paths';
import { useSpecialities, useDeleteSpeciality } from '~/hooks/worldCare/speciality';
import { Table, Popconfirm, Button } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { Link } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';

const columns = [
  {
    title: 'Tên Chứng chỉ',
    dataIndex: 'name',
    key: 'name'
  },

  {
    title: 'Thao tác',
    key: 'action',
    width: '100px',
    render: (record) => <ColumnActions {...record} />
  }
];

const ColumnActions = ({ id }) => {
  const [, deleteSpeciality] = useDeleteSpeciality();

  return (
    <div className="custom-table__actions">
      <Link to={`/speciality/edit/${id}`}>
        <p>Sửa</p>
      </Link>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá phòng khám này?"
        onConfirm={() => deleteSpeciality(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const Specialities = () => {
  const [specialities, isLoading] = useSpecialities();

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách chuyên môn" />
        <div className="page-wraper__header page-wraper__header--flex-end">
          <Link to={PATH_APP.config.createSpeciality}>
            <Button type="primary">Tạo mới</Button>
          </Link>
        </div>

        {isLoading ? (
          <SkeletonTable rowCount={specialities.length} columns={columns} />
        ) : (
          <Table
            rowKey={(rc) => rc.id}
            columns={columns}
            dataSource={specialities}
            pagination={{
              total: specialities.length,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size='middle'
          />
        )}
      </div>
    </div>
  );
};

export default Specialities;
