import React from 'react';
import { Tabs, Button, Tooltip, Dropdown, Menu } from 'antd';
import { useDeleteSale, getSaleName } from '~/hooks';
import { v4 as uuidv4 } from 'uuid';
import {
  DownOutlined,
  SwapOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import {
  DISCOUNT_TYPES,
  SALE_TYPES,
  PAYMENT_METHODS
} from '~/constants/defaultValue';
import { getSaleCase } from '~/utils/saleHelper';

const { ORDER, DIRECT, RETURN } = SALE_TYPES;
const { CASH } = PAYMENT_METHODS;

const { TabPane } = Tabs;

const getSaleColor = type => (type === DIRECT ? '#2db7f5' : 'orange');

const Header = ({ selectSale, addSale, sales, selectedSale, onSaleChange }) => {
  const [, deleteSale] = useDeleteSale();

  const onAddSale = type => {
    const newSale = {
      name: getSaleName(sales, type),
      medicines: [],
      summary: {
        discount: { type: DISCOUNT_TYPES.VALUE, value: 0 },
        payment: [{ method: CASH, value: 0 }]
      },
      uuid: uuidv4(),
      type
    };

    newSale.case = getSaleCase(newSale);

    addSale(newSale);
  };

  const onEdit = (uuid, action) => {
    const isAdding = action === 'add';
    if (isAdding) {
      onAddSale(DIRECT);
    }


    const isRemoving = action === 'remove';
    if (isRemoving) {
      deleteSale(uuid);
    }
  };

  return (
    <>
      <Tabs
        type="editable-card"
        onChange={selectSale}
        activeKey={selectedSale?.uuid}
        onEdit={onEdit}
      >
        {sales.map(sale => (
          <TabPane
            tab={
              sale?.uuid === selectedSale?.uuid ? (
                <span
                  style={{
                    color: getSaleColor(sale?.type),
                    display: 'flex',
                    alignItems: 'center',
                    height: 36
                  }}
                >
                  {!sale.saleOrderId && sale.type !== RETURN && (
                    <Tooltip
                      placement="bottom"
                      title={`Chuyển sang ${
                        sale.type === DIRECT ? 'đặt hàng' : 'hoá đơn'
                      }`}
                      color={getSaleColor(sale.type)}
                    >
                      <Button
                        onClick={() =>
                          onSaleChange(sale.uuid, {
                            type: sale.type === DIRECT ? ORDER : DIRECT
                          })
                        }
                        className="tab__swap-btn btn--no-border"
                        shape="circle"
                        icon={
                          <SwapOutlined
                            style={{
                              marginRight: 0,
                              color: getSaleColor(sale.type)
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  )}

                  {sale.name}
                </span>
              ) : (
                <span
                  style={{
                    color: getSaleColor(sale.type)
                  }}
                >
                  {sale.name}
                </span>
              )
            }
            key={sale.uuid}
          ></TabPane>
        ))}
      </Tabs>

      <Dropdown
        overlay={
          <Menu onClick={() => onAddSale(SALE_TYPES.ORDER)}>
            <Menu.Item key="1">Thêm mới đặt hàng</Menu.Item>
          </Menu>
        }
        placement="bottomCenter"
        style={{ marginRight: 50 }}
      >
        <Button className="sale-header__add-order-btn">
          <DownOutlined />
        </Button>
      </Dropdown>

      <div className="sale-header__right-tool-bar">
        <p className="sale-header__user-name">0967991550</p>

        <Button
          className="btn--no-border sale-header__right-menu"
          shape="circle"
          icon={<UnorderedListOutlined />}
        />
      </div>
    </>
  );
};

export default Header;
