import {
  useFetchByParam,
} from '~/hooks/utils';

import {
  getPartnerById
} from '~/redux/action';

const PARTNER_MODULE = 'partnerClinic';

const getSelector = key => state => state[PARTNER_MODULE][key];

const getPartnerInfoSelector = getSelector('partnerInfo');
const getPartnerInfoLoadingSelector = getSelector('isGetPartnerLoading');
const getPartnerInfoFailedSelector = getSelector('getPartnerFailed');

export const usePartnerById = partnerId => {
  return useFetchByParam({
    action: getPartnerById,
    loadingSelector: getPartnerInfoLoadingSelector,
    dataSelector: getPartnerInfoSelector,
    failedSelector: getPartnerInfoFailedSelector,
    param: partnerId
  });
};
