import requester from '../requester';

const newsCategory = {
  getAll: query => requester.get('/news-category', query),
  getById: id => requester.get(`/news-category/${id}?raw=true`),
  delete: id => requester.delete(`/news-category/${id}`),
  create: newsCategory => requester.post('/news-category', newsCategory),
  update: newsCategory =>
    requester.put(`/news-category/${newsCategory.id}`, newsCategory)
};

export default newsCategory;
