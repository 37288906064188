import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Skeleton,
  Modal
} from 'antd';

import UploadImage from '~/components/Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';

import {
  useCreateEmployee,
  useEmployee,
  useInitEmployee,
  useResetEmployee,
  useUpdateEmployee
} from '~/hooks';
import { useStaffGroups } from '~/hooks/worldCare/staffGroup';
import './form.scss';
import { useSpecialities } from '~/hooks/worldCare/speciality';
import { useDegrees } from '~/hooks/worldCare/degree';
import { filterAcrossAccents } from '~/hooks/utils';
import { useParams } from 'react-router-dom';
import History from './History';
import Certification from './Certification';
import AddressFormSection from '~/components/Common/AddressFormSection';

const { Option } = Select;

const FormItem = Form.Item;

const verticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const EmployeeForm = ({ isOpen, onClose, id }) => {
  const { id: branchId } = useParams();

  const [form] = Form.useForm();
  const [logo, setLogo] = useState();

  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [degrees, isDegreesLoading] = useDegrees();
  const [staffGroups, isStaffGroupLoading] = useStaffGroups();

  const [isSubmitLoading, handleCreate] = useCreateEmployee(onClose);
  const [, handleUpdate] = useUpdateEmployee(onClose);

  const [employeeDetail, isGetEmployeeLoading] = useEmployee(id);
  const initEmployee = useInitEmployee(employeeDetail, id);

  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  useEffect(() => {
    form.resetFields();
    const { avatar, address } = initEmployee;

    if (avatar) {
      setLogo(avatar);
    }

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initEmployee, form]);

  useResetEmployee();

  const onFinish = (values) => {
    const employee = {
      ...values,
      avatar: logo,
      branchId
    };

    if (id) {
      handleUpdate({
        ...employee,
        _id: id,
        employeeNumber: initEmployee.employeeNumber
      });
    } else {
      handleCreate(employee);
    }
  };

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }

    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }
  };

  const isLoading = isGetEmployeeLoading;

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
      style={{ top: 40 }}
    >
      <div className="employee-form">
        <h4 style={{ marginRight: 'auto', paddingLeft: 27 }}>
          {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} nhân viên
        </h4>

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          initialValues={initEmployee}
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="employee-form__logo-row"
          >
            <Col span={12}>
              <Row gutter={36}>
                <Col span={24}>
                  <FormItem
                    label="Tên nhân viên"
                    name="firstName"
                    rules={[
                      { required: true, message: 'Xin mời nhập tên nhân viên!' }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label="Họ nhân viên"
                    name="lastName"
                    rules={[
                      { required: true, message: 'Xin mời nhập tên nhân viên!' }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>

              <FormItem label="Giới tính" name="gender">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    <Option value="male" key="male">
                      Nam
                    </Option>
                    <Option value="female" key="female">
                      Nữ
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12} className="employee-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Ngôn ngữ" name="lang">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select mode="multiple" style={{ width: '100%' }}>
                    <Option value={LANGUAGE.VI} key={LANGUAGE.VI}>
                      Việt nam
                    </Option>
                    <Option value={LANGUAGE.EN} key={LANGUAGE.EN}>
                      English
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Nhóm nhân sự"
                name="employeeGroup"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn nhóm nhân sự'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isStaffGroupLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {staffGroups.map(({ id, _id, name }) => (
                      <Option key={id || _id} value={id || _id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48}>
            <Col span={12}>
              <FormItem label="Chuyên môn" name="speciality">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    placeholder=""
                    style={{ width: '100%' }}
                    loading={isSpecialitiesLoading}
                    disabled={isSpecialitiesLoading}
                  >
                    {specialities.map(({ name, id }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Mô tả ngắn" name={['description']}>
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Bằng cấp" name={['degree', 'degreeId']}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    disabled={isDegreesLoading}
                    loading={isDegreesLoading}
                  >
                    {degrees.map((degree) => (
                      <Option value={degree.id} key={degree.id}>
                        {degree.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Thời gian cấp" name={['degree', 'issuedAt']}>
                {isLoading ? <Skeleton.Input active /> : <DatePicker />}
              </FormItem>
            </Col>
          </Row>

          <Certification name="certification" verticalLayout={verticalLayout} />

          <History name="employeeHistory" verticalLayout={verticalLayout} />

          <Row className="employee-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EmployeeForm;
