import requester from '../requester';

const appointment = {
  getAll: query => requester.get('/appointment',query),
  getById: id => requester.get(`appointment/${id}`),
  delete: id => requester.delete(`appointment/${id}`),
  create: appointment => requester.post('appointment', appointment),
  update: appointment => requester.put(`appointment/${appointment.id}`, appointment)
};

export default appointment;
