import React, { memo, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
    ProviderPurchaseOrderQuotation,
    useGetValueContextPurchaseOrder
} from './contextProvider';
import { Button, Popconfirm, Table, Tag, Tooltip } from 'antd';
import {
    STATUS_ORDER_QUOTATION,
    STATUS_ORDER_QUOTATION_COLOR,
    STATUS_ORDER_QUOTATION_CONTENT
} from './constants';
import {
    DeleteOutlined,
    EyeOutlined,
    ShoppingCartOutlined,
    StopOutlined
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { formatCurrency } from '~/utils/helper';
import { Link } from 'react-router-dom';
import { PATH_CLINIC } from '~/routes/paths';

function TablePurchaseOrder() {
    const {
        query,
        paging,
        onParamChange,
    } = useGetValueContextPurchaseOrder();
    
    const columnMemo = useMemo(
        () => [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (e, rc, i) => i + 1 + query.limit * (query.page - 1),
                width: 80,
                align: 'center',
                fixed:'left',
            },
            {
                title: 'Mã đơn',
                dataIndex: 'code',
                key: 'code',
                fixed:'left',
                width: 100,
                align: 'center',
                render: (code, rc) => {
                    return <RenderCode code={code} record={rc}/>;
                }
            },
            {
                title: 'Mã đơn Nhập kho',
                dataIndex: 'purchaseOrder',
                key: 'code',
                fixed:'left',
                width: 150,
                align: 'center',
                render: (value, rc) => <Link to = {`${PATH_CLINIC.warehouse.root}?page=1&limit=10&keyword=${value?.code}&isRedirect=true`}>{value?.code}</Link>
            },
            {
                title: 'Ngày Tạo',
                key: 'createdAt',
                width: 100,
                align:'center',
                dataIndex: 'createdAt',
                render: date => {
                    return <div style={{width:130}}>{moment(date).format('YYYY-MM-DD HH:mm')}</div>;
                }
            },
            {
                title: 'Mã nhập hàng từ WorldPharma	',
                key: 'billCodeSequence',
                dataIndex: 'billCodeSequence',
                // fixed: 'left',
                align: 'center',
                width: 300,
            },
            {
                title: 'Cần trả NCC',
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                width: 150,
                align: 'center',
                render: (value) => formatCurrency(value)
            },
            {
                title: 'Nhà cung cấp',
                dataIndex: 'supplier',
                width: 200,
                key: 'supplier',
                render: (supplier, rc) => {
                    return <div style={{minWidth:300}}>{supplier}</div>;
                }
            },
           
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: 150,
                align:'center',
                render: (status,rc) => {

                    return (
                        <Tag color={STATUS_ORDER_QUOTATION_COLOR[status]} style={{display:'block'}}>
                            {STATUS_ORDER_QUOTATION_CONTENT[status]}
                        </Tag>
                    );
                }
            },
            {
                title: 'Ghi chú',
                key: 'note',
                dataIndex: 'note',
                align: 'center',
                width: 100,
                render: (note,rc) => {
                    return  <div style={{minWidth:400}}>
                            <NoteComponent value={note??""} id={rc._id}/>
                        </div>
                }
            },
            {
                title: 'Hành động',
                key: 'action',
                dataIndex: '_id',
                fixed:'right',
                width: 205,
                align: 'center',
                render: (id,{status}) =>  (<ActionConfirm id={id}  status={status}/>)
            }
        ],
        [ query.limit, query.page]
    );
    return (
        <ProviderPurchaseOrderQuotation.Consumer>
            {({ dataTable, loadingTable }) => (
                <Table
                    style={{ width: '100%' }}
                    // sticky
                    dataSource={dataTable}
                    loading={loadingTable}
                    columns={columnMemo}
                    rowKey={'_id'}
                    scroll={{x: 'max-content'}}
                    pagination={{
                        ...paging,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: total => `Tổng cộng: ${total}`,
                        onChange: (page, limit) => {
                            onParamChange({ page, limit });
                        }
                    }}
                />
            )}
        </ProviderPurchaseOrderQuotation.Consumer>
    );
}

export default memo(TablePurchaseOrder);

function NoteComponent({id,value}) {
    const [isFocus, setFocus] = useState(false);
    const { onUpdate } = useGetValueContextPurchaseOrder();
    const refValue = useRef(value);
    const refValueOld = useRef(value);
    return (
        <TextArea
            style={{border:'none'}}
            width={400}
            placeholder='Nhập ghi chú..'
            bordered={isFocus}
            onChange={(e)=>{refValue.current = e.target.value}}
            defaultValue={refValue.current}
            onFocus={() => setFocus(true)}
            onBlur={() => {
                setFocus(false);
                // eslint-disable-next-line eqeqeq
                if(value!==refValue.current&& refValueOld.current !== refValue.current){
                    refValueOld.current = refValue.current
                    onUpdate(id,{ note: refValue.current })
                }
            }}
        />
    );
}
function ActionConfirm ({id,status}){
    const { handleUpdate, handleDelete,query } = useGetValueContextPurchaseOrder();
    if ([STATUS_ORDER_QUOTATION.COMPLETED].includes(status)) return <></>;
    // if (status === STATUS_ORDER_QUOTATION.CONFIRMED)
    //     return (
    //         <Button
    //             onClick={() => {
    //                 handleUpdate({
    //                     _id: id,
    //                     status: STATUS_ORDER_QUOTATION.COMPLETED,

    //                     query
    //                 });
    //             }}
    //             style={{ background: STATUS_ORDER_QUOTATION_COLOR.COMPLETED }}
    //             icon={<ShoppingCartOutlined />}
    //             type="primary"
    //             size="small"
    //         >
    //             Hoàn thành
    //         </Button>
    //     );

    const initPropsButton ={
        okText:"Xác nhận",
        cancelText:"Huỷ",
        style:{ borderRadius: 8},
        placement:"left",
    }

    if ([STATUS_ORDER_QUOTATION.CANCELLED].includes(status))
        return (
            <Popconfirm
                title="Xác nhận xoá"
                onConfirm={() => {
                    handleDelete(id);
                }}
                okType="primary"
                {...initPropsButton}
            >
                <Button icon={<DeleteOutlined />} type="primary">
                  Xoá
                </Button>
            </Popconfirm>
        );

    return (
        <Button.Group size="small">
            <Popconfirm
                title="Xác nhận yêu cầu"
                onConfirm={() => {
                    handleUpdate({
                        _id:id,
                        status: STATUS_ORDER_QUOTATION.COMPLETED,

                        query
                    });
                }}
                okType="primary"
                {...initPropsButton}
            >
                <Button icon={<ShoppingCartOutlined />} type="primary">
                    Xác nhận
                </Button>
            </Popconfirm>

            <Popconfirm
                title="Xác nhận huỷ"
                onConfirm={() => {
                    handleUpdate({
                        _id:id,
                        status: STATUS_ORDER_QUOTATION.CANCELLED,

                        query
                    });;
                    console.log('xác nhận');
                }}
                okType="danger"
                {...initPropsButton}
            >
                <Button icon={<StopOutlined />} danger>
                    Huỷ{' '}
                </Button>
            </Popconfirm>
        </Button.Group>
    );
}


function RenderCode({ code, record }) {
    const { openModal } = useGetValueContextPurchaseOrder();
    return (
        <Tooltip trigger={['hover']} title={'Xem chi tiết'}>
            <Button
                icon={<EyeOutlined />}
                onClick={() =>
                    openModal({
                        dataTable: record.orderItems,
                        detail: {
                            supplier: record.supplier,
                            note: record.note,
                            createdAt: record.createdAt
                        },

                        id: record._id
                    }, code )
                }
                type="text"
            >
                {`  ${code}`}
            </Button>
        </Tooltip>
    );
}