import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
import {
  TYPE_ACTIVITY,
  MESSAGE_ACTIVITY,
  ACTIVITY_NAME
} from '~/constants/defaultValue';
function* getCashFlows({ payload: query }) {
  try {
    const data = yield call(Api.cashFlow.getAll, query);
    yield put({ type: Types.GET_CASH_FLOWS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CASH_FLOWS_FAILED,
      payload: error
    });
  }
}

function* createPaymentNoteType(action) {
  try {
    const data = yield call(Api.cashFlow.createPaymentNoteType, action.payload);
    yield put({ type: Types.CREATE_PAYMENT_NOTE_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PAYMENT_NOTE_TYPE_FAILED,
      payload: error
    });
  }
}

function* createRecipientSubmitter(action) {
  try {
    const data = yield call(Api.cashFlow.createRecipientSubmitter, action.payload);
    yield put({ type: Types.CREATE_RECIPIENT_SUBMITTER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_RECIPIENT_SUBMITTER_FAILED,
      payload: error
    });
  }
}

function* createPayRectNote(action) {
  try {
    const data = yield call(Api.cashFlow.createPayRectNote, action.payload);
    yield put({ type: Types.CREATE_PAY_REC_NOTE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PAY_REC_NOTE_FAILED,
      payload: error
    });
  }
}
function* createPayRectNoteSuccessAndCreateActivity(action) {
   const getProfile = (state) => state.user.profile
  try {
    let profile = yield select(getProfile); 
    const { branchId, paymentAmount,type } = action.payload;
    const message = `${profile.fullName} ${type === "RECEIPT" ? MESSAGE_ACTIVITY.CREATE_RECEIPT : MESSAGE_ACTIVITY.CREATE_PAYMENT} ${paymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    const dataSubmit = {
      type: TYPE_ACTIVITY.INTERNAL,
      branchId,
      data: { detail: action.payload, createdBy : profile, message,action : type === "RECEIPT" ? ACTIVITY_NAME.CREATE_RECEIPT : ACTIVITY_NAME.CREATE_PAYMENT },
    }
    const data = yield call(Api.notificationService.create, dataSubmit);
  } catch (error) {
    console.log(error);
  }
}
function* searchPayerReceivers({ payload }) {
  try {
    const data = yield call(Api.cashFlow.searchPayerReceivers, payload);
    yield put({ type: Types.SEARCH_PAYER_RECEIVERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SEARCH_PAYER_RECEIVERS_FAILED,
      payload: error
    });
  }
}

function* searchPayReceiversType({ payload }) {
  try {
    const data = yield call(Api.cashFlow.searchPayReceiversType, payload);
    yield put({ type: Types.SEARCH_PAY_RECEIVE_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SEARCH_PAYER_RECEIVERS_FAILED,
      payload: error
    });
  }
}

function* getCashFlow({ payload }) {
  try {
    const cashFlow = yield call(Api.cashFlow.getById, payload);
    yield put({
      type: Types.GET_CASH_FLOW_SUCCESS,
      payload: cashFlow
    });
  } catch (error) {
    yield put({
      type: Types.GET_CASH_FLOW_FAILED,
      payload: error
    });
  }
}

function* createCashFlow(action) {
  try {
    const data = yield call(Api.cashFlow.create, action.payload);
    yield put({ type: Types.CREATE_CASH_FLOW_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_CASH_FLOW_FAILED,
      payload: error
    });
  }
}

function* updateCashFlow(action) {
  try {
    const data = yield call(Api.cashFlow.update, action.payload);
    yield put({ type: Types.UPDATE_CASH_FLOW_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_CASH_FLOW_FAILED,
      payload: error
    });
  }
}

function* deleteCashFlow({ payload }) {
  try {
    yield call(Api.cashFlow.delete, payload);
    yield put({ type: Types.DELETE_CASH_FLOW_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_CASH_FLOW_FAILED,
      payload: error
    });
  }
}

export default function* cashFlow() {
  yield takeLatest(Types.CREATE_RECIPIENT_SUBMITTER_REQUEST, createRecipientSubmitter)
  yield takeLatest(Types.UPDATE_CASH_FLOW_REQUEST, updateCashFlow);
  yield takeLatest(Types.CREATE_PAYMENT_NOTE_TYPE_REQUEST, createPaymentNoteType)
  yield takeLatest(Types.SEARCH_PAYER_RECEIVERS_REQUEST, searchPayerReceivers)
  yield takeLatest(Types.SEARCH_PAY_RECEIVE_TYPE_REQUEST, searchPayReceiversType)
  yield takeLatest(Types.CREATE_PAY_REC_NOTE_REQUEST, createPayRectNote)
  yield takeLatest(Types.CREATE_PAY_REC_NOTE_SUCCESS, createPayRectNoteSuccessAndCreateActivity)
  yield takeLatest(Types.GET_CASH_FLOWS_REQUEST, getCashFlows);
  yield takeLatest(Types.GET_CASH_FLOW_REQUEST, getCashFlow);
  yield takeLatest(Types.DELETE_CASH_FLOW_REQUEST, deleteCashFlow);
  yield takeLatest(Types.CREATE_CASH_FLOW_REQUEST, createCashFlow);
}
