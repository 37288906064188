import React from 'react';
import { Form, Select } from 'antd';

const FilterModules = ({ value, onChange }) => {
  return (
    <Form.Item label="Phân Loại:" labelCol={{ span: 24 }}>
      <Select
        onChange={onChange}
        mode="multiple"
        value={value}
        placeholder="Tất cả"
      >
        <Select.Option key="PHARMACY" value="PHARMACY">
          Pharmacy
        </Select.Option>
        <Select.Option key="CLINIC" value="CLINIC">
          Clinic
        </Select.Option>
      </Select>
    </Form.Item>
  );
};

export default FilterModules;
