import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Skeleton, Modal, Select } from 'antd';
import AddressFormSection from '~/components/Common/AddressFormSection';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreateSupplier,
  useSupplier,
  useInitSupplier,
  useUpdateSupplier,
  useSupplierGroups
} from '~/hooks';
import { STATUS } from '~/constants/defaultValue';

const FormItem = Form.Item;
const { Option } = Select;

const query = {status : STATUS.ACTIVE};

const SupplierForm = ({ isOpen, onClose, id, callback }) => {
  const [form] = Form.useForm();

  const createSuccessCallback = newSupplier => {
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, form]);

  const [isSubmitLoading, handleCreate] = useCreateSupplier(
    createSuccessCallback
  );
  const [, handleUpdate] = useUpdateSupplier(onClose);
  const [supplier, isLoading] = useSupplier(id);
  const [supplierGroups, isGettingSupplierGroups] = useSupplierGroups(query);
  const initSupplier = useInitSupplier(supplier, id);
  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  useEffect(() => {
    form.resetFields();
    const { address } = initSupplier;

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initSupplier, form]);

  const onFinish = values => {
    const supplier = {
      ...values
    };

    if (id) {
      handleUpdate({ ...supplier, _id: id });
    } else {
      handleCreate(supplier);
    }
  };

  return (
    <Modal
      visible={isOpen}
      width={1020}
      footer={[]}
      onCancel={onClose}
      className="form-modal wc-form form-modal--clinic"
    >
      <div className="trading-supplier-form">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={values => onFormAddressChange(values, form)}
          scrollToFirstError
          requiredMark={false}
          initialValues={initSupplier}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} nhà cung cấp
            </h4>
          </Row>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Tên nhà cung cấp"
                name="name"
                rules={[
                  { required: true, message: 'Xin mời nhập tên nhà cung cấp' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Mã nhà cung cấp" name="supplierCode">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Nhóm nhà cung cấp" name="supplierGroupId">
                <Select
                  loading={isGettingSupplierGroups}
                  disabled={isGettingSupplierGroups}
                  showSearch
                >
                  {supplierGroups.map(({ name, _id }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Mô tả ngắn" name="note">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Công ty" name="company">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Mã số thuế" name="taxIdentificationNumber">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />

          <Row className="submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default SupplierForm;
