import React from 'react';
import { Form, Input, Row, Col, Skeleton } from 'antd';

const FormItem = Form.Item;
export default function NationalDrugLinkFormSection({ isLoading }) {
  return (
    <div
      className="nationalDrugLinkFormBox"
      style={{
        marginTop: '20px',
        fontWeight: '800',
        padding: '2em',
        borderRadius: '6px',
        backgroundColor: '#eee',
        border: '1px solid #c1c1c1'
      }}
    >
      <h6 style={{ fontWeight: '800', marginBottom: '20px' }}>
        Thông tin tài khoản kết nối với cơ sở dữ liệu Dược Quốc gia
      </h6>
      <Row gutter={48}>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <FormItem label="Mã cơ sở" name="pharmacyConnectCode">
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={48}>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <FormItem label="Tên đăng nhập" name="pharmacyConnectUsername">
            {isLoading ? <Skeleton.Input active /> : <Input />}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={48}>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <FormItem label="Mật khẩu" name="pharmacyConnectPassword">
            {isLoading ? <Skeleton.Input active /> : <Input.Password />}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
}
