export const GET_DAMAGES_REQUEST = 'GET_DAMAGES_REQUEST';
export const GET_DAMAGES_SUCCESS = 'GET_DAMAGES_SUCCESS';
export const GET_DAMAGES_FAILED = 'GET_DAMAGES_FAILED';

export const GET_DAMAGE_REQUEST = 'GET_DAMAGE_REQUEST';
export const GET_DAMAGE_SUCCESS = 'GET_DAMAGE_SUCCESS';
export const GET_DAMAGE_FAILED = 'GET_DAMAGE_FAILED';

export const CREATE_DAMAGE_REQUEST = 'CREATE_DAMAGE_REQUEST';
export const CREATE_DAMAGE_SUCCESS = 'CREATE_DAMAGE_SUCCESS';
export const CREATE_DAMAGE_FAILED = 'CREATE_DAMAGE_FAILED';

export const UPDATE_DAMAGE_REQUEST = 'UPDATE_DAMAGE_REQUEST';
export const UPDATE_DAMAGE_SUCCESS = 'UPDATE_DAMAGE_SUCCESS';
export const UPDATE_DAMAGE_FAILED = 'UPDATE_DAMAGE_FAILED';

export const DELETE_DAMAGE_REQUEST = 'DELETE_DAMAGE_REQUEST';
export const DELETE_DAMAGE_SUCCESS = 'DELETE_DAMAGE_SUCCESS';
export const DELETE_DAMAGE_FAILED = 'DELETE_DAMAGE_FAILED';

export const RESET_DAMAGE_STATE = 'RESET_DAMAGE_STATE';
