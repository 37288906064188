import * as React from 'react';
import { Popover } from 'antd';
import { PRODUCT_REPORT_POPOVER_ITEM } from '~/constants/defaultValue';

import './index.scss';

const valueDefault = {
  expireIn: '3MONTH', //get value from popupData
  duration: 'Ba tháng tới'
};

const PopoverNearDueReport = ({ setExpireIn, open, setOpen, durationValue, position, setEndDate }) => {
  const [duration, setDuration] = React.useState(durationValue ?? valueDefault.duration);

  const handleClick = (expireIn) => {
    setOpen(false)
    setExpireIn(expireIn.value);

    if (setEndDate) {
      setEndDate(expireIn.value)
    }

    setTimeout(() => {
      setDuration(expireIn.text);
    }, 300)
  };

  const renderTemplatePopup = () => (
    <div className={'popover-near-due__item'}>
      {PRODUCT_REPORT_POPOVER_ITEM.map((item, index) =>
        <span key={index} onClick={(e) => handleClick(item)}>{item.text}</span>
      )}
    </div>
  );

  const handleVisibleChange = (newOpen) => {
    setOpen(newOpen)
  };

  return (
    <Popover
      overlayClassName={'popover-near-due'}
      overlayStyle={{
        width: "240px"
      }}
      placement={position ?? 'bottomRight'}
      content={renderTemplatePopup()}
      onVisibleChange={handleVisibleChange}
      visible={open}
      trigger="click"
    >
      <span className={'popover-near-due__duration'}>{duration}</span>
    </Popover>
  )
};

export default PopoverNearDueReport;
