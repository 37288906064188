
import React, { useCallback, useState,useMemo, useEffect } from 'react'
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Popover, Radio } from "antd";
import { get, isArray, transform } from "lodash";
import moment from "moment"
import { useQueryParams } from "~/hooks/utils";
import { TYPE_SEARCH } from '~/constants/defaultValue';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const options = [
    {
      label: 'Tất cả',
      value: null,
    },
    {
      label: 'Hoàn Thành',
      value: 'COMPLETED',
    },
    {
      label: 'Nháp',
      value: 'DRAFT',
    },
    {
      label: 'Đã Hủy',
      value: 'CANCELED',
    },
  ];
function SelectAfter ({onFinishSearch,data}){
    const [form] =Form.useForm()
  const [openPopover, setOpenPopover] = useState(false);
    return <Popover
    style={{
      width: 300,
    }}
    className="popover-search-box"
    placement="bottomRight"
    content={Content({form:form,onFinishSearch:onFinishSearch,hide:setOpenPopover,data})}
    trigger="click"
    visible={openPopover}
    onVisibleChange={setOpenPopover}
  >
    <Button style={{marginLeft: 0}}>
      <CaretDownOutlined/>
    </Button>
  </Popover>
  }

function SearchInputMulti({onParamChange,keyword,data}) {
  
    const [timing, setTiming] = useState();
    const onFinishSearch= useCallback((value,callBackDelay)=>{
        onParamChange({...value})
        callBackDelay(600)
      },[])
      const transformValue = transform(data,(obj,value)=>{obj[value?.name] = value?.name=="status" ?null :""},{})   
    return (
        <Input.Search
        addonAfter={SelectAfter({onFinishSearch,data }) }
        style={{ width: '100%' }}
        placeholder="Tìm tất cả"
        enterButton={false}
        allowClear
        onSearch={(e) => onParamChange({...transformValue, keyword:e,page:1 })}
        onChange={e =>{
          timing && clearTimeout(timing)
          if(e.target.value===""){
            setTiming(setTimeout(()=>onParamChange({keyword:""}) ,1200))
          }                
        }}
          // defaultValue={keyword}
      />
    )
}

//content search
 function Content({form,onFinishSearch,hide,data}){
  const [isLoading, setIsLoading] = useState(false);
  const query = useQueryParams()
  const {location,push} = useHistory()

  const memoizedValue = useMemo(() => {
    return transform(data,(obj,value)=>{obj[value?.name]=query.get(value?.name)},{})
  }, [query,data]);
  const getTitleQuery = useCallback((text)=> get(memoizedValue,[text],""),[memoizedValue])
  const resetForm = useCallback(() =>{ 
    const transformValue = transform(data,(obj,value)=>{obj[value?.name] = value?.name=="status" ?null :""},{})   
    form.setFieldsValue(transformValue)
  },[data, form])
    const initialValue =(value) =>{
      switch (value?.type) {
        case "date":
          return getTitleQuery(value?.name) ? moment(getTitleQuery(value?.name)):""
        case "rangerTime":
          const [timeS,timeE] = getTitleQuery(value?.name)?.split(",")??[]
          return getTitleQuery(value?.name) ?[ moment(timeS),moment(timeE)]:""
        case "radio":
          return getTitleQuery(value?.name)??null
        default: 
          return getTitleQuery(value?.name)
      }
    }
    console.log(location,'location')
   useEffect(() => {
     if(!location?.search?.includes("isRedirect")){
       form.resetFields()
       push(location.pathName)
     };
    },[])
  const delay =(delay)=>{
    setTimeout(()=>{
      hide(false)
      setIsLoading(false)
    },delay)
  }
  return  (<Form
    onFinish={(value)=>{
      setIsLoading(true)
      let valueClone = {}
      for (const key in value) {
        if(moment.isMoment(value[key])){
          value[key] = value[key].format('YYYY-MM-DD')
        }
        if(isArray(value[key])){
          value[key].forEach((element) => {
            if(moment.isMoment(element)){
              valueClone[key] = [...(valueClone?.[key]??[]),element?.format('YYYY-MM-DD')]
            }
          });
        }
      }
      onFinishSearch({...value,...valueClone,keyword:"",page:1},delay)
    }
    }
    form={form}
    autoComplete="off"
    scrollToFirstError
    requiredMark={false}
    labelAlign={"left"}
    labelCol={{ span: 7 }}
    validateTrigger="onChange"
  >

    {(data)?.map((value,index)=>{
      return <FormInput
            key={index}
            label={value?.label} 
            name={value?.name} 
            placeholder={value.placeholder}
            // initialValue={initialValue(value)} 
            className={'form-item__multi'}
        >
          {
           value?.type ===TYPE_SEARCH.date? <DatePicker allowEmpty format={"YYYY-MM-DD"} /> 
            :value?.type===TYPE_SEARCH.radio ?
            <Radio.Group 
              size="small" 
              options={options} 
              optionType={"button"} 
              defaultValue={getTitleQuery(value?.name)??null} 
            /> 
            : value?.type=== TYPE_SEARCH.rangerTime ? 
            <DatePicker.RangePicker allowEmpty format={"YYYY-MM-DD"} /> 
            :""
          }
        </FormInput>
    }) }
    <div style={{textAlign: 'right'}}>
      <Button type="default" onClick={resetForm} >Xóa</Button>
      <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
    </div>
  </Form>);
 }

  function FormInput({name,initialValue,placeholder,children,label,rules}) {
    return (
      <Form.Item label={label} rules={rules} name={name} className={'form-item__multi'} >
        {!!children?children:<Input style={{borderBottom : '1px solid #999'}} allowClear bordered={false}  placeholder={placeholder}/>}      
    </Form.Item>
    )
  }

  export default SearchInputMulti