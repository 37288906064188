import * as Types from '~/constants/actionTypes';

export const getManufactures = query => ({
  type: Types.GET_MANUFACTURES_REQUEST,
  payload: query
});

export const getManufacture = id => ({
  type: Types.GET_MANUFACTURE_REQUEST,
  payload: id
});

export const createManufacture = location => ({
  type: Types.CREATE_MANUFACTURE_REQUEST,
  payload: location
});

export const updateManufacture = (id) => ({
  type: Types.UPDATE_MANUFACTURE_REQUEST,
  payload: id
});

export const deleteManufacture = (id) => ({
  type: Types.DELETE_MANUFACTURE_REQUEST,
  payload: id
});

export const getManufactureOptions = searchText => ({
  type: Types.GET_MANUFACTURE_OPTIONS_REQUEST,
  payload: searchText
});

export const resetManufactureState = () => ({
  type: Types.RESET_MANUFACTURE_STATE
});
