import { Button, Col, Input, InputNumber, Popconfirm, Row } from 'antd';
import { trim } from 'lodash';
import React from 'react';
import toastr from 'toastr';
import api from '~/api';
import BaseBorderBox from '~/components/Common/BaseBorderBox';
import { useAddLocalSale, useSelectedSale } from '~/hooks';
import { formatNumber } from '~/utils/helper';
export default function Logistic({ data, totalPrice, id, closeForm }) {
    const [code, setCode] = React.useState(data?.logisticCode);
    const [isDisable, setIsDisable] = React.useState(true);
    const [selectedSale] = useSelectedSale();
    const [, addSale] = useAddLocalSale();
    const updateLogisticCode = async ({ code, id }) => {
        try {
            await api.sale.updateLogisticCode({ code, id });
            addSale({
                ...selectedSale,
                deliveryInfo: {
                    ...selectedSale.deliveryInfo,
                    logisticCode: code
                }
            });
            closeForm();
            return toastr.success('Cập nhật mã vận đơn thành công!');
        } catch (error) {
            return toastr.error(
                error?.message || 'Cập nhật mã vận đơn thành công!'
            );
        }
    };
    const onUpdateLogisticCode = () => {
        try {
            if (trim(code) === '') {
                return toastr.error('Vui lòng nhập đúng mã vận đơn!');
            }
            updateLogisticCode({ code, id });
        } catch (error) {
            console.log(error);
        }
        setIsDisable(true);
    };
    return (
        <BaseBorderBox>
            <BaseBorderBox title={'Thông tin vận chuyển'}>
                <Row>
                    <Col span={12}>
                        <label>Mã vận đơn:</label>
                    </Col>
                    <Col span={12}>
                        <Input
                            value={code}
                            onChange={e => {
                                setIsDisable(false);
                                setCode(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row justify="end" align="middle">
                    <Popconfirm
                        zIndex={2001}
                        title="Bạn có chắc chắn muốn cập nhập mã vận đơn cho đơn hàng này?"
                        onConfirm={onUpdateLogisticCode}
                        okText="Đồng ý"
                        cancelText="Huỷ"
                    >
                        <Button
                            disabled={isDisable}
                            type="primary"
                            style={{ marginTop: 10, padding: '0 20px' }}
                        >
                            Cập nhật mã vận đơn
                        </Button>
                    </Popconfirm>
                </Row>
            </BaseBorderBox>
            <BaseBorderBox title={'Dự toán'}>
                <Row className="warehouse-form__row">
                    <Col span={12}>
                        <label className='warehouse-label-logistic'>
                            Phí vận chuyển:
                        </label>
                        <Input
                            style={{ width: '150px' }}
                            readOnly
                            value={formatNumber(data?.logisticFee)}
                            addonAfter="VND"
                        />
                    </Col>
                    <Col span={12}>
                        <label className='warehouse-label-logistic'>Tỉ lệ so với giá trị đơn hàng: </label>
                        <Input
                            style={{ width: '130px' }}
                            readOnly
                            value={(
                                (+data?.logisticFee / +totalPrice) *
                                100
                            )?.toFixed(2)}
                            addonAfter="%"
                        />
                    </Col>
                </Row>
            </BaseBorderBox>
            <BaseBorderBox title={'Thực tế - Hiện tại chưa kết nối được với đơn vị vận chuyển'}>
            <Row className="warehouse-form__row">
                    <Col span={12}>
                        <label className='warehouse-label-logistic'>
                            Phí vận chuyển:
                        </label>
                        <Input
                            style={{ width: '150px' }}
                            readOnly
                            value={formatNumber(data?.logisticFee)}
                            addonAfter="VND"
                        />
                    </Col>
                    <Col span={12}>
                        <label className='warehouse-label-logistic'>Tỉ lệ so với giá trị đơn hàng: </label>
                        <Input
                            style={{ width: '130px' }}
                            readOnly
                            value={(
                                (+data?.logisticFee / +totalPrice) *
                                100
                            )?.toFixed(2)}
                            addonAfter="%"
                        />
                    </Col>
                </Row>
            </BaseBorderBox>
        </BaseBorderBox>
    );
}
