import requester from '../requester';
import { CLINIC_BASE_URL } from '~/constants/defaultValue';

export const mockApi = {
  request(model = {}) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(model);
      }, 500);
    });
  }
};

const updateConfig = config => ({
  ...config,
  baseURL: CLINIC_BASE_URL
});

const clinicRequester = {
  get: (url, params, config = {}) =>
    requester.get(url, params, updateConfig(config)),
  post: (url, params, config = {}) =>
    requester.post(url, params, updateConfig(config)),
  put: (url, params, config = {}) =>
    requester.put(url, params, updateConfig(config)),
  patch: (url, params, config = {}) =>
    requester.patch(url, params, updateConfig(config)),
  delete: (url, params, config = {}) =>
    requester.delete(url, params, updateConfig(config))
};

export default clinicRequester;
