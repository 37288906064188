import requester from '../requester';

const news = {
  getAll: query => requester.get('/news', query),
  getById: id => requester.get(`/news/${id}?raw=true`),
  delete: id => requester.delete(`/news/${id}`),
  create: news => requester.post('/news', news),
  update: news => requester.put(`/news/${news.id}`, news)
};

export default news;
