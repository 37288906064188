import React, { useState } from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useStaffs,
  useDeleteStaff,
  useStaffQueryParams,
  useStaffPaging
} from '~/hooks/worldCare/staff';
import { Table, Tag, Popconfirm, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';

const { Search } = Input;

const ColumnActions = ({ id, deleteStaff }) => {
  return (
    <div className="custom-table__actions">
      <Link to={`/staff/edit/${id}`}>
        <p>Sửa</p>
      </Link>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá nhân viên này?"
        onConfirm={() => deleteStaff(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const Staffs = ({ history }) => {
  const [query, onPagingChange] = useStaffQueryParams();
  const [staffs, isLoading] = useStaffs(query);
  const [keyword, setKeyword] = useState(query.keyword);
  const [, deleteStaff] = useDeleteStaff();
  const paging = useStaffPaging();

  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}`;
    if (keyword) searchParams += `&keyword=${keyword}`;

    history.push({
      pathname: '/staff',
      search: searchParams
    });
  };

  const columns = [
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName',
      key: 'fullName'
    },

    {
      title: 'Chứng chỉ',
      dataIndex: 'certification',
      key: 'certification',
      render: (certification) => <p>{certification[0]?.name}</p>
    },
    {
      title: 'Dịch Vụ',
      key: 'speciality',
      dataIndex: 'speciality',
      render: (speciality) => (
        <div className="speciality-tags">
          {speciality.map((spec, index) => {
            return (
              <Tag color="blue" key={index}>
                {spec.name}
              </Tag>
            );
          })}
        </div>
      )
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return <ColumnActions {...record} deleteStaff={deleteStaff} />;
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhân viên" />

        <div className="page-wraper__header">
          <Search
            style={{ width: 200 }}
            placeholder="Tìm nhân viên"
            enterButton
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <Link to={PATH_APP.staff.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div>

        {isLoading ? (
          <SkeletonTable
            rowCount={staffs.length}
            columns={columns}
            pagination={paging}
          />
        ) : (
          <Table
            className='permission-table'
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={staffs}
            onChange={onPagingChange}
            size="middle"
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Staffs;
