import { useMemo, useRef, useState } from 'react';
import {
    getSelectors,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    createPurchaseOrderQuotation,
    deletePurchaseOrderQuotation,
    getPurchaseOrderQuotation,
    getPurchaseOrderQuotations,
    resetPurchaseOrderQuotationState,
    updatePurchaseOrderQuotation
} from '~/redux/action';
import { PATH_CLINIC } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';

const PURCHASE_RETURN_MODULE = 'purchaseOrderQuotation';

const CREATE_SUCCESS_MESS = 'Tạo mới đơn trả hàng nhập thành công';
const CREATE_FAILED_MESS = 'Tạo mới đơn trả hàng nhập thất bại';
const UPDATE_SUCCESS_MESS = 'Cập nhật đơn trả hàng nhập thành công';
const UPDATE_FAILED_MESS = 'Cập nhật đơn trả hàng nhập thất bại';
const DELETE_SUCCESS_MESS = 'Huỷ đơn trả hàng nhập thành công';
const DELETE_FAILED_MESS = 'Huỷ đơn trả hàng nhập thất bại';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(PURCHASE_RETURN_MODULE);

export const usePurchaseOrderQuotations = query => {
  return useFetchByParam({
    action: getPurchaseOrderQuotations,
    loadingSelector,
    dataSelector: listSelector,
    pagingSelector: pagingSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};


export const useCreatePurchaseOrderQuotation = callback => {
  useSuccess(createSuccessSelector, CREATE_SUCCESS_MESS, callback);
  useFailed(createFailedSelector, CREATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPurchaseOrderQuotation
  });
};

export const useUpdatePurchaseOrderQuotation = (callback=()=>{}) => {
  useSuccess(updateSuccessSelector, UPDATE_SUCCESS_MESS, callback);
  useFailed(updateFailedSelector, UPDATE_FAILED_MESS);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePurchaseOrderQuotation
  });
};

export const useDeletePurchaseOrderQuotation = onDeleteSuccess => {
  useSuccess(deleteSuccessSelector, DELETE_SUCCESS_MESS, onDeleteSuccess);
  useFailed(deleteFailedSelector, DELETE_FAILED_MESS);

  return useSubmit({
    loadingSelector,
    action: deletePurchaseOrderQuotation
  });
};

export const usePurchaseOrderQuotationById = params => {
  return useFetchByParam({
    action: getPurchaseOrderQuotation,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useUpdatePurchaseOrderQuotationParams = query => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = param => {
    history.push({
      pathname: PATH_CLINIC.purchaseOrderQuotation.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          id: undefined
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const usePurchaseOrderQuotationQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const page = query.get('page') ?? 1;
  const limit = query.get('limit') ?? 10;
  
  const keyword = query.get('keyword');
  const status = query.get('status');
  const id = query.get('id');

  const deleteSuccess = useSelector(deleteSuccessSelector);
  const createSuccess = useSelector(createSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      id,
      status,
      createSuccess
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page, 
    limit, 
    keyword, 
    createSuccess, 
    deleteSuccess,
    id,
    status
  ]);
};

export const usePurchaseOrderQuotationPaging = () => useSelector(pagingSelector);

export const useResetPurchaseOrderQuotation = () => {
  useResetState(resetPurchaseOrderQuotationState);
};

// const COLUMNS_OPTIONS = [
//   {
//     title: 'Mã nhập hàng',
//     key: 'code'
//   },
//   {
//     title: 'Thời gian',
//     key: 'time'
//   },
//   {
//     title: 'Nhà cung cấp',
//     key: 'supplier'
//   },
//   {
//     title: 'Tổng tiền hàng',
//     key: 'totalSupplierPayment'
//   },
//   {
//     title: 'Giảm giá',
//     key: 'discount'
//   },
//   {
//     title: 'NCC cần trả',
//     key: 'supplierDebt'
//   },
//   {
//     title: 'NCC đã trả',
//     key: 'totalSupplierPaid'
//   },
//   {
//     title: 'Trạng thái',
//     key: 'status'
//   }
// ];

