import { put, call, takeLatest, delay } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getCustomers({ payload: query }) {
  try {
    const data = yield call(Api.customer.getAll, query);
    yield put({ type: Types.GET_CUSTOMERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMERS_FAILED,
      payload: error
    });
  }
}

function* getCustomer({ payload }) {
  try {
    const customer = yield call(Api.customer.getOptions, payload);
    yield put({
      type: Types.GET_CUSTOMER_SUCCESS,
      payload: customer
    });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMER_FAILED,
      payload: error
    });
  }
}

function* createCustomer(action) {
  try {
    const data = yield call(Api.customer.create, action.payload);
    yield put({ type: Types.CREATE_CUSTOMER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_CUSTOMER_FAILED,
      payload: error
    });
  }
}

function* updateCustomer(action) {
  try {
    const data = yield call(Api.customer.update, action.payload);
    yield put({ type: Types.UPDATE_CUSTOMER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_CUSTOMER_FAILED,
      payload: error
    });
  }
}

function* deleteCustomer({ payload }) {
  try {
    yield call(Api.customer.delete, payload);
    yield put({ type: Types.DELETE_CUSTOMER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_CUSTOMER_FAILED,
      payload: error
    });
  }
}

function* getCustomerOptions({ payload }) {
  try {
    const options = yield call(Api.customer.getOptions, payload);
    yield put({
      type: Types.GET_CUSTOMER_OPTIONS_SUCCESS,
      payload: options
    });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMER_OPTIONS_FAILED,
      payload: error
    });
  }
}

function* getCustomerSearch({ payload }) {
  try {
   yield delay(500)
    const options = yield call(Api.customer.getCustomerSearch, payload);
    yield put({
      type: Types.GET_CUSTOMER_SEARCH_SUCCESS,
      payload: options
    });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMER_SEARCH_FAILED,
      payload: error
    });
  }
}

function* getCustomerDebt({ payload: query }) {
  try {
    const data = yield call(Api.customer.getDebt, query);
    yield put({ type: Types.GET_CUSTOMER_DEBT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMER_DEBT_FAILED,
      payload: error
    });
  }
}

function* getCustomerOrderHistory({ payload: query }) {
  try {
    const data = yield call(Api.customer.getOrderHistory, query);
    yield put({
      type: Types.GET_CUSTOMER_ORDER_HISTORY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMER_ORDER_HISTORY_FAILED,
      payload: error
    });
  }
}

function* getCustomerPurchases({ payload: query }) {
  try {
    const data = yield call(Api.customer.getPurchases, query);
    yield put({
      type: Types.GET_CUSTOMER_PURCHASES_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMER_PURCHASES_FAILED,
      payload: error
    });
  }
}

export default function* customer() {
  yield takeLatest(Types.GET_CUSTOMERS_REQUEST, getCustomers);
  yield takeLatest(Types.GET_CUSTOMER_REQUEST, getCustomer);
  yield takeLatest(Types.DELETE_CUSTOMER_REQUEST, deleteCustomer);
  yield takeLatest(Types.CREATE_CUSTOMER_REQUEST, createCustomer);
  yield takeLatest(Types.UPDATE_CUSTOMER_REQUEST, updateCustomer);
  yield takeLatest(Types.GET_CUSTOMER_OPTIONS_REQUEST, getCustomerOptions);
  yield takeLatest(Types.GET_CUSTOMER_SEARCH_REQUEST, getCustomerSearch);
  yield takeLatest(Types.GET_CUSTOMER_DEBT_REQUEST, getCustomerDebt);
  yield takeLatest(Types.GET_CUSTOMER_PURCHASES_REQUEST, getCustomerPurchases);
  yield takeLatest(
    Types.GET_CUSTOMER_ORDER_HISTORY_REQUEST,
    getCustomerOrderHistory
  );
}
