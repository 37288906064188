import * as Types from '~/constants/actionTypes';

export const getSupplierGroups = query => ({
  type: Types.GET_SUPPLIER_GROUPS_REQUEST,
  payload: query
});

export const getSupplierGroup = id => ({
  type: Types.GET_SUPPLIER_GROUP_REQUEST,
  payload: id
});

export const createSupplierGroup = unit => ({
  type: Types.CREATE_SUPPLIER_GROUP_REQUEST,
  payload: unit
});

export const updateSupplierGroup = unit => ({
  type: Types.UPDATE_SUPPLIER_GROUP_REQUEST,
  payload: unit
});

export const deleteSupplierGroup = id => ({
  type: Types.DELETE_SUPPLIER_GROUP_REQUEST,
  payload: id
});

export const resetSupplierGroupState = () => ({
  type: Types.RESET_SUPPLIER_GROUP_STATE
});
