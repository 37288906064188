import React, { useState, useMemo } from 'react';
import {
  useProcessesImport,
  useUpdateProcessImport,
  useDeleteProcessImport,
  useProcessImportQueryParams,
  useUpdateProcessImportParams,
  useResetProcessImport,
  useProcessImportPaging
} from '~/hooks';
import {
  Table,
  Popconfirm,
  Input,
  Button,
  Progress,
  Tag,
  Row,
  Radio,
  Space,
  Typography
} from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useMatchPolicy } from '~/hooks';
import {
  CARD_STYLE,
  PROCESS_STATUS_TYPES,
  PROCESS_STATUS_NAMES
} from '~/constants/defaultValue';
import FormImportFile from '~/components/Common/FormInportFile/FormImportFile';
import moment from 'moment';
import ProcessImportForm from './Form';

const { Search } = Input;

const ColumnActions = ({ _id, deleteFile, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
      <p onClick={() => onOpenForm(_id)}>Sửa</p>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá file này?"
        onConfirm={() => deleteFile(_id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

const ProcessImport = () => {
  const [query] = useProcessImportQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateProcessImportParams(query);

  const [processes, isLoading] = useProcessesImport(query);
  const paging = useProcessImportPaging();
  const [processImportId, setProcessImportId] = useState(null);
  const [isSubmitLoading, handleUpdate] = useUpdateProcessImport();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const numberOfUnits = processes.length;
  const canUpdate = useMatchPolicy();
  const canDelete = useMatchPolicy();

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfUnits === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteFile] = useDeleteProcessImport(onDeleteSuccess);

  useResetProcessImport();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onOpenForm = id => {
    setProcessImportId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setProcessImportId(null);
    setIsOpenForm(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Tên file dữ liệu',
        key: 'fileAction',
        width: 250,
        dataIndex: ['fileAction'],
        render: (e, record) => {
          return (
            <div>
              {e?.nameFile}
              {record?.process ? (
                <Progress percent={record?.process ?? 0}></Progress>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
      {
        title: 'File lỗi',
        key: 'fileError',
        dataIndex: ['fileError'],
        width: 250,
        render: record => {
          return (
            <a
              target="_blank"
              href={record?.url}
              download
              style={{ color: 'red' }}
            >
              {record?.nameFile}
            </a>
          );
        }
      },
      {
        title: 'Ngày tạo',
        key: 'createdAt',
        width: 150,
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD-MM-YYYY')
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        width: 150,
        align: 'center',
        render: (record, _) => {
          return record == 'DONE' ? (
            <Tag color="success">Thành công</Tag>
          ) : record == 'PROCESSING' ? (
            <Tag color="processing">Đang xử lý</Tag>
          ) : (
            <div style={{ display: 'flex' }}>
              <Tag color="error">Thất bại</Tag>
              <Button
                type="primary"
                onClick={() => handleUpdate({ _id: _._id, refresh: true })}
                style={{ marginLeft: '10px' }}
              >
                Tải lại
              </Button>
            </div>
          );
        }
      },
      {
        title: 'Ghi chú',
        key: 'note',
        dataIndex: 'note',
        width: 250
      },
      ...(canDelete || canUpdate
        ? [
            {
              title: 'Thao tác',
              key: 'action',
              width: '110px',
              render: record => {
                return (
                  <ColumnActions
                    {...record}
                    deleteFile={deleteFile}
                    onOpenForm={onOpenForm}
                  />
                );
              }
            }
          ]
        : [])
    ],
    [deleteFile]
  );

  const onChange = ({ target }) => {
    switch (target.value) {
      case 4:
        onParamChange({ ...query, status: PROCESS_STATUS_TYPES['DONE'] });
        break;
      case 3:
        onParamChange({ ...query, status: PROCESS_STATUS_TYPES['FAIL'] });
        break;
      case 2:
        onParamChange({ ...query, status: PROCESS_STATUS_TYPES['PROCESSING'] });
        break;
      default:
        onParamChange({ ...query, status: '' });
        break;
    }
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <div className="wc-page-header" style={{ paddingRight: 0 }}>
          <h1
            className="wc-page--header__title"
            style={{ fontSize: '24px', marginBottom: '5px' }}
          >
            Quá trình nhập thuốc, hàng hoá
          </h1>

          <div className="wc-page-header__search wclinic-search">
            {/* <Search
              style={{ width: '100%' }}
              placeholder="Tìm tên file"
              enterButton
              onSearch={() => onParamChange({ keyword })}
              onChange={e => setKeyword(e.target.value)}
              value={keyword}
            /> */}
          </div>

          <div className="wc-page-header__actions">
            {' '}
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={showModal}
            >
              Import file
            </Button>
          </div>
        </div>

        <Space style={{ marginBottom: 20 }}>
          <Typography style={{ fontSize: 18, marginRight: 20 }}>
            Phân loại trạng thái theo :
          </Typography>
          <Row gutter={20}>
            <Radio.Group
              onChange={onChange}
              optionType="button"
              buttonStyle="solid"
              defaultValue={(() => {
                switch (query) {
                  case 'DONE':
                    return 4;
                  case 'FAIL':
                    return 3;
                  case 'PROCESSING':
                    return 2;
                  default:
                    return 1;
                }
              })()}
            >
              <Radio.Button value={1}>Tất cả</Radio.Button>
              <Radio.Button value={4}>
                {PROCESS_STATUS_NAMES['DONE']}
              </Radio.Button>
              <Radio.Button value={3}>
                {PROCESS_STATUS_NAMES['FAIL']}
              </Radio.Button>
              <Radio.Button value={2}>
                {PROCESS_STATUS_NAMES['PROCESSING']}
              </Radio.Button>
            </Radio.Group>
          </Row>
        </Space>

        <div className="page-content__main">
          <div className="page-content__left hidden" style={CARD_STYLE}></div>

          <div className="page-content__right" style={CARD_STYLE}>
            {false ? (
              <SkeletonTable
                rowCount={processes.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={rc => rc._id}
                columns={columns}
                dataSource={processes}
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: total => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <FormImportFile
        onModule={handleOk}
        isModalOpen={isModalOpen}
        onClose={handleCancel}
        query={query}
      />
      <ProcessImportForm 
        isOpen={isOpenForm}
        onClose={onCloseForm}
        _id={processImportId}
        handleUpdate={handleUpdate}
        isSubmitLoading={isSubmitLoading}
      />
    </div>
  );
};

export default ProcessImport;
